export enum preIntakeTypes {
    PUT_PRE_INTAKE_REQUEST = "PUT_PRE_INTAKE_REQUEST",
    PUT_PRE_INTAKE_REQUEST_SUCCESS = "PUT_PRE_INTAKE_REQUEST_SUCCESS",
    PUT_PRE_INTAKE_REQUEST_FAILURE = "PUT_PRE_INTAKE_REQUEST_FAILURE",
    POST_PRE_INTAKE_DATA_REQUEST = "POST_PRE_INTAKE_DATA_REQUEST",
    POST_PRE_INTAKE_DATA_REQUEST_FAILURE = "POST_PRE_INTAKE_DATA_REQUEST_FAILURE"
}

export const GET_PRE_INTAKE_REQUEST = "GET_PRE_INTAKE_REQUEST";
export const GET_PRE_INTAKE_CACHE_DATA_CLEAR_REQUEST = "GET_PRE_INTAKE_CACHE_DATA_CLEAR_REQUEST";
export const GET_PRE_INTAKE_REQUEST_SUCCESS = "GET_PRE_INTAKE_REQUEST_SUCCESS";
export const GET_PRE_INTAKE_REQUEST_FAILURE = "GET_PRE_INTAKE_REQUEST_FAILURE";

export const GET_SET_PRE_INTAKE_ERROR_REQUEST = "GET_SET_PRE_INTAKE_ERROR_REQUEST";
export const UPDATE_PRE_INTAKE_ERROR_REQUEST = "UPDATE_PRE_INTAKE_ERROR_REQUEST";
export const CONTINUE_PRE_INTAKE_HANDLER_REQUEST = "CONTINUE_PRE_INTAKE_HANDLER_REQUEST";