// Get Wellness profile
export const WITHOUT_ACCOUNT_GET_PATIENT_WELLNESS_PROFILE_REQUEST = 'WITHOUT_ACCOUNT_GET_PATIENT_WELLNESS_PROFILE_REQUEST';
export const WITHOUT_ACCOUNT_GET_PATIENT_WELLNESS_PROFILE_REQUEST_SUCCESS = 'WITHOUT_ACCOUNT_GET_PATIENT_WELLNESS_PROFILE_REQUEST_SUCCESS';
export const WITHOUT_ACCOUNT_GET_PATIENT_WELLNESS_PROFILE_REQUEST_FAILURE = 'WITHOUT_ACCOUNT_GET_PATIENT_WELLNESS_PROFILE_REQUEST_FAILURE';

// Edit Wellness profile
export const WITHOUT_ACCOUNT_UPDATE_PATIENT_WELLNESS_PROFILE_REQUEST = 'WITHOUT_ACCOUNT_UPDATE_PATIENT_WELLNESS_PROFILE_REQUEST';
export const WITHOUT_ACCOUNT_UPDATE_PATIENT_WELLNESS_PROFILE_REQUEST_SUCCESS = 'WITHOUT_ACCOUNT_UPDATE_PATIENT_WELLNESS_PROFILE_REQUEST_SUCCESS';
export const WITHOUT_ACCOUNT_UPDATE_PATIENT_WELLNESS_PROFILE_REQUEST_FAILURE = 'WITHOUT_ACCOUNT_UPDATE_PATIENT_WELLNESS_PROFILE_REQUEST_FAILURE';

//Download PDF
export const DOWNLOAD_PDF_WITHOUT_ACCOUNT_REQUEST = "DOWNLOAD_PDF_WITHOUT_ACCOUNT_REQUEST"
export const DOWNLOAD_PDF_WITHOUT_ACCOUNT_REQUEST_SUCCESS = "DOWNLOAD_PDF_WITHOUT_ACCOUNT_REQUEST_SUCCESS"
export const DOWNLOAD_PDF_WITHOUT_ACCOUNT_REQUEST_FAILURE = "DOWNLOAD_PDF_WITHOUT_ACCOUNT_REQUEST_FAILURE"
