import {Box, Typography} from '@mui/material'
import React from 'react'
import PopupComponent from "../../../common/PopupComponent";

type Props = {
    open: boolean
    handleClose: any
    treatmentStatus: string
    continueHandler: any
}
const ApproveRejectPopup = (props: Props) => {
    const {open, handleClose, treatmentStatus, continueHandler} = props
    return (
        <PopupComponent isPatient saveBtnText={'Yes'} cancelBtnText={'Cancel'} width={'570px'} headerAction={false}
                        footerAction={true}  open={open} handleClose={handleClose} handleSave={continueHandler}>
            <Box py={2}>
                <Typography className={'f-16 f-w-400 fw-regular labelColor'} textAlign={'center'}>
                    {treatmentStatus === 'approved' ? 'Would you like to accept the treatment and schedule an appointment?' :
                        "Would you like to reconsider this treatment?"
                    }
                </Typography>
            </Box>

        </PopupComponent>
    )
}

export default ApproveRejectPopup