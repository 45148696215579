import { call, put, takeEvery, all } from 'redux-saga/effects';
import API from '../../../../utils/api';
import { errorToaster, successToaster } from '../../../../utils/helper';
import {
  getNotificationListTypeSuccessAction,
  getNotificationListTypeFailureAction,
  getNotificationListCacheDataClearRequest,
  putNotificationListTypeSuccessAction,
  putNotificationListTypeFailureAction,
  getNotificationCountTypeSuccessAction,
  getNotificationCountTypeFailureAction,
  getNotificationCountCacheDataClearRequest
} from '../../../actions/v2/dentist/notificationListingAction';
import { GET_NOTIFICATION_REQUEST, PUT_NOTIFICATION_REQUEST, GET_NOTIFICATION_COUNT_REQUEST } from '../../../ActionTypes/v2/dentist/notificationListing';

// get notification listing
function* getNotificationListingTypeRequest(payload: any): any {
  yield put(getNotificationListCacheDataClearRequest())
  try {
    const params = [
      { key: 'page', value: payload?.payload?.page },
      { key: 'size', value: payload?.payload?.size },
      { key: 'show_unread_only', value: payload?.payload?.showUnreadOnly }
    ]
    let tmpUrl = `?`
    params?.map((p: any) => {
      if (tmpUrl.length > 1) {
        if (p.value) {
          tmpUrl = `${tmpUrl}&${p.key}=${p.value}`
        }
      } else {
        if (p.value) {
          tmpUrl = `${tmpUrl}${p.key}=${p.value}`
        }
      }
      return true
    })

    const newUrl = `v2/user/notifications/${payload.payload?.clinicID}${tmpUrl}`
    let url = newUrl
    const response = yield API.get(url)

    yield put(
      putNotificationListTypeSuccessAction({
        notificationData: response?.data?.detail,
        notificationMeta: response?.data?.meta,
      })
    )
    // successToaster(response?.data?.meta?.message)
  } catch (e: any) {
    errorToaster(e?.message)
    yield put(
      putNotificationListTypeFailureAction({
        notificationError: e?.message,
      })
    )
  }
}

// put notification
function* putNotificationStatusTypeRequest(payload: any): any {
  const newPayloadData = payload?.payload?.notificationID && payload?.payload?.notificationID
  try {
    const response = yield API.put(`v2/user/notification/read/${payload.payload?.clinicID}`, {
      ID: newPayloadData,
    });

    yield put(
      getNotificationListTypeSuccessAction({
        notificationData: response?.data?.detail,
        notificationMeta: response?.data?.meta,
      })
    )

    if (payload?.payload?.callback) {
      yield call(payload?.payload?.callback)
    }
    // successToaster(response?.data?.meta?.message)
  } catch (e: any) {
    errorToaster(e?.message)
    yield put(
      getNotificationListTypeFailureAction({
        notificationError: e?.message,
      })
    )
  }
}

// get notification count
function* getNotificationCountTypeRequest(payload: any): any {
  yield put(getNotificationCountCacheDataClearRequest())
  try {
    const response = yield API.get(`v2/user/notifications/count/${payload.payload?.clinicID}`);

    yield put(
      getNotificationCountTypeSuccessAction({
        notificationCount: response?.data?.detail,
      })
    )

    if (payload?.payload?.callback) {
      yield call(payload?.payload?.callback)
    }
    // successToaster(response?.data?.meta?.message)
  } catch (e: any) {
    errorToaster(e?.message)
    yield put(
      getNotificationCountTypeFailureAction({
        notificationCountError: e?.message,
      })
    )
  }
}

function* getNotificationListingSaga() {
  yield takeEvery(GET_NOTIFICATION_REQUEST, getNotificationListingTypeRequest)
}

function* putNotificationStatusSaga() {
  yield takeEvery(PUT_NOTIFICATION_REQUEST, putNotificationStatusTypeRequest)
}

function* getNotificationCountSaga() {
  yield takeEvery(GET_NOTIFICATION_COUNT_REQUEST, getNotificationCountTypeRequest)
}

export default function* notificationListingSaga() {
  yield all([
    getNotificationListingSaga(),
    putNotificationStatusSaga(),
    getNotificationCountSaga()
  ])
}
