import theme from '../../../../../../../theme';

const rightPathsData = [
  "M-14.4488 1.83789L44.2148 1.83789",
  "M-14.4488 3.13867L44.2148 3.13867",
  "M-14.4488 4.4375L44.2148 4.4375",
  "M-14.4488 5.73828L44.2148 5.73828",
  "M-14.4488 7.03906L44.2148 7.03906",
  "M-14.4488 8.33789L44.2148 8.33789",
  "M-14.4488 9.63867L44.2148 9.63867",
  "M-14.4488 10.9375L44.2148 10.9375",
  "M-14.4488 12.2383L44.2148 12.2383",
  "M-14.4488 13.5391L44.2148 13.5391",
  "M-14.4488 14.8379L44.2148 14.8379",
  "M-14.4488 16.1387L44.2148 16.1387",
  "M-14.4488 17.4375L44.2148 17.4375",
  "M-14.4488 18.7383L44.2148 18.7383",
  "M-14.4488 20.0391L44.2148 20.0391",
  "M-14.4488 21.3379L44.2148 21.3379",
  "M-14.4488 22.6387L44.2148 22.6387",
  "M-14.4488 23.9375L44.2148 23.9375",
  "M-14.4488 25.2383L44.2148 25.2383",
  "M-14.4488 26.5391L44.2148 26.5391",
];
const bottomPathsData = [
  "M-9.07969 21.3398L49.584 21.3398",
  "M-9.07969 22.6406L49.584 22.6406",
  "M-9.07969 23.9414L49.584 23.9414",
  "M-9.07969 25.2402L49.584 25.2402",
  "M-9.07969 26.541L49.584 26.541",
  "M-9.07969 27.8398L49.584 27.8398",
  "M-14.4488 9.63867L44.2148 9.63867",
  "M-14.4488 26.5391L44.2148 26.5391",
];
const leftPathsData = [
  "M7.46914 1.83984L66.1328 1.83984",
  "M7.46914 3.13867L66.1328 3.13867",
  "M7.46914 4.43945L66.1328 4.43945",
  "M7.46914 5.73828L66.1328 5.73828",
  "M7.46914 7.03906L66.1328 7.03906",
  "M7.46914 8.33984L66.1328 8.33984",
  "M7.46914 9.63867L66.1328 9.63867",
  "M7.46914 10.9395L66.1328 10.9395",
  "M7.46914 12.2383L66.1328 12.2383",
  "M7.46914 13.5391L66.1328 13.5391",
  "M7.46914 14.8398L66.1328 14.8398",
  "M7.46914 16.1387L66.1328 16.1387",
  "M7.46914 17.4395L66.1328 17.4395",
  "M7.46914 18.7383L66.1328 18.7383",
  "M7.46914 20.0391L66.1328 20.0391",
  "M7.46914 21.3398L66.1328 21.3398",
  "M7.46914 22.6387L66.1328 22.6387",
  "M7.46914 23.9395L66.1328 23.9395",
  "M7.46914 25.2383L66.1328 25.2383",
  "M7.46914 26.5391L66.1328 26.5391",
];
const topPathsData = [
  "M-10.1988 0.535156L48.4648 0.535156",
  "M-10.1988 1.83594L48.4648 1.83594",
  "M-10.1988 3.13477L48.4648 3.13477",
  "M-10.1988 4.43555L48.4648 4.43555",
  "M-10.1988 5.73438L48.4648 5.73438",
  "M-10.1988 7.03516L48.4648 7.03516",
];
const centerPathsData = [
  "M-11.2535 8.3418L47.4102 8.3418",
  "M-11.2535 9.64258L47.4102 9.64258",
  "M-11.2535 10.9414L47.4102 10.9414",
  "M-11.2535 12.2422L47.4102 12.2422",
  "M-11.2535 13.543L47.4102 13.543",
  "M-11.2535 14.8418L47.4102 14.8418",
  "M-11.2535 16.1426L47.4102 16.1426",
  "M-11.2535 17.4414L47.4102 17.4414",
  "M-11.2535 18.7422L47.4102 18.7422",
  "M-11.2535 20.043L47.4102 20.043",
  "M-11.2535 21.3418L47.4102 21.3418",
];
const { palette } = theme
const surfacesLineData = {
  right: {
    maskId: 'mask0_4022_182503',
    x: 0,
    y: 1,
    width: 9,
    height: 27,
    d: "M0.435547 3.97418C0.435547 3.0298 0.842649 2.17136 1.4979 1.54883L8.02156 7.74232C7.68598 8.06197 7.47852 8.50294 7.47852 8.98993V19.6279C7.47852 20.1154 7.68646 20.5569 8.02275 20.8766L1.4979 27.0713C0.842649 26.4489 0.435547 25.5903 0.435547 24.6459V3.97418Z",
    pathData: rightPathsData
  },
  left: {
    maskId: 'mask3_10734_117431',
    x: 21,
    y: 1,
    width: 9,
    height: 27,
    d: "M28.3534 1.57031C29.0095 2.19284 29.4157 3.05129 29.4157 3.99567V24.6674C29.4157 25.6117 29.0095 26.4704 28.3534 27.0928L21.8301 20.8992C22.167 20.5793 22.3754 20.1375 22.3754 19.6494V9.01141C22.3754 8.52389 22.1675 8.08248 21.8313 7.76275L28.3534 1.57031Z",
    pathData: leftPathsData
  },
  top: {
    maskId: 'mask4_10734_117431',
    x: 1,
    y: 0,
    width: 28,
    height: 8,
    d: "M25.8175 0.539062C26.8127 0.539062 27.717 0.925472 28.3722 1.5479L21.8516 7.73896C21.5148 7.41992 21.05 7.22266 20.5367 7.22266H9.33212C8.8173 7.22266 8.35129 7.42108 8.01432 7.74178L1.49023 1.5479C2.14637 0.925472 3.05065 0.539062 4.045 0.539062H25.8175Z",
    pathData: topPathsData
  },
  bottom: {
    maskId: 'mask2_10734_117431',
    x: 1,
    y: 20,
    width: 28,
    height: 9,
    d: "M8.01428 20.873C8.35126 21.1938 8.81728 21.3922 9.33212 21.3922H20.5367C21.0501 21.3922 21.5148 21.1949 21.8516 20.8759L28.3722 27.067C27.717 27.6894 26.8127 28.0757 25.8175 28.0757H4.045C3.05065 28.0757 2.14637 27.6894 1.49023 27.067L8.01428 20.873Z",
    pathData: bottomPathsData
  },
  center: {
    maskId: 'mask0_10734_117431',
    x: 7,
    y: 7,
    width: 16,
    height: 15,
    d: "M20.5328 7.22266H9.32822C8.30018 7.22266 7.4668 8.01388 7.4668 8.98993V19.6279C7.4668 20.6039 8.30018 21.3952 9.32822 21.3952H20.5328C21.5608 21.3952 22.3942 20.6039 22.3942 19.6279V8.98993C22.3942 8.01388 21.5608 7.22266 20.5328 7.22266Z",
    pathData: centerPathsData
  }
} as any
export const SurfaceLineData = (side: string, svg: any, color: string) => {
  const surfaceSeq = ['top', 'right', 'bottom', 'left', 'center'] as any;
  const lineSide = (side === 'left') ? 'right' : side === 'right' ? 'left' : side
  return surfaceSeq?.filter((d: string) => d === lineSide)?.map((surfaceType: string) => {
    const data = surfacesLineData[surfaceType];
    const mask = svg
      .append('mask')
      .attr('id', data?.maskId)
      .attr('style', 'mask-type: alpha')
      .attr('maskUnits', 'userSpaceOnUse')
      .attr('x', data?.x)
      .attr('y', data?.y)
      .attr('width', data?.width)
      .attr('height', data?.height);
    mask
      .append('path')
      .attr('d', data?.d)
      .attr('fill', palette.common.skyBg)
      .attr('stroke', palette.common.skyBorder)
      .attr('stroke-width', '0.409272')
      .attr('stroke-miterlimit', '10');
    const group = svg.append('g').attr('mask', `url(#${data?.maskId})`);
    data?.pathData?.forEach((pathsData: any) => {
      group.append('path')
        .attr('d', pathsData)
        .attr('fill', palette.common.skyBg)
        .attr('stroke', color)
        .attr('stroke-width', '0.3');
    })
    return svg
  })
}
