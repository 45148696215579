import {
    REASSIGN_TREATMENT_REQUEST,
    REMOVE_TREATMENTS_DATA,
    REMOVE_HYGIENE_REGIME_DATA,
    SELECTED_PLANNED_CHECKBOX_DATA,
    ADD_PROCEDURE_TEXT_DATA,
    UPDATE_PROCEDURE_TEXT_ERROR,
    UPDATE_TREATMENT_DATA_VALIDATION,
    OPEN_PROCEDURE_TEXT_POPUP,
    GET_TREATMENT_FEES_REQUEST,
    GET_TREATMENT_FEES_SUCCESS,
    GET_TREATMENT_FEES_FAILURE
} from '../../../../ActionTypes/v2/dentist/clinicalExam/treatment/plannedTreatments';

import {
    AddProcedureTextDataRequest,
    ReassignedTreatmentRequest,
    RemoveHygieneRegimeDataRequest,
    RemoveTreatmentsDataRequest,
    selectedPlannedCheckboxData, UpdateProcedureTextErrorRequest, UpdateTreatmentDataValidationsRequest,
    OpenProcedureTextPopUpRequest,
    GetTreatmentFeesRequest,
    GetTreatmentFeesSuccess,
    GetTreatmentFeesFailure
} from '../../../../types/v2/dentist/clinicalExam/treatments/plannedTreatments';

// Assigned treatment state value
export const reAssignedTreatmentAction = (payload: any): ReassignedTreatmentRequest => ({
    type: REASSIGN_TREATMENT_REQUEST,
    payload
});

// Remove treatments store
export const removeTreatmentsDataAction = (payload: any): RemoveTreatmentsDataRequest => ({
    type: REMOVE_TREATMENTS_DATA,
    payload
});

// Remove HygieneRegime store
export const removeHygieneRegimeDataAction = (payload: any): RemoveHygieneRegimeDataRequest => ({
    type: REMOVE_HYGIENE_REGIME_DATA,
    payload
});

//checkbox selected data
export const selectedPlannedCheckboxDataAction = (payload: any): selectedPlannedCheckboxData => ({
    type: SELECTED_PLANNED_CHECKBOX_DATA,
    payload
});


export const openProcedureTextPopUpRequestAction = (payload: any): OpenProcedureTextPopUpRequest => ({
    type: OPEN_PROCEDURE_TEXT_POPUP,
    payload
})
export const addProcedureTextDataRequestAction = (payload: any): AddProcedureTextDataRequest => ({
    type: ADD_PROCEDURE_TEXT_DATA,
    payload
})
export const updateProcedureTextErrorRequestAction = (payload: any): UpdateProcedureTextErrorRequest => ({
    type: UPDATE_PROCEDURE_TEXT_ERROR,
    payload
})
export const updateTreatmentDataValidationsAction = (payload: any): UpdateTreatmentDataValidationsRequest => ({
    type: UPDATE_TREATMENT_DATA_VALIDATION,
    payload
})

// Get treatment data fees
export const getTreatmentDataFeesAction = (payload: any): GetTreatmentFeesRequest => ({
    type: GET_TREATMENT_FEES_REQUEST,
    payload
})

export const getTreatmentDataFeesSuccessAction = (payload: any): GetTreatmentFeesSuccess => ({
    type: GET_TREATMENT_FEES_SUCCESS,
    payload
})

export const getTreatmentDataFeesFailAction = (payload: any): GetTreatmentFeesFailure => ({
    type: GET_TREATMENT_FEES_FAILURE,
    payload
})
