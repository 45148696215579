import {
    UPDATE_RISK_ASSESSMENT,
    GET_RISK_ASSESSMENT_REQUEST,
    GET_RISK_ASSESSMENT_REQUEST_SUCCESS,
    GET_RISK_ASSESSMENT_REQUEST_FAILURE,
    PUT_RISK_ASSESSMENT_REQUEST,
    PUT_RISK_ASSESSMENT_REQUEST_SUCCESS,
    PUT_RISK_ASSESSMENT_REQUEST_FAILURE,
    UPDATE_RISK_ASSESSMENT_VALIDATION,
    GENERATE_RISK_ASSESSMENT_REQUEST,
    GENERATE_RISK_ASSESSMENT_REQUEST_SUCCESS,
    GENERATE_RISK_ASSESSMENT_REQUEST_FAILURE
} from '../../../../ActionTypes/v2/dentist/clinicalExam/riskAssessment';
import { riskAssessmentInterface} from '../../../../types/v2/dentist/clinicalExam/riskAssessment';

const initialState: riskAssessmentInterface = {
    getRiskAssessmentLoader: false,
    data: null,
    error: '',
    riskAssessmentData: null,
    saveLoader: false,
    errors: {
        medicalRisk: {},
        sleepRisk: {},
        occlusionTmjRisk: {},
        cariesRisk: {},
        periodontalRisk: {},
        biomechanicsRisk: {},
        estheticsRisk: {}
    },
    generateRiskAssessment: null,
    generateRiskAssessmentLoader: false
};

const riskAssessmentReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case UPDATE_RISK_ASSESSMENT:
            return {
                ...state,
                riskAssessmentData: action.payload
            };
        case UPDATE_RISK_ASSESSMENT_VALIDATION:
            return {
                ...state,
                errors: action.payload
            };
        case GET_RISK_ASSESSMENT_REQUEST:
            return {
                ...state,
                getRiskAssessmentLoader: true,
                data: null,
                error: ''
            };
        case GET_RISK_ASSESSMENT_REQUEST_SUCCESS:
            return {
                ...state,
                getRiskAssessmentLoader: false,
                data: action.payload,
                error: ''
            };
        case GET_RISK_ASSESSMENT_REQUEST_FAILURE:
            return {
                ...state,
                getRiskAssessmentLoader: false,
                data: null,
                error: action.payload,
                riskAssessmentData: null
            };
        case PUT_RISK_ASSESSMENT_REQUEST:
            return {
                ...state,
                saveLoader: true
            };
        case PUT_RISK_ASSESSMENT_REQUEST_SUCCESS:
            return {
                ...state,
                saveLoader: false,
                data: action.payload,
                error: ''
            };
        case PUT_RISK_ASSESSMENT_REQUEST_FAILURE:
            return {
                ...state,
                saveLoader: false,
                data: null,
                error: action.payload
            };
        case GENERATE_RISK_ASSESSMENT_REQUEST:
            return {
                ...state,
                generateRiskAssessmentLoader: true
            };
        case GENERATE_RISK_ASSESSMENT_REQUEST_SUCCESS:
            return {
                ...state,
                generateRiskAssessmentLoader: false,
                generateRiskAssessment: action.payload
            };
        case GENERATE_RISK_ASSESSMENT_REQUEST_FAILURE:
            return {
                ...state,
                generateRiskAssessmentLoader: false,
                generateRiskAssessment: null
            };

        default:
            return {
                ...state
            };
    }
};

export default riskAssessmentReducer;
