import {BorderColor, Delete} from "@mui/icons-material";
import {Box, Typography} from "@mui/material";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {makeStyles} from "@mui/styles"
import {isArray} from "lodash";
import React, {useCallback} from "react";
import {useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";
import {setExamFindingsAction} from "../../../../../app/actions/v2/dentist/clinicalExam/exam/examFindingsTypesAction";
import {
    handleOdontogramFormFindingSelectionAction,
    openOdontogramGroupFindingPopupAction,
    selectOdontrogramFindingAction,
    selectOdontrogramToothAction,
    setEditOdontogramFindingDataAction
} from "../../../../../app/actions/v2/dentist/clinicalExam/odontogramAction";
import {RootState} from "../../../../../app/reducers/v2/rootReducer";
import theme from "../../../../../theme";
import {removeRefFromJson, stringToArray, toothNumberPreferences} from "../../../../../utils/helper";
import {
    completeCheckout,
    getShortSurface, viewExam
} from "../../../../../utils/v2/clinicalExamConstant";
import {
    examConstantInterface,
    findingData,
    FindingList
} from "../../../../../utils/v2/examConstant";
import {procedureColors} from "../../../../../utils/v2/procedureHelper";
import NoDataFound from "../../../../v1/Common/NoDataFound";
import CheckboxSelect from "../../../common/CheckboxSelect";
import {useLocation} from "react-router-dom";

const useStyles = makeStyles((theme: any) => ({
    toothBox: {
        width: '26px',
        height: '24px',
        borderRadius: '12px',
        fontSize: '12px',
    },
    chartingTr: {
        minHeight: '40px',
        height: '40px',
        borderBottom: `1px solid ${theme.palette.common.grey}`
    },
    chartingTd: {
        paddingTop: 0,
        paddingBottom: 0,
        border: "none !important",
        minHeight: '40px',
        color: theme.palette.common.black
    }
}));

export default function ChartingForm() {
    const {palette} = theme
    const classes = useStyles();
    const dispatch = useDispatch()
    const {
        odontogram: {
            selectedFormFinding,
            cameOdontogramFromTreatment,
            procedureFilters,
            showRejectedTreatmentsOnly,
        },
        examFindingReducer: {examFindingData},
        appointmentsReducer: {appointmentDetails},
        clinicalExamFilters: {riskFilters},
        wellnessProfileFilters: { wellnessProfileData},
    } = useSelector((state: RootState) => state) as any
    const location = useLocation()
    const isWellnessProfile = location?.pathname?.includes('/v2/dentist/wellness-profile/')
    const riskWiseFilteredData = useMemo(() => {
        const riskWiseFiltered = riskFilters?.length
            ? (findingData?.filter((f: examConstantInterface) => f?.risks?.filter((ff: string) => riskFilters.includes(ff))?.length) as any)
            : findingData; /*Filter the data only if there is at least one risk is selected else will show data without risk filters*/
        return riskWiseFiltered
    }, [riskFilters, riskFilters?.length, findingData]);


    const allowEdit = useCallback((finding?: string | undefined) => {
        return (isWellnessProfile ? !wellnessProfileData?.is_wellness_locked :
            ((![completeCheckout, viewExam].includes(appointmentDetails?.sub_status) || cameOdontogramFromTreatment)))
    }, [appointmentDetails?.sub_status, cameOdontogramFromTreatment, appointmentDetails, wellnessProfileData?.is_wellness_locked])

    const getRows = useMemo(() => {
        let rows = [] as any
        if (examFindingData) {
            const filteredFindingData = removeRefFromJson(riskWiseFilteredData) as any
            Object.keys(examFindingData)?.map((res: any) => {
                filteredFindingData?.map((findingKey: any) => {
                    Object.keys(examFindingData[res])?.filter((findingData: any) => findingData === findingKey?.key || (findingKey?.key === 'missing_tooth' && findingData === 'missing_tooth_tooth_number'))?.map((finding: any) => {
                        if (examFindingData[res][finding]) {
                            if (isArray(examFindingData[res][finding])) {
                                examFindingData[res][finding]?.map((val: any) => {

                                    if (val && Object.keys(val)?.filter((obj: any) => val[obj])?.length) {
                                        if (showRejectedTreatmentsOnly) {
                                            if (val?.rejected) {
                                                //Condition to show filtered data by procedure code
                                                if (procedureFilters?.length < 1 || (procedureFilters?.includes(val?.procedure_status) || (procedureFilters?.includes('Existing') && finding?.includes('missing_tooth')))) {
                                                    if (cameOdontogramFromTreatment) {
                                                        if (val?.procedure_status === 'Planned') {
                                                            rows.push({
                                                                ...val,
                                                                key: finding,
                                                                title: filteredFindingData?.find((fin: any) => fin?.key === finding)?.title,
                                                                exam_group: res
                                                            })
                                                        }
                                                    } else {
                                                        if (finding?.includes('missing_tooth')) {
                                                            rows.push({
                                                                tooth_number: `${val}`,
                                                                key: finding,
                                                                title: 'Missing Tooth',
                                                                exam_group: res,
                                                                procedure_status: 'Existing',
                                                            })
                                                        } else {
                                                            rows.push({
                                                                ...val,
                                                                key: finding,
                                                                title: filteredFindingData?.find((fin: any) => fin?.key === finding)?.title,
                                                                exam_group: res
                                                            })
                                                        }
                                                    }
                                                }
                                            }
                                        } else {
                                            //Condition to show filtered data by procedure code
                                            if (procedureFilters?.length < 1 || (procedureFilters.includes(val?.procedure_status) || (procedureFilters.includes('Existing') && finding?.includes('missing_tooth')))) {
                                                if (cameOdontogramFromTreatment) {
                                                    if (val?.procedure_status === 'Planned') {
                                                        rows.push({
                                                            ...val,
                                                            key: finding,
                                                            title: filteredFindingData?.find((fin: any) => fin?.key === finding)?.title,
                                                            exam_group: res
                                                        })
                                                    }
                                                } else {
                                                    if (finding?.includes('missing_tooth')) {
                                                        rows.push({
                                                            tooth_number: `${val}`,
                                                            key: finding,
                                                            title: 'Missing Tooth',
                                                            exam_group: res,
                                                            procedure_status: 'Existing',
                                                        })
                                                    } else {
                                                        rows.push({
                                                            ...val,
                                                            key: finding,
                                                            title: filteredFindingData?.find((fin: any) => fin?.key === finding)?.title,
                                                            exam_group: res
                                                        })
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    return true
                                })
                            } else {
                                if (finding?.includes('missing_tooth') && !cameOdontogramFromTreatment) {
                                    examFindingData[res][finding]?.split(',')?.map((mt: string) => {
                                        if (procedureFilters.includes('Existing') || procedureFilters?.length < 1) {
                                            if (!showRejectedTreatmentsOnly) {
                                                rows.push({
                                                    tooth_number: mt,
                                                    key: finding,
                                                    title: 'Missing Tooth',
                                                    exam_group: res,
                                                    procedure_status: 'Existing',
                                                })
                                            }
                                        }
                                        return true
                                    })
                                }
                            }
                        }
                        return true
                    })
                })
                return true
            })
        }
        return rows
    }, [examFindingData, cameOdontogramFromTreatment, procedureFilters?.length, showRejectedTreatmentsOnly, procedureFilters, riskWiseFilteredData])

    const openEditFindingPopup = (row: any) => {
        dispatch(selectOdontrogramToothAction([parseInt(row?.tooth_number[0])]))
        dispatch(selectOdontrogramFindingAction(row?.key))
        dispatch(setEditOdontogramFindingDataAction(row))
        FindingList?.filter((res: any) =>
            res?.groupSubType?.includes(row?.key)
        )?.map((findingData: any) => dispatch(openOdontogramGroupFindingPopupAction(findingData?.key)))
    }

    const handleSelectFinding = (e: any, obj: any) => {
        let newSelection = removeRefFromJson(selectedFormFinding) as any
        if (!e.target.checked) {
            if (e.target.name === 'title') {
                newSelection = []
            } else {
                newSelection = newSelection?.filter((f: any) => JSON.stringify(f) !== JSON.stringify(obj))
            }
        } else {
            if (e.target.name === 'title') {
                newSelection = getRows
            } else {
                newSelection?.push(obj)
            }
        }
        dispatch(handleOdontogramFormFindingSelectionAction(newSelection))
    }

    const handleDeleteMissingTooth = (fin: any, index: number) => {
        let updatedExamFindingData = examFindingData as any
        //Put the conditions' coz sometime toots founded as array and sometime as a string. (Need to be fixed)
        if (isArray(updatedExamFindingData[fin.exam_group][fin.key])) {
            updatedExamFindingData[fin.exam_group][fin.key] = updatedExamFindingData[fin.exam_group][fin.key].filter((f: string) => f !== fin?.tooth_number)
        } else {
            updatedExamFindingData[fin.exam_group][fin.key] = updatedExamFindingData[fin.exam_group][fin.key]?.split(',').filter((f: string) => f !== fin?.tooth_number)
        }
        dispatch(setExamFindingsAction(updatedExamFindingData))

        if (index > -1) {
            selectedFormFinding.splice(index, 1);
        }
        dispatch(handleOdontogramFormFindingSelectionAction(selectedFormFinding))
    }

    const getFindingTitle = (findingData: any) => {
        if (findingData?.key === 'sct') {
            if (findingData?.cracks === 'yes' || findingData?.cracks === true) {
                return `${findingData?.title} - Cracks Detected`
            } else {
                return `${findingData?.title} - No Cracks`
            }
        }
        if (findingData?.key === 'non_carious_cervical_lesions') {
            return `${findingData?.title} (${(findingData?.ncclTypes?.toString())})`
        }
        return findingData?.title

    }

    return (
        <TableContainer component={Paper} sx={{overflowY: 'hidden !important'}}>
            <Table aria-label="simple table" sx={{background: palette.common.white}}>
                <TableHead>
                    <TableRow className={classes.chartingTr}>
                        <TableCell className={classes.chartingTd} sx={{
                            lineHeight: allowEdit() ? '' : '3rem !important',
                        }} align="left">
                            <Box pt={1}>
                                {
                                    allowEdit() ? <CheckboxSelect
                                        disabled={!allowEdit()}
                                        label={<Typography className='fw-medium f-w-600'
                                                           color={palette.common.black}>Title</Typography>}
                                        checkBoxProps={{
                                            name: 'title',
                                            checked: getRows?.length > 0 && selectedFormFinding?.length === getRows?.length,
                                            onChange: (e: any) => handleSelectFinding(e, null),
                                        }}
                                    /> : <Typography className='fw-medium f-w-600'
                                                     color={palette.common.black}>Title</Typography>
                                }
                            </Box>
                        </TableCell>
                        <TableCell className={`f-w-600 fw-medium ${classes.chartingTd}`} align="center"
                                   sx={{lineHeight: allowEdit() ? '' : '3rem !important',}}>Tooth</TableCell>
                        <TableCell className={`f-w-600 fw-medium ${classes.chartingTd}`} align="center"
                                   sx={{lineHeight: allowEdit() ? '' : '3rem !important',}}>Surface</TableCell>
                        <TableCell className={`f-w-600 fw-medium ${classes.chartingTd}`} align="center"
                                   sx={{lineHeight: allowEdit() ? '' : '3rem !important',}}>Subtype</TableCell>
                        {
                            allowEdit() ? <TableCell className={` fw-medium ${classes.chartingTd}`} align="center"
                                                     sx={{lineHeight: allowEdit() ? '' : '3rem !important',}}>Action</TableCell> : null
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {getRows?.map((row: any, index: number) => (
                        <TableRow
                            key={index}
                            sx={{
                                '&:last-child td, &:last-child th': {border: 0},
                                borderBottom: `1px solid ${palette.common.grey}`
                            }}
                        >
                            <TableCell className={classes.chartingTd}
                                       sx={{lineHeight: allowEdit(row?.key) ? '' : '3rem !important'}} component="th"
                                       scope="row">
                                {
                                    allowEdit(row?.key) ? <CheckboxSelect
                                        sx={{margin: getFindingTitle(row)?.length > 15 ? '12px 0px 10px 0px !important' : ''}}
                                        label={getFindingTitle(row) || ''}
                                        checkBoxProps={{
                                            name: row?.key,
                                            checked: selectedFormFinding?.map((sff: any) => JSON.stringify(sff)).includes(JSON.stringify(row)),
                                            value: '',
                                            onChange: (e: any) => handleSelectFinding(e, row),
                                        }}
                                    /> : <Typography
                                        className='f-14 fw-regular labelColor'>{getFindingTitle(row) || ''} </Typography>
                                }
                            </TableCell>
                            <TableCell className={classes.chartingTd}
                                       sx={{lineHeight: allowEdit(row?.key) ? '' : '3rem !important',}} align="left">
                                <Box
                                    sx={{backgroundColor: procedureColors[row?.procedure_status] ? procedureColors[row?.procedure_status]?.bgColor : ''}}
                                    className={classes.toothBox + ` d-flex-all-center`}
                                >
                                    {toothNumberPreferences(row?.tooth_number?.toString())}
                                </Box>
                            </TableCell>
                            <TableCell className={classes.chartingTd}
                                       sx={{lineHeight: allowEdit(row?.key) ? '' : '3rem !important',}} align="center">
                                <Box
                                    justifyContent={"center"}
                                    display={'flex'}>
                                    {(row?.tooth_surface && row?.tooth_surface?.length) ? typeof row?.tooth_surface === 'string' ? stringToArray(row?.tooth_surface) : row?.tooth_surface?.map((surface: string, ind: number) =>
                                        <Box
                                            key={ind}>
                                            {getShortSurface(surface)}{row?.tooth_surface?.length > ind + 1 ? `, ` : ''}
                                        </Box>) : '-'}
                                </Box>
                            </TableCell>
                            <TableCell className={classes.chartingTd}
                                       sx={{lineHeight: allowEdit(row?.key) ? '' : '3rem !important',}}
                                       align="center">{row?.subtype || '-'}</TableCell>
                            {
                                allowEdit(row?.key) ? <TableCell className={classes.chartingTd}
                                                                 sx={{lineHeight: allowEdit(row?.key) ? '' : '3rem !important',}}
                                                                 align="center">
                                    {
                                        row?.key?.includes('missing_tooth') ? <Delete className={'cursor-pointer'}
                                                                                      onClick={() => handleDeleteMissingTooth(row, index)}
                                                                                      fontSize={'small'}/> :
                                            <BorderColor className={'cursor-pointer'}
                                                         onClick={() => openEditFindingPopup(row)}
                                                         fontSize={'small'}/>
                                    }
                                </TableCell> : null
                            }
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            {
                getRows?.length < 1 ?
                    <Box
                        sx={{backgroundColor: palette.common.white}}
                        width={'100%'}
                        py={2}
                        className={'d-flex-all-center'}
                    >
                        <NoDataFound errorMessage={"finding"}/>
                    </Box>
                    : null
            }
        </TableContainer>
    )
        ;
}
