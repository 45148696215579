import { all, call, put, takeEvery } from "redux-saga/effects";
import API from "../../../../utils/api";
import {
    getDentistPreIntakeRequestSuccess,
    getDentistPreIntakeRequestFailure, dentistPreIntakeRequestSuccess, dentistPreIntakeRequestFailure, postDentistPreIntakeDataFailureRequest
} from "../../../actions/v1/preIntakeActions/dentistPreIntakeActions";
import {
    GET_DENTIST_PRE_INTAKE_REQUEST,
    PUT_DENTIST_PRE_INTAKE_REQUEST
} from "../../../ActionTypes/v1/dentist/dentistPreIntake";
import { errorToaster, stringToArray, successToaster } from "../../../../utils/helper";

function* getDentistPreIntakeRequestSaga(payload: any): any {
    try {
        const response = yield API.get(`/preIntakeForm?patientID=${payload.payload.patient_id}`)
        if (response?.data?.data === null) {
            yield put(postDentistPreIntakeDataFailureRequest())
            yield put(
                getDentistPreIntakeRequestSuccess({
                    data: response.data
                })
            );

        } else {
            yield call(payload.payload.callback, response.data);
            const newData = response.data;
            newData.dental_info = stringToArray(newData.dental_info)
            yield put(
                getDentistPreIntakeRequestSuccess({
                    data: response?.data
                })
            );
        }
    } catch (e: any) {
        //TODO: update toast according to status code
        // errorToaster(e?.detail)
        yield put(postDentistPreIntakeDataFailureRequest())
        yield put(
            getDentistPreIntakeRequestFailure({
                error: e?.detail
            })
        );
    }
}

function* dentistPreIntakeRequestSaga(payload: any): any {
    try {
        const response = yield API.put(`/preIntakeForm?patientID=${payload.payload.data.patient_id}`, payload.payload.data)
        yield call(payload.payload.callback, response.data);
        yield put(
            dentistPreIntakeRequestSuccess({
                data: response?.data
            })
        );
        successToaster("The form has been successfully submitted")
    } catch (e: any) {
        errorToaster(e?.detail)
        yield put(
            dentistPreIntakeRequestFailure({
                error: e?.detail
            })
        );
    }
}

function* getDentistPreIntakeSaga() {
    yield takeEvery(GET_DENTIST_PRE_INTAKE_REQUEST, getDentistPreIntakeRequestSaga)
}

function* putDentistPreIntakeSaga() {
    yield takeEvery(PUT_DENTIST_PRE_INTAKE_REQUEST, dentistPreIntakeRequestSaga);
}

export default function* dentistPreIntakeSaga() {
    yield all([
        getDentistPreIntakeSaga(),
        putDentistPreIntakeSaga()
    ])
}
