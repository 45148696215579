import React, {useEffect, useState} from "react";
import {Box, Divider, Grid, IconButton, ListItemIcon, Menu, MenuItem, Typography, useMediaQuery,} from "@mui/material";
import {makeStyles} from "@mui/styles";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import KeyIcon from "@mui/icons-material/Key";
import LogoutIcon from "@mui/icons-material/Logout";
import WidgetsIcon from "@mui/icons-material/Widgets";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import {useDispatch, useSelector} from "react-redux";
import {Link, NavLink, useLocation, useNavigate} from "react-router-dom";
import popUpBg from "../../../assets/images/v1/bgPopUp.png";
import logo from "../../../assets/images/v1/color-logo.png";
import theme from "../../../theme";
import {RootState} from '../../../app/reducers/v1/rootReducer';
import {getPreIntakeDataAction, getPreIntakeDataFailAction} from '../../../app/actions/v2/dentist/preIntakeActions';
import ReminderPopUp from '../../../pages/v1/patient/Appointment/ReminderPopUp';
import {HeathHistoryCompleted} from "../../../utils/v2/clinicalExamConstant";
import ClinicFilterPopUp from "../../v2/common/ClinicSelection/ClinicFilterPopUp";
import {getClinicRequest} from "../../../app/actions/v1/dentist/clinic/clinicActions";
import {getLocalStorageItem, patientProfileData} from "../../../utils/helper";
import MobileVerificationPopUp from "../../v2/common/MobileVerificationPopUp";
import ChangeClinicDropDown from "../../v2/common/ClinicSelection/ChangeClinicDropDown";

const useStyle = makeStyles((theme: any) => ({
    popUpBg: {
        backgroundImage: `url(${popUpBg})`,
        backgroundSize: "100% 100%",
        backgroundColor: "transparent  !important",
    },
    list: {
        display: "flex",
    },
    root: {
        "&$selected": {
            backgroundColor: `${theme.palette.error.main} !important`,
            "&:hover": {
                backgroundColor: "yellow",
            },
        },
    },
    notification: {
        backgroundColor: `${theme.palette.primary.light} !important`,
        minWidth: "unset !important",
        height: "40px",
        width: "40px",
    },
    button: {
        color: `${theme.palette.text.primary} !important`,
        marginLeft: 15,
        marginRight: 15,
        textDecoration: "none",
        textTransform: "none",
        lineHeight: "23px !important",
        fontWeight: "bold !important",
        "&:hover": {
            backgroundColor: "transparent !important",
            color: `${theme.palette.secondary.main} !important`,
            fontWeight: "bold",
        },
    },
    profileButton: {
        color: `${theme.palette.text.primary} !important`,
        fontSize: "18px !important",
        textDecoration: "none",
        textTransform: "none",
        lineHeight: "23px !important",
        "&:hover": {
            backgroundColor: "transparent !important",
            color: `${theme.palette.secondary.main} !important`,
            fontWeight: "bold",
        },
    },
    headerText: {
        color: `${theme.palette.secondary.main} !important`,
        fontSize: "20px !important",
        lineHeight: "25px !important",
        "&:hover": {
            backgroundColor: "transparent !important",
            color: `${theme.palette.secondary.main} !important`,
        },
    },
    activeLink: {
        color: `${theme.palette.secondary.main} !important`,
        marginLeft: 15,
        marginRight: 15,
        textDecoration: "none",
        textTransform: "none",
        lineHeight: "23px !important",
        fontWeight: "bold !important",
    },
    healthHistoryMobile: {
        [theme.breakpoints.down(650)]: {
            fontSize: "14px !important",
        },
        [theme.breakpoints.down(618)]: {
            fontSize: "12px !important",
        },
        [theme.breakpoints.down(600)]: {
            display: 'none !important',
        }
    }

}));

const PatientHeader = (props: any) => {
    const dispatch = useDispatch();
    const history = useNavigate();
    const location = useLocation();
    const [openModel, setOpenModel] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
        useState<null | HTMLElement>(null);
    const [mobileProfileMenu, setMobileProfileMenu] =
        useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const profileOpen = Boolean(mobileProfileMenu);
    const patientUserData = localStorage.getItem('patientUserData')
    const patientAccountData = patientUserData ? JSON.parse(localStorage.getItem('patientUserData') || '') : {}
    const patient_id = patientAccountData?.id;
    const mobileMenuId = "primary-search-account-menu-mobile";
    const classes = useStyle();
    const {preIntakeData, getPreIntakeLoader} = useSelector((state: RootState) => state.preIntake);
    const isPreIntakeCompleted =preIntakeData?.completion_status === HeathHistoryCompleted
    const clinicData = getLocalStorageItem('patientClinic')
    const [openPopUp, setOpenPopup] = useState(!clinicData)
    const [openMobileVerification, setOpenMobileVerification] = useState(false)

    const latestPatientData = localStorage?.getItem('patientClinicInfo')
    const newPatientData = latestPatientData && JSON.parse(latestPatientData)?.profile

    useEffect(() => {
        dispatch(getClinicRequest({staff_id: patient_id}));
    }, []);
    useEffect(() => {
        if (!clinicData) {
            setOpenPopup(true)
        }
    }, [])
    useEffect(() => {
        if (clinicData && !patientAccountData?.is_mobile_verified) {
            setOpenMobileVerification(true)
        }
    }, []);
    useEffect(() => {
        if (preIntakeData === null && clinicData && patientAccountData?.is_mobile_verified) {
            dispatch(getPreIntakeDataAction({patientId: patientProfileData?.id}));
        }
    }, [preIntakeData]);
    //check pre-Intake
    useEffect(() => {
        if (preIntakeData?.completion_status && preIntakeData?.completion_status !== HeathHistoryCompleted && !getPreIntakeLoader && !location.pathname?.includes('/v2/patient/health-history') && !location.pathname?.includes('/patient/update-profile') && !location.pathname?.includes('/patient/change-password')) {
            setOpenModel(preIntakeData?.completion_status !== HeathHistoryCompleted)
        }
    }, [preIntakeData, preIntakeData?.completion_status, getPreIntakeLoader, location.pathname]);

    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
    const handleClose = () => {
        handleMobileMenuClose();
        setAnchorEl(null);
        setMobileProfileMenu(null);
    };
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleMobileProfile = (event: React.MouseEvent<HTMLElement>) => {
        setMobileProfileMenu(event.currentTarget);
    };
    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };
    const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };
    const isSmallScreen = useMediaQuery(theme.breakpoints.down(1100));

    //Logout
    const handleLogout = () => {
        dispatch(getPreIntakeDataFailAction())
        localStorage.removeItem("patientClinic");
        localStorage.removeItem("patientToken");
        localStorage.removeItem("patientUserData");
        localStorage.removeItem("social_login");
        localStorage.removeItem('patientClinicInfo')
        window.location.href = '/patient/login'
    };

    interface MyNavLinkProps {
        to: string;
        text: string;
        disabled?: boolean;

    }

    const MyNavLink: React.FC<MyNavLinkProps> = ({to, text, disabled}) => {
        if (disabled) {
            return <Typography component={'span'} className={`f-18 fw-regular ${classes.button}`}>{text}</Typography>;
        }
        return <NavLink to={to} className={(navData) =>
            `f-18 fw-regular ${navData.isActive ? classes.activeLink : classes.button}`
        }>{text}</NavLink>;
    };
    return (
        <Box sx={{flexGrow: 1, boxShadow: "none !important",}}>
            {/*Desktop NavBar Start*/}
            {openModel && <ReminderPopUp open={openModel} setOpen={setOpenModel}/>}
            {openPopUp && <ClinicFilterPopUp open={openPopUp} setOpen={setOpenPopup} isPatient/>}
            {openMobileVerification &&
                <MobileVerificationPopUp isPatient open={openMobileVerification} setOpen={setOpenMobileVerification}/>}
            <AppBar
                position="static"
                style={{
                    background: theme.palette.secondary.light,
                    color: theme.palette.common.black,
                    justifyContent: "center",
                    paddingTop: "10px",
                    position: "fixed",
                    zIndex: 999,
                    width: "100%",
                    top: 0,
                }}
                sx={{
                    boxShadow: 'none !important'
                }}
            >
                <Toolbar
                    style={{
                        boxShadow: "none !important",
                        alignItems: "center",
                        paddingRight: "0px !important",
                    }}
                >
                    <Link to={`/patient/wellness-profile`}>
                        <Typography sx={{display: {sm: "block"}, alignItems: "center"}}>
                            <img
                                src={logo}
                                className={"cursor-pointer"}
                                alt="logo"
                                onClick={() => {
                                    if (isPreIntakeCompleted) {
                                        history({
                                            pathname: "/patient/wellness-profile",
                                        })
                                    }
                                }
                                }
                            />
                        </Typography>
                    </Link>
                    <Box sx={{flexGrow: 1}}/>

                    <Grid
                        sx={{
                            display: isSmallScreen ? 'none' : 'flex',
                            alignItems: "center",
                            marginRight: "0px !important",
                        }}
                    >
                        <Box>
                            <Grid item display={'flex'} alignItems={'center'}>


                                <MyNavLink to={"/patient/wellness-profile"} text={'OraQ Wellness Profile '}
                                           disabled={!isPreIntakeCompleted}/>
                                <MyNavLink to={"/patient/appointment/upcoming"} text={' Appointments'}
                                           disabled={!isPreIntakeCompleted}/>
                                <NavLink
                                    to={"/v2/patient/health-history"}
                                    replace={true}
                                    className={(navData) =>
                                        `f-18 ${!isPreIntakeCompleted ? 'tabBorder' : ''}  ${navData.isActive ? classes.activeLink : classes.button} `
                                    }
                                >
                                    {!isPreIntakeCompleted && <Box className="circleContainer">
                                        <Box className="circle circle1"></Box>
                                        <Box className="circle circle2"></Box>
                                        <Box className="circle circle3"></Box>
                                    </Box>}
                                    <Box display={'inline-block'}
                                         className={`fw-regular ${!isPreIntakeCompleted ? 'circleDiv' : ''}`}>
                                        Health History
                                    </Box>
                                </NavLink>
                                <Box mr={2.5}>
                                    <ChangeClinicDropDown isPatient/>
                                </Box>
                            </Grid>
                        </Box>

                        <Box
                            mr={2}
                            display={"flex"}
                            alignItems={"center"}
                            justifySelf={"end"}
                        >
                            {/* <Button
                                style={{marginRight: "15px"}}
                                color={"primary"}
                                className={classes.notification}
                            >
                                <Badge color="secondary" variant="dot">
                                    <NotificationsNoneIcon/>
                                </Badge>
                            </Button> */}
                            <Divider
                                sx={{mx: 1, borderStyle: "dashed"}}
                                orientation="vertical"
                                variant="fullWidth"
                                flexItem
                            />
                            <Box>
                                <IconButton
                                    onClick={handleClick}
                                    size="small"
                                    sx={{ml: 2, borderRadius: "5px"}}
                                    aria-controls={open ? "account-menu" : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? "true" : undefined}
                                >
                                    <Box
                                        style={{
                                            borderRadius: "10px",
                                            border: `1px solid ${theme.palette.secondary.light}`,
                                            backgroundColor: theme.palette.grey[400],
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            minHeight: "40px",
                                            minWidth: "40px",
                                            width: "4px",
                                            height: "40px",
                                            overflow: "hidden",
                                        }}
                                    >
                                        {(newPatientData?.profile_picture === "" || newPatientData?.profile_picture === null) ? (
                                            <Typography
                                                className={'fw-regular'}
                                                variant={"subtitle1"}
                                                component={"span"}
                                                style={{
                                                    color: theme.palette.secondary.main,
                                                    fontWeight: 600,
                                                    fontSize: 14,
                                                    margin: "9px",
                                                    textTransform: "uppercase",
                                                }}
                                            >
                                                {newPatientData?.first_name[0]?.charAt(0)}
                                                {newPatientData?.last_name[0]?.charAt(0)}
                                            </Typography>
                                        ) : (
                                            <img
                                                src={newPatientData?.profile_picture}
                                                alt={"user"}
                                                style={{
                                                    height: "40px",
                                                    borderRadius: "5px",
                                                }}
                                            />
                                        )}
                                    </Box>
                                </IconButton>
                            </Box>
                        </Box>
                    </Grid>

                    <Box
                        sx={{display: isSmallScreen ? 'flex' : 'none', alignItems: "center"}}
                    >
                        <Box mr={1}>
                            <ChangeClinicDropDown isPatient/>
                        </Box>
                        {!isPreIntakeCompleted && <NavLink
                            to={"/v2/patient/health-history"}
                            replace={true}
                            className={(navData) =>
                                `${classes.healthHistoryMobile} ${!isPreIntakeCompleted ? 'tabBorderMobile' : ''} ${navData.isActive ? classes.activeLink : classes.button} `
                            }
                        >
                            <Box className="circleContainer">
                                <Box className="circle circle1"></Box>
                                <Box className="circle circle2"></Box>
                                <Box className="circle circle3"></Box>
                            </Box>
                            <Box className={'circleDiv'}>
                                Health History
                            </Box>

                        </NavLink>}
                        {/* <Button color={"primary"} className={classes.notification}>
                            <Badge color="secondary" variant="dot">
                                <NotificationsNoneIcon/>
                            </Badge>
                        </Button> */}
                        <IconButton
                            size="large"
                            aria-label="show more"
                            aria-controls={mobileMenuId}
                            aria-haspopup="true"
                            onClick={handleMobileMenuOpen}
                            color="inherit"
                        >
                            <WidgetsIcon/>
                        </IconButton>
                    </Box>
                </Toolbar>
            </AppBar>
            {/*Desktop NavBar End*/}

            {/*Mobile NavBar Start*/}
            <Menu
                sx={{
                    "& .MuiPaper-root": {
                        width: "100% !important",
                        marginTop: "10px",
                        left: "0 !important",
                        minWidth: "100%",
                        maxWidth: "100%",
                        backgroundColor: `${theme.palette.secondary.light} !important`,
                    },
                    "& .MuiMenuItem-root": {
                        "&:hover": {
                            color: theme.palette.secondary.main,
                            backgroundColor: theme.palette.secondary.light,
                        },
                    },
                    "& .MuiList-root": {
                        backgroundColor: theme.palette.common.white,
                        borderRadius: "10px",
                        paddingTop: "0",
                        paddingBottom: 0,
                    },
                }}
                anchorEl={mobileMoreAnchorEl}
                id={mobileMenuId}
                keepMounted
                open={isMobileMenuOpen}
                onClose={handleMobileMenuClose}
                className={'fw-regular'}
            >
                <MenuItem
                    className={'fw-regular'}
                    sx={{
                        height: "50px",
                        backgroundColor: theme.palette.secondary.light,
                        marginTop: "1px",
                        marginBottom: "1px",
                        display: "flex",
                        alignItems: "center",
                    }}
                    onClick={() => {
                        if (isPreIntakeCompleted) {
                            history({
                                pathname: "/patient/wellness-profile",
                            })
                        }
                    }}
                >
                    <MyNavLink to={"/patient/wellness-profile"} text={'OraQ Wellness Profile'}
                               disabled={!isPreIntakeCompleted}/>
                </MenuItem>
                <MenuItem
                    sx={{
                        height: "50px",
                        backgroundColor: theme.palette.secondary.light,
                        marginTop: "1px",
                        marginBottom: "1px",
                        display: "flex",
                        alignItems: "center",
                    }}
                    className={'fw-regular'}
                    onClick={() => {
                        if (isPreIntakeCompleted) {
                            history({
                                pathname: "/patient/appointment/upcoming",
                            })
                        }
                    }
                    }
                >
                    <MyNavLink to={"/patient/appointment/upcoming"} text={'Appointments'}
                               disabled={!isPreIntakeCompleted}/>
                </MenuItem>
                <MenuItem
                    className={'fw-regular'}
                    sx={{
                        height: "50px",
                        backgroundColor: theme.palette.secondary.light,
                        marginTop: "1px",
                        marginBottom: "1px",
                        display: "flex",
                        alignItems: "center",
                    }}
                    onClick={() =>
                        history({
                            pathname: "/v2/patient/health-history",
                        })
                    }
                >
                    <NavLink
                        to={"/v2/patient/health-history"}
                        replace={true}
                        className={(navData) =>
                            `f-18 fw-regular ${navData.isActive ? classes.activeLink : classes.button}`
                        }
                    >
                        Health History
                    </NavLink>
                </MenuItem>
                <MenuItem
                    className={'fw-regular'}
                    onClick={handleMobileProfile}
                    sx={{
                        height: "50px",
                        backgroundColor: theme.palette.secondary.light,
                        marginTop: "1px",
                        marginBottom: "1px",
                        display: "flex",
                        alignItems: "center",
                    }}
                >
                    <Link
                        to={""}
                        onClick={handleMobileProfile}
                        className={` fw-regular f-18 ${classes.button}`}
                    >
                        My Profile
                    </Link>
                </MenuItem>
            </Menu>
            {/*Mobile NavBar End*/}

            {/*Profile Popup Start (Desktop)*/}
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                MenuListProps={{
                    sx: {
                        padding: 0,
                    },
                }}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                        mt: 1.5,
                        "& .MuiMenuItem-root": {
                            "&:hover": {
                                color: theme.palette.secondary.main,
                                backgroundColor: theme.palette.common.white,
                            },
                        },
                        "& .MuiList-root": {
                            backgroundColor: theme.palette.common.white,
                            borderRadius: "10px",
                        },
                        "& .MuiListItemIcon-root": {
                            "&:hover": {
                                color: theme.palette.secondary.main,
                            },
                        },
                        "&:before": {
                            content: '""',
                            display: "block",
                            position: "absolute",
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: theme.palette.secondary.main,
                            transform: "translateY(-50%) rotate(45deg)",
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{horizontal: "right", vertical: "top"}}
                anchorOrigin={{horizontal: "right", vertical: "bottom"}}
            >
                <Box className={classes.popUpBg} padding="10px 20px">
                    <Typography
                        color={theme.palette.common.white}
                        fontWeight={400}
                        lineHeight="20px"
                        fontSize="16px"
                        className={'fw-regular'}
                    >
                        {patientProfileData?.first_name}
                        {patientProfileData?.last_name}
                    </Typography>
                    <Typography
                        className={'fw-regular'}
                        color={theme.palette.common.white}
                        fontWeight={400}
                        lineHeight="15px"
                        fontSize="12px"
                        sx={{opacity: 0.5}}
                    >
                        {patientProfileData?.email}
                    </Typography>
                </Box>

                <Divider
                    sx={{borderBottom: ` 2px solid ${theme.palette.primary.main} !important`, marginTop: "2px"}}
                />
                <Link
                    className={'fw-regular'}
                    to={"/patient/update-profile"}
                    replace={true}
                    style={{
                        textDecoration: "none",
                        color: theme.palette.common.black,
                    }}
                >
                    {" "}
                    <MenuItem className={'fw-regular'}>
                        <ListItemIcon
                            sx={{
                                backgroundColor: theme.palette.secondary.light,
                                height: 30,
                                width: 30,
                                borderRadius: "5px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                marginRight: "10px",
                            }}
                        >
                            <PersonOutlineIcon/>
                        </ListItemIcon>
                        My Profile
                    </MenuItem>
                </Link>
                <Link
                    to={"/patient/change-password"}
                    replace={true}
                    style={{
                        textDecoration: "none",
                        color: theme.palette.common.black,
                    }}
                    className={'fw-regular'}
                >
                    {" "}
                    <MenuItem className={'fw-regular'}>
                        <ListItemIcon
                            sx={{
                                backgroundColor: theme.palette.secondary.light,
                                height: 30,
                                width: 30,
                                borderRadius: "5px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                marginRight: "10px",
                            }}
                        >
                            <KeyIcon/>
                        </ListItemIcon>
                        Change Password
                    </MenuItem>
                </Link>
                <MenuItem onClick={handleLogout} className={'fw-regular'}>
                    <ListItemIcon
                        sx={{
                            backgroundColor: theme.palette.secondary.light,
                            height: 30,
                            width: 30,
                            borderRadius: "5px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginRight: "10px",
                        }}
                    >
                        <LogoutIcon/>
                    </ListItemIcon>
                    Logout
                </MenuItem>
            </Menu>
            {/*Profile Popup End (Desktop)*/}

            {/*Profile Popup Start (Mobile)*/}
            <Menu
                sx={{
                    "& .MuiPaper-root": {
                        width: "100% !important",
                        marginTop: "3px",
                        left: "0 !important",
                        minWidth: "100%",
                        maxWidth: "100%",
                        backgroundColor: `${theme.palette.secondary.light} !important`,
                    },
                    "& .MuiMenuItem-root": {
                        "&:hover": {
                            color: theme.palette.secondary.main,
                            backgroundColor: theme.palette.secondary.light,
                        },
                    },
                    "& .MuiList-root": {
                        backgroundColor: theme.palette.common.white,
                        borderRadius: "10px",
                        paddingTop: "0",
                        paddingBottom: 0,
                    },
                    "& .MuiListItemIcon-root": {
                        "&:hover": {
                            color: theme.palette.secondary.main,
                        },
                    },
                }}
                anchorEl={mobileProfileMenu}
                id="profile-menu"
                open={profileOpen}
                MenuListProps={{
                    sx: {
                        padding: 0,
                    },
                }}
                onClose={handleClose}
                onClick={handleClose}
                transformOrigin={{horizontal: "right", vertical: "top"}}
                anchorOrigin={{horizontal: "right", vertical: "bottom"}}
            >
                <Link
                    to={"/patient/update-profile"}
                    replace={true}
                    style={{
                        textDecoration: "none",
                        color: theme.palette.text.primary,
                    }}
                    className={'fw-regular'}
                >
                    <MenuItem
                        className={` fw-regular ${classes.profileButton}`}
                        sx={{
                            height: "50px",
                            backgroundColor: theme.palette.secondary.light,
                            marginTop: "1px",
                            marginBottom: "1px",
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        <ListItemIcon
                            sx={{
                                backgroundColor: theme.palette.secondary.light,
                                height: 30,
                                width: 30,
                                borderRadius: "5px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                marginRight: "10px",
                            }}
                        >
                            <PersonOutlineIcon/>
                        </ListItemIcon>
                        My Profile
                    </MenuItem>
                </Link>
                <Link
                    to={"/patient/change-password"}
                    replace={true}
                    style={{
                        textDecoration: "none",
                        color: theme.palette.text.primary,
                    }}
                >
                    <MenuItem
                        className={` fw-regular ${classes.profileButton}`}
                        sx={{
                            height: "50px",
                            backgroundColor: theme.palette.secondary.light,
                            marginTop: "1px",
                            marginBottom: "1px",
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        <ListItemIcon
                            sx={{
                                backgroundColor: theme.palette.secondary.light,
                                height: 30,
                                width: 30,
                                borderRadius: "5px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                marginRight: "10px",
                            }}
                        >
                            <KeyIcon/>
                        </ListItemIcon>
                        Change Password
                    </MenuItem>
                </Link>
                <MenuItem
                    className={` fw-regular ${classes.profileButton}`}
                    sx={{
                        height: "50px",
                        backgroundColor: theme.palette.secondary.light,
                        marginTop: "1px",
                        marginBottom: "1px",
                        display: "flex",
                        alignItems: "center",
                    }}
                    onClick={handleLogout}
                >
                    <ListItemIcon
                        sx={{
                            backgroundColor: theme.palette.secondary.light,
                            height: 30,
                            width: 30,
                            borderRadius: "5px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginRight: "10px",
                        }}
                    >
                        <LogoutIcon/>
                    </ListItemIcon>
                    Logout
                </MenuItem>
            </Menu>
            {/*Profile Popup End (Mobile)*/}
        </Box>
    );
};
export default PatientHeader;
