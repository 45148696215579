
export const grade = [
    { label: "None", value: "None" },
    { label: "Mild (< 5 mm)", value: "Mild (< 5 mm)" },
    {
      label: "Moderate (>= 5 mm, < 10 mm)",
      value: "Moderate (>= 5 mm, < 10 mm)",
    },
    { label: "Severe (>= 10 mm)", value: "Severe (>= 10 mm)" },
  ];

  export const defaultCrowdingMandibular = [
    {
      name: "grade",
      value: "",
      option: grade,
      type: "select",
      title: "Grade",
      dataType: "string",
    },
  ];
export const defaultCrowdingMaxillary = [
    {
      name: "grade",
      value: "",
      option: grade,
      type: "select",
      title: "Grade",
      dataType: "string",
    },
  ];
 export const defaultSpacingMaxillary = [
    {
      name: "grade",
      value: "",
      option: grade,
      type: "select",
      title: "Grade",
      dataType: "string",
    },
  ];
 export const defaultSpacingMandibular = [
    {
      name: "grade",
      value: "",
      option: grade,
      type: "select",
      title: "Grade",
      dataType: "string",
    },
  ];