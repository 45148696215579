import {patientListActions, PatientListInterface} from "../../../types/v2/dentist/patientsList";
import {
    GET_PATIENT_LIST_REQUEST,
    GET_PATIENT_LIST_REQUEST_FAILURE,
    GET_PATIENT_LIST_REQUEST_SUCCESS
} from "../../../ActionTypes/v2/dentist/patientsList";

const initialState: PatientListInterface = {
    getPatientLoader: false,
    getPatientData: null,
    getPatientError: null,
    getPatientMeta: null
}
const patientListReducer = (state = initialState, action: patientListActions) => {
    switch (action.type) {

        case GET_PATIENT_LIST_REQUEST:
            return {
                ...state,
                getPatientLoader: true,
            }
        case GET_PATIENT_LIST_REQUEST_SUCCESS:
            return {
                ...state,
                getPatientLoader: false,
                getPatientData: action.payload?.getPatientData,
                getPatientMeta: action.payload?.getPatientMeta,
                getPatientError: null,
            }
        case GET_PATIENT_LIST_REQUEST_FAILURE:
            return {
                ...state,
                getPatientLoader: false,
                getPatientData: null,
                getPatientError: action.payload.getPatientError,
            }
        default:
            return {
                ...state,
            }
    }
}
export default patientListReducer