import {
  DISCLAIMER_ACCEPT_REQUEST,
  DISCLAIMER_ACCEPT_REQUEST_SUCCESS,
  DISCLAIMER_ACCEPT_REQUEST_FAILURE
} from '../../../ActionTypes/v2/auth';
import { disclaimerActions, disclaimerInterface } from '../../../types/v2/authActionTypes/disclaimerActionTypes';

const initialState: disclaimerInterface = {
  disclaimerLoader: false,
  disclaimerData: null,
  disclaimerError: null
};

const disclaimerReducer = (state = initialState, action: disclaimerActions) => {
  switch (action.type) {
    case DISCLAIMER_ACCEPT_REQUEST:
      return {
        ...state,
        disclaimerLoader: true
      };
    case DISCLAIMER_ACCEPT_REQUEST_SUCCESS:
      return {
        ...state,
        disclaimerLoader: false,
        disclaimerData: action.payload,
        disclaimerError: null
      };
    case DISCLAIMER_ACCEPT_REQUEST_FAILURE:
      return {
        ...state,
        disclaimerLoader: false,
        disclaimerData: null,
        disclaimerError: action.payload
      };

    default:
      return {
        ...state
      };
  }
};
export default disclaimerReducer;
