import {
  UPDATE_EXTRA_ORAL,
  CHANGE_EXTRA_ORAL,
  UPDATE_EXTRA_ORAL_FAILURE,
} from "../../../../ActionTypes/v1/dentist/clinicalExamAssistant";
import { ExtraOralAssistantInterface } from "../../../../types/dentistTypes";

const initialState: ExtraOralAssistantInterface = {
  data: {
    bruxism_clenching: "No",
    bruxism_clenching_other: "",
    curr_nightguard: "No",
    num_nightgaurd_lifetime: null,
    nightguard_use_per_week: null,
    hx_ortho_tx: "No treatment",
    hx_ortho_tx_other: "",
    ortho_tx_age: null,
    ortho_tx_type: "",
  }
};

export const extraOralReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case UPDATE_EXTRA_ORAL:
      return {
        ...state,
        data: action.payload,
        cancelData: action.payload,
      };
    case UPDATE_EXTRA_ORAL_FAILURE:
      return {
        data: {
          bruxism_clenching: "No",
          bruxism_clenching_other: "",
          curr_nightguard: "No",
          num_nightgaurd_lifetime: null,
          nightguard_use_per_week: null,
          hx_ortho_tx: "No treatment",
          hx_ortho_tx_other: "",
          ortho_tx_age: null,
          ortho_tx_type: "",
        },
      };

    case CHANGE_EXTRA_ORAL:
      const temp = state.data;
      const { key, value } = action.payload as any;
      temp[key] = value;
      return {
        ...state,
        data: temp,
      };
    default:
      return { ...state };
  }
};
