import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, Divider, Typography, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import QIcon from '../../../../assets/images/v2/QIcon.png'
import theme from "../../../../theme";

const useStyles = makeStyles((theme: any) => ({
    DialogActionsClass: {
        padding: '16px 20px',
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between'
    },
    closeButton: {
        padding: '0px !important',
        display: 'flex',
        justifyContent: 'flex-end',
        backgroundColor: 'transparent',
        color: theme.palette.common.black,
        '&:hover': {
            backgroundColor: theme.palette.common.white,
            boxShadow: 'none'
        }
    },
    dialogContent: {
        width: '100% !important',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center'
    },
    verifyButton: (isPatient: any) => ({
        height: '40px',
        padding: '10px 20px !important',
        minWidth: '100px',
        borderRadius: '4px',
        background: isPatient ? theme.palette.secondary.main : theme.palette.v2.primary.main,
        color: theme.palette.common.white,
        textDecoration: 'none',
        textAlign: 'center',
        '&:hover': {
            background: isPatient ? theme.palette.secondary.main : theme.palette.v2.primary.main,
            color: theme.palette.common.white
        }
    }),
    verifyButtonContainer: {
        padding: '12px 20px',
    },
    cancelBtn: (isPatient: any) => ({
        height: '40px',
        padding: '8.5px 20px',
        border: `1px solid ${isPatient ? theme.palette.secondary.main : theme.palette.v2.primary.main}`,
        color: isPatient ? theme.palette.secondary.main : theme.palette.v2.primary.main,
        backgroundColor: theme.palette.common.white,
        '&:hover': {
            backgroundColor: theme.palette.common.white,
            borderColor: isPatient ? theme.palette.secondary.main : theme.palette.v2.primary.main
        }
    }),
    logoutBtn: {
        height: '40px',
        padding: '8.5px 20px',
        border: `1px solid ${theme.palette.error.main}`,
        color: theme.palette.error.main,
        backgroundColor: theme.palette.common.white,
        '&:hover': {
            backgroundColor: theme.palette.common.white,
            borderColor: theme.palette.error.main
        }
    }
}));

type Props = {
    open: boolean;
    headerAction: boolean
    footerAction: boolean
    width: string
    handleClose?: any
    handleSave?: any
    children: React.ReactNode
    saveBtnText?: string
    heading?: string | undefined
    isOraqIcon?: boolean | undefined
    cancelBtnText?: string
    isPatient?: boolean | undefined
    disabledbtn?: boolean | undefined
    notDisplayCancel?: boolean | undefined
    height?: any
    fromImageXrayPopUp?: boolean | undefined
    notDisplayCancelIcon?: boolean | undefined
    isLogoutOnSave?: boolean | undefined
    isLogoutOncancel?: boolean | undefined
    isQSummary?: boolean | undefined
    otherBtn?: any
    btnLoader?: boolean | undefined
};

const PopupComponent = (props: Props) => {
    const {
        open,
        handleClose,
        handleSave,
        children,
        saveBtnText,
        headerAction,
        footerAction,
        width,
        heading,
        isOraqIcon,
        cancelBtnText,
        isPatient,
        disabledbtn,
        notDisplayCancel,
        height,
        fromImageXrayPopUp,
        notDisplayCancelIcon,
        isLogoutOnSave,
        isLogoutOncancel,
        isQSummary,
        otherBtn,
        btnLoader
    } = props;
    const classes = useStyles(!!isPatient);
    const isSmallScreen = useMediaQuery(theme.breakpoints.down(500));
    const btnClick = () => {
        const { funcCall } = otherBtn
        funcCall()
    }
    return (
        <Dialog
            sx={{
                '& .MuiDialogContent-root': {
                    width: '100% !important',
                    padding: (isSmallScreen && fromImageXrayPopUp) ? '2px' : isQSummary ? '0' : '12px 20px'
                },
                '& .MuiPaper-root ': {
                    width: width,
                    maxWidth: width,
                    height: height,
                    background: theme.palette.common.white,
                    boxShadow: ' 0px 10px 20px rgba(0, 0, 0, 0.07)',
                    borderRadius: '10px !important',
                    display: 'flex !important',
                    alignItems: 'center !important',
                    justifyContent: 'center !important'
                }
            }}
            open={open}
            onClose={handleClose}
        >
            {headerAction && <DialogActions className={classes.DialogActionsClass}>
                <Box className='d-flex align-center'>
                    {isOraqIcon && <img src={QIcon} width={20} height={20} alt={'Q'} />}
                    <Typography ml={1} className='f-14 f-w-500 fw-medium'> {heading}</Typography>
                </Box>
                {!notDisplayCancelIcon && <Button onClick={handleClose} className={classes.closeButton} disableRipple>
                    <FontAwesomeIcon
                        icon={faClose}
                        color={theme.palette.common.black50}
                        className='f-20'
                        width={'20px'}
                        height={'20px'}
                    />
                </Button>}
            </DialogActions>}
            {headerAction && <Divider sx={{ width: '100%', borderColor: theme.palette.divider }} />}
            <DialogContent sx={{ overflow: fromImageXrayPopUp ? 'hidden' : 'auto' }}
                className={`${fromImageXrayPopUp ? 'd-flex-all-center' : ''}`}>
                {children}
            </DialogContent>
            {footerAction && <Divider sx={{ width: '100%', borderColor: theme.palette.divider }} />}
            {footerAction &&
                <DialogActions
                    sx={{
                        justifyContent: notDisplayCancel ? 'center' : 'space-between !important'
                    }}
                    className={`d-flex ${classes.verifyButtonContainer} w-100`}>
                    {!notDisplayCancel &&
                        <Button variant={isLogoutOncancel ? 'outlined' : 'contained'} onClick={handleClose}
                                className={`fw-regular ${isLogoutOncancel ? classes.logoutBtn : classes.cancelBtn}`}>
                            {cancelBtnText}
                        </Button>}
                    <Box>
                        {otherBtn?.isBtnDisplay && <Button
                            sx={{ marginRight: '10px' }}
                            variant={'outlined'} onClick={btnClick}
                            className={`fw-regular ${classes.cancelBtn}`}>
                            {otherBtn?.btnText}
                        </Button>}
                        <Button
                            sx={{ marginLeft: (notDisplayCancel || otherBtn?.isBtnDisplay) ? "0px" : '35px', }}
                            onClick={handleSave} disabled={disabledbtn}
                            className={`fw-regular ${isLogoutOnSave ? classes.logoutBtn : classes.verifyButton}`}
                            variant={isLogoutOnSave ? 'outlined' : 'contained'}
                            style={{ textTransform: 'capitalize' }}
                        >
                            {btnLoader ? <CircularProgress color={isPatient ? 'secondary' : 'primary'} size={19} /> : saveBtnText}
                        </Button>
                    </Box>

                </DialogActions>}
        </Dialog>
    );
};

export default PopupComponent;
