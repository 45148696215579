//notification data
export const GET_NOTIFICATION_REQUEST = 'GET_NOTIFICATION_REQUEST-';
export const GET_NOTIFICATION_SUCCESS = 'GET_NOTIFICATION_SUCCESS';
export const GET_NOTIFICATION_FAILURE = 'GET_NOTIFICATION_FAILURE';
export const GET_NOTIFICATION_CACHE_DATA_CLEAR_REQUEST = 'GET_NOTIFICATION_CACHE_DATA_CLEAR_REQUEST';

//notification count data
export const GET_NOTIFICATION_COUNT_REQUEST = 'GET_NOTIFICATION_COUNT_REQUEST-';
export const GET_NOTIFICATION_COUNT_SUCCESS = 'GET_NOTIFICATION_COUNT_SUCCESS';
export const GET_NOTIFICATION_COUNT_FAILURE = 'GET_NOTIFICATION_COUNT_FAILURE';
export const GET_NOTIFICATION_COUNT_CACHE_DATA_CLEAR_REQUEST = 'GET_NOTIFICATION_COUNT_CACHE_DATA_CLEAR_REQUEST';

//update notification status
export const PUT_NOTIFICATION_REQUEST = 'PUT_NOTIFICATION_REQUEST-';
export const PUT_NOTIFICATION_SUCCESS = 'PUT_NOTIFICATION_SUCCESS';
export const PUT_NOTIFICATION_FAILURE = 'PUT_NOTIFICATION_FAILURE';
