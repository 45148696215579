import {
  DISCLAIMER_ACCEPT_REQUEST,
  DISCLAIMER_ACCEPT_REQUEST_SUCCESS,
  DISCLAIMER_ACCEPT_REQUEST_FAILURE
} from '../../../ActionTypes/v2/auth';
import {
  DisclaimerRequest,
  DisclaimerRequestFailure,
  DisclaimerRequestFailurePayload,
  DisclaimerRequestPayload,
  DisclaimerRequestSuccess,
  DisclaimerRequestSuccessPayload
} from '../../../types/v2/authActionTypes/disclaimerActionTypes';

export const disclaimerRequest = (payload: DisclaimerRequestPayload): DisclaimerRequest => ({
  type: DISCLAIMER_ACCEPT_REQUEST,
  payload
});

export const disclaimerSuccess = (payload: DisclaimerRequestSuccessPayload): DisclaimerRequestSuccess => ({
  type: DISCLAIMER_ACCEPT_REQUEST_SUCCESS,
  payload
});

export const disclaimerFailure = (payload: DisclaimerRequestFailurePayload): DisclaimerRequestFailure => ({
  type: DISCLAIMER_ACCEPT_REQUEST_FAILURE,
  payload
});
