import React from 'react';
import CreatePasswordDentist from '../pages/v1/dentist/auth/CreatePassword';
import CreatePasswordPatient from '../pages/v1/patient/auth/CreatePassword';
import {AppointmentLayout} from '../components/v2/common/layouts/AppointmentLayout';
import {MainLayout} from '../components/v2/common/layouts/MainLayout';
import PatientWellnessProfile from '../pages/v2/patient/WellnessProfile';
import SendOtpScreen from '../pages/v2/patient/VerificationsWithoutAccount/EmailVerification/SendOtpScreen';
import VerifyOtpScreen from '../pages/v2/patient/VerificationsWithoutAccount/EmailVerification/VerifyOtpScreen';
import AccessCodeScreen from '../pages/v2/patient/VerificationsWithoutAccount/QrCodeVerification/AccessCodeScreen';
import PatientRegister from '../pages/v2/patient/VerificationsWithoutAccount/PatientRegister';
import AccessHealthHistory from '../pages/v2/patient/VerificationsWithoutAccount/AccessHealthHistoryWithoutAccount';
import SubmitHealthHistoryPage
    from '../pages/v2/patient/VerificationsWithoutAccount/AccessHealthHistoryWithoutAccount/SubmitHealthHistoryPage';
import MobileAndDateScreen
    from '../pages/v2/patient/VerificationsWithoutAccount/QrCodeVerification/MobileAndDateScreen';
import PatientWellnessProfileWithoutAccount from '../pages/v2/patient/WellnessProfileWithoutAccount';
import SessionExpire from '../pages/v2/patient/WellnessProfileWithoutAccount/SessionExpire';
import PatientForgotUserName from '../pages/v2/patient/auth/ForgotUserName';

const version = process.env.REACT_APP_VERSION;

const Home = React.lazy(() => import('../pages/Home'));
const Unsubscribe = React.lazy(() => (import('../pages/UnsubscribeEmail/index')))

const DentistForgotPassword = React.lazy(() => import('../pages/v1/dentist/auth/ForgotPassword'));
const PatientForgotPassword = React.lazy(() => import('../pages/v1/patient/auth/ForgotPassword'));
const PatientRecoverPassword = React.lazy(() => import('../pages/v1/patient/auth/RecoverPassword'));
const DentistRecoverPassword = React.lazy(() => import('../pages/v1/dentist/auth/RecoverPassword'));
const PatientPhotos = React.lazy(() => import('../components/v1/Dentist/Appointment/InsuranceApproval/PatientPhotos'));
const DentistLogin = React.lazy(() => import('../pages/v1/dentist/auth/Login'));
const PatientLogin = React.lazy(() => import('../pages/v1/patient/auth/Login'));
const UpdateProfile = React.lazy(() => import('../pages/v1/patient/patientProfile/UpdateProfile'));
const PatientChangePassword = React.lazy(() => import('../pages/v1/patient/patientProfile/ChangePassword'));
const DentistChangePassword = React.lazy(() => import('../pages/v1/dentist/dentistProfile/ChangePassword'));
const Appointment = React.lazy(() => import('../pages/v1/patient/Appointment/Appointment'));
const AppointmentDetails = React.lazy(() => import('../pages/v1/patient/Appointment/AppointmentDetails'));
const DentistAppointmentOld = React.lazy(() => import('../pages/v1/dentist/Appointment/Appointment'));
const DentistAppointment = React.lazy(() => import('../pages/v2/dentist/Appointments'));
const PageNotFound = React.lazy(() => import('../components/v2/common/PageNotFound'));
const ClinicalExamOld = React.lazy(() => import('../pages/v1/dentist/Appointment/ClinicalExam'));
const EditImage = React.lazy(() =>
    import('../components/v1/Dentist/Appointment/ClinicExamDentist/RadiographicAnalysisAndFindings/EditImage')
);
const PatientSnapShot = React.lazy(() => import('../pages/v1/dentist/Appointment/PatientSnapShot'));
const RiskProfileSnapShotSummary = React.lazy(() => import('../pages/v1/patient/riskProfile/snapshotSummary'));
const ViewAllXrayOrImage = React.lazy(() =>
    import('../components/v1/Dentist/Appointment/AssignedTreatment/ViewAllXrayOrImage')
);
const UpdateProfileDentist = React.lazy(() => import('../pages/v1/dentist/dentistProfile/UpdateProfileDentist'));
const DentistDashboard = React.lazy(() => import('../pages/v2/dentist/Dashboard'));

const DentistTermsConditionAndPrivacyPolicy = React.lazy(() =>
    import('../pages/v1/dentist/auth/TermsConditionAndPrivacyPolicy')
);
const PatientTermsConditionAndPrivacyPolicy = React.lazy(() =>
    import('../pages/v1/patient/auth/TermsConditionAndPrivacyPolicy')
);
const TermsConditionsStaticPage = React.lazy(() => import('../pages/v1/auth/TermsConditionsStaticPage'));
const PrivacyPolicyStaticPage = React.lazy(() => import('../pages/v1/auth/PrivacyPolicyStaticPage'));
const PatientLandingPage = React.lazy(() => import('../pages/v1/patient/PatientLanding'));
const StaffLandingPage = React.lazy(() => import('../pages/v1/dentist/StaffLanding'));
const DentistProfile = React.lazy(() => import('../pages/v2/dentist/DentistProfile'));
const Analytics = React.lazy(() => import('../pages/v2/dentist/Analytics'));
const TermsOfUse = React.lazy(() => import('../pages/v2/dentist/TermsOfUse'));
const PrivacyPolicy = React.lazy(() => import('../pages/v2/dentist/PrivacyPolicy'));
const Notification = React.lazy(() => import('../pages/v2/dentist/Notification'));
const Resources = React.lazy(() => import('../pages/v2/dentist/Resources'));

const StaffLogin = React.lazy(() => import('../pages/v2/dentist/auth/Login'));
const StaffForgotPassword = React.lazy(() => import('../pages/v2/dentist/auth/ForgotPassword'));
const StaffVerifyMobile = React.lazy(() => import('../pages/v2/dentist/auth/VerifyMobile'));
const StaffTermsConditions = React.lazy(() => import('../pages/v2/dentist/auth/TermsConditions'));
const StaffPrivacyPolicy = React.lazy(() => import('../pages/v2/dentist/auth/PrivacyPolicy'));
const StaffCreatePassword = React.lazy(() => import('../pages/v2/dentist/auth/CreatePassword'));
const Disclaimer = React.lazy(() => import('../pages/v2/dentist/auth/Disclaimer'));
const StaffResetPassword = React.lazy(() => import('../pages/v2/dentist/auth/ResetPassword'));
const StaffChangePassword = React.lazy(() => import('../pages/v2/dentist/auth/ChangePassword'));
const PreIntake = React.lazy(() => import('../pages/v2/dentist/PreIntake'));
const PatientPreIntake = React.lazy(() => import('../pages/v2/patient/PreIntake'));
const ClinicalExam = React.lazy(() => import('../pages/v2/dentist/ClinicalExam'));
const WellnessProfile = React.lazy(() => import('../pages/v2/dentist/WellnessProfile'));
const LandingPage = React.lazy(() => import('../pages/v2/landingPage'));
const PatientProfile = React.lazy(() => import('../pages/v2/patient/PatientProfile'));
const AnonymousPreIntake = React.lazy(() => import('../pages/AnonymousPreIntake/Index'));
const SecondScreen = React.lazy(() => import('../pages/v2/dentist/SecondScreen'));

const PatientCommunications = React.lazy(() => import('../pages/v1/patient/auth/Communications'));
const DentistCommunications = React.lazy(() => import('../pages/v2/dentist/auth/Communications'));


const PatientsList = React.lazy(() => import('../pages/v2/dentist/PatientsList'));

const v1Paths = [
    {
        path: '/home',
        name: 'Home',
        exact: true,
        component: <Home/>,
        private: false
    },
    //Patient Routes
    {
        path: '/patient/login',
        name: 'Patient Login',
        exact: true,
        component: <PatientLogin/>,
        private: false
    },
    {
        path: '/patient/forgot-password',
        name: 'Patient Forgot password',
        exact: true,
        component: <PatientForgotPassword/>,
        private: false
    },
    {
        path: '/patient/forgot-username',
        name: 'Patient Forgot username',
        exact: true,
        component: <PatientForgotUserName />,
        private: false
    },
    {
        path: '/patient/recover-password/:recovery_token',
        name: 'Patient Recover password',
        exact: true,
        component: <PatientRecoverPassword/>,
        private: false
    },
    {
        path: '/patient/change-password',
        name: 'Patient Change password',
        exact: true,
        component: <PatientChangePassword/>,
        private: true
    },

    {
        path: '/patient/update-profile',
        name: 'Patient Update Profile',
        exact: true,
        component: version === 'v2' ? <PatientProfile/> : <UpdateProfile/>,
        private: true
    },
    {
        path: '/patient/appointment/:status',
        name: 'Patient upcoming Appointment',
        exact: true,
        component: <Appointment/>,
        private: true
    },
    {
        path: '/patient/appointment/:status/:appointmentID',
        name: 'Patient Appointment Details',
        exact: true,
        component: <AppointmentDetails/>,
        private: true
    },
    {
        path: '/patient/wellness-profile',
        name: 'Patient RiskProfile',
        exact: true,
        component: <PatientWellnessProfile/>,
        private: true
    },
    // {
    //   path: '/patient/risk-profile/:appointmentID',
    //   name: 'Patient RiskProfile',
    //   exact: true,
    //   component: <RiskProfile />,
    //   private: true
    // },
    {
        path: '/patient/appointment/snapshot/:appointmentID',
        name: 'Patient RiskProfile snapshot summary',
        exact: true,
        component: <RiskProfileSnapShotSummary/>,
        private: true
    },
    {
        path: '/patient/appointment/snapshot/view-all/:appointmentID/:imagetype',
        name: 'Patient RiskProfile snapshot summary',
        exact: true,
        component: <ViewAllXrayOrImage/>,
        private: true
    },
    {
        path: '/patient/create-password',
        name: 'Patient Create Password',
        exact: true,
        component: <CreatePasswordPatient/>,
        private: true
    },
    {
        path: '/patient/terms-and-condition',
        name: 'Patient Terms Condition and Privacy Policy',
        exact: true,
        component: <PatientTermsConditionAndPrivacyPolicy/>,
        private: true
    },

    //dentist Routes
    {
        path: '/dentist/login',
        name: 'Dentist Login',
        exact: true,
        component: <DentistLogin/>,
        private: false
    },
    {
        path: '/dentist/create-password',
        name: 'Dentist Create Password',
        exact: true,
        component: <CreatePasswordDentist/>,
        private: true
    },
    {
        path: '/dentist/forgot-password',
        name: 'Dentist Forgot password',
        exact: true,
        component: <DentistForgotPassword/>,
        private: false
    },
    {
        path: '/dentist/recover-password/:recovery_token',
        name: 'Dentist Recover password',
        exact: true,
        component: <DentistRecoverPassword/>,
        private: false
    },
    {
        path: '/dentist/change-password',
        name: 'Dentist Change Password',
        exact: true,
        component: <DentistChangePassword/>,
        private: true
    },
    {
        path: '/dentist/update-profile',
        name: 'Dentist Update Profile',
        exact: true,
        component: <UpdateProfileDentist/>,
        private: true
    },
    {
        path: '/dentist/appointment',
        name: 'Dentist Pre Appointment',
        exact: true,
        component: <DentistAppointmentOld/>,
        private: true
    },
    {
        path: `dentist/appointment/clinical-exam/:appointmentID/:patientID`,
        name: 'Dentist Clinical Exam',
        exact: true,
        component: <ClinicalExamOld/>,
        private: true
    },
    {
        path: 'dentist/appointment/snap-shot/:appointmentID',
        name: 'Patient Snap Shot',
        exact: true,
        component: <PatientSnapShot/>,
        private: true
    },

    {
        path: '/dentist/appointment/clinical-exam/edit-image',
        name: 'Dentist Clinic Exam Edit Image',
        exact: true,
        component: <EditImage/>,
        private: true
    },
    {
        path: '/dentist/dashboard',
        name: 'Dentist Dashboard',
        exact: true,
        component: <DentistDashboard/>,
        private: true
    },
    {
        path: '/dentist/terms-and-condition',
        name: 'Dentist Terms Condition And Privacy Policy',
        exact: true,
        component: <DentistTermsConditionAndPrivacyPolicy/>,
        private: false
    },
    {
        path: '/dentist/appointment/patient-photos/:appointmentID',
        name: 'Radiographic Analysis',
        exact: true,
        component: <PatientPhotos/>,
        private: true
    },
    // Other Routes
    {
        path: '*',
        name: 'Page Not Found',
        exact: true,
        component: <PageNotFound/>,
        private: false
    },
    {
        path: '/tNc',
        name: 'Terms & Condition',
        exact: true,
        component: <TermsConditionsStaticPage/>,
        private: false
    },
    {
        path: '/pNp',
        name: 'Privacy & Policy',
        exact: true,
        component: <PrivacyPolicyStaticPage/>,
        private: false
    },
    {
        path: '/patient',
        name: 'Patient Home',
        exact: true,
        component: <PatientLandingPage/>,
        private: false
    },
    {
        path: '/staff',
        name: 'Staff Home',
        exact: true,
        component: <StaffLandingPage/>,
        private: false
    }
];
const v2Paths = [
    {
        path: '/unsubscribe/:token',
        name: 'unsubscribe mail',
        exact: true,
        component: <Unsubscribe/>,
        private: false
    },
    {
        path: 'v2/dentist/dashboard',
        name: 'Dentist Dashboard',
        exact: true,
        component: <MainLayout name={'Dashboard'} component={<DentistDashboard/>}/>,
        private: true
    },
    {
        path: 'v2/dentist/appointment',
        name: 'Dentist Pre Appointment',
        exact: true,
        component: <MainLayout name={'Appointments'} component={<DentistAppointment/>}/>,
        private: true
    },
    {
        path: 'v2/dentist/profile',
        name: 'Profile',
        exact: true,
        component: <MainLayout name={'Profile'} component={<DentistProfile/>}/>,
        private: true
    },
    {
        path: 'v2/dentist/analytics',
        name: 'Analytics',
        exact: true,
        component: <MainLayout name={'Analytics'} component={<Analytics/>}/>,
        private: true
    },
    {
        path: 'v2/dentist/login',
        name: 'Dentist Login',
        exact: true,
        component: <StaffLogin/>,
        private: false
    },
    {
        path: 'v2/dentist/forgot-password',
        name: 'Dentist Forgot password',
        exact: true,
        component: <StaffForgotPassword/>,
        private: false
    },
    {
        path: 'v2/dentist/verify-mobile',
        name: 'Dentist Verify Mobile Number',
        exact: true,
        component: <StaffVerifyMobile/>,
        private: true
    },
    {
        path: 'v2/dentist/terms-conditions',
        name: 'Dentist Terms and conditions',
        exact: true,
        component: <StaffTermsConditions/>,
        private: true
    },
    {
        path: 'v2/dentist/privacy-policy',
        name: 'Dentist Privacy Policy',
        exact: true,
        component: <StaffPrivacyPolicy/>,
        private: true
    },
    {
        path: 'v2/dentist/create-password',
        name: 'Dentist Create Password',
        exact: true,
        component: <StaffCreatePassword/>,
        private: true
    },
    {
        path: 'v2/dentist/disclaimer',
        name: 'Disclaimer',
        exact: true,
        component: <Disclaimer/>,
        private: true
    },
    {
        path: 'v2/dentist/communications',
        name: 'Communications',
        exact: true,
        component: <DentistCommunications/>,
        private: true
    },
    {
        path: 'v2/dentist/recover-password/:recovery_token',
        name: 'Dentist Reset Password',
        exact: true,
        component: <StaffResetPassword/>,
        private: false
    },

    {
        path: 'v2/dentist/change-password',
        name: 'Dentist Change Password',
        exact: true,
        component: <MainLayout name={'Change Password'} component={<StaffChangePassword/>}/>,
        private: true
    },
    {
        path: 'v2/dentist/appointment/health-history/:appointmentId/:patientId',
        name: 'Dentist Health History',
        exact: true,
        component: <AppointmentLayout name={'Health History'} component={<PreIntake/>}/>,
        private: true
    },
    {
        path: 'v2/dentist/appointment/health-history/:patientId',
        name: 'Dentist Health History',
        exact: true,
        component: <AppointmentLayout name={'Health History'} component={<PreIntake/>}/>,
        private: true
    },
    {
        path: 'v2/dentist/appointment/clinical-exam/:appointmentId/:patientId',
        name: 'Clinical Exam',
        exact: true,
        component: <AppointmentLayout name={'clinic-exam'} component={<ClinicalExam/>}/>,
        private: true
    },
    {
        path: 'v2/dentist/termOfUse',
        name: 'Terms of use',
        exact: true,
        component: <MainLayout name={'Terms of Use'} component={<TermsOfUse/>}/>,
        private: true
    },
    {
        path: 'v2/dentist/privacyPolicy',
        name: 'Privacy policy',
        exact: true,
        component: <MainLayout name={'Privacy Policy'} component={<PrivacyPolicy/>}/>,
        private: true
    },
    {
        path: 'v2/dentist/notification',
        name: 'Notification',
        exact: true,
        component: <MainLayout name={'Notifications'} component={<Notification/>}/>,
        private: true
    },
    {
        path: 'v2/dentist/resources',
        name: 'Resources',
        exact: true,
        component: <MainLayout name={'Resources'} component={<Resources/>}/>,
        private: true
    },
    {
        path: 'v2/dentist/wellness-profile/:patientId',
        name: 'Wellness Profile',
        exact: true,
        component: <AppointmentLayout name={'wellness-profile'} component={<WellnessProfile/>}/>,
        private: true
    },
    {
        path: 'v2/dentist',
        name: 'Dentist Landing Page',
        exact: true,
        component: <LandingPage/>,
        private: false
    },

    //patient
    {
        path: 'v2/patient/health-history',
        name: 'Patient Health History',
        exact: true,
        component: <PatientPreIntake/>,
        private: true
    },
    {
        path: 'v2/patient',
        name: 'Patient Landing Page',
        exact: true,
        component: <LandingPage/>,
        private: false
    },
    {
        path: 'anonymous-health-history/:clinicID',
        name: 'Anonymous Health History',
        exact: true,
        component: <AnonymousPreIntake/>,
        private: false
    },
    {
        path: 'dentist/second-screen/:appointmentId/:patientId',
        name: 'Second Screen',
        exact: true,
        component: <SecondScreen/>,
        private: false
    },
    {
        path: '/patient/communications',
        name: 'Patient Communications',
        exact: true,
        component: <PatientCommunications/>,
        private: true
    },
    {
        path: '/patient/send-otp/:clinicId/:patientId',
        name: 'Patient Send OTP',
        exact: true,
        component: <SendOtpScreen/>,
        private: false
    },
    {
        path: '/patient/verify-otp/:clinicId/:patientId',
        name: 'Patient Verify OTP',
        exact: true,
        component: <VerifyOtpScreen/>,
        private: false
    },
    {
        path: '/patient/verify-mobile/:clinicId',
        name: 'Patient Verify Mobile',
        exact: true,
        component: <MobileAndDateScreen/>,
        private: false
    },
    {
        path: '/patient/verify-access-code/:clinicId',
        name: 'Patient Verify Access Code',
        exact: true,
        component: <AccessCodeScreen/>,
        private: false
    },
    {
        path: '/patient/access-health-history/:clinicId/:patientId',
        name: 'Patient Verify Access Code',
        exact: true,
        component: <AccessHealthHistory/>,
        private: false
    },
    {
        path: '/patient/submit-health-history/:clinicId/:patientId',
        name: 'Submitted Health History',
        exact: true,
        component: <SubmitHealthHistoryPage/>,
        private: false
    },
    {
        path: '/patient/patient-register/:clinicId/:patientId',
        name: 'Patient Register',
        exact: true,
        component: <PatientRegister/>,
        private: false
    },
    {
        path: '/patient/verify-mobile/wellness/:clinicId',
        name: 'Patient Verify Mobile',
        exact: true,
        component: <MobileAndDateScreen/>,
        private: false
    },
    {
        path: '/patient/verify-otp/wellness/:clinicId/:patientId',
        name: 'Patient Verify OTP',
        exact: true,
        component: <VerifyOtpScreen/>,
        private: false
    },
    {
        path: '/patient/verify-access-code/wellness/:clinicId',
        name: 'Patient Verify Access Code',
        exact: true,
        component: <AccessCodeScreen/>,
        private: false
    },
    {
        path: '/patient/without-account/wellness-profile/:clinicId/:patientId',
        name: 'Patient RiskProfile',
        exact: true,
        component: <PatientWellnessProfileWithoutAccount/>,
        private: false
    },
    {
        path: '/v2/dentist/patients-list',
        name: 'Patients',
        exact: true,
        component: <MainLayout name={'Patients'} component={<PatientsList/>}/>,
        private: false
    },
    {
        path: '/patient/session-expired',
        name: 'Session Expired',
        exact: true,
        component: <SessionExpire />,
        private: false
    },
];

export const paths = [...v1Paths, ...v2Paths];
