import {
  APPOINTMENT_DETAILS_REQUEST,
  APPOINTMENT_DETAILS_REQUEST_FAILURE,
  APPOINTMENT_DETAILS_REQUEST_SUCCESS,
  DASHBOARD_MEMBERS_CACHE_DATA_CLEAR_REQUEST,
  DASHBOARD_MEMBERS_REQUEST,
  DASHBOARD_MEMBERS_REQUEST_FAILURE,
  DASHBOARD_MEMBERS_REQUEST_SUCCESS,
  DENTIST_NEW_APPOINTMENT_CACHE_DATA_CLEAR_REQUEST,
  DENTIST_NEW_APPOINTMENT_REQUEST,
  DENTIST_NEW_APPOINTMENT_REQUEST_FAILURE,
  DENTIST_NEW_APPOINTMENT_REQUEST_SUCCESS,
  SET_DATE_NEW_APPOINTMENT,
  UPDATE_APPOINTMENT_DETAILS,
  CHANGE_LOGIN_USER_PERMISSION_REQUEST,
  CHANGE_LOGIN_USER_PERMISSION_SUCCESS,
  CHANGE_LOGIN_USER_PERMISSION_FAILURE,
  UPDATE_APPOINTMENT_DATA_REQUEST,
  SET_DESCENDING_ASCENDING,
  SET_SEARCH_DATA_APPOINTMENT,
  SET_ROLE_APPOINTMENT,
  SET_EXAM_APPOINTMENT,
  SET_STATUS_APPOINTMENT,
  SET_TEAM_MEMBER_APPOINTMENT,
  UPDATE_APPOINTMENT_STATUS_REQUEST,
  UPDATE_APPOINTMENT_STATUS_REQUEST_SUCCESS,
  UPDATE_APPOINTMENT_STATUS_REQUEST_FAILURE
} from '../../../ActionTypes/v2/dentist/appointment';
import {
  AppointmentDetailsRequest,
  AppointmentDetailsRequestFailure,
  AppointmentDetailsRequestFailurePayload,
  AppointmentDetailsRequestPayload,
  AppointmentDetailsRequestSuccess,
  AppointmentDetailsRequestSuccessPayload,
  ChangeLoginUserPermissionRequest,
  ChangeLoginUserPermissionRequestFailure,
  ChangeLoginUserPermissionRequestFailurePayload,
  ChangeLoginUserPermissionRequestPayload,
  ChangeLoginUserPermissionRequestSuccess,
  ChangeLoginUserPermissionRequestSuccessPayload,
  DashboardMemberRequest,
  DashboardMemberRequestFailure,
  DashboardMemberRequestFailurePayload,
  DashboardMemberRequestPayload,
  DashboardMemberRequestSuccess,
  DashboardMemberRequestSuccessPayload,
  DentistNewAppointmentRequest,
  DentistNewAppointmentRequestFailure,
  DentistNewAppointmentRequestFailurePayload,
  DentistNewAppointmentRequestPayload,
  DentistNewAppointmentRequestSuccess,
  DentistNewAppointmentRequestSuccessPayload,
  SetDateForNewAppointment,
  SetDateForNewAppointmentPayload,
  SetDescendingAscending,
  SetDescendingAscendingPayload,
  SetSearchDataAppointment,
  SetSearchDataAppointmentPayload,
  UpdateAppointmentData,
  UpdateAppointmentDataPayload,
  UpdateAppointmentDetails,
  UpdateAppointmentStatusRequest,
  UpdateAppointmentStatusRequestFailure,
  UpdateAppointmentStatusRequestFailurePayload,
  UpdateAppointmentStatusRequestPayload,
  UpdateAppointmentStatusRequestSuccess,
  UpdateAppointmentStatusRequestSuccessPayload
} from '../../../types/v2/dentist/appointment';

//dashboard appointments staff
export const setDateForNewAppointment = (payload: SetDateForNewAppointmentPayload): SetDateForNewAppointment => ({
  type: SET_DATE_NEW_APPOINTMENT,
  payload
});

// Appointment List
export const dentistNewAppointmentRequest = (payload: DentistNewAppointmentRequestPayload): DentistNewAppointmentRequest => ({
  type: DENTIST_NEW_APPOINTMENT_REQUEST,
  payload
});

export const dentistNewAppointmentCacheDataClearRequest = (): any => ({
  type: DENTIST_NEW_APPOINTMENT_CACHE_DATA_CLEAR_REQUEST
});

export const dentistNewAppointmentRequestSuccess = (
  payload: DentistNewAppointmentRequestSuccessPayload
): DentistNewAppointmentRequestSuccess => ({
  type: DENTIST_NEW_APPOINTMENT_REQUEST_SUCCESS,
  payload
});

export const dentistNewAppointmentRequestFailure = (
  payload: DentistNewAppointmentRequestFailurePayload
): DentistNewAppointmentRequestFailure => ({
  type: DENTIST_NEW_APPOINTMENT_REQUEST_FAILURE,
  payload
});

//members list
export const dashboardMemberRequest = (payload: DashboardMemberRequestPayload): DashboardMemberRequest => ({
  type: DASHBOARD_MEMBERS_REQUEST,
  payload
});

export const dashboardMemberCacheDataClearRequest = (): any => ({
  type: DASHBOARD_MEMBERS_CACHE_DATA_CLEAR_REQUEST
});

export const dashboardMemberRequestSuccess = (
  payload: DashboardMemberRequestSuccessPayload
): DashboardMemberRequestSuccess => ({
  type: DASHBOARD_MEMBERS_REQUEST_SUCCESS,
  payload
});

export const dashboardMemberRequestFailure = (
  payload: DashboardMemberRequestFailurePayload
): DashboardMemberRequestFailure => ({
  type: DASHBOARD_MEMBERS_REQUEST_FAILURE,
  payload
});

// Appointment details
export const appointmentDetailsRequestAction = (payload: AppointmentDetailsRequestPayload): AppointmentDetailsRequest => ({
  type: APPOINTMENT_DETAILS_REQUEST,
  payload
});

export const appointmentDetailsRequestSuccessAction = (
  payload: AppointmentDetailsRequestSuccessPayload
): AppointmentDetailsRequestSuccess => ({
  type: APPOINTMENT_DETAILS_REQUEST_SUCCESS,
  payload
});

export const appointmentDetailsRequestFailureAction = (
  payload: AppointmentDetailsRequestFailurePayload
): AppointmentDetailsRequestFailure => ({
  type: APPOINTMENT_DETAILS_REQUEST_FAILURE,
  payload
});

export const updateAppointmentDetailsRequestAction = (payload: any): UpdateAppointmentDetails => ({
  type: UPDATE_APPOINTMENT_DETAILS,
  payload
});

// role base update
export const changeLoginUserPermissionRequestAction = (
  payload: ChangeLoginUserPermissionRequestPayload
): ChangeLoginUserPermissionRequest => ({
  type: CHANGE_LOGIN_USER_PERMISSION_REQUEST,
  payload
});

export const changeLoginUserPermissionRequestSuccessAction = (
  payload: ChangeLoginUserPermissionRequestSuccessPayload
): ChangeLoginUserPermissionRequestSuccess => ({
  type: CHANGE_LOGIN_USER_PERMISSION_SUCCESS,
  payload
});

export const changeLoginUserPermissionRequestFailureAction = (
  payload: ChangeLoginUserPermissionRequestFailurePayload
): ChangeLoginUserPermissionRequestFailure => ({
  type: CHANGE_LOGIN_USER_PERMISSION_FAILURE,
  payload
});

export const updateAppointmentDataAction = (payload: UpdateAppointmentDataPayload): UpdateAppointmentData => ({
  type: UPDATE_APPOINTMENT_DATA_REQUEST,
  payload
});

// set descending ascending
export const setDescendingAscendingAction = (payload: SetDescendingAscendingPayload): SetDescendingAscending => ({
  type: SET_DESCENDING_ASCENDING,
  payload
});

// set search data
export const setSearchDataAppointment = (payload: SetSearchDataAppointmentPayload): SetSearchDataAppointment => ({
  type: SET_SEARCH_DATA_APPOINTMENT,
  payload
});

export const setRoleAppointment = (payload: any): any => ({
  type: SET_ROLE_APPOINTMENT,
  payload
});

export const setExamAppointment = (payload: any): any => ({
  type: SET_EXAM_APPOINTMENT,
  payload
});
export const setStatusAppointment = (payload: any): any => ({
  type: SET_STATUS_APPOINTMENT,
  payload
});

export const setTeamMemberAppointment = (payload: any): any => ({
  type: SET_TEAM_MEMBER_APPOINTMENT,
  payload
});

// update appointment status
export const updateAppointmentStatusRequest = (payload: UpdateAppointmentStatusRequestPayload): UpdateAppointmentStatusRequest => ({
  type: UPDATE_APPOINTMENT_STATUS_REQUEST,
  payload,
})

export const updateAppointmentStatusRequestSuccess = (payload: UpdateAppointmentStatusRequestSuccessPayload): UpdateAppointmentStatusRequestSuccess => ({
  type: UPDATE_APPOINTMENT_STATUS_REQUEST_SUCCESS,
  payload,
})
export const updateAppointmentStatusRequestFailure = (payload: UpdateAppointmentStatusRequestFailurePayload): UpdateAppointmentStatusRequestFailure => ({
  type: UPDATE_APPOINTMENT_STATUS_REQUEST_FAILURE,
  payload,
})
