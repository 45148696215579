import { MedicalHistoryDentistInterface } from "../../../../types/dentistTypes";
import {
  UPDATE_MEDICAL_HISTORY_DATA,UPDATE_MEDICAL_HISTORY_DATA_FAILURE,
  CHANGE_MEDICAL_HISTORY_DATA,
} from "../../../../ActionTypes/v1/dentist/clinicalExamDentist";

const initialState: MedicalHistoryDentistInterface = {
  asa_classification: "",
  dental_medical_risk_notes: "",
  appointment_id: "",
  patient_id: "",
  appt_type: ""
};

export const medicalHistoryDentistReducer = (
  state = initialState,
  action: any
) => {
  switch (action.type) {
    case UPDATE_MEDICAL_HISTORY_DATA:
      return {
        ...state,
        ...action.payload,
      };
    case UPDATE_MEDICAL_HISTORY_DATA_FAILURE:
      return {
        ...state,
        asa_classification: "",
        dental_medical_risk_notes: "",
      }
    case CHANGE_MEDICAL_HISTORY_DATA:
      const { value, key } = action.payload as any;
      const newState = state as any;
      let newFinding = newState[key] as any;
      newFinding = value;
      return {
        ...state,
        [key]: newFinding,
      };

    default:
      return { ...state };
  }
};
