import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createPasswordValidator } from "../../../../validation";
import dentistBanner from "../../../../assets/images/v1/verification_banner.png";
import CreatePasswordComponent from "../../auth/CreatePasswordComponent";
import { createPasswordRequest } from "../../../../app/actions/v1/patient/authActions/authActions";
import themes from "../../../../theme";
import { getLocalStorageItem } from "../../../../utils/helper";

const useStyles = makeStyles((theme: any) => ({
  mainWrapper: {
    position: "relative",
    overflow: "hidden",
    maxHeight: "100vh",
    backgroundColor: themes.palette.secondary.main,
  },
  rightSection: {
    paddingLeft: "10%",
    paddingRight: "10%",
    [theme.breakpoints.down("md")]: {
      backgroundColor: themes.palette.secondary.main,
      height: "100vh",
      width: "100vw",
      paddingLeft: "20%",
      paddingRight: "20%",
      borderLeft: "none",
    },
  },
  cssOutlinedInput: {
    borderRadius: "10px !important",
    "&$cssFocused $notchedOutline": {
      borderColor: `${theme.palette.primary.main} !important`,
    },
  },
  notchedOutline: {
    borderWidth: "1px",
    borderColor: `${theme.palette.common.white} !important`,
  },
  input: {
    color:`${theme.palette.common.white} !important`,
    padding: "10px !important",
    fontWeight: 400,
  },
  floatingLabelRootStyle: {
    color:`${theme.palette.common.white} !important`,
    opacity: "50%",
    top: "-6px !important",
  },
  floatingLabelFocusStyle: {
    color: theme.palette.primary.main,
  },
  danger: {
    borderWidth: "1px",
    borderColor:`${theme.palette.error.main} !important`,
  },
  button: {
    color: theme.palette.common.white,
    marginBottom: "30px !important",
  },
  showPassword: {
    color: `${theme.palette.common.white} !important`,
  },
  imageText: {
    position: "absolute",
    top: "22%",
    right: "50%",
    transform: "translate(50%,-50%)",
    width: "100%",
    padding: "0 20px",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
  },
}));

const CreatePasswordDentist = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useNavigate();

  const [formData, setFormData] = useState({
    password: "",
    confirmPassword: "",
    showPassword: false,
    showNewPassword: false,
  });
  const [error, setError]: any = useState({});
  const imageText = {
    title: "Create Password",
    text1: "",
    text2: "",
    text3: "",
  };

  useEffect(() => {
    const localStorageData = JSON.parse(
      getLocalStorageItem("dentistUserData") as string
    );
    if (
      localStorage.getItem("dentistToken") &&
      localStorageData?.password_updated
    ) {
      history({
        pathname: "/v2/dentist/appointment",
      });
    }
  }, []);

  const submitHandler = (e: any) => {
    e.preventDefault();
    let { errors, isValid } = createPasswordValidator(formData);
    setError(errors);
    if (isValid) {
      dispatch(
        createPasswordRequest({
          payload: { password: formData.password },
          callback: (data: any) => {
            localStorage.setItem(
              "dentistUserData",
              JSON.stringify(data?.data?.detail)
            );
            history({
              pathname: "/v2/dentist/appointment",
            });
          },
        })
      );
    }
  };

  return (
    <div className={classes.mainWrapper}>
        <CreatePasswordComponent
          isPatient={false}
          banner={dentistBanner}
          classes={classes}
          formData={formData}
          setFormData={setFormData}
          error={error}
          setError={setError}
          imageText={imageText}
          submitHandler={submitHandler}
        />
    </div>
  );
};

export default CreatePasswordDentist;
