import React, { useEffect, useState } from 'react'
import { Box, ClickAwayListener, InputLabel, Tooltip, Typography } from '@mui/material'
import theme from '../../../../theme'
import StartAdornmentIcon from '../StartAdornmentIcon';
import EndAdornmentIcon from '../EndAdornmentIcon';
import { faPhone } from '@fortawesome/free-solid-svg-icons';

type Props = {
    label?: any;
    value?: any;
    info?: string;
    startIcon?: any;
    endIcon?: any;
}

const PreIntakeDisableInput = (props: Props) => {
    const {
        label,
        value,
        info,
        startIcon,
        endIcon,
    } = props as Props;

    const [tooltipOpen, setTooltipOpen] = useState(false)

    const handleTooltipClose = () => {
        setTooltipOpen(false);
    };

    const handleTooltipOpen = () => {
        setTooltipOpen(true);
    };

    return (
        <React.Fragment>
            {label && (
                <InputLabel
                    htmlFor={label}
                    sx={{
                        color: theme.palette.common.black50,
                        mb: "8px", whiteSpace: {
                            xs: 'break-spaces',
                            sm: 'nowrap'
                        },
                    }}
                    className={`labelColor fw-regular f-14 lh-19 f-w-350`}
                >
                    {label}
                </InputLabel>

            )}
            <ClickAwayListener onClickAway={handleTooltipClose}>
                <Tooltip
                    title={info}
                    onClose={handleTooltipClose}
                    open={tooltipOpen}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                >
                    <Box
                        onClick={handleTooltipOpen}
                        sx={{
                            boxSizing: 'border-box',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            height: '50px !important',
                            fontFamily: 'FavoritPro-Regular !important',
                            fontStyle: 'normal',
                            fontWeight: 400,
                            fontSize: '14px',
                            lineHeight: '16px',
                            letterSpacing: '0.4px',
                            border: `1px solid ${theme.palette.text.secondary}`,
                            borderRadius: '10px',
                            color: `${theme.palette.text.secondary} !important`,
                            padding: endIcon ? '5px 0px !important' : startIcon ? '5px 10px 5px 0px !important' : '',
                        }}
                    >
                        {startIcon && <StartAdornmentIcon text={startIcon} disabled />}
                        <Typography
                            className="textField"
                            sx={{
                                color: `${theme.palette.text.secondary} !important`,
                                flex: 1,
                            }}
                        >
                            {value}
                        </Typography>
                        {endIcon && <EndAdornmentIcon icon={endIcon} disabled />}
                    </Box>
                </Tooltip>
            </ClickAwayListener>
        </React.Fragment >
    )
}

export default PreIntakeDisableInput