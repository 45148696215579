export const hygieneCycleDropdown = [
    {
        value: 3,
        label: '3 Months'
    },
    {
        value: 4,
        label: '4 Months'
    },
    {
        value: 6,
        label: '6 Months'
    },
    {
        value: 9,
        label: '9 Months'
    },
    {
        value: 12,
        label: '12 Months'
    },
]

export const examCycleDropdown = [
    {
        value: 3,
        label: '3 Months'
    },
    {
        value: 4,
        label: '4 Months'
    },
    {
        value: 6,
        label: '6 Months'
    },
    {
        value: 12,
        label: '12 Months'
    },
]

export const xRayCycleDropdown = [
    {
        value: 6,
        label: '6 Months'
    },
    {
        value: 12,
        label: '12 Months'
    },
    {
        value: 18,
        label: '18 Months'
    },
    {
        value: 24,
        label: '24 Months'
    },
    {
        value: 36,
        label: '36 Months'
    },
]
export const stagingDropdown = [
    {
        value: '1',
        label: 1
    },
    {
        value: '2',
        label: 2
    },
    {
        value: '3',
        label: 3
    },
    {
        value: '4',
        label: 4
    }
]
export const gradingDropdown = [
    {
        value: 'A',
        label: 'A'
    },
    {
        value: 'B',
        label: 'B'
    },
    {
        value: 'C',
        label: 'C'
    },
]
export const extentDropdown = [
    {
        value: 'Localized',
        label: 'Localized'
    },
    {
        value: 'Generalized',
        label: 'Generalized'
    },
    {
        value: 'Both',
        label: 'Both'
    },
]

export const appointmentTime = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]

export const insuranceStatusDropdown = [
    {
        value: 'Send Preauth',
        label: 'Send Preauth'
    },
    {
        value: "Preauth Sent",
        label: "Preauth Sent"
    },
    {
        value: 'Waiting for Insurance Approval',
        label: 'Waiting for Insurance Approval'
    },
    {
        value: "Insurance Approved Tx",
        label: "Insurance Approved Tx"
    },
    {
        value: 'Insurance Denied Tx',
        label: 'Insurance Denied Tx'
    },
];

export const treatmentStatusDropdown = [
    {
        value: 'Pending',
        label: 'Pending'
    },
    {
        value: 'Ongoing',
        label: 'Ongoing'
    },
    {
        value: 'Completed',
        label: 'Completed'
    },
]

export const preAuthCheckboxOption = [
    { label: 'PA', value: false, key: 'pa' },
    { label: 'Perio Charting', value: false, key: 'perio_charting' },
    { label: 'Photos', value: false, key: 'photos' },
    { label: 'Panoramic X-Ray', value: false, key: 'panoramoic_xray' },
    { label: 'BW', value: false, key: 'bw' },
]
