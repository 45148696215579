import {
  UPDATE_APPOINTMENT_INFORMATION,
  GET_PRE_CLINICAL_DATA_REQUEST,
  GET_PRE_CLINICAL_DATA_SUCCESS,
  GET_PRE_CLINICAL_DATA_FAIL,
  PUT_PRE_CLINICAL_DATA_REQUEST,
  PUT_PRE_CLINICAL_DATA_SUCCESS,
  PUT_PRE_CLINICAL_DATA_FAIL,
  UPDATE_PRE_CLINICAL_VALIDATIONS,
  UPDATE_PRE_CLINICAL_PRE_INTAKE_VALIDATIONS,
  OPEN_POPUP_APPOINTMENT_TYPE_CHANGE,
  IS_PRE_CLINICAL_SUBMIT
} from '../../../../ActionTypes/v2/dentist/clinicalExam/preClinical';

const initialState: any = {
  preClinicalLoader: false,
  savePreClinicalLoader: false,
  preClinicalData: null,
  appointmentInfo: {
    // dateOfExam: null,
    appointmentType: '',
    lastDateOfExamination: null,
    recallCyclePeriod: '',
    instrumentCycle: null,
    verbalConsent: '',
    chiefComplaint: '',
    socialNotes: '',
    patientPreferences: ''
  },
  appointmentPreIntakeData: null,
  openPopUpAppointmentType: {
    isOpen: false,
    flag: null
  },
  errors: {
    appointmentInfo: {},
  },
  preIntakeErrors: {},
  isPreClinicalSubmit: false,
};

const preClinicalReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_PRE_CLINICAL_DATA_REQUEST:
      return {
        ...state,
        preClinicalLoader: true,
        preClinicalData: null
      };

    case GET_PRE_CLINICAL_DATA_SUCCESS:
      return {
        ...state,
        preClinicalLoader: false,
        preClinicalData: action.payload
      };
    case GET_PRE_CLINICAL_DATA_FAIL:
      return {
        ...state,
        preClinicalLoader: false,
        preClinicalData: null
      };
    case UPDATE_APPOINTMENT_INFORMATION:
      return {
        ...state,
        appointmentInfo: action.payload?.appointmentInfo,
        appointmentPreIntakeData: action.payload?.appointmentPreIntakeData
      };
    case PUT_PRE_CLINICAL_DATA_REQUEST:
      return {
        ...state,
        savePreClinicalLoader: true
      };
    case PUT_PRE_CLINICAL_DATA_SUCCESS:
      return {
        ...state,
        savePreClinicalLoader: false
      };
    case PUT_PRE_CLINICAL_DATA_FAIL:
      return {
        ...state,
        savePreClinicalLoader: false
      };
    case UPDATE_PRE_CLINICAL_VALIDATIONS:
      return {
        ...state,
        errors: action.payload
      };
    case UPDATE_PRE_CLINICAL_PRE_INTAKE_VALIDATIONS:
      return {
        ...state,
        preIntakeErrors: action.payload
      };
    case OPEN_POPUP_APPOINTMENT_TYPE_CHANGE:
      return {
        ...state,
        openPopUpAppointmentType: action.payload
      };
    case IS_PRE_CLINICAL_SUBMIT:
      return {
        ...state,
        isPreClinicalSubmit: action.payload
      };
    default:
      return {
        ...state
      };
  }
};

export default preClinicalReducer;
