import React, {useMemo} from 'react'
import {Box, Typography} from '@mui/material';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCircle} from '@fortawesome/free-solid-svg-icons';
import {useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import MedicalAlertsCollapsibleBox from "./MedicalAlertsCollapsibleBox";
import theme from '../../../../../theme';
import CardComponent from '../../../common/CardComponent'
import {RootState} from "../../../../../app/reducers/v2/rootReducer";
import {stringToArray} from "../../../../../utils/helper";
import {checkDisease} from "../../../../../utils/v2/preIntakeConstant/preIntakeConstant";
import NoDataFound from "../../../../v1/Common/NoDataFound";


const MedicalAlerts = () => {
    const {patientId} = useParams();
    const navigate = useNavigate()
    const {wellnessProfileFilters: {wellnessProfileData},} = useSelector((state: RootState) => state);
    const medicalAlerts = wellnessProfileData?.medical_alerts;

    const diseasesList: string[] = useMemo(() => {
        return stringToArray(medicalAlerts?.medical_conditions)
    }, [wellnessProfileData, checkDisease]);

    const allergiesList: string[] = useMemo(() => {
        return stringToArray(medicalAlerts?.allergies_list)
    }, [wellnessProfileData, checkDisease]);

    const viewAll = () => {
        const search = `?section=medicalHealthHistory`
        navigate({
            pathname: `/v2/dentist/appointment/health-history/${patientId}`,
            search: search
        })
    }
    const medicalAlertsList = [
        {
            title: 'Serious Illnesses Or Operations?',
            content: medicalAlerts?.serious_illness_or_operation_existance === true ? wellnessProfileData?.medical_alerts?.serious_illness_or_operation_name : 'No',
            isData: medicalAlerts?.serious_illness_or_operation_existance !== null && medicalAlerts?.serious_illness_or_operation_existance !== false
        },
        {
            title: 'Currently Taking Any Medications And/Or Supplements?',
            content: medicalAlerts?.currently_taking_any_medication === true ? wellnessProfileData?.medical_alerts?.medication_list : 'No',
            isData: medicalAlerts?.currently_taking_any_medication !== null && medicalAlerts?.currently_taking_any_medication !== false
        },
        {
            title: 'Chest Pains And/Or Shortness Of Breath?',
            content: medicalAlerts?.chest_pain_shortness_breath ? 'Yes' : 'No',
            isData: medicalAlerts?.chest_pain_shortness_breath !== null && medicalAlerts?.chest_pain_shortness_breath !== false
        },
        {
            title: 'Are You Nursing?',
            content: medicalAlerts?.nursing ? 'Yes' : 'No',
            isData: medicalAlerts?.nursing !== null && medicalAlerts?.nursing !== false
        },
        {
            title: 'Are You Pregnant?',
            content: medicalAlerts?.pregnant ? 'Yes' : 'No',
            isData: medicalAlerts?.pregnant !== null && medicalAlerts?.pregnant !== false
        },
        {
            title: 'Birth Control',
            content: medicalAlerts?.birth_control ? 'Yes' : 'No',
            isData: medicalAlerts?.birth_control !== null && medicalAlerts?.birth_control !== false
        },
        {
            title: 'Diseases List',
            content: diseasesList,
            isData: diseasesList?.length > 0
        },
        {
            title: 'Allergies List',
            content: allergiesList,
            isData: allergiesList?.length > 0
        },
    ]

    return (
        <CardComponent
            bgColor={theme.palette.v2.primary.primary25}
            color={theme.palette.v2.primary.main}
            title='Medical Alerts'
            rightText='+ Update Health History'
            height={230}
            btnClick={viewAll}
            fromMedicalAlerts>
            {(medicalAlertsList?.some((res: any) => res?.isData) && medicalAlerts !== null) ?
                <React.Fragment>
                    {medicalAlertsList?.map((item: any, index: number) =>
                        <React.Fragment>
                            {item?.isData ?
                                <MedicalAlertsCollapsibleBox key={index} title={item?.title}>
                                    {(typeof item?.content === 'string') ?
                                        <Typography className={'f-14 f-w-400 fw-regular labelColor'}>
                                            {item?.content}
                                        </Typography> :
                                        <Typography className={'f-14 f-w-400 fw-regular labelColor'}>
                                            {item?.content?.map((res: any, index: number) =>
                                                <Box key={index} ml={2} display={'flex'} alignItems={'center'} mb={1}>
                                                    <FontAwesomeIcon color={theme.palette.common.black50}
                                                                     icon={faCircle}
                                                                     width={'5px'}/>
                                                    <Typography className={'f-14 f-w-400 fw-regular'}
                                                                color={theme.palette.common.black50}
                                                                ml={1}>
                                                        {res}
                                                    </Typography>
                                                </Box>
                                            )}
                                        </Typography>
                                    }

                                </MedicalAlertsCollapsibleBox> : ''}
                        </React.Fragment>
                    )}
                </React.Fragment> :
                <NoDataFound errorMessage={'data'}/>}
        </CardComponent>
    )
}

export default MedicalAlerts