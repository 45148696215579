import {all, put, select, takeEvery} from "redux-saga/effects";
import API from "../../../../../../utils/api";
import {
    changeAnnotationResponse,
    convertMultiSelectArrayToString,
    errorToaster, getLocalStorageItem, removeRefFromJson,
    successToaster,
} from "../../../../../../utils/helper";
import {
    getAnnotationRequestAction,
    getAnnotationRequestFailureAction,
    getAnnotationRequestSuccessAction,
    putAnnotationRequestFailureAction,
    putAnnotationRequestSuccessAction,
} from "../../../../../actions/v2/dentist/clinicalExam/exam/annotationAction";
import {
    GET_ANNOTATION_REQUEST,
    PUT_ANNOTATION_REQUEST,
} from "../../../../../ActionTypes/v2/dentist/clinicalExam/exams/annotation";
import {RootState} from "../../../../../reducers/v2/rootReducer";

const keyToReplace = [
    "cause",
    "tooth_number",
    "subtype",
    "tooth_site",
    "tooth_surface",
    "grade",
    "present",
    "mm",
    "notes"
] as any;

const keysToKeep = ["lingual_mm", "buccal_mm", "lingual_grade", 'buccal_grade']; // Add keys to keep as they are


function* getAnnotationRequestSaga(payload: any): any {
    try {
        const isWellnessProfile = window.location.pathname?.includes('/v2/dentist/wellness-profile/')
        const urlForWellnessProfile = `/v2/images/${payload.payload}/tags?clinic_id=${getLocalStorageItem('clinic')}`
        const urlForClinicalExam = `/v2/images/${payload.payload}/tags`
        const response = yield API.get(isWellnessProfile ? urlForWellnessProfile : urlForClinicalExam);
        const details = response?.data?.detail;
        const updatedDetails = changeAnnotationResponse(details)
        yield put(getAnnotationRequestSuccessAction(removeRefFromJson(updatedDetails)));
    } catch (e: any) {
        yield put(
            getAnnotationRequestFailureAction({
                error: e?.message,
            })
        );
    }
}

function* putAnnotationRequestSaga(payload: any): any {
    try {
        const processData = () => {
            return payload?.payload.data.map((item: any) => {
                const newItem = {...item};
                if (newItem?.ncclTypes?.length) {
                    if (newItem?.ncclTypes?.includes("Erosion")) {
                        newItem.erosion = true;
                    }

                    if (newItem.ncclTypes.includes("Attrition")) {
                        newItem.attrition = true;
                    }
                    if (newItem.ncclTypes.includes("Is Dentin Exposed")) {
                        newItem.is_dentin_exposed = true;
                    }
                    if (newItem.ncclTypes.includes("Abrasion")) {
                        newItem.abrasion = true;
                    }
                    if (newItem.ncclTypes.includes("Abfraction")) {
                        newItem.abfraction = true;
                    }

                    // Remove the ncclTypes key
                    delete newItem.ncclTypes;
                }


                return newItem;
            });
        };
        const convertedData = convertMultiSelectArrayToString(removeRefFromJson(processData()));

        const updatedPayload = [] as any;
        convertedData?.map((res: any) => {
            const newObj = {} as any;
            Object.keys(res).map((k: any) => {
                if (keysToKeep.includes(k)) {
                    newObj[k] = res[k]; // Keep the key as it is
                } else {
                    const needToReplace = keyToReplace.filter((f: any) => k.includes(f)) as any;
                    if (needToReplace.length) {
                        newObj[needToReplace[0]] = res[k];
                    } else {
                        newObj[k] = res[k];
                    }
                }
                return true;
            });
            updatedPayload.push(newObj);
            return true;
        });

        const transformedData = updatedPayload.map((item: any) => ({
            x_position: item.x_position,
            y_position: item.y_position,
            title: item.title,
            tag_id: item?.tag_id || undefined,
            finding_details: {
                finding: item.finding,
                tooth_number: item.tooth_number || undefined,
                buccal_mm: item.buccal_mm || undefined,
                lingual_mm: item.lingual_mm || undefined,
                notes: item.notes || undefined,
                tooth_surface: item.tooth_surface || undefined,
                lingual_grade: item?.lingual_grade || undefined,
                buccal_grade: item?.buccal_grade || undefined,
                tooth_site: item.tooth_site || undefined,
                subtype: item.subtype || undefined,
                grade: item?.grade || undefined,
                erosion: item?.erosion || undefined,
                attrition: item?.attrition || undefined,
                is_dentin_exposed: item?.is_dentin_exposed || undefined,
                abrasion: item?.abrasion || undefined,
                abfraction: item?.abfraction || undefined,
                cracks: item?.cracks || undefined,
            }
        }));
        const {
            clinicalExamFilters: {selectedImage,},
            annotation: {annotationDataFromAPI},
        } = yield select((state: RootState) => state);
        const deletedTags = annotationDataFromAPI
            ?.map((tag: any) => tag.tag_id)
            ?.filter(
                (f: string) => !updatedPayload?.map((tag: any) => tag?.tag_id)?.includes(f)
            );

        const response = yield API.post(
            `/v2/images/${payload.payload.imageId}/tags`,
            {
                tags: transformedData,
                delete_tags: deletedTags,
            }
        );
        yield put(getAnnotationRequestAction(`${selectedImage}`))
        yield put(putAnnotationRequestSuccessAction(response?.data?.detail));
        successToaster(response?.data?.meta?.message);
    } catch (e: any) {
        errorToaster(e?.message);
        yield put(
            putAnnotationRequestFailureAction({
                error: e?.message,
            })
        );
    }
}

function* getAnnotationWatch() {
    yield takeEvery(GET_ANNOTATION_REQUEST, getAnnotationRequestSaga);
}

function* putAnnotationWatch() {
    yield takeEvery(PUT_ANNOTATION_REQUEST, putAnnotationRequestSaga);
}

export default function* annotationSaga() {
    yield all([getAnnotationWatch(), putAnnotationWatch()]);
}
