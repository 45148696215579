import React, {createRef, useEffect, useMemo, useState} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import {makeStyles} from '@mui/styles';
import Button from '@mui/material/Button';
import {Box, Divider, Grid, Typography} from '@mui/material';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faClose} from '@fortawesome/free-solid-svg-icons';
import {styled} from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation, useParams} from 'react-router-dom';
import {debounce} from 'lodash';
import {FileUploader} from 'react-drag-drop-files';
import PhotoXrayNameList from './PhotoXrayNameList';
import WebcamUpload from './WebcamUpload';
import BrowsePhotoXray from './BrowsePhotoXray';
import UploadSuccessfully from './UploadSuccessfully';
import Heading from '../../../../v1/Common/Heading';
import theme from '../../../../../theme';
import {referencePhotos, referenceXray} from '../../../../../utils/v2/photoXrayConstant';
import {
    addAdditionalImageXrayAction,
    changeAdditionalImageXrayTitleRequestAction,
    getPhotosRequestAction,
    justUploadedIndexAction,
    putPhotosRequestAction,
    selectPhotosAction,
    updatePhotosXrayValidationRequestRequestAction,
    uploadPhotosAction,
    uploadPhotosModelAction
} from '../../../../../app/actions/v2/dentist/clinicalExam/exam/uploadPhotosXrayAction';
import FullScreenLoader from '../../../../v1/Common/FullScreenLoader';
import TextBox from '../../../common/TextBox';
import {uploadPhotosXrayValidator} from '../../../../../validation/v2/exam/uploadPhotosXray/uploadPhotosXrayValidator';
import ImageCropper from '../../../common/ImageCropper';
import {photo} from '../../../../../utils/v2/clinicalExamConstant';
import {dentistProfileData} from "../../../../../utils/helper";

const useStyles = makeStyles((theme: any) => ({
    DialogActionsClass: {
        padding: '16px 20px',
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between'
    },
    closeButton: {
        padding: '0px !important',
        display: 'flex',
        justifyContent: 'flex-end',
        backgroundColor: 'transparent',
        color: theme.palette.common.black,
        '&:hover': {
            backgroundColor: theme.palette.common.white,
            boxShadow: 'none'
        }
    },
    dialogContent: {
        width: '100% !important',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
    },
    dialogContentTwo: {
        width: '100% !important',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100%'
    },

    verifyButton: {
        height: '40px',
        padding: '10px 20px !important',
        maxWidth: '180px',
        borderRadius: '4px',
        background: theme.palette.v2.primary.main,
        color: theme.palette.common.white,
        textDecoration: 'none',
        textAlign: 'center',
        marginLeft: '10px',
        '&:hover': {
            background: theme.palette.v2.primary.main,
            color: theme.palette.common.white
        }
    },
    skipPhotos: {
        color: theme.palette.v2.secondary,
        marginRight: '10px',
        '&:hover': {
            background: 'transparent',
            color: theme.palette.v2.secondary,
        }
    },
    submitButton: {
        height: '40px',
        padding: '10px 20px !important',
        width: '170px',
        borderRadius: '4px',
        background: theme.palette.v2.secondary,
        color: theme.palette.common.white,
        textDecoration: 'none',
        textAlign: 'center',
        marginLeft: '10px',
        '&:hover': {
            background: theme.palette.v2.secondary,
            color: theme.palette.common.white
        }
    },
    verifyButtonContainer: {
        padding: '12px 20px',
        justifyContent: 'space-between !important'
    },
    cancelBtn: {
        height: '40px',
        padding: '8.5px 20px',
        border: `1px solid ${theme.palette.v2.primary.main}`,
        color: theme.palette.v2.primary.main,
        '&:hover': {
            backgroundColor: 'transparent',
            borderColor: theme.palette.v2.primary.main
        }
    },
    listContainer: {
        border: `1px solid ${theme.palette.text.secondary}`
    },
    nameListContainer: {
        height: 'calc(100vh - 320px)', maxHeight: '550px', overflowY: 'scroll', overflowX: 'hidden'
    },

    imageSize: {
        width: "100%",
        display: "block",
        objectFit: "contain",
        [theme.breakpoints.up(1000)]: {
            maxHeight: "225px",
        },
        [theme.breakpoints.down(1000)]: {
            maxHeight: "225px",
        },
        [theme.breakpoints.down(768)]: {
            maxHeight: "224px",
        },
    },
    dividerClass: {
        width: '100%', borderColor: theme.palette.divider
    },
    remainingBox: {
        border: `1px dashed ${theme.palette.grey['Gray80']}`
    },
    mainContainer: {
        height: 'calc(100vh - 380px)', overflowY: 'scroll', overflowX: 'hidden'
    },
    mainContainerTwo: {
        height: 'calc(100vh - 300px)', overflowY: 'scroll', overflowX: 'hidden'
    },
    imageBox: {
        border: '1px dashed #A39BCB', background: '#F8F8FF', position: 'relative'
    },
    refImage: {
        width: '443px !important', height: '223px  !important', borderRadius: '8px'
    }
}));

const AntSwitch = styled(Switch)(({theme}) => ({
    width: 27,
    height: 17,
    padding: 0,
    display: 'flex',
    '&:active': {
        '& .MuiSwitch-thumb': {
            width: 15,
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(9px)',
        },
    },
    '& .MuiSwitch-switchBase': {
        padding: 2,
        '&.Mui-checked': {
            transform: 'translateX(12px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: '#077178',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 9,
        height: 9,
        borderRadius: 6,
        marginTop: '1.5px',
        transition: theme.transitions.create(['width'], {
            duration: 200,
        }),
    },
    '& .MuiSwitch-track': {
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor:
            theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
        boxSizing: 'border-box',
    },
}));

const FACING_MODE_ENVIRONMENT = "environment";

const UploadPhotosXray = () => {
    const classes = useStyles();
    const dispatch = useDispatch()
    const takeFromUrl = useParams() as any
    const inputFileRef = createRef<any>();
    const webcamRef = React.useRef(null) as any;
    const [auto, setAuto] = useState(true)
    const [windowSize, setWindowSize] = useState([window.innerWidth, window.innerHeight,]);
    const location = useLocation()
    const isWellnessProfile = location?.pathname?.includes('/v2/dentist/wellness-profile/')
    const {
        uploadPhotoXray: {
            justUploadedIndex,
            uploadPhotoModel,
            selectPhotosData,
            uploadPhotosData,
            photosXrayData,
            putPhotosXrayLoader,
            addAdditionalImageXray,
            additionalImageXrayTitle,
            errors,
            photosXrayLoader
        },
        clinicalExamFilters: {findingType},
        appointmentsReducer: {appointmentDetails},
        wellnessProfileFilters: {wellnessProfileStep}

    } = useSelector((state: any) => state);
    const tabData = isWellnessProfile ? wellnessProfileStep : findingType
    const isPhoto = tabData === photo
    const [openCamera, setOpenCamera] = useState(false);
    const [facingMode, setFacingMode] = useState(FACING_MODE_ENVIRONMENT);
    const [imagePath, setImagePath] = useState(null) as any
    const [modalOpen, setModalOpen] = useState(false) as any;
    let imageSrc = null as any
    const videoConstraints = {
        width: 440,
        height: 223,
        video: false,
        facingMode: FACING_MODE_ENVIRONMENT,
    }

    useEffect(() => {
        const handleWindowResize = () => {
            setWindowSize([window?.innerWidth, window?.innerHeight]);
        };
        window?.addEventListener('resize', handleWindowResize);
        return () => {
            window?.removeEventListener('resize', handleWindowResize);
        };
    }, [windowSize]);

    const handleChange = debounce((e: any) => {
        const {value} = e.target as any;
        let updatedAdditionalImageXrayTitle = additionalImageXrayTitle;
        updatedAdditionalImageXrayTitle = value;
        dispatch(changeAdditionalImageXrayTitleRequestAction(updatedAdditionalImageXrayTitle));

        //FOR REMOVE VALIDATION
        dispatch(
            updatePhotosXrayValidationRequestRequestAction({
                ...errors,
                uploadPhotosXray: {
                    ...errors,
                    additionalImageXrayTitle: "",
                },
            })
        );
    }, 1000) as any;

    const getImagesName = useMemo(() => {
        let uploaded = [] as any;
        const imageXrayData = JSON.parse(JSON.stringify(isPhoto ? referencePhotos : referenceXray));
        imageXrayData?.map((res: any) => {
            if (res?.title) {
                uploaded?.push(res?.title);
            } else {
                uploaded = [];
            }
            return true;
        });
        return uploaded;
    }, [isPhoto]);

    const filterImageXrayData = useMemo(() => {
        const filteredPhotoData = referencePhotos as any;
        const examType = appointmentDetails?.appointmentType || "NPE";
        const examTypeWiseFilteredData = isWellnessProfile ? referencePhotos : filteredPhotoData?.filter((f: any) => f?.examType?.includes(examType));
        return isPhoto ? examTypeWiseFilteredData : referenceXray;
    }, [appointmentDetails, isPhoto]);

    const getRemainingPhotoNumber = useMemo(() => {
        if (photosXrayData) {
            const lengthOfImage = photosXrayData?.filter((r: any) =>
                getImagesName?.includes(r?.image_name));
            return (filterImageXrayData)?.length - lengthOfImage?.length;
        }
        return 0;
    }, [photosXrayData, getImagesName, filterImageXrayData]);

    const filterImageData = useMemo(() => {
        const filteredPhotoData = isPhoto ? referencePhotos : referenceXray as any;
        const examType = appointmentDetails?.appointmentType || "NPE";
        const examTypeWiseFilteredData = filteredPhotoData?.filter((f: any) => f?.examType?.includes(examType));

        let imageXrayData = JSON.parse(JSON.stringify(isPhoto ? examTypeWiseFilteredData : referenceXray));
        const newTOAdd = photosXrayData?.filter((f: any) => !getImagesName?.includes(f?.image_name)) || [] as any;
        return [...imageXrayData, ...newTOAdd];
    }, [appointmentDetails, isPhoto, photosXrayData, getImagesName, tabData]);

    const uploadAllPhotoXray = useMemo(() => {
        if (photosXrayData) {
            const lengthOfImage = photosXrayData?.filter((r: any) =>
                getImagesName?.includes(r?.image_name))
            return (filterImageData)?.length <= lengthOfImage?.length
        }
    }, [photosXrayData, getImagesName, filterImageData]);

    const getIndexOfSelectedPhoto = useMemo(() => {
        return filterImageData?.map((res: any) => res?.title)?.indexOf(selectPhotosData?.title);
    }, [filterImageData, selectPhotosData]);

    const selectDataName = useMemo(() => {
        if (selectPhotosData?.ID) {
            return selectPhotosData?.image_name;
        } else {
            return selectPhotosData?.title;
        }
    }, [selectPhotosData]);

    const getSelectedImageData = useMemo(() => {
        if (photosXrayData && selectPhotosData) {
            return photosXrayData?.filter((f: any) => selectDataName === f?.image_name)[0];
        }
        return null;
    }, [photosXrayData, selectPhotosData, selectDataName]);

    const hideTakePhotosButton = useMemo(() => {
        return (
            getSelectedImageData?.ID === undefined &&
            photosXrayData?.map((res: any) => res?.image_name)?.includes(filterImageData[getIndexOfSelectedPhoto]?.title)
        );
    }, [getSelectedImageData, photosXrayData, filterImageData, getIndexOfSelectedPhoto]);

    const displayStartTakingPhotosButton = useMemo(() => {
        if (
            !(uploadAllPhotoXray && !selectPhotosData?.image_id) &&
            ((selectPhotosData?.image_id === undefined && getSelectedImageData === undefined) ||
                (getSelectedImageData?.ID === selectPhotosData?.image_id))
        ) {
            return true;
        }
        return false
    }, [selectPhotosData, uploadAllPhotoXray, getSelectedImageData]);

    const handleClose = () => {
        dispatch(uploadPhotosModelAction(false));
        setOpenCamera(false)
        setAuto(true)
        dispatch(selectPhotosAction(null))
        dispatch(addAdditionalImageXrayAction(false))
        dispatch(justUploadedIndexAction(-1))
    };
    const closeCamera = () => {
        setOpenCamera(false)
    }
    const getVideo = () => {
        if (webcamRef?.current) {
            navigator?.mediaDevices
                .getUserMedia({video: {width: 300}})
                .then((str) => {
                    let video = webcamRef?.current as any
                    if (video) {
                        video.srcObject = str;
                        video?.play();
                    }
                })
                .catch((err) => {
                    console.error("error:", err);
                });
        }
    };
    const uploadImageAPICall = (imageData: any, image_name: any) => {
        const form = new FormData();
        form.append("file", imageData);

        if (isWellnessProfile) {
            form.append("patient_id", takeFromUrl?.patientId);
        } else {
            form.append("appointment_id", takeFromUrl?.appointmentId);
        }

        form.append("type", isPhoto ? "image" : 'xray');
        form.append("image_name", image_name);
        form.append('profile_id', dentistProfileData?.id)
        if (selectPhotosData?.image_id) {
            form.append("image_id", selectPhotosData?.image_id);
        }
        dispatch(putPhotosRequestAction({
            file: form,
            callback: () => {
                dispatch(
                    getPhotosRequestAction({
                        appointment_id: takeFromUrl?.appointmentId,
                        image_type: isPhoto ? "image" : "xray",
                        patient_id: takeFromUrl?.patientId,
                    })
                )
                dispatch(changeAdditionalImageXrayTitleRequestAction(null));
                setImagePath(null)
            },
        }))
        const index = filterImageData.findIndex((item: any) => item?.title === image_name);
        dispatch(justUploadedIndexAction(index))
    }
    useEffect(() => {
        dispatch(justUploadedIndexAction(-1))
    }, [tabData])

    const uploadImagesFromLocal = (e?: any) => {
        // if we use input tag
        // const imageData = e.target.files[0];
        const imageData = e; //Use FileUploader
        setImagePath(imageData)
        if (imageData && imageData?.type) {
            imageSrc = new Blob([imageData], {type: imageData?.type});
            const imageUrl = URL.createObjectURL(imageSrc);
            dispatch(uploadPhotosAction(imageUrl))
            if (!addAdditionalImageXray) {
                uploadImageAPICall(imageData, selectPhotosData?.title)
            }
            // TODO: if crop image functionality needed
            // if (isPhoto) {
            //   setModalOpen(true);
            // } else {
            //   imageSrc = new Blob([imageData], { type: imageData?.type });
            //   const imageUrl = URL.createObjectURL(imageSrc);
            //   dispatch(uploadPhotosAction(imageUrl))
            //   if (!addAdditionalImageXray) {
            //     uploadImageAPICall(imageData, selectPhotosData?.title)
            //   }
            // }
        }
    }

    const rotateImage = (imageSrc: any) => {
        return new Promise((resolve, reject) => {
            const image = new Image();
            image.src = imageSrc;

            image.onload = () => {
                const canvas = document.createElement('canvas');
                canvas.width = image.width;
                canvas.height = image.height;
                const ctx = canvas.getContext('2d') as any

                // Rotate the image by 180 degrees
                ctx.translate(image.width / 2, image.height / 2);
                ctx.rotate(Math.PI);
                ctx.drawImage(image, -image.width / 2, -image.height / 2);

                // Convert the canvas content to data URL
                const rotatedImage = canvas.toDataURL();
                resolve(rotatedImage);
            };

            image.onerror = (error) => {
                reject(error);
            };
        });
    };

    const uploadImagesFromCamera = async () => {
        getVideo();
        if (webcamRef?.current && webcamRef?.current?.getScreenshot()) {
            const imageSrc = webcamRef?.current?.getScreenshot();

            try {
                const isMandibular = selectPhotosData?.title === 'Mandibular occlusal arch'
                const imageRotated = await rotateImage(imageSrc)
                const rotatedImage = isMandibular ? imageRotated : imageSrc as any
                dispatch(uploadPhotosAction(rotatedImage))
                setImagePath(rotatedImage);
                if (!addAdditionalImageXray) {
                    const blob = await fetch(rotatedImage).then((res) => res?.blob());
                    uploadImageAPICall(blob, selectPhotosData?.title);
                }
            } catch (error) {
                console.error('Error:', error);
            }
        }
    };

    useEffect(() => {
        let nextToBeUpload = null as any;
        if (justUploadedIndex === -1 || justUploadedIndex === (filterImageData?.length - 1)) {
            filterImageData?.map((res: any) => {
                if (!photosXrayData?.map((f: any) => f?.image_name)?.includes(res?.title) && nextToBeUpload === null && auto && !addAdditionalImageXray) {
                    nextToBeUpload = res
                }
                return true
            })
        } else {
            filterImageData?.map((res: any, ind: number) => {
                if (!photosXrayData?.map((f: any) => f?.image_name)?.includes(res?.title) && nextToBeUpload === null && auto && !addAdditionalImageXray) {
                    if (ind > justUploadedIndex) {
                        nextToBeUpload = res
                    }
                }
                return true
            })
        }
        if (auto && !selectPhotosData?.image_id && !uploadAllPhotoXray && !addAdditionalImageXray) {
            dispatch(selectPhotosAction(nextToBeUpload))
        }
    }, [photosXrayData, photosXrayData?.length, dispatch, auto, filterImageData, selectPhotosData?.image_id, uploadAllPhotoXray, addAdditionalImageXray, uploadPhotoModel])

    const addAdditionalImageXrayFunc = () => {
        dispatch(addAdditionalImageXrayAction(true))
        dispatch(selectPhotosAction(null))

    }

    const cancelAdditional = () => {
        dispatch(addAdditionalImageXrayAction(false))
        // Remove title
        dispatch(changeAdditionalImageXrayTitleRequestAction(null));
        // for remove image
        dispatch(uploadPhotosAction(null))
        setImagePath(null)
    }

    const submitAdditional = () => {
        const uploadPhotosXrayValid = uploadPhotosXrayValidator(additionalImageXrayTitle) as any;
        dispatch(
            updatePhotosXrayValidationRequestRequestAction({
                ...errors,
                uploadPhotosXray: uploadPhotosXrayValid?.errors,
            })
        );
        if (uploadPhotosXrayValid?.isValid) {
            if (openCamera && imagePath) {
                getVideo();
                fetch(imagePath)
                    .then(async (res) => await res?.blob())
                    .then((blob) => {
                        if (imagePath) {
                            uploadImageAPICall(blob, additionalImageXrayTitle)
                            setOpenCamera(false)
                        }
                    });
            } else {
                uploadImageAPICall(imagePath, additionalImageXrayTitle)
            }
        }

    }

    const skipPhotos = () => {
        let nextIndex = getIndexOfSelectedPhoto + 1
        while (photosXrayData?.map((res: any) => res?.image_name)?.includes(filterImageData[nextIndex]?.title)) {
            nextIndex++
        }
        dispatch(selectPhotosAction(filterImageData[nextIndex > 22 ? 22 : nextIndex]))
    }

    const fileTypes = ["JPG", "PNG", "JPEG"];

    return (
        <React.Fragment>
            <Dialog
                sx={{
                    '& .MuiDialogContent-root': {
                        width: '100% !important',
                        padding: '12px 20px',
                    },
                    '& .MuiPaper-root ': {
                        width: '1020px !important',
                        maxWidth: '1020px !important',
                        background: theme.palette.common.white,
                        boxShadow: ' 0px 10px 20px rgba(0, 0, 0, 0.07)',
                        borderRadius: '16px !important',
                        display: 'flex !important',
                        alignItems: 'center !important',
                        justifyContent: 'center !important',
                        // height: '85%'
                    }
                }}
                open={uploadPhotoModel}
                onClose={handleClose}
            >
                {(putPhotosXrayLoader || photosXrayLoader) && <FullScreenLoader/>}
                <DialogActions className={classes.DialogActionsClass}>
                    <Heading className='f-14 f-w-500 fw-medium' sx={{mb: '0'}}
                             heading={isPhoto ? 'Upload photo' : 'Upload Xray'}/>
                    <Button onClick={handleClose} className={classes.closeButton} disableRipple>
                        <FontAwesomeIcon
                            icon={faClose}
                            color={theme.palette.common.black50}
                            className='f-20'
                            width={'20px'}
                            height={'20px'}
                        />
                    </Button>
                </DialogActions>
                <Divider className={classes.dividerClass}/>
                <DialogContent sx={{height: '100%'}}>
                    <Box className={(windowSize[1] <= 675) ? classes.dialogContentTwo : classes.dialogContent}>
                        <Grid container height={'100%'}
                        >

                            <Grid item xs={12} mb={2}>
                                {getRemainingPhotoNumber > 0 &&
                                    <Box p={'12px'} className={`border-radius-8 ${classes.remainingBox}`}>
                                        <Typography className='labelColor f-w-500 fw-medium f-18'>There
                                            are {getRemainingPhotoNumber} {isPhoto ? 'photos' : "x-rays"} remaining </Typography>
                                    </Box>}
                            </Grid>

                            <Grid item xs={12} sx={{height: 'calc(100% - 100px) !important'}}>
                                <Grid container sx={{height: 'calc(100% - 100px) !important'}}>
                                    <Grid item xs={6} className={classes.nameListContainer}>
                                        {filterImageData?.map((res: any, index: number) =>
                                            <PhotoXrayNameList data={res} key={index}/>
                                        )}
                                    </Grid>
                                    <Grid item xs={6}
                                          className={`${classes.listContainer} ${classes.nameListContainer}`}>
                                        <React.Fragment>
                                            {(uploadAllPhotoXray && (!selectPhotosData?.image_id || selectPhotosData?.image_id === undefined) && !addAdditionalImageXray) ?
                                                <UploadSuccessfully/> : <Box p={'12px 20px'}>
                                                    {!addAdditionalImageXray ?
                                                        <Typography color={theme.palette.v2.secondary}
                                                                    className='f-20 lh-27 f-w-500 fw-medium'>{selectDataName}</Typography> :
                                                        <TextBox
                                                            labelSx={{whiteSpace: 'break-spaces !important'}}
                                                            label={'Title'}
                                                            labelProps={{htmlFor: 'title'}}
                                                            inputProps={{
                                                                id: 'title',
                                                                name: 'title',
                                                                onChange: (e: any) => handleChange(e),
                                                                defaultValue: '',
                                                                fullWidth: true,
                                                                error: errors?.uploadPhotosXray?.additionalImageXrayTitle,
                                                                helperText: errors?.uploadPhotosXray?.additionalImageXrayTitle,
                                                            }}
                                                            isPadding={true}
                                                        />}
                                                    <input
                                                        ref={inputFileRef}
                                                        accept='image/*'
                                                        hidden id='profile'
                                                        type='file'
                                                        onChange={uploadImagesFromLocal}
                                                        onDrop={uploadImagesFromLocal}
                                                    />
                                                    <Box
                                                        p={'0px 9px'}
                                                        height={'225.5px'}
                                                        mt={2.5}
                                                        className={`border-radius-8 d-flex flex-col align-center cursor-pointer ${classes.imageBox}`}
                                                    >
                                                        {((addAdditionalImageXray ? addAdditionalImageXray : (selectPhotosData?.image_id ?
                                                                getSelectedImageData?.ID !== selectPhotosData?.image_id : true))
                                                            && (uploadPhotosData || (getSelectedImageData?.image_path))) ?
                                                            <Box className={'d-flex-all-center'} width={'100%'}
                                                                 height={'100%'}>
                                                                <img
                                                                    className={`border-radius-8 ${classes.imageSize}`}
                                                                    src={uploadPhotosData || getSelectedImageData?.image_path}
                                                                    alt='cameraImage'
                                                                />
                                                            </Box> :
                                                            (
                                                                openCamera ?
                                                                    <React.Fragment>
                                                                        <WebcamUpload
                                                                            webcamRef={webcamRef}
                                                                            openCamera={openCamera}
                                                                            setOpenCamera={setOpenCamera}
                                                                            videoConstraints={videoConstraints}
                                                                            facingMode={facingMode}
                                                                            setFacingMode={setFacingMode}
                                                                        />
                                                                    </React.Fragment>
                                                                    :
                                                                    // <label htmlFor='profile' className='d-flex flex-col align-center cursor-pointer'>
                                                                    <FileUploader draggable
                                                                                  handleChange={uploadImagesFromLocal}
                                                                                  name="file" types={fileTypes}>
                                                                        <BrowsePhotoXray/>
                                                                    </FileUploader>
                                                                // </label>
                                                            )
                                                        }
                                                    </Box>
                                                    {addAdditionalImageXray &&
                                                        <Box className='d-flex justify-end' mt={2}>
                                                            <Button variant='contained' className={classes.verifyButton}
                                                                    disabled={imagePath === null} type='button'
                                                                    onClick={submitAdditional}>Submit</Button>
                                                        </Box>}
                                                    {isPhoto && selectPhotosData?.refImage &&
                                                        <Box height={'225.5px'} mt={2.5}
                                                             sx={{border: '1px dashed #A39BCB'}}
                                                             className='border-radius-8'>
                                                            <Box className={'d-flex-all-center'}>
                                                                <img
                                                                    className={`border-radius-8 image-grey-scale ${classes.imageSize}`}
                                                                    src={selectPhotosData?.refImage}
                                                                    alt='refImage'
                                                                />
                                                            </Box>
                                                        </Box>}
                                                </Box>}
                                        </React.Fragment>
                                    </Grid>
                                </Grid>
                            </Grid>

                            {!addAdditionalImageXray &&
                                <Grid item xs={12} className='d-flex justify-end align-center' mt={2}>
                                    <Typography mr={'12px'}>Proceed to the next photo automatically</Typography>
                                    <AntSwitch defaultChecked inputProps={{'aria-label': 'ant design'}}
                                               onClick={() => setAuto(!auto)}/>
                                </Grid>}
                        </Grid>
                    </Box>
                </DialogContent>
                <Divider className={classes.dividerClass}/>
                <DialogActions className={`d-flex justify-content-between ${classes.verifyButtonContainer} w-100`}>
                    <Box>
                        {!addAdditionalImageXray ?
                            <Button variant='outlined' onClick={openCamera ? closeCamera : handleClose}
                                    className={classes.cancelBtn}>
                                {openCamera ? 'Stop process ' : 'Close'}
                            </Button>
                            :
                            < Button variant='outlined' onClick={cancelAdditional} className={classes.cancelBtn}>
                                cancel
                            </Button>
                        }
                        {!openCamera &&
                            <Button variant='contained' className={classes.verifyButton}
                                    onClick={addAdditionalImageXrayFunc} disabled={addAdditionalImageXray}>
                                Add additional
                            </Button>}
                    </Box>
                    {isPhoto &&
                        (openCamera ?
                            <Box>
                                {getIndexOfSelectedPhoto < 22 && auto && openCamera && !addAdditionalImageXray &&
                                    <Button variant='text' className={classes.skipPhotos} onClick={skipPhotos}>Skip
                                        Photo</Button>}
                                {((selectPhotosData?.image_id === undefined && getSelectedImageData === undefined) ||
                                        (getSelectedImageData?.ID === selectPhotosData?.image_id)) &&
                                    <Button
                                        variant='contained'
                                        disabled={hideTakePhotosButton}
                                        className={classes.submitButton}
                                        onClick={() => uploadImagesFromCamera()}
                                    >
                                        Take Photo
                                    </Button>
                                }
                            </Box>
                            : ((displayStartTakingPhotosButton)
                                && <Button variant='contained' className={classes.verifyButton}
                                           onClick={() => setOpenCamera(true)}>
                                    Start taking photos
                                </Button>))
                    }
                </DialogActions>
            </Dialog>

            <ImageCropper
                modalOpen={modalOpen}
                imagePath={imagePath}
                setModalOpen={setModalOpen}
                uploadImageAPICall={uploadImageAPICall}
            />
        </React.Fragment>
    );
};

export default UploadPhotosXray;
