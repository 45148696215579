import {ClickAwayListener, Tooltip, Typography} from '@mui/material';
import {Box} from '@mui/system';
import React from 'react';
import {styled} from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import {makeStyles} from '@mui/styles';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEllipsisVertical} from '@fortawesome/free-solid-svg-icons';
import {useDispatch, useSelector} from 'react-redux';
import theme from '../../../../../../theme';
import {
    openProcedureTooltipAction,
    showProcedureCodesAction,
    showProcedureFeesAction
} from '../../../../../../app/actions/v2/dentist/clinicalExam/patientReviewAction';
import {RootState} from '../../../../../../app/reducers/v2/rootReducer';

export const AntSwitch = styled(Switch)(() => ({
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
    color: `${theme.palette.v2.primary.main} !important`,
    '&:active': {
        '& .MuiSwitch-thumb': {
            width: 15
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(9px) '
        }
    },
    '& .MuiSwitch-switchBase': {
        padding: 2,
        '&.Mui-checked': {
            transform: 'translateX(12px)',
            color: theme.palette.common.white,
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: `${theme.palette.v2.primary.main} !important`
            }
        }
    },
    '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 12,
        height: 12,
        borderRadius: 6,
        transition: theme.transitions.create(['width'], {
            duration: 200
        })
    },
    '& .MuiSwitch-track': {
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor: 'rgba(0,0,0,.25)',
        boxSizing: 'border-box'
    }
}));

const useStyles = makeStyles((theme: any) => ({
    tooltip: {
        color: `${theme.palette.common.white} !important`
    },
    customWidth: {
        zIndex: '-1',
        width: '240px !important',
        backgroundColor: `${theme.palette.common.white} !important`,
        padding: '20px !important',
        borderRadius: '10px 0px 10px 10px',
        border: '1px solid rgba(51, 51, 51, 0.1)',
        boxShadow: '0px 0px 19px -1px rgba(136, 136, 136, 0.07)'
    }
}));

type Props = {
    procedureTooltip: boolean;
};

const ProcedureDisplayHideTooltip = (props: Props) => {
    const {procedureTooltip} = props;
    const {palette} = theme
    const classes = useStyles();
    const dispatch = useDispatch();
    const {patientReview: {showProcedureCodes, showProcedureFees}} = useSelector((state: RootState) => state);

    const toggleShowProcedureCodes = (e: any) => {
        e?.preventDefault();
        e?.stopPropagation();
        dispatch(showProcedureCodesAction(!showProcedureCodes));
    };
    const toggleShowProcedureFees = (e: any) => {
        e?.preventDefault();
        e?.stopPropagation();
        dispatch(showProcedureFeesAction(!showProcedureFees));
    };

    return (
        <ClickAwayListener onClickAway={(e: any) => {
            dispatch(openProcedureTooltipAction(false))
        }}>
            <Tooltip
                placement='bottom-start'
                open={procedureTooltip}
                className={classes.tooltip}
                classes={{tooltip: classes.customWidth, arrow: classes.tooltip}}
                title={
                    <React.Fragment>
                        <Box
                            // mb={'18px'}
                            className='d-flex align-center justify-content-between'>
                            <Typography sx={{color: `${palette.common.black50} !important`}}>Show Procedure
                                Codes</Typography>
                            <AntSwitch
                                checked={showProcedureCodes === true}
                                value={showProcedureCodes}
                                inputProps={{'aria-label': 'ant design'}}
                                onClick={(e: any) => toggleShowProcedureCodes(e)}

                            />
                        </Box>
                        {/*<Box className='d-flex align-center justify-content-between'>*/}
                        {/*  <Typography sx={{ color: `${palette.common.black50} !important` }}>Show Procedure Fees</Typography>*/}
                        {/*  <AntSwitch checked={showProcedureFees} inputProps={{ 'aria-label': 'ant design' }}*/}
                        {/*    onClick={(e: any) => toggleShowProcedureFees(e)} />*/}
                        {/*</Box>*/}
                    </React.Fragment>
                }
            >
                <FontAwesomeIcon icon={faEllipsisVertical} className={`f-18`} color={palette.common.white}/>
            </Tooltip>
        </ClickAwayListener>
    );
};

export default ProcedureDisplayHideTooltip;
