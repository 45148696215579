import {
    GET_PATIENT_REVIEW_REQUEST,
    GET_PATIENT_REVIEW_REQUEST_SUCCESS,
    GET_PATIENT_REVIEW_REQUEST_FAILURE,
    PUT_PATIENT_REVIEW_TREATMENT_REQUEST,
    PUT_PATIENT_REVIEW_TREATMENT_SUCCESS,
    PUT_PATIENT_REVIEW_TREATMENT_FAILURE,
    PATIENT_REVIEW_TREATMENT_DATA_CHANGE_REQUEST,
    UPDATE_PATIENT_REVIEW_TREATMENT_VALIDATION,
    UPDATE_PATIENT_REVIEW_READINESS_BAR_VALIDATION,
    UPDATE_PATIENT_CONSENT,
    PDF_DOWNLOAD_REQUEST,
    PDF_DOWNLOAD_REQUEST_SUCCESS,
    PDF_DOWNLOAD_REQUEST_FAILURE,
    SHOW_PROCEDURE_CODES,
    SHOW_PROCEDURE_FEES,
    IS_PATIENT_REVIEW_SUBMIT,
    SEARCH_PROCEDURE_CODE_TREATMENT_REQUEST,
    SEARCH_PROCEDURE_CODE_TREATMENT_REQUEST_SUCCESS,
    SEARCH_PROCEDURE_CODE_TREATMENT_REQUEST_FAILURE,
    SELECT_ALTERNATIVE_TREATMENT_DATA,
    EDIT_TREATMENT_PLANNING,
    OPEN_PROCEDURE_TOOLTIP,
    SELECT_ALTERNATIVE_TREATMENT_INDEX,
    SET_ALTERNATIVE_ODONTOGRAM_DATA, DELETE_TREATMENT_DATA,
} from '../../../../ActionTypes/v2/dentist/clinicalExam/patientReview';
import {
    getPatientReviewRequestPayload,
    getPatientReviewRequest,
    getPatientReviewRequestSuccessPayload,
    getPatientReviewRequestSuccess,
    getPatientReviewFailurePayload,
    getPatientReviewRequestFailure,
    putPatientReviewTreatmentRequestPayload,
    putPatientReviewTreatmentRequest,
    putPatientReviewTreatmentFailurePayload,
    putPatientReviewTreatmentFailure,
    putPatientReviewTreatmentSuccessPayload,
    putPatientReviewTreatmentSuccess,
    DownloadPdfRequestPayload,
    DownloadPdfRequestSuccessPayload,
    DownloadPdfFailurePayload,
    DownloadPdfRequest,
    DownloadPdfRequestFailure,
    DownloadPdfRequestSuccess,
    showProcedureCodes,
    showProcedureFees,
    isPatientReviewSubmit,
    searchProcedureCodeTreatmentRequestPayload,
    searchProcedureCodeTreatmentRequestSuccessPayload,
    searchProcedureCodeTreatmentFailurePayload,
    searchProcedureCodeTreatmentRequest,
    searchProcedureCodeTreatmentRequestSuccess,
    searchProcedureCodeTreatmentRequestFailure,
    selectAlternativeTreatmentData,
    isEditTreatmentPlanning,
    openProcedureTooltip,
    selectAlternativeTreatmentIndex,
    setAlternativeOdontogram,
    DeleteTreatmentsDataRequest
} from '../../../../types/v2/dentist/clinicalExam/patientReview';


export const updatePatientConsentAction = (payload: any): any => ({
    type: UPDATE_PATIENT_CONSENT,
    payload
});

export const patientReviewTreatmentChangeAction = (payload: any): any => ({
    type: PATIENT_REVIEW_TREATMENT_DATA_CHANGE_REQUEST,
    payload
});

export const getPatientReviewRequestAction = (payload: getPatientReviewRequestPayload): getPatientReviewRequest => ({
    type: GET_PATIENT_REVIEW_REQUEST,
    payload
});

export const getPatientReviewRequestSuccessAction = (
    payload: getPatientReviewRequestSuccessPayload
): getPatientReviewRequestSuccess => ({
    type: GET_PATIENT_REVIEW_REQUEST_SUCCESS,
    payload
});

export const getPatientReviewRequestFailureAction = (
    payload: getPatientReviewFailurePayload
): getPatientReviewRequestFailure => ({
    type: GET_PATIENT_REVIEW_REQUEST_FAILURE,
    payload
});

export const putPatientReviewTreatmentRequestAction = (
    payload: putPatientReviewTreatmentRequestPayload
): putPatientReviewTreatmentRequest => ({
    type: PUT_PATIENT_REVIEW_TREATMENT_REQUEST,
    payload
});

export const putPatientReviewTreatmentSuccessAction = (
    payload: putPatientReviewTreatmentSuccessPayload
): putPatientReviewTreatmentSuccess => ({
    type: PUT_PATIENT_REVIEW_TREATMENT_SUCCESS,
    payload
});

export const putPatientReviewTreatmentFailureAction = (
    payload: putPatientReviewTreatmentFailurePayload
): putPatientReviewTreatmentFailure => ({
    type: PUT_PATIENT_REVIEW_TREATMENT_FAILURE,
    payload
});

export const updatePatientReviewTreatmentValidationAction = (payload: any): any => ({
    type: UPDATE_PATIENT_REVIEW_TREATMENT_VALIDATION,
    payload
});

export const updatePatientReviewBarValidationAction = (payload: any): any => ({
    type: UPDATE_PATIENT_REVIEW_READINESS_BAR_VALIDATION,
    payload
});

// download PDF

export const downloadPdfRequestAction = (payload: DownloadPdfRequestPayload): DownloadPdfRequest => ({
    type: PDF_DOWNLOAD_REQUEST,
    payload
});

export const downloadPdfRequestSuccessAction = (payload: DownloadPdfRequestSuccessPayload): DownloadPdfRequestSuccess => ({
    type: PDF_DOWNLOAD_REQUEST_SUCCESS,
    payload
});

export const downloadPdfRequestFailureAction = (payload: DownloadPdfFailurePayload): DownloadPdfRequestFailure => ({
    type: PDF_DOWNLOAD_REQUEST_FAILURE,
    payload
});

export const openProcedureTooltipAction = (payload: any): openProcedureTooltip => ({
    type: OPEN_PROCEDURE_TOOLTIP,
    payload
});

export const showProcedureCodesAction = (payload: any): showProcedureCodes => ({
    type: SHOW_PROCEDURE_CODES,
    payload
});

export const showProcedureFeesAction = (payload: any): showProcedureFees => ({
    type: SHOW_PROCEDURE_FEES,
    payload
});

export const isPatientReviewSubmitAction = (payload: boolean): isPatientReviewSubmit => ({
    type: IS_PATIENT_REVIEW_SUBMIT,
    payload
});

// get procedure code and treatment data using search
export const searchProcedureCodeTreatmentRequestAction = (
    payload: searchProcedureCodeTreatmentRequestPayload
): searchProcedureCodeTreatmentRequest => ({
    type: SEARCH_PROCEDURE_CODE_TREATMENT_REQUEST,
    payload
});

export const searchProcedureCodeTreatmentRequestSuccessAction = (
    payload: searchProcedureCodeTreatmentRequestSuccessPayload
): searchProcedureCodeTreatmentRequestSuccess => ({
    type: SEARCH_PROCEDURE_CODE_TREATMENT_REQUEST_SUCCESS,
    payload
});

export const searchProcedureCodeTreatmentRequestFailureAction = (
    payload: searchProcedureCodeTreatmentFailurePayload
): searchProcedureCodeTreatmentRequestFailure => ({
    type: SEARCH_PROCEDURE_CODE_TREATMENT_REQUEST_FAILURE,
    payload
});

export const selectAlternativeTreatmentDataAction = (payload: any): selectAlternativeTreatmentData => ({
    type: SELECT_ALTERNATIVE_TREATMENT_DATA,
    payload
});

export const selectAlternativeTreatmentIndexAction = (payload: any): selectAlternativeTreatmentIndex => ({
    type: SELECT_ALTERNATIVE_TREATMENT_INDEX,
    payload
});
export const isEditTreatmentPlanningAction = (payload: boolean): isEditTreatmentPlanning => ({
    type: EDIT_TREATMENT_PLANNING,
    payload
});
export const setAlternativeOdontogramAction = (payload: any): setAlternativeOdontogram => ({
    type: SET_ALTERNATIVE_ODONTOGRAM_DATA,
    payload
});

export const deleteTreatmentsDataAction = (payload: any): DeleteTreatmentsDataRequest => ({
    type: DELETE_TREATMENT_DATA,
    payload
});