import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { useSelector } from 'react-redux';
import theme from '../../../../../../theme';
import uploadImage from '../../../../../../assets/images/v2/refPhotos/uploadImage.png';
import { photo } from '../../../../../../utils/v2/clinicalExamConstant';

const BrowsePhotoXray = () => {
  const {
    clinicalExamFilters: { findingType }
  } = useSelector((state: any) => state);
  const isPhoto = findingType === photo;

  return (
    <Box my={'30px'} className='d-flex flex-col align-center '>
      <img src={uploadImage} alt='Photos' height={88} width={74} />
      <Typography mt={'15px'} className='f-16 lh-16 f-w-500 fw-medium' component={'span'}>
        <Typography className='f-16 lh-18 f-w-500 fw-medium' component={'span'} color={theme.palette.v2.secondary}>
          {' '}
          Browse for the {isPhoto ? 'image' : 'xray'}
        </Typography>
        {isPhoto && ' or use the camera to take photos'}
      </Typography>
      <Typography mt={2.5} className='f-12 lh-16 f-w-400 fw-regular' color={theme.palette.text.darkGrey}>
        Supported format's: JPEG, JPG and PNG.
      </Typography>
    </Box>
  );
};

export default BrowsePhotoXray;
