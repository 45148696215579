import React, { useEffect, useState } from "react";
import { Box, Grid, Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PatientWellnessRiskScore from "../../../../components/v2/patient/WellnessProfile/PatientWellnessRiskScore";
import HelmetComponent from "../../../../components/v1/Common/HelmetComponent";
import theme from "../../../../theme";
import PatientWellnessImages from "../../../../components/v2/patient/WellnessProfile/PatientWellnessImages";
import PatientWellnessXrays from "../../../../components/v2/patient/WellnessProfile/PatientWellnessXrays";
import PatientWellnessPlannedTreatments from "../../../../components/v2/patient/WellnessProfile/PatientWellnessPlannedTreatments";
import PatientWellnessButtons from "../../../../components/v2/patient/WellnessProfile/PatientWellnessButtons";
import { RootState } from "../../../../app/reducers/v2/rootReducer";
import FullScreenLoader from "../../../../components/v1/Common/FullScreenLoader";
import TabsComponent from "../../../../components/v2/common/TabsComponent";
import { PatientWellnessProfileTabData } from "../../../../utils/v2/wellnessProfileConstant";
import PatientWellnessHygieneRegimen from "../../../../components/v2/patient/WellnessProfile/PatientWellnessHygieneRegimen";
import PatientWellnessReferrals from "../../../../components/v2/patient/WellnessProfile/PatientWellnessReferrals";
import PatientImageXrayList from "../WellnessProfile/PatientImageXrayList";
import { getWellnessProfileWithoutAccountRequestAction } from "../../../../app/actions/v2/patient/WellnessProfileWithoutAccountAction";

const useStyles = makeStyles((theme: any) => ({
  tabContainer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    overflowY: "auto",
    height: "calc(100vh - 80px)",
    [theme.breakpoints.down(470)]: {
      height: "calc(100vh - 130px)",
    },
  },
  buttonAndTabContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: "20px",
    paddingRight: "20px",
    [theme.breakpoints.down(470)]: {
      flexDirection: "column",
      alignItems: "start",
      justifyContent: "start",
      paddingLeft: "0px",
      paddingRight: "20px",
    },
  },
  btnContainer: {
    order: 2,
    [theme.breakpoints.down(470)]: {
      order: 1,
      marginBottom: "10px",
      width: "100%",
      display: "flex",
      justifyContent: "end",
    },
  },
  tabNameContainer: {
    order: 1,
    [theme.breakpoints.down(470)]: {
      order: 2,
    },
  },
}));

const PatientWellnessProfileWithoutAccount = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { clinicId, patientId } = useParams();
  const {
    patientWellnessProfileWithoutAccount: { withoutAccountGetWellnessProfileLoader },
  } = useSelector((state: RootState) => state);
  const [value, setValue] = useState(1);

  useEffect(() => {
    dispatch(
      getWellnessProfileWithoutAccountRequestAction({
        patient_id: patientId,
        clinic_id: clinicId,
      })
    );
  }, []);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <React.Fragment>
      {withoutAccountGetWellnessProfileLoader && <FullScreenLoader />}
      <HelmetComponent title={"Wellness Profile"} />
      <Paper elevation={0} sx={{ background: theme.palette.secondary.light }}>
        <Box
          p={2}
          className={`${classes.buttonAndTabContainer} cursor-pointer`}
        >
          <Box className={classes.tabNameContainer}>
            <TabsComponent
              tabData={PatientWellnessProfileTabData}
              value={value}
              handleChange={handleChange}
              isPatient
            />
          </Box>
          <Box order={1} className={classes.btnContainer}>
            <PatientWellnessButtons />
          </Box>
        </Box>
        <Box className={classes.tabContainer} p={2}>
          {value === 1 && (
            <Box sx={{ background: theme.palette.secondary.light }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={12}>
                  <PatientWellnessRiskScore />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <PatientWellnessImages setValue={setValue} />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <PatientWellnessXrays setValue={setValue} />
                </Grid>
                <Grid item xs={12}>
                  <PatientWellnessHygieneRegimen />
                </Grid>
                <Grid item xs={12}>
                  <PatientWellnessPlannedTreatments />
                </Grid>
                <Grid item xs={12}>
                  <PatientWellnessReferrals />
                </Grid>
                {/*<Grid item xs={12} md={12} lg={12}><PatientWellnessTreatmentPlan/></Grid>*/}
              </Grid>
            </Box>
          )}
          {value === 2 && <PatientImageXrayList flag={"images"} />}
          {value === 3 && <PatientImageXrayList flag={"xrays"} />}
        </Box>
      </Paper>
    </React.Fragment>
  );
};

export default PatientWellnessProfileWithoutAccount;
