//APPOINTMENT LIST
export const PATIENT_APPOINTMENT_REQUEST = 'PATIENT_APPOINTMENT_REQUEST';
export const PATIENT_APPOINTMENT_CACHE_DATA_CLEAR_REQUEST = 'PATIENT_APPOINTMENT_CACHE_DATA_CLEAR_REQUEST';
export const PATIENT_APPOINTMENT_REQUEST_SUCCESS = 'PATIENT_APPOINTMENT_REQUEST_SUCCESS';
export const PATIENT_APPOINTMENT_REQUEST_FAILURE = 'PATIENT_APPOINTMENT_REQUEST_FAILURE';

//APPOINTMENT DETAILS
export const PATIENT_APPOINTMENT_DETAILS_REQUEST = 'PATIENT_APPOINTMENT_DETAILS_REQUEST';
export const PATIENT_APPOINTMENT_DETAILS_CACHE_DATA_CLEAR_REQUEST = 'PATIENT_APPOINTMENT_DETAILS_CACHE_DATA_CLEAR_REQUEST';
export const PATIENT_APPOINTMENT_DETAILS_REQUEST_SUCCESS = 'PATIENT_APPOINTMENT_DETAILS_REQUEST_SUCCESS';
export const PATIENT_APPOINTMENT_DETAILS_REQUEST_FAILURE = 'PATIENT_APPOINTMENT_DETAILS_REQUEST_FAILURE';


export const GET_APPOINTMENT_NOTIFICATION_DENTIST_REQUEST = 'GET_APPOINTMENT_NOTIFICATION_DENTIST_REQUEST';
export const GET_APPOINTMENT_NOTIFICATION_DENTIST_REQUEST_SUCCESS = 'GET_APPOINTMENT_NOTIFICATION_DENTIST_REQUEST_SUCCESS';
export const GET_APPOINTMENT_NOTIFICATION_DENTIST_REQUEST_FAILURE = 'GET_APPOINTMENT_NOTIFICATION_DENTIST_REQUEST_FAILURE';

// set start and end date for appointment filter in dentist side
export const SET_DATE_APPOINTMENT = 'SET_DATE_APPOINTMENT';
