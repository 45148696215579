import {
  PUT_CLINICAL_EXAM_DENTIST_REQUEST,
  PUT_CLINICAL_EXAM_DENTIST_REQUEST_SUCCESS,
  PUT_CLINICAL_EXAM_DENTIST_REQUEST_FAILURE,
  GET_CLINICAL_EXAM_DENTIST_REQUEST,
  GET_CLINICAL_EXAM_DENTIST_REQUEST_SUCCESS,
  GET_CLINICAL_EXAM_DENTIST_REQUEST_FAILURE,
} from "../../../../ActionTypes/v1/dentist/clinicalExamDentist";

import {
  ClinicalExamDentistState,
  ClinicalExamDentistActions,
} from "../../../../types/dentistTypes";

const initialState: ClinicalExamDentistState = {
  dentist_exam_loader: false,
  put_dentist_exam_loader: false,
  clinical_exam_dentist_data: null,
  error: null,
};

const clinicalExamDentistReducer = (
  state = initialState,
  action: ClinicalExamDentistActions
) => {
  switch (action.type) {
    case PUT_CLINICAL_EXAM_DENTIST_REQUEST:
      return {
        ...state,
        put_dentist_exam_loader: true,
      };
    case PUT_CLINICAL_EXAM_DENTIST_REQUEST_SUCCESS:
      return {
        ...state,
        put_dentist_exam_loader: false,
        // clinical_exam_dentist_data: action.payload,
        error: null,
      };

    case PUT_CLINICAL_EXAM_DENTIST_REQUEST_FAILURE:
      return {
        ...state,
        put_dentist_exam_loader: true,
        clinical_exam_dentist_data: null,
        error: action.payload.error,
      };
    case GET_CLINICAL_EXAM_DENTIST_REQUEST:
      return {
        ...state,
        dentist_exam_loader: true,
      };
    case GET_CLINICAL_EXAM_DENTIST_REQUEST_SUCCESS:
      return {
        ...state,
        dentist_exam_loader: false,
        clinical_exam_dentist_data: action.payload,
        error: null,
      };

    case GET_CLINICAL_EXAM_DENTIST_REQUEST_FAILURE:
      return {
        ...state,
        dentist_exam_loader: false,
        clinical_exam_dentist_data: null,
        error: action.payload.error,
      };
    default:
      return {
        ...state,
      };
  }
};
export default clinicalExamDentistReducer;
