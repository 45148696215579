import {
  TO_REDIRECT_CLINICAL_EXAM_STEP,
  TO_REDIRECT_CLINICAL_EXAM_SUB_STEP,
  UPDATE_APPOINTMENT_STEP_REQUEST,
  UPDATE_APPOINTMENT_STEP_REQUEST_SUCCESS,
  UPDATE_APPOINTMENT_STEP_REQUEST_FAILURE,
  TO_HIDE_FOOTER_REQUEST,
} from "../../../../ActionTypes/v1/dentist/clinicalExam";

const initialState: any = {
  step: 0,
  subStep: 0,
  mode: null,
  completedStep: null,
  appointment_id: null,
  completedStepLoader: false,
  completedStepError: false,
  hide: false,
};

const clinicalExamReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case TO_REDIRECT_CLINICAL_EXAM_STEP:
      return {
        ...state,
        step: action.payload.step,
      };
    case TO_REDIRECT_CLINICAL_EXAM_SUB_STEP:
      return {
        ...state,
        subStep: action.payload.subStep,
        mode: action.payload.mode,
      };
    case TO_HIDE_FOOTER_REQUEST:
      return {
        ...state,
        hide: action.payload.hide,
      };
    case UPDATE_APPOINTMENT_STEP_REQUEST:
      return {
        ...state,
        completedStepLoader: true,
      };
    case UPDATE_APPOINTMENT_STEP_REQUEST_SUCCESS:
      return {
        ...state,
        completedStep: action.payload,
        appointment_id: action.payload,
        completedStepError: null,
        completedStepLoader: false,
      };
    case UPDATE_APPOINTMENT_STEP_REQUEST_FAILURE:
      return {
        ...state,
        completedStep: null,
        completedStepError: action.payload,
        completedStepLoader: false,
      };
    default:
      return {
        ...state,
      };
  }
};
export default clinicalExamReducer;
