import {
  CHANGE_REVIEW_TREATMENT_PLAN,
  GET_REVIEW_TREATMENT_PLAN_REQUEST,
  GET_REVIEW_TREATMENT_PLAN_CACHE_DATA_CLEAR_REQUEST,
  GET_REVIEW_TREATMENT_PLAN_REQUEST_SUCCESS,
  GET_REVIEW_TREATMENT_PLAN_REQUEST_FAILURE,
  PUT_REVIEW_TREATMENT_PLAN_REQUEST,
  PUT_REVIEW_TREATMENT_PLAN_REQUEST_SUCCESS,
  PUT_REVIEW_TREATMENT_PLAN_REQUEST_FAILURE,
  UPDATE_REVIEW_TREATMENT_PLAN,
} from "../../../../../ActionTypes/v1/dentist/reviewTreatmentPlan";
import {
  ReviewTreatmentPlanInterface,
  ReviewTreatmentPlanAction,
} from "../../../../../types/dentistTypes";

const initialState: ReviewTreatmentPlanInterface = {
  treatmentData: null,
  review_treatment_plan_loader: false,
  review_treatment_plan_error: null,
  treatmentDataLocal: null,
  putTreatmentData: null,
};

const reviewTreatmentPlanReducer = (
  state = initialState,
  action: ReviewTreatmentPlanAction
) => {
  switch (action.type) {
    case UPDATE_REVIEW_TREATMENT_PLAN:
      let treatmentData = action.payload
      treatmentData = treatmentData?.treatmentDataLocal?.map((res: any, i: number) => {
        res.position = i
        return res
      })

      return {
        ...state,
        treatmentDataLocal: treatmentData,
      };
    case CHANGE_REVIEW_TREATMENT_PLAN:
      let newTreatment = state.treatmentData;
      const { ID, is_approved, is_interested, is_alternative_approved, is_alternative_interested } = action.payload;
      newTreatment = newTreatment?.map((res: any) => {
        if (res?.ID === ID) {
          return {
            ...res,
            is_approved: is_approved,
            is_interested: is_interested,
            is_alternative_approved: is_alternative_approved,
            is_alternative_interested: is_alternative_interested
          };
        }
        return res;
      });
      return {
        ...state,
        treatmentDataLocal: newTreatment,
      };

    case GET_REVIEW_TREATMENT_PLAN_REQUEST:
      return {
        ...state,
        review_treatment_plan_loader: true,
      };
    case GET_REVIEW_TREATMENT_PLAN_CACHE_DATA_CLEAR_REQUEST:
      return {
        ...state,
        treatmentData: null,
        treatmentDataLocal: null,
        putTreatmentData: null,
      }
    case GET_REVIEW_TREATMENT_PLAN_REQUEST_SUCCESS:
      return {
        ...state,
        review_treatment_plan_loader: false,
        treatmentData: action.payload?.treatmentData,
        review_treatment_plan_error: null,
      };
    case GET_REVIEW_TREATMENT_PLAN_REQUEST_FAILURE:
      return {
        ...state,
        review_treatment_plan_loader: false,
        treatmentData: null,
        review_treatment_plan_error: action.payload,
      };
    case PUT_REVIEW_TREATMENT_PLAN_REQUEST:
      return {
        ...state,
        review_treatment_plan_loader: true,
      };
    case PUT_REVIEW_TREATMENT_PLAN_REQUEST_SUCCESS:
      return {
        ...state,
        review_treatment_plan_loader: false,
        putTreatmentData: action.payload,
        treatmentDataLocal: action.payload?.treatmentData,
        review_treatment_plan_error: null,
      };
    case PUT_REVIEW_TREATMENT_PLAN_REQUEST_FAILURE:
      return {
        ...state,
        review_treatment_plan_loader: false,
        treatmentData: null,
        review_treatment_plan_error: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};

export default reviewTreatmentPlanReducer;
