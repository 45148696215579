import React, {useMemo} from 'react';
import {Box, Button, Chip, CircularProgress, Grid, InputLabel, Stack, Tooltip} from '@mui/material';
import {useDispatch, useSelector} from "react-redux";
import {makeStyles} from "@mui/styles";
import InputHeading from '../../../common/InputHeading';
import DropDown from '../../../common/DropDown';
import theme from '../../../../../theme';
import CardComponent from "../../../common/CardComponent";
import {RootState} from "../../../../../app/reducers/v2/rootReducer";
import NoDataFound from "../../../../v1/Common/NoDataFound";
import TextBox from "../../../common/TextBox";
import {
    examCycleDropdown,
    extentDropdown,
    gradingDropdown,
    hygieneCycleDropdown,
    stagingDropdown, xRayCycleDropdown
} from "../../../../../utils/v2/newAdminHandoffConstant";
import {
    changeWellnessHygieneRegimeRequestAction,
    updateWellnessHygieneRegimeRequestAction
} from "../../../../../app/actions/v2/dentist/wellnessProfile/wellnessProfileFiltersAction";

const useStyle = makeStyles((theme: any) => ({
    saveButton: {
        color: `${theme.palette.common.white} !important`,
        backgroundColor: theme.palette.v2.primary.main,
        width: '100%',
        maxWidth: '190px',
        "&:hover": {
            color: `${theme.palette.common.white} !important`,
            backgroundColor: theme.palette.v2.primary.main,
        },

    },
}));
const WellnessHygieneRegimen = () => {
    const {palette} = theme
    const classes = useStyle()
    const dispatch = useDispatch()
    const {
        wellnessProfileFilters: {
            wellnessProfileData, changeWellnessHygieneRegimeLocal,
            updateWellnessHygieneRegimeLoader
        }
    } = useSelector((state: RootState) => state);
    const hygieneRegimeData = changeWellnessHygieneRegimeLocal

    const handleChange = (event: any) => {
        const {value, name} = event?.target as any
        let changeWellnessHygieneRegimeLocalTemp = changeWellnessHygieneRegimeLocal
        changeWellnessHygieneRegimeLocalTemp[name] = value || null
        dispatch(changeWellnessHygieneRegimeRequestAction(changeWellnessHygieneRegimeLocalTemp))
    }
    const saveHygieneRegimeData = () => {
        dispatch(updateWellnessHygieneRegimeRequestAction(changeWellnessHygieneRegimeLocal))
    }
    const disabledFiled = useMemo(() => {
        return true
        // return wellnessProfileData?.is_wellness_locked
    }, [wellnessProfileData?.is_wellness_locked])


    return (
        <CardComponent bgColor={theme.palette.v2.primary.primary25} color={theme.palette.v2.primary.main}
                       title='Hygiene Regimen'>
            {hygieneRegimeData ?
                <React.Fragment>
                    <Grid container rowSpacing={1.5} columnSpacing={2.5} mb={2.5}>
                        <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
                            <InputHeading title={'Staging'}/>
                            <DropDown
                                selectOptions={stagingDropdown}
                                fromHygieneTreatment={true}
                                inputBackColor={theme?.palette?.common?.white}
                                disabled={disabledFiled}
                                selectProps={{
                                    name: 'staging',
                                    value: hygieneRegimeData?.staging,
                                    onChange: (e: any) => handleChange(e),
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
                            <InputHeading title={'Grading'}/>
                            <DropDown
                                fromHygieneTreatment={true}
                                selectOptions={gradingDropdown}
                                inputBackColor={theme?.palette?.common?.white}
                                disabled={disabledFiled}
                                selectProps={{
                                    name: 'grading',
                                    value: hygieneRegimeData?.grading,
                                    onChange: (e: any) => handleChange(e),
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
                            <InputHeading title={'Extent'}/>
                            <DropDown
                                selectOptions={extentDropdown}
                                fromHygieneTreatment={true}
                                inputBackColor={theme?.palette?.common?.white}
                                disabled={disabledFiled}
                                selectProps={{
                                    name: 'extent',
                                    value: hygieneRegimeData?.extent,
                                    onChange: (e: any) => handleChange(e),
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container rowSpacing={1.5} columnSpacing={2.5}>
                        <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                            <InputHeading title={'Hygiene Cycle'}/>
                            <DropDown
                                selectOptions={hygieneCycleDropdown}
                                fromHygieneTreatment={true}
                                inputBackColor={theme?.palette?.common?.white}
                                disabled={disabledFiled}
                                prefixLabel={"Months"}
                                selectProps={{
                                    name: 'hygiene',
                                    value: hygieneRegimeData?.hygiene_cycle,
                                    onChange: (e: any) => handleChange(e),
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                            <InputHeading title={'Exam Cycle'}/>
                            <DropDown
                                selectOptions={examCycleDropdown}
                                fromHygieneTreatment={true}
                                inputBackColor={theme?.palette?.common?.white}
                                disabled={disabledFiled}
                                prefixLabel={"Months"}
                                selectProps={{
                                    name: 'exam',
                                    value: hygieneRegimeData?.exam_cycle,
                                    onChange: (e: any) => handleChange(e),
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                            <InputHeading title={'X-Ray Cycle'}/>
                            <DropDown
                                selectOptions={xRayCycleDropdown}
                                fromHygieneTreatment={true}
                                inputBackColor={theme?.palette?.common?.white}
                                disabled={disabledFiled}
                                prefixLabel={"Months"}
                                selectProps={{
                                    name: 'xray',
                                    value: hygieneRegimeData?.xray_cycle,
                                    onChange: (e: any) => handleChange(e),
                                }}
                            />
                        </Grid>
                        {hygieneRegimeData?.adjunctive?.length ?
                            <Grid item xs={12}>
                                <InputLabel
                                    htmlFor="adjunctive"
                                    sx={{mb: "8px"}}
                                    className={`labelColor fw-regular f-14 lh-19 f-w-350`}
                                >
                                    Adjunctive
                                </InputLabel>
                                <Stack direction="row" gap={1} p={'12px'}
                                       border={`1px solid ${palette.divider}`} borderRadius={'8px'} flexWrap={'wrap'}>
                                    {hygieneRegimeData?.adjunctive?.map((res: any, index: number) =>
                                        <Box>
                                            <Tooltip className={'fw-regular f-16'} title={res} key={index}>
                                                <Box
                                                    sx={{
                                                        textOverflow: 'ellipsis',
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                    }}
                                                >
                                                    <Chip
                                                        disabled={disabledFiled}
                                                        label={res}
                                                        component="a"
                                                        className={'fw-regular'}
                                                        // onDelete={() => removeAdjunctive(res)}
                                                        sx={{
                                                            textOverflow: 'ellipsis',
                                                            whiteSpace: 'nowrap',
                                                            overflow: 'hidden',
                                                            maxWidth: '170px',
                                                            '.MuiChip-root': {
                                                                color: palette.common.black50,
                                                                backgroundColor: palette.divider
                                                            }
                                                        }}
                                                    />
                                                </Box>

                                            </Tooltip>
                                        </Box>
                                    )}
                                </Stack>
                            </Grid>
                            : null
                        }
                        <Grid item xs={12}>
                            <TextBox
                                label={'Notes'}
                                labelProps={{
                                    htmlFor: 'notes'
                                }}
                                isPadding={true}
                                maxLength={1000}
                                disabled={disabledFiled}
                                inputProps={{
                                    id: 'notes',
                                    name: 'notes',
                                    value: hygieneRegimeData?.notes,
                                    error: false,
                                    helperText: '',
                                    fullWidth: true,
                                    onChange: (e: any) => handleChange(e),
                                    wrap: true,
                                    InputProps: {
                                        classes: {
                                            root: "border-radius-8 textFieldEndIconPadding textFieldStartIconPadding bg-white",
                                            input: "textFieldStartEndIcon",
                                        }
                                    }
                                }}
                            />

                        </Grid>
                        {/*{!disabledFiled && <Grid item xs={12}>*/}
                        {/*    <Box display={'flex'} justifyContent={'end'}>*/}
                        {/*        <Button onClick={saveHygieneRegimeData}*/}
                        {/*                className={`fw-regular f-14 ${classes.saveButton}`}*/}
                        {/*                variant={'contained'}>*/}
                        {/*            {updateWellnessHygieneRegimeLoader ? (*/}
                        {/*                <CircularProgress color={"primary"} size={24}/>*/}
                        {/*            ) : ('Save hygiene regimen')}*/}

                        {/*        </Button>*/}
                        {/*    </Box>*/}
                        {/*</Grid>}*/}
                    </Grid>
                </React.Fragment> : <Box py={1.5}> <NoDataFound errorMessage={'hygiene regimen'}/></Box>
            }
        </CardComponent>
    )
}

export default WellnessHygieneRegimen