import { call, put, takeEvery, all } from "redux-saga/effects";
import API from "../../../../../../utils/api";
import {
  putReadinessBarRequestSuccess,
  putReadinessBarRequestFailure,
  getReadinessBarRequestSuccess,
  getReadinessBarRequestFailure,
} from "../../../../../actions/v1/dentist/assignedTreatment/ReadinessBarActions/ReadinessBarActions";
import {
  GET_READINESS_BAR_REQUEST,
  PUT_READINESS_BAR_REQUEST,
} from "../../../../../ActionTypes/v1/dentist/readinessBar";
import { errorToaster } from '../../../../../../utils/helper';

function* getReadinessBarRequestSaga(payload: any): any {
  try {
    const response = yield API.get(
      `/dentist/readiness/score/${payload?.payload?.appointmentId}`
    );
    yield call(payload.payload.callback, response.data);
    if (payload?.payload?.callback) {
      payload?.payload?.callback(response?.data);
    }
    yield put(
      getReadinessBarRequestSuccess({
        readinessBarData: response.data,
      })
    );
  } catch (e: any) {
     //TODO: update toast according to status code
    //   errorToaster(e?.detail);
    yield put(
      getReadinessBarRequestFailure({
        readinessBarError: e?.detail,
      })
    );
  }
}

function* putReadinessBarRequestSaga(payload: any): any {
  try {
    const response = yield API.put(
      `/dentist/update/readiness/score?appointment_id=${payload?.payload?.appointmentId}`,
      payload.payload
    );
    yield call(payload.payload.callback, response.data);
    if (payload?.payload?.callback) {
      payload?.payload?.callback(response?.data);
    }
    yield put(
      putReadinessBarRequestSuccess({
        readinessBarData: response?.data,
      })
    );
  } catch (e: any) {
    errorToaster(e?.detail)
    yield put(
      putReadinessBarRequestFailure({
        readinessBarError: e?.detail,
      })
    );
  }
}

function* getReadinessBarSaga() {
  yield takeEvery(GET_READINESS_BAR_REQUEST, getReadinessBarRequestSaga);
}
function* putReadinessBarSaga() {
  yield takeEvery(PUT_READINESS_BAR_REQUEST, putReadinessBarRequestSaga);
}
export default function* rootSaga() {
  yield all([getReadinessBarSaga(), putReadinessBarSaga()]);
}
