import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton, InputAdornment } from "@mui/material";
import React from "react";
import theme from "../../../../theme";

const EndAdornmentIcon = (props: any) => {
  const { icon, disabled } = props;
  return (
    <InputAdornment
      position="end"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginLeft: "0px",
        maxHeight: "",
        marginRight: "13px",
        "& :hover": {
          cursor: disabled ? "default" : "pointer",
        },
      }}
    >
      <IconButton
        disableFocusRipple
        disableRipple
        edge="end"
        sx={{
          width: "48px",
          height: "48px",
        }}
      >
        <FontAwesomeIcon
          icon={icon}
          style={{
            height: "16px",
            color: disabled
              ? theme.palette.text.secondary
              : theme.palette.text.darkGrey,
          }}
        />
      </IconButton>
    </InputAdornment>
  );
};

export default EndAdornmentIcon;
