import React, {useMemo} from 'react'
import {Button, Grid, Tooltip, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";
import ExamsCustomFields from "../ExamsCustomFields";
import {examList} from "../../../../../utils/helper";
import {findingData} from "../../../../../utils/v2/examConstant";
import DropDown from "../../../common/DropDown";
import InputHeading from "../../../common/InputHeading";


const useStyles = makeStyles((theme: any) => ({
    tooltip: {
        color: `${theme.palette.common.white} !important`,

    },
    customWidth: {
        maxWidth: 500,
        width: "45vw !important",
        backgroundColor: `${theme.palette.background.paper} !important`,
        padding: "20px !important",

    },
    toolTipPopper: {
        zIndex: 9999
    },
    annotationTitle: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "15px 15px",
        fontWeight: 600,
    }
}));

type Props = {
    markerData: any
    setOpenTooltip: any
    openTooltip: any
    annotationData: any
}
const AnnotationsTooltip = (props: Props) => {
    const {markerData, setOpenTooltip, openTooltip, annotationData} = props
    const classes = useStyles();
    const handleMarkerClick = () => {
        if (openTooltip?.tagId === markerData.tag_id) {
            setOpenTooltip({tagId: null, findings: null});
        } else {
            setOpenTooltip({tagId: markerData.tag_id, findings: markerData.findings});
        }
    };
    const getTag = () => {
        return annotationData?.filter((f: any) => f?.tag_id === openTooltip?.tagId)?.filter((f: any) => f?.tag_id === openTooltip?.tagId)
    }
    const getSelectedFindingData = () => {
        if (openTooltip?.findings) {
            return findingData.filter((f: any) => f?.key === openTooltip?.findings)[0]?.options
        }
        return null
    }
    const getFindingLists = () => {
        return findingData?.map((res: any) => {
            return {
                value: res?.key,
                name: res?.title
            }
        })
    }
    const getFindingName = useMemo(() => {
        const findingList = getFindingLists();
        const finding = findingList?.find(f => f?.value === openTooltip?.findings);
        return finding?.name || '';
    }, [openTooltip?.findings]);
    return (
        <Tooltip
            className={classes.tooltip}
            style={{}}
            classes={{tooltip: `${classes.customWidth}`, arrow: classes.tooltip, popper: classes.toolTipPopper}}
            title={
                <Grid container rowSpacing={1}
                      sx={{marginLeft: '0px !important'}}>
                    {getFindingName && <Grid item xs={12}>
                        <InputHeading title={'Finding'}/>
                        <DropDown
                            selectOptions={getFindingLists()}
                            selectProps={{value: getFindingName}}
                            disabled={true}
                            sx={{height: '50px'}}
                        />
                    </Grid>}
                    {openTooltip?.tagId &&
                        getSelectedFindingData()?.map((option: any, k: number) => (
                            <ExamsCustomFields
                                key={k}
                                exam={examList[markerData.title]?.key || ''}
                                fieldData={option}
                                finding={openTooltip?.findings}
                                index={k}
                                isMulti={undefined}
                                tag={getTag()[0]}
                                fromAnnotation
                                annotationData={annotationData}
                            />
                        ))
                    }
                    <Grid item mt={1} xs={12} display={'flex'} justifyContent={'end'} className={`cursor-pointer`}>
                        <Button color={'error'} variant={'outlined'}
                                onClick={() => setOpenTooltip({tagId: null, findings: null})}>
                            Close
                        </Button>
                    </Grid>
                </Grid>
            }
            open={openTooltip?.tagId === markerData.tag_id}
            arrow
        >
            <Typography
                className={`fw-regular image-marker__marker image-marker__marker--default cursor-pointer ${classes.annotationTitle} ${openTooltip?.tagId === markerData.tag_id ? 'selected-marker-style' : ''} }`}
                data-testid="marker"
                onClick={() => handleMarkerClick()}
                sx={{background: markerData?.color}}
            >
                {markerData?.title}
            </Typography>
        </Tooltip>
    )
}
export default AnnotationsTooltip;