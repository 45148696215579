import { TmjDentistInterface } from "../../../../types/dentistTypes";
import {
  UPDATE_TMJ_DENTIST, UPDATE_TMJ_DENTIST_FAILURE,
  CHANGE_TMJ_DENTIST,
} from "../../../../ActionTypes/v1/dentist/clinicalExamDentist";
import { clickData, painData, crepitusData } from "../../../../../utils/dentistTmjConstant"
import { clickCacheData, painCacheData, crepitusCacheData } from "../../../../../utils/cacheDataConstant/dentistTmjExamConstant";
import { jsonParseAndStringfy } from "../../../../../utils/helper";

const initialState: TmjDentistInterface = {
  click: clickData,
  pain: painData,
  crepitus: crepitusData,
  click_change_2_years: "",
  tmj_click_start_date: null,
  tmj_locked_jaw_hx_notes: "",
  tmj_click_hx_notes: "",
  tmj_pain_scale: null,
  tmj_trigger_notes: "",
  tmj_headache_present: "",
  tmj_comment: ''
};

export const tmjDentistReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case UPDATE_TMJ_DENTIST:
      return {
        ...state,
        ...action.payload,
      };
    case UPDATE_TMJ_DENTIST_FAILURE:
      const newClickData = jsonParseAndStringfy(clickCacheData)
      const newPainData = jsonParseAndStringfy(painCacheData)
      const newCrepitusData = jsonParseAndStringfy(crepitusCacheData)
      return {
        click: newClickData,
        pain: newPainData,
        crepitus: newCrepitusData,
        click_change_2_years: "",
        tmj_click_start_date: null,
        tmj_locked_jaw_hx_notes: "",
        tmj_click_hx_notes: "",
        tmj_pain_scale: null,
        tmj_trigger_notes: "",
        tmj_headache_present: "",
        tmj_comment: ''
      }
    case CHANGE_TMJ_DENTIST:
      const { toFinding, value, key } = action.payload as any;
      const newState = state as any;
      let newFinding = newState[toFinding] as any;

      if (
        typeof newFinding !== "string" &&
        typeof newFinding !== "number" &&
        newFinding !== null
      ) {
        newFinding = newFinding?.map((res: any) => {
          if (res.name === key) {
            return { ...res, value: value };
          }
          return res;
        });
      } else {
        newFinding = value;
      }

      return {
        ...state,
        [toFinding]: newFinding,
      };
    default:
      return { ...state };
  }
};
