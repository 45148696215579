import { Box, Typography } from '@mui/material'
import React, { useMemo } from 'react'
import { makeStyles } from "@mui/styles";
import { useDispatch } from "react-redux";
import { useLocation, useParams } from 'react-router-dom';
import PopupComponent from "../../../common/PopupComponent";
import { formatPhoneNumber, getLocalStorageItem, patientProfileData } from "../../../../../utils/helper";
import {
    getWellnessProfileRequestAction
} from "../../../../../app/actions/v2/dentist/wellnessProfile/wellnessProfileFiltersAction";
import theme from "../../../../../theme";
import { getWellnessProfileWithoutAccountRequestAction } from '../../../../../app/actions/v2/patient/WellnessProfileWithoutAccountAction';

type Props = {
    openConfirmationPopUp: boolean
    handleClose: any
    treatmentStatus: any
    treatmentDetails: any
}
const useStyles = makeStyles((theme: any) => ({
    contentText: {
        fontSize: "16px",
        lineHeight: "20px",
        fontWeight: 400,
        textAlign: "center",
        display: 'inline',
        color: theme.palette.common.black50
    },
    hyperLink: {
        color: theme.palette.common.black,
        textDecoration: "none"
    }
}));

const ConfirmationMessagePopUp = (props: Props) => {
    const { openConfirmationPopUp, handleClose, treatmentStatus, treatmentDetails } = props
    const dispatch = useDispatch()
    const classes = useStyles()
    const location = useLocation()
    const { clinicId, patientId } = useParams();

    const withoutAccountWellnessProfile: boolean = useMemo(() => {
        return location?.pathname?.includes('/patient/without-account/wellness-profile/')
    }, [location.pathname]);

    const patient_id = patientProfileData?.id;
    const continueHandler = () => {
        handleClose()
        withoutAccountWellnessProfile ?
            dispatch(
                getWellnessProfileWithoutAccountRequestAction({
                    patient_id: patientId,
                    clinic_id: clinicId,
                })
            )
            :
            dispatch(getWellnessProfileRequestAction({
                patient_id: patient_id,
                isPatient: true,
                clinic_id: getLocalStorageItem('patientClinic') || ''
            }));
    }
    return (
        <PopupComponent isPatient saveBtnText={'Ok'} width={'570px'} headerAction={false}
            footerAction={true} open={openConfirmationPopUp} handleClose={handleClose}
            handleSave={continueHandler} notDisplayCancel>
            <Box py={2}>
                <>
                    {treatmentStatus === 'approved' ?
                        <Typography lineHeight={1.2} color={theme.palette.common.black50}
                            className={'f-16 f-w-400 fw-regular labelColor'} textAlign={'start'}>
                            {`We have sent an email to ${treatmentDetails?.clinic_name} letting them know you would like to accept the treatment. You should receive a call or email from your clinic soon to schedule your appointment. THIS IS NOT A CONFIRMATION OF A BOOKED APPOINTMENT. If you would rather not wait, 
                              you can reach ${treatmentDetails?.clinic_name} at`
                            }&nbsp;
                            <Typography className={`${classes.contentText} f-16 fw-regular`} mt={"15px"}>
                                <a className={classes.hyperLink}
                                    href={`tel:${treatmentDetails?.mobile?.replace("-", "")}`}>
                                    {formatPhoneNumber(treatmentDetails?.mobile)} </a>
                            </Typography>
                        </Typography>
                        : treatmentStatus === 'rejected' ?
                            <Typography lineHeight={1.2} color={theme.palette.common.black50}
                                className={'f-16 f-w-400 fw-regular labelColor'} textAlign={'start'}>
                                {`We have sent an email to ${treatmentDetails?.clinic_name} letting them know you would like to discuss your treatment. You should receive a call or email from your clinic soon regarding your appointment. THIS IS NOT A CONFIRMATION OF A CANCELLED APPOINTMENT. Any missed appointment or late cancellation fees will still apply. If this is urgent,
                                 please ensure you connect with ${treatmentDetails?.clinic_name} to discuss your decision. They can be reached at`
                                }&nbsp;
                                <Typography className={`${classes.contentText} f-16 fw-regular`} mt={"15px"}>
                                    <a className={classes.hyperLink}
                                        href={`tel:${treatmentDetails?.mobile?.replace("-", "")}`}>
                                        {formatPhoneNumber(treatmentDetails?.mobile)} </a>
                                </Typography>
                            </Typography>
                            : <Typography lineHeight={1.2} color={theme.palette.common.black50}
                                className={'f-16 f-w-400 fw-regular labelColor'} textAlign={'start'}>
                                {`We have sent an email to ${treatmentDetails?.clinic_name} letting them know you would like to mark the treatment pending. You should receive a call or email from your clinic soon regarding your appointment. THIS IS NOT A CONFIRMATION OF A BOOKED OR CANCELLED APPOINTMENT. Any missed appointment or late cancellation fees will still apply. If this is urgent, 
                                please ensure you connect with ${treatmentDetails?.clinic_name} to discuss your decision. They can be reached at`
                                }&nbsp;
                                <Typography className={`${classes.contentText} f-16 fw-regular`} mt={"15px"}>
                                    <a className={classes.hyperLink}
                                        href={`tel:${treatmentDetails?.mobile?.replace("-", "")}`}>
                                        {formatPhoneNumber(treatmentDetails?.mobile)} </a>
                                </Typography>
                            </Typography>
                    }</>
            </Box>
        </PopupComponent>
    )
}

export default ConfirmationMessagePopUp
