import { Box } from '@mui/material';
import React from 'react';
import Webcam from 'react-webcam';
import { makeStyles } from '@mui/styles';
import CameraswitchIcon from '@mui/icons-material/Cameraswitch';
import theme from '../../../../../../theme';
import { errorToaster } from '../../../../../../utils/helper';

const useStyles = makeStyles((theme: any) => ({
  facingModeBox: {
    position: 'absolute',
    bottom: 2,
    right: 7,
    zIndex: 1
  }
}));

const FACING_MODE_USER = 'user';
const FACING_MODE_ENVIRONMENT = 'environment';

type Props = {
  webcamRef: any;
  openCamera: any;
  setOpenCamera: any;
  videoConstraints: any;
  facingMode: any;
  setFacingMode: any;
};
const WebcamUpload = (props: Props) => {
  const { webcamRef, openCamera, setOpenCamera, videoConstraints, facingMode, setFacingMode } = props;
  const classes = useStyles();

  const handleClick = () => {
    if (facingMode === FACING_MODE_USER) {
      setFacingMode(FACING_MODE_ENVIRONMENT);
    } else {
      setFacingMode(FACING_MODE_USER);
    }
  };

  const userMediaError = () => {
    setTimeout(() => {
      setOpenCamera(!openCamera);
    }, 3000);
    errorToaster('Camera not found');
  };

  return (
    <React.Fragment>
      <Box className={classes.facingModeBox}>
        <CameraswitchIcon color={theme.palette.common.black50} onClick={() => handleClick()} />
      </Box>
      <Box className={'d-flex-all-center'}>
        <Webcam
          audio={false}
          ref={webcamRef}
          style={{
            borderRadius: '8px',
            width: '441px',
            height: '224px'
          }}
          screenshotFormat='image/jpeg'
          onUserMediaError={() => userMediaError()}
          videoConstraints={{
            ...videoConstraints,
            facingMode
          }}
          screenshotQuality={1}
          minScreenshotWidth={441}
          minScreenshotHeight={224}
          width={441}
          height={224}
          forceScreenshotSourceSize={true}
        />
      </Box>
    </React.Fragment>
  );
};

export default WebcamUpload;
