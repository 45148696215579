import {
  ADD_PERIODONTAL_FINDING_ROW,
  CHANGE_PERIODONTAL_FINDING, DELETE_PERIODONTAL_FINDING_ROW,
  UPDATE_PERIODONTAL, UPDATE_PERIODONTAL_FAILURE,
  UPDATE_PERIODONTAL_RECALL_EXAM,
  ADD_PERIODONTAL_FINDING_ROW_RECALL_EXAM,
  CHANGE_PERIODONTAL_FINDING_RECALL_EXAM,
  DELETE_PERIODONTAL_FINDING_ROW_RECALL_EXAM
} from "../../../../ActionTypes/v1/dentist/clinicalExamDentist";
import {PeriodontalInterface, PeriodontalRecallExamInterface} from "../../../../types/dentistTypes";

export const updatePeriodontalRequest = (
  payload: PeriodontalInterface
): PeriodontalInterface => ({
  type: UPDATE_PERIODONTAL,
  payload,
}) as any;

export const updatePeriodontalRequestFailure = (): any => ({
  type: UPDATE_PERIODONTAL_FAILURE,
}) as any;

export const addPeriodontalFindingRowAction = (payload: string): any => ({
  type: ADD_PERIODONTAL_FINDING_ROW,
  payload
}) as any;

export const changePeriodontalFindingAction = (payload: any): any => ({
  type: CHANGE_PERIODONTAL_FINDING,
  payload
}) as any;

export const deletePeriodontalFindingRowAction = (payload: any): any => ({
  type: DELETE_PERIODONTAL_FINDING_ROW,
  payload
}) as any;

