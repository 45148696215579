import {
    GET_TAG_XRAY_ALL_REQUEST,
    GET_TAG_XRAY_ALL_REQUEST_SUCCESS,
    GET_TAG_XRAY_ALL_REQUEST_FAILURE,
    GET_XRAY_APPOINTMENT_REQUEST,
    GET_XRAY_APPOINTMENT_CACHE_DATA_CLEAR_REQUEST,
    GET_XRAY_APPOINTMENT_REQUEST_SUCCESS,
    GET_XRAY_APPOINTMENT_REQUEST_FAILURE,
    DELETE_XRAY_APPOINTMENT_REQUEST,
    DELETE_XRAY_APPOINTMENT_REQUEST_SUCCESS,
    DELETE_XRAY_APPOINTMENT_REQUEST_FAILURE,
    CREATE_TAG_REQUEST,
    CREATE_TAG_REQUEST_FAILURE,
    DELETE_TAG_REQUEST,
    DELETE_TAG_REQUEST_SUCCESS,
    DELETE_TAG_REQUEST_FAILURE,

    GET_TAG_DATA_REQUEST,
    GET_TAG_DATA_REQUEST_SUCCESS,
    GET_TAG_DATA_REQUEST_FAILURE,

    UPDATE_TAG_DATA_REQUEST,
    UPDATE_TAG_DATA_REQUEST_FAILURE,
    UPDATE_TAG_DATA_REQUEST_SUCCESS,

    PUT_CONTRAST_REQUEST,
    PUT_CONTRAST_REQUEST_SUCCESS,
    PUT_CONTRAST_REQUEST_FAILURE,

    PUT_CROPPED_IMAGE_REQUEST,
    PUT_CROPPED_IMAGE_REQUEST_SUCCESS,
    PUT_CROPPED_IMAGE_REQUEST_FAILURE,
    UPDATE_CONTRAST,
    UPDATE_CROPPED_IMAGE,
} from '../../../../ActionTypes/v1/dentist/clinicalExamDentist'

import {
    GetTagXrayAllRequest,
    GetTagXrayAllRequestSuccess,
    GetTagXrayAllRequestFailure,
    GetTagXrayAllRequestPayload,
    GetTagXrayAllRequesttSuccessPayload,
    GetTagXrayAllRequestFailurePayload,

    GetXrayAppointmentRequest,
    GetXrayAppointmentRequestSuccess,
    GetXrayAppointmentRequestFailure,
    GetXrayAppointmentRequestPayload,
    GetXrayAppointmentRequestSuccessPayload,
    GetXrayAppointmentRequestFailurePayload,
    DeleteXrayAppointmentRequest,
    DeleteXrayAppointmentRequestSuccess,
    DeleteXrayAppointmentRequestFailure,
    DeleteXrayAppointmentRequestPayload,
    DeleteXrayAppointmentSuccessPayload,
    DeleteXrayAppointmentFailurePayload,

    CreateTagRequest,
    CreateTagRequestFailure,
    CreateTagRequestPayload,
    CreateTagRequestFailurePayload,

    DeleteTagRequest,
    DeleteTagRequestSuccess,
    DeleteTagRequestFailure,
    DeleteTagRequestPayload,
    DeleteTagRequestSuccessPayload,
    DeleteTagRequestFailurePayload,

    GetTagDataRequest,
    GetTagDataRequestSuccess,
    GetTagDataRequestFailure,
    GetTagDataRequestPayload,
    GetTagDataRequestSuccessPayload,
    GetTagDataRequestFailurePayload,

    UpdateTagRequest,
    UpdateTagRequestFailure,
    UpdateTagRequestPayload,
    UpdateTagRequestFailurePayload,
    UpdateTagRequestSuccess,
    UpdateTagRequestSuccessPayload,


    PutContrastRequest,
    PutContrastRequestSuccess,
    PutContrastRequestFailure,
    PutContrastRequestPayload,
    PutContrastRequestSuccessPayload,
    PutContrastRequestFailurePayload,


    PutCroppedImageRequest,
    PutCroppedImageRequestSuccess,
    PutCroppedImageRequestFailure,
    PutCroppedImageRequestPayload,
    PutCroppedImageRequestFailurePayload,
    PutCroppedImageRequestSuccessPayload,
    UpdateContrast,
    UpdateContrastInterfaces,
    UpdateCroppedImageInterfaces,
    UpdateCroppedImage,

} from '../../../../types/dentistTypes'

// Get tag LIST  xray 
export const getTagXrayAllRequest = (payload: GetTagXrayAllRequestPayload): GetTagXrayAllRequest => ({
    type: GET_TAG_XRAY_ALL_REQUEST,
    payload
})
export const getTagXrayAllRequestSuccess = (payload: GetTagXrayAllRequesttSuccessPayload): GetTagXrayAllRequestSuccess => ({
    type: GET_TAG_XRAY_ALL_REQUEST_SUCCESS,
    payload
})
export const getTagXrayAllRequestFailure = (payload: GetTagXrayAllRequestFailurePayload): GetTagXrayAllRequestFailure => ({
    type: GET_TAG_XRAY_ALL_REQUEST_FAILURE,
    payload
})


// get xray and images form the specific appointment

export const getXrayAppointmentRequest = (payload: GetXrayAppointmentRequestPayload): GetXrayAppointmentRequest => ({
    type: GET_XRAY_APPOINTMENT_REQUEST,
    payload
})
export const getXrayAppointmentCacheDataClearRequest = ():any => ({
    type: GET_XRAY_APPOINTMENT_CACHE_DATA_CLEAR_REQUEST
})
export const getXrayAppointmentRequestSuccess = (payload: GetXrayAppointmentRequestSuccessPayload): GetXrayAppointmentRequestSuccess => ({
    type: GET_XRAY_APPOINTMENT_REQUEST_SUCCESS,
    payload
})
export const getXrayAppointmentRequestFailure = (payload: GetXrayAppointmentRequestFailurePayload): GetXrayAppointmentRequestFailure => ({
    type: GET_XRAY_APPOINTMENT_REQUEST_FAILURE,
    payload
})
export const updateContrastAction = (payload: UpdateContrast): UpdateContrastInterfaces => ({
    type: UPDATE_CONTRAST,
    payload
})

export const updateCroppedImageAction = (payload: UpdateCroppedImage): UpdateCroppedImageInterfaces => ({
    type: UPDATE_CROPPED_IMAGE,
    payload
})

// delete xray / image from specific appointment
export const deleteXrayAppointmentRequest = (payload: DeleteXrayAppointmentRequestPayload): DeleteXrayAppointmentRequest => ({
    type: DELETE_XRAY_APPOINTMENT_REQUEST,
    payload
})
export const deleteXrayAppointmentRequestSuccess = (payload: DeleteXrayAppointmentSuccessPayload): DeleteXrayAppointmentRequestSuccess => ({
    type: DELETE_XRAY_APPOINTMENT_REQUEST_SUCCESS,
    payload
})
export const deleteXrayAppointmentRequestFailure = (payload: DeleteXrayAppointmentFailurePayload): DeleteXrayAppointmentRequestFailure => ({
    type: DELETE_XRAY_APPOINTMENT_REQUEST_FAILURE,
    payload
})
//for get tag (anotations) data
export const getTagDataRequest = (payload: GetTagDataRequestPayload): GetTagDataRequest => ({
    type: GET_TAG_DATA_REQUEST,
    payload
})
export const getTagDataRequestSuccess = (payload: GetTagDataRequestSuccessPayload): GetTagDataRequestSuccess => ({
    type: GET_TAG_DATA_REQUEST_SUCCESS,
    payload
})
export const getTagDataRequestFailure = (payload: GetTagDataRequestFailurePayload): GetTagDataRequestFailure => ({
    type: GET_TAG_DATA_REQUEST_FAILURE,
    payload
})



//for create tag (anotations)

export const createTagRequest = (payload: CreateTagRequestPayload): CreateTagRequest => ({
    type: CREATE_TAG_REQUEST,
    payload
})
export const createTagRequestFailure = (payload: CreateTagRequestFailurePayload): CreateTagRequestFailure => ({
    type: CREATE_TAG_REQUEST_FAILURE,
    payload
})

//for Update tag (anotations)

export const updateTagRequest = (payload: UpdateTagRequestPayload): UpdateTagRequest => ({
    type: UPDATE_TAG_DATA_REQUEST,
    payload
})
export const updateTagRequestFailure = (payload: UpdateTagRequestFailurePayload): UpdateTagRequestFailure => ({
    type: UPDATE_TAG_DATA_REQUEST_FAILURE,
    payload
})

export const updateTagRequestSuccess = (payload: UpdateTagRequestSuccessPayload): UpdateTagRequestSuccess => ({
    type: UPDATE_TAG_DATA_REQUEST_SUCCESS,
    payload
})

//for Delete tag (anotations)

export const deleteTagRequest = (payload: DeleteTagRequestPayload): DeleteTagRequest => ({
    type: DELETE_TAG_REQUEST,
    payload
})
export const deleteTagRequestSuccess = (payload: DeleteTagRequestSuccessPayload): DeleteTagRequestSuccess => ({
    type: DELETE_TAG_REQUEST_SUCCESS,
    payload
})
export const deleteTagRequestFailure = (payload: DeleteTagRequestFailurePayload): DeleteTagRequestFailure => ({
    type: DELETE_TAG_REQUEST_FAILURE,
    payload
})

// for put contrast value

export const putContrastRequest = (payload: PutContrastRequestPayload): PutContrastRequest => ({
    type: PUT_CONTRAST_REQUEST,
    payload
})
export const putContrastRequestFailure = (payload: PutContrastRequestFailurePayload): PutContrastRequestFailure => ({
    type: PUT_CONTRAST_REQUEST_FAILURE,
    payload
})
export const putContrastRequestSuccess = (payload: PutContrastRequestSuccessPayload): PutContrastRequestSuccess => ({
    type: PUT_CONTRAST_REQUEST_SUCCESS,
    payload
})

//for crop image(put cropped image)


export const putCroppedImageRequest = (payload: PutCroppedImageRequestPayload): PutCroppedImageRequest => ({
    type: PUT_CROPPED_IMAGE_REQUEST,
    payload
})
export const putCroppedImageRequestFailure = (payload: PutCroppedImageRequestFailurePayload): PutCroppedImageRequestFailure => ({
    type: PUT_CROPPED_IMAGE_REQUEST_FAILURE,
    payload
})
export const putCroppedImageRequestSuccess = (payload: PutCroppedImageRequestSuccessPayload): PutCroppedImageRequestSuccess => ({
    type: PUT_CROPPED_IMAGE_REQUEST_SUCCESS,
    payload
})