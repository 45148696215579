import React from "react";
import {Checkbox, FormControlLabel, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";

const CheckboxSelect = (props: any) => {
    const {checkBoxProps, label, sx, disabled, fromPatientConsent, isPatient} = props;

    const useStyles = makeStyles((theme: any) => ({
        checkBoxClass: {
            [theme.breakpoints.down('sm')]: {
                height: "35px",
            },
            height: "50px",
            color: theme.palette.common.black50,
            padding: "0 10px 0 0",
            margin: "0px 0px 8px 0px",
            "& .MuiButtonBase-root": {
                color: disabled
                    ? theme.palette.text.secondary
                    : isPatient ? theme.palette.secondary.main : theme.palette.v2.primary.main,
                marginTop: fromPatientConsent && '-13px',
                [theme.breakpoints.down(1045)]: {
                    marginTop: fromPatientConsent && '-26px'
                },
                [theme.breakpoints.down(857)]: {
                    marginTop: fromPatientConsent && '-27px'
                },
            },
            "& .MuiFormControlLabel-label": {
                fontFamily: "FavoritPro-Regular !important",
            },
        },
        typography: {
            fontSize: "12px",
            lineHeight: "14px",
            margin: "-5px 0 10px 10px",
            color: theme?.palette?.error?.main,
        },
    }));
    const classes = useStyles();

    return (
        <React.Fragment>
            <FormControlLabel
                disabled={disabled}
                control={<Checkbox {...checkBoxProps} />}
                label={label}
                className={`${classes.checkBoxClass}`}
                sx={{...sx}}
            />
            {checkBoxProps.error && (
                <Typography className={`${classes.typography}`}>
                    {checkBoxProps.error}
                </Typography>
            )}
        </React.Fragment>
    );
};

export default CheckboxSelect;
