import { alphabetRegex } from "../helper";

export const treatmentTabData = [
    {
        title: 'Planned Treatments',
        value: 1,
    }, {
        title: 'Past Treatment Plans',
        value: 2,
    },
]
export const outstandingTreatmentTabData = [
    {
        title: 'Planned Treatments',
        value: 1,
    }, {
        title: 'Past Treatment Plans',
        value: 2,
    },
    {
        title: 'Outstanding PMS Treatments',
        value: 3,
    }
]

export const plannedTreatmentColumns = [
    {
        label: 'Sr. No.',
        width: "7%",
    },
    {
        label: "Treatment Description",
        width: "50%",
        textAlign: "center",
    },
    {
        label: "Created at",
        width: "20%",
        textAlign: "left",
    },
    {
        label: "Procedure Code",
        width: "20%",
        textAlign: "left",
    },
    {
        label: "Status",
        width: "20%",
        textAlign: "center",
    },
    // TODO: IF WE SHOW FEES
    // {
    //     label: "Procedure Fees",
    //     width: "20%",
    //     textAlign: "left",
    // },

]
export const outstandingTreatmentsColumns = [
    {
        label: 'Sr. No.',
        width: "7%",
    },
    {
        label: "Description",
        width: "20%",
        textAlign: "center",
    },
    {
        label: "Procedure Code",
        width: "40%",
        textAlign: "left",
    },
    {
        label: "Fee",
        width: "40%",
        textAlign: "left",
    },
    {
        label: "Procedure Date",
        width: "30%",
        textAlign: "left",
    },

]

export const PatientPrefAndSocialNotesTabData = [
    {
        title: 'Chief Concerns',
        value: 1,
    },
    {
        title: 'Patient Preferences',
        value: 2,
    },
    {
        title: 'Personal Notes',
        value: 3,
    }]

export const appointmentTabData = [
    {
        title: 'Upcoming Appointments',
        value: 1,
    }, {
        title: 'Past Appointments',
        value: 2,
    }]

export const appointmentColumns = [
    {
        label: 'Date & Time',
        width: "20%",
    },
    {
        label: "Clinic Name",
        width: "30%",
        textAlign: "center",
    },
    {
        label: "Dentist",
        width: "10%",
        textAlign: "left",
    },
    {
        label: "Assistant",
        width: "10%",
        textAlign: "left",
    },
    {
        label: "Hygienist",
        width: "10%",
        textAlign: "left",
    },
    {
        label: "Action",
        width: "60%",
        textAlign: "center",
    },

]
// export const wellnessProfileSteps = [
//     'Patient Information',
//     'Photos',
//     'X-Rays',
//     'Soft Tissue',
//     'Hard Tissue',
//     'Odontogram',
//     'Periogram',
//     'Medical',
//     'Treatments'
// ];

export const wellnessProfileSteps = [
    {
        title: 'Patient Information',
        value: 'patientInformation',
        examType: ['NPE', 'RE', 'LE'],
        examStatus: ''
    },
    {
        title: 'Photos',
        value: 'photo',
        examType: ['NPE', 'RE', 'LE'],
        examStatus: ''
    },
    {
        title: 'X-Rays',
        value: 'xray',
        examType: ['NPE', 'RE'],
        examStatus: ''
    },
    {
        title: 'Soft Tissue',
        value: 'softTissue',
        examType: ['NPE', 'RE'],
        examStatus: ''
    },
    {
        title: 'Hard Tissue',
        value: 'hardTissue',
        examType: ['NPE', 'RE'],
        examStatus: ''
    }, {
        title: 'Odontogram',
        value: 'odontogram',
        examType: ['NPE', 'RE', 'LE'],
        examStatus: ''
    }, {
        title: 'Periogram',
        value: 'periogram',
        examType: ['NPE', 'RE', 'LE'],
        examStatus: ''
    },
    // {
    //     title: 'Medical',
    //     value: 'medical',
    //     examType: ['NPE', 'RE'],
    //     examStatus: ''
    // },
    // {
    //     title: 'Treatment',
    //     value: 'treatment',
    //     examType: ['NPE', 'RE'],
    //     examStatus: ''
    // },

]
export const patientPastTreatmentColumns = [
    {
        label: 'Sr. No.',
        minWidth: 50,
    },
    {
        label: 'Treatment Description',
        minWidth: 720,
        maxWidth: 500,
    },
    {
        label: 'Procedure Code',
        minWidth: 235,
        maxWidth: 235,
    },
    // {
    //     label: 'Procedure Fees',
    //     minWidth: 235,
    //     maxWidth: 200,
    // },
    // {
    //     label: 'Total Fees',
    //     minWidth: 235,
    //     maxWidth: 200,
    // },
    {
        label: 'Insurance Status',
        minWidth: 250,
        maxWidth: 170,
    },

    {
        label: 'Treatment Status',
        minWidth: 250,
        maxWidth: 170,
    }
]
export const pastTreatmentColumns = [
    {
        label: 'Sr. No.',
        minWidth: 50,
    },
    {
        label: 'Treatment Description',
        minWidth: 720,
        maxWidth: 500,
    },
    {
        label: 'Procedure Code',
        minWidth: 235,
        maxWidth: 235,
    },
    {
        label: "Status",
        width: "20%",
        textAlign: "left",
    },
    // TODO: if we show fees
    // {
    //     label: 'Procedure Fees',
    //     minWidth: 235,
    //     maxWidth: 200,
    // },
    // {
    //     label: 'Total Fees',
    //     minWidth: 235,
    //     maxWidth: 200,
    // },
    {
        label: 'Insurance Status',
        minWidth: 250,
        maxWidth: 170,
    },

    {
        label: 'Treatment Status',
        minWidth: 250,
        maxWidth: 170,
    }
]

export const countTotalFeesPastAppointment = (fees: any, quantity: number) => {
    if (fees?.includes("-") || alphabetRegex.test(fees)) {
        return "-"
    } else {
        const fee = parseFloat(fees)?.toFixed(2) as any
        const updatedFees = ((fee) * (quantity || 1))?.toFixed(2) as any
        return "$ " + (updatedFees)
    }

}

export const patientPlannedTreatmentsColumns = [
    {
        label: "Sr No.",
        width: "7%",
        id: 1,
    },
    {
        label: "Treatment Description",
        width: "40%",
    },
    // {
    //     label: "Procedure Code",
    //     width: "10%",
    //     textAlign: "center",
    // },
    // {
    //     label: "Procedure Fees",
    //     width: "10%",
    //     textAlign: "center",
    // },
    // {
    //     label: "Action",
    //     width: "33%",
    //     textAlign: "center",
    // },
];

export const PatientWellnessProfileTabData = [
    {
        title: 'Wellness Profile',
        value: 1,
    },
    {
        title: 'Images',
        value: 2,
    },
    {
        title: 'X-rays',
        value: 3,
    }]
