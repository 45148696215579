import {Box} from '@mui/material';
import * as d3 from "d3";
import {isArray} from "lodash";
import React, {useCallback, useEffect, useMemo, useRef} from 'react';
import {useSelector} from 'react-redux';
import {SurfaceLineData} from './SurfaceLineData';
import theme from "../../../../../../theme";
import {procedureColors} from "../../../../../../utils/v2/procedureHelper";

type Props = {
    tooth: any;
    isBottomTeeth?: boolean | undefined
    filteredFinding: any
};

const OdontogramSurface = (props: Props) => {
    const {palette} = theme
    const {tooth, isBottomTeeth, filteredFinding} = props
    const toothNumber = isBottomTeeth ? (tooth + 20) : tooth
    const surfaceBox = useRef(null) as any
    const {
        examFindingReducer: {examFindingData},
        odontogram: {procedureFilters, showRejectedTreatmentsOnly, cameOdontogramFromTreatment}
    } = useSelector((state: any) => state) as any;
    const surfaceSeq = ['top', 'right', 'bottom', 'left', 'center'] as any;

    const getDataFromToothNumber = useMemo(() => {
        let data = [] as any
        if (examFindingData) {
            Object.keys(examFindingData)?.map((examGroup: string) => {
                if (examFindingData[examGroup]) {
                    Object.keys(examFindingData[examGroup])?.map((finding: string) => {
                        if (examFindingData[examGroup][finding] && isArray(examFindingData[examGroup][finding]) && filteredFinding.includes(finding)) {
                            const findingsWithMyTooth = examFindingData[examGroup][finding].filter((findingData: any) => findingData?.tooth_number && findingData?.tooth_number?.length && findingData?.tooth_number[0] === `${toothNumber}`)
                            findingsWithMyTooth.map((res: any) => {
                                data.push({...res, finding})
                                return true
                            })
                        }
                        return true
                    })
                }
                return true
            })
        }

        return data
    }, [examFindingData, filteredFinding, toothNumber])

    const getSurfaceKey = useCallback((side: string) => {
        const surfaceList = {
            top: 'Buccal',
            bottom: 'Lingual',
            left: (tooth > 30 && tooth < 39) || (tooth > 20 && tooth < 29) ? 'Mesial' : 'Distal',
            right: (tooth > 30 && tooth < 39) || (tooth > 20 && tooth < 29) ? 'Distal' : 'Mesial',
            center: 'Occlusal/Incisal'
        } as any;
        return surfaceList[side];
    }, [tooth]);

    const surfacesData = (detail?: any) => {
        const svg = d3?.select(surfaceBox?.current);
        const surfacesData = {
            top: {
                d: "M25.5733 0.804688C26.5686 0.804688 27.4729 1.1911 28.1281 1.81352L21.6074 8.00459C21.2707 7.68555 20.8059 7.48828 20.2926 7.48828H9.08798C8.57316 7.48828 8.10715 7.68671 7.77018 8.00741L1.24609 1.81352C1.90223 1.1911 2.80651 0.804688 3.80086 0.804688H25.5733Z",
                fill: palette.common.skyBg,
                stroke: palette.common.skyBorder,
                strokeWidth: '0.409272'
            },
            right: {
                d: "M28.1288 1.81445C28.7849 2.43698 29.1911 3.29543 29.1911 4.23981V24.9115C29.1911 25.8559 28.7849 26.7145 28.1288 27.337L21.6055 21.1433C21.9424 20.8235 22.1508 20.3816 22.1508 19.8935V9.25555C22.1508 8.76803 21.9429 8.32662 21.6067 8.00689L28.1288 1.81445Z",
                fill: palette.common.skyBg,
                stroke: palette.common.skyBorder,
                strokeWidth: '0.409272'
            },
            bottom: {
                d: "M7.77014 21.1406C8.10712 21.4613 8.57314 21.6598 9.08798 21.6598H20.2926C20.8059 21.6598 21.2707 21.4625 21.6075 21.1434L28.1281 27.3346C27.4729 27.957 26.5686 28.3433 25.5733 28.3433H3.80086C2.80651 28.3433 1.90223 27.957 1.24609 27.3346L7.77014 21.1406Z",
                fill: palette.common.skyBg,
                stroke: palette.common.skyBorder,
                strokeWidth: '0.409272'
            },
            left: {
                d: "M0.191406 4.23981C0.191406 3.29543 0.598509 2.43698 1.25376 1.81445L7.77742 8.00794C7.44184 8.32759 7.23438 8.76857 7.23438 9.25555V19.8935C7.23438 20.3811 7.44232 20.8225 7.77861 21.1422L1.25376 27.337C0.598509 26.7145 0.191406 25.8559 0.191406 24.9115V4.23981Z",
                fill: palette.common.skyBg,
                stroke: palette.common.skyBorder,
                strokeWidth: '0.409272'
            },
            center: {
                d: "M20.2887 7.48828H9.08407C8.05604 7.48828 7.22266 8.27951 7.22266 9.25555V19.8935C7.22266 20.8695 8.05604 21.6608 9.08407 21.6608H20.2887C21.3167 21.6608 22.1501 20.8695 22.1501 19.8935V9.25555C22.1501 8.27951 21.3167 7.48828 20.2887 7.48828Z",
                fill: palette.common.skyBg,
                stroke: palette.common.skyBorder,
                strokeWidth: '0.409272'
            }
        } as any
        if (detail) {
            return surfaceSeq?.filter((d: string) => d === detail?.side)?.map((surfaceType: any) => {
                const data = surfacesData[surfaceType];
                return svg.append('path')
                    .attr('d', data.d)
                    .attr('fill', detail?.fill)
                    .attr('stroke', detail.stroke)
                    .attr('stroke-width', detail.stroke ? 1 : '0.409272')
            })
        } else {
            return surfaceSeq.map((surfaceType: any) => {
                const data = surfacesData[surfaceType];
                return svg.append('path')
                    .attr('d', data.d)
                    .attr('fill', data.fill)
                    .attr('stroke', data.stroke)
                    .attr('stroke-width', data.strokeWidth)
                    .attr('stroke-miterlimit', '10');
            })
        }
    }
    const displayOnlyPlannedFindingForTreatment = (procedureStatus: any) => {
        return cameOdontogramFromTreatment ? procedureStatus === 'Planned' : true
    }
    const handleSurfaceSvg = () => {
        if (surfaceBox?.current) {
            const svg = d3?.select(surfaceBox?.current);
            surfacesData()?.map((surfaces: any) => {
                return surfaces
            })
            const showLinesBySubType = ['Composite', 'Temporary/Provisional', 'Metal Ceramic', 'Fixture and Crown']
            const crownSubtypeForStroke = ['All Ceramic', 'Metal Ceramic', 'Provisional']
            const findingsForFillSurfaces = ['watch', 'decay', 'filling', 'crown']
            getDataFromToothNumber?.map((toothData: any) => {
                surfaceSeq?.map((side: string) => {
                    const svgInfo = {} as any
                    const {tooth_surface, finding, subtype, procedure_status, rejected} = toothData as any
                    // for fill
                    if (displayOnlyPlannedFindingForTreatment(procedure_status) && (procedureFilters?.includes(procedure_status) || procedureFilters?.length < 1) &&
                        (!showRejectedTreatmentsOnly || (showRejectedTreatmentsOnly && rejected))) {
                        if ((tooth_surface && tooth_surface?.includes(getSurfaceKey(side))) &&
                            findingsForFillSurfaces?.includes(finding) &&
                            ((finding === 'watch') || (finding === 'filling' && subtype === 'Amalgam')) && procedure_status) {
                            svgInfo.fill = procedureColors[procedure_status].color
                        } else if ((finding === 'crown' && toothData?.subtype === 'Full Metal') && subtype && findingsForFillSurfaces?.includes(finding) && procedure_status) {
                            svgInfo.fill = procedureColors[procedure_status].color
                        } else if (finding === 'decay' && (tooth_surface && tooth_surface?.includes(getSurfaceKey(side))) && findingsForFillSurfaces?.includes(finding)) {
                            svgInfo.fill = palette.common.black
                        }
                        //for stroke
                        if (displayOnlyPlannedFindingForTreatment(procedure_status) && (finding === 'crown' && crownSubtypeForStroke?.includes(subtype)) || (finding === 'implant' && subtype === 'Fixture and Crown') || (finding === 'non_carious_cervical_lesions' && tooth_surface && tooth_surface?.includes(getSurfaceKey(side)))) {
                            svgInfo.stroke = procedureColors[procedure_status].color
                        }
                        if (Object.keys(svgInfo)?.length > 0) {
                            surfacesData({
                                fill: svgInfo.fill,
                                stroke: svgInfo.stroke,
                                side,
                            })?.map((surfaces: any) => {
                                return surfaces
                            })
                        }
                        //for line
                        const isLine = displayOnlyPlannedFindingForTreatment(procedure_status) && showLinesBySubType?.includes(subtype) &&
                            (((finding === 'filling') && (tooth_surface && tooth_surface?.includes(getSurfaceKey(side)))) ||
                                (finding === 'crown') || (finding === 'implant'))
                        if (isLine) {
                            SurfaceLineData(side, svg, procedureColors[procedure_status].color)?.map((surfaces: any) => {
                                return surfaces
                            })
                        }
                        if (subtype === 'Provisional' || subtype === 'Temporary/Provisional' && displayOnlyPlannedFindingForTreatment(procedure_status)) {
                            svg.append("circle")
                                .attr("cx", 15.2)
                                .attr("r", 6.5)
                                .attr("cy", 15.2)
                                .attr('stroke', (((finding === 'crown' && subtype === 'Provisional') || (finding === 'filling' && subtype === 'Temporary/Provisional')) ? procedureColors[procedure_status].color : null))
                                .style("stroke-width", 1.2)
                        }
                    }
                    return true
                })
            })
        }
    }
    useEffect(() => {
        handleSurfaceSvg()
    }, [surfaceBox?.current, handleSurfaceSvg]);
    return (
        // for hide tooth surface in specific teeth ::  ${noSurfaceTeeth.includes(toothNumber) ? classes.noSurfaceTeeth : ''}
        <Box mt={2} mb={2}>
            <svg ref={surfaceBox} width="32" height="31" viewBox="0 0 32 31" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
                {/* You can add more SVG elements or components here */}
            </svg>
        </Box>
    );
};

export default OdontogramSurface;
