import React, { useEffect } from 'react';
import { Box, Grid, InputLabel, Tab, Tabs } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import {
    generateRiskWellnessPMSRequestAction,
    getWellnessProfileRequestAction,
    updateWellnessProfileStepAction
} from '../../../../app/actions/v2/dentist/wellnessProfile/wellnessProfileFiltersAction';
import WellnessPatientInformation from '../../../../components/v2/dentist/wellnessProfile/WellnessPatientInformation';
import PatientPrefAndSocialNotes from '../../../../components/v2/dentist/wellnessProfile/PatientPrefAndSocialNotes';
import MedicalAlerts from '../../../../components/v2/dentist/wellnessProfile/MedicalAlerts';
import WellnessDiscProfile from '../../../../components/v2/dentist/wellnessProfile/WellnessDiscProfile';
import WellnessRiskScore from '../../../../components/v2/dentist/wellnessProfile/WellnessRiskScore';
import WellnessTreatmentPlan from '../../../../components/v2/dentist/wellnessProfile/WellnessTreatmentPlan';
import WellnessAppointment from '../../../../components/v2/dentist/wellnessProfile/WellnessAppointment';
import { RootState } from '../../../../app/reducers/v2/rootReducer';
import { wellnessProfileSteps } from '../../../../utils/v2/wellnessProfileConstant';
import FullScreenLoader from '../../../../components/v1/Common/FullScreenLoader';
import { getLocalStorageItem } from '../../../../utils/helper';
import WellnessHygieneRegimen from "../../../../components/v2/dentist/wellnessProfile/WellnessHygieneRegimen";
import PhotoXray from "../../../../components/v2/dentist/clinicalExam/PhotoXray";
import {
    clearPhotoXrayDataRequestAction,
    getPhotosRequestAction
} from "../../../../app/actions/v2/dentist/clinicalExam/exam/uploadPhotosXrayAction";
import WellnessExam from "../../../../components/v2/dentist/wellnessProfile/WellnessExam";
import ReferralsSection from "../../../../components/v2/common/ReferralsSection";
import ExamForm from "../../../../components/v2/dentist/clinicalExam/ExamForm";
import {
    syncImageXrayRequestAction,
    updateExamAccordionAction,
    updateExamFormSizeAction
} from "../../../../app/actions/v2/dentist/clinicalExam/clinicalExamFilterAction";
import { getTreatmentDataFeesAction } from '../../../../app/actions/v2/dentist/plannedTreatments';
import {
    syncOdontogramPmsDataRequestAction,
    syncPeriogramPmsDataRequestAction
} from '../../../../app/actions/v2/dentist/clinicalExam/exam/examFindingsTypesAction';
import { odontogram, periogram, photo, xray } from '../../../../utils/v2/clinicalExamConstant';


const useStyles = makeStyles((theme: any) => ({
    appointmentFlowTabs: {
        background: theme.palette.common.white,
        "& .MuiTabs-indicator": {
            backgroundColor: theme.palette.v2.primary.main,
            height: "3px",
        },
        "& .MuiTabs-scroller": {
            overflow: 'auto !important',
            width: '100%',
            "&::-webkit-scrollbar": {
                display: " none",
            },
        }
    },
    examContent: {
        background: theme.palette.background.secondaryPaper,
    },
    filterBox: {
        height: "40px",
        borderWidth: "2px !important",
        borderRadius: "20px",
        fontWeight: "500",
        fontSize: "14px",
        fontFamily: "FavoritPro-Regular",
    },
    patientInformationTab: {
        [theme.breakpoints.down(1200)]: {
            width: 450
        },
        [theme.breakpoints.down(1166)]: {
            width: 450
        },
        [theme.breakpoints.down(1136)]: {
            width: 450
        },
        [theme.breakpoints.down(1116)]: {
            width: "60%"
        },
        [theme.breakpoints.down(800)]: {
            width: "70%"
        },
        [theme.breakpoints.down(650)]: {
            width: "100%"
        },
    },
    discProfileTab: {
        [theme.breakpoints.down(1200)]: {
            width: 220
        },
        [theme.breakpoints.down(1166)]: {
            width: 220
        },
        [theme.breakpoints.down(1136)]: {
            width: 200
        },
        [theme.breakpoints.down(1116)]: {
            width: '40%',
        },
        [theme.breakpoints.down(800)]: {
            width: "30%"
        },
        [theme.breakpoints.down(650)]: {
            width: "100%"
        },
    }
}));


const WellnessProfile = () => {
    const dispatch = useDispatch()
    const { pathname } = useLocation();
    const takeFromUrl = useParams();
    const classes = useStyles();
    const {
        wellnessProfileFilters: {
            wellnessProfileStep,
            wellnessProfileLoader,
            wellnessProfileData,
            wellnessRiskScoreDataLoader
        },
        clinicalExamFilters: {
            examAccordionExpanded,
            examFormSize,
            selectedImage
        },
    } = useSelector((state: RootState) => state);
    const disableTab = ['medical', 'treatment']

    const clinicInfo = localStorage.getItem('clinicInfo') || ''
    const clinicData = clinicInfo ? JSON.parse(clinicInfo) : null as any

    useEffect(() => {
        const clinicID = getLocalStorageItem("clinic") as string
        dispatch(getWellnessProfileRequestAction({ patient_id: takeFromUrl.patientId, clinic_id: clinicID }))
        if (wellnessProfileStep === 'patientInformation') {
            dispatch(generateRiskWellnessPMSRequestAction({ patient_id: takeFromUrl.patientId }))
        }

    }, [wellnessProfileStep])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    const handleChange = (event: React.SyntheticEvent, newValue: any) => {
        dispatch(updateWellnessProfileStepAction(newValue));
        dispatch(clearPhotoXrayDataRequestAction({}))
    };
    const handleExpanded = (e: any) => {
        e?.preventDefault();
        e?.stopPropagation();
        if (examFormSize) {
            dispatch(updateExamFormSizeAction(false));
        }
        dispatch(updateExamAccordionAction(!examAccordionExpanded));
    };
    const handleFullSize = (e: any) => {
        e?.preventDefault();
        e?.stopPropagation();
        if (!examAccordionExpanded && !examFormSize) {
            dispatch(updateExamAccordionAction(true));
        }
        dispatch(updateExamFormSizeAction(!examFormSize));
    };

    useEffect(() => {
        if (clinicData?.pms_integration && wellnessProfileData) {
            dispatch(getTreatmentDataFeesAction({
                appointmentID: wellnessProfileData?.latest_appointment_id,
                patientID: takeFromUrl?.patientId
            }))
        }
    }, [wellnessProfileData])

    useEffect(() => {

        if (performance.navigation.type !== performance.navigation.TYPE_RELOAD && clinicData?.pms_integration) {
            dispatch(syncOdontogramPmsDataRequestAction({
                patientId: takeFromUrl.patientId,
                preventGetApiCall: wellnessProfileStep !== odontogram,
                callback: () => {
                    dispatch(getTreatmentDataFeesAction({
                        appointmentID: wellnessProfileData?.latest_appointment_id,
                        patientID: takeFromUrl.patientId
                    }))
                }
            }))
            dispatch(syncPeriogramPmsDataRequestAction({
                patientId: takeFromUrl.patientId,
                preventGetApiCall: wellnessProfileStep !== periogram,
            }))
            dispatch(syncImageXrayRequestAction({
                appointment_id: wellnessProfileData?.latest_appointment_id || '',
                patientId: takeFromUrl.patientId,
                callback: () => {
                    if (wellnessProfileStep === photo || wellnessProfileStep === xray) {
                        dispatch(
                            getPhotosRequestAction({
                                image_type: wellnessProfileStep === photo ? 'image' : 'xray',
                                patient_id: takeFromUrl.patientId,
                            })
                        );
                    }
                }
            }))
        }
    }, [])


    return (
        <Grid>
            {wellnessProfileLoader && <FullScreenLoader />}
            <Box
                className={classes.appointmentFlowTabs}
                pt={"4px"}
                pl={["20px"]}
                height={["52px"]}
            >
                <Tabs
                    value={wellnessProfileStep}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                >
                    {wellnessProfileSteps?.map((res: any, index: number) => (
                        <Tab
                            disabled={disableTab?.includes(res?.value)}
                            key={index}
                            className='cursor-pointer'
                            label={
                                <InputLabel
                                    sx={{
                                        opacity: disableTab.includes(res?.value) ? 0.6 : 1,
                                        cursor: disableTab.includes(res?.value)
                                            ? "not-allowed"
                                            : "pointer",
                                    }}
                                    className={`labelColor fw-regular f-14 lh-19 f-w-400 cursor-pointer`}>
                                    {res?.title}
                                </InputLabel>
                            }
                            value={res?.value}
                        />
                    ))}
                </Tabs>
            </Box>
            <Box
                p={(wellnessProfileStep === 'patientInformation') ? '30px' : '0px'}
                className={classes.examContent}
                sx={{
                    height: `calc(100vh - 132px)`,
                    overflowY: 'scroll'
                }}
            >
                {wellnessProfileStep === 'patientInformation' &&
                    <React.Fragment>
                        <Grid container display="flex" spacing={2}>
                            <Grid item lg={"auto"} width={[450, 450, 450, 500, 500]}
                                className={classes.patientInformationTab}>
                                <WellnessPatientInformation />
                            </Grid>
                            <Grid item lg={"auto"} width={[250, 250, 250, 250, 250]} className={classes.discProfileTab}>
                                <WellnessDiscProfile />
                            </Grid>
                            <Grid item xs width={'auto'}><PatientPrefAndSocialNotes /></Grid>
                            <Grid item xs width={'auto'} minWidth={['auto', 400, 400, 400, 400]}><MedicalAlerts /></Grid>
                            <Grid item xs={12}><WellnessRiskScore /></Grid>
                            <Grid item xs={12}><ReferralsSection referralsData={wellnessProfileData?.referrals}
                                disabledField={true} /></Grid>
                            <Grid item xs={12}><WellnessHygieneRegimen /></Grid>
                            <Grid item xs={12}><WellnessTreatmentPlan /></Grid>
                            <Grid item xs={12}><WellnessAppointment /></Grid>
                        </Grid>
                    </React.Fragment>
                }
                {(wellnessProfileStep === 'photo' || wellnessProfileStep === 'xray') &&
                    <Grid container>
                        <Grid xl={selectedImage ? (examAccordionExpanded ? 8.5 : 12) : 12}
                            lg={selectedImage ? (examAccordionExpanded ? 8.5 : 12) : 12}
                            md={selectedImage ? (examAccordionExpanded ? 8 : 12) : 12}
                            sm={selectedImage ? (examAccordionExpanded ? 8 : 12) : 12}
                            xs={selectedImage ? (examAccordionExpanded ? 8 : 12) : 12} flexDirection={'column'} item
                            display={'flex'}>
                            <PhotoXray />
                        </Grid>
                        {selectedImage && <Grid
                            item
                            xl={examFormSize ? 12 : 3.5}
                            lg={examFormSize ? 12 : 3.5}
                            md={examFormSize ? 12 : 4}
                            sm={examFormSize ? 12 : 4}
                            xs={examFormSize ? 12 : 4}
                            pl={examFormSize ? 0 : 1}
                        >
                            <ExamForm
                                handleExpanded={handleExpanded}
                                handleFullSize={handleFullSize}
                                showFooter={false}
                            />
                        </Grid>}

                    </Grid>
                }
                {(wellnessProfileStep === 'softTissue' ||
                    wellnessProfileStep === 'hardTissue' ||
                    wellnessProfileStep === 'odontogram' ||
                    wellnessProfileStep === 'periogram') &&
                    <WellnessExam />
                }

            </Box>
        </Grid>
    )
};

export default WellnessProfile;
