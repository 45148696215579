import { useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { Cell, Pie, PieChart, Tooltip } from "recharts";
import { makeStyles } from "@mui/styles";
import theme from "../../../../../theme";
import { discData } from "../../../../../utils/discData";
import CollapsibleBox from "../../../common/CollapsibleBox";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../app/reducers/v2/rootReducer";

const useStyles = makeStyles((theme: any) => ({
  chartHeader: {
    fontSize: "14px",
    color: theme.palette.common.black50,
    lineHeight: "16px",
    marginBottom: "8px",
  },
  chartDescription: {
    color: theme.palette.common.black50,
    fontSize: "12px",
    marginBottom: "12px",
    lineHeight: "16px",
  },
  cellData: {
    outline: "none",
    "&:hover": {
      backgroundColor: "red",
    },
  },
  customTooltip: {
    padding: "10px",
    color: theme.palette.common.white,
    marginTop: "-10px",
    width: "380px",
    borderRadius: "8px",
    [theme.breakpoints.down('md')]: {
      width: '320px',
      marginLeft: '-10px',
      height: 'fit-content',
      marginTop: '-50px'
    }
  }
}));

const RADIAN = Math.PI / 180;

const DiscProfile = () => {
  const classes = useStyles();
  const [activeIndex, setActiveIndex]: any = useState();
  const { preIntakeData } = useSelector((state: RootState) => state.preIntake);

  useEffect(() => {
    if (preIntakeData?.disc_profile) {
      preIntakeData.disc_profile === "I" && setActiveIndex(0);
      preIntakeData.disc_profile === "D" && setActiveIndex(1);
      preIntakeData.disc_profile === "C" && setActiveIndex(2);
      preIntakeData.disc_profile === "S" && setActiveIndex(3);
    }
  }, [preIntakeData]);

  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    name,
  }: any) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    return (
      <text
        x={x}
        y={y}
        fill="#FFFFFF"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
        fontSize={22}
        fontWeight={700}
        transform={"rotate(-50deg)"}
      >
        {name}
      </text>
    );
  };

  const renderCustomTooltip = ({ active, payload }: any) => {
    if (active) {
      return (
        <Box
          sx={{ backgroundColor: payload[0]?.payload?.lightColor }}
          className={`${classes.customTooltip} fw-regular`}
        >
          <Typography
            fontSize={"18px"}
            color={theme.palette.common.black50}
            className={"fw-medium"}
            mb={"16px"}
            textTransform={"uppercase"}
          >
            {payload[0]?.payload?.payload.title}
          </Typography>
          <Typography className={`${classes.chartHeader} fw-regular`}>
            Priorities:
          </Typography>
          <Typography className={`${classes.chartDescription} fw-regular`}>
            {payload[0]?.payload?.payload.priorities}
          </Typography>
          <Typography className={`${classes.chartHeader} fw-regular`}>
            Motivated by:
          </Typography>
          <Typography className={`${classes.chartDescription} fw-regular`}>
            {payload[0]?.payload?.payload.motivatedBy}
          </Typography>
          <Typography className={`${classes.chartHeader} fw-regular`}>
            Fears:
          </Typography>
          <Typography className={`${classes.chartDescription} fw-regular`}>
            {payload[0]?.payload?.payload.fear}
          </Typography>
          <Typography className={`${classes.chartHeader} fw-regular`}>
            You will notice:
          </Typography>
          <Typography className={`${classes.chartDescription} fw-regular`}>
            {payload[0]?.payload?.payload.notice}
          </Typography>
          <Typography className={`${classes.chartHeader} fw-regular`}>
            Limitations:
          </Typography>
          <Typography className={`${classes.chartDescription} fw-regular`}>
            {payload[0]?.payload?.payload.limitations}
          </Typography>
        </Box>
      );
    }
    return null;
  };

  return (
    <CollapsibleBox
      title={"Disc Profile"}
      id={"discProfile"}
    >
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={9} xl={6}>
          <Grid container >
            {
              <Box
                display={"flex"}
                width={"100%"}
                height={"fit-content"}
                ml={"20px"}
                sx={{
                  [theme.breakpoints.down(700)]: {
                    marginLeft: '0'
                  }
                }}
              >
                <Grid
                  item
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  sx={{
                    [theme.breakpoints.down('md')]: {
                      marginLeft: '-35px',
                    },
                    [theme.breakpoints.down(700)]: {
                      marginLeft: '-25px',
                    },
                  }}
                >
                  <div style={{ height: "fit-content" }}>
                    {/* future code */}
                    {/* style={{textAlign:'-webkit-center'}} */}
                    <PieChart
                      width={300}
                      height={300}
                    // future use
                    // className={classes.mainChart}
                    >
                      <Pie
                        data={discData}
                        labelLine={false}
                        label={({
                          cx,
                          cy,
                          midAngle,
                          innerRadius,
                          outerRadius,
                          name,
                        }) =>
                          renderCustomizedLabel({
                            cx,
                            cy,
                            midAngle,
                            innerRadius,
                            outerRadius,
                            name,
                          })
                        }
                        fill="#FFFFFF"
                        dataKey="value"
                        activeIndex={activeIndex}
                        height={"300px"}
                        width={"300px"}
                        strokeWidth={5}
                        style={{ minWidth: "350px" }}
                      >
                        {discData?.map((entry: any, index: any) => (
                          <Cell
                            className={classes.cellData}
                            key={`cell-${index}`}
                            fill={
                              activeIndex === index
                                ? entry.color
                                : "#9899A1"
                            }
                          />
                        ))}
                      </Pie>
                      <Tooltip
                        wrapperStyle={{ outline: "none" }}
                        content={renderCustomTooltip}
                        position={{ x: 300, y: 0 }}
                      />
                    </PieChart>
                  </div>
                </Grid>
                {preIntakeData?.disc_profile && (
                  <Grid
                    lg={6}
                    md={6}
                    sm={12}
                    xs={12}
                    sx={{
                      backgroundColor: discData[activeIndex]?.lightColor,
                      height: "fit-content",
                      borderRadius: "8px",
                    }}
                    mt={"12px"}
                    mr={"12px"}
                    mb={"12px"}
                    maxHeight={"355px"}
                    overflow={"auto"}
                  >
                    <Grid
                      p={"12px"}
                      borderRadius="8px"
                      sx={{ backgroundColor: discData[activeIndex]?.lightColor }}
                    >
                      <Typography
                        sx={{
                          textTransform: "capitalize",
                          fontSize: "18px",
                          marginBottom: "16px",
                          color: theme.palette.common.black50,
                        }}
                        className={"fw-medium"}
                      >
                        {discData[activeIndex]?.title}
                      </Typography>
                      <Typography
                        className={`${classes.chartHeader} fw-regular`}
                      >
                        Priorities:
                      </Typography>
                      <Typography
                        className={`${classes.chartDescription} fw-regular`}
                      >
                        {discData[activeIndex]?.priorities}
                      </Typography>
                      <Typography
                        className={`${classes.chartHeader} fw-regular`}
                      >
                        Motivated by:
                      </Typography>
                      <Typography
                        className={`${classes.chartDescription} fw-regular`}
                      >
                        {discData[activeIndex]?.motivatedBy}
                      </Typography>
                      <Typography
                        className={`${classes.chartHeader} fw-regular`}
                      >
                        Fears:
                      </Typography>
                      <Typography
                        className={`${classes.chartDescription} fw-regular`}
                      >
                        {discData[activeIndex]?.fear}
                      </Typography>
                      <Typography
                        className={`${classes.chartHeader} fw-regular`}
                      >
                        You will notice:
                      </Typography>
                      <Typography
                        className={`${classes.chartDescription} fw-regular`}
                      >
                        {discData[activeIndex]?.notice}
                      </Typography>
                      <Typography
                        className={`${classes.chartHeader} fw-regular`}
                      >
                        Limitations:
                      </Typography>
                      <Typography
                        className={`${classes.chartDescription} fw-regular`}
                      >
                        {discData[activeIndex]?.limitations}
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              </Box>
            }
          </Grid>
        </Grid>
      </Grid>
    </CollapsibleBox>
  );
};

export default DiscProfile;
