import {
  CHANGE_PASSWORD_V2_REQUEST,
  CHANGE_PASSWORD_V2_REQUEST_FAILURE,
  CHANGE_PASSWORD_V2_REQUEST_SUCCESS
} from '../../../ActionTypes/v2/auth';
import {
  ChangePasswordRequest,
  ChangePasswordRequestFailure,
  ChangePasswordRequestFailurePayload,
  ChangePasswordRequestPayload,
  ChangePasswordRequestSuccess,
  ChangePasswordRequestSuccessPayload
} from '../../../types/v2/authActionTypes/changePasswordActionTypes';

export const changePasswordRequest = (payload: ChangePasswordRequestPayload): ChangePasswordRequest => ({
  type: CHANGE_PASSWORD_V2_REQUEST,
  payload
});

export const changePasswordRequestSuccess = (payload: ChangePasswordRequestSuccessPayload): ChangePasswordRequestSuccess => ({
  type: CHANGE_PASSWORD_V2_REQUEST_SUCCESS,
  payload
});

export const changePasswordRequestFailure = (payload: ChangePasswordRequestFailurePayload): ChangePasswordRequestFailure => ({
  type: CHANGE_PASSWORD_V2_REQUEST_FAILURE,
  payload
});
