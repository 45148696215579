export const PUT_INSIGHTS_RECOMMENDATIONS_REQUEST =
  "PUT_INSIGHTS_RECOMMENDATIONS_REQUEST";
export const PUT_INSIGHTS_RECOMMENDATIONS_SUCCESS =
  "PUT_INSIGHTS_RECOMMENDATIONS_SUCCESS";
export const PUT_INSIGHTS_RECOMMENDATIONS_FAILURE =
  "PUT_INSIGHTS_RECOMMENDATIONS_FAILURE";

export const GET_INSIGHTS_RECOMMENDATIONS_REQUEST =
  "GET_INSIGHTS_RECOMMENDATIONS_REQUEST";
export const GET_INSIGHTS_RECOMMENDATIONS_CACHE_DATA_CLEAR_REQUEST = "GET_INSIGHTS_RECOMMENDATIONS_CACHE_DATA_CLEAR_REQUEST"
export const GET_INSIGHTS_RECOMMENDATIONS_REQUEST_SUCCESS =
  "GET_INSIGHTS_RECOMMENDATIONS_REQUEST_SUCCESS";
export const GET_INSIGHTS_RECOMMENDATIONS_REQUEST_FAILURE =
  "GET_INSIGHTS_RECOMMENDATIONS_REQUEST_FAILURE";

export const CHANGE_INSIGHTS_AND_RECOMMENDATIONS =
  "CHANGE_INSIGHTS_AND_RECOMMENDATIONS";
export const UPDATE_INSIGHTS_AND_RECOMMENDATIONS =
  "UPDATE_INSIGHTS_AND_RECOMMENDATIONS";
export const UPDATE_INSIGHTS_AND_RECOMMENDATIONS_CACHE_DATA_CLEAR_REQUEST = "UPDATE_INSIGHTS_AND_RECOMMENDATIONS_CACHE_DATA_CLEAR_REQUEST"  
