import validator from "validator";
import { isEmptyObject } from "../../utils/helper";
import { requireMessage } from "../validationMessage";

export const QrCodeAccessCodeValidator = (data) => {
  let errors = {};

  if (data?.accessCode == null || validator.isEmpty(data?.accessCode))
    errors.accessCode = "This field is required";

  if (data?.dob == null || validator.isEmpty(data?.dob))
    errors.dob = "This field is required";

  return { errors, isValid: isEmptyObject(errors) };
};

export const QrCodeMobileValidator = (data) => {
  let errors = {};

  if (data?.mobile == null || validator.isEmpty(data?.mobile))
    errors.mobile = "This field is required";

  if (data?.mobile && data?.mobile.length !== 14)
    errors.mobile = requireMessage("valid mobile number");

  if (data?.dob == null || validator.isEmpty(data?.dob))
    errors.dob = "This field is required";

  return { errors, isValid: isEmptyObject(errors) };
};
