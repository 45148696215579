import { Box, Button, Typography } from "@mui/material";
import React, { Component, ErrorInfo } from 'react';

type Props = {
  children: React.ReactNode;
};

type State = {
  hasError: boolean;
  redirect: boolean;
};

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      hasError: false,
      redirect: false
    };
  }

  static getDerivedStateFromError(error: Error) {
    console.error(error);
    return { hasError: true };
  }

  handleRedirect = () => {
    const redirectTo = window.location?.pathname?.split("/")?.includes("patient") ? '/patient/wellness-profile' : "/v2/dentist/dashboard" as string
    window.location.href = redirectTo
  };

  handleRefresh = () => {
    window.location.reload()
  };
  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <Box display={'flex'} justifyContent={'center'} alignItems={'center'} flexDirection={'column'} height={'100vh'}>
          <Typography variant={'h3'}>Oops! Something went wrong.</Typography>
          <Typography>We are sorry for the inconvenience. Please try again later.</Typography>
          <Box className='d-flex'>  
          <Button className={'button'} variant={'contained'} sx={{ mt: 1.5 }} onClick={this.handleRedirect}>
            Go to Home
          </Button>
          <Button className={'button'} variant={'contained'} sx={{ mt: 1.5,marginLeft:'10px' }} onClick={this.handleRefresh} >
            Refresh page
          </Button>
          </Box>
        </Box>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary
