// @ts-ignore
import { put, takeEvery, all } from "redux-saga/effects";
import API from "../../../../utils/api";
import { LOGOUT_V2_REQUEST } from "../../../ActionTypes/v2/auth";
import { errorToaster, getLocalStorageItem } from "../../../../utils/helper";
import {
  logoutRequestFailure,
  logoutRequestSuccess,
} from "../../../actions/v2/authAction/logOutAction";

function* logoutRequestSaga({ payload }: any): any {
  try {
    const response = yield API.delete(`/v2/auth/device/token`, {
      data: {
        device_token: getLocalStorageItem("dt") || "",
      },
    });
    yield put(
      logoutRequestSuccess({
        logoutData: response?.data,
      })
    );
  } catch (e: any) {
    payload.errorCallback();
    errorToaster(e?.message);
    yield put(
      logoutRequestFailure({
        logoutError: e?.message,
      })
    );
  }
}
function* logoutSaga() {
  yield takeEvery(LOGOUT_V2_REQUEST, logoutRequestSaga);
}

export default function* rootSaga() {
  yield all([logoutSaga()]);
}
