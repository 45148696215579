import React from 'react';
import {Box, Button, CircularProgress, useMediaQuery} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import theme from '../../../../../theme';
import {RootState} from '../../../../../app/reducers/v2/rootReducer';
import {
    communicationPreferencesValidator,
    dentalInfoValidator,
    emergencyContactInfoValidator,
    generalHealthValidator,
    letsGetToKnowYouBetterValidator,
    medicalHealthHistoryValidator,
    patientInformationValidatorWithoutAccount
} from '../../../../../validation/v2/preIntakeValidator';
import {
    isPreIntakeSubmitAction,
    updatePreIntakeValidationsAction
} from '../../../../../app/actions/v2/dentist/preIntakeActions';
import {
    putHealthHistoryWithoutAcRequest,
    registerPopUpRequestAction
} from '../../../../../app/actions/v2/patient/healthHistoryWithoutAccountAction';
import RegisterPopUp from './RegisterPopUp';

export const useStyles = makeStyles((theme: any) => ({
    buttonContainer: {
        position: 'relative',
        zIndex: 500,
        background: theme.palette.common.white,
        "& .MuiTabs-indicator": {
            backgroundColor: theme.palette.v2.primary.main,
            height: "3px",
        },
        "& .MuiTabs-scroller": {
            overflow: 'auto !important',
            width: '100%',
            "&::-webkit-scrollbar": {
                display: " none",
            },
        }
    },
    submitButton: {
        minWidth: "180px",
        width: "180px",
        height: "40px",
        backgroundColor: theme.palette.v2.primary.main,
        zIndex: 99,
        '&:hover': {
            backgroundColor: theme.palette.v2.primary.main,
            color: theme.palette.common.white
        }
    },
    cancelButton: {
        width: "134px",
        height: "40px",
        marginRight: "20px",
        textTransform: "none",
        color: theme.palette.v2.primary.main,
        border: `1px solid ${theme.palette.v2.primary.main}`,
        textDecoration: 'none',
        borderRadius: '4px',
    },
}));

const HealthHistoryFooter = () => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {clinicId, patientId}: any = useParams();
    const isMobile = useMediaQuery(theme.breakpoints.down(768));
    const isTablet = useMediaQuery(theme.breakpoints.between(769, 992));

    const {
        HealthHistoryWithoutAc: {putHealthHistoryWithoutAccountLoader, openPopUp},
        preIntake: {getFiledData},
    } = useSelector((state: RootState) => state) as any;

    const preIntakeData = useSelector((state: RootState) => state?.preIntake)


    const useQuery = () => {
        return new URLSearchParams(useLocation().search);
    };

    const query = useQuery();
    const secondScreen = query.get('secondScreen');

    const token = query.get("token")

    const checkIsValid = () => {
        const emergencyContactInfo = emergencyContactInfoValidator(
            preIntakeData.emergencyContactInfo
        );
        const patientInformation = patientInformationValidatorWithoutAccount(
            preIntakeData.patientInformation
        );
        const letsGetToKnowYouBetter = letsGetToKnowYouBetterValidator(
            preIntakeData.letsGetToKnowYouBetter
        );
        const dentalInfo = dentalInfoValidator(preIntakeData.dentalInfo);
        const medicalHealthHistory = medicalHealthHistoryValidator(
            preIntakeData.medicalHealthHistory, getFiledData
        );
        const communicationPreferences = communicationPreferencesValidator(
            preIntakeData.communicationPreferences
        );
        const generalHealth = generalHealthValidator(preIntakeData.generalHealth);
        // TODO : Dental Insurance Information - Hide until we have import/export support (7558, 7054,5245)
        // const dentistInsuranceInfo = dentistInsuranceInfoValidator(
        //   preIntakeData?.dentistInsuranceInfo,
        //   preIntakeData.letsGetToKnowYouBetter
        // );
        const errors = {
            emergencyContactInfo: emergencyContactInfo.errors,
            // dentistInsuranceInfo: dentistInsuranceInfo.errors, // TODO : Dental Insurance Information - Hide until we have import/export support (7558, 7054,5245)
            generalHealth: generalHealth.errors,
            letsGetToKnowYouBetter: letsGetToKnowYouBetter.errors,
            dentalInfo: dentalInfo.errors,
            medicalHealthHistory: medicalHealthHistory.errors,
            patientInformation: patientInformation.errors,
            communicationPreferences: communicationPreferences.errors,
        };

        dispatch(updatePreIntakeValidationsAction(errors));

        return (
            emergencyContactInfo?.isValid &&
            letsGetToKnowYouBetter?.isValid &&
            medicalHealthHistory?.isValid &&
            communicationPreferences?.isValid &&
            // dentistInsuranceInfo?.isValid && // TODO : Dental Insurance Information - Hide until we have import/export support (7558, 7054,5245)
            dentalInfo?.isValid &&
            generalHealth?.isValid &&
            patientInformation?.isValid
        )
    }

    const submitHealthHistoryWithoutAccount = (isSubmit: boolean) => {
        const deviceType = isMobile ? "mobile" : isTablet ? "tablet" : 'Desktop'

        //Save PreIntake
        dispatch(putHealthHistoryWithoutAcRequest({
            getHealthHistoryWithoutAccountData: preIntakeData,
            clinic_id: clinicId,
            patient_id: patientId,
            device: deviceType,
            is_second_screen: !!secondScreen,
            ...(isSubmit && {submit_form: true}),
            ...(token && {token: token}),
            callback: (data: any) => {
                if (isSubmit && !secondScreen) {
                    dispatch(registerPopUpRequestAction(false));
                    navigate(`/patient/submit-health-history/${clinicId}/${patientId}?account=${data?.is_account}`)
                }
            },
            errorCallback: (data: any) => {
                if (data?.token_expired) {
                    navigate('/patient/session-expired')
                }
            }
        }))
    }

    return (
        <Box
            className={`${classes.buttonContainer} d-flex align-center justify-end`}
            pt={"4px"}
            px={["20px"]}
            height={["80px"]}
        >
            {!secondScreen &&
                <Button
                    variant='outlined'
                    className={`fw-regular d-flex-all-center ${classes.cancelButton}`}
                    onClick={() => submitHealthHistoryWithoutAccount(false)}
                    disabled={putHealthHistoryWithoutAccountLoader}
                >
                    {putHealthHistoryWithoutAccountLoader ? (
                        <CircularProgress color={"primary"} size={19}/>
                    ) : (
                        'Save as draft'
                    )}
                </Button>
            }
            <Button
                variant={"contained"}
                className={`fw-regular ${classes.submitButton}`}
                onClick={() => {
                    if (secondScreen) {
                        submitHealthHistoryWithoutAccount(true)
                    } else {
                        if (checkIsValid()) {
                            dispatch(registerPopUpRequestAction(true))
                        } else {
                            dispatch(isPreIntakeSubmitAction(true))
                        }
                    }
                }
                }
                disabled={putHealthHistoryWithoutAccountLoader}
            >
                Submit Health History
            </Button>

            {openPopUp &&
                <RegisterPopUp
                    open={openPopUp}
                    handleClose={() => dispatch(registerPopUpRequestAction(false))}
                    continueHandler={submitHealthHistoryWithoutAccount}
                />
            }
        </Box>
    )
}

export default HealthHistoryFooter