//for state management (with the help of continue and back button)
export const POST_CLINICAL_EXAM_DENTIST_DATA_REQUEST =
  "POST_CLINICAL_EXAM_DENTIST_DATA_REQUEST";
export const POST_ADD_MULTIPLE_FINDING_DENTIST =
  "POST_ADD_MULTIPLE_FINDING_DENTIST";
export const POST_DELETE_MULTIPLE_FINDING_DENTIST =
  "POST_DELETE_MULTIPLE_FINDING_DENTIST";

//for state management(xray (tag) anotations)
export const POST_XRAY_TAG_DATA = "POST_XRAY_TAG_DATA";
export const POST_EDIT_XRAY_TAG_DATA = "POST_EDIT_XRAY_TAG_DATA";
export const POST_IMAGE_TAG_DATA = "POST_IMAGE_TAG_DATA";
export const POST_EDIT_IMAGE_TAG_DATA = "POST_EDIT_IMAGE_TAG_DATA";
export const POST_DELETE_XRAY_TAG_DATA = "POST_DELETE_XRAY_TAG_DATA";
export const POST_DELETE_IMAGE_TAG_DATA = "POST_DELETE_IMAGE_TAG_DATA";

//for recent Xray
export const RECENT_XRAY_REQUEST = "RECENT_XRAY_REQUEST";
export const RECENT_XRAY_CACHE_DATA_CLEAR_REQUEST =
  "RECENT_XRAY_CACHE_DATA_CLEAR_REQUEST";
export const RECENT_XRAY_REQUEST_SUCCESS = "RECENT_XRAY_REQUEST_SUCCESS";
export const RECENT_XRAY_REQUEST_FAILURE = "RECENT_XRAY_REQUEST_FAILURE";

export const GET_TAG_XRAY_ALL_REQUEST = "GET_TAG_XRAY_ALL_REQUEST";
export const GET_TAG_XRAY_ALL_REQUEST_SUCCESS =
  "GET_TAG_XRAY_ALL_REQUEST_SUCCESS";
export const GET_TAG_XRAY_ALL_REQUEST_FAILURE =
  "GET_TAG_XRAY_ALL_REQUEST_FAILURE";

export const GET_XRAY_APPOINTMENT_REQUEST = "GET_XRAY_APPOINTMENT_REQUEST";
export const GET_XRAY_APPOINTMENT_CACHE_DATA_CLEAR_REQUEST =
  "GET_XRAY_APPOINTMENT_CACHE_DATA_CLEAR_REQUEST";
export const GET_XRAY_APPOINTMENT_REQUEST_SUCCESS =
  "GET_XRAY_APPOINTMENT_REQUEST_SUCCESS";
export const GET_XRAY_APPOINTMENT_REQUEST_FAILURE =
  "GET_XRAY_APPOINTMENT_REQUEST_FAILURE";
export const UPDATE_CONTRAST = "UPDATE_CONTRAST";
export const UPDATE_CROPPED_IMAGE = "UPDATE_CROPPED_IMAGE";

export const DELETE_XRAY_APPOINTMENT_REQUEST =
  "DELETE_XRAY_APPOINTMENT_REQUEST";
export const DELETE_XRAY_APPOINTMENT_REQUEST_SUCCESS =
  "DELETE_XRAY_APPOINTMENT_REQUEST_SUCCESS";
export const DELETE_XRAY_APPOINTMENT_REQUEST_FAILURE =
  "DELETE_XRAY_APPOINTMENT_REQUEST_FAILURE";

//for create tag (anotations)

export const CREATE_TAG_REQUEST = "CREATE_TAG_REQUEST";
export const CREATE_TAG_REQUEST_SUCCESS = "CREATE_TAG_REQUEST_SUCCESS";
export const CREATE_TAG_REQUEST_FAILURE = "CREATE_TAG_REQUEST_FAILURE";

//for Get tag data(anotations)
export const GET_TAG_DATA_REQUEST = "GET_TAG_DATA_REQUEST";
export const GET_TAG_DATA_REQUEST_SUCCESS = "GET_TAG_DATA_REQUEST_SUCCESS";
export const GET_TAG_DATA_REQUEST_FAILURE = "GET_TAG_DATA_REQUEST_FAILURE";

//for update tag data(anotations)
export const UPDATE_TAG_DATA_REQUEST = "UPDATE_TAG_DATA_REQUEST";
export const UPDATE_TAG_DATA_REQUEST_SUCCESS =
  "UPDATE_TAG_DATA_REQUEST_SUCCESS";
export const UPDATE_TAG_DATA_REQUEST_FAILURE =
  "UPDATE_TAG_DATA_REQUEST_FAILURE";

//for Delete tag (anotations)

export const DELETE_TAG_REQUEST = "DELETE_TAG_REQUEST";
export const DELETE_TAG_REQUEST_SUCCESS = "DELETE_TAG_REQUEST_SUCCESS";
export const DELETE_TAG_REQUEST_FAILURE = "DELETE_TAG_REQUEST_FAILURE";

// for contrast (put contrast value)
export const PUT_CONTRAST_REQUEST = "PUT_CONTRAST_REQUEST";
export const PUT_CONTRAST_REQUEST_SUCCESS = "PUT_CONTRAST_REQUEST_SUCCESS";
export const PUT_CONTRAST_REQUEST_FAILURE = "PUT_CONTRAST_REQUEST_FAILURE";

//for crop image(put cropped image)

export const PUT_CROPPED_IMAGE_REQUEST = "PUT_CROPPED_IMAGE_REQUEST";
export const PUT_CROPPED_IMAGE_REQUEST_SUCCESS =
  "PUT_CROPPED_IMAGE_REQUEST_SUCCESS";
export const PUT_CROPPED_IMAGE_REQUEST_FAILURE =
  "PUT_CROPPED_IMAGE_REQUEST_FAILURE";

// for step 3b put data in DB

export const PUT_CLINICAL_EXAM_DENTIST_REQUEST = "PUT_CLINICAL_EXAM_DENTIST";
export const PUT_CLINICAL_EXAM_DENTIST_REQUEST_SUCCESS =
  "PUT_CLINICAL_EXAM_DENTIST_REQUEST_SUCCESS";
export const PUT_CLINICAL_EXAM_DENTIST_REQUEST_FAILURE =
  "PUT_CLINICAL_EXAM_DENTIST_REQUEST_FAILURE";

// for get data (clinical exam dentist)

export const GET_CLINICAL_EXAM_DENTIST_REQUEST =
  "GET_CLINICAL_EXAM_DENTIST_REQUEST";
export const GET_CLINICAL_EXAM_DENTIST_REQUEST_SUCCESS =
  "GET_CLINICAL_EXAM_DENTIST_REQUEST_SUCCESS";
export const GET_CLINICAL_EXAM_DENTIST_REQUEST_FAILURE =
  "GET_CLINICAL_EXAM_DENTIST_REQUEST_FAILURE";

// for get data (clinical exam periodontal)
export const UPDATE_PERIODONTAL = "UPDATE_PERIODONTAL";
export const UPDATE_PERIODONTAL_FAILURE = "UPDATE_PERIODONTAL_FAILURE";
export const ADD_PERIODONTAL_FINDING_ROW = "ADD_PERIODONTAL_FINDING_ROW";
export const CHANGE_PERIODONTAL_FINDING = "CHANGE_PERIODONTAL_FINDING";
export const DELETE_PERIODONTAL_FINDING_ROW = "DELETE_PERIODONTAL_FINDING_ROW";
// for recall exam (clinical exam periodontal)
export const UPDATE_PERIODONTAL_RECALL_EXAM = "UPDATE_PERIODONTAL_RECALL_EXAM";
export const UPDATE_PERIODONTAL_RECALL_EXAM_FAILURE =
  "UPDATE_PERIODONTAL_RECALL_EXAM_FAILURE";
export const ADD_PERIODONTAL_FINDING_ROW_RECALL_EXAM =
  "ADD_PERIODONTAL_FINDING_ROW_RECALL_EXAM";
export const CHANGE_PERIODONTAL_FINDING_RECALL_EXAM =
  "CHANGE_PERIODONTAL_FINDING_RECALL_EXAM";
export const DELETE_PERIODONTAL_FINDING_ROW_RECALL_EXAM =
  "DELETE_PERIODONTAL_FINDING_ROW_RECALL_EXAM";

//for get data(clinical exam - medical history)
export const UPDATE_MEDICAL_HISTORY_DATA = "UPDATE_MEDICAL_HISTORY_DATA";
export const UPDATE_MEDICAL_HISTORY_DATA_FAILURE =
  "UPDATE_MEDICAL_HISTORY_DATA_FAILURE";
export const CHANGE_MEDICAL_HISTORY_DATA = "CHANGE_MEDICAL_HISTORY_DATA";

//for get data(clinical exam - extra oral exam)

export const UPDATE_EXTRA_ORAL_DENTIST = "UPDATE_EXTRA_ORAL_DENTIST";
export const UPDATE_EXTRA_ORAL_DENTIST_FAILURE =
  "UPDATE_EXTRA_ORAL_DENTIST_FAILURE";
export const CHANGE_EXTRA_ORAL_DENTIST = "CHANGE_EXTRA_ORAL_DENTIST";

//for get data(clinical exam - TMJ)

export const UPDATE_TMJ_DENTIST = "UPDATE_TMJ_DENTIST";
export const UPDATE_TMJ_DENTIST_FAILURE = "UPDATE_TMJ_DENTIST_FAILURE";
export const CHANGE_TMJ_DENTIST = "CHANGE_TMJ_DENTIST";

//for get data(clinical exam - Muscle)
export const UPDATE_MUSCLE_DENTIST = "UPDATE_MUSCLE_DENTIST";
export const UPDATE_MUSCLE_DENTIST_FAILURE = "UPDATE_MUSCLE_DENTIST_FAILURE";
export const CHANGE_MUSCLE_DENTIST = "CHANGE_MUSCLE_DENTIST";

//for get data(clinical exam - intra oral)
export const UPDATE_INTRA_ORAL_DENTIST = "UPDATE_INTRA_ORAL_DENTIST";
export const UPDATE_INTRA_ORAL_DENTIST_FAILURE =
  "UPDATE_INTRA_ORAL_DENTIST_FAILURE";
export const CHANGE_INTRA_ORAL_DENTIST = "CHANGE_INTRA_ORAL_DENTIST";

// For recall exam flow of intraoral dental team
export const UPDATE_INTRA_ORAL_DENTIST_RECALL_EXAM =
  "UPDATE_INTRA_ORAL_DENTIST_RECALL_EXAM";
export const UPDATE_INTRA_ORAL_DENTIST_RECALL_EXAM_FAILURE =
  "UPDATE_INTRA_ORAL_DENTIST_RECALL_EXAM_FAILURE";
export const CHANGE_INTRA_ORAL_DENTIST_RECALL_EXAM =
  "CHANGE_INTRA_ORAL_DENTIST_RECALL_EXAM";

//for get data(clinical exam - Occlusion exam)
export const UPDATE_OCCLUSION_DENTIST = "UPDATE_OCCLUSION_DENTIST";
export const UPDATE_OCCLUSION_DENTIST_FAILURE =
  "UPDATE_OCCLUSION_DENTIST_FAILURE";
export const CHANGE_OCCLUSION_DENTIST = "CHANGE_OCCLUSION_DENTIST";
// For recall exam flow of occlusion dental team
export const UPDATE_OCCLUSION_DENTIST_RECALL_FLOW =
  "UPDATE_OCCLUSION_DENTIST_RECALL_FLOW";
export const UPDATE_OCCLUSION_DENTIST_RECALL_FLOW_FAILURE =
  "UPDATE_OCCLUSION_DENTIST_RECALL_FLOW_FAILURE";
export const CHANGE_OCCLUSION_DENTIST_RECALL_FLOW =
  "CHANGE_OCCLUSION_DENTIST_RECALL_FLOW";

//for get data(clinical exam - wear exam)

export const UPDATE_WEAR_DENTIST = "UPDATE_WEAR_DENTIST";
export const UPDATE_WEAR_DENTIST_FAILURE = "UPDATE_WEAR_DENTIST_FAILURE";
export const CHANGE_WEAR_DENTIST = "CHANGE_WEAR_DENTIST";

// for get data (clinical exam existing treatment)
export const UPDATE_DENTIST_EXISTING_TREATMENT =
  "UPDATE_DENTIST_EXISTING_TREATMENT";
export const UPDATE_DENTIST_EXISTING_TREATMENT_FAILURE =
  "UPDATE_DENTIST_EXISTING_TREATMENT_FAILURE";
export const ADD_DENTIST_EXISTING_TREATMENT_FINDING_ROW =
  "ADD_DENTIST_EXISTING_TREATMENT_FINDING_ROW";
export const CHANGE_DENTIST_EXISTING_TREATMENT_FINDING =
  "CHANGE_DENTIST_EXISTING_TREATMENT_FINDING";
export const DELETE_DENTIST_EXISTING_TREATMENT_FINDING_ROW =
  "DELETE_DENTIST_EXISTING_TREATMENT_FINDING_ROW";
