import React, { useMemo } from 'react';
import { Box, Chip, Grid, InputLabel, Stack, Tooltip } from '@mui/material';
import { useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';
import InputHeading from '../../../common/InputHeading';
import DropDown from '../../../common/DropDown';
import theme from '../../../../../theme';
import CardComponent from "../../../common/CardComponent";
import { RootState } from "../../../../../app/reducers/v2/rootReducer";
import NoDataFound from "../../../../v1/Common/NoDataFound";
import TextBox from "../../../common/TextBox";


const PatientWellnessHygieneRegimen = () => {
    const { palette } = theme
    const location = useLocation();
    const {
        wellnessProfileFilters: { wellnessProfileData },
        patientWellnessProfileWithoutAccount: { withoutAccountGetWellnessProfileData },
    } = useSelector((state: RootState) => state);

    const withoutAccountWellnessProfile: boolean = useMemo(() => {
        return location?.pathname?.includes('/patient/without-account/wellness-profile/')
    }, [location.pathname]);

    const wellnessData = withoutAccountWellnessProfile ? withoutAccountGetWellnessProfileData : wellnessProfileData;
    const hygieneRegimeData = wellnessData?.hygiene_regimen

    return (
        <CardComponent bgColor={theme.palette.secondary.lightShade} color={theme.palette.secondary.main}
            title='Hygiene Regimen'>
            {wellnessData?.hygiene_regimen ?
                <React.Fragment>
                    <Grid container rowSpacing={1.5} columnSpacing={2.5} mb={2.5}>
                        <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
                            <InputHeading title={'Staging'} />
                            <DropDown
                                fromHygieneTreatment={true}
                                inputBackColor={theme?.palette?.common?.white}
                                disabled
                                selectProps={{
                                    name: 'staging',
                                    value: hygieneRegimeData?.staging,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
                            <InputHeading title={'Grading'} />
                            <DropDown
                                fromHygieneTreatment={true}
                                inputBackColor={theme?.palette?.common?.white}
                                disabled
                                selectProps={{
                                    name: 'grading',
                                    value: hygieneRegimeData?.grading,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
                            <InputHeading title={'Extent'} />
                            <DropDown
                                fromHygieneTreatment={true}
                                inputBackColor={theme?.palette?.common?.white}
                                disabled
                                selectProps={{
                                    name: 'extent',
                                    value: hygieneRegimeData?.extent
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container rowSpacing={1.5} columnSpacing={2.5}>
                        <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                            <InputHeading title={'Hygiene Cycle'} />
                            <DropDown
                                fromHygieneTreatment={true}
                                inputBackColor={theme?.palette?.common?.white}
                                disabled
                                prefixLabel={"Months"}
                                selectProps={{
                                    name: 'hygiene',
                                    value: hygieneRegimeData?.hygiene_cycle,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                            <InputHeading title={'Exam Cycle'} />
                            <DropDown
                                fromHygieneTreatment={true}
                                inputBackColor={theme?.palette?.common?.white}
                                disabled
                                prefixLabel={"Months"}
                                selectProps={{
                                    name: 'exam',
                                    value: hygieneRegimeData?.exam_cycle,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                            <InputHeading title={'X-Ray Cycle'} />
                            <DropDown
                                fromHygieneTreatment={true}
                                inputBackColor={theme?.palette?.common?.white}
                                disabled
                                prefixLabel={"Months"}
                                selectProps={{
                                    name: 'xray',
                                    value: hygieneRegimeData?.xray_cycle,
                                }}
                            />
                        </Grid>
                        {hygieneRegimeData?.adjunctive?.length ?
                            <Grid item xs={12}>
                                <InputLabel
                                    htmlFor="adjunctive"
                                    sx={{ mb: "8px" }}
                                    className={`labelColor fw-regular f-14 lh-19 f-w-350`}
                                >
                                    Adjunctive
                                </InputLabel>
                                <Stack direction="row" gap={1} p={'12px'}
                                    border={`1px solid ${palette.divider}`} borderRadius={'8px'} flexWrap={'wrap'}>
                                    {hygieneRegimeData?.adjunctive?.map((res: any, index: number) =>
                                        <Box>
                                            <Tooltip className={'fw-regular'} title={res} key={index}>
                                                <Box sx={{
                                                    textOverflow: 'ellipsis',
                                                    whiteSpace: 'nowrap',
                                                    overflow: 'hidden',
                                                }}>
                                                    <Chip
                                                        disabled
                                                        label={res}
                                                        component="a"
                                                        className={'fw-regular'}
                                                        // onDelete={() => removeAdjunctive(res)}
                                                        sx={{
                                                            textOverflow: 'ellipsis',
                                                            whiteSpace: 'nowrap',
                                                            overflow: 'hidden',
                                                            maxWidth: '170px',
                                                            '.MuiChip-root': {
                                                                color: palette.common.black50,
                                                                backgroundColor: palette.divider
                                                            }
                                                        }}
                                                    />
                                                </Box>

                                            </Tooltip>
                                        </Box>
                                    )}
                                </Stack>
                            </Grid>
                            : null
                        }
                        <Grid item xs={12}>
                            <TextBox
                                label={'Notes'}
                                labelProps={{
                                    htmlFor: 'notes'
                                }}
                                isPadding={true}
                                disabled
                                maxLength={1000}
                                inputProps={{
                                    id: 'notes',
                                    name: 'notes',
                                    value: hygieneRegimeData?.notes,
                                    error: false,
                                    helperText: '',
                                    fullWidth: true,
                                    wrap: true,
                                    InputProps: {
                                        classes: {
                                            root: "border-radius-8 textFieldEndIconPadding textFieldStartIconPadding bg-white",
                                            input: "textFieldStartEndIcon",
                                        }
                                    }
                                }}
                            />

                        </Grid>
                    </Grid>
                </React.Fragment> : <Box py={1.5}> <NoDataFound errorMessage={'hygiene regimen'} /></Box>
            }
        </CardComponent>
    )
}

export default PatientWellnessHygieneRegimen