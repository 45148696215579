import {
   
    GET_LIST_OF_PATIENT_REQUEST,
    GET_LIST_OF_PATIENT_REQUEST_SUCCESS,
    GET_LIST_OF_PATIENT_REQUEST_FAILURE
} from '../../../../ActionTypes/v1/dentist/clinic'
import {
    getPatientListState,
    GetPatientListActions,
} from '../../../../types/dentistTypes'


const initialState: getPatientListState = {
    pending: false,
    data: null,
    error: null
};


const getPatientListReducer = (state = initialState, action: GetPatientListActions) => {
    switch (action.type) {
        case GET_LIST_OF_PATIENT_REQUEST:
            return {
                ...state,
                pending: true,
            }
        case GET_LIST_OF_PATIENT_REQUEST_SUCCESS:
            return {
                ...state,
                pending: false,
                data: action.payload,
                error: null
            }
        case GET_LIST_OF_PATIENT_REQUEST_FAILURE:
            return {
                ...state,
                pending: false,
                data: null,
                error: action.payload
            }    
        default:
            return {
                ...state
            };
    }
}

export default getPatientListReducer