import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FormControl, ListItemText, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PopupComponent from '../../common/PopupComponent';
import { MenuProps } from '../../../../utils/v2/appointmentConstant';
import theme from '../../../../theme';
import { RootState } from '../../../../app/reducers/v2/rootReducer';
import { multipleNumberPopUp } from '../../../../app/actions/v2/patient/authAction';
import { hideMiddleDigits } from '../../../../utils/helper';
import { sendMultipleMobileOtpValidator } from '../../../../validation';
import MobileVerificationPopUp from './mobileVerificationPopUp';
import { patientEmailSendOtpRequest } from '../../../../app/actions/v2/patient/emailAndQrCodeFormAction';

type Props = {
    open: boolean;
};

const useStyles = makeStyles((theme: any) => ({
    inputLabel: {
        "& .MuiInputLabel-root": {
            top: "-5px !important",
            fontFamily: 'FavoritPro-Regular !important',
        },
        "&$cssFocused $notchedOutline": {
            borderColor: `${theme.palette.secondary.main} !important`,
        },
        "& .MuiOutlinedInput-input": {
            fontFamily: 'FavoritPro-Regular !important',
        }
    },
    selectClass: {
        color: `${theme.palette.common.black50} !important`,
        borderRadius: '8px',
        "& .MuiSelect-select": {
            height: '100% !important',
            display: 'flex',
            alignItems: 'center',
            padding: '10px',
        },
        "& fieldset": {
            borderColor: theme.palette.text.darkGrey, //  TODO: base border color -- >> #666666
        },
        "&:hover fieldset": {
            borderColor: theme.palette.common.black50, // hover color -- >> TODO: #333333
        },
        "&.Mui-focused fieldset": {
            border: `2px solid ${theme.palette.v2.primary.main} !important`,
        },
        "& .Mui-disabled": {
            "& .MuiOutlinedInput-input": {
                fontFamily: "FavoritPro-Regular !important",
                color: `${theme.palette.text.secondary} !important`,
                "& fieldset": {
                    border: `1px solid ${theme.palette.text.secondary} !important`,
                },
            },
            "-webkit-text-fill-color": `${theme.palette.text.secondary} !important`,
            color: `${theme.palette.text.secondary} !important`,
            opacity: 1,
        },
    },
    menuText: {
        "& .MuiTypography-root": {
            fontSize: "14px",
            fontFamily: "FavoritPro-Regular !important",
            fontWeight: 400,
            color: theme.palette?.common.black50,
        },
    },
}));

const MultipleNumberPopUp = (props: Props) => {
    const {
        open,
    } = props;

    const classes = useStyles();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [selectedOption, setSelectedOption] = useState('')
    const [error, setError] = useState(null) as any
    const [mobileVerificationPopUp, setMobileVerificationPopUp] = useState(false) as any

    const {
        patientAuthV2: { multipleNumberData },
    } = useSelector((state: RootState) => state) as any;

    const handleClose = () => {
        setSelectedOption('')
        setError(null)
        dispatch(multipleNumberPopUp({
            multipleNumberPopUp: false,
            multipleNumberData: null
        }))
    }

    const handleSave = () => {
        let { errors, isValid } = sendMultipleMobileOtpValidator(selectedOption);

        const selectedMobileData = multipleNumberData?.find((item: any) => item?.id === selectedOption)

        if (isValid) {
            dispatch(
                patientEmailSendOtpRequest({
                    profile_id: selectedMobileData?.id,
                    mobile_number: selectedMobileData?.mobile_number,
                    account_type: "patient",
                    callback: () => {
                        setMobileVerificationPopUp(true)
                    },
                })
            );
        } else {
            setError(errors)
        }
    }

    const handleChange = (event: SelectChangeEvent) => {
        setSelectedOption(event.target.value);
        setError(null)
    };

    return (
        <>
            <PopupComponent
                width={'450px'}
                saveBtnText={'Send'}
                cancelBtnText={'Cancel'}
                headerAction
                footerAction
                open={open}
                heading={'Send User Name'}
                handleClose={handleClose}
                handleSave={handleSave}
                isOraqIcon
                isPatient
            >
                <FormControl fullWidth error={error}>
                    <Select
                        fullWidth
                        value={selectedOption}
                        className={`${classes.selectClass} ${classes.inputLabel} cssOutlinedInput text-overflow-ellipsis fw-regular f-w-400 f-14`}
                        displayEmpty
                        onChange={(e: any) => handleChange(e)}
                        MenuProps={MenuProps}
                    >
                        <MenuItem value={""} id={""} className="text-overflow-ellipsis fw-regular f-w-400 f-14">
                            <ListItemText
                                className={`${classes.menuText} text-overflow-ellipsis fw-regular f-w-400 f-14`}
                                primary={"Select Your Number"}
                            />
                        </MenuItem>
                        {multipleNumberData &&
                            multipleNumberData?.map((itm: any, index: number) => (
                                <MenuItem
                                    key={index}
                                    value={itm.id}
                                    id={itm.id}
                                    className="text-overflow-ellipsis fw-regular f-w-400 f-14"
                                >
                                    <ListItemText
                                        className={`${classes.menuText} text-overflow-ellipsis fw-regular f-w-400 f-14`}
                                        primary={hideMiddleDigits(itm?.mobile_number)}
                                    />
                                </MenuItem>
                            ))}
                    </Select>
                    <Typography component='span' m={'3px 0px 0px 10px'} className="f-12" color={theme.palette.error.main}>
                        {error}
                    </Typography>
                </FormControl>
            </PopupComponent>

            {mobileVerificationPopUp &&
                <MobileVerificationPopUp
                    open={mobileVerificationPopUp}
                    setOpen={setMobileVerificationPopUp}
                    selectedOption={selectedOption}
                    isPatient
                />}
        </>
    )
}

export default MultipleNumberPopUp