import {
  UPDATE_PRE_CLINICAL_INFO,
  CHANGE_PRE_CLINICAL_INFO,UPDATE_PRE_CLINICAL_INFO_FAILURE
} from "../../../../ActionTypes/v1/dentist/clinicalExamAssistant";
import { PreClinicalAssistantInterface } from "../../../../types/dentistTypes";

export const updatePreClinicalInfoRequest = (
  payload: PreClinicalAssistantInterface
): PreClinicalAssistantInterface =>
  ({
    type: UPDATE_PRE_CLINICAL_INFO,
    payload,
  } as any);

export const updatePreClinicalInfoRequestFailure = ():any => ({
  type: UPDATE_PRE_CLINICAL_INFO_FAILURE
}) as any;

export const changePreClinicalInfoAction = (payload: any): any =>
  ({
    type: CHANGE_PRE_CLINICAL_INFO,
    payload,
  } as any);
