import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { makeStyles } from '@mui/styles';
import { Button, CircularProgress, Divider, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@mui/system';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import theme from '../../../../theme';
import Heading from '../../../v1/Common/Heading';

const useStyles = makeStyles((theme: any) => ({
  DialogActionsClass: {
    padding: '16px 20px',
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between'
  },
  closeButton: {
    padding: '0px !important',
    display: 'flex',
    justifyContent: 'flex-end',
    backgroundColor: 'transparent',
    color: theme.palette.common.black,
    '&:hover': {
      backgroundColor: theme.palette.common.white,
      boxShadow: 'none'
    }
  },
  dialogContent: {
    width: '100% !important',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center'
  },

  verifyButton: {
    height: '40px',
    padding: '10px 20px !important',
    width: '170px',
    borderRadius: '4px',
    background: theme.palette.v2.primary.main,
    color: theme.palette.common.white,
    textDecoration: 'none',
    textAlign: 'center',
    marginLeft: '10px',
    '&:hover': {
      background: theme.palette.v2.primary.main,
      color: theme.palette.common.white
    }
  },
  skipPhotos: {
    color: theme.palette.v2.secondary,
    marginRight: '10px',
    '&:hover': {
      background: 'transparent',
      color: theme.palette.v2.secondary
    }
  },
  deleteButton: {
    height: '40px',
    padding: '10px 20px !important',
    width: '170px',
    borderRadius: '4px',
    background: theme.palette.error.main,
    color: theme.palette.common.white,
    textDecoration: 'none',
    textAlign: 'center',
    marginLeft: '10px',
    '&:hover': {
      background: theme.palette.error.main,
      color: theme.palette.common.white
    }
  },
  verifyButtonContainer: {
    padding: '12px 20px',
    justifyContent: 'space-between !important'
  },
  cancelBtn: {
    height: '40px',
    padding: '8.5px 20px',
    border: `1px solid ${theme.palette.v2.primary.main}`,
    color: theme.palette.v2.primary.main,
    '&:hover': {
      backgroundColor: 'transparent',
      borderColor: theme.palette.v2.primary.main
    }
  }
}));

type Props = {
  open: boolean;
  setOpen: any;
  deleteHandler: any;
  deleteText: string;
  loader?: boolean;
};
const DeleteConfirmationPopUp = (props: Props) => {
  const { open, setOpen, deleteHandler, deleteText, loader } = props;
  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
  };
  const handleDelete = () => {
    deleteHandler();
  };
  return (
    <Dialog
      sx={{
        '& .MuiDialogContent-root': {
          width: '100% !important',
          padding: '12px 20px'
        },
        '& .MuiPaper-root ': {
          width: '600px !important',
          maxWidth: '600px !important',
          background: theme.palette.common.white,
          boxShadow: ' 0px 10px 20px rgba(0, 0, 0, 0.07)',
          borderRadius: '16px !important',
          display: 'flex !important',
          alignItems: 'center !important',
          justifyContent: 'center !important'
        }
      }}
      open={open}
      onClose={handleClose}
    >
      <DialogActions className={classes.DialogActionsClass}>
        <Heading className='f-14 f-w-500 fw-medium' sx={{ mb: '0' }} heading={'Delete'} />
        <Button disabled={loader} onClick={handleClose} className={classes.closeButton} disableRipple>
          <FontAwesomeIcon
            icon={faClose}
            color={theme.palette.common.black50}
            className='f-20'
            width={'20px'}
            height={'20px'}
          />
        </Button>
      </DialogActions>
      <Divider sx={{ width: '100%', borderColor: theme.palette.divider }} />
      <DialogContent>
        <Box className={classes.dialogContent}>
          <Typography className='f-14 f-w-500 fw-medium'>Are you sure you want to delete this {deleteText}?</Typography>
        </Box>
      </DialogContent>
      <Divider sx={{ width: '100%', borderColor: theme.palette.divider }} />
      <DialogActions className={`d-flex justify-content-between ${classes.verifyButtonContainer} w-100`}>
        <Button variant='outlined' onClick={handleClose} className={classes.cancelBtn} disabled={loader}>
          Cancel
        </Button>
        <Button variant='contained' className={classes.deleteButton} onClick={handleDelete} disabled={loader}>
          {loader ? <CircularProgress color={'error'} size={19} /> : 'Delete'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteConfirmationPopUp;
