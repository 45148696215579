import {UnsubscribeEmailAction, UnsubscribeEmailInterface} from "../../types/unsubscribeEmail/unsubscribeTypes";
import {
    UNSUBSCRIBE_EMAIL_REQUEST, UNSUBSCRIBE_EMAIL_REQUEST_FAILURE,
    UNSUBSCRIBE_EMAIL_REQUEST_SUCCESS
} from "../../ActionTypes/unsubscribeEmail/unsubscribeActionTypes";


const initialState: UnsubscribeEmailInterface = {
    unsubscribeEmailLoader: false,
    unsubscribeEmailData: null,
    unsubscribeEmailError: null,
};

const unsubscribeEmailReducer = (state = initialState, action: UnsubscribeEmailAction) => {
    switch (action.type) {
        case UNSUBSCRIBE_EMAIL_REQUEST:
            return {
                ...state,
                unsubscribeEmailLoader: true,
                unsubscribeEmailData: null,
                unsubscribeEmailError: null
            };
        case UNSUBSCRIBE_EMAIL_REQUEST_SUCCESS:
            return {
                ...state,
                unsubscribeEmailLoader: false,
                unsubscribeEmailData: action.payload,
                unsubscribeEmailError: null
            };
        case UNSUBSCRIBE_EMAIL_REQUEST_FAILURE:
            return {
                ...state,
                unsubscribeEmailLoader: false,
                unsubscribeEmailData: null,
                unsubscribeEmailError: action.payload
            };
        default:
            return {
                ...state
            };
    }
};

export default unsubscribeEmailReducer;
