import {
  POST_RISK_PROFILE_REQUEST,
  UPDATE_RISK_PROFILE_REQUEST, UPDATE_RISK_PROFILE_CACHE_DATA_CLEAR_REQUEST
} from "../../../ActionTypes/v1/patient/riskProfile";

const initialState: any = {
  data: {
    patient_id: "",
    appointment_id: "",
    version: null,
    appointmentImages: false,
    appointmentXrays: false,
    riskData: [
      {
        title: "Overall Sleep Disorder Risk",
        name: "sleepRisk",
        sliderValue: null,
        valueFromAI: null,
        isNote: false,
        note: "",
        modify: false,
        modifyRange: false,
        modifyButton: false,
        acceptButton: false,
        canGoBack: true,
        reason: "",
        appointmentImages: false,
        appointmentXrays: false,
        status: null
      },
      {
        title: "Overall Occlusion/TMJ/Orthodontic Risk",
        name: "occlusionTMjRisk",
        sliderValue: null,
        valueFromAI: null,
        isNote: false,
        note: "",
        modify: false,
        modifyRange: false,
        modifyButton: false,
        acceptButton: false,
        canGoBack: true,
        reason: "",
        appointmentImages: false,
        appointmentXrays: false,
        status: null
      },
      {
        title: "Overall Caries Risk",
        name: "cariesRisk",
        sliderValue: null,
        valueFromAI: null,
        isNote: false,
        note: "",
        modify: false,
        modifyRange: false,
        modifyButton: false,
        acceptButton: false,
        canGoBack: true,
        reason: "",
        appointmentImages: false,
        appointmentXrays: false,
        status: null
      },
      {
        title: "Overall Periodontal Risk",
        name: "periodontalRisk",
        sliderValue: null,
        valueFromAI: null,
        isNote: false,
        note: "",
        modify: false,
        modifyRange: false,
        modifyButton: false,
        acceptButton: false,
        canGoBack: true,
        reason: "",
        appointmentImages: false,
        appointmentXrays: false,
        status: null
      },
      {
        title: "Overall Biomechanics Risk",
        name: "biomechanicsRisk",
        sliderValue: null,
        valueFromAI: null,
        isNote: false,
        note: "",
        modify: false,
        modifyRange: false,
        modifyButton: false,
        acceptButton: false,
        canGoBack: true,
        reason: "",
        appointmentImages: false,
        appointmentXrays: false,
        status: null
      },
      {
        title: "Overall Esthetics Risk",
        name: "estheticsRisk",
        sliderValue: null,
        valueFromAI: null,
        isNote: false,
        note: "",
        modify: false,
        modifyRange: false,
        modifyButton: false,
        acceptButton: false,
        canGoBack: true,
        reason: "",
        appointmentImages: false,
        appointmentXrays: false,
        status: null
      },
      {
        title: "Overall Medical Risk",
        name: "medicalRisk",
        sliderValue: null,
        valueFromAI: null,
        isNote: false,
        note: "",
        modify: false,
        modifyRange: true,
        modifyButton: false,
        acceptButton: false,
        canGoBack: true,
        reason: "",
        appointmentImages: false,
        appointmentXrays: false,
        status: null
      },
    ],
  },
};

export const riskProfileReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case UPDATE_RISK_PROFILE_REQUEST:
      return {
        ...state,
        data: action.payload,
      };
    case UPDATE_RISK_PROFILE_CACHE_DATA_CLEAR_REQUEST:
      return {
        ...state,
        data: null
      }  
    case POST_RISK_PROFILE_REQUEST:
      return {
        ...state,
      };
    default:
      return { ...state };
  }
};
