import { call, put, takeEvery, all } from "redux-saga/effects";
import API from "../../../../../../utils/api";
import {
  putInsightRecommendationRequestSuccess,
  putInsightRecommendationRequestFailure,
  getInsightRecommendationRequestSuccess,
  getInsightRecommendationRequestFailure,
  getInsightRecommendationCacheDataClearRequest,
} from "../../../../../actions/v1/dentist/assignedTreatment/insightAndRecommendationActions/insightAndRecommendation";
import {
  PUT_INSIGHTS_RECOMMENDATIONS_REQUEST,
  GET_INSIGHTS_RECOMMENDATIONS_REQUEST,
} from "../../../../../ActionTypes/v1/dentist/insightAndRecommendation";
import { updateInsightRecommendationCacheDataClearRequest } from "../../../../../actions/v1/dentist/assignedTreatment/insightAndRecommendationActions/updateInsightRecommendation";
import { errorToaster } from '../../../../../../utils/helper';

function* putInsightRecommendationRequestSaga(payload: any): any {
  try {
    const response = yield API.put(
      `/exam/dentist/insights/recommendations/create?appointment_id=${payload?.payload?.appointmentId}`,
      payload.payload?.putInsightRecommendationData
    );
  
    if (payload?.payload?.callback) {
      payload?.payload?.callback(response?.data);
    }
    yield put(
      putInsightRecommendationRequestSuccess({
        putInsightRecommendationData: response?.data,
      })
    );
  } catch (e: any) {
    errorToaster(e?.detail)
    yield put(
      putInsightRecommendationRequestFailure({
        putInsightRecommendationError: e?.detail,
      })
    );
  }
}
function* getInsightRecommendationRequestSaga(payload: any): any {
  yield put(getInsightRecommendationCacheDataClearRequest())
  yield put(updateInsightRecommendationCacheDataClearRequest())
  try {
    const response = yield API.get(
      `/exam/dentist/insights/recommendations/${payload?.payload?.appointmentId}`
    );
    yield call(payload.payload.callback, response.data);
    if (payload.payload.callback) {
      payload?.payload?.callback(response?.data);
    }
    yield put(
      getInsightRecommendationRequestSuccess({
        getInsightRecommendationData: response?.data,
      })
    );
  } catch (e: any) {
     //TODO: update toast according to status code
    //   errorToaster(e?.detail);
    yield put(getInsightRecommendationCacheDataClearRequest())
    yield put(updateInsightRecommendationCacheDataClearRequest())
    yield put(
      getInsightRecommendationRequestFailure({
        getInsightRecommendationError: e?.detail,
      })
    );
  }
}

function* putInsightRecommendationSaga() {
  yield takeEvery(
    PUT_INSIGHTS_RECOMMENDATIONS_REQUEST,
    putInsightRecommendationRequestSaga
  );
}
function* getInsightRecommendationSaga() {
  yield takeEvery(
    GET_INSIGHTS_RECOMMENDATIONS_REQUEST,
    getInsightRecommendationRequestSaga
  );
}

export default function* rootSaga() {
  yield all([putInsightRecommendationSaga(), getInsightRecommendationSaga()]);
}
