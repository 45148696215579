export const PATIENT_REGISTER_FORM_DATA = 'PATIENT_REGISTER_FORM_DATA';
export const PATIENT_REGISTER_FORM_ERROR = 'PATIENT_REGISTER_FORM_ERROR';

export const GET_PATIENT_DATA_REQUEST = 'GET_PATIENT_DATA_REQUEST'
export const GET_PATIENT_DATA_SUCCESS = 'GET_PATIENT_DATA_SUCCESS'
export const GET_PATIENT_DATA_FAIL = 'GET_PATIENT_DATA_FAIL'

export const SAVE_PATIENT_REGISTER_REQUEST = 'SAVE_PATIENT_REGISTER_REQUEST'
export const SAVE_PATIENT_REGISTER_SUCCESS = 'SAVE_PATIENT_REGISTER_SUCCESS'
export const SAVE_PATIENT_REGISTER_FAIL = 'SAVE_PATIENT_REGISTER_FAIL'

export const VERIFY_PATIENT_USERNAME_REQUEST = 'VERIFY_PATIENT_USERNAME_REQUEST'
export const VERIFY_PATIENT_USERNAME_SUCCESS = 'VERIFY_PATIENT_USERNAME_SUCCESS'
export const VERIFY_PATIENT_USERNAME_FAIL = 'VERIFY_PATIENT_USERNAME_FAIL'
