import React, { useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import {
  ClickAwayListener,
  IconButton,
  InputAdornment,
  InputLabel,
  TextField,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar, faXmark } from "@fortawesome/free-solid-svg-icons";
import { dateFormat, usDateFormat } from "../../../../utils/helper";
import theme from "../../../../theme";
import RequiredStar from "../RequiredStar";

const useStyles = makeStyles((theme: any) => ({
  rangeDatePicker: {
    width: "100%",
    "& .MuiPickersDay-root": {
      fontFamily: "FavoritPro-Regular !important",
    },
    "& .MuiButtonBase-root": {
      fontFamily: "FavoritPro-Regular !important",
    },
  },
  focused: {
    "& .Mui-focused .MuiOutlinedInput-notchedOutline ": {
      borderColor: theme.palette.v2.primary.main,
    },
  },
}));

const DateSelect = (props: any) => {
  const classes = useStyles();
  const {
    datePickerProps,
    clearClickHandler,
    showClearIcon,
    value,
    disabled,
    disableFuture,
    onChange,
    inputProps,
    sx,
    maxDate,
    isPhotoList,
    label,
    labelSx,
    labelProps,
    required,
    isPatient
  } = props;
  const [isOpen, setIsOpen] = useState(false);

  const handleClickAway = () => {
    if (isOpen) {
      setIsOpen(false);
    }
  };
  const handleOpen = () => {
    setIsOpen(true);
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box>
        {
          label &&
          <InputLabel
            htmlFor={label}
            sx={{
              mb: "8px", whiteSpace: {
                xs: 'break-spaces',
                sm: 'nowrap'
              },
              ...labelSx
            }}
            className={`labelColor fw-regular f-14 lh-19 f-w-350`}
            {...labelProps}
          >
            {label} {required && <RequiredStar isPatient={isPatient} />}
          </InputLabel>
        }

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            onChange={(e: any) => {
              onChange(e);
              // setIsOpen(false);
            }}
            onClose={() => setIsOpen(false)}
            views={["year", "month", "day"]}
            width="auto"
            maxDate={maxDate}
            disabled={disabled}
            disableFuture={disableFuture}
            className={`${classes.rangeDatePicker} ${classes.focused}`}
            inputFormat={usDateFormat}
            renderInput={(params: any) => (
              <TextField
                placeholder={usDateFormat}
                value={dateFormat(value)}
                {...params}
                {...datePickerProps}
                {...inputProps}
                inputProps={{
                  readOnly: "true",
                }}
                sx={{
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: `1px solid ${disabled ? theme.palette.text.primary : inputProps?.error ? theme.palette.error.main : isPatient ? theme.palette.secondary.main : theme.palette.text.darkGrey} !important`,
                    opacity: disabled ? 0.26 : 1
                  },
                  input: {
                    color: `${theme.palette.common.black50} !important`, // TODO: inner text color -->> #3333333
                    "&::placeholder": {
                      color: theme.palette.text.darkGrey, //  TODO: placeholder color -- >> #666666
                      opacity: 1,
                    },

                    "& .Mui-disabled": {
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: `1px solid ${isPatient ? theme.palette.secondary.main : theme.palette.text.secondary} !important`,
                        },
                      },
                      color: `${theme.palette.text.secondary} !important`,
                      opacity: 1,
                    },

                    ".Mui-error ": {
                      fontSize: "12px",
                      lineHeight: "14px",
                    },
                    ".MuiFormHelperText-root": {
                      marginLeft: "10px",
                      marginTop: "5px",
                    },
                    "& .MuiOutlinedInput-root": {
                      color: `${theme.palette.common.black50} !important`,
                      "& fieldset": {
                        borderColor: isPatient ? theme.palette.secondary.main : theme.palette.text.darkGrey, //  TODO: base border color -- >> #666666
                      },
                      "&:hover fieldset": {
                        borderColor: disabled
                          ? "none"
                          : isPatient ? theme.palette.secondary.main : theme.palette.common.black50,
                      },
                      "&.Mui-focused fieldset": {
                        border: `2px solid ${isPatient ? theme.palette.secondary.main : theme.palette.v2.primary.main} !important`,
                      },
                    },
                    "&.Mui-focused fieldset": {
                      border: `2px solid ${isPatient ? theme.palette.secondary.main : theme.palette.v2.primary.main} !important`,
                    },
                  },
                  ...sx,
                }}
                InputProps={{
                  classes: {
                    root: "border-radius-8 textFieldEndIconPadding textFieldStartIconPadding bg-white",
                    input: "textFieldStartEndIcon",
                  },
                  endAdornment: (
                    <>
                      {showClearIcon && value && !disabled && (
                        <InputAdornment
                          position="end"
                          onClick={() => {
                            clearClickHandler();
                          }}
                        >
                          <FontAwesomeIcon
                            className="cursor-pointer"
                            icon={faXmark}
                            style={{
                              height: "16px",
                              color: theme.palette.text.darkGrey,
                            }}
                          />
                        </InputAdornment>
                      )}
                      {
                        <InputAdornment
                          position="end"
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            marginLeft: "0px",
                            maxHeight: "",
                            marginRight: "13px",
                            "& :hover": {
                              cursor: disabled ? "default" : "pointer",
                            },
                          }}
                        >
                          <IconButton
                            onClick={() => {
                              !disabled && handleOpen();
                            }}
                            disableFocusRipple
                            disableRipple
                            edge="end"
                            sx={{ width: "48px", height: "48px" }}
                          >
                            <FontAwesomeIcon
                              icon={faCalendar}
                              style={{
                                height: "16px",
                                color: disabled
                                  ? theme.palette.text.secondary
                                  : theme.palette.text.darkGrey,
                              }}
                            />
                          </IconButton>
                        </InputAdornment>
                      }
                    </>
                  ),
                }}
              />
            )}
            {...datePickerProps}
            value={dateFormat(value) || null}
            open={isOpen}
          />
        </LocalizationProvider>
      </Box>
    </ClickAwayListener>
  );
};

export default DateSelect;
