export const riskAssessmentInsufficient = "INSUFFICIENT DATA FOR STAGE AND GRADE"

export const riskAssessmentOption = [
    {
        label: 'Modify',
        value: 'Modify'
    },
    {
        label: 'Accept',
        value: 'Accept'
    }
];

export const riskData = [
    {
        name: 'Overall Medical Risk',
        key: 'medicalRisk'
    },
    {
        name: 'Overall Sleep Disorder Risk',
        key: 'sleepRisk'
    },
    {
        name: 'Overall Occlusion/TMJ/Orthodontic Risk',
        key: 'occlusionTmjRisk'
    },
    {
        name: 'Overall Biomechanical Risk',
        key: 'biomechanicsRisk'
    },
    {
        name: 'Overall Caries Risk',
        key: 'cariesRisk'
    },
    {
        name: 'Overall Periodontal Risk',
        key: 'periodontalRisk'
    },
    {
        name: 'Overall Esthetics Index',
        key: 'estheticsRisk'
    }
];

export const patientRiskData = [
    {
        name: 'Overall Medical Risk',
        key: 'medicalRisk'
    },
    {
        name: 'Overall Sleep Disorder Risk',
        key: 'sleepRisk'
    },
    {
        name: 'Risk of Bite/Jaw Issues',
        key: 'occlusionTmjRisk'
    },
    {
        name: 'Risk of Cracks/Breaks',
        key: 'biomechanicsRisk'
    },
    {
        name: 'Risk of Cavities',
        key: 'cariesRisk'
    },
    {
        name: 'Risk of Gum Disease',
        key: 'periodontalRisk'
    },
    {
        name: 'Overall Esthetics Index',
        key: 'estheticsRisk'
    }
];
