// get recall exam intraoral in dentist flow
export const GET_INTRA_ORAL_DENTIST_RECALL_EXAM_REQUEST = "GET_INTRA_ORAL_DENTIST_RECALL_EXAM_REQUEST"
export const GET_INTRA_ORAL_DENTIST_RECALL_EXAM_REQUEST_SUCCESS = "GET_INTRA_ORAL_DENTIST_RECALL_EXAM_REQUEST_SUCCESS"
export const GET_INTRA_ORAL_DENTIST_RECALL_EXAM_REQUEST_FAILURE = "GET_INTRA_ORAL_DENTIST_RECALL_EXAM_REQUEST_FAILURE"

// get recall exam occlusion in dentist flow
export const GET_OCCLUSION_DENTIST_RECALL_EXAM_REQUEST = "GET_OCCLUSION_DENTIST_RECALL_EXAM_REQUEST"
export const GET_OCCLUSION_DENTIST_RECALL_EXAM_REQUEST_SUCCESS = "GET_OCCLUSION_DENTIST_RECALL_EXAM_REQUEST_SUCCESS"
export const GET_OCCLUSION_DENTIST_RECALL_EXAM_REQUEST_FAILURE = "GET_OCCLUSION_DENTIST_RECALL_EXAM_REQUEST_FAILURE"

// get recall exam oCclusion in dentist flow
export const GET_PERIODONTAL_DENTIST_RECALL_EXAM_REQUEST = "GET_PERIODONTAL_DENTIST_RECALL_EXAM_REQUEST"
export const GET_PERIODONTAL_DENTIST_RECALL_EXAM_REQUEST_SUCCESS = "GET_PERIODONTAL_DENTIST_RECALL_EXAM_REQUEST_SUCCESS"
export const GET_PERIODONTAL_DENTIST_RECALL_EXAM_REQUEST_FAILURE = "GET_PERIODONTAL_DENTIST_RECALL_EXAM_REQUEST_FAILURE"

// put recall exam intraoral in dentist flow
export const PUT_INTRA_ORAL_DENTIST_RECALL_EXAM_REQUEST = "PUT_INTRA_ORAL_DENTIST_RECALL_EXAM_REQUEST"
export const PUT_INTRA_ORAL_DENTIST_RECALL_EXAM_REQUEST_SUCCESS = "PUT_INTRA_ORAL_DENTIST_RECALL_EXAM_REQUEST_SUCCESS"
export const PUT_INTRA_ORAL_DENTIST_RECALL_EXAM_REQUEST_FAILURE = "PUT_INTRA_ORAL_DENTIST_RECALL_EXAM_REQUEST_FAILURE"

// put recall exam occlusion in dentist flow
export const PUT_OCCLUSION_DENTIST_RECALL_EXAM_REQUEST = "PUT_OCCLUSION_DENTIST_RECALL_EXAM_REQUEST"
export const PUT_OCCLUSION_DENTIST_RECALL_EXAM_REQUEST_SUCCESS = "PUT_OCCLUSION_DENTIST_RECALL_EXAM_REQUEST_SUCCESS"
export const PUT_OCCLUSION_DENTIST_RECALL_EXAM_REQUEST_FAILURE = "PUT_OCCLUSION_DENTIST_RECALL_EXAM_REQUEST_FAILURE"

// put recall exam oCclusion in dentist flow
export const PUT_PERIODONTAL_DENTIST_RECALL_EXAM_REQUEST = "PUT_PERIODONTAL_DENTIST_RECALL_EXAM_REQUEST"
export const PUT_PERIODONTAL_DENTIST_RECALL_EXAM_REQUEST_SUCCESS = "PUT_PERIODONTAL_DENTIST_RECALL_EXAM_REQUEST_SUCCESS"
export const PUT_PERIODONTAL_DENTIST_RECALL_EXAM_REQUEST_FAILURE = "PUT_PERIODONTAL_DENTIST_RECALL_EXAM_REQUEST_FAILURE" 