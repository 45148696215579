import React from 'react'
import { Box, Grid, Typography, useMediaQuery } from '@mui/material'
import { makeStyles } from '@mui/styles';
import patientBanner from "../../../../assets/images/v2/patientBanner.png";
import transparentLogo from "../../../../assets/images/v1/transparent_logo.png";
import theme from '../../../../theme';

const useStyles = makeStyles((theme: any) => ({
    leftSection: {
        width: "100%",
        overflow: "hidden",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        height: "auto",
        backgroundPosition: "center",
        zIndex: 10,
        position: "relative",
        [theme.breakpoints.down("md")]: {
            display: "none",
            height: "100vh",
            position: "absolute",
            zIndex: -1,
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
        },
    },
    imageText: {
        position: "absolute",
        top: "24%",
        right: "50%",
        transform: "translate(50%,-50%)",
        width: "100%",
        padding: "0 20px",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
    },
}));

interface Props {
    imageText?: any
}

const PatientLeftSideImage = (props: Props) => {
    const classes = useStyles();
    const { palette } = theme;
    const { imageText } = props

    const isSmallScreen = useMediaQuery(theme.breakpoints.down(960));

    return (
        <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            className={classes.leftSection}
            style={{
                backgroundImage: `url(${patientBanner})`,
            }}
        >
            <Typography
                color={palette.common.white}
                fontWeight="bold"
                fontSize={"30px"}
                style={{ lineHeight: "40px" }}
                className={`fw-medium f-w-500 ${classes.imageText}`}
                component={"span"}

            >
                <span className={'fw-medium'}>
                    {imageText?.text1}
                    <br />
                    {imageText?.text2}
                    <br />
                    {imageText?.text3}
                </span>
            </Typography>

            <Box className={`whiteLogoContainer`} position={'absolute'} top={'24px'} left={'24px'}
                zIndex={1024}>
                <img style={{ width: '85px', height: '30px' }}
                    src={transparentLogo}
                    className={'cursor-pointer'}
                    alt='logo' />
            </Box>
        </Grid>
    )
}

export default PatientLeftSideImage