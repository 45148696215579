import React from 'react'
import { Typography } from '@mui/material'
import PopupComponent from '../../../common/PopupComponent'

type Props = {
    open: boolean
    handleClose: any
    continueHandler: any
}
const RegisterPopUp = (props: Props) => {
    const { open, handleClose, continueHandler } = props
    const handleSubmit = () => {
        continueHandler(true)
    }
    return (
        <PopupComponent
            saveBtnText={'Submit'}
            width={'530px'}
            headerAction={false}
            footerAction
            open={open}
            handleClose={handleClose}
            cancelBtnText={'Cancel'}
            handleSave={handleSubmit}
        >
            <Typography className={'f-16 f-w-400 fw-regular labelColor'} textAlign={'center'} py={2} lineHeight={'20px'}>
                Are you sure you want to submit your health history?
            </Typography>
        </PopupComponent>
    )
}

export default RegisterPopUp