export const PUT_INSURANCE_APPROVAL_REQUEST =
    "PUT_INSURANCE_APPROVAL_REQUEST";
export const PUT_INSURANCE_APPROVAL_SUCCESS =
    "PUT_INSURANCE_APPROVAL_SUCCESS";
export const PUT_INSURANCE_APPROVAL_FAILURE =
    "PUT_INSURANCE_APPROVAL_FAILURE";

export const GET_INSURANCE_APPROVAL_REQUEST =
    "GET_INSURANCE_APPROVAL_REQUEST";
export const GET_INSURANCE_APPROVAL_CACHE_DATA_CLEAR_REQUEST = "GET_INSURANCE_APPROVAL_CACHE_DATA_CLEAR_REQUEST";   
export const GET_INSURANCE_APPROVAL_SUCCESS =
    "GET_INSURANCE_APPROVAL_SUCCESS";
export const GET_INSURANCE_APPROVAL_FAILURE =
    "GET_INSURANCE_APPROVAL_FAILURE";

export const GET_INSURANCE_APPROVAL_CHECKLIST_REQUEST =
    "GET_INSURANCE_APPROVAL_CHECKLIST_REQUEST";
export const GET_INSURANCE_APPROVAL_CHECKLIST_CACHE_DATA_CLEAR_REQUEST = "GET_INSURANCE_APPROVAL_CHECKLIST_CACHE_DATA_CLEAR_REQUEST";    
export const GET_INSURANCE_APPROVAL_CHECKLIST_SUCCESS =
    "GET_INSURANCE_APPROVAL_CHECKLIST_SUCCESS";
export const GET_INSURANCE_APPROVAL_CHECKLIST_FAILURE =
    "GET_INSURANCE_APPROVAL_CHECKLIST_FAILURE";

export const PUT_INSURANCE_APPROVAL_CHECKLIST_REQUEST =
    "PUT_INSURANCE_APPROVAL_CHECKLIST_REQUEST";
export const PUT_INSURANCE_APPROVAL_CHECKLIST_SUCCESS =
    "PUT_INSURANCE_APPROVAL_CHECKLIST_SUCCESS";
export const PUT_INSURANCE_APPROVAL_CHECKLIST_FAILURE =
    "PUT_INSURANCE_APPROVAL_CHECKLIST_FAILURE";
