import {
    GetClinicalExamAssistantRequest,
    GetClinicalExamAssistantRequestFailure,
    GetClinicalExamAssistantRequestFailurePayload,
    GetClinicalExamAssistantRequestPayload,
    GetClinicalExamAssistantRequestSuccess,
    GetClinicalExamAssistantRequestSuccessPayload,
    GetDiscProfileRequest,
    GetDiscProfileRequestFailure,
    GetDiscProfileRequestFailurePayload,
    GetDiscProfileRequestPayload,
    GetDiscProfileRequestSuccess,
    GetDiscProfileRequestSuccessPayload,
    PostAddMultipleFindingDataRequest,
    PostClinicalExamAssistantDataRequest,
    PostImageUploadMultipleRequestPayload,
    PostImageUploadMultipleRequestSuccessPayload,
    PostImageUploadMultipleRequestFailurePayload,
    PostImageUploadMultipleRequest,
    PostImageUploadMultipleRequestSuccess,
    PostImageUploadMultipleRequestFailure,
    PostDeleteMultipleFindingDataRequest,

    PutClinicalExamAssistantRequest,
    PutClinicalExamAssistantRequestSuccess,
    PutClinicalExamAssistantRequestFailure,
    PutClinicalExamAssistantRequestPayload,
    PutClinicalExamAssistantRequestSuccessPayload,
    PutClinicalExamAssistantRequestFailurePayload
} from "../../../../types/dentistTypes";
import {
    GET_CLINICAL_EXAM_ASSISTANT_REQUEST,
    GET_CLINICAL_EXAM_ASSISTANT_REQUEST_FAILURE,
    GET_CLINICAL_EXAM_ASSISTANT_REQUEST_SUCCESS,
    GET_DISC_PROFILE_REQUEST,
    GET_DISC_PROFILE_REQUEST_FAILURE,
    GET_DISC_PROFILE_REQUEST_SUCCESS,
    POST_ADD_MULTIPLE_FINDING,
    POST_DELETE_MULTIPLE_FINDING,
    POST_CLINICAL_EXAM_ASSISTANT_DATA_REQUEST,
    POST_IMAGE_UPLOAD_MULTIPLE_REQUEST,
    POST_IMAGE_UPLOAD_MULTIPLE_REQUEST_SUCCESS,
    POST_IMAGE_UPLOAD_MULTIPLE_REQUEST_FAILURE,

    PUT_CLINICAL_EXAM_ASSISTANT_REQUEST,
    PUT_CLINICAL_EXAM_ASSISTANT_REQUEST_SUCCESS,
    PUT_CLINICAL_EXAM_ASSISTANT_REQUEST_FAILURE,
} from "../../../../ActionTypes/v1/dentist/clinicalExamAssistant";


export const postClinicalExamAssistantDataRequest = (payload: any): PostClinicalExamAssistantDataRequest => ({
    type: POST_CLINICAL_EXAM_ASSISTANT_DATA_REQUEST,
    payload
})

export const postAddMultipleFindingDataRequest = (payload: any): PostAddMultipleFindingDataRequest => ({
    type: POST_ADD_MULTIPLE_FINDING,
    payload
})

export const postDeleteMultipleFindingDataRequest = (payload: any): PostDeleteMultipleFindingDataRequest => ({
    type: POST_DELETE_MULTIPLE_FINDING,
    payload
})

export const getClinicalExamAssistantRequest = (payload: GetClinicalExamAssistantRequestPayload): GetClinicalExamAssistantRequest => ({
    type: GET_CLINICAL_EXAM_ASSISTANT_REQUEST,
    payload,
});

export const getClinicalExamAssistantRequestSuccess = (payload: GetClinicalExamAssistantRequestSuccessPayload): GetClinicalExamAssistantRequestSuccess => ({
    type: GET_CLINICAL_EXAM_ASSISTANT_REQUEST_SUCCESS,
    payload
});

export const getClinicalExamAssistantRequestFailure = (payload: GetClinicalExamAssistantRequestFailurePayload): GetClinicalExamAssistantRequestFailure => ({
    type: GET_CLINICAL_EXAM_ASSISTANT_REQUEST_FAILURE,
    payload
});

export const getDiscProfileRequest = (payload: GetDiscProfileRequestPayload): GetDiscProfileRequest => ({
    type: GET_DISC_PROFILE_REQUEST,
    payload
})

export const getDiscProfileRequestSuccess = (payload: GetDiscProfileRequestSuccessPayload): GetDiscProfileRequestSuccess => ({
    type: GET_DISC_PROFILE_REQUEST_SUCCESS,
    payload
});

export const getDiscProfileRequestFailure = (payload: GetDiscProfileRequestFailurePayload): GetDiscProfileRequestFailure => ({
    type: GET_DISC_PROFILE_REQUEST_FAILURE,
    payload
});

export const postImageUploadMultipleRequest = (payload: PostImageUploadMultipleRequestPayload): PostImageUploadMultipleRequest => ({
    type: POST_IMAGE_UPLOAD_MULTIPLE_REQUEST,
    payload
})

export const postImageUploadMultipleRequestSuccess = (payload: PostImageUploadMultipleRequestSuccessPayload): PostImageUploadMultipleRequestSuccess => ({
    type: POST_IMAGE_UPLOAD_MULTIPLE_REQUEST_SUCCESS,
    payload
});

export const postImageUploadMultipleRequestFailure = (payload: PostImageUploadMultipleRequestFailurePayload): PostImageUploadMultipleRequestFailure => ({
    type: POST_IMAGE_UPLOAD_MULTIPLE_REQUEST_FAILURE,
    payload
});

export const putClinicalExamAssistantRequest = (payload: PutClinicalExamAssistantRequestPayload): PutClinicalExamAssistantRequest => ({
    type: PUT_CLINICAL_EXAM_ASSISTANT_REQUEST,
    payload
})
export const putClinicalExamAssistantRequestSuccess = (payload: PutClinicalExamAssistantRequestSuccessPayload): PutClinicalExamAssistantRequestSuccess => ({
    type: PUT_CLINICAL_EXAM_ASSISTANT_REQUEST_SUCCESS,
    payload
})
export const putClinicalExamAssistantRequestFailure = (payload: PutClinicalExamAssistantRequestFailurePayload): PutClinicalExamAssistantRequestFailure => ({
    type: PUT_CLINICAL_EXAM_ASSISTANT_REQUEST_FAILURE,
    payload
})