import {
  GET_INTRA_ORAL_DENTIST_RECALL_EXAM_REQUEST,
  GET_INTRA_ORAL_DENTIST_RECALL_EXAM_REQUEST_SUCCESS,
  GET_INTRA_ORAL_DENTIST_RECALL_EXAM_REQUEST_FAILURE,

  PUT_INTRA_ORAL_DENTIST_RECALL_EXAM_REQUEST,
  PUT_INTRA_ORAL_DENTIST_RECALL_EXAM_REQUEST_SUCCESS,
  PUT_INTRA_ORAL_DENTIST_RECALL_EXAM_REQUEST_FAILURE,
} from "../../../../ActionTypes/v1/dentist/clinicalExamDentistRecallExam"
import {
  IntraOralExamDentistRecallFlowState,
  ClinicalExamDentistRecallExamActions
} from "../../../../types/dentistTypes"

const initialState: IntraOralExamDentistRecallFlowState = {
  put_intra_oral_dentist_recall_exam_loader: false,
  intra_oral_dentist_recall_exam_loader: false,
  intra_oral_dentist_recall_data: null,
  error: null
};

const intraOralDentistRecallExamReducer = (
  state = initialState,
  action: ClinicalExamDentistRecallExamActions
) => {
  switch (action.type) {

    case GET_INTRA_ORAL_DENTIST_RECALL_EXAM_REQUEST:
      return {
        ...state,
        intra_oral_dentist_recall_exam_loader: true,
      };
    case GET_INTRA_ORAL_DENTIST_RECALL_EXAM_REQUEST_SUCCESS:
      return {
        ...state,
        intra_oral_dentist_recall_exam_loader: false,
        intra_oral_dentist_recall_data: action.payload,
        error: null,
      };

    case GET_INTRA_ORAL_DENTIST_RECALL_EXAM_REQUEST_FAILURE:
      return {
        ...state,
        intra_oral_dentist_recall_exam_loader: false,
        intra_oral_dentist_recall_data: null,
        error: action.payload.error,
      };

    case PUT_INTRA_ORAL_DENTIST_RECALL_EXAM_REQUEST:
      return {
        ...state,
        intra_oral_dentist_recall_exam_loader: true,
      } 
    case PUT_INTRA_ORAL_DENTIST_RECALL_EXAM_REQUEST_SUCCESS:
      return {
        ...state,
        intra_oral_dentist_recall_exam_loader: false,
        intra_oral_dentist_recall_data: action.payload,
        error: null,
      }  
    case PUT_INTRA_ORAL_DENTIST_RECALL_EXAM_REQUEST_FAILURE:
        return {
          ...state,
          intra_oral_dentist_recall_exam_loader: true,
          intra_oral_dentist_recall_data: null,
          error: action.payload.error,
        };

    default:
      return {
        ...state,
      };
  }
};
export default intraOralDentistRecallExamReducer;