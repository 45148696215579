import {
    UPDATE_INTRA_ORAL_DENTIST_RECALL_EXAM,UPDATE_INTRA_ORAL_DENTIST_RECALL_EXAM_FAILURE,
    CHANGE_INTRA_ORAL_DENTIST_RECALL_EXAM
} from "../../../../ActionTypes/v1/dentist/clinicalExamDentist";
import { intraOralDentistRecallExamInterface } from "../../../../types/dentistTypes";

export const updateIntraOralDentistRecallExamRequest = (
    payload: intraOralDentistRecallExamInterface
): intraOralDentistRecallExamInterface => ({
    type: UPDATE_INTRA_ORAL_DENTIST_RECALL_EXAM,
    payload,
}) as any;

export const updateIntraOralDentistRecallExamRequestFailure = (): any => ({
    type: UPDATE_INTRA_ORAL_DENTIST_RECALL_EXAM_FAILURE,
}) as any;

export const changeIntraOralFindingDentistRecallExamAction = (payload: any): any => ({
    type: CHANGE_INTRA_ORAL_DENTIST_RECALL_EXAM,
    payload
}) as any;