import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/system';
import React, { useMemo, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectPhotosAction } from '../../../../../../app/actions/v2/dentist/clinicalExam/exam/uploadPhotosXrayAction';
import theme from '../../../../../../theme';

const useStyles = makeStyles((theme: any) => ({
  selectedTabClass: {
    border: `2px solid ${theme.palette.v2.primary.main}`,
    background: theme.palette.v2.primary.primary25
  },
  defaultClass: {
    borderBottom: `1px solid ${theme.palette.grey['Gray80']}`
  }

}));

type Props = {
  data: any
}
const PhotoXrayNameList = (props: Props) => {
  const { data } = props
  const classes = useStyles()
  const dispatch = useDispatch()
  const scrollRef = useRef(null) as any
  const { uploadPhotoXray: { selectPhotosData, photosXrayData, addAdditionalImageXray } } = useSelector((state: any) => state);

  const getUploadedImages = useMemo(() => {
    let uploaded = [] as any;
    photosXrayData && photosXrayData?.map((res: any) =>
      uploaded?.push(res?.image_name)
    );
    return uploaded;
  }, [photosXrayData]);

  const selectImageName = (selectedImage: any) => {
    dispatch(selectPhotosAction(selectedImage))
  }

  const getName = useMemo(() => {
    if (data?.ID) {
      return data?.image_name;
    } else {
      return data?.title;
    }
  }, [data]);

  const selectDataName = useMemo(() => {
    if (selectPhotosData?.ID) {
      return selectPhotosData?.image_name;
    } else {
      return selectPhotosData?.title;
    }
  }, [selectPhotosData]);

  useEffect(() => {
    if (getName === selectDataName && scrollRef?.current) {
      scrollRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [getName, selectDataName]);


  return (
    <Box p={'12px 20px'} ref={scrollRef}
      sx={{ opacity: addAdditionalImageXray ? 0.6 : 1, }}
      className={`${getName === selectDataName ? classes.selectedTabClass : classes.defaultClass} cursor-pointer d-flex justify-content-between`} onClick={() => addAdditionalImageXray ? '' : selectImageName(data)}>
      <Typography color={theme.palette.text.darkGrey} className='f-14 f-w-400 fw-regular'>{getName}</Typography>
      {getUploadedImages?.includes(getName) && <FontAwesomeIcon icon={faCheck} color={theme.palette.primary.main} />}
    </Box>)
};

export default PhotoXrayNameList;
