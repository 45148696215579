import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import API from '../../../../utils/api';
import { errorToaster, newDateFormate, successToaster } from '../../../../utils/helper';
import { photo } from '../../../../utils/v2/clinicalExamConstant';
import {
  appointmentDetailsRequestFailureAction,
  appointmentDetailsRequestSuccessAction,
  changeLoginUserPermissionRequestFailureAction,
  changeLoginUserPermissionRequestSuccessAction,
  dashboardMemberCacheDataClearRequest,
  dashboardMemberRequestFailure,
  dashboardMemberRequestSuccess,
  dentistNewAppointmentRequestFailure,
  dentistNewAppointmentRequestSuccess,
  updateAppointmentStatusRequestFailure,
  updateAppointmentStatusRequestSuccess
} from '../../../actions/v2/dentist/appointmentsAction';
import {
  saveFindingTypeStepAction,
  updateFindingTypeAction,
  updateOpenCompleteStepAction
} from '../../../actions/v2/dentist/clinicalExam/clinicalExamFilterAction';
import {
  APPOINTMENT_DETAILS_REQUEST,
  CHANGE_LOGIN_USER_PERMISSION_REQUEST,
  DASHBOARD_MEMBERS_REQUEST,
  DENTIST_NEW_APPOINTMENT_REQUEST,
  UPDATE_APPOINTMENT_STATUS_REQUEST
} from '../../../ActionTypes/v2/dentist/appointment';
import { RootState } from '../../../reducers/v2/rootReducer';

// get appointments all staff
function* dentistNewAppointmentRequestSaga(payload: any): any {
  const {
    appointmentsReducer: {
      startDate,
      endDate,
      desc,
      sortBy,
      searchData,
      exam,
      role,
      status,
      teamMember,
    }
  } = yield select((state: RootState) => state) as any

  try {
    const params = [
      { key: 'search', value: searchData },
      { key: 'page', value: payload.payload.page },
      { key: 'size', value: payload.payload.size },
      { key: 'clinic_id', value: payload.payload.clinic_id },
      { key: 'from_date', value: newDateFormate(startDate) },
      { key: 'to_date', value: newDateFormate(endDate) },
      { key: 'role', value: role },
      { key: 'staff_id', value: teamMember },
      { key: 'status', value: status?.toString() },
      { key: 'appointment_type', value: exam },
      { key: 'sort_by', value: sortBy },
      { key: 'desc', value: desc },
    ]
    let tmpUrl = `?`
    params?.map((p: any) => {
      if (tmpUrl.length > 1) {
        if (typeof p.value === 'boolean' ? p?.value !== null : p?.value) {
          tmpUrl = `${tmpUrl}&${p.key}=${p.value}`
        }
      } else {
        if (typeof p.value === 'boolean' ? p?.value !== null : p?.value) {
          tmpUrl = `${tmpUrl}${p.key}=${p.value}`
        }
      }
      return true
    })

    const response = yield API.get(`v2/appointments/all/staff${tmpUrl}`)
    if (payload?.payload?.callback) {
      yield call(payload?.payload?.callback, response?.data)
    }
    yield put(
      dentistNewAppointmentRequestSuccess({
        newAppointment_data: response.data?.detail,
        newAppointment_meta: response.data?.meta
      })
    )
  } catch (e: any) {
    errorToaster(e?.message)
    yield put(
      dentistNewAppointmentRequestFailure({
        error: e?.message,
      })
    )
  }
}

// dashboard members
function* dashboardMembersRequestSaga(payload: any): any {
  yield put(dashboardMemberCacheDataClearRequest())
  try {
    const params = [
      { key: 'clinic_id', value: payload.payload.clinic_id },
    ]
    let tmpUrl = `?`
    params?.map((p: any) => {
      if (tmpUrl.length > 1) {
        if (p.value) {
          tmpUrl = `${tmpUrl}&${p.key}=${p.value}`
        }
      } else {
        if (p.value) {
          tmpUrl = `${tmpUrl}${p.key}=${p.value}`
        }
      }
      return true
    })

    const response = yield API.get(`v2/dashboard/members${tmpUrl}`)
    if (payload?.payload?.callback) {
      yield call(payload?.payload?.callback, response?.data)
    }
    yield put(
      dashboardMemberRequestSuccess({
        members_data: response.data?.detail,
        members_meta: response.data?.meta
      })
    )
  } catch (e: any) {
    errorToaster(e?.message)
    yield put(
      dashboardMemberRequestFailure({
        member_error: e?.message,
      })
    )
  }
}

//appointment details
function* appointmentDetailsRequestSaga(payload: any): any {
  const { clinicalExamFilters: { saveFindingTypeStep } } = yield select((state: RootState) => state) as any

  try {
    const response = yield API.get(`/v2/appointments/${payload.payload.appointment_id}`);
    yield put(updateOpenCompleteStepAction(false))
    yield put(
      appointmentDetailsRequestSuccessAction({
        appointmentDetails: response?.data?.detail
      })
    );
    if (payload?.payload?.callback) {
      yield call(payload?.payload?.callback, response?.data?.detail)
    }
    const newStep = saveFindingTypeStep === null ? photo : saveFindingTypeStep

    yield put(updateFindingTypeAction(newStep))
    if (!payload.payload.fromPreIntake) {
      yield put(saveFindingTypeStepAction(null))
    }

  } catch (e: any) {
    yield put(
      appointmentDetailsRequestFailureAction({
        appointmentDetailsError: e?.detail
      })
    );
  }
}

// role base update
function* changeLoginUserPermissionRequestSaga(payload: any): any {
  try {
    const payloadData = {
      staff_id: payload.payload?.staff_id,
      clinic_id: payload.payload?.clinic_id,
      position: payload.payload?.position
    }

    const response = yield API.put(`v2/appointments/${payload.payload.appointment_id}/staff/replace`, payloadData);

    if (payload?.payload?.callback) {
      yield call(payload?.payload?.callback, response?.data)
    }
    yield put(
      changeLoginUserPermissionRequestSuccessAction(response?.data?.detail)
    )
  } catch (e: any) {
    errorToaster(e?.message)
    yield put(
      changeLoginUserPermissionRequestFailureAction(e?.message)
    )
  }
}

// update appointment status
function* updateAppointmentStatusRequestSaga(payload: any): any {
  try {
    let payloadData
    if (payload.payload.subStatus === null) {
      payloadData = {
        status: payload.payload.status,
      }
    } else {
      payloadData = {
        status: payload.payload.status,
        sub_status: payload.payload.subStatus,
      }
    }
    const response = yield API.put(`/v2/appointments/${payload.payload.appointment_id}/status`, payloadData)

    if (payload?.payload?.callback) {
      yield call(payload?.payload?.callback, response?.data)
    }

    successToaster(response?.data?.meta?.message)
    yield put(
      updateAppointmentStatusRequestSuccess({
        updateAppointmentStatusData: response?.data,
      })
    )
  } catch (e: any) {
    errorToaster(e?.message)
    yield put(
      updateAppointmentStatusRequestFailure({
        updateAppointmentStatusError: e?.message,
      })
    )
  }
}

function* dentistNewAppointmentSaga() {
  yield takeEvery(DENTIST_NEW_APPOINTMENT_REQUEST, dentistNewAppointmentRequestSaga)
}

function* dashboardMembersSaga() {
  yield takeEvery(DASHBOARD_MEMBERS_REQUEST, dashboardMembersRequestSaga)
}

function* appointmentDetailsSaga() {
  yield takeEvery(APPOINTMENT_DETAILS_REQUEST, appointmentDetailsRequestSaga)
}

function* changeLoginUserPermissionSaga() {
  yield takeEvery(CHANGE_LOGIN_USER_PERMISSION_REQUEST, changeLoginUserPermissionRequestSaga)
}

function* updateAppointmentStatusSaga() {
  yield takeEvery(UPDATE_APPOINTMENT_STATUS_REQUEST, updateAppointmentStatusRequestSaga)
}

export default function* appointmentSaga() {
  yield all([
    dentistNewAppointmentSaga(),
    dashboardMembersSaga(),
    appointmentDetailsSaga(),
    changeLoginUserPermissionSaga(),
    updateAppointmentStatusSaga()
  ])
}
