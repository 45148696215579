import {
    PATIENT_REGISTER_FORM_DATA,
    PATIENT_REGISTER_FORM_ERROR,
    SAVE_PATIENT_REGISTER_REQUEST,
    SAVE_PATIENT_REGISTER_SUCCESS,
    SAVE_PATIENT_REGISTER_FAIL,
    VERIFY_PATIENT_USERNAME_REQUEST,
    VERIFY_PATIENT_USERNAME_SUCCESS,
    VERIFY_PATIENT_USERNAME_FAIL,
    GET_PATIENT_DATA_REQUEST,
    GET_PATIENT_DATA_SUCCESS,
    GET_PATIENT_DATA_FAIL
} from "../../../ActionTypes/v2/patient/patientRegister";
import {
    PatientRegisterActions,
    PatientRegisterInterface
} from "../../../types/v2/patient/patientRegisterActionTypes";


const initialState: PatientRegisterInterface = {
    patientRegisterData: {
        userName: '',
        email: '',
        mobile: '',
        password: '',
        confirmPassword: '',
    },
    patientRegisterError: null,

    getPatientDataLoader: false,
    getPatientData: null,
    getPatientDataError: null,

    savePatientRegisterLoader: false,
    savePatientRegisterData: null,
    savePatientRegisterError: null,

    verifyPatientUsernameLoader: false,
    VerifyPatientUsernameData: null,
    VerifyPatientUsernameError: null,
};
const patientRegisterReducer = (state = initialState, action: PatientRegisterActions) => {
    switch (action.type) {
        //patient register form
        case PATIENT_REGISTER_FORM_DATA:
            return {
                ...state,
                patientRegisterData: action.payload
            };

        case PATIENT_REGISTER_FORM_ERROR:
            return {
                ...state,
                patientRegisterError: action.payload
            };

        //register patient
        case SAVE_PATIENT_REGISTER_REQUEST:
            return {
                ...state,
                putHealthHistoryWithoutAccountLoader: true
            };

        case SAVE_PATIENT_REGISTER_SUCCESS:
            return {
                ...state,
                putHealthHistoryWithoutAccountLoader: false,
                putHealthHistoryWithoutAccountData: action?.payload,
                putHealthHistoryWithoutAccountError: null
            };
        case SAVE_PATIENT_REGISTER_FAIL:
            return {
                ...state,
                putHealthHistoryWithoutAccountLoader: false,
                putHealthHistoryWithoutAccountData: null,
                putHealthHistoryWithoutAccountError: action?.payload
            };

        //verify patient username
        case VERIFY_PATIENT_USERNAME_REQUEST:
            return {
                ...state,
                verifyPatientUsernameLoader: true
            };

        case VERIFY_PATIENT_USERNAME_SUCCESS:
            return {
                ...state,
                verifyPatientUsernameLoader: false,
                VerifyPatientUsernameData: action?.payload,
                VerifyPatientUsernameError: null
            };
        case VERIFY_PATIENT_USERNAME_FAIL:
            return {
                ...state,
                verifyPatientUsernameLoader: false,
                VerifyPatientUsernameData: null,
                VerifyPatientUsernameError: action?.payload
            };

        //get patient data
        case GET_PATIENT_DATA_REQUEST:
            return {
                ...state,
                getPatientDataLoader: true
            };

        case GET_PATIENT_DATA_SUCCESS:
            return {
                ...state,
                getPatientDataLoader: false,
                getPatientData: action?.payload,
                getPatientDataError: null
            };
        case GET_PATIENT_DATA_FAIL:
            return {
                ...state,
                getPatientDataLoader: false,
                getPatientData: null,
                getPatientDataError: action?.payload
            };

        default:
            return {
                ...state
            };
    }
};
export default patientRegisterReducer;