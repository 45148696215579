export const UPDATE_PATIENT_CONSENT = 'UPDATE_PATIENT_CONSENT';
export const PATIENT_REVIEW_TREATMENT_DATA_CHANGE_REQUEST = 'PATIENT_REVIEW_TREATMENT_DATA_CHANGE_REQUEST';

export const IS_PATIENT_REVIEW_SUBMIT = 'IS_PATIENT_REVIEW_SUBMIT';

export const GET_PATIENT_REVIEW_REQUEST = 'GET_PATIENT_REVIEW_REQUEST';
export const GET_PATIENT_REVIEW_REQUEST_SUCCESS = 'GET_PATIENT_REVIEW_REQUEST_SUCCESS';
export const GET_PATIENT_REVIEW_REQUEST_FAILURE = 'GET_PATIENT_REVIEW_REQUEST_FAILURE';

export const PUT_PATIENT_REVIEW_TREATMENT_REQUEST = 'PUT_PATIENT_REVIEW_TREATMENT_REQUEST';
export const PUT_PATIENT_REVIEW_TREATMENT_SUCCESS = 'PUT_PATIENT_REVIEW_TREATMENT_SUCCESS';
export const PUT_PATIENT_REVIEW_TREATMENT_FAILURE = 'PUT_PATIENT_REVIEW_TREATMENT_FAILURE';

export const UPDATE_PATIENT_REVIEW_TREATMENT_VALIDATION = 'UPDATE_PATIENT_REVIEW_TREATMENT_VALIDATION';
export const UPDATE_PATIENT_REVIEW_READINESS_BAR_VALIDATION = 'UPDATE_PATIENT_REVIEW_READINESS_BAR_VALIDATION';

export const PDF_DOWNLOAD_REQUEST = 'PDF_DOWNLOAD_REQUEST';
export const PDF_DOWNLOAD_REQUEST_SUCCESS = 'PDF_DOWNLOAD_REQUEST_SUCCESS';
export const PDF_DOWNLOAD_REQUEST_FAILURE = 'PDF_DOWNLOAD_REQUEST_FAILURE';

export const OPEN_PROCEDURE_TOOLTIP = 'OPEN_PROCEDURE_TOOLTIP';
export const SHOW_PROCEDURE_CODES = 'SHOW_PROCEDURE_CODES';
export const SHOW_PROCEDURE_FEES = 'SHOW_PROCEDURE_FEES';

export const SEARCH_PROCEDURE_CODE_TREATMENT_REQUEST = 'SEARCH_PROCEDURE_CODE_TREATMENT_REQUEST';
export const SEARCH_PROCEDURE_CODE_TREATMENT_REQUEST_SUCCESS = 'SEARCH_PROCEDURE_CODE_TREATMENT_REQUEST_SUCCESS';
export const SEARCH_PROCEDURE_CODE_TREATMENT_REQUEST_FAILURE = 'SEARCH_PROCEDURE_CODE_TREATMENT_REQUEST_FAILURE';

export const SELECT_ALTERNATIVE_TREATMENT_DATA = 'SELECT_ALTERNATIVE_TREATMENT_DATA';
export const EDIT_TREATMENT_PLANNING = 'EDIT_TREATMENT_PLANNING';

export const SELECT_ALTERNATIVE_TREATMENT_INDEX = 'SELECT_ALTERNATIVE_TREATMENT_INDEX';
export const SET_ALTERNATIVE_ODONTOGRAM_DATA = 'SET_ALTERNATIVE_ODONTOGRAM_DATA';

export const DELETE_TREATMENT_DATA = 'DELETE_TREATMENT_DATA';
