import {
  GET_TC_REQUEST,
  GET_TC_REQUEST_FAILURE,
  GET_TC_REQUEST_SUCCESS,
  TERMS_CONDITION_V2_REQUEST,
  TERMS_CONDITION_V2_REQUEST_FAILURE,
  TERMS_CONDITION_V2_REQUEST_SUCCESS
} from '../../../ActionTypes/v2/auth';
import {
  termsConditionsActions,
  termsConditionsInterface
} from '../../../types/v2/authActionTypes/termsConditionActionTypes';

const initialState: termsConditionsInterface = {
  termsConditionsLoader: false,
  termsConditionsData: null,
  termsConditionsError: null,
  getTCLoader: false,
  getTCData: null,
  getTCError: null
};

const termsConditionReducer = (state = initialState, action: termsConditionsActions) => {
  switch (action.type) {
    case TERMS_CONDITION_V2_REQUEST:
      return {
        ...state,
        termsConditionsLoader: true
      };
    case TERMS_CONDITION_V2_REQUEST_SUCCESS:
      return {
        ...state,
        termsConditionsLoader: false,
        termsConditionsData: action.payload,
        termsConditionsError: null
      };
    case TERMS_CONDITION_V2_REQUEST_FAILURE:
      return {
        ...state,
        termsConditionsLoader: true
      };
    case GET_TC_REQUEST:
      return {
        ...state,
        getTCLoader: true
      };
    case GET_TC_REQUEST_SUCCESS:
      return {
        ...state,
        getTCLoader: false,
        getTCData: action.payload?.getTCData,
        getTCError: null
      };
    case GET_TC_REQUEST_FAILURE:
      return {
        ...state,
        getTCLoader: false,
        getTCError: action.payload.getTCError
      };
    default:
      return {
        ...state
      };
  }
};
export default termsConditionReducer;
