
import { Typography } from '@mui/material'
import React from 'react'
import openMarginIcon from '../../../../../../../assets/images/v2/odontogramFindings/openMarginIcon.svg'

interface Props {
  isOpenMargin: any
}
const OdontogramOpenMarginFinding = (props: Props) => {
  const { isOpenMargin } = props
  return (
    <React.Fragment>
      {isOpenMargin?.hasTooth &&
        <Typography className='pos-absolute' sx={{ top: 42 }} >
          <img src={openMarginIcon} alt='open margin' height={9} />
        </Typography>
      }
    </React.Fragment>
  )
}
export default OdontogramOpenMarginFinding