export const discData = [
  {
    key: 0,
    name: "I",
    value: 500,
    title: "Influence",
    priorities:
      "Expressing enthusiasm, taking action, encouraging collaboration",
    motivatedBy: "Social recognition, group activities, friendly relationships",
    fear: "Social rejection, disapproval, loss of influence, being ignored",
    notice: "Charm, enthusiasm, sociability, optimism, talkativeness",
    limitations: "Impulsiveness, disorganization, lack of follow-through",
    color: "#FF8042",
    lightColor: "#FFEAE0"
  },
  {
    key: 1,
    name: "D",
    value: 500,
    title: "Dominance",
    priorities:
      "Getting immediate results, taking action, challenging self and others",
    motivatedBy: "Power and authority, competition, winning, success",
    fear: "Loss of control, being taken advantage of, vulnerability",
    notice: "Self-confidence, directness, forcefulness, risk-taking",
    limitations: "Lack of concern for others, impatience, insecurity",
    color: "#00C49F",
    lightColor: '#C7FFF4'
  },

  {
    key: 2,
    name: "C",
    value: 500,
    title: "Conscientiousness",
    priorities:
      "Ensuring accuracy, maintaining stability, challenging assumptions",
    motivatedBy:
      "Opportunities to use expertise or gain knowledge, attention to quality",
    fear: "Criticism, slipshod methods, being wrong",
    notice: "Precision, analysis, skepticism, reserve, quiet",
    limitations: "Overly critical, tendency to overanalyze, isolates self",
    color: "#FFBB28",
    lightColor: "#FFF0D0"
  },
  {
    key: 3,
    name: "S",
    value: 500,
    title: "Steadiness",
    priorities: "Giving support, maintaining stability, enjoying collaboration",
    motivatedBy:
      "Stable environments, sincere appreciation, cooperation, opportunities to help",
    fear: "Loss of stability, change, loss of harmony, offending others",
    notice: "Patience, team player, calm approach, good listener, humility",
    limitations:
      "Overly accommodating, tendency to avoid change, indecisiveness",
    color: "#0088FE",
    lightColor: '#CFE8FF'
  },
];


