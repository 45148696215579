export const startExam = 'start_exam'
export const completeExam = 'complete_exam'
export const completeCheckout = 'complete_checkout'
export const viewExam = 'view_exam';
export const HeathHistoryPending = 'Health history pending'
export const HeathHistoryCompleted = 'Health history completed'
export const appointmentStatus = {
    Upcoming: 'upcoming',
    Ongoing: 'ongoing',
    Finished: 'finished',
    Completed: 'completed',
    Cancelled: 'cancelled',
    NoShow: 'no_show',
};
export const appointmentSteps = [
    'Appointment Details', // gitHub issue : 4590 - 1
    'Exam',
    'Assessment',
    'Treatment Planning',
    'Patient Review',
    'Admin Handoff'
];
export const filters = [
    {
        label: 'Medical',
        key: 'medical',
        riskKey: 'medicalRisk'
    },
    {
        label: 'Sleep Disorder',
        key: 'sleep_disorder',
        riskKey: 'sleepRisk'
    },
    {
        label: 'Biomechanical',
        key: 'biomechanical',
        riskKey: 'biomechanicsRisk'
    },
    {
        label: 'Occlusion/TMJ/Orthodontic',
        key: 'occlusion_tmj_orthodontic',
        riskKey: 'occlusionTmjRisk'
    },
    {
        label: 'Caries',
        key: 'caries',
        riskKey: 'cariesRisk'
    },
    {
        label: 'Periodontal',
        key: 'periodontal',
        riskKey: 'periodontalRisk'
    },
    {
        label: 'Esthetics',
        key: 'esthetics',
        riskKey: 'estheticsRisk'
    }
];
export const findingTypesTab = [
    {
        title: 'Photos',
        value: 'photo',
        examType: ['NPE', 'RE', 'LE'],
        examStatus: ''
    },
    {
        title: 'X-rays',
        value: 'xray',
        examType: ['NPE', 'RE', 'LE'],
        examStatus: ''
    },
    {
        title: 'Soft Tissue',
        value: 'softTissue',
        examType: ['NPE', 'RE'],
        examStatus: ''
    },
    {
        title: 'Hard Tissue',
        value: 'hardTissue',
        examType: ['NPE', 'RE'],
        examStatus: ''
    }, {
        title: 'Odontogram',
        value: 'odontogram',
        examType: ['NPE', 'RE', 'LE'],
        examStatus: ''
    }, {
        title: 'Periogram',
        value: 'periogram',
        examType: ['NPE', 'RE', 'LE'],
        examStatus: ''
    },
    //  {
    //   title: 'Medical',
    //   value: 'medical',
    //   examType: ['NPE', 'RE'],
    //   examStatus: continuePreClinical
    // },
    {
        title: 'Limited Exam',
        value: 'limitedExam',
        examType: ['LE'],
        examStatus: ''
    }
]
export const getShortSurface = (surface: string) => {
    const surfaces = {
        Mesial: 'M',
        "Occlusal/Incisal": 'O',
        Buccal: 'B',
        Distal: 'D',
        Lingual: 'L',
    } as any
    return surfaces[surface]
}
export const xray = 'xray'
export const photo = 'photo'
export const softTissue = 'softTissue'
export const hardTissue = 'hardTissue'
export const odontogram = 'odontogram'
export const periogram = 'periogram'
export const medical = 'medical'
export const limitedExam = 'limitedExam'
export const disabledField = (appointmentDetails: any) => {
    return [completeCheckout, viewExam]?.includes(appointmentDetails?.sub_status)
}

export const getHeightForClinicalExam = (hasRiskFilters: any, openSpeedometerClinical: any, appointmentStep: any, openRecommendationLoadingScreen: any, findingType: any, isPhotosXray: any, showFooter: any) => {
    let heightCalculation;
    // Helper function for footer-related calculations
    const footerHeightCalculation = () => {
        if (hasRiskFilters()) {
            return openSpeedometerClinical ? '438px' : '286px';
        } else if (appointmentStep === 1) {
            return '214px';
        } else {
            return '220px';
        }
    };

    // Helper function for general calculations without the footer
    const generalHeightCalculation = () => {
        if (appointmentStep === 3 || (appointmentStep === 1 && (findingType !== photo && findingType !== xray))) {
            return openRecommendationLoadingScreen ? '132px' : (openSpeedometerClinical ? '356px' : '204px');
        } else if (appointmentStep === 1 && isPhotosXray) {
            return openSpeedometerClinical ? '356px' : '207px';
        } else if (appointmentStep === 2) {
            return '140px';
        } else if (appointmentStep === 0 || appointmentStep === 4 || appointmentStep === 5) {
            return openSpeedometerClinical ? '356px' : '207px';
        } else {
            return '357px';
        }
    };
    if (showFooter) {
        heightCalculation = `calc(100vh - ${footerHeightCalculation()}) !important`;
    } else {
        heightCalculation = `calc(100vh - ${generalHeightCalculation()})`;
    }
    return heightCalculation;
};
