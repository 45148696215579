import {
    UPDATE_PERIODONTAL_RECALL_EXAM,UPDATE_PERIODONTAL_RECALL_EXAM_FAILURE,
    ADD_PERIODONTAL_FINDING_ROW_RECALL_EXAM,
    CHANGE_PERIODONTAL_FINDING_RECALL_EXAM,
    DELETE_PERIODONTAL_FINDING_ROW_RECALL_EXAM
} from "../../../../ActionTypes/v1/dentist/clinicalExamDentist";
import { PeriodontalRecallExamInterface } from "../../../../types/dentistTypes";

export const updatePeriodontalRecallExamRequest = (payload: PeriodontalRecallExamInterface): PeriodontalRecallExamInterface => ({
    type: UPDATE_PERIODONTAL_RECALL_EXAM,
    payload
}) as any;
export const updatePeriodontalRecallExamRequestFailure = (): any => ({
    type: UPDATE_PERIODONTAL_RECALL_EXAM_FAILURE,
}) as any;
export const addPeriodontalFindingRowRecallExamAction = (payload: string): any => ({
    type: ADD_PERIODONTAL_FINDING_ROW_RECALL_EXAM,
    payload
}) as any;

export const changePeriodontalFindingRecallExamAction = (payload: any): any => ({
    type: CHANGE_PERIODONTAL_FINDING_RECALL_EXAM,
    payload
}) as any;

export const deletePeriodontalFindingRowRecallExamAction = (payload: any): any => ({
    type: DELETE_PERIODONTAL_FINDING_ROW_RECALL_EXAM,
    payload
}) as any;