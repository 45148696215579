import {
  CHANGE_REVIEW_TREATMENT_PLAN,
  GET_REVIEW_TREATMENT_PLAN_REQUEST,
  GET_REVIEW_TREATMENT_PLAN_CACHE_DATA_CLEAR_REQUEST,
  GET_REVIEW_TREATMENT_PLAN_REQUEST_SUCCESS,
  GET_REVIEW_TREATMENT_PLAN_REQUEST_FAILURE,
  PUT_REVIEW_TREATMENT_PLAN_REQUEST,
  PUT_REVIEW_TREATMENT_PLAN_REQUEST_SUCCESS,
  PUT_REVIEW_TREATMENT_PLAN_REQUEST_FAILURE,
  UPDATE_REVIEW_TREATMENT_PLAN,
} from "../../../../../ActionTypes/v1/dentist/reviewTreatmentPlan";
import {
  PutReviewTreatmentPlanRequest,
  PutReviewTreatmentPlanRequestSuccess,
  PutReviewTreatmentPlanRequestFailure,
  GetReviewTreatmentPlanRequest,
  GetReviewTreatmentPlanRequestSuccess,
  GetReviewTreatmentPlanRequestFailure,
  ChangeReviewTreatmentPlanRequest,
  ChangeReviewTreatmentPlanRequestPayload,
  PutReviewTreatmentPlanRequestPayload,
  PutReviewTreatmentPlanSuccessPayload,
  PutReviewTreatmentPlanFailurePayload,
  GetReviewTreatmentPlanRequestPayload,
  GetReviewTreatmentPlanSuccessPayload,
  GetReviewTreatmentPlanFailurePayload,
  UpdateReviewTreatmentPlanRequest,
  UpdateReviewTreatmentPlanRequestPayload,
} from "../../../../../types/dentistTypes";

export const updateReviewTreatmentPlan = (
  payload: UpdateReviewTreatmentPlanRequestPayload
): UpdateReviewTreatmentPlanRequest => ({
  type: UPDATE_REVIEW_TREATMENT_PLAN,
  payload,
});

export const changeReviewTreatmentPlan = (
  payload: ChangeReviewTreatmentPlanRequestPayload
): ChangeReviewTreatmentPlanRequest => ({
  type: CHANGE_REVIEW_TREATMENT_PLAN,
  payload,
});

export const putReviewTreatmentPlanRequest = (
  payload: PutReviewTreatmentPlanRequestPayload
): PutReviewTreatmentPlanRequest => ({
  type: PUT_REVIEW_TREATMENT_PLAN_REQUEST,
  payload,
});

export const putReviewTreatmentPlanSuccess = (
  payload: PutReviewTreatmentPlanSuccessPayload
): PutReviewTreatmentPlanRequestSuccess => ({
  type: PUT_REVIEW_TREATMENT_PLAN_REQUEST_SUCCESS,
  payload,
});
export const putReviewTreatmentPlanFailure = (
  payload: PutReviewTreatmentPlanFailurePayload
): PutReviewTreatmentPlanRequestFailure => ({
  type: PUT_REVIEW_TREATMENT_PLAN_REQUEST_FAILURE,
  payload,
});

export const getReviewTreatmentPlanRequest = (
  payload: GetReviewTreatmentPlanRequestPayload
): GetReviewTreatmentPlanRequest => ({
  type: GET_REVIEW_TREATMENT_PLAN_REQUEST,
  payload,
});

export const getReviewTreatmentCacheDataClearRequest = ():any => ({
  type: GET_REVIEW_TREATMENT_PLAN_CACHE_DATA_CLEAR_REQUEST
})

export const getReviewTreatmentPlanSuccess = (
  payload: GetReviewTreatmentPlanSuccessPayload
): GetReviewTreatmentPlanRequestSuccess => ({
  type: GET_REVIEW_TREATMENT_PLAN_REQUEST_SUCCESS,
  payload,
});
export const getReviewTreatmentPlanFailure = (
  payload: GetReviewTreatmentPlanFailurePayload
): GetReviewTreatmentPlanRequestFailure => ({
  type: GET_REVIEW_TREATMENT_PLAN_REQUEST_FAILURE,
  payload,
});
