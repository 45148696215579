import {
    GET_SUMMARIZATION_DATA_REQUEST,
    GET_SUMMARIZATION_DATA_REQUEST_SUCCESS,
    GET_SUMMARIZATION_DATA_REQUEST_FAILURE,
    UPDATE_SUMMARIZATION_DATA_REQUEST,
    UPDATE_SUMMARIZATION_DATA_REQUEST_SUCCESS,
    UPDATE_SUMMARIZATION_DATA_REQUEST_FAILURE,
    UPDATE_SUMMARIZATION_DATA_LOCALLY,
} from '../../../ActionTypes/v2/dentist/qSummarization';
import {
    GetSummarizationFailure,
    GetSummarizationFailurePayload,
    GetSummarizationRequest,
    GetSummarizationRequestPayload,
    GetSummarizationSuccess,
    GetSummarizationSuccessPayload,
    UpdateSummarizationFailure,
    UpdateSummarizationFailurePayload,
    UpdateSummarizationRequest,
    UpdateSummarizationRequestPayload,
    UpdateSummarizationSuccess,
    UpdateSummarizationSuccessPayload,
    UpdateSummaryDataLocallyPayload,
    UpdateSummaryDataLocallyRequest
} from '../../../types/v2/dentist/qSummarization';

export const updateSummarizationDataLocally = (payload: UpdateSummaryDataLocallyPayload): UpdateSummaryDataLocallyRequest => ({
    type: UPDATE_SUMMARIZATION_DATA_LOCALLY,
    payload
} as any);

export const getSummarizationRequest = (payload: GetSummarizationRequestPayload): GetSummarizationRequest =>
({
    type: GET_SUMMARIZATION_DATA_REQUEST,
    payload
} as any);

export const getSummarizationSuccess = (payload: GetSummarizationSuccessPayload): GetSummarizationSuccess => {
    return {
        type: GET_SUMMARIZATION_DATA_REQUEST_SUCCESS,
        payload
    };
};

export const getSummarizationFailure = (payload: GetSummarizationFailurePayload): GetSummarizationFailure =>
({
    type: GET_SUMMARIZATION_DATA_REQUEST_FAILURE,
    payload
} as any);

export const updateSummarizationRequest = (payload: UpdateSummarizationRequestPayload): UpdateSummarizationRequest =>
({
    type: UPDATE_SUMMARIZATION_DATA_REQUEST,
    payload
} as any);

export const updateSummarizationSuccess = (payload: UpdateSummarizationSuccessPayload): UpdateSummarizationSuccess => {
    return {
        type: UPDATE_SUMMARIZATION_DATA_REQUEST_SUCCESS,
        payload
    };
};

export const updateSummarizationFailure = (payload: UpdateSummarizationFailurePayload): UpdateSummarizationFailure =>
({
    type: UPDATE_SUMMARIZATION_DATA_REQUEST_FAILURE,
    payload
} as any);