
import { call, put, takeEvery, all } from "redux-saga/effects";
import API from "../../../../../../utils/api";
import {
  putReviewTreatmentPlanSuccess,
  putReviewTreatmentPlanFailure,
  getReviewTreatmentPlanSuccess,
  getReviewTreatmentPlanFailure,
  getReviewTreatmentCacheDataClearRequest,
} from "../../../../../actions/v1/dentist/assignedTreatment/reviewTreatmentPlanActions/reviewTreatmentPlanActions";
import {
  GET_REVIEW_TREATMENT_PLAN_REQUEST,
  PUT_REVIEW_TREATMENT_PLAN_REQUEST,
} from "../../../../../ActionTypes/v1/dentist/reviewTreatmentPlan";


function* getReviewTreatmentPlanRequestSaga(payload: any): any {
  yield put(getReviewTreatmentCacheDataClearRequest())
  try {
    const response = yield API.get(
      `/exam/dentist/treatment/plan?appointment_id=${payload?.payload?.appointmentId}`
    );
    if (payload?.payload?.callback) {
      payload?.payload?.callback(response?.data);
    }
    yield put(
      getReviewTreatmentPlanSuccess({
        treatmentData: response?.data,
      })
    );
  } catch (e: any) {
    //TODO: update toast according to status code
    //   errorToaster(e?.detail);
    yield put(getReviewTreatmentCacheDataClearRequest())
    yield put(
      getReviewTreatmentPlanFailure({
        review_treatment_plan_error: e?.detail,
      })
    );
  }
}

function* putReviewTreatmentPlanRequestSaga(payload: any): any {
  try {
    const response = yield API.put(
      `/exam/dentist/treatment/create?appointment_id=${payload?.payload?.appointmentId}`,
      payload.payload
    );
    if (payload?.payload?.callback) {
      payload?.payload?.callback(response?.data)
      yield call(payload?.payload?.callback, response?.data);
    }
    yield put(
      putReviewTreatmentPlanSuccess({
        treatmentData: response?.data,
      })
    );
  } catch (e: any) {
    yield put(
      putReviewTreatmentPlanFailure({
        review_treatment_plan_error: e?.detail,
      })
    );
  }
}

function* getReviewTreatmentPlanSaga() {
  yield takeEvery(
    GET_REVIEW_TREATMENT_PLAN_REQUEST,
    getReviewTreatmentPlanRequestSaga
  );
}
function* putReviewTreatmentPlanSaga() {
  yield takeEvery(
    PUT_REVIEW_TREATMENT_PLAN_REQUEST,
    putReviewTreatmentPlanRequestSaga
  );
}
export default function* rootSaga() {
  yield all([getReviewTreatmentPlanSaga(), putReviewTreatmentPlanSaga()]);
}
