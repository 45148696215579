import {
    FORGOT_USERNAME_V2_REQUEST,
    FORGOT_USERNAME_V2_REQUEST_SUCCESS,
    FORGOT_USERNAME_V2_REQUEST_FAILURE,
    MULTIPLE_NUMBER_POPUP_REQUEST
} from '../../../ActionTypes/v2/patient/auth';

import {
    ForgotUserNameRequest,
    ForgotUserNameRequestFailure,
    ForgotUserNameRequestFailurePayload,
    ForgotUserNameRequestPayload,
    ForgotUserNameRequestSuccess,
    ForgotUserNameRequestSuccessPayload,
    MultipleNumberPopUpRequest
} from '../../../types/v2/patient/auth/authActionType';

export const forgotUserNameRequest = (payload: ForgotUserNameRequestPayload): ForgotUserNameRequest => ({
    type: FORGOT_USERNAME_V2_REQUEST,
    payload
});

export const forgotUserNameRequestSuccess = (payload: ForgotUserNameRequestSuccessPayload): ForgotUserNameRequestSuccess => ({
    type: FORGOT_USERNAME_V2_REQUEST_SUCCESS,
    payload
});

export const forgotUserNameRequestFailure = (payload: ForgotUserNameRequestFailurePayload): ForgotUserNameRequestFailure => ({
    type: FORGOT_USERNAME_V2_REQUEST_FAILURE,
    payload
});

export const multipleNumberPopUp = (payload: any): MultipleNumberPopUpRequest => ({
    type: MULTIPLE_NUMBER_POPUP_REQUEST,
    payload
});
