import {
  ADD_ALTERNATIVE_TREATMENT, ADD_TREATMENT_PLAN,
  GET_TREATMENT_PLAN_REQUEST, GET_TREATMENT_PLAN_CACHE_DATA_CLEAR_REQUEST,
  GET_TREATMENT_PLAN_REQUEST_FAILURE,
  GET_TREATMENT_PLAN_REQUEST_SUCCESS,
  HANDLE_TREATMENT_PLAN_VALUE_CHANGE,
  PUT_TREATMENT_PLAN_REQUEST,
  PUT_TREATMENT_PLAN_REQUEST_FAILURE,
  PUT_TREATMENT_PLAN_REQUEST_SUCCESS,
  REMOVE_ALTERNATIVE_TREATMENT,
  REMOVE_TREATMENT_PLAN,
  UPDATE_TREATMENT_PLAN,
  UPDATE_TREATMENT_PLAN_CACHE_DATA_CLEAR,
  DELETE_TREATMENT_PLAN,
  DELETE_TREATMENT_PLAN_FAILURE,
  DELETE_TREATMENT_PLAN_SUCCESS
} from "../../../../../ActionTypes/v1/dentist/treatmentPlan";
import { ChangeTreatmentPlanInterface, treatmentPlanActions, } from "../../../../../types/dentistTypes";

const initialState: ChangeTreatmentPlanInterface = {
  treatmentPlan: null,
  treatmentPlanData: null,
  treatmentPlanLoader: false,
  treatmentPlanError: null,
  deleteTreatment: null
};

const treatmentPlanReducer = (
  state = initialState,
  action: treatmentPlanActions
) => {
  switch (action.type) {
    case GET_TREATMENT_PLAN_REQUEST:
      return {
        ...state,
        treatmentPlanLoader: true,
      };
    case GET_TREATMENT_PLAN_CACHE_DATA_CLEAR_REQUEST:
      return {
        ...state,
        treatmentPlanData: null,
        treatmentPlan: null
      }
    case GET_TREATMENT_PLAN_REQUEST_SUCCESS:
      return {
        ...state,
        treatmentPlanLoader: false,
        treatmentPlanData: action.payload,
        treatmentPlanError: null,
      };
    case GET_TREATMENT_PLAN_REQUEST_FAILURE:
      return {
        ...state,
        treatmentPlanLoader: false,
        treatmentPlanData: null,
        treatmentPlanError: action.payload,
      };
    case PUT_TREATMENT_PLAN_REQUEST:
      return {
        ...state,
        treatmentPlanLoader: true,
        treatmentData: action.payload?.treatmentData?.map((res: any) => {
          res.priority = res.urgent ? "Urgent" : res.elective ? "Elective" : ""
          return res
        }),
      };
    case PUT_TREATMENT_PLAN_REQUEST_SUCCESS:
      return {
        ...state,
        treatmentPlanLoader: false,
        treatmentPlan: action.payload?.treatmentPlan,
        treatmentPlanError: null,
      };
    case PUT_TREATMENT_PLAN_REQUEST_FAILURE:
      return {
        ...state,
        treatmentPlanLoader: false,
        treatmentPlan: null,
        treatmentPlanError: action.payload,
      };
    case UPDATE_TREATMENT_PLAN:

      return {
        ...state,
        treatmentPlan: action.payload?.treatmentPlan?.map((res: any, i: number) => {

          res.urgent = res.priority === "Urgent"
          res.elective = res.priority === "Elective"
          res.position = i
          return res
        }),
      };
    case UPDATE_TREATMENT_PLAN_CACHE_DATA_CLEAR:
      return {
        ...state,
        treatmentPlanData: null
      }
    case ADD_ALTERNATIVE_TREATMENT:
      const newTreatmentPlan = state.treatmentPlan
      newTreatmentPlan[action.payload.index].alternative_treatment = ""
      return {
        ...state,
        treatmentPlan: newTreatmentPlan
      }
    case REMOVE_ALTERNATIVE_TREATMENT:
      const tempTreatmentPlan = state.treatmentPlan
      tempTreatmentPlan[action.payload.index].alternative_treatment = null
      tempTreatmentPlan[action.payload.index].is_alternative_approved = null
      tempTreatmentPlan[action.payload.index].is_alternative_interested = null
      return {
        ...state,
        treatmentPlan: tempTreatmentPlan
      }
    case ADD_TREATMENT_PLAN:
      const existingTreatmentPlan = state?.treatmentPlan || []
      existingTreatmentPlan.push({
        treatment: "",
        urgent: true,
        elective: false,
        alternative_treatment: null,
        position: state?.treatmentPlan?.length,
      })
      return {
        ...state,
        treatmentPlan: existingTreatmentPlan
      }
    case REMOVE_TREATMENT_PLAN:
      const oldTreatmentPlan = state.treatmentPlan
      const newTreatmentPlanData = oldTreatmentPlan.filter((_: any, ind: number) => ind !== action.payload.index);
      return {
        ...state,
        treatmentPlan: newTreatmentPlanData
      }
    case HANDLE_TREATMENT_PLAN_VALUE_CHANGE:
      let changedTreatmentPlan = state.treatmentPlan
      if (action.payload.name === 'urgent' || action.payload.name === 'elective') {
        if (action.payload.value === 'true') {
          if (action.payload.name === 'urgent') {
            changedTreatmentPlan[action.payload.index].elective = false
          }
          if (action.payload.name === 'elective') {
            changedTreatmentPlan[action.payload.index].urgent = false
          }
        }
        changedTreatmentPlan[action.payload.index][action.payload.name] = action.payload.value === 'true'
      } else {
        changedTreatmentPlan[action.payload.index][action.payload.name] = action.payload.value
      }
      return {
        ...state,
        treatmentPlan: changedTreatmentPlan
      }
    case DELETE_TREATMENT_PLAN:
      return {
        ...state,

      }
    case DELETE_TREATMENT_PLAN_FAILURE:
      return {
        ...state,
        error: action.payload?.error

      }
    case DELETE_TREATMENT_PLAN_SUCCESS:
      return {
        ...state,

      }
    default:
      return {
        ...state,
      };
  }
};

export default treatmentPlanReducer;
