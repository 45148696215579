import { all, call, put, takeEvery } from "redux-saga/effects";
import API from "../../../../../utils/api";
import {
  getClinicalExamAssistantRequestFailure,
  getClinicalExamAssistantRequestSuccess,
  getDiscProfileRequestFailure,
  getDiscProfileRequestSuccess,
  postImageUploadMultipleRequestSuccess,
  postImageUploadMultipleRequestFailure,
  putClinicalExamAssistantRequestSuccess,
  putClinicalExamAssistantRequestFailure,
} from "../../../../actions/v1/dentist/clinicalExamAssistantActions/clinicalExamAssistantActions";
import {
  GET_CLINICAL_EXAM_ASSISTANT_REQUEST,
  GET_DISC_PROFILE_REQUEST,
  POST_IMAGE_UPLOAD_MULTIPLE_REQUEST,
  PUT_CLINICAL_EXAM_ASSISTANT_REQUEST,
} from "../../../../ActionTypes/v1/dentist/clinicalExamAssistant";
import { updateExistingTreatmentRequestFailure } from "../../../../actions/v1/dentist/clinicalExamAssistantActions/existingTreatmentAssistantActions";
import { updateExtraoralRequestFailure } from "../../../../actions/v1/dentist/clinicalExamAssistantActions/extraoralAssistantActions";
import { updateIntraOralRequestFailure } from "../../../../actions/v1/dentist/clinicalExamAssistantActions/intraoralAssistantActions";
import { updateOcclusionRequestFailure } from "../../../../actions/v1/dentist/clinicalExamAssistantActions/occlusionAssistantActions";
import { updatePreClinicalInfoRequestFailure } from "../../../../actions/v1/dentist/clinicalExamAssistantActions/preClinicalAssistantActions";
import { errorToaster, successToaster } from '../../../../../utils/helper';

function* getClinicalExamAssistantRequestSaga(payload: any): any {
  try {
    // yield put(updateAppointmentInfoRequestFailure())
    yield put(updateExistingTreatmentRequestFailure())
    yield put(updateExtraoralRequestFailure())
    yield put(updateIntraOralRequestFailure())
    yield put(updateOcclusionRequestFailure())
    yield put(updatePreClinicalInfoRequestFailure())
    const response = yield API.get(
      `/assistant/clinical/exam/${payload.payload.appointment_id}`
    );
    yield put(updateExistingTreatmentRequestFailure())
    yield put(updateExtraoralRequestFailure())
    yield put(updateIntraOralRequestFailure())
    yield put(updateOcclusionRequestFailure())
    yield put(updatePreClinicalInfoRequestFailure())
    yield call(payload?.payload?.callback, response?.data);
    yield put(
      getClinicalExamAssistantRequestSuccess({
        data: response?.data,
      })
    );
  } catch (e: any) {
    yield put(updateExistingTreatmentRequestFailure())
    yield put(updateExtraoralRequestFailure())
    yield put(updateIntraOralRequestFailure())
    yield put(updateOcclusionRequestFailure())
    yield put(updatePreClinicalInfoRequestFailure())
    // TODO: update toast according to status code
    // errorToaster(e?.detail);
    yield put(
      getClinicalExamAssistantRequestFailure({
        error: e?.detail,
      })
    );
  }
}

function* putClinicalExamAssistantRequestSaga(payload: any): any {
  try {
    const response = yield API.put(
      `/assistant/clinical/exam`,
      payload?.payload?.clinicalExamAssistant
    );
    yield call(payload?.payload?.callback, response?.data);
    yield put(
      putClinicalExamAssistantRequestSuccess({
        assistant_exam: response?.data,
      })
    );
    successToaster("Assistant exam has been successfully submitted.")
  } catch (e: any) {
    // TODO: update toast according to status code
    errorToaster(e?.detail)
    yield put(
      putClinicalExamAssistantRequestFailure({
        error: e?.detail,
      })
    );
  }
}

function* getDiscProfileRequestSaga(payload: any): any {
  try {
    const response = yield API.get(
      `/preIntakeForm/discProfile?patientID=${payload.payload.patient_id}`
    );
    yield call(payload.payload.callback, response.data);
    yield put(
      getDiscProfileRequestSuccess({
        data: response.data,
      })
    );
  } catch (e: any) {
    errorToaster(e?.detail)
    yield put(
      getDiscProfileRequestFailure({
        error: e?.detail,
      })
    );
  }
}

function* postImageUploadMultipleRequestSaga(payload: any): any {
  try {
    const response = yield API.post(
      `/exam/image/upload/multiple?appointment_id=${payload.payload.appointment_id}&type=${payload.payload.type}&image_name=${encodeURIComponent(payload.payload.image_name)}`,
      payload.payload.file
    );
    yield call(payload.payload.callback, response.data);
    yield put(
      postImageUploadMultipleRequestSuccess({
        data: response.data,
      })
    );
    successToaster(`${payload?.payload?.type === "image" ? "Image" : "X-ray"} uploaded successfully!`)
  } catch (e: any) {
    errorToaster(e?.detail)
    yield put(
      postImageUploadMultipleRequestFailure({
        error: e?.detail,
      })
    );
  }
}

function* getClinicalExamAssistantSaga() {
  yield takeEvery(
    GET_CLINICAL_EXAM_ASSISTANT_REQUEST,
    getClinicalExamAssistantRequestSaga
  );
}
function* putClinicalExamAssistantSaga() {
  yield takeEvery(
    PUT_CLINICAL_EXAM_ASSISTANT_REQUEST,
    putClinicalExamAssistantRequestSaga
  );
}

function* getDiscProfileSaga() {
  yield takeEvery(GET_DISC_PROFILE_REQUEST, getDiscProfileRequestSaga);
}

function* postImageUploadMultipleSaga() {
  yield takeEvery(
    POST_IMAGE_UPLOAD_MULTIPLE_REQUEST,
    postImageUploadMultipleRequestSaga
  );
}

export default function* clinicalExamAssistantSaga() {
  yield all([
    getClinicalExamAssistantSaga(),
    getDiscProfileSaga(),
    postImageUploadMultipleSaga(),
    putClinicalExamAssistantSaga(),
  ]);
}
