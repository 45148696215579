import React, { useEffect, useRef } from 'react';
import { useSelector } from "react-redux";
import { RootState } from "../../../../../app/reducers/v2/rootReducer";

const ScrollableTagWrapper = (props: any) => {
  const myDivRef = useRef(null) as any;
  const {
    annotation: { selectedMarker },
  } = useSelector((state: RootState) => state) as any
  const handleScrollIntoView = () => {
    if (myDivRef?.current) {
      myDivRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  useEffect(() => {
    if (selectedMarker && myDivRef && myDivRef?.current) {
      handleScrollIntoView()
    }
  }, [selectedMarker])

  return (
    <div ref={myDivRef}>
      {props.children}
    </div>
  );
}

export default ScrollableTagWrapper
