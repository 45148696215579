import { CHANGE_SIDEBAR_WIDTH } from '../../ActionTypes/v2/dentist/sidebar';
import { sidebarAction } from '../../types/v2/dentist/sidebarActionTypes';

const initialState: any = {
  width: 64
};

const sidebarReducer = (state = initialState, action: sidebarAction) => {
  switch (action.type) {
    case CHANGE_SIDEBAR_WIDTH:
      return {
        ...state,
        width: action.payload?.width  
      };

    default:
      return {
        ...state
      };
  }
};
export default sidebarReducer;
