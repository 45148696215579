// get staff profile - new
export const STAFF_NEW_PROFILE_REQUEST = 'STAFF_NEW_PROFILE_REQUEST';
export const STAFF_NEW_PROFILE_REQUEST_SUCCESS = 'STAFF_NEW_PROFILE_REQUEST_SUCCESS';
export const STAFF_NEW_PROFILE_REQUEST_FAILURE = 'STAFF_NEW_PROFILE_REQUEST_FAILURE';
export const STAFF_NEW_PROFILE_CACHE_DATA_CLEAR_REQUEST = 'STAFF_NEW_PROFILE_CACHE_DATA_CLEAR_REQUEST';

// Edit Staff Profile - new
export const STAFF_NEW_PROFILE_EDIT_REQUEST = 'STAFF_NEW_PROFILE_EDIT_REQUEST';
export const STAFF_NEW_PROFILE_EDIT_REQUEST_FAILURE = 'STAFF_NEW_PROFILE_EDIT_REQUEST_FAILURE';
export const STAFF_NEW_PROFILE_EDIT_REQUEST_SUCCESS = 'STAFF_NEW_PROFILE_EDIT_REQUEST_SUCCESS';
