import {
  UPDATE_PRE_CLINICAL_INFO,
  CHANGE_PRE_CLINICAL_INFO,UPDATE_PRE_CLINICAL_INFO_FAILURE
} from "../../../../ActionTypes/v1/dentist/clinicalExamAssistant";
import { PreClinicalAssistantInterface } from "../../../../types/dentistTypes";

const initialState: PreClinicalAssistantInterface = {
  data: {
    social_note: "",
    exam_consent: false,
    chief_complaint: "",
    pt_pref_notes: "",
    disc_profile_note: "",
  },
};

export const preClinicalInfoReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case UPDATE_PRE_CLINICAL_INFO:
      return {
        ...state,
        data: action.payload,
      };
    case UPDATE_PRE_CLINICAL_INFO_FAILURE:
      return {
        ...state,
        data: {
          social_note: "",
          exam_consent: false,
          chief_complaint: "",
          pt_pref_notes: "",
          disc_profile_note: "",
        },
      }  

    case CHANGE_PRE_CLINICAL_INFO:
      const temp = state.data;
      const { key, value } = action.payload as any;
      temp[key] = value;
      return {
        ...state,
        data: temp,
      };
    default:
      return { ...state };
  }
};
