import {
    UpdateOtpRequest,
    UpdateOtpRequestPayload,
    UpdateOtpValidationRequest,
    UpdateOtpValidationRequestPayload
} from "../../../types/v2/common/mobileVerificarionActionTypes";
import {UPDATE_OTP, UPDATE_OTP_VALIDATION} from "../../../ActionTypes/v2/common/mobileVerification";

export const updateOtpRequest = (payload: UpdateOtpRequestPayload): UpdateOtpRequest => ({
    type: UPDATE_OTP,
    payload
});
export const updateOtpValidationRequest = (payload: UpdateOtpValidationRequestPayload): UpdateOtpValidationRequest => ({
    type: UPDATE_OTP_VALIDATION,
    payload
});