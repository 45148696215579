

//Edit Patient Wellness Profile
import {
    UpdatePatientWellnessProfileRequest,
    UpdatePatientWellnessProfileRequestFailure,
    UpdatePatientWellnessProfileRequestFailurePayload,
    UpdatePatientWellnessProfileRequestPayload,
    UpdatePatientWellnessProfileRequestSuccess,
    UpdatePatientWellnessProfileRequestSuccessPayload
} from "../../../types/v2/patient/patientWellnessProfileActionTypes";
import {
    UPDATE_PATIENT_WELLNESS_PROFILE_REQUEST,
    UPDATE_PATIENT_WELLNESS_PROFILE_REQUEST_FAILURE,
    UPDATE_PATIENT_WELLNESS_PROFILE_REQUEST_SUCCESS
} from "../../../ActionTypes/v2/patient/wellnessProfile";

export const updatePatientWellnessProfileRequest = (payload: UpdatePatientWellnessProfileRequestPayload): UpdatePatientWellnessProfileRequest => ({
    type: UPDATE_PATIENT_WELLNESS_PROFILE_REQUEST,
    payload
});
export const updatePatientWellnessProfileRequestSuccess = (
    payload: UpdatePatientWellnessProfileRequestSuccessPayload
): UpdatePatientWellnessProfileRequestSuccess => ({
    type: UPDATE_PATIENT_WELLNESS_PROFILE_REQUEST_SUCCESS,
    payload
});
export const updatePatientWellnessProfileRequestFailure = (
    payload: UpdatePatientWellnessProfileRequestFailurePayload
): UpdatePatientWellnessProfileRequestFailure => ({
    type: UPDATE_PATIENT_WELLNESS_PROFILE_REQUEST_FAILURE,
    payload
});
