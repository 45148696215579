import {
    UPDATE_OCCLUSION_DENTIST_RECALL_FLOW,UPDATE_OCCLUSION_DENTIST_RECALL_FLOW_FAILURE,
    CHANGE_OCCLUSION_DENTIST_RECALL_FLOW
} from "../../../../ActionTypes/v1/dentist/clinicalExamDentist";
import { occlusionDentistRecallInterface } from "../../../../types/dentistTypes";

export const updateOcclusionDentistRecallFlowRequest = (
    payload: occlusionDentistRecallInterface
): occlusionDentistRecallInterface => ({
    type: UPDATE_OCCLUSION_DENTIST_RECALL_FLOW,
    payload
}) as any;

export const updateOcclusionDentistRecallFlowRequestFailure = ():any => ({
    type: UPDATE_OCCLUSION_DENTIST_RECALL_FLOW_FAILURE,
}) as any;

export const changeOcclusionDentistRecallFlowRequest = (payload: any): any => ({
    type: CHANGE_OCCLUSION_DENTIST_RECALL_FLOW,
    payload
}) as any;