import {
    CHANGE_PASSWORD_REQUEST,
    CHANGE_PASSWORD_REQUEST_FAILURE,
    CHANGE_PASSWORD_REQUEST_SUCCESS,
    CREATE_PASSWORD_REQUEST,
    CREATE_PASSWORD_REQUEST_FAILURE,
    CREATE_PASSWORD_REQUEST_SUCCESS,
    FORGOT_PASSWORD_REQUEST,
    FORGOT_PASSWORD_REQUEST_FAILURE,
    FORGOT_PASSWORD_REQUEST_SUCCESS,
    LOGIN_REQUEST,
    LOGIN_REQUEST_FAILURE,
    LOGIN_REQUEST_SUCCESS,
    RECOVER_PASSWORD_REQUEST,
    RECOVER_PASSWORD_REQUEST_FAILURE,
    RECOVER_PASSWORD_REQUEST_SUCCESS,
} from "../../../../ActionTypes/v1/patient/auth";
import {
    LoginRequestFailure,
    LoginRequestFailurePayload,
    LoginRequest,
    LoginRequestSuccess,
    LoginRequestSuccessPayload,
    LoginRequestPayload,

    ChangePasswordRequestFailure,
    ChangePasswordRequestFailurePayload,
    ChangePasswordRequest,
    ChangePasswordRequestSuccess,
    ChangePasswordRequestSuccessPayload,
    ChangePasswordRequestPayload,

    ForgotPasswordRequestFailure,
    ForgotPasswordRequestFailurePayload,
    ForgotPasswordRequest,
    ForgotPasswordRequestSuccess,
    ForgotPasswordRequestSuccessPayload,
    ForgotPasswordRequestPayload,

    RecoverPasswordRequestPayload,
    RecoverPasswordRequest,
    RecoverPasswordRequestSuccessPayload,
    RecoverPasswordRequestFailurePayload,
    RecoverPasswordRequestFailure,
    RecoverPasswordRequestSuccess,
    CreatePasswordRequestPayload,
    CreatePasswordRequestSuccessPayload,
    CreatePasswordRequestFailurePayload,
    CreatePasswordRequest,
    CreatePasswordRequestSuccess,
    CreatePasswordRequestFailure
} from "../../../../types/patientTypes";

export const loginRequest = (payload: LoginRequestPayload): LoginRequest => ({
    type: LOGIN_REQUEST,
    payload
});

export const loginRequestSuccess = (
    payload: LoginRequestSuccessPayload
): LoginRequestSuccess => ({
    type: LOGIN_REQUEST_SUCCESS,
    payload
});

export const loginRequestFailure = (
    payload: LoginRequestFailurePayload
): LoginRequestFailure => ({
    type: LOGIN_REQUEST_FAILURE,
    payload
});


export const changePasswordRequest = (payload: ChangePasswordRequestPayload): ChangePasswordRequest => ({
    type: CHANGE_PASSWORD_REQUEST,
    payload,
});

export const changePasswordRequestSuccess = (
    payload: ChangePasswordRequestSuccessPayload
): ChangePasswordRequestSuccess => ({
    type: CHANGE_PASSWORD_REQUEST_SUCCESS,
    payload
});

export const changePasswordRequestFailure = (
    payload: ChangePasswordRequestFailurePayload
): ChangePasswordRequestFailure => ({
    type: CHANGE_PASSWORD_REQUEST_FAILURE,
    payload
});

export const forgotPasswordRequest = (payload: ForgotPasswordRequestPayload): ForgotPasswordRequest => ({
    type: FORGOT_PASSWORD_REQUEST,
    payload,
});

export const forgotPasswordRequestSuccess = (
    payload: ForgotPasswordRequestSuccessPayload
): ForgotPasswordRequestSuccess => ({
    type: FORGOT_PASSWORD_REQUEST_SUCCESS,
    payload
});

export const forgotPasswordRequestFailure = (
    payload: ForgotPasswordRequestFailurePayload
): ForgotPasswordRequestFailure => ({
    type: FORGOT_PASSWORD_REQUEST_FAILURE,
    payload
});

export const recoverPasswordRequest = (payload: RecoverPasswordRequestPayload): RecoverPasswordRequest => ({
    type: RECOVER_PASSWORD_REQUEST,
    payload,
});

export const recoverPasswordRequestSuccess = (
    payload: RecoverPasswordRequestSuccessPayload
): RecoverPasswordRequestSuccess => ({
    type: RECOVER_PASSWORD_REQUEST_SUCCESS,
    payload
});

export const recoverPasswordRequestFailure = (
    payload: RecoverPasswordRequestFailurePayload
): RecoverPasswordRequestFailure => ({
    type: RECOVER_PASSWORD_REQUEST_FAILURE,
    payload
});


export const createPasswordRequest = (payload: CreatePasswordRequestPayload): CreatePasswordRequest => ({
    type: CREATE_PASSWORD_REQUEST,
    payload,
});


export const createPasswordRequestSuccess = (
    payload: CreatePasswordRequestSuccessPayload
): CreatePasswordRequestSuccess => ({
    type: CREATE_PASSWORD_REQUEST_SUCCESS,
    payload
});

export const createPasswordRequestFailure = (
    payload: CreatePasswordRequestFailurePayload
): CreatePasswordRequestFailure => ({
    type: CREATE_PASSWORD_REQUEST_FAILURE,
    payload
});