import {all, call, put, takeEvery} from 'redux-saga/effects';
import API from '../../../../../utils/api';
import {
    getClinicCacheDataClearRequest,
    getClinicRequestFailure,
    getClinicRequestSuccess,
    getPatientListRequestFailure,
    getPatientListRequestSuccess,
    verifyPasswordRequestFailure,
    verifyPasswordRequestSuccess
} from '../../../../actions/v1/dentist/clinic/clinicActions';
import {
    GET_CLINIC_REQUEST,
    GET_LIST_OF_PATIENT_REQUEST,
    VERIFY_PASSWORD_REQUEST
} from '../../../../ActionTypes/v1/dentist/clinic';
import {errorToaster, getLocalStorageItem, successToaster} from "../../../../../utils/helper";

function* verifyPasswordSaga(payload: any): any {
    try {
        const response = yield API.post(`/v2/auth/account/password`, {
            password: payload.payload.password
        });
        yield put(
            verifyPasswordRequestSuccess({
                data: response.data.detail
            })
        );
        successToaster(response?.data?.meta?.message)
        yield call(payload.payload.callback)
    } catch (e: any) {
        errorToaster(e?.message)
        yield put(
            verifyPasswordRequestFailure({
                error: e?.message
            })
        );
    }
}

function* getClinicRequestSaga(payload: any): any {
    yield put(getClinicCacheDataClearRequest());
    try {
        const response = yield API.get(`/v2/clinic/account/${payload?.payload?.staff_id}`);
        yield put(
            getClinicRequestSuccess({
                data: response.data.detail
            })
        );
        if (payload?.payload.callback) {
            yield call(payload?.payload.callback, response.data.detail);
        }
    } catch (e: any) {
        yield put(getClinicCacheDataClearRequest());
        yield put(
            getClinicRequestFailure({
                error: e?.detail
            })
        );
    }
}

function* getPatientListRequestSaga(payload: any): any {
    try {
        const response = yield API.get(`/appointments/patient/list`);
        yield call(payload.payload.callback, response.data);
        yield put(
            getPatientListRequestSuccess({
                data: response.data
            })
        );
    } catch (e: any) {
        yield put(
            getPatientListRequestFailure({
                error: e?.detail
            })
        );
    }
}

function* verifyPasswordWatch() {
    yield takeEvery(VERIFY_PASSWORD_REQUEST, verifyPasswordSaga);
}

function* clinicSaga() {
    yield takeEvery(GET_CLINIC_REQUEST, getClinicRequestSaga);
}

function* patientListSaga() {
    yield takeEvery(GET_LIST_OF_PATIENT_REQUEST, getPatientListRequestSaga);
}

export default function* rootSaga() {
    yield all([clinicSaga(), patientListSaga(), verifyPasswordWatch()]);
}
