export const LOGIN_V2_REQUEST = 'LOGIN_V2_REQUEST';
export const LOGIN_V2_REQUEST_SUCCESS = 'LOGIN_V2_REQUEST_SUCCESS';
export const LOGIN_V2_REQUEST_FAILURE = 'LOGIN_V2_REQUEST_FAILURE';

export const LOGOUT_V2_REQUEST = 'LOGOUT_V2_REQUEST';
export const LOGOUT_V2_REQUEST_SUCCESS = 'LOGOUT_V2_REQUEST_SUCCESS';
export const LOGOUT_V2_REQUEST_FAILURE = 'LOGOUT_V2_REQUEST_FAILURE';

export const FORGOT_PASSWORD_V2_REQUEST = 'FORGOT_PASSWORD_V2_REQUEST';
export const FORGOT_PASSWORD_V2_REQUEST_SUCCESS = 'FORGOT_PASSWORD_V2_REQUEST_SUCCESS';
export const FORGOT_PASSWORD_V2_REQUEST_FAILURE = 'FORGOT_PASSWORD_V2_REQUEST_FAILURE';

export const IS_MULTIPLE_ACCOUNT_FORGOT_PASSWORD_POPUP = 'IS_MULTIPLE_ACCOUNT_FORGOT_PASSWORD_POPUP';

export const VERIFY_OTP_V2_REQUEST = 'VERIFY_OTP_V2_REQUEST';
export const VERIFY_OTP_V2_REQUEST_SUCCESS = 'VERIFY_OTP_V2_REQUEST_SUCCESS';
export const VERIFY_OTP_V2_REQUEST_FAILURE = 'VERIFY_OTP_V2_REQUEST_FAILURE';

export const SEND_OTP_V2_REQUEST = 'SEND_OTP_V2_REQUEST';
export const SEND_OTP_V2_REQUEST_SUCCESS = 'SEND_OTP_V2_REQUEST_SUCCESS';
export const SEND_OTP_V2_REQUEST_FAILURE = 'SEND_OTP_V2_REQUEST_FAILURE';

export const RECOVER_PASSWORD_V2_REQUEST = 'RECOVER_PASSWORD_V2_REQUEST';
export const RECOVER_PASSWORD_V2_REQUEST_SUCCESS = 'RECOVER_PASSWORD_V2_REQUEST_SUCCESS';
export const RECOVER_PASSWORD_V2_REQUEST_FAILURE = 'RECOVER_PASSWORD_V2_REQUEST_FAILURE';

export const CREATE_PASSWORD_V2_REQUEST = 'CREATE_PASSWORD_V2_REQUEST';
export const CREATE_PASSWORD_V2_REQUEST_SUCCESS = 'CREATE_PASSWORD_V2_REQUEST_SUCCESS';
export const CREATE_PASSWORD_V2_REQUEST_FAILURE = 'CREATE_PASSWORD_V2_REQUEST_FAILURE';

export const PRIVACY_POLICY_V2_REQUEST = 'PRIVACY_POLICY_V2_REQUEST';
export const PRIVACY_POLICY_V2_REQUEST_SUCCESS = 'PRIVACY_POLICY_V2_REQUEST_SUCCESS';
export const PRIVACY_POLICY_V2_REQUEST_FAILURE = 'PRIVACY_POLICY_V2_REQUEST_FAILURE';

export const TERMS_CONDITION_V2_REQUEST = 'TERMS_CONDITION_V2_REQUEST';
export const TERMS_CONDITION_V2_REQUEST_SUCCESS = 'TERMS_CONDITION_V2_REQUEST_SUCCESS';
export const TERMS_CONDITION_V2_REQUEST_FAILURE = 'TERMS_CONDITION_V2_REQUEST_FAILURE';

export const CHANGE_PASSWORD_V2_REQUEST = 'CHANGE_PASSWORD_V2_REQUEST';
export const CHANGE_PASSWORD_V2_REQUEST_SUCCESS = 'CHANGE_PASSWORD_V2_REQUEST_SUCCESS';
export const CHANGE_PASSWORD_V2_REQUEST_FAILURE = 'CHANGE_PASSWORD_V2_REQUEST_FAILURE';

export const GET_TC_REQUEST = 'GET_TC_REQUEST';
export const GET_TC_REQUEST_SUCCESS = 'GET_TC_REQUEST_SUCCESS';
export const GET_TC_REQUEST_FAILURE = 'GET_TC_REQUEST_FAILURE';

export const UPDATE_DEVICE_TOKEN = 'UPDATE_DEVICE_TOKEN';

//Disclaimer
export const DISCLAIMER_ACCEPT_REQUEST = 'DISCLAIMER_ACCEPT_REQUEST';
export const DISCLAIMER_ACCEPT_REQUEST_SUCCESS = 'DISCLAIMER_ACCEPT_REQUEST_SUCCESS';
export const DISCLAIMER_ACCEPT_REQUEST_FAILURE = 'DISCLAIMER_ACCEPT_REQUEST_FAILURE';

export const GOOGLE_LOGIN_REQUEST = 'GOOGLE_LOGIN_REQUEST';
export const GOOGLE_LOGIN_REQUEST_SUCCESS = 'GOOGLE_LOGIN_REQUEST_SUCCESS';
export const GOOGLE_LOGIN_REQUEST_FAILURE = 'GOOGLE_LOGIN_REQUEST_FAILURE';

// Communications
export const COMMUNICATIONS_REQUEST = 'COMMUNICATIONS_REQUEST';
export const COMMUNICATIONS_REQUEST_SUCCESS = 'COMMUNICATIONS_REQUEST_SUCCESS';
export const COMMUNICATIONS_REQUEST_FAILURE = 'COMMUNICATIONS_REQUEST_FAILURE';
