import {
    UnsubscribeEmailRequest,
    UnsubscribeEmailRequestFailure, UnsubscribeEmailRequestFailurePayload, UnsubscribeEmailRequestPayload,
    UnsubscribeEmailRequestSuccess, UnsubscribeEmailRequestSuccessPayload
} from "../../types/unsubscribeEmail/unsubscribeTypes";
import {
    UNSUBSCRIBE_EMAIL_REQUEST, UNSUBSCRIBE_EMAIL_REQUEST_FAILURE,
    UNSUBSCRIBE_EMAIL_REQUEST_SUCCESS
} from "../../ActionTypes/unsubscribeEmail/unsubscribeActionTypes";


export const unsubscribeEmailRequest = (payload: UnsubscribeEmailRequestPayload): UnsubscribeEmailRequest => ({
    type: UNSUBSCRIBE_EMAIL_REQUEST,
    payload
});


export const unsubscribeEmailRequestSuccess = (
    payload: UnsubscribeEmailRequestSuccessPayload
): UnsubscribeEmailRequestSuccess => ({
    type: UNSUBSCRIBE_EMAIL_REQUEST_SUCCESS,
    payload
});

export const unsubscribeEmailRequestFailure = (
    payload: UnsubscribeEmailRequestFailurePayload
): UnsubscribeEmailRequestFailure => ({
    type: UNSUBSCRIBE_EMAIL_REQUEST_FAILURE,
    payload
});