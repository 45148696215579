import {
  GET_ANNOTATION_REQUEST,
  GET_ANNOTATION_REQUEST_FAILURE,
  GET_ANNOTATION_REQUEST_SUCCESS,
  PUT_ANNOTATION_REQUEST,
  PUT_ANNOTATION_REQUEST_FAILURE,
  PUT_ANNOTATION_REQUEST_SUCCESS,
  SELECT_ANNOTATION, SELECT_MARKER
} from '../../../../../ActionTypes/v2/dentist/clinicalExam/exams/annotation';
import {
  getAnnotationRequest,
  getAnnotationRequestFailure,
  getAnnotationRequestFailurePayload,
  getAnnotationRequestPayload,
  getAnnotationRequestSuccess,
  getAnnotationRequestSuccessPayload,
  putAnnotationRequest,
  putAnnotationRequestFailure,
  putAnnotationRequestFailurePayload,
  putAnnotationRequestPayload,
  putAnnotationRequestSuccess,
  putAnnotationRequestSuccessPayload,
  selectAnnotationRequest
} from '../../../../../types/v2/dentist/clinicalExam/exam/annotation';

//annotation
export const selectAnnotationAction = (payload: any): selectAnnotationRequest => ({
  type: SELECT_ANNOTATION,
  payload
});

// get annotation
export const getAnnotationRequestAction = (payload: string): getAnnotationRequest => ({
  type: GET_ANNOTATION_REQUEST,
  payload
});

export const getAnnotationRequestSuccessAction = (
  payload: getAnnotationRequestSuccessPayload
): getAnnotationRequestSuccess => ({
  type: GET_ANNOTATION_REQUEST_SUCCESS,
  payload
});

export const getAnnotationRequestFailureAction = (
  payload: getAnnotationRequestFailurePayload
): getAnnotationRequestFailure => ({
  type: GET_ANNOTATION_REQUEST_FAILURE,
  payload
});

// put annotation
export const putAnnotationRequestAction = (payload: putAnnotationRequestPayload): putAnnotationRequest => ({
  type: PUT_ANNOTATION_REQUEST,
  payload
});

export const putAnnotationRequestSuccessAction = (
  payload: putAnnotationRequestSuccessPayload
): putAnnotationRequestSuccess => ({
  type: PUT_ANNOTATION_REQUEST_SUCCESS,
  payload
});

export const putAnnotationRequestFailureAction = (
  payload: putAnnotationRequestFailurePayload
): putAnnotationRequestFailure => ({
  type: PUT_ANNOTATION_REQUEST_FAILURE,
  payload
});

export const selectMarkerAction = (payload: any): any => ({
  type: SELECT_MARKER,
  payload
});
