import { all, put, takeEvery } from "redux-saga/effects";
import API from "../../../../utils/api";
import { GET_ANALYTICS_DATA_REQUEST } from "../../../ActionTypes/v2/dentistTypes";
import { updateAnalyticsDataAction } from "../../../actions/v2/dentist/dashboardActions";

function* getAnalyticsDataSaga(payload: any): any {
  try {
    const params = [
      { key: 'clinic_id', value: payload.payload.clinic_id },
      { key: 'from_date', value: payload.payload.from_date },
      { key: 'to_date', value: payload.payload.to_date },
    ]
    let tmpUrl = `?`
    params?.map((p: any) => {
      if (tmpUrl.length > 1) {
        if (p.value) {
          tmpUrl = `${tmpUrl}&${p.key}=${p.value}`
        }
      } else {
        if (p.value) {
          tmpUrl = `${tmpUrl}${p.key}=${p.value}`
        }
      }
      return true
    })

    const newUrl = `/v2/analytics${tmpUrl}`
    let url = newUrl
    const response = yield API.get(url)

    // const response = yield API.get(`/v2/analytics?clinic_id=28cbc1d7-304f-453b-8ab2-53f8dcfab8e4&from_date=2020-01-05&to_date=2023-02-07`)
    yield put(
      updateAnalyticsDataAction(response?.data?.detail)
    );
  } catch (e: any) {
    yield put(
      updateAnalyticsDataAction(null)
    );
  }
}

function* getAnalyticsDataWatcher() {
  yield takeEvery(GET_ANALYTICS_DATA_REQUEST, getAnalyticsDataSaga)
}

export default function* dentistDashboardSaga() {
  yield all([
    getAnalyticsDataWatcher()
  ])
}
