import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { Box, Button, Divider, Typography } from '@mui/material';
import theme from "../../../../theme";

const useStyles = makeStyles(() => ({
  verifyButton: {
    height: "40px",
    padding: "10px 20px !important",
    minWidth: "170px",
    width: "fit-content",
    borderRadius: "4px",
    background: theme.palette.secondary.main,
    color: theme.palette.common.white,
    textDecoration: "none",
    textAlign: "center",
    marginLeft: "10px",
    "&:hover": {
      background: theme.palette.secondary.main,
      color: theme.palette.common.white,
    },
  },
  verifyButtonContainer: {
    padding: "12px 20px",
  },
  cancelBtn: {
    height: "40px",
    padding: "8.5px 20px",
    border: `1px solid ${theme.palette.secondary.main}`,
    color: theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: "transparent",
      borderColor: theme.palette.secondary.main,
    },
  },
  dialogContent: {
    width: "100% !important",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
  },
}));
type Props = {
  open: boolean,
  setOpen: any
}

const ReminderPopUp = (props: Props) => {
  const { open, setOpen } = props
  const classes = useStyles();
  const history = useNavigate();

  const handleClose = () => {
    setOpen(false);
    history({
      pathname: "/patient/appointment/upcoming",
    });
  };
  const continueHandle = () => {
    setOpen(false);
    history({
      pathname: "/v2/patient/health-history",
    });
  }
  return (
    <Dialog
      sx={{
        "& .MuiDialogContent-root": {
          width: "100% !important",
          padding: "12px 20px",
        },
        "& .MuiPaper-root ": {
          width: "600px !important",
          maxWidth: "600px !important",
          background: theme.palette.common.white,
          boxShadow: " 0px 10px 20px rgba(0, 0, 0, 0.07)",
          borderRadius: "10px !important",
          display: "flex !important",
          alignItems: "center !important",
          justifyContent: "center !important",
        },
      }}
      open={open}
    >
      <DialogContent>
        <Box className={classes.dialogContent} py={3}>
          <Typography className="f-w-400 f-18 lh-23 fw-regular">
            {'Please complete your health-history registration'}
          </Typography>
        </Box>
      </DialogContent>

      <Divider sx={{ width: "100%", borderColor: theme.palette.divider }} />
      <DialogActions
        className={`d-flex-all-center ${classes.verifyButtonContainer} w-100`}
      >
        {/* <Button
          variant="outlined"
          onClick={handleClose}
          className={classes.cancelBtn}
          style={{ textTransform: "none" }}
        >
          Skip for now
        </Button> */}
        <Button
          className={`fw-regular ${classes.verifyButton}`}
          onClick={continueHandle}
          style={{ textTransform: "capitalize" }}
        >
          Complete Health History
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default ReminderPopUp;
