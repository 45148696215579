import { toothNumberForFindings, toothSurface } from "./helper";

export const non_carious_subType = [
  { label: "Erosion", value: "Erosion" },
  { label: "Abrasion", value: "Abrasion" },
  { label: "Abfraction", value: "Abfraction" },
];
export const grade = [
  { label: "None", value: "None" },
  { label: "Mild", value: "Mild" },
  { label: "Moderate", value: "Moderate" },
  { label: "Severe", value: "Severe" },
];
// recession stuff
export const recessionToothSite = [
  { label: "MB", value: "MB" },
  { label: "B", value: "B" },
  { label: "DB", value: "DB" },
  { label: "ML", value: "ML" },
  { label: "L", value: "L" },
  { label: "DL", value: "DL" },
];
export const tissueToothSite = [
  { label: "Buccal", value: "Buccal" },
  { label: "Lingual", value: "Lingual" },
];
export const defaultNonCariousCervicalLesionsRecallExam = [
  {
    value: [],
    option: toothNumberForFindings,
    type: "multi-select",
    title: "Tooth Number",
    name: "tooth_number",
  },
  {
    option: toothSurface,
    type: "multi-select",
    title: "Tooth Surface",
    name: "tooth_surface",
    value: [],
  },
  {
    value: "",
    option: non_carious_subType,
    type: "select",
    title: "Sub Type",
    name: "sub_type",
    disabled: true,
  },
  {
    value: "",
    name: "note",
    type: "string",
    title: "Notes",
  },
  {
    value: false,
    name: "erosion_radio",
    type: "radio",
    title: "Erosion",
  },
  {
    value: false,
    name: "attrition_radio",
    type: "radio",
    title: "Attrition",
  },
  {
    value: false,
    name: "exposed_radio",
    type: "radio",
    title: "Is dentin exposed?",
  },
];
export const defaultRecessionRecallExam = [
  {
    value: [],
    option: toothNumberForFindings,
    type: "multi-select",
    title: "Tooth Number",
    name: "tooth_number",
  },

  {
    value: "",
    option: recessionToothSite,
    type: "select",
    title: "Tooth site",
    name: "tooth_site",
  },
  {
    value: "",
    option: grade,
    type: "select",
    title: "Grade",
    name: "grade",
  },

  {
    value: "",
    name: "dimension",
    type: "string",
    title: "Dimensions (mm)",
    dataType: "number",
  },
  {
    value: "",
    name: "note",
    type: "string",
    title: "Notes",
  },
];

export const defaultMinimalAttachedTissueRecallExam = [
  {
    value: [],
    option: toothNumberForFindings,
    type: "multi-select",
    title: "Tooth Number",
    name: "tooth_number",
  },
  {
    value: "",
    option: tissueToothSite,
    type: "select",
    title: "Tooth site",
    name: "tooth_site",
  },
  {
    value: "",
    name: "dimension",
    type: "string",
    title: "Dimensions (mm)",
    dataType: "number",
  },
  {
    value: "",
    name: "note",
    type: "string",
    title: "Notes",
  },
];
