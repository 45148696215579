import {
  VERIFY_PASSWORD_REQUEST,
  VERIFY_PASSWORD_REQUEST_SUCCESS,
  VERIFY_PASSWORD_REQUEST_FAILURE
} from '../../../../ActionTypes/v1/dentist/clinic'
import {
  verifyPasswordState,
  VerifyPasswordActions,
} from '../../../../types/dentistTypes'


const initialState: verifyPasswordState = {
  pending: false,
  data: null,
  error: null
};


const verifyPasswordReducer = (state = initialState, action: VerifyPasswordActions) => {
  switch (action.type) {
    case VERIFY_PASSWORD_REQUEST:
      return {
        ...state,
        pending: true,
      }
    case VERIFY_PASSWORD_REQUEST_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.payload,
        error: null
      }
    case VERIFY_PASSWORD_REQUEST_FAILURE:
      return {
        ...state,
        pending: false,
        data: null,
        error: action.payload
      }
    default:
      return {
        ...state
      };
  }
}

export default verifyPasswordReducer