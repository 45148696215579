import { UPDATE_APPOINTMENT_INFO } from "./clinicalExamAssistant";
export const TO_REDIRECT_CLINICAL_EXAM_STEP = "TO_REDIRECT_CLINICAL_EXAM_STEP";
export const TO_REDIRECT_CLINICAL_EXAM_SUB_STEP =
  "TO_REDIRECT_CLINICAL_EXAM_SUB_STEP";
export const UPDATE_APPOINTMENT_STEP_REQUEST =
  "UPDATE_APPOINTMENT_STEP_REQUEST";
export const UPDATE_APPOINTMENT_STEP_REQUEST_SUCCESS =
  "UPDATE_APPOINTMENT_STEP_REQUEST_SUCCESS";
export const UPDATE_APPOINTMENT_STEP_REQUEST_FAILURE =
  "UPDATE_APPOINTMENT_STEP_REQUEST_FAILURE";

// Get appointment type
export const GET_APPOINTMENT_TYPE_REQUEST = "GET_APPOINTMENT_TYPE_REQUEST";
export const GET_APPOINTMENT_TYPE_REQUEST_SUCCESS =
  "GET_APPOINTMENT_TYPE_REQUEST_SUCCESS";
export const GET_APPOINTMENT_TYPE_REQUEST_FAILURE =
  "GET_APPOINTMENT_TYPE_REQUEST_FAILURE";

export const GET_CLINICAL_EXAM_NOTES_REQUEST =
  "GET_CLINICAL_EXAM_NOTES_REQUEST";
export const GET_CLINICAL_EXAM_NOTES_REQUEST_SUCCESS =
  "GET_CLINICAL_EXAM_NOTES_REQUEST_SUCCESS";
export const GET_CLINICAL_EXAM_NOTES_REQUEST_FAILURE =
  "GET_CLINICAL_EXAM_NOTES_REQUEST_FAILURE";

export const TO_HIDE_FOOTER_REQUEST = "TO_HIDE_FOOTER_REQUEST";
