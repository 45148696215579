import {
    GET_NOTIFICATION_REQUEST,
    GET_NOTIFICATION_SUCCESS,
    GET_NOTIFICATION_FAILURE,
    GET_NOTIFICATION_CACHE_DATA_CLEAR_REQUEST,
    PUT_NOTIFICATION_REQUEST,
    PUT_NOTIFICATION_SUCCESS,
    PUT_NOTIFICATION_FAILURE,
    GET_NOTIFICATION_COUNT_REQUEST,
    GET_NOTIFICATION_COUNT_SUCCESS,
    GET_NOTIFICATION_COUNT_FAILURE,
    GET_NOTIFICATION_COUNT_CACHE_DATA_CLEAR_REQUEST
} from '../../../ActionTypes/v2/dentist/notificationListing'
import { NotificationListingTypeActions, NotificationListingTypeState } from '../../../types/v2/dentist/notificationListing';

const initialState: NotificationListingTypeState = {
    notificationLoader: false,
    notificationData: null,
    notificationMeta: null,
    notificationError: null,

    notificationCountLoader: false,
    notificationCount: null,
    notificationCountError: null
};

const notificationListingReducer = (state = initialState, action: NotificationListingTypeActions) => {
    switch (action.type) {
        // get notification listing
        case GET_NOTIFICATION_REQUEST:
            return {
                ...state,
                notificationLoader: true,
            }
        case GET_NOTIFICATION_CACHE_DATA_CLEAR_REQUEST:
            return {
                ...state,
                notificationData: null
            }
        case GET_NOTIFICATION_SUCCESS:
            return {
                ...state,
                notificationLoader: false,
                notificationData: action.payload?.notificationData,
                notificationMeta: action.payload?.notificationMeta,
                notificationError: null
            }
        case GET_NOTIFICATION_FAILURE:
            return {
                ...state,
                notificationLoader: false,
                notificationData: null,
                notificationError: action.payload
            }
        // put notification
        case PUT_NOTIFICATION_REQUEST:
            return {
                ...state,
                notificationLoader: true,
            }
        case PUT_NOTIFICATION_SUCCESS:
            return {
                ...state,
                notificationLoader: false,
                notificationData: action.payload?.notificationData,
                notificationMeta: action.payload?.notificationMeta,
                notificationError: null
            }
        case PUT_NOTIFICATION_FAILURE:
            return {
                ...state,
                notificationLoader: false,
                notificationData: null,
                notificationError: action.payload
            }

        //get notification count
        case GET_NOTIFICATION_COUNT_REQUEST:
            return {
                ...state,
                notificationCountLoader: true,
            }
        case GET_NOTIFICATION_COUNT_CACHE_DATA_CLEAR_REQUEST:
            return {
                ...state,
                notificationCount: null
            }
        case GET_NOTIFICATION_COUNT_SUCCESS:
            return {
                ...state,
                notificationCountLoader: false,
                notificationCount: action.payload?.notificationCount,
                notificationCountError: null
            }
        case GET_NOTIFICATION_COUNT_FAILURE:
            return {
                ...state,
                notificationCountLoader: false,
                notificationCount: null,
                notificationCountError: action.payload
            }
        default:
            return {
                ...state
            };
    }
}

export default notificationListingReducer
