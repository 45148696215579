import CrownFixture11 from './fixture/crownFixture11.svg';
import CrownFixture12 from './fixture/crownFixture12.svg';
import CrownFixture13 from './fixture/crownFixture13.svg';
import CrownFixture14 from './fixture/crownFixture14.svg';
import CrownFixture15 from './fixture/crownFixture15.svg';
import CrownFixture16 from './fixture/crownFixture16.svg';
import CrownFixture17 from './fixture/crownFixture17.svg';
import CrownFixture18 from './fixture/crownFixture18.svg';
import CrownFixture21 from './fixture/crownFixture21.svg';
import CrownFixture22 from './fixture/crownFixture22.svg';
import CrownFixture23 from './fixture/crownFixture23.svg';
import CrownFixture24 from './fixture/crownFixture24.svg';
import CrownFixture25 from './fixture/crownFixture25.svg';
import CrownFixture26 from './fixture/crownFixture26.svg';
import CrownFixture27 from './fixture/crownFixture27.svg';
import CrownFixture28 from './fixture/crownFixture28.svg';
import CrownFixture31 from './fixture/crownFixture31.svg';
import CrownFixture32 from './fixture/crownFixture32.svg';
import CrownFixture33 from './fixture/crownFixture33.svg';
import CrownFixture34 from './fixture/crownFixture34.svg';
import CrownFixture35 from './fixture/crownFixture35.svg';
import CrownFixture36 from './fixture/crownFixture36.svg';
import CrownFixture37 from './fixture/crownFixture37.svg';
import CrownFixture38 from './fixture/crownFixture38.svg';
import CrownFixture41 from './fixture/crownFixture41.svg';
import CrownFixture42 from './fixture/crownFixture42.svg';
import CrownFixture43 from './fixture/crownFixture43.svg';
import CrownFixture44 from './fixture/crownFixture44.svg';
import CrownFixture45 from './fixture/crownFixture45.svg';
import CrownFixture46 from './fixture/crownFixture46.svg';
import CrownFixture47 from './fixture/crownFixture47.svg';
import CrownFixture48 from './fixture/crownFixture48.svg';
import LingualFixture11 from './fixture/lingualFixture11.svg';
import LingualFixture12 from './fixture/lingualFixture12.svg';
import LingualFixture13 from './fixture/lingualFixture13.svg';
import LingualFixture14 from './fixture/lingualFixture14.svg';
import LingualFixture15 from './fixture/lingualFixture15.svg';
import LingualFixture16 from './fixture/lingualFixture16.svg';
import LingualFixture17 from './fixture/lingualFixture17.svg';
import LingualFixture18 from './fixture/lingualFixture18.svg';
import LingualFixture21 from './fixture/lingualFixture21.svg';
import LingualFixture22 from './fixture/lingualFixture22.svg';
import LingualFixture23 from './fixture/lingualFixture23.svg';
import LingualFixture24 from './fixture/lingualFixture24.svg';
import LingualFixture25 from './fixture/lingualFixture25.svg';
import LingualFixture26 from './fixture/lingualFixture26.svg';
import LingualFixture27 from './fixture/lingualFixture27.svg';
import LingualFixture28 from './fixture/lingualFixture28.svg';
import LingualFixture31 from './fixture/lingualFixture31.svg';
import LingualFixture32 from './fixture/lingualFixture32.svg';
import LingualFixture33 from './fixture/lingualFixture33.svg';
import LingualFixture34 from './fixture/lingualFixture34.svg';
import LingualFixture35 from './fixture/lingualFixture35.svg';
import LingualFixture36 from './fixture/lingualFixture36.svg';
import LingualFixture37 from './fixture/lingualFixture37.svg';
import LingualFixture38 from './fixture/lingualFixture38.svg';
import LingualFixture41 from './fixture/lingualFixture41.svg';
import LingualFixture42 from './fixture/lingualFixture42.svg';
import LingualFixture43 from './fixture/lingualFixture43.svg';
import LingualFixture44 from './fixture/lingualFixture44.svg';
import LingualFixture45 from './fixture/lingualFixture45.svg';
import LingualFixture46 from './fixture/lingualFixture46.svg';
import LingualFixture47 from './fixture/lingualFixture47.svg';
import LingualFixture48 from './fixture/lingualFixture48.svg';
import ImplantCrown11 from './crown/implantCrown11.svg';
import ImplantCrown12 from './crown/implantCrown12.svg';
import ImplantCrown13 from './crown/implantCrown13.svg';
import ImplantCrown14 from './crown/implantCrown14.svg';
import ImplantCrown15 from './crown/implantCrown15.svg';
import ImplantCrown16 from './crown/implantCrown16.svg';
import ImplantCrown17 from './crown/implantCrown17.svg';
import ImplantCrown18 from './crown/implantCrown18.svg';
import ImplantCrown21 from './crown/implantCrown21.svg';
import ImplantCrown22 from './crown/implantCrown22.svg';
import ImplantCrown23 from './crown/implantCrown23.svg';
import ImplantCrown24 from './crown/implantCrown24.svg';
import ImplantCrown25 from './crown/implantCrown25.svg';
import ImplantCrown26 from './crown/implantCrown26.svg';
import ImplantCrown27 from './crown/implantCrown27.svg';
import ImplantCrown28 from './crown/implantCrown28.svg';
import ImplantCrown31 from './crown/implantCrown31.svg';
import ImplantCrown32 from './crown/implantCrown32.svg';
import ImplantCrown33 from './crown/implantCrown33.svg';
import ImplantCrown34 from './crown/implantCrown34.svg';
import ImplantCrown35 from './crown/implantCrown34.svg';
import ImplantCrown36 from './crown/implantCrown36.svg';
import ImplantCrown37 from './crown/implantCrown37.svg';
import ImplantCrown38 from './crown/implantCrown38.svg';
import ImplantCrown41 from './crown/implantCrown41.svg';
import ImplantCrown42 from './crown/implantCrown42.svg';
import ImplantCrown43 from './crown/implantCrown43.svg';
import ImplantCrown44 from './crown/implantCrown44.svg';
import ImplantCrown45 from './crown/implantCrown45.svg';
import ImplantCrown46 from './crown/implantCrown46.svg';
import ImplantCrown47 from './crown/implantCrown47.svg';
import ImplantCrown48 from './crown/implantCrown48.svg';
import ImplantLingualCrown11 from './crown/implantLingualCrown11.svg';
import ImplantLingualCrown12 from './crown/implantLingualCrown12.svg';
import ImplantLingualCrown13 from './crown/implantLingualCrown13.svg';
import ImplantLingualCrown14 from './crown/implantLingualCrown14.svg';
import ImplantLingualCrown15 from './crown/implantLingualCrown15.svg';
import ImplantLingualCrown16 from './crown/implantLingualCrown16.svg';
import ImplantLingualCrown17 from './crown/implantLingualCrown17.svg';
import ImplantLingualCrown18 from './crown/implantLingualCrown18.svg';
import ImplantLingualCrown21 from './crown/implantLingualCrown21.svg';
import ImplantLingualCrown22 from './crown/implantLingualCrown22.svg';
import ImplantLingualCrown23 from './crown/implantLingualCrown23.svg';
import ImplantLingualCrown24 from './crown/implantLingualCrown24.svg';
import ImplantLingualCrown25 from './crown/implantLingualCrown25.svg';
import ImplantLingualCrown26 from './crown/implantLingualCrown26.svg';
import ImplantLingualCrown27 from './crown/implantLingualCrown27.svg';
import ImplantLingualCrown28 from './crown/implantLingualCrown28.svg';
import ImplantLingualCrown31 from './crown/implantLingualCrown31.svg';
import ImplantLingualCrown32 from './crown/implantLingualCrown32.svg';
import ImplantLingualCrown33 from './crown/implantLingualCrown33.svg';
import ImplantLingualCrown34 from './crown/implantLingualCrown34.svg';
import ImplantLingualCrown35 from './crown/implantLingualCrown35.svg';
import ImplantLingualCrown36 from './crown/implantLingualCrown36.svg';
import ImplantLingualCrown37 from './crown/implantLingualCrown37.svg';
import ImplantLingualCrown38 from './crown/implantLingualCrown38.svg';
import ImplantLingualCrown41 from './crown/implantLingualCrown41.svg';
import ImplantLingualCrown42 from './crown/implantLingualCrown42.svg';
import ImplantLingualCrown43 from './crown/implantLingualCrown43.svg';
import ImplantLingualCrown44 from './crown/implantLingualCrown44.svg';
import ImplantLingualCrown45 from './crown/implantLingualCrown45.svg';
import ImplantLingualCrown46 from './crown/implantLingualCrown46.svg';
import ImplantLingualCrown47 from './crown/implantLingualCrown47.svg';
import ImplantLingualCrown48 from './crown/implantLingualCrown48.svg';

export const implantsIcon = {
  CrownFixture11,
  CrownFixture12,
  CrownFixture13,
  CrownFixture14,
  CrownFixture15,
  CrownFixture16,
  CrownFixture17,
  CrownFixture18,
  CrownFixture21,
  CrownFixture22,
  CrownFixture23,
  CrownFixture24,
  CrownFixture25,
  CrownFixture26,
  CrownFixture27,
  CrownFixture28,
  CrownFixture31,
  CrownFixture32,
  CrownFixture33,
  CrownFixture34,
  CrownFixture35,
  CrownFixture36,
  CrownFixture37,
  CrownFixture38,
  CrownFixture41,
  CrownFixture42,
  CrownFixture43,
  CrownFixture44,
  CrownFixture45,
  CrownFixture46,
  CrownFixture47,
  CrownFixture48,
  LingualFixture11,
  LingualFixture12,
  LingualFixture13,
  LingualFixture14,
  LingualFixture15,
  LingualFixture16,
  LingualFixture17,
  LingualFixture18,
  LingualFixture21,
  LingualFixture22,
  LingualFixture23,
  LingualFixture24,
  LingualFixture25,
  LingualFixture26,
  LingualFixture27,
  LingualFixture28,
  LingualFixture31,
  LingualFixture32,
  LingualFixture33,
  LingualFixture34,
  LingualFixture35,
  LingualFixture36,
  LingualFixture37,
  LingualFixture38,
  LingualFixture41,
  LingualFixture42,
  LingualFixture43,
  LingualFixture44,
  LingualFixture45,
  LingualFixture46,
  LingualFixture47,
  LingualFixture48,
  ImplantCrown11,
  ImplantCrown12,
  ImplantCrown13,
  ImplantCrown14,
  ImplantCrown15,
  ImplantCrown16,
  ImplantCrown17,
  ImplantCrown18,
  ImplantCrown21,
  ImplantCrown22,
  ImplantCrown23,
  ImplantCrown24,
  ImplantCrown25,
  ImplantCrown26,
  ImplantCrown27,
  ImplantCrown28,
  ImplantCrown31,
  ImplantCrown32,
  ImplantCrown33,
  ImplantCrown34,
  ImplantCrown35,
  ImplantCrown36,
  ImplantCrown37,
  ImplantCrown38,
  ImplantCrown41,
  ImplantCrown42,
  ImplantCrown43,
  ImplantCrown44,
  ImplantCrown45,
  ImplantCrown46,
  ImplantCrown47,
  ImplantCrown48,
  ImplantLingualCrown11,
  ImplantLingualCrown12,
  ImplantLingualCrown13,
  ImplantLingualCrown14,
  ImplantLingualCrown15,
  ImplantLingualCrown16,
  ImplantLingualCrown17,
  ImplantLingualCrown18,
  ImplantLingualCrown21,
  ImplantLingualCrown22,
  ImplantLingualCrown23,
  ImplantLingualCrown24,
  ImplantLingualCrown25,
  ImplantLingualCrown26,
  ImplantLingualCrown27,
  ImplantLingualCrown28,
  ImplantLingualCrown31,
  ImplantLingualCrown32,
  ImplantLingualCrown33,
  ImplantLingualCrown34,
  ImplantLingualCrown35,
  ImplantLingualCrown36,
  ImplantLingualCrown37,
  ImplantLingualCrown38,
  ImplantLingualCrown41,
  ImplantLingualCrown42,
  ImplantLingualCrown43,
  ImplantLingualCrown44,
  ImplantLingualCrown45,
  ImplantLingualCrown46,
  ImplantLingualCrown47,
  ImplantLingualCrown48
};
