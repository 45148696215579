import {
  GET_TC_REQUEST,
  GET_TC_REQUEST_FAILURE,
  GET_TC_REQUEST_SUCCESS,
  TERMS_CONDITION_V2_REQUEST,
  TERMS_CONDITION_V2_REQUEST_FAILURE,
  TERMS_CONDITION_V2_REQUEST_SUCCESS
} from '../../../ActionTypes/v2/auth';
import {
  GetTCFailurePayload,
  GetTCRequest,
  GetTCRequestFailure,
  GetTCRequestPayload,
  GetTCRequestSuccess,
  GetTCSuccessPayload,
  TermsConditionsFailurePayload,
  TermsConditionsRequest,
  TermsConditionsRequestFailure,
  TermsConditionsRequestPayload,
  TermsConditionsRequestSuccess,
  TermsConditionsSuccessPayload
} from '../../../types/v2/authActionTypes/termsConditionActionTypes';

export const termsConditionRequest = (payload: TermsConditionsRequestPayload): TermsConditionsRequest => ({
  type: TERMS_CONDITION_V2_REQUEST,
  payload
});

export const termsConditionSuccess = (payload: TermsConditionsSuccessPayload): TermsConditionsRequestSuccess => ({
  type: TERMS_CONDITION_V2_REQUEST_SUCCESS,
  payload
});

export const termsConditionFailure = (payload: TermsConditionsFailurePayload): TermsConditionsRequestFailure => ({
  type: TERMS_CONDITION_V2_REQUEST_FAILURE,
  payload
});

export const GetTCDataRequest = (payload: GetTCRequestPayload): GetTCRequest => ({
  type: GET_TC_REQUEST,
  payload
});

export const GetTCDataSuccess = (payload: GetTCSuccessPayload): GetTCRequestSuccess => ({
  type: GET_TC_REQUEST_SUCCESS,
  payload
});

export const GetTCDataFailure = (payload: GetTCFailurePayload): GetTCRequestFailure => ({
  type: GET_TC_REQUEST_FAILURE,
  payload
});
