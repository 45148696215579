export const CHANGE_TREATMENT_PLAN = "CHANGE_TREATMENT_PLAN";
export const ADD_TREATMENT_PLAN = "ADD_TREATMENT_PLAN";
export const REMOVE_TREATMENT_PLAN = "REMOVE_TREATMENT_PLAN";
export const ADD_ALTERNATIVE_TREATMENT = "ADD_ALTERNATIVE_TREATMENT";
export const REMOVE_ALTERNATIVE_TREATMENT = "REMOVE_ALTERNATIVE_TREATMENT";
export const HANDLE_TREATMENT_PLAN_VALUE_CHANGE = "HANDLE_TREATMENT_PLAN_VALUE_CHANGE";
export const UPDATE_TREATMENT_PLAN = "UPDATE_TREATMENT_PLAN";
export const UPDATE_TREATMENT_PLAN_CACHE_DATA_CLEAR = "UPDATE_TREATMENT_PLAN_CACHE_DATA_CLEAR";

export const GET_TREATMENT_PLAN_REQUEST = "GET_TREATMENT_PLAN_REQUEST";
export const GET_TREATMENT_PLAN_CACHE_DATA_CLEAR_REQUEST = "GET_TREATMENT_PLAN_CACHE_DATA_CLEAR_REQUEST";
export const GET_TREATMENT_PLAN_REQUEST_SUCCESS = "GET_TREATMENT_PLAN_REQUEST_SUCCESS";
export const GET_TREATMENT_PLAN_REQUEST_FAILURE = "GET_TREATMENT_PLAN_REQUEST_FAILURE";


export const PUT_TREATMENT_PLAN_REQUEST = "PUT_TREATMENT_PLAN_REQUEST";
export const PUT_TREATMENT_PLAN_REQUEST_SUCCESS = "PUT_TREATMENT_PLAN_REQUEST_SUCCESS";
export const PUT_TREATMENT_PLAN_REQUEST_FAILURE = "PUT_TREATMENT_PLAN_REQUEST_FAILURE";

export const DELETE_TREATMENT_PLAN = "DELETE_TREATMENT_PLAN"
export const DELETE_TREATMENT_PLAN_SUCCESS = "DELETE_TREATMENT_PLAN_SUCCESS"
export const DELETE_TREATMENT_PLAN_FAILURE = "DELETE_TREATMENT_PLAN_FAILURE"
