import React, {useEffect, useMemo, useState} from "react";
import {makeStyles} from "@mui/styles";
import {useLocation} from "react-router";
import ImageMarker from "react-image-marker";
import {Box, useMediaQuery} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import PopupComponent from "../PopupComponent";
import theme from "../../../../theme";
import { examList} from "../../../../utils/helper";
import {
    getPhotosDetailsRequestAction
} from "../../../../app/actions/v2/dentist/clinicalExam/exam/uploadPhotosXrayAction";
import {RootState} from "../../../../app/reducers/v2/rootReducer";
import AnnotationsTooltip from "../../dentist/clinicalExam/AnnotationsTooltip";
import pusher from "../../../../utils/pusher";

type Props = {
    open: boolean;
    setOpen: any
    selectImageXray: any
    isPatient?: boolean | undefined
    setSelectImageXray: any
    patientReview?: boolean | undefined
};

const useStyles = makeStyles((theme: any) => ({
    imageSize: {
        width: "100%",
        display: "block",
        objectFit: "contain",
        [theme.breakpoints.up(1000)]: {
            maxHeight: "550px",
        },
        [theme.breakpoints.down(1000)]: {
            maxHeight: "415px",
        },
        [theme.breakpoints.down(768)]: {
            maxHeight: "270px",
        },
    },

}));

const ImageXrayPupUp = (props: Props) => {
    const {open, selectImageXray, setOpen, isPatient, setSelectImageXray} = props;
    const classes = useStyles()
    const location = useLocation()
    const dispatch = useDispatch()
    const takeFromUrl = useParams()
    const isSmallScreen = useMediaQuery(theme.breakpoints.down(500));
    const {uploadPhotoXray: {photosXrayDetails}} = useSelector((state: RootState) => state);
    const [imageData, setImageData] = useState({}) as any
    const [openTooltip, setOpenTooltip] = useState<any>({tagId: null, findings: null});

    const withoutAccountWellnessProfile: boolean = useMemo(() => {
        return location?.pathname?.includes('/patient/without-account/wellness-profile/')
    }, [location.pathname])


    useEffect(() => {
        if (!withoutAccountWellnessProfile && selectImageXray) {
            dispatch(getPhotosDetailsRequestAction({
                image_id: selectImageXray?.image_id,
                callback: (data: any) => {
                    setImageData(data?.tags || photosXrayDetails?.tags)
                }
            }))
        } else {
            setImageData(selectImageXray?.tags)
        }
    }, [selectImageXray])

    // get instant data using pusher
    useEffect(() => {
        const channel = pusher.subscribe('second_screen_channel');
        channel.bind(takeFromUrl?.appointmentId || '', (data: any) => {
            if (data?.data_changed && data?.is_second_screen && data?.is_annotation) {
                dispatch(getPhotosDetailsRequestAction({
                    image_id: data?.image_id,
                    callback: (data: any) => {
                        setImageData(data?.tags || photosXrayDetails?.tags)
                    }
                }))
            }
        });

    }, [open])

    const handleClose = () => {
        setOpen(false)
        setSelectImageXray(null)
    }
    const getMarkersFromTag = useMemo(() => {
        if (imageData && imageData.length > 0) {
            return (
                imageData.map((res: any) => {
                    return {
                        top: res.y_position,
                        left: res.x_position,
                        color: examList[res.title].color,
                        title: res.title,
                        tag_id: res?.tag_id,
                        findings: res?.finding
                    };
                }) || []
            );
        }
        return [];
    }, [imageData]);

    return (
        <PopupComponent
            width={!isSmallScreen ? `calc(100vw - 150px)` : '100%'}
            height={!isSmallScreen ? `calc(100vh - 152px)` : 'fit-content'}
            headerAction={true}
            footerAction={false}
            open={open}
            heading={selectImageXray?.image_name || ''}
            handleClose={handleClose}
            isPatient={isPatient}
            fromImageXrayPopUp
        >
            <Box className={'d-flex-all-center'} width={'100%'} height={'100%'}>
                <Box>
                    <ImageMarker
                        extraClass={`image-class-view-all cursor-pointer ${classes.imageSize}`}
                        src={selectImageXray?.image_path}
                        markers={getMarkersFromTag || []}
                        markerComponent={(e) => (
                            <AnnotationsTooltip markerData={e}
                                                setOpenTooltip={setOpenTooltip}
                                                openTooltip={openTooltip}
                                                annotationData={imageData}
                            />
                        )}
                    />
                </Box>
                {/*<img src={selectImageXray?.image_path} alt={'image/xray'}*/}
                {/*     className={`border-radius-8 ${classes.imageSize}`}/>*/}
            </Box>

        </PopupComponent>
    )
}

export default ImageXrayPupUp;