import React from 'react'
import PopupComponent from './PopupComponent'
import copy from 'clipboard-copy'
import { useSelector } from 'react-redux'
import { TextField } from '@mui/material'
import { RootState } from '../../../app/reducers/v2/rootReducer'
import { successToaster } from '../../../utils/helper'
import theme from '../../../theme'


type Props = {
    open: boolean
    setOpen: any
}
const CopyPopUpForSafari = (props: Props) => {
    const { open, setOpen } = props
    const { insurance: { copyNotesData } } = useSelector((state: RootState) => state);
    const copyText = () => {
        copy(copyNotesData)
        successToaster("Notes copied to clipboard!")
        setOpen(false)
    }
    const handleClose = () => {
        setOpen(false)
    }
    return (
        <PopupComponent
            heading={'Copy Clinical Note'}
            width={'700px'}
            saveBtnText={'Copy'}
            cancelBtnText={'Cancel'}
            isOraqIcon
            headerAction={true}
            footerAction={copyNotesData ? true : false}
            open={open}
            handleClose={handleClose}
            handleSave={copyText}>
            <TextField
                InputProps={{
                    classes: {
                        root: `border-radius-8 textFieldEndIconPadding textFieldStartIconPadding labelColor bg-white `,
                        input: 'textFieldMultilineClass textField',
                    },
                }}
                id='copyNotes'
                value={copyNotesData ? copyNotesData : 'No notes found.'}
                fullWidth={true}
                multiline={true}
                disabled
                sx={{
                    '& .MuiOutlinedInput-input': {
                        minWidth: '100%',
                    },

                }}
            />
        </PopupComponent>
    )
}


export default CopyPopUpForSafari