import {
    UPDATE_PATIENT_WELLNESS_PROFILE_REQUEST, UPDATE_PATIENT_WELLNESS_PROFILE_REQUEST_FAILURE,
    UPDATE_PATIENT_WELLNESS_PROFILE_REQUEST_SUCCESS
} from "../../../ActionTypes/v2/patient/wellnessProfile";
import {
    PatientWellnessProfileActions,
    patientWellnessProfileInterface
} from "../../../types/v2/patient/patientWellnessProfileActionTypes";


const initialState: patientWellnessProfileInterface = {
    updateWellnessProfileLoader: false,
    updateWellnessProfileData: null,
    updateWellnessProfileError: null,
};
const patientWellnessProfileReducer = (state = initialState, action: PatientWellnessProfileActions) => {
    switch (action.type) {

        case UPDATE_PATIENT_WELLNESS_PROFILE_REQUEST:
            return {
                ...state,
                updateWellnessProfileLoader: true,
                updateWellnessProfileData: null,
                updateWellnessProfileError: null,
            };
        case UPDATE_PATIENT_WELLNESS_PROFILE_REQUEST_SUCCESS:
            return {
                ...state,
                updateWellnessProfileLoader: false,
                updateWellnessProfileData: action.payload,
                updateWellnessProfileError: null
            };

        case UPDATE_PATIENT_WELLNESS_PROFILE_REQUEST_FAILURE:
            return {
                ...state,
                updateWellnessProfileLoader: false,
                updateWellnessProfileData: null,
                updateWellnessProfileError: action.payload.error
            };

        default:
            return {
                ...state
            };
    }
};
export default patientWellnessProfileReducer;
