/*Get recommendation and treatment api*/
export const GET_TREATMENT_DATA_REQUEST = 'GET_TREATMENT_DATA_REQUEST';
export const GET_TREATMENT_DATA_SUCCESS = 'GET_TREATMENT_DATA_SUCCESS';
export const GET_TREATMENT_DATA_FAIL = 'GET_TREATMENT_DATA_FAIL';

/*Update recommendation and treatment api*/
export const UPDATE_TREATMENT_DATA_REQUEST = 'UPDATE_TREATMENT_DATA_REQUEST';
export const UPDATE_TREATMENT_DATA_SUCCESS = 'UPDATE_TREATMENT_DATA_SUCCESS';
export const UPDATE_TREATMENT_DATA_FAIL = 'UPDATE_TREATMENT_DATA_FAIL';

// Update data
export const TREATMENT_DATA_CHANGE_REQUEST = 'TREATMENT_DATA_CHANGE_REQUEST';
export const RECOMMENDATION_DATA_CHANGE_REQUEST = 'RECOMMENDATION_DATA_CHANGE_REQUEST';
export const CHECKED_RECOMMENDATION_REQUEST = 'CHECKED_RECOMMENDATION_REQUEST';
export const ADDITIONAL_TREATMENT_DATA_CHANGE_REQUEST = 'ADDITIONAL_TREATMENT_DATA_CHANGE_REQUEST';

/*Delete recommendation and treatment api*/
export const DELETE_TREATMENT_DATA_REQUEST = 'DELETE_TREATMENT_DATA_REQUEST';
export const DELETE_TREATMENT_DATA_SUCCESS = 'DELETE_TREATMENT_DATA_SUCCESS';
export const DELETE_TREATMENT_DATA_FAIL = 'DELETE_TREATMENT_DATA_FAIL';

export const IS_EDIT_RECOMMENDATION = 'IS_EDIT_RECOMMENDATION';

export const OPEN_RECOMMENDATION_LOADING_SCREEN = 'OPEN_RECOMMENDATION_LOADING_SCREEN';

export const RECOMMENDATION_LOADER_SCREEN_TIMING_STATE = 'RECOMMENDATION_LOADER_SCREEN_TIMING_STATE';

export const RECOMMENDATION_PREVIOUS_DATA = 'RECOMMENDATION_PREVIOUS_DATA';

export const GENERATE_RECOMMENDATION_REQUEST = 'GENERATE_RECOMMENDATION';
export const GENERATE_RECOMMENDATION_REQUEST_SUCCESS = 'GENERATE_RECOMMENDATION_REQUEST_SUCCESS';
export const GENERATE_RECOMMENDATION_REQUEST_FAILURE = 'GENERATE_RECOMMENDATION_REQUEST_FAILURE';

/*Master Api for Get recommendation*/
export const GET_RECOMMENDATION_DATA_REQUEST = 'GET_RECOMMENDATION_DATA_REQUEST';
export const GET_RECOMMENDATION_DATA_SUCCESS = 'GET_RECOMMENDATION_DATA_SUCCESS';
export const GET_RECOMMENDATION_DATA_FAIL = 'GET_RECOMMENDATION_DATA_FAIL';

export const UPDATE_RECOMMENDATION_DATA_REQUEST = 'UPDATE_RECOMMENDATION_DATA_REQUEST';

export const HYGIENE_REGIME_DATA_CHANGE_REQUEST = 'HYGIENE_REGIME_DATA_CHANGE_REQUEST';

export const FILTER_PROPOSED_DATA_REQUEST = 'FILTER_PROPOSED_DATA_REQUEST';

export const ADDITIONAL_ODONTOGRAM_DATA_REQUEST = 'ADDITIONAL_ODONTOGRAM_DATA_REQUEST';

export const ADD_HYGIENE_REGIME = 'ADD_HYGIENE_REGIME';

//add Adjunctive
export const ADD_ADJUNCTIVE = 'ADD_ADJUNCTIVE';

// set staging and grading value from key findings
export const SET_STAGING_GRADING_FROM_KEY_FINDING = 'SET_STAGING_GRADING_FROM_KEY_FINDING';

// change staging and grading value
export const UPDATE_STAGING_GRADING = 'UPDATE_STAGING_GRADING';

// change extent value
export const UPDATE_EXTENT = 'UPDATE_EXTENT';
// notes for hygiene regime
export const ADD_NOTES_HYGIENE_REGIME = 'ADD_NOTES_HYGIENE_REGIME';

//add ReferralsPatientReview
export const ADD_REFERRALS = 'ADD_REFERRALS';

export const UPDATE_X_RAY_CYCLE_REQUEST = 'UPDATE_X_RAY_CYCLE_REQUEST'
export const UPDATE_X_RAY_CYCLE_REQUEST_SUCCESS = 'UPDATE_X_RAY_CYCLE_REQUEST_SUCCESS'
export const UPDATE_X_RAY_CYCLE_REQUEST_FAILURE = 'UPDATE_X_RAY_CYCLE_REQUEST_FAILURE'