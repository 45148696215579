import {faExpandAlt, faMaximize, faMinimize, faMinus, faTrash} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Box, Divider} from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import {makeStyles} from '@mui/styles';
import {isArray} from 'lodash';
import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';
import ExamGroup from '../ExamGroup';
import ChartingForm from "../ChartingForm";
import {
    examFindingDataCountAction,
    setExamFindingsAction
} from '../../../../../app/actions/v2/dentist/clinicalExam/exam/examFindingsTypesAction';
import {
    handleOdontogramFormFindingSelectionAction
} from "../../../../../app/actions/v2/dentist/clinicalExam/odontogramAction";
import {RootState} from '../../../../../app/reducers/v2/rootReducer';
import theme from '../../../../../theme';
import {examList, getUniqId} from "../../../../../utils/helper";
import {
    hardTissue,
    limitedExam,
    medical,
    odontogram,
    photo,
    softTissue,
    xray
} from '../../../../../utils/v2/clinicalExamConstant';
import {
    examGroupsData,
    examTabName,
    findingData,
    findingTypes,
} from '../../../../../utils/v2/examConstant';
import CollapsibleBoxWithoutBorder from '../../../common/CollapsibleBoxWithoutBorder';

const useStyles = makeStyles((theme: any) => ({
    accordionRoot: {
        position: 'absolute',
        width: '25%',
        [theme.breakpoints.down('lg')]: {
            width: '33.33% !important'
        },
        right: 0
    },
    accordionSummary: {
        color: theme.palette.common.white,
        height: '36px',
        backgroundColor: `${theme.palette.v2.primary.main} !important`,
        cursor: 'auto !important'
    },
    accordionDetails: {
        padding: '1px 0px 0px !important',
        backgroundColor: theme.palette.common.white
    },
    expanded: {
        '&$expanded': {
            minHeight: 36,
            maxHeight: 36
        }
    },
    accordionRootForm: {
        margin: '0 !important',
        '& .MuiAccordion-root': {
            margin: '0 !important'
        }
    },
    accordionSummaryForm: {
        color: theme.palette.v2.primary.main,
        height: '42px',
        backgroundColor: theme.palette.v2.primary.primary25,
        padding: '10px 12px',
        border: `1px solid ${theme.palette.v2.primary.main}`
    },
    accordionDetailsForm: {
        padding: '16px 20px !important',
        backgroundColor: theme.palette.common.white
    },
    expandedForm: {
        '&$expanded': {
            minHeight: 42,
            maxHeight: 42,
        }
    },
}));

type Props = {
    handleExpanded: any;
    handleFullSize: any;
    showFooter: boolean;
};
type examConstantInterface = {
    key?: string;
    title?: string;
    risks?: Array<string>;
    examType?: Array<string>;
    findingTypes?: Array<string>;
    examGroup?: string;
    options?: Array<any>;
};

const ExamForm = (props: Props) => {
    const {handleExpanded, handleFullSize, showFooter} = props;
    const classes = useStyles();
    const dispatch = useDispatch()
    const examFormRef = useRef(null) as any
    const location = useLocation()
    const isWellnessProfile = location?.pathname?.includes('/v2/dentist/wellness-profile/')
    const [handleState, updateHandleState] = useState(false)
    const {
        odontogram: {selectedFormFinding},
        annotation: {annotationDataFromAPI},
        examFindingReducer: {examDataFromAPI, examFindingData},
        appointmentsReducer: {appointmentDetails},
        wellnessProfileFilters: {wellnessProfileStep, wellnessProfileData},
        clinicalExamFilters: {
            riskFilters,
            findingType,
            examAccordionExpanded,
            examFormSize,
            openSpeedometerClinical,
            openCompleteStep
        },
    } = useSelector((state: RootState) => state) as any
    const tabData = isWellnessProfile ? wellnessProfileStep : findingType
    const isPhotoXray = tabData === photo || tabData === xray
    useEffect(() => {
        if (examFormRef?.current) {
            examFormRef.current.scroll(0, 0)
        }
    }, [tabData])

    useEffect(() => {
        updateHandleState(!handleState)
    }, [openCompleteStep, examFindingData])

    useEffect(() => {
        createStructureForCurrentExam()
        getData()
    }, [riskFilters, tabData, examDataFromAPI, openCompleteStep, annotationDataFromAPI]) /*TODO REDUX State for opopup open*/

    const getData = () => {
        const examType = appointmentDetails?.appointmentType;
        const findingTypeWiseFilteredData = findingData?.filter((f: examConstantInterface) =>
            f?.findingTypes?.includes(findingTypes[tabData])
        ) as any;
        // Limited Exam data
        const limitedExamData = (tabData === limitedExam) ? findingTypeWiseFilteredData?.filter((res: any) => res?.findingTypes?.includes(softTissue) || res?.findingTypes?.includes(hardTissue)) : findingTypeWiseFilteredData
        const riskWiseFilteredData = riskFilters?.length
            ? (limitedExamData?.filter((f: examConstantInterface) => f?.risks?.filter((ff: string) => riskFilters.includes(ff))?.length) as any)
            : limitedExamData; /*Filter the data only if there is at least one risk is selected else will show data without risk filters*/

        const examTypeWiseFilteredData = riskWiseFilteredData?.filter((f: examConstantInterface) => f?.examType?.includes(examType));
        return (isPhotoXray || isWellnessProfile) ? riskWiseFilteredData : examTypeWiseFilteredData
    };

    const examGroupWiseFilteredData = (key: any) => {
        return getData()?.filter((f: examConstantInterface) => f?.examGroup === key);
    };
    const examGroupWiseFilteredDataForOdontogram = (key: any) => {
        return getData()?.filter((f: examConstantInterface) => f?.examGroup === key)?.filter((ff: any) => ff?.displayOdontogramForm === true);
    }

    const examTabWiseFilteredData = (key: any) => {
        return getData()?.filter((f: examConstantInterface) => f?.findingTypes?.includes(key));
    };
    const createStructureForCurrentExam = () => {
        let dataForReducer = {} as any
        // get exam groups
        let examGroups = getData()?.map((res: any) => res?.examGroup) as any
        // remove duplicate exam groups and assign exam key to dataForReducer
        examGroups?.filter((item: any, index: number) => examGroups?.indexOf(item) === index)?.map((examKey: string) => {
            dataForReducer[examKey] = {}
            return true
        })
        // addExistingFindingData
        Object.keys(dataForReducer)?.map((examKey: string) => {
            let currentExamValues = {} as any
            examGroupWiseFilteredData(examKey)?.map((res: any) => {
                if (res?.isMulti && !isPhotoXray) {
                    const findingOptions = {} as any
                    res?.options?.map((option: any) => {
                        findingOptions[option?.key] = null
                        return true
                    })
                    if (examDataFromAPI && examDataFromAPI[examKey] && (((isArray(examDataFromAPI[examKey][res?.key]) && examDataFromAPI[examKey][res?.key].length > 0) || (!isArray(examDataFromAPI[examKey][res?.key]) && examDataFromAPI[examKey][res?.key])) || examDataFromAPI[examKey][res?.key] === false)) {
                        //Set Data from an API
                        currentExamValues[res?.key] = examDataFromAPI && examDataFromAPI[examKey] && examDataFromAPI[examKey][res?.key]
                    } else {
                        currentExamValues[res?.key] = [findingOptions] as any
                    }
                } else {
                    res?.options?.map((option: any) => {
                        if (examDataFromAPI && examDataFromAPI[examKey] && (examDataFromAPI[examKey][option?.key] || examDataFromAPI[examKey][option?.key] === false || examDataFromAPI[examKey][option?.key] === 0)) {
                            currentExamValues[option?.key] = examDataFromAPI && examDataFromAPI[examKey] && examDataFromAPI[examKey][option?.key]
                        } else {
                            currentExamValues[option?.key] = null
                        }
                        return true
                    })
                }
                return true
            })
            if (currentExamValues) {
                dataForReducer[examKey] = {...currentExamValues}
            }
            return true
        })

        if (isPhotoXray && annotationDataFromAPI) {
            // Make tag reducerData for Photo & Xray
            dispatch(setExamFindingsAction(annotationDataFromAPI?.map((res: any) => ({
                ...res,
                key: getUniqId()
            })) || []))
        } else {
            dispatch(setExamFindingsAction(dataForReducer))
        }
    }

    const hasTagForExam = (examName?: string) => {
        if (isPhotoXray && examFindingData && isArray(examFindingData))
            return examFindingData?.map((res: any) => examList[res?.title]?.key).includes(examName)
        return null
    }

    const hasFindingData = () => {
        return examGroupsData && examGroupsData?.filter((res: any) => examGroupWiseFilteredData(res?.key)?.length > 0)
    }
    const hasFindingDataForOdontogram = () => {
        return examGroupsData && examGroupsData?.filter((res: any) => examGroupWiseFilteredDataForOdontogram(res?.key)?.length > 0)
    }

    const hasTabFindingData = () => {
        return examTabName?.filter((res: any) => examTabWiseFilteredData(res?.key)?.length > 0)
    }

    useEffect(() => {
        dispatch(examFindingDataCountAction(hasFindingData().length))
    }, [hasFindingData()?.length])

    const handleDeleteFindings = () => {
        let updatedExamFindingData = examFindingData as any
        selectedFormFinding?.map((fin: any) => {
            if (fin?.exam_group && fin?.key) {
                if (fin?.key?.includes('missing_tooth')) {
                    const toothToBeDelete = selectedFormFinding?.filter((f: any) => f.key === fin.key).map((tn: any) => tn?.tooth_number) || [] as any
                    //Put the conditions' coz sometime toots founded as array and sometime as a string. (Need to be fixed)
                    if (isArray(updatedExamFindingData[fin.exam_group][fin.key])) {
                        updatedExamFindingData[fin.exam_group][fin.key] = updatedExamFindingData[fin.exam_group][fin.key].filter((f: string) => !toothToBeDelete?.includes(f))
                    } else {
                        updatedExamFindingData[fin.exam_group][fin.key] = updatedExamFindingData[fin.exam_group][fin.key]?.split(',').filter((f: string) => !toothToBeDelete?.includes(f))
                    }
                } else {
                    updatedExamFindingData[fin.exam_group][fin.key] = updatedExamFindingData[fin.exam_group][fin.key].filter((f: any) => Object.keys(f).filter((ff: string) => JSON.stringify(f[ff]) === JSON.stringify(fin[ff])).length !== Object.keys(f).length)
                }
            }
            return true
        })
        dispatch(setExamFindingsAction(updatedExamFindingData))
        dispatch(handleOdontogramFormFindingSelectionAction([]))
    }

    const isIconDisplay = () => {
        if (isWellnessProfile) {
            return !(wellnessProfileStep === softTissue || wellnessProfileStep === hardTissue);
        } else return !(findingType === medical && findingType === limitedExam);
    }

    return (
        <Accordion expanded={examAccordionExpanded} className={examAccordionExpanded ? '' : classes.accordionRoot}
                   sx={{bottom: showFooter && !examAccordionExpanded ? 80 : 0}}>
            <AccordionSummary
                expandIcon={
                    (isIconDisplay()) &&
                    <FontAwesomeIcon
                        className='cursor-pointer f-16'
                        onClick={handleExpanded}
                        icon={(examAccordionExpanded ? faMinus : faExpandAlt)}
                        color={theme.palette.common.white}
                    />
                }
                className={`${classes.accordionSummary} ${classes.expanded}`}
            >
                <Box className='d-flex justify-content-between align-center w-100' mr={2.5}>
                    <Typography className='f-18 f-w-500 fw-medium'>Findings</Typography>
                    <Box>
                        {
                            (selectedFormFinding?.length > 0 && (tabData === odontogram)) &&
                            <FontAwesomeIcon
                                icon={faTrash}
                                onClick={handleDeleteFindings}
                                className='cursor-pointer f-16 pr-10'
                            />
                        }
                        {(isIconDisplay()) &&
                            <FontAwesomeIcon icon={examFormSize ? faMinimize : faMaximize} onClick={handleFullSize}
                                             className='cursor-pointer f-16'/>}
                    </Box>
                </Box>
            </AccordionSummary>
            <AccordionDetails
                className={classes.accordionDetails}
                sx={{
                    height: isWellnessProfile ? `calc(100vh - 261px)` : showFooter ? `calc(100vh - ${openSpeedometerClinical ? '518px' : '366px'}) !important` : `calc(100vh - ${openSpeedometerClinical ? '436px' : '284px'}) !important`,
                    overflow: 'scroll  !important',
                }}
                ref={examFormRef}
            >
                {/*tabData === 4 is for Odontogram form only*/}
                {
                    (tabData === odontogram) ? <Box>
                        {hasFindingDataForOdontogram()?.length > 0 ?
                            ((hasFindingDataForOdontogram())?.map((res: examConstantInterface, i: number) => (
                                <React.Fragment key={i}>
                                    {<ExamGroup meta={res} data={examGroupWiseFilteredDataForOdontogram(res?.key)}/>}
                                </React.Fragment>
                            )))
                            :
                            <Typography display={'flex'} alignItems={'center'} justifyContent={'center'}
                                        className={'fw-regular'}
                                        fontSize={'18px'} mt={'20px'} mb={2}>
                                No findings found...
                            </Typography>}
                        <Divider/>
                        <CollapsibleBoxWithoutBorder title={"Charted Findings"}>
                            <ChartingForm/>
                        </CollapsibleBoxWithoutBorder>
                    </Box> : hasFindingData()?.length > 0 ?
                        (((tabData === limitedExam) ? hasTabFindingData() : hasFindingData())?.map((res: examConstantInterface, i: number) => (
                            <React.Fragment key={i}>
                                {/*IF it's photo or xray then check nested length*/}
                                {isPhotoXray && hasTagForExam(res?.key) &&
                                    <ExamGroup meta={res} data={examGroupWiseFilteredData(res?.key)}/>}
                                {(!isPhotoXray && tabData !== limitedExam) &&
                                    <ExamGroup meta={res} data={examGroupWiseFilteredData(res?.key)}/>}
                                {tabData === limitedExam &&
                                    <ExamGroup meta={res} data={examTabWiseFilteredData(res?.key)}/>}
                            </React.Fragment>
                        )))
                        :
                        <Typography display={'flex'} alignItems={'center'} justifyContent={'center'}
                                    className={'fw-regular'}
                                    fontSize={'18px'} mt={'20px'}>
                            No findings found...
                        </Typography>
                }
            </AccordionDetails>
        </Accordion>
    );
};

export default ExamForm;
