import {
  GET_PRE_INTAKE_REQUEST,
  GET_PRE_INTAKE_CACHE_DATA_CLEAR_REQUEST,
  GET_PRE_INTAKE_REQUEST_FAILURE,
  GET_PRE_INTAKE_REQUEST_SUCCESS,
  GET_SET_PRE_INTAKE_ERROR_REQUEST,
  preIntakeTypes,
} from "../../../ActionTypes/v1/preIntake";
import { preIntakeActions, preIntakeState } from "../../../types/patientTypes";

const initialState: preIntakeState = {
  pending: false,
  data: null,
  error: null,
  put_pre_intake_loader: false,
};

const preIntakeReducer = (state = initialState, action: preIntakeActions) => {
  switch (action.type) {
    case preIntakeTypes.PUT_PRE_INTAKE_REQUEST:
      return {
        ...state,
        put_pre_intake_loader: true,
      };
    case preIntakeTypes.PUT_PRE_INTAKE_REQUEST_SUCCESS:
      return {
        ...state,
        put_pre_intake_loader: false,
        data: action.payload,
        error: null,
      };
    case preIntakeTypes.PUT_PRE_INTAKE_REQUEST_FAILURE:
      return {
        ...state,
        put_pre_intake_loader: false,
        data: null,
        error: action.payload.error,
      };

    case GET_PRE_INTAKE_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case GET_PRE_INTAKE_CACHE_DATA_CLEAR_REQUEST:
      return {
        ...state,
        data: null
      }  
    case GET_PRE_INTAKE_REQUEST_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.payload,
        error: null,
      };
    case GET_PRE_INTAKE_REQUEST_FAILURE:
      return {
        ...state,
        pending: false,
        data: null,
        error: action.payload.error,
      };

    // case GET_SET_PRE_INTAKE_ERROR_REQUEST:
    //     return {
    //         ...state,
    //         pending: true
    //     }
    default:
      return {
        ...state,
      };
  }
};
export default preIntakeReducer;
