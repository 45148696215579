import {
    GET_PERIODONTAL_DENTIST_RECALL_EXAM_REQUEST,
    GET_PERIODONTAL_DENTIST_RECALL_EXAM_REQUEST_SUCCESS,
    GET_PERIODONTAL_DENTIST_RECALL_EXAM_REQUEST_FAILURE,
    PUT_PERIODONTAL_DENTIST_RECALL_EXAM_REQUEST,
  PUT_PERIODONTAL_DENTIST_RECALL_EXAM_REQUEST_SUCCESS,
  PUT_PERIODONTAL_DENTIST_RECALL_EXAM_REQUEST_FAILURE,
} from "../../../../ActionTypes/v1/dentist/clinicalExamDentistRecallExam"
import {PeriodontalDentistRecallFlowState,
    PeriodontalDentistRecallExamActions } from "../../../../types/dentistTypes"

const initialState: PeriodontalDentistRecallFlowState = {
    put_periodontal_dentist_recall_exam_loader: false,
    periodontal_dentist_recall_exam_loader: false,
    periodontal_dentist_recall_data:null,
    error: null
};    

const periodontalDentistRecallExamReducer = (
    state = initialState,
    action: PeriodontalDentistRecallExamActions
  ) => {
    switch (action.type) {
    
      case GET_PERIODONTAL_DENTIST_RECALL_EXAM_REQUEST:
        return {
          ...state,
          periodontal_dentist_recall_exam_loader: true,
        };
      case GET_PERIODONTAL_DENTIST_RECALL_EXAM_REQUEST_SUCCESS:
        return {
          ...state,
          periodontal_dentist_recall_exam_loader: false,
          periodontal_dentist_recall_data: action.payload,
          error: null,
        };
  
      case GET_PERIODONTAL_DENTIST_RECALL_EXAM_REQUEST_FAILURE:
        return {
          ...state,
          periodontal_dentist_recall_exam_loader: false,
          periodontal_dentist_recall_data: null,
          error: action.payload.error,
        };
        case PUT_PERIODONTAL_DENTIST_RECALL_EXAM_REQUEST:
      return {
        ...state,
        periodontal_dentist_recall_exam_loader: true,
      }
    case PUT_PERIODONTAL_DENTIST_RECALL_EXAM_REQUEST_SUCCESS:
      return {
        ...state,
        periodontal_dentist_recall_exam_loader: false,
        periodontal_dentist_recall_data: action.payload,
        error: null,
      }
    case PUT_PERIODONTAL_DENTIST_RECALL_EXAM_REQUEST_FAILURE:
      return {
        ...state,
        periodontal_dentist_recall_exam_loader: true,
        periodontal_dentist_recall_data: null,
        error: action.payload.error,
      };
      default:
        return {
          ...state,
        };
    }
  };
  export default periodontalDentistRecallExamReducer;