import React, {useMemo, useState} from "react";
import {Box, Button} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faSpinner, faXmark} from "@fortawesome/free-solid-svg-icons";
import {makeStyles} from "@mui/styles";
import {useDispatch} from "react-redux";
import {useLocation, useParams} from "react-router-dom";
import ApproveRejectPopup from "./ApproveRejectPopup";
import ConfirmationMessagePopUp from "./ConfirmationMessagePopUp";
import {patientProfileData} from "../../../../../utils/helper";
import {
    withoutAccountUpdatePatientWellnessProfileRequest
} from "../../../../../app/actions/v2/patient/WellnessProfileWithoutAccountAction";
import {updatePatientWellnessProfileRequest} from "../../../../../app/actions/v2/patient/patientWellnessProfileAction";

const useStyles = makeStyles((theme: any) => ({
    approveBtn: {
        background: theme.palette.common.white,
        borderRadius: '5px',
        marginRight: '24px',
        color: theme.palette.success.main,
        border: '1px solid',
        borderColor: theme.palette.success.main,
        "&:hover": {
            background: theme.palette.common.white,
            color: theme.palette.success.main,
            borderColor: theme.palette.success.main,
        },
        [theme.breakpoints.down(475)]: {
            marginRight: '0px',
        }
    },
    approvedBtn: {
        background: theme.palette.success.main,
        borderRadius: '5px',
        marginRight: '24px',
        color: theme.palette.common.white,
        borderColor: '',
        "&:hover": {
            background: theme.palette.success.main,
            color: '',
            borderColor: '',
        },
        [theme.breakpoints.down(475)]: {
            marginRight: '0px',
        }
    },
    pendingBtn: {
        background: theme.palette.common.white,
        borderRadius: '5px',
        marginRight: '24px',
        color: theme.palette.warning.main,
        borderColor: theme.palette.warning.main,
        border: '1px solid',
        "&:hover": {
            background: theme.palette.common.white,
            color: theme.palette.warning.main,
            borderColor: theme.palette.warning.main,
        },
        [theme.breakpoints.down(475)]: {
            marginRight: '0px',
        }
    },
    pendedBtn: {
        background: theme.palette.warning.main,
        borderRadius: '5px',
        marginRight: '24px',
        color: theme.palette.common.white,
        borderColor: '',
        "&:hover": {
            background: theme.palette.warning.main,
            color: '',
            borderColor: '',
        },
        [theme.breakpoints.down(475)]: {
            marginRight: '0px',
        }
    },
    rejectBtn: {
        background: theme.palette.common.white,
        borderRadius: '5px',
        marginRight: '24px',
        color: theme.palette.error.main,
        borderColor: theme.palette.error.main,
        border: '1px solid',
        "&:hover": {
            background: theme.palette.common.white,
            color: theme.palette.error.main,
            borderColor: theme.palette.error.main,
        },
        [theme.breakpoints.down(475)]: {
            marginRight: '0px',
        }
    },
    rejectedBtn: {
        background: theme.palette.error.main,
        borderRadius: '5px',
        marginRight: '24px',
        color: theme.palette.common.white,
        borderColor: '',
        "&:hover": {
            background: theme.palette.error.main,
            color: '',
            borderColor: '',
        },
        [theme.breakpoints.down(475)]: {
            marginRight: '0px',
        }
    },
    btnContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'end',
        height: `40px !important`,
        [theme.breakpoints.down(475)]: {
            // justifyContent: 'start',
            alignItems: 'normal',
            flexDirection: 'column',
            height: `auto !important`
        }
    },
    buttonMargin: {
        [theme.breakpoints.down(475)]: {
            marginBottom: '10px'
        }
    }
}));

type Props = {
    resData: any
    isAlternative: boolean
    treatmentData: any
}
const WellnessProfileTreatmentButtons = (props: Props) => {
    const {resData, isAlternative, treatmentData} = props;
    const classes = useStyles();
    const dispatch = useDispatch()
    const {patientId} = useParams()
    const location = useLocation()
    const [openPopup, setOpenPopup] = useState(false);
    const [openConfirmationPopUp, setOpenConfirmationPopUp] = React.useState(false)
    const [treatmentStatus, setTreatmentStatus] = useState('')
    const [treatmentDetails, setTreatmentDetails] = useState('')

    const withoutAccountWellnessProfile: boolean = useMemo(() => {
        return location?.pathname?.includes('/patient/without-account/wellness-profile/')
    }, [location.pathname]);

    const patient_id = withoutAccountWellnessProfile ? patientId : patientProfileData?.id

    const approveRejectTreatment = (status: string, resData: any) => {
        if (status === 'approved' || status === 'rejected') {
            setOpenPopup(true);
            setTreatmentStatus(status);
            setTreatmentDetails(resData)
        } else {
            const updateProfileRequest = {
                patient_id: patient_id,
                treatment_id: treatmentData?.treatment_id,
                treatment_details_id: resData?.treatment_details_id,
                callback: () => {
                    setOpenConfirmationPopUp(true)
                    setTreatmentStatus(status);
                    setTreatmentDetails(resData)
                }
            };
            if (isAlternative) {
                if (withoutAccountWellnessProfile) {
                    dispatch(withoutAccountUpdatePatientWellnessProfileRequest({
                        ...updateProfileRequest,
                        alternative_treatment_status: status
                    }));
                } else {
                    dispatch(updatePatientWellnessProfileRequest({
                        ...updateProfileRequest,
                        alternative_treatment_status: status
                    }));
                }
            } else {
                if (withoutAccountWellnessProfile) {
                    dispatch(withoutAccountUpdatePatientWellnessProfileRequest({
                        ...updateProfileRequest,
                        treatment_status: status
                    }));
                } else {
                    dispatch(updatePatientWellnessProfileRequest({
                        ...updateProfileRequest,
                        treatment_status: status
                    }));
                }
            }
        }
    };

    const continueHandler = () => {
        const updateProfileRequest = {
            patient_id: patient_id,
            treatment_id: treatmentData?.treatment_id,
            treatment_details_id: resData?.treatment_details_id,
            callback: () => {
                setOpenConfirmationPopUp(true)
                setOpenPopup(false)
            }
        };
        if (isAlternative) {
            if (withoutAccountWellnessProfile) {
                dispatch(withoutAccountUpdatePatientWellnessProfileRequest({
                    ...updateProfileRequest,
                    alternative_treatment_status: treatmentStatus
                }));
            } else {
                dispatch(updatePatientWellnessProfileRequest({
                    ...updateProfileRequest,
                    alternative_treatment_status: treatmentStatus
                }));
            }
        } else {
            if (withoutAccountWellnessProfile) {
                dispatch(withoutAccountUpdatePatientWellnessProfileRequest({
                    ...updateProfileRequest,
                    treatment_status: treatmentStatus
                }));
            } else {
                dispatch(updatePatientWellnessProfileRequest({
                    ...updateProfileRequest,
                    treatment_status: treatmentStatus
                }));
            }
        }
    }

    return (
        <React.Fragment>
            {!isAlternative ?
                <Box className={classes?.btnContainer}>
                    <React.Fragment>
                        <Button
                            startIcon={<FontAwesomeIcon icon={faCheck}/>}
                            className={`fw-regular f-w-400 ${classes.buttonMargin} ${resData?.treatment_status === "approved" ? classes.approvedBtn : classes.approveBtn}`}
                            variant={resData?.treatment_status === "approved" ? 'contained' : 'outlined'}
                            onClick={() => approveRejectTreatment("approved", resData)}
                        >
                            Approve
                        </Button>

                        <Button
                            startIcon={<FontAwesomeIcon icon={faSpinner}/>}
                            className={`fw-regular f-w-400 ${classes.buttonMargin} ${resData?.treatment_status === "pending" ? classes.pendedBtn : classes.pendingBtn}`}
                            variant={resData?.treatment_status === "pending" ? 'contained' : 'outlined'}
                            onClick={() => approveRejectTreatment("pending", resData)}
                        >
                            Pending
                        </Button>

                        <Button
                            startIcon={<FontAwesomeIcon icon={faXmark}/>}
                            className={`fw-regular f-w-400 ${resData?.treatment_status === "rejected" ? classes.rejectedBtn : classes.rejectBtn}`}
                            variant={resData?.treatment_status === "rejected" ? 'contained' : 'outlined'}
                            onClick={() => approveRejectTreatment("rejected", resData)}
                        >
                            Reconsider
                        </Button>
                    </React.Fragment>
                </Box> :
                <Box className={classes?.btnContainer}>
                    <React.Fragment>
                        <Button
                            startIcon={<FontAwesomeIcon icon={faCheck}/>}
                            className={`fw-regular f-w-400 ${classes.buttonMargin} ${resData?.alternative_treatment_status === "approved" ? classes.approvedBtn : classes.approveBtn}`}
                            variant={resData?.alternative_treatment_status === "approved" ? 'contained' : 'outlined'}
                            onClick={() => approveRejectTreatment("approved", resData)}
                        >
                            Approve
                        </Button>

                        <Button
                            startIcon={<FontAwesomeIcon icon={faSpinner}/>}
                            className={`fw-regular f-w-400 ${classes.buttonMargin} ${resData?.alternative_treatment_status === "pending" ? classes.pendedBtn : classes.pendingBtn}`}
                            variant={resData?.alternative_treatment_status === "pending" ? 'contained' : 'outlined'}
                            onClick={() => approveRejectTreatment("pending", resData)}
                        >
                            Pending
                        </Button>

                        <Button
                            startIcon={<FontAwesomeIcon icon={faXmark}/>}
                            className={`fw-regular f-w-400 ${resData?.alternative_treatment_status === "rejected" ? classes.rejectedBtn : classes.rejectBtn}`}
                            variant={resData?.alternative_treatment_status === "rejected" ? 'contained' : 'outlined'}
                            onClick={() => approveRejectTreatment("rejected", resData)}
                        >
                            Reconsider
                        </Button>
                    </React.Fragment>
                </Box>
            }
            <ConfirmationMessagePopUp
                handleClose={() => setOpenConfirmationPopUp(false)}
                treatmentStatus={treatmentStatus}
                openConfirmationPopUp={openConfirmationPopUp}
                treatmentDetails={treatmentDetails}
            />
            <ApproveRejectPopup
                open={openPopup}
                handleClose={() => setOpenPopup(false)}
                treatmentStatus={treatmentStatus}
                continueHandler={continueHandler}/>
        </React.Fragment>
    )
}
export default WellnessProfileTreatmentButtons;