import {
  UPDATE_APPOINTMENT_INFO,
  CHANGE_APPOINTMENT_INFO, UPDATE_APPOINTMENT_INFO_FAILURE
} from "../../../../ActionTypes/v1/dentist/clinicalExamAssistant";
import { AppointmentInfoAssistantInterface } from "../../../../types/dentistTypes";

const initialState: AppointmentInfoAssistantInterface = {
  data: {
    first_name: "",
    last_name: "",
    appointment_id: "",
    exam_date: "",
    appt_type: "NPE",
    inst_steri_cycle: null,
    patient_id: "",
    last_date_of_examination: "",
    recall_cycle_period: ""
  },
};

export const appointmentInfoReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case UPDATE_APPOINTMENT_INFO:
      return {
        ...state,
        data: action.payload,
      };

    case UPDATE_APPOINTMENT_INFO_FAILURE:
      return {
        ...state,
        data: {
          exam_date: "",
          inst_steri_cycle: null,
          last_date_of_examination: "",
          recall_cycle_period: ""
        }
      }

    case CHANGE_APPOINTMENT_INFO:
      const temp = state.data;
      const { key, value } = action.payload as any;
      temp[key] = value;
      return {
        ...state,
      };
    default:
      return { ...state };
  }
};
