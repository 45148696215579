import { all, put, takeEvery } from 'redux-saga/effects';
import API from '../../../../utils/api';
import { errorToaster } from '../../../../utils/helper';
import {
    getRiskCategoryDataSuccessAction,
    getRiskCategoryDataFailAction,
} from "../../../actions/v2/master/riskCategoriesActions";
import { GET_RISK_CATEGORY_DATA_REQUEST } from "../../../ActionTypes/v2/master/riskCategories";

function* getRiskCategoryDataSaga(payload: any): any {
    try {
        const response = yield API.get(`v2/treatment/risk/categories`);
        const data = response?.data?.detail
        //Set data in global redux to use for revert/discard changes (future usages)
        yield put(
            getRiskCategoryDataSuccessAction(data)
        );
    } catch (e: any) {
        errorToaster(e?.message)
        yield put(
            getRiskCategoryDataFailAction()
        );
    }
}

function* riskCategoryDataWatcher() {
    yield takeEvery(GET_RISK_CATEGORY_DATA_REQUEST, getRiskCategoryDataSaga);
}

export default function* riskCategorySaga() {
    yield all([riskCategoryDataWatcher()]);
}
