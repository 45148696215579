import {all, call, put, takeEvery} from "redux-saga/effects";
import API from "../../../../utils/api";
import {errorToaster, getLocalStorageItem, successToaster} from '../../../../utils/helper';
import {
    updatePatientWellnessProfileRequestFailure,
    updatePatientWellnessProfileRequestSuccess
} from "../../../actions/v2/patient/patientWellnessProfileAction";
import {UPDATE_PATIENT_WELLNESS_PROFILE_REQUEST} from "../../../ActionTypes/v2/patient/wellnessProfile";


//Edit patient wellness Profile
function* updatePatientWellnessProfileRequestSaga(payload: any): any {
    try {
        const response = yield API.put(`/v2/wellness-profile?clinic_id=${getLocalStorageItem('patientClinic')}`, payload.payload)
        yield put(
            updatePatientWellnessProfileRequestSuccess(response?.data?.detail)
        );
        if (payload.payload.callback) {
            yield call(payload.payload.callback, response.data?.detail);
        }
        successToaster(response?.data?.meta?.message);
    } catch (e: any) {
        errorToaster(e?.message)
        yield put(
            updatePatientWellnessProfileRequestFailure(e?.message)
        );
    }
}


function* updatePatientWellnessProfileWatch() {
    yield takeEvery(UPDATE_PATIENT_WELLNESS_PROFILE_REQUEST, updatePatientWellnessProfileRequestSaga);
}

export default function* patientWellnessProfileSaga() {
    yield all([
        updatePatientWellnessProfileWatch(),
    ]);
}
