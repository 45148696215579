import { occlusionDentistRecallInterface } from "../../../../types/dentistTypes";
import {
  UPDATE_OCCLUSION_DENTIST_RECALL_FLOW,
  UPDATE_OCCLUSION_DENTIST_RECALL_FLOW_FAILURE,
  CHANGE_OCCLUSION_DENTIST_RECALL_FLOW,
} from "../../../../ActionTypes/v1/dentist/clinicalExamDentist";
import {
  defaultCrowdingMandibular,
  defaultCrowdingMaxillary,
  defaultSpacingMaxillary,
  defaultSpacingMandibular,
} from "../../../../../utils/recallFlowOcclusionExamConstant";
import {
  defaultCacheCrowdingMandibular,
  defaultCacheCrowdingMaxillary,
  defaultCacheSpacingMandibular,
  defaultCacheSpacingMaxillary,
} from "../../../../../utils/cacheDataConstant/recallOcclusionConstant";
import { jsonParseAndStringfy } from "../../../../../utils/helper";

const initialState: occlusionDentistRecallInterface = {
  crowdingMaxillary: [defaultCrowdingMaxillary],
  crowdingMandibular: [defaultCrowdingMandibular],
  spacingMaxillary: [defaultSpacingMaxillary],
  spacingMandibular: [defaultSpacingMandibular],
  aligner_therapy: false,
  occlusion_wear_tmd_concern: false,
  wish_correct_appearance_smile: false,
  wish_whiter_smile: false,
  occlusion_comment: "",
};

export const occlusionDentistRecallFlowReducer = (
  state = initialState,
  action: any
) => {
  switch (action.type) {
    case UPDATE_OCCLUSION_DENTIST_RECALL_FLOW:
      return {
        ...state,
        ...action.payload,
      };
    case UPDATE_OCCLUSION_DENTIST_RECALL_FLOW_FAILURE:
      const newCrowdingMaxillaryData = jsonParseAndStringfy(
        defaultCacheCrowdingMaxillary
      );
      const newCrowdingMandibularData = jsonParseAndStringfy(
        defaultCacheCrowdingMandibular
      );
      const newSpacingMaxillaryData = jsonParseAndStringfy(
        defaultCacheSpacingMaxillary
      );
      const newSpacingMandibularData = jsonParseAndStringfy(
        defaultCacheSpacingMandibular
      );
      return {
        crowdingMaxillary: [newCrowdingMaxillaryData],
        crowdingMandibular: [newCrowdingMandibularData],
        spacingMaxillary: [newSpacingMaxillaryData],
        spacingMandibular: [newSpacingMandibularData],
        aligner_therapy: false,
        occlusion_wear_tmd_concern: false,
        wish_correct_appearance_smile: false,
        wish_whiter_smile: false,
        occlusion_comment: "",
      };
    case CHANGE_OCCLUSION_DENTIST_RECALL_FLOW:
      const { toFinding, value, type, index, key } = action.payload as any;
      const newState = state as any;
      let newFinding = newState[toFinding] as any;
      if (
        newFinding &&
        typeof newFinding !== "string" &&
        typeof newFinding !== "number" &&
        typeof newFinding !== "boolean"
      ) {
        newFinding[index] = newFinding[index]?.map((res: any) => {
          if (res.name === key) {
            return { ...res, value: value };
          }
          return res;
        });
      } else {
        newFinding = value;
      }
      return {
        ...state,
        [toFinding]: newFinding,
      };
    default:
      return { ...state };
  }
};
