import {
    DownloadPdfRequest,
    DownloadPdfRequestSuccess,
    DownloadPdfRequestFailure,
    DownloadPdfRequestPayload,
    DownloadPdfSuccessPayload,
    DownloadPdfFailurePayload
} from '../../../types/dentistTypes'
import {
    DOWNLOAD_PDF_REQUEST,
    DOWNLOAD_PDF_REQUEST_SUCCESS,
    DOWNLOAD_PDF_REQUEST_FAILURE,
} from '../../../ActionTypes/v1/downloadPdf'

export const downloadPdfRequest = (payload: DownloadPdfRequestPayload): DownloadPdfRequest => ({
    type: DOWNLOAD_PDF_REQUEST,
    payload,
});
export const downloadPdfRequestSuccess = (
    payload: DownloadPdfSuccessPayload
): DownloadPdfRequestSuccess => ({
    type: DOWNLOAD_PDF_REQUEST_SUCCESS,
    payload
});

export const downloadPdfRequestFailure = (
    payload: DownloadPdfFailurePayload
): DownloadPdfRequestFailure => ({
    type: DOWNLOAD_PDF_REQUEST_FAILURE,
    payload
});


