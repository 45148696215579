export const tmjSubType = [
    { label: "Neither", value: "Neither" },
    { label: "Left", value: "Left" },
    { label: "Right", value: "Right" },
    { label: "Both", value: "Both" },
  ];
export  const clickData = [
    {
      value: "",
      option: tmjSubType,
      type: "select",
      title: "Sub Type",
      name: "sub_type",
    },
    {
      value: "",
      name: "note",
      type: "string",
      title: "Notes",
    },
  ];
export const painData = [
    {
      value: "",
      option: tmjSubType,
      type: "select",
      title: "Sub Type",
      name: "sub_type",
    },
    {
      value: "",
      name: "note",
      type: "string",
      title: "Notes",
    },
  ];
export const crepitusData = [
    {
      value: "",
      option: tmjSubType,
      type: "select",
      title: "Sub Type",
      name: "sub_type",
    },
    {
      fieldName: "note",
      value: "",
      name: "note",
      type: "string",
      title: "Notes",
    },
  ];