
import { all, call, put, takeEvery } from "redux-saga/effects";
import API from "../../../../utils/api";
import {
  patientProfileRequestSuccess,
  patientProfileRequestFailure,
  patientProfileEditRequestFailure,
  checkPreIntakeRequestSuccess,
  checkPreIntakeRequestFailure,
  patientProfileCacheDataClearRequest,
  patientProfileEditRequestSuccess,
} from "../../../actions/v1/patient/patientProfileAction/patientProfileAction";
import {
  PATIENT_PROFILE_REQUEST,
  PATIENT_PROFILE_EDIT_REQUEST,
  CHECK_PRE_INTAKE_REQUEST,
} from "../../../ActionTypes/v1/patient/patientProfile";
import { errorToaster, successToaster } from '../../../../utils/helper';

//patient Profile Data
function* patientProfileRequestSaga(payload: any): any {
  yield put(patientProfileCacheDataClearRequest());
  try {
    const response = yield API.get(
      `/v2/user/patient/${payload.payload.patient_id}`
    );
    yield call(payload.payload.callback, response.data);
    yield put(
      patientProfileRequestSuccess({
        data: response.data?.detail,
      })
    );
  } catch (e: any) {
    //TODO: update toast according to status code
    //  errorToaster(e?.detail)
    yield put(patientProfileCacheDataClearRequest());
    yield put(
      patientProfileRequestFailure({
        error: e?.message,
      })
    );
  }
}

//Edit patient Profile
function* patientProfileEditRequestSaga(payload: any): any {
  try {
    const response = yield API.put(`/v2/user/patient`, payload.payload.profile_picture)
    if (response) {
      successToaster(response?.data?.meta?.message)
    }
    yield put(
      patientProfileEditRequestSuccess({
        data: response?.data?.detail
      })
    );
  } catch (e: any) {
    errorToaster(e?.message)
    yield put(
      patientProfileEditRequestFailure({
        error: e?.message
      })
    );
  }
}


//check PreIntake
function* checkPreIntakeRequestSaga(payload: any): any {
  try {
    const response = yield API.get(payload.payload.patientID ? `/appointments/preIntakeForm?patientID=${payload.payload.patientID}` : `/appointments/preIntakeForm`)
    yield call(payload.payload.callback, response?.data);
    yield put(
      checkPreIntakeRequestSuccess({
        isPreIntake: response?.data
      })
    );

  } catch (e: any) {
    yield put(
      checkPreIntakeRequestFailure({
        error: e?.detail
      })
    );
  }
}

function* getPatientProfileSaga() {
  yield takeEvery(PATIENT_PROFILE_REQUEST, patientProfileRequestSaga);
}

function* patientProfileEditSaga() {
  yield takeEvery(PATIENT_PROFILE_EDIT_REQUEST, patientProfileEditRequestSaga);
}

function* checkPreIntakeSaga() {
  yield takeEvery(CHECK_PRE_INTAKE_REQUEST, checkPreIntakeRequestSaga);
}

export default function* patientProfileSaga() {
  yield all([
    getPatientProfileSaga(),
    patientProfileEditSaga(),
    checkPreIntakeSaga(),
  ]);
}
