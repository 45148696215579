import {
  UPDATE_ASSISTANT_INTRAORAL,
  CHANGE_ASSISTANT_INTRAORAL_FINDING,
  ADD_ASSISTANT_INTRAORAL_FINDING_ROW, UPDATE_ASSISTANT_INTRAORAL_FAILURE
} from "../../../../ActionTypes/v1/dentist/clinicalExamAssistant";
import { IntraOralAssistantInterface } from "../../../../types/dentistTypes";

export const updateIntraOralRequest = (
  payload: IntraOralAssistantInterface
): IntraOralAssistantInterface =>
  ({
    type: UPDATE_ASSISTANT_INTRAORAL,
    payload,
  } as any);

export const updateIntraOralRequestFailure = (): any => ({
  type: UPDATE_ASSISTANT_INTRAORAL_FAILURE
})  as any

export const addIntraoralFindingRowAction = (payload: string): any =>
  ({
    type: ADD_ASSISTANT_INTRAORAL_FINDING_ROW,
    payload,
  } as any);

export const changeIntraOralFindingAction = (payload: any): any =>
  ({
    type: CHANGE_ASSISTANT_INTRAORAL_FINDING,
    payload,
  } as any);
