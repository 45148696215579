import {
  DENTIST_WELCOME_REQUEST,
  DENTIST_WELCOME_REQUEST_FAILURE,
  DENTIST_WELCOME_REQUEST_SUCCESS,
  TERMS_CONDITION_AND_PRIVACY_POLICY_REQUEST,
  TERMS_CONDITION_AND_PRIVACY_POLICY_REQUEST_SUCCESS,
  TERMS_CONDITION_AND_PRIVACY_POLICY_REQUEST_FAILURE
} from '../../../../ActionTypes/v1/dentist/auth';
import { authActions, authState } from '../../../../types/dentistTypes';

const initialState: authState = {
  pending: false,
  data: null,
  error: null,
  termsConditions: null,
  termsConditionsLoader: false
};

const authReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case DENTIST_WELCOME_REQUEST:
      return {
        ...state,
        pending: true
      };
    case DENTIST_WELCOME_REQUEST_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.payload,
        error: null
      };
    case DENTIST_WELCOME_REQUEST_FAILURE:
      return {
        ...state,
        pending: false,
        data: null,
        error: action.payload.error
      };
    case TERMS_CONDITION_AND_PRIVACY_POLICY_REQUEST:
      return {
        ...state,
        termsConditionsLoader: true
      };
    case TERMS_CONDITION_AND_PRIVACY_POLICY_REQUEST_SUCCESS:
      return {
        ...state,
        termsConditionsLoader: false,
        termsConditions: action.payload,
        error: null
      };
    case TERMS_CONDITION_AND_PRIVACY_POLICY_REQUEST_FAILURE:
      return {
        ...state,
        termsConditionsLoader: true
      };

    default:
      return {
        ...state
      };
  }
};
export default authReducer;
