import React from 'react';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import theme from '../../../theme';

const useStyles = makeStyles((theme: any) => ({}));

type Props = {
  variant?: string;
  className?: string;
  sx?: any;
  heading: string;
};

const Heading = (props: Props) => {
  const { heading, className, sx } = props;
  return (
    <Typography
      className={`fw-regular ${className}`}
      fontSize={'16px'}
      sx={{ lineHeight: '21px', mb: '15px', color: theme.palette.common.black50, ...sx }}
    >
      {heading}
    </Typography>
  );
};

export default Heading;
