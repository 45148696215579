import {
    UPDATE_INTRA_ORAL_DENTIST, UPDATE_INTRA_ORAL_DENTIST_FAILURE,
    CHANGE_INTRA_ORAL_DENTIST
} from "../../../../ActionTypes/v1/dentist/clinicalExamDentist";
import { intraOralDentistInterface } from "../../../../types/dentistTypes";

export const updateIntraOralDentistRequest = (
    payload: intraOralDentistInterface
): intraOralDentistInterface => ({
    type: UPDATE_INTRA_ORAL_DENTIST,
    payload,
}) as any;

export const updateIntraOralDentistRequestFailure = (): any => ({
    type: UPDATE_INTRA_ORAL_DENTIST_FAILURE, 
}) as any;


export const changeIntraOralFindingAction = (payload: any): any => ({
    type: CHANGE_INTRA_ORAL_DENTIST,
    payload
}) as any;