import React from 'react'
import { Box, Typography } from '@mui/material'

import OraQLogo from '../../../../assets/images/v2/logo.png'

const SessionExpire = () => {
    return (
        <Box
            height={'100vh'} padding={2.5}
            sx={{ overflowX: 'hidden', gap: '30px' }}
            className={`d-flex-all-center flex-column`}
        >
            <img src={OraQLogo} alt='OraQ' width={170} />
            <Typography
                textAlign={'center'} fontSize={20} lineHeight={1.5}
                className='fw-regular f-w-400 labelColor'
            >
                Oops! It looks like your session has expired. To continue, please scan the QR code again.
            </Typography>
        </Box>
    )
}

export default SessionExpire