import Crown11 from './crown/crown11.svg';
import Crown12 from './crown/crown12.svg';
import Crown13 from './crown/crown13.svg';
import Crown14 from './crown/crown14.svg';
import Crown15 from './crown/crown15.svg';
import Crown16 from './crown/crown16.svg';
import Crown17 from './crown/crown17.svg';
import Crown18 from './crown/crown18.svg';
import Crown21 from './crown/crown21.svg';
import Crown22 from './crown/crown22.svg';
import Crown23 from './crown/crown23.svg';
import Crown24 from './crown/crown24.svg';
import Crown25 from './crown/crown25.svg';
import Crown26 from './crown/crown26.svg';
import Crown27 from './crown/crown27.svg';
import Crown28 from './crown/crown28.svg';
import Crown31 from './crown/crown31.svg';
import Crown32 from './crown/crown32.svg';
import Crown33 from './crown/crown33.svg';
import Crown34 from './crown/crown34.svg';
import Crown35 from './crown/crown35.svg';
import Crown36 from './crown/crown36.svg';
import Crown37 from './crown/crown37.svg';
import Crown38 from './crown/crown38.svg';
import Crown41 from './crown/crown41.svg';
import Crown42 from './crown/crown42.svg';
import Crown43 from './crown/crown43.svg';
import Crown44 from './crown/crown44.svg';
import Crown45 from './crown/crown45.svg';
import Crown46 from './crown/crown46.svg';
import Crown47 from './crown/crown47.svg';
import Crown48 from './crown/crown48.svg';
import Root11 from './root/root11.svg';
import Root12 from './root/root12.svg';
import Root13 from './root/root13.svg';
import Root14 from './root/root14.svg';
import Root15 from './root/root15.svg';
import Root16 from './root/root16.svg';
import Root17 from './root/root17.svg';
import Root18 from './root/root18.svg';
import Root21 from './root/root21.svg';
import Root22 from './root/root22.svg';
import Root23 from './root/root23.svg';
import Root24 from './root/root24.svg';
import Root25 from './root/root25.svg';
import Root26 from './root/root26.svg';
import Root27 from './root/root27.svg';
import Root28 from './root/root28.svg';
import Root31 from './root/root31.svg';
import Root32 from './root/root32.svg';
import Root33 from './root/root33.svg';
import Root34 from './root/root34.svg';
import Root35 from './root/root35.svg';
import Root36 from './root/root36.svg';
import Root37 from './root/root37.svg';
import Root38 from './root/root38.svg';
import Root41 from './root/root41.svg';
import Root42 from './root/root42.svg';
import Root43 from './root/root43.svg';
import Root44 from './root/root44.svg';
import Root45 from './root/root45.svg';
import Root46 from './root/root46.svg';
import Root47 from './root/root47.svg';
import Root48 from './root/root48.svg';
import Lingual11 from './lingual/lingual11.svg';
import Lingual12 from './lingual/lingual12.svg';
import Lingual13 from './lingual/lingual13.svg';
import Lingual14 from './lingual/lingual14.svg';
import Lingual15 from './lingual/lingual15.svg';
import Lingual16 from './lingual/lingual16.svg';
import Lingual17 from './lingual/lingual17.svg';
import Lingual18 from './lingual/lingual18.svg';
import Lingual21 from './lingual/lingual21.svg';
import Lingual22 from './lingual/lingual22.svg';
import Lingual23 from './lingual/lingual23.svg';
import Lingual24 from './lingual/lingual24.svg';
import Lingual25 from './lingual/lingual24.svg';
import Lingual26 from './lingual/lingual26.svg';
import Lingual27 from './lingual/lingual27.svg';
import Lingual28 from './lingual/lingual28.svg';
import Lingual31 from './lingual/lingual31.svg';
import Lingual32 from './lingual/lingual32.svg';
import Lingual33 from './lingual/lingual33.svg';
import Lingual34 from './lingual/lingual34.svg';
import Lingual35 from './lingual/lingual35.svg';
import Lingual36 from './lingual/lingual36.svg';
import Lingual37 from './lingual/lingual37.svg';
import Lingual38 from './lingual/lingual38.svg';
import Lingual41 from './lingual/lingual41.svg';
import Lingual42 from './lingual/lingual42.svg';
import Lingual43 from './lingual/lingual43.svg';
import Lingual44 from './lingual/lingual44.svg';
import Lingual45 from './lingual/lingual45.svg';
import Lingual46 from './lingual/lingual46.svg';
import Lingual47 from './lingual/lingual47.svg';
import Lingual48 from './lingual/lingual48.svg';
import LingualRoot11 from './lingualRoot/lingualRoot11.svg';
import LingualRoot12 from './lingualRoot/lingualRoot12.svg';
import LingualRoot13 from './lingualRoot/lingualRoot13.svg';
import LingualRoot14 from './lingualRoot/lingualRoot14.svg';
import LingualRoot15 from './lingualRoot/lingualRoot15.svg';
import LingualRoot16 from './lingualRoot/lingualRoot16.svg';
import LingualRoot17 from './lingualRoot/lingualRoot17.svg';
import LingualRoot18 from './lingualRoot/lingualRoot18.svg';
import LingualRoot21 from './lingualRoot/lingualRoot21.svg';
import LingualRoot22 from './lingualRoot/lingualRoot22.svg';
import LingualRoot23 from './lingualRoot/lingualRoot23.svg';
import LingualRoot24 from './lingualRoot/lingualRoot24.svg';
import LingualRoot25 from './lingualRoot/lingualRoot25.svg';
import LingualRoot26 from './lingualRoot/lingualRoot26.svg';
import LingualRoot27 from './lingualRoot/lingualRoot27.svg';
import LingualRoot28 from './lingualRoot/lingualRoot28.svg';
import LingualRoot31 from './lingualRoot/lingualRoot31.svg';
import LingualRoot32 from './lingualRoot/lingualRoot32.svg';
import LingualRoot33 from './lingualRoot/lingualRoot33.svg';
import LingualRoot34 from './lingualRoot/lingualRoot34.svg';
import LingualRoot35 from './lingualRoot/lingualRoot35.svg';
import LingualRoot36 from './lingualRoot/lingualRoot36.svg';
import LingualRoot37 from './lingualRoot/lingualRoot37.svg';
import LingualRoot38 from './lingualRoot/lingualRoot38.svg';
import LingualRoot41 from './lingualRoot/lingualRoot41.svg';
import LingualRoot42 from './lingualRoot/lingualRoot42.svg';
import LingualRoot43 from './lingualRoot/lingualRoot43.svg';
import LingualRoot44 from './lingualRoot/lingualRoot44.svg';
import LingualRoot45 from './lingualRoot/lingualRoot45.svg';
import LingualRoot46 from './lingualRoot/lingualRoot46.svg';
import LingualRoot47 from './lingualRoot/lingualRoot47.svg';
import LingualRoot48 from './lingualRoot/lingualRoot48.svg';

export const toothImages = {
  Root11,
  Root12,
  Root13,
  Root14,
  Root15,
  Root16,
  Root17,
  Root18,
  Root21,
  Root22,
  Root23,
  Root24,
  Root25,
  Root26,
  Root27,
  Root28,
  Root31,
  Root32,
  Root33,
  Root34,
  Root35,
  Root36,
  Root37,
  Root38,
  Root41,
  Root42,
  Root43,
  Root44,
  Root45,
  Root46,
  Root47,
  Root48,
  Crown11,
  Crown12,
  Crown13,
  Crown14,
  Crown15,
  Crown16,
  Crown17,
  Crown18,
  Crown21,
  Crown22,
  Crown23,
  Crown24,
  Crown25,
  Crown26,
  Crown27,
  Crown28,
  Crown31,
  Crown32,
  Crown33,
  Crown34,
  Crown35,
  Crown36,
  Crown37,
  Crown38,
  Crown41,
  Crown42,
  Crown43,
  Crown44,
  Crown45,
  Crown46,
  Crown47,
  Crown48,
  Lingual11,
  Lingual12,
  Lingual13,
  Lingual14,
  Lingual15,
  Lingual16,
  Lingual17,
  Lingual18,
  Lingual21,
  Lingual22,
  Lingual23,
  Lingual24,
  Lingual25,
  Lingual26,
  Lingual27,
  Lingual28,
  Lingual31,
  Lingual32,
  Lingual33,
  Lingual34,
  Lingual35,
  Lingual36,
  Lingual37,
  Lingual38,
  Lingual41,
  Lingual42,
  Lingual43,
  Lingual44,
  Lingual45,
  Lingual46,
  Lingual47,
  Lingual48,
  LingualRoot11,
  LingualRoot12,
  LingualRoot13,
  LingualRoot14,
  LingualRoot15,
  LingualRoot16,
  LingualRoot17,
  LingualRoot18,
  LingualRoot21,
  LingualRoot22,
  LingualRoot23,
  LingualRoot24,
  LingualRoot25,
  LingualRoot26,
  LingualRoot27,
  LingualRoot28,
  LingualRoot31,
  LingualRoot32,
  LingualRoot33,
  LingualRoot34,
  LingualRoot35,
  LingualRoot36,
  LingualRoot37,
  LingualRoot38,
  LingualRoot41,
  LingualRoot42,
  LingualRoot43,
  LingualRoot44,
  LingualRoot45,
  LingualRoot46,
  LingualRoot47,
  LingualRoot48
} as any;
