
import API from "../../../../../../utils/api";
import { call, put, takeEvery, all } from "redux-saga/effects";
import {
  putTreatmentPlanRequestSuccess,
  putTreatmentPlanRequestFailure,
  getTreatmentPlanRequestSuccess,
  getTreatmentPlanRequestFailure,
  deleteTreatmentPlanFailure,
  deleteTreatmentPlanSuccess,
  updateTreatmentPlanCacheDataClearRequest,
  getTreatmentPlanCacheDataClearRequest
} from "../../../../../actions/v1/dentist/assignedTreatment/treatmentPlanActions/treatmentPlanAction";
import {
  GET_TREATMENT_PLAN_REQUEST,
  PUT_TREATMENT_PLAN_REQUEST,
  DELETE_TREATMENT_PLAN
} from "../../../../../ActionTypes/v1/dentist/treatmentPlan";
import { errorToaster, successToaster } from '../../../../../../utils/helper';

function* getTreatmentPlanRequestSaga(payload: any): any {
  yield put(updateTreatmentPlanCacheDataClearRequest())
  yield put(getTreatmentPlanCacheDataClearRequest())
  try {
    const response = yield API.get(`/exam/dentist/treatment/plan?appointment_id=${payload.payload.appointmentId}`);
    yield call(payload?.payload?.callback, response.data);
    if (payload?.payload?.callback) {
      payload?.payload?.callback(response?.data);
    }
    yield put(
      getTreatmentPlanRequestSuccess({
        treatmentPlanData: response,
      })
    );
  } catch (e: any) {
     //TODO: update toast according to status code
    //   errorToaster(e?.detail)
    yield put(updateTreatmentPlanCacheDataClearRequest())
    yield put(getTreatmentPlanCacheDataClearRequest())
    yield put(
      getTreatmentPlanRequestFailure({
        treatmentPlanError: e?.detail,
      })
    );
  }
}
function* putTreatmentPlanRequestSaga(payload: any): any {
  try {
    let newPayload = payload?.payload;
    newPayload.treatmentData = payload?.payload?.treatmentData?.map((r: any) => {
      delete r.elective
      delete r.urgent
      return r;
    })
    const response = yield API.put(`/exam/dentist/treatment/create?appointment_id=${payload?.payload?.appointmentId}`, newPayload);
    yield put(
      putTreatmentPlanRequestSuccess({
        treatmentPlan: response?.data,
      })
    );
    yield call(payload?.payload?.callback, response?.data);
    if (payload.payload.callback) {
      payload?.payload?.callback(response?.data);
    }
  } catch (e: any) {
    yield put(
      putTreatmentPlanRequestFailure({
        treatmentPlanError: e?.detail,
      })
    );
  }
}
function* deleteTreatmentPlanRequestSaga(payload: any): any {
  try {
    const response = yield API.delete(`/exam/dentist/treatment/delete?treatment_id=${payload.payload.treatment_id}`);
    if (response) {
      successToaster(response?.data?.detail)
    }
    yield put(
      deleteTreatmentPlanSuccess({
        deleteTreatment: response?.data,
      })
    );
  } catch (e: any) {
    errorToaster(e?.detail)
    yield put(
      deleteTreatmentPlanFailure({
        error: e?.detail,
      })
    );
  }
}

function* getTreatmentPlanSaga() {
  yield takeEvery(GET_TREATMENT_PLAN_REQUEST, getTreatmentPlanRequestSaga);
}

function* putTreatmentPlanSaga() {
  yield takeEvery(PUT_TREATMENT_PLAN_REQUEST, putTreatmentPlanRequestSaga);
}

function* deleteTreatmentPlanSaga() {
  yield takeEvery(DELETE_TREATMENT_PLAN, deleteTreatmentPlanRequestSaga);
}
export default function* rootSaga() {
  yield all([getTreatmentPlanSaga(), putTreatmentPlanSaga(), deleteTreatmentPlanSaga()]);
}
