export const UPDATE_PREAUTH_CHECKLIST_DATA = 'UPDATE_PREAUTH_CHECKLIST_DATA';

//get insurance data
export const GET_INSURANCE_DATA_REQUEST = 'GET_INSURANCE_DATA_REQUEST';
export const GET_INSURANCE_DATA_REQUEST_SUCCESS = 'GET_INSURANCE_DATA_REQUEST_SUCCESS';
export const GET_INSURANCE_DATA_REQUEST_FAILURE = 'GET_INSURANCE_DATA_REQUEST_FAILURE';

//get provider data
export const GET_PROVIDER_DROPDOWN_REQUEST = 'GET_PROVIDER_DROPDOWN_REQUEST';
export const GET_PROVIDER_DROPDOWN_REQUEST_SUCCESS = 'GET_PROVIDER_DROPDOWN_REQUEST_SUCCESS';
export const GET_PROVIDER_DROPDOWN_REQUEST_FAILURE = 'GET_PROVIDER_DROPDOWN_REQUEST_FAILURE';

export const PUT_INSURANCE_DATA_REQUEST = 'PUT_INSURANCE_DATA_REQUEST';
export const PUT_INSURANCE_DATA_REQUEST_SUCCESS = 'PUT_INSURANCE_DATA_REQUEST_SUCCESS';
export const PUT_INSURANCE_DATA_REQUEST_FAILURE = 'PUT_INSURANCE_DATA_REQUEST_FAILURE';

export const COPY_EXAM_NOTES_REQUEST = 'COPY_EXAM_NOTES_REQUEST';
export const COPY_EXAM_NOTES_REQUEST_SUCCESS = 'COPY_EXAM_NOTES_REQUEST_SUCCESS';
export const COPY_EXAM_NOTES_REQUEST_FAILURE = 'COPY_EXAM_NOTES_REQUEST_FAILURE';

export const UPDATE_HYGIENE_REGIME_DATA = 'UPDATE_HYGIENE_REGIME_DATA';

export const UPDATE_INSURANCE_TREATMENT_DATA = 'UPDATE_INSURANCE_TREATMENT_DATA';

export const UPDATE_INSURANCE_VALIDATIONS = 'UPDATE_INSURANCE_VALIDATIONS';
