import {Box, Typography} from '@mui/material'
import React from 'react'
import {makeStyles} from '@mui/styles'
import PopupComponent from "../../../../common/PopupComponent";

const useStyles = makeStyles((theme: any) => ({
    contentText: {
        fontSize: "16px",
        lineHeight: "20px",
        fontWeight: 400,
        color: theme.palette.common.black,
        textAlign: "center",
    },
    hyperLink: {
        color: theme.palette.common.black,
        textDecoration: "none"
    }
}));

type Props = {
    open: boolean
    handleClose: any
    appointmentData: any

}

const WellnessRescheduleAppointmentPopUp = (props: Props) => {
    const classes = useStyles()
    const {open, handleClose, appointmentData} = props
    return (
        <PopupComponent heading={'Reschedule Appointment'} width={'470px'} headerAction={true} footerAction={false} open={open} handleClose={handleClose}>
            <Box>
                <Typography className={classes.contentText}>
                    Please contact <i><b>{appointmentData?.clinicName}</b></i> to reschedule your appointment. Thank
                    you!
                </Typography>
                <Typography className={classes.contentText} mt={"15px"}>
                    Phone Number: <a className={classes.hyperLink}
                                     href={`tel:${appointmentData?.mobile?.replace("-", "")}`}>
                    <b><i>{appointmentData?.mobile || "N/A"}</i></b> </a>
                </Typography>
                <Typography mb={"20px"} className={classes.contentText} mt={"5px"}>
                    Email Address: <a className={classes.hyperLink}
                                      href={`mailto:${appointmentData?.contact_email}`}>
                    <b><i>{appointmentData?.contact_email || "N/A"}</i></b> </a>
                </Typography>
            </Box>
        </PopupComponent>
    )
}

export default WellnessRescheduleAppointmentPopUp