import { wearDentistInterface } from "../../../../types/dentistTypes";
import {
  UPDATE_WEAR_DENTIST,
  UPDATE_WEAR_DENTIST_FAILURE,
  CHANGE_WEAR_DENTIST,
} from "../../../../ActionTypes/v1/dentist/clinicalExamDentist";
import {
  WearFacetAppearanceData,
  WearFacetShapeData,
  WearFacetSharpnessData,
  GeneralWearPatternData,
} from "../../../../../utils/dentistWearExamConstant";
import {
  WearFacetAppearanceCacheData,
  WearFacetShapeCacheData,
  WearFacetSharpnessCacheData,
  GeneralWearPatternCacheData,
} from "../../../../../utils/cacheDataConstant/dentistWearExamConstant";
import { jsonParseAndStringfy } from "../../../../../utils/helper";

const initialState: wearDentistInterface = {
  wear_facet_appearance: WearFacetAppearanceData,
  wear_facet_shape: WearFacetShapeData,
  facet_sharpness: WearFacetSharpnessData,
  general_wear_pattern: GeneralWearPatternData,
  ant_wear_grade: "",
  post_wear_grade: "",
  length_21_mm: null,
  width_21_mm: null,
  wear_facet_comment: ''
};

export const wearDentistReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case UPDATE_WEAR_DENTIST:
      return {
        ...state,
        ...action.payload,
      };
    case UPDATE_WEAR_DENTIST_FAILURE:
      const newWearFacetAppearanceData = jsonParseAndStringfy(WearFacetAppearanceCacheData);
      const newWearFacetShapeData = jsonParseAndStringfy(WearFacetShapeCacheData);
      const newWearFacetSharpnessData = jsonParseAndStringfy(WearFacetSharpnessCacheData);
      const newGeneralWearPatternData = jsonParseAndStringfy(GeneralWearPatternCacheData);
      return {
        wear_facet_appearance: newWearFacetAppearanceData,
        wear_facet_shape: newWearFacetShapeData,
        facet_sharpness: newWearFacetSharpnessData,
        general_wear_pattern: newGeneralWearPatternData,
        ant_wear_grade: "",
        post_wear_grade: "",
        length_21_mm: null,
        width_21_mm: null,
        wear_facet_comment: ''
      };
    case CHANGE_WEAR_DENTIST:
      const { toFinding, value, key } = action.payload as any;
      const newState = state as any;
      let newFinding = newState[toFinding] as any;
      if (
        newFinding !== null &&
        typeof newFinding !== "string" &&
        typeof newFinding !== "number"
      ) {
        newFinding = newFinding?.map((res: any) => {
          if (res.name === key) {
            return { ...res, value: value };
          }
          return res;
        });
      } else {
        newFinding = value;
      }
      return {
        ...state,
        [toFinding]: newFinding,
      };
    default:
      return { ...state };
  }
};
