import { all, call, put, takeEvery } from 'redux-saga/effects';
import API from '../../../../utils/api';
import {
  getRiskProfileRequestSuccess,
  getRiskProfileRequestFailure,
  putRiskProfileRequestSuccess,
  putRiskProfileRequestFailure,
  getRiskProfileTreatmentRequestSuccess,
  getRiskProfileTreatmentRequestFailure,
  updateRiskProfileCacheDataClearRequest,
  getRiskProfileCacheDataClearRequest
} from '../../../actions/v1/patient/PatientRiskProfileAction/PatientRiskProfileAction';
import {
  GET_RISK_PROFILE_REQUEST,
  GET_RISK_PROFILE_TREATMENT_REQUEST,
  PUT_RISK_PROFILE_REQUEST
} from '../../../ActionTypes/v1/patient/riskProfile';
import { errorToaster, successToaster } from '../../../../utils/helper';

function* getRiskProfileRequestSaga(payload: any): any {
  yield put(getRiskProfileCacheDataClearRequest());
  yield put(updateRiskProfileCacheDataClearRequest());
  try {
    const response = yield API.get(
      payload.payload.appointmentID
        ? `v2/risk/profile/${payload.payload.appointmentID}`
        : `/v2/risk/profile/patient/${payload.payload.patient_id}`
    );
    if(payload.payload.callback){

      yield call(payload.payload.callback, response.data?.detail);
    }
    yield put(
      getRiskProfileRequestSuccess({
        data: response.data?.detail
      })
    );
  } catch (e:any) {
    //TODO: update toast according to status code
    // toast.error(e.detail);
    yield put(getRiskProfileCacheDataClearRequest());
    yield put(updateRiskProfileCacheDataClearRequest());
    yield put(
      getRiskProfileRequestFailure({
        error: e?.detail
      })
    );
  }
}

function* putRiskProfileRequestSaga(payload: any): any {
  try {
    const response = yield API.put('/riskProfile/reviewed', payload.payload.riskProfileCreate);
    yield call(payload.payload.callback, response.data);
    yield put(
      putRiskProfileRequestSuccess({
        riskProfileCreate: response.data
      })
    );
    successToaster('Submitted Successfully')
  } catch (e:any) {
    errorToaster(e?.detail)
    yield put(
      putRiskProfileRequestFailure({
        error: e?.detail
      })
    );
  }
}


function* getRiskProfileTreatmentRequestSaga(payload: any): any {
  try {
    //TODO: NEED TO CHANGE THE ENDPOINT URL AFTER DONE FROM BE SIDE
    const response = yield API.get(`/v2/risk/profile/patient/treatment/${payload.payload.patient_id}`);

    yield call(payload.payload.callback, response.data?.detail);
    yield put(
      getRiskProfileTreatmentRequestSuccess({
        data: response.data?.detail
      })
    );
  } catch (e:any) {
    //TODO: update toast according to status code
    // errorToaster(e?.detail);
    yield put(
      getRiskProfileTreatmentRequestFailure({
        error: e?.detail
      })
    );
  }
}

function* getPatientRiskProfileSaga() {
  yield takeEvery(GET_RISK_PROFILE_REQUEST, getRiskProfileRequestSaga);
}
function* putRiskProfileSaga() {
  yield takeEvery(PUT_RISK_PROFILE_REQUEST, putRiskProfileRequestSaga);
}


function* getPatientRiskProfileTreatmentSaga() {
  yield takeEvery(GET_RISK_PROFILE_TREATMENT_REQUEST, getRiskProfileTreatmentRequestSaga);
}

export default function* patientRiskProfileSaga() {
  yield all([
    getPatientRiskProfileSaga(),
    putRiskProfileSaga(),
    getPatientRiskProfileTreatmentSaga()
  ]);
}
