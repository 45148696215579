import { faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useState } from 'react'
import theme from '../../../../theme';


const useStyles = makeStyles((theme: any) => ({
  accordionRoot: {
    margin: '0 !important',
    '& .MuiAccordion-root': {
      margin: '0 !important'
    }
  },
  accordionSummary: {
    color: theme.palette.v2.primary.main,
    height: '42px',
    backgroundColor: theme.palette.v2.primary.primary25,
    padding: '10px 12px',
    border: `1px solid ${theme.palette.v2.primary.main}`
  },
  accordionDetails: {
    padding: '0px !important',
    backgroundColor: theme.palette.common.white
  },
  expanded: {
    '&$expanded': {
      minHeight: 42,
      maxHeight: 42,
    }
  },

}))

type Props = {
  title: string
  children?: any;
}

const CollapsibleBoxWithoutBorder = (props: Props) => {
  const { title } = props
  const classes = useStyles();
  const [expanded, setExpanded] = useState<boolean>(true) as any;

  return (
    <Accordion
      expanded={expanded}
      onChange={() => setExpanded(!expanded)}
      className={expanded ? classes.accordionRoot : ''}
    >
      <AccordionSummary
        expandIcon={
          <FontAwesomeIcon className='cursor-pointer f-16' icon={faAngleUp}
            color={theme.palette.v2.primary.main} />
        }
        className={`${classes.accordionSummary} ${classes.expanded}`}
      >        <Box className='d-flex justify-content-between align-center w-100' mr={2.5}>
          <Typography className='f-16 f-w-400 fw-regular'>{title}</Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>
        {props.children}
      </AccordionDetails>
    </Accordion>
  )
}

export default CollapsibleBoxWithoutBorder