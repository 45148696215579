import {
    GET_PERIODONTAL_DENTIST_RECALL_EXAM_REQUEST,
    GET_PERIODONTAL_DENTIST_RECALL_EXAM_REQUEST_SUCCESS,
    GET_PERIODONTAL_DENTIST_RECALL_EXAM_REQUEST_FAILURE,
    PUT_PERIODONTAL_DENTIST_RECALL_EXAM_REQUEST,
    PUT_PERIODONTAL_DENTIST_RECALL_EXAM_REQUEST_SUCCESS,
    PUT_PERIODONTAL_DENTIST_RECALL_EXAM_REQUEST_FAILURE,
} from "../../../../ActionTypes/v1/dentist/clinicalExamDentistRecallExam";

import {
    GetPeriodontalDentistRecallExamRequestPayload,
    GetPeriodontalDentistRecallExamRequestSuccessPayload,
    GetPeriodontalDentistRecallExamRequestFailurePayload,
    GetPeriodontalDentistRecallExamRequest,
    GetPeriodontalDentistRecallExamRequestSuccess,
    GetPeriodontalDentistRecallExamRequestFailure,

    PutPeriodontalDentistRecallExamRequest,
    PutPeriodontalDentistRecallExamRequestSuccess,
    PutPeriodontalDentistRecallExamRequestFailure,
    PutPeriodontalDentistRecallExamRequestPayload,
    PutPeriodontalDentistRecallExamRequestSuccessPayload,
    PutPeriodontalDentistRecallExamRequestFailurePayload
} from "../../../../types/dentistTypes";

export const getPeriodontalDentistRecallExamRequest = (payload: GetPeriodontalDentistRecallExamRequestPayload): GetPeriodontalDentistRecallExamRequest  => ({
    type: GET_PERIODONTAL_DENTIST_RECALL_EXAM_REQUEST,
    payload
}) as any

export const getPeriodontalDentistRecallExamDataRequestSuccess = (payload: GetPeriodontalDentistRecallExamRequestSuccessPayload): GetPeriodontalDentistRecallExamRequestSuccess => ({
    type: GET_PERIODONTAL_DENTIST_RECALL_EXAM_REQUEST_SUCCESS,
    payload
}) as any

export const getPeriodontalDentistRecallExamDataRequestFailure = (payload: GetPeriodontalDentistRecallExamRequestFailurePayload): GetPeriodontalDentistRecallExamRequestFailure => ({
    type: GET_PERIODONTAL_DENTIST_RECALL_EXAM_REQUEST_FAILURE,
    payload
}) as any

export const putPeriodontalDentistRecallExamRequest = (payload: PutPeriodontalDentistRecallExamRequestPayload): PutPeriodontalDentistRecallExamRequest  => ({
    type: PUT_PERIODONTAL_DENTIST_RECALL_EXAM_REQUEST,
    payload
}) as any

export const putPeriodontalDentistRecallExamDataRequestSuccess = (payload: PutPeriodontalDentistRecallExamRequestSuccessPayload): PutPeriodontalDentistRecallExamRequestSuccess => ({
    type: PUT_PERIODONTAL_DENTIST_RECALL_EXAM_REQUEST_SUCCESS,
    payload
}) as any

export const putPeriodontalDentistRecallExamDataRequestFailure = (payload: PutPeriodontalDentistRecallExamRequestFailurePayload): PutPeriodontalDentistRecallExamRequestFailure => ({
    type: PUT_PERIODONTAL_DENTIST_RECALL_EXAM_REQUEST_FAILURE,
    payload
}) as any