export const grade = [
  { label: "I", value: "I", isDisabled: false },
  { label: "II", value: "II", isDisabled: false },
  { label: "III", value: "III", isDisabled: false },
  { label: "IV", value: "IV", isDisabled: false },
];
export const tonsilGrade = [
  { label: "0", value: "0", isDisabled: false },
  { label: "1", value: "1", isDisabled: false },
  { label: "2", value: "2", isDisabled: false },
  { label: "3", value: "3", isDisabled: false },
  { label: "4", value: "4", isDisabled: false },
];
export const softPalateSubType = [
  { label: "WNL", value: "WNL" },
  { label: "elongated soft palate", value: "elongated soft palate" },
];
export const uvalasubType = [
  { label: "WNL", value: "WNL" },
  { label: "elongated uvula", value: "elongated uvula" },
];
export const defaultMpclass = [
  {
    name: "grade",
    value: "",
    option: grade,
    type: "select",
    title: "Grade",
    dataType: "string",
  },
];
export const defaultTonsilClass = [
  {
    name: "radio",
    value: false,
    type: "radio",
    title: "Present",
    dataType: "",
  },
  {
    name: "grade",
    value: "",
    type: "select",
    option: tonsilGrade,
    title: "Grade",
    dataType: "string",
  },
];
export const defaultTongueScalloping = [
  {
    name: "radio",
    value: false,
    type: "radio",
    title: "Present",
    dataType: "",
  },
  {
    name: "note",
    value: "",
    type: "string",
    title: "Notes",
    dataType: "string",
  },
];
export const defaultTongueTies = [
  {
    name: "radio",
    value: false,
    type: "radio",
    title: "Present",
    dataType: "",
  },
  {
    name: "note",
    value: "",
    type: "string",
    title: "Notes",
    dataType: "string",
  },
];
export const defaultSoftPalete = [
  {
    name: "select",
    value: "",
    option: softPalateSubType,
    type: "select",
    title: "Sub type",
    dataType: "string",
  },
];
export const defaultUvala = [
  {
    name: "select",
    value: "",
    option: uvalasubType,
    type: "select",
    title: "Sub type",
    dataType: "string",
  },
];
export const defaultMandTori = [
  {
    name: "mand_radio",
    value: false,
    type: "radio",
    title: "Mandibular Present",
    dataType: "",
  },
  {
    name: "mand_note",
    value: "",
    type: "string",
    title: "Mandibular Notes",
    dataType: "",
  },
  {
    name: "max_radio",
    value: false,
    type: "radio",
    title: "Maxillary Present",
    dataType: "",
  },
  {
    name: "max_note",
    value: "",
    type: "string",
    title: "Maxillary Notes",
    dataType: "",
  },
];
