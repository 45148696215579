import { FormControlLabel, Switch, Typography } from '@mui/material';
import { styled } from "@mui/material/styles";
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  handleShowRejectedTreatmentAction
} from "../../../../../../app/actions/v2/dentist/clinicalExam/odontogramAction";
import theme from "../../../../../../theme";

const Android12Switch = styled(Switch)(({ theme: any }) => ({
  padding: 8,
  '& .MuiSwitch-track': {
    borderRadius: 22 / 2,
    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 16,
      height: 16,
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    width: 16,
    height: 16,
    margin: 2,
  },
}));

const ShowRejectedTreatments = () => {
  const { palette } = theme
  const dispatch = useDispatch()
  const {
    odontogram: { showRejectedTreatmentsOnly },
  } = useSelector((state: any) => state) as any;

  useEffect(() => {
    dispatch(handleShowRejectedTreatmentAction(false))
  }, [])

  const handleShowRejectedTreatments = (e: any) => {
    const { checked } = e.target as any
    dispatch(handleShowRejectedTreatmentAction(checked))
  }

  return (
    <FormControlLabel
      control={<Android12Switch checked={showRejectedTreatmentsOnly} onChange={handleShowRejectedTreatments} />}
      color={palette.text.secondary}
      label={
        <Typography className='fw-regular'>
          Show rejected treatments only
        </Typography>
      }
    />
  );
};

export default ShowRejectedTreatments;
