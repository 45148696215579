
import { put, takeEvery, all } from "redux-saga/effects";
import API from "../../../../utils/api";
import { errorToaster, successToaster } from '../../../../utils/helper';
import { disclaimerFailure, disclaimerSuccess } from '../../../actions/v2/authAction/disclaimerAction';
import { DISCLAIMER_ACCEPT_REQUEST } from '../../../ActionTypes/v2/auth';

function* acceptDisclaimerRequestSaga(payload: any): any {
  try {
    const response = yield API.put(`/v2/auth/disclaimer/accepted`, payload?.payload?.payload)
    yield put(
      disclaimerSuccess(response?.data?.detail)
    );
    if (payload?.payload?.callback) {
      payload?.payload?.callback(response)
    }
    successToaster(response?.data?.meta?.message)
  } catch (e: any) {
    errorToaster(e?.message)
    yield put(
      disclaimerFailure(e?.message)
    );
  }
}

function* acceptDisclaimerWatch() {
  yield takeEvery(DISCLAIMER_ACCEPT_REQUEST, acceptDisclaimerRequestSaga);
}

export default function* disclaimerSaga() {
  yield all([
    acceptDisclaimerWatch()
  ])
}
