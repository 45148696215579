import {
    CONTINUE_PRE_INTAKE_HANDLER_REQUEST,
    GET_PRE_INTAKE_REQUEST,
    GET_PRE_INTAKE_CACHE_DATA_CLEAR_REQUEST,
    GET_PRE_INTAKE_REQUEST_FAILURE,
    GET_PRE_INTAKE_REQUEST_SUCCESS,

    GET_SET_PRE_INTAKE_ERROR_REQUEST,
    preIntakeTypes,
    UPDATE_PRE_INTAKE_ERROR_REQUEST,
} from "../../../ActionTypes/v1/preIntake";
import {
    PreIntakeRequest,
    PreIntakeRequestSuccess,
    PreIntakeRequestFailure,
    PreIntakeRequestPayload,
    PreIntakeRequestSuccessPayload,
    PreIntakeRequestFailurePayload,
    PostDataPreIntakeRequest,
    GetPreIntakeRequestPayload,
    GetPreIntakeRequest,
    
    GetPreIntakeRequestSuccess,
    GetPreIntakeRequestSuccessPayload,
    GetPreIntakeRequestFailure,
    GetPreIntakeRequestFailurePayload,

    GetSetPreIntakErrorRequest,
    GetSetPreIntakErrorRequestPayload,
    UpdatePreIntakErrorRequest,
    UpdatePreIntakErrorRequestPayload,
    ContinuePreIntakHandlerRequestPayload,
    ContinuePreIntakeHandlerRequest,
    PostDataPreIntakeFailureRequest
} from "../../../types/patientTypes";

export const preIntakeRequest = (payload: PreIntakeRequestPayload): PreIntakeRequest => ({
    type: preIntakeTypes.PUT_PRE_INTAKE_REQUEST,
    payload,
});

export const preIntakeRequestSuccess = (
    payload: PreIntakeRequestSuccessPayload
): PreIntakeRequestSuccess => ({
    type: preIntakeTypes.PUT_PRE_INTAKE_REQUEST_SUCCESS,
    payload
});

export const preIntakeRequestFailure = (
    payload: PreIntakeRequestFailurePayload
): PreIntakeRequestFailure => ({
    type: preIntakeTypes.PUT_PRE_INTAKE_REQUEST_FAILURE,
    payload
});


export const postPreIntakeDataRequest = (payload: any): PostDataPreIntakeRequest => ({
    type: preIntakeTypes.POST_PRE_INTAKE_DATA_REQUEST,
    payload
})
export const postPreIntakeDatafailureRequest = (): PostDataPreIntakeFailureRequest => ({
    type: preIntakeTypes.POST_PRE_INTAKE_DATA_REQUEST_FAILURE,
})

export const getPreIntakeRequest = (payload: GetPreIntakeRequestPayload): GetPreIntakeRequest => ({
    type: GET_PRE_INTAKE_REQUEST,
    payload,
});

export const getPreIntakeCacheDataClearRequest = (): any => ({
    type: GET_PRE_INTAKE_CACHE_DATA_CLEAR_REQUEST
})

export const getPreIntakeRequestSuccess = (
    payload: GetPreIntakeRequestSuccessPayload
): GetPreIntakeRequestSuccess => ({
    type: GET_PRE_INTAKE_REQUEST_SUCCESS,
    payload
});

export const getPreIntakeRequestFailure = (
    payload: GetPreIntakeRequestFailurePayload
): GetPreIntakeRequestFailure => ({
    type: GET_PRE_INTAKE_REQUEST_FAILURE,
    payload
});


export const getSetPreIntakeErrorRequest = (payload: GetSetPreIntakErrorRequestPayload): GetSetPreIntakErrorRequest => ({
    type: GET_SET_PRE_INTAKE_ERROR_REQUEST,
    payload
})

export const updatePreIntakeErrorRequest = (payload: UpdatePreIntakErrorRequestPayload): UpdatePreIntakErrorRequest => ({
    type: UPDATE_PRE_INTAKE_ERROR_REQUEST,
    payload
})

export const continuePreIntakeHandlerRequest = (payload: ContinuePreIntakHandlerRequestPayload): ContinuePreIntakeHandlerRequest => ({
    type: CONTINUE_PRE_INTAKE_HANDLER_REQUEST,
    payload
})


