import React from 'react'
import { Box } from '@mui/material'
import { useSelector } from 'react-redux'
import PatientInformation from '../../../dentist/preIntake/PatientInformation'
import EmergencyContactInformation from '../../../dentist/preIntake/EmergencyContactInformation'
import LetsGetToKnowYouBetter from '../../../dentist/preIntake/LetsGetToKnowYouBetter'
import GeneralHealth from '../../../dentist/preIntake/GeneralHealth'
import MedicalHealthHistory from '../../../dentist/preIntake/MedicalHealthHistory'
import DentalInformation from '../../../dentist/preIntake/DentalInformation'
import SleepQuality from '../../../dentist/preIntake/SleepQuality'
import CommunicationPreferences from '../../../dentist/preIntake/CommunicationPreferences'
import { RootState } from '../../../../../app/reducers/v2/rootReducer'
import FullScreenLoader from '../../../../v1/Common/FullScreenLoader'

const HealthHistoryWithoutAccPreIntake = () => {
    const {
        HealthHistoryWithoutAc: { getHealthHistoryWithoutAccountLoader, putHealthHistoryWithoutAccountLoader },
    } = useSelector((state: RootState) => state) as any;

    const infoForPatientInformation = 'Basic patient information is provided by your dental clinic, if information here is not accurate please let your clinic know.'

    return (
        <Box p={["20px"]} >
            {(getHealthHistoryWithoutAccountLoader || putHealthHistoryWithoutAccountLoader) ?
                <FullScreenLoader />
                :
                <form>
                    {/*Patient Information*/}
                    <PatientInformation info={infoForPatientInformation} />
                    {/*Emergency Contact Information*/}
                    <EmergencyContactInformation />
                    {/*Let’s get to know you better*/}
                    <LetsGetToKnowYouBetter />
                    {/*Dental Insurance Information - Hide until we have import/export support (7558, 7054,5245) */}
                    {/*           {convertToLowerCase(
            letsGetToKnowYouBetter?.doYouHaveDentalInsurance
          ) === "yes" && (
              <DentalInsuranceInformation />
            )} */}
                    {/*General Health*/}
                    <GeneralHealth />
                    {/*Medical Health History*/}
                    <MedicalHealthHistory />
                    {/*Dental Information*/}
                    <DentalInformation />
                    {/* Sleep Quality */}
                    <SleepQuality />
                    {/*Communication Preferences*/}
                    <CommunicationPreferences />
                </form>
            }
        </Box>
    )
}

export default HealthHistoryWithoutAccPreIntake