import { clinicalExamAssistantActions } from "../../../../types/dentistTypes";
import {
  POST_ADD_MULTIPLE_FINDING,
  POST_CLINICAL_EXAM_ASSISTANT_DATA_REQUEST,
  POST_DELETE_MULTIPLE_FINDING,
} from "../../../../ActionTypes/v1/dentist/clinicalExamAssistant";
import { FormatColorResetTwoTone } from "@mui/icons-material";

const initialState: any = {
  data: {
    firstName: "",
    lastName: "",
    appointment_id: "",
    exam_date: "",
    appt_type: "NPE",
    inst_steri_cycle: null,
    social_note: "",
    exam_consent: false,
    chief_complaint: "",
    pt_pref_notes: "",
    disc_profile_note: "",
    bruxism_clenching: "No",
    bruxism_clenching_other: "",
    curr_nightguard: "No",
    num_nightgaurd_lifetime: null,
    nightguard_use_per_week: null,
    hx_ortho_tx: false,
    hx_ortho_tx_other: "",
    ortho_tx_age: null,
    ortho_tx_type: "",
    intraOralExam: {
      mp_class_grade: "",
      tonsil_class_grade: "",
      tonsil_class_present: false,
      tongue_scalloping_presence: false,
      tongue_scalloping_notes: "",
      tongue_ties_presence: false,
      tongue_ties_notes: "",
      soft_palate_subtype: "",
      uvula_subtype: "",
      mand_tori_presence: false,
      concerns_sleep_quality: "",
      hx_snoring: "Yes",
      hx_sleep_test: "",
    },
    occlusionExam: {
      angle_class_right_cuspid: "",
      angle_class_left_cuspid: "",
      angle_class_right_molar: "",
      angle_class_left_molar: "",
      excur_lateral_right: [],
      excur_lateral_left: [],
      protru_guide_tooth_number: [],
      protru_guide_notes: "",
      max_midline_to_face_midline_subtype: "",
      max_midline_to_face_midline_mm: "",
      max_midline_to_face_midline_note: "",
      mand_midline_to_max_midline_subtype: "",
      mand_midline_to_max_midline_mm: "",
      mand_midline_to_max_midline_note: "",
      overbite_31_mm: "",
      overbite_31_note: "",
      overbite_percent_mm: "",
      overbite_percent_note: "",
      overjet_mm: "",
      overjet_subtype: "",
      overjet_note: "",
      crowding_max_grade: "",
      crowding_max_note: "",
      crowding_mand_grade: "",
      crowding_mand_note: "",
      spacing_max_grade: "",
      spacing_max_note: "",
      spacing_mand_grade: "",
      spacing_mand_note: "",
      ortho_occl_concern_present: false,
      ortho_occl_concern_notes: "",
      wisdom_teeth: "",
      occlusion_protrusive_comment: "",
      occusion_impacted_teeth_comment: "",
      occlusion_missing_extracted_teeth_comment: "",
      missing_tooth: [
        {
          missing_tooth_number: [],
          missing_tooth_notes: "",
        },
      ],
      impacted_tooth: [
        {
          impacted_tooth_number: [],
          impacted_tooth_subtype: "",
        },
      ],
    },
    existingTreatmentExam: {
      decay_comment: "",
      denture_comment: "",
      inlay_comment: "",
      veneer_comment: "",
      bridge_pontic_comment: "",
      bridge_abutment_comment: "",
      implant_comment: "",
      contour_comment: "",
      open_margin_comment: "",
      overhang_comment: "",
      open_contact_comment: "",
      sct_comment: "",
      watch_comment: "",
      filling_comment: "",
      crown_comment: "",
      onlay_comment: "",
      number_teeth_restorations: null,
      odontogram: "",
      conditionDecay: [
        {
          decay_tooth_number: "",
          decay_tooth_surface: "",
          decay_grade: "",
          decay_note: "",
        },
      ],
      denture: [
        {
          denture_tooth_number: [],
          denture_subtype: [],
        },
      ],
      indirectRestoInlay: [
        {
          inlay_tooth_number: [],
          inlay_tooth_surface: [],
          inlay_tooth_subtype: [],
        },
      ],
      veneer: [
        {
          veneer_tooth_number: "",
          veneer_tooth_surface: "",
          veneer_sub_type: "",
        },
      ],
      bridgePontic: [
        {
          pontic_tooth_number: "",
          pontic_tooth_subtype: "",
        },
      ],
      bridgeAbutment: [
        {
          abutment_tooth_number: "",
          abutment_tooth_surface: "",
          abutment_tooth_subtype: "",
        },
      ],
      indirectRestoImplant: [
        {
          implant_tooth_number: "",
          implant_tooth_subtype: "",
        },
      ],
      contour: [
        {
          contour_tooth_number: "",
          contour_tooth_surface: "",
          contour_subtype: "",
        },
      ],
      defectiveRestoOpenMargin: [
        {
          open_margin_tooth_number: "",
          open_margin_tooth_surface: "",
        },
      ],
      defectiveRestoOverHang: [
        {
          overhang_tooth_number: "",
          overhang_tooth_surface: "",
        },
      ],
      conditionOpenContact: [
        {
          open_contact_tooth_number: "",
          open_contact_tooth_surface: "",
          open_contact_mm: "",
        },
      ],
      existingSCT: [
        {
          sct_tooth_number: "",
        },
      ],
      watch: [
        {
          watch_tooth_number: "",
          watch_tooth_surface: "",
          watch_tooth_note: "",
        },
      ],
      filling: [
        {
          filling_tooth_number: "",
          filling_tooth_surface: "",
          filling_subtype: "",
        },
      ],
      crown: [
        {
          crown_tooth_number: "",
          crown_sub_type: "",
          crown_tooth_surface: "",
        },
      ],
      onlay: [
        {
          onlay_tooth_number: "",
          onlay_sub_type: "",
          onlay_tooth_surface: "",
          onlay_note: "",
        },
      ],
    },
    decay_selected: false,
    denture_selected: false,
    inlay_selected: false,
    veneer_selected: false,
    bridge_pontic_selected: false,
    bridge_abutment_selected: false,
    implant_selected: false,
    contour_selected: false,
    open_margin_selected: false,
    overhang_selected: false,
    open_contact_selected: false,
    sct_selected: false,
    watch_selected: false,
    filling_selected: false,
    crown_selected: false,
    onlay_selected: false,
  },
};

export const postClinicalExamAssistantDataReducer = (
  state = initialState,
  action: clinicalExamAssistantActions
) => {
  switch (action.type) {
    case POST_CLINICAL_EXAM_ASSISTANT_DATA_REQUEST:
      const temp = state.data;
      if (action?.payload?.mainType) {
        if (action.payload.finding) {
          temp[action.payload.mainType][action.payload.finding][
            action.payload.index
          ][action.payload.key] = action.payload.value;
        } else {
          temp[action.payload.mainType][action.payload.key] =
            action.payload.value;
        }
      } else {
        temp[action.payload.key] = action.payload.value;
      }
      return {
        ...state,
        data: temp,
      };
    case POST_ADD_MULTIPLE_FINDING:
      const tempData = state.data;
      const temps = action.payload.finding;
      if (action.payload.object) {
        tempData[action.payload.mainType][action.payload.finding].push(
          action.payload.object
        );
      }
      return {
        ...state,
        data: tempData,
      };

    case POST_DELETE_MULTIPLE_FINDING:
      const tempDelete = state.data;
      tempDelete[action.payload.mainType][action.payload.finding] = tempDelete[action.payload.mainType][action.payload.finding].filter((f: any, i: number) => i!== action.payload.index)
      return {
        ...state,
        data: tempDelete,
      };

    default:
      return { ...state };
  }
};
