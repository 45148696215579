import {fork} from 'redux-saga/effects';
import patientAuthSaga from './patientSaga/authSaga/authSaga';
import dentistAuthSaga from './dentistSaga/authSaga/authSaga';
import preIntakeSaga from './preIntakeSaga/preIntakeSaga';
import dentistPreIntakeSaga from './preIntakeSaga/dentistPreIntakeSaga';
import patientProfileSaga from './patientSaga/patientProfileSaga';
import AppointmentSaga from './patientSaga/patientAppointmentSaga';
import patientRiskProfileSaga from './patientSaga/patientRiskProfileSaga';
import recentXrayRequestSaga from './dentistSaga/clinicalExamDentistSaga/recentXraySaga';
import clinicSaga from './dentistSaga/clinicSaga/clinicSaga';
import clinicalExamAssistant from './dentistSaga/clinicalExamAssistantSaga/clinicalExamAssistantSaga';
import radioGraphicAnalysisAndFindingSaga
  from './dentistSaga/clinicalExamDentistSaga/radiographicAnalysisAndFindingSaga';
import staffProfileSaga from './dentistSaga/staffProfileSaga/staffProfileSaga';
import otpSaga from './otpSaga/otpSaga';
import udateAppointmentStepSaga from './dentistSaga/clinicalExamSaga/clinicalExamSaga';
import clinicalExamDentistSaga from './dentistSaga/clinicalExamDentistSaga/clinicalExamDentistSaga';
import keyFindingSaga from './dentistSaga/AssignedTreatmentSaga/keyFindingSaga/keyFindingSaga';
import insightRecommendationSaga
  from './dentistSaga/AssignedTreatmentSaga/insightRecommendationSaga/insightAndRecommendationSaga';
import treatmentPlanSaga from './dentistSaga/AssignedTreatmentSaga/treatmentPlanSaga/treatmentPlanSaga';
import readinessBarSaga from './dentistSaga/AssignedTreatmentSaga/readinessBarSaga/readinessBarSaga';
import reviewTreatmentPlansSaga
  from './dentistSaga/AssignedTreatmentSaga/reviewTreatmentPlansSaga/reviewTreatmentPlansSaga';
import insuranceApprovalSaga from './dentistSaga/insuranceApprovalSaga/insuranceApprovalSaga';
import downloadPdfSaga from './downloadPdfSaga/downloadPdfSaga';
import intraOralRecallExamSaga from './dentistSaga/clinicalExamDentistRecallFlowSaga/intraOralDentistRecallExamSaga';
import occlusionRecallExamSaga from './dentistSaga/clinicalExamDentistRecallFlowSaga/occlusionDentistRecallExamSaga';
import periodontalRecallExamSaga
  from './dentistSaga/clinicalExamDentistRecallFlowSaga/periodontalDentistRecallExamSaga';
import dashboardSaga from '../v2/dentist/dashboardSaga';

export const v1Sagas = [
  fork(patientAuthSaga),
  fork(dentistAuthSaga),
  fork(preIntakeSaga),
  fork(patientProfileSaga),
  fork(AppointmentSaga),
  fork(patientRiskProfileSaga),
  fork(dentistPreIntakeSaga),
  fork(clinicalExamAssistant),
  fork(recentXrayRequestSaga),
  fork(clinicSaga),
  fork(radioGraphicAnalysisAndFindingSaga),
  fork(staffProfileSaga),
  fork(otpSaga),
  fork(clinicalExamDentistSaga),
  fork(udateAppointmentStepSaga),
  fork(keyFindingSaga),
  fork(insightRecommendationSaga),
  fork(treatmentPlanSaga),
  fork(readinessBarSaga),
  fork(reviewTreatmentPlansSaga),
  fork(insuranceApprovalSaga),
  fork(downloadPdfSaga),
  fork(intraOralRecallExamSaga),
  fork(occlusionRecallExamSaga),
  fork(periodontalRecallExamSaga),
  fork(dashboardSaga),
]
