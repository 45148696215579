import {
  RECENT_XRAY_REQUEST,
  RECENT_XRAY_CACHE_DATA_CLEAR_REQUEST,
  RECENT_XRAY_REQUEST_SUCCESS,
  RECENT_XRAY_REQUEST_FAILURE,
} from "../../../../ActionTypes/v1/dentist/clinicalExamDentist";
import {
  GetRecentXrayRequest,
  GetRecentXrayRequestSuccess,
  GetRecentXrayRequestFailure,
  GetRecentXrayRequestPayload,
  GetRecentXrayRequesttSuccessPayload,
  GetRecentXrayRequestFailurePayload,
} from "../../../../types/dentistTypes";

//Recent Xray List
export const getRecentXrayRequest = (
  payload: GetRecentXrayRequestPayload
): GetRecentXrayRequest => ({
  type: RECENT_XRAY_REQUEST,
  payload,
});

export const getRecentXrayCacheDataClearRequest = (): any =>
  ({
    type: RECENT_XRAY_CACHE_DATA_CLEAR_REQUEST,
  } as any);

export const getRecentXrayRequestSuccess = (
  payload: GetRecentXrayRequesttSuccessPayload
): GetRecentXrayRequestSuccess => ({
  type: RECENT_XRAY_REQUEST_SUCCESS,
  payload,
});

export const getRecentXrayRequestFailure = (
  payload: GetRecentXrayRequestFailurePayload
): GetRecentXrayRequestFailure => ({
  type: RECENT_XRAY_REQUEST_FAILURE,
  payload,
});
