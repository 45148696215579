import { Box } from "@mui/system";
import React from "react";

const NoDataFound = (props: any) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: '100%'
      }}
      className='fw-regular f-w-400 labelColor'
    >
      {`No ${props?.errorMessage} found`}
    </Box>
  );
};

export default NoDataFound;
