import { call, put, takeEvery, all, select } from "redux-saga/effects";
import API from "../../../../../utils/api";
import {
    changePasswordRequestFailure,
    changePasswordRequestSuccess,
    loginRequestFailure,
    loginRequestSuccess,
    forgotPasswordRequestSuccess,
    forgotPasswordRequestFailure,
    recoverPasswordRequestSuccess, recoverPasswordRequestFailure, createPasswordRequestSuccess, createPasswordRequestFailure
} from "../../../../actions/v1/patient/authActions/authActions";
import {
    LOGIN_REQUEST,
    CHANGE_PASSWORD_REQUEST,
    FORGOT_PASSWORD_REQUEST,
    RECOVER_PASSWORD_REQUEST,
    CREATE_PASSWORD_REQUEST
} from "../../../../ActionTypes/v1/patient/auth";
import { errorToaster, getLocalStorageItem, successToaster } from '../../../../../utils/helper';
import { isMultipleAccountForgotPasswordPopUp } from "../../../../actions/v2/authAction/forgotPasswordAction";

// @ts-ignore
function* loginRequestSaga({ payload }: any): any {
    try {
        const state = yield select(); /*TODO: will make it with help of redux once things are live on production domain*/
        const response = yield API.post(`/v2/auth/login?is_owner=${payload?.payload?.isOwner}`, payload.payload, {
            headers: {
                device_token: getLocalStorageItem('dt') || ''
            }
        })
        yield call(payload?.callback, response?.data);
        yield put(
            loginRequestSuccess({
                data: response?.data
            })
        );
    } catch (e: any) {
        payload.errorCallback()
        errorToaster(e?.message)
        yield put(
            loginRequestFailure({
                error: e?.message
            })
        );
    }
}

function* changePasswordRequestSaga(payload: any): any {
    try {
        const response = yield API.put("/v2/auth/password", payload?.payload?.payload)
        yield call(payload?.payload?.callback, response.data);
        yield put(
            changePasswordRequestSuccess({
                data: response?.data?.detail,
            })
        );
        successToaster(response?.data?.meta?.message)
    } catch (e: any) {
        errorToaster(e?.message)
        yield put(
            changePasswordRequestFailure({
                error: e?.message
            })
        );
    }
}

function* forgotPasswordRequestSaga(payload: any): any {
    try {
        const response = yield API.post(`/v2/auth/forgot/password`, payload?.payload?.payload)
        yield call(payload?.payload?.callback, response);
        yield put(
            forgotPasswordRequestSuccess({
                data: response?.data?.detail,
            })
        );
        if (response) {
            successToaster(response?.data?.meta?.message)
        }
    } catch (e: any) {
        if (e?.data?.is_multiple_profile) {
            yield put(isMultipleAccountForgotPasswordPopUp(true))
        }
        if (e?.data == null) {
            errorToaster(e?.meta?.message)
        }
        yield put(
            forgotPasswordRequestFailure({
                error: e?.meta?.message
            })
        );
    }
}

function* recoverPasswordRequestSaga(payload: any): any {
    try {
        const response = yield API.post('/v2/auth/reset/password', payload?.payload?.payload)
        yield call(payload?.payload?.callback, response?.detail);
        yield put(
            recoverPasswordRequestSuccess({
                data: response?.data?.detail,
            })
        );
        successToaster(response?.data?.meta?.message)
    } catch (e: any) {
        errorToaster(e?.message)
        yield put(
            recoverPasswordRequestFailure({
                error: e?.message
            })
        );
    }
}


function* createPasswordRequestSaga(payload: any): any {
    try {
        const response = yield API.post(`/v2/auth/password`, payload?.payload?.payload)
        yield call(payload?.payload?.callback, response);
        yield put(
            createPasswordRequestSuccess({
                data: response?.data?.detail,
            })
        );
        if (response) {
            successToaster(response?.data?.meta?.message)
        }
    } catch (e: any) {
        errorToaster(e?.message)
        yield put(
            createPasswordRequestFailure({
                error: e?.message
            })
        );
    }
}

function* loginSaga() {
    yield takeEvery(LOGIN_REQUEST, loginRequestSaga);
}

function* changePasswordSaga() {
    yield takeEvery(CHANGE_PASSWORD_REQUEST, changePasswordRequestSaga);
}

function* forgotPasswordSaga() {
    yield takeEvery(FORGOT_PASSWORD_REQUEST, forgotPasswordRequestSaga);
}

function* recoverPasswordSaga() {
    yield takeEvery(RECOVER_PASSWORD_REQUEST, recoverPasswordRequestSaga);
}


function* createPasswordSaga() {
    yield takeEvery(CREATE_PASSWORD_REQUEST, createPasswordRequestSaga);
}

export default function* rootSaga() {
    yield all([
        loginSaga(),
        changePasswordSaga(),
        forgotPasswordSaga(),
        recoverPasswordSaga(),
        createPasswordSaga()
    ])
}