import { occlusionDentistInterface } from "../../../../types/dentistTypes";
import {
  UPDATE_OCCLUSION_DENTIST, UPDATE_OCCLUSION_DENTIST_FAILURE,
  CHANGE_OCCLUSION_DENTIST,
} from "../../../../ActionTypes/v1/dentist/clinicalExamDentist";
import {
  maximumComfortableOpeningData, maximumAssistedOpeningData,
  presentOfPainOnAssistedOpeningData, rangeOfMotionRightLateral,
  rangeOfMotionLeftLateral, rangeOfMotionProtrusiveLateral, shimbashiNumber, fremitus
} from "../../../../../utils/dentistOcclusionConstant";
import {
  maximumComfortableOpeningCacheData, maximumAssistedOpeningCacheData, presentOfPainOnAssistedOpeningCacheData, rangeOfMotionLeftLateralCache,
  rangeOfMotionProtrusiveLateralCache, rangeOfMotionRightLateralCache, shimbashiNumberCache, fremitusCache
} from "../../../../../utils/cacheDataConstant/dentistOcclusionConstant"
import { jsonParseAndStringfy } from "../../../../../utils/helper";

const initialState: occlusionDentistInterface = {
  maximum_comfortable_opening: maximumComfortableOpeningData,
  maximum_assisted_opening: maximumAssistedOpeningData,
  present_of_pain_on_assisted_opening: presentOfPainOnAssistedOpeningData,
  range_of_Motion_right_lateral: rangeOfMotionRightLateral,
  range_of_motion_left_lateral: rangeOfMotionLeftLateral,
  range_of_motion_protrusive_lateral: rangeOfMotionProtrusiveLateral,
  shimbashi_number: shimbashiNumber,
  fremitus: fremitus,
  occlusion_comment: ''
};

export const occlusionDentistReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case UPDATE_OCCLUSION_DENTIST:
      return {
        ...state,
        ...action.payload,
      };
    case UPDATE_OCCLUSION_DENTIST_FAILURE:
      const newMaximumComfortableOpeningData = jsonParseAndStringfy(maximumComfortableOpeningCacheData)
      const newMaximumAssistedOpeningData = jsonParseAndStringfy(maximumAssistedOpeningCacheData)
      const newPresentOfPainOnAssistedOpeningData = jsonParseAndStringfy(presentOfPainOnAssistedOpeningCacheData)
      const newRangeOfMotionLeftLateralData = jsonParseAndStringfy(rangeOfMotionLeftLateralCache)
      const newRangeOfMotionProtrusiveLateralData = jsonParseAndStringfy(rangeOfMotionProtrusiveLateralCache)
      const newRangeOfMotionRightLateralData = jsonParseAndStringfy(rangeOfMotionRightLateralCache)
      const newShimbashiNumberData = jsonParseAndStringfy(shimbashiNumberCache)
      const newFremitusData = jsonParseAndStringfy(fremitusCache)
      return {
        maximum_comfortable_opening: newMaximumComfortableOpeningData,
        maximum_assisted_opening: newMaximumAssistedOpeningData,
        present_of_pain_on_assisted_opening: newPresentOfPainOnAssistedOpeningData,
        range_of_Motion_right_lateral: newRangeOfMotionRightLateralData,
        range_of_motion_left_lateral: newRangeOfMotionLeftLateralData,
        range_of_motion_protrusive_lateral: newRangeOfMotionProtrusiveLateralData,
        shimbashi_number: newShimbashiNumberData,
        fremitus: newFremitusData,
        occlusion_comment: ''
      }
    case CHANGE_OCCLUSION_DENTIST:
      const { toFinding, value, key } = action.payload as any;
      const newState = state as any;
      let newFinding = newState[toFinding] as any;

      if (typeof newFinding !== "string" && typeof newFinding !== "number") {
        newFinding = newFinding?.map((res: any) => {
          if (res.name === key) {
            return { ...res, value: value };
          }
          return res;
        });
      } else {
        newFinding = value;
      }
      return {
        ...state,
        [toFinding]: newFinding,
      };
    default:
      return { ...state };
  }
};
