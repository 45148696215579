import { ExistingTreatmentDentistInterface } from "../../../../types/dentistTypes";
import {
  UPDATE_DENTIST_EXISTING_TREATMENT,
  UPDATE_DENTIST_EXISTING_TREATMENT_FAILURE,
  ADD_DENTIST_EXISTING_TREATMENT_FINDING_ROW,
  CHANGE_DENTIST_EXISTING_TREATMENT_FINDING,
  DELETE_DENTIST_EXISTING_TREATMENT_FINDING_ROW,
} from "../../../../ActionTypes/v1/dentist/clinicalExamDentist";
import {
  defaultConditionDecay,
  defaultDenture,
  defaultInlay,
  defaultVaneer,
  defaultBridgePontic,
  defaultBridgeAbutment,
  defaultImplant,
  defaultContour,
  defaultOpenMargin,
  defaultOverhang,
  defaultOpenContact,
  defaultSCT,
  defaultWatch,
  defaultFilling,
  defaultCrown,
  defaultOnlay,
  defaultRootCanal,
} from "../../../../../utils/dentistExistingTreatmentConstant";

const initialState: ExistingTreatmentDentistInterface = {
  conditionDecay: [],
  denture: [],
  indirectRestoInlay: [],
  veneer: [],
  bridgePontic: [],
  bridgeAbutment: [],
  indirectRestoImplant: [],
  contour: [],
  defectiveRestoOpenMargin: [],
  defectiveRestoOverHang: [],
  conditionOpenContact: [],
  existingSCT: [],
  watch: [],
  filling: [],
  crown: [],
  onlay: [],
  rootCanal: [],
  existing_comment: ''
};

export const existingTreatmentDentistReducer = (
  state = initialState,
  action: any
) => {
  switch (action.type) {
    case UPDATE_DENTIST_EXISTING_TREATMENT:
      return {
        ...state,
        ...action.payload,
      };
    case UPDATE_DENTIST_EXISTING_TREATMENT_FAILURE:
      return {
        conditionDecay: [],
        denture: [],
        indirectRestoInlay: [],
        veneer: [],
        bridgePontic: [],
        bridgeAbutment: [],
        indirectRestoImplant: [],
        contour: [],
        defectiveRestoOpenMargin: [],
        defectiveRestoOverHang: [],
        conditionOpenContact: [],
        existingSCT: [],
        watch: [],
        filling: [],
        crown: [],
        onlay: [],
        rootCanal: [],
        existing_comment: ''
      };
    case ADD_DENTIST_EXISTING_TREATMENT_FINDING_ROW:
      const finding = action.payload as string;
      const newStateAdd = state as any;
      const updatedFinding = newStateAdd[finding];
      if (finding === "conditionDecay") {
        updatedFinding.push(defaultConditionDecay);
      } else if (finding === "denture") {
        updatedFinding.push(defaultDenture);
      } else if (finding === "indirectRestoInlay") {
        updatedFinding.push(defaultInlay);
      } else if (finding === "veneer") {
        updatedFinding.push(defaultVaneer);
      } else if (finding === "bridgePontic") {
        updatedFinding.push(defaultBridgePontic);
      } else if (finding === "bridgeAbutment") {
        updatedFinding.push(defaultBridgeAbutment);
      } else if (finding === "indirectRestoImplant") {
        updatedFinding.push(defaultImplant);
      } else if (finding === "contour") {
        updatedFinding.push(defaultContour);
      } else if (finding === "defectiveRestoOpenMargin") {
        updatedFinding.push(defaultOpenMargin);
      } else if (finding === "defectiveRestoOverHang") {
        updatedFinding.push(defaultOverhang);
      } else if (finding === "conditionOpenContact") {
        updatedFinding.push(defaultOpenContact);
      } else if (finding === "existingSCT") {
        updatedFinding.push(defaultSCT);
      } else if (finding === "watch") {
        updatedFinding.push(defaultWatch);
      } else if (finding === "filling") {
        updatedFinding.push(defaultFilling);
      } else if (finding === "crown") {
        updatedFinding.push(defaultCrown);
      } else if (finding === "onlay") {
        updatedFinding.push(defaultOnlay);
      } else if (finding === "rootCanal") {
        updatedFinding.push(defaultRootCanal);
      }
      return {
        ...state,
        [finding]: updatedFinding,
      };
    case CHANGE_DENTIST_EXISTING_TREATMENT_FINDING:
      const { toFinding, value, type, index, key } = action.payload as any;
      const newState = state as any;
      let newFinding = newState[toFinding] as any;
      if (typeof newFinding !== "string") {
        newFinding[index] = newFinding[index]?.map((res: any) => {
          if (res.name === key) {
            return { ...res, value: value };
          }
          return res;
        });
      } else {
        newFinding = value;
      }
      return {
        ...state,
        [toFinding]: newFinding,
      };
    case DELETE_DENTIST_EXISTING_TREATMENT_FINDING_ROW:
      const {
        deletingFrom,
        deleteIndex,
        remove = false,
      } = action.payload as any;
      const newStateDelete = state as any;
      let findingDeletingFrom = newStateDelete[deletingFrom] as any;
      // @ts-ignore Ignoring
      if (!remove) {
        findingDeletingFrom = Object.values(
          Object.assign({}, findingDeletingFrom)
        ).filter((f: any, i: number) => i !== deleteIndex);
      }
      return {
        ...state,
        [deletingFrom]: remove ? [] : findingDeletingFrom,
      };
    default:
      return { ...state };
  }
};
