import { ExtraOralDentistInterface } from "../../../../types/dentistTypes";
import {
    UPDATE_EXTRA_ORAL_DENTIST, UPDATE_EXTRA_ORAL_DENTIST_FAILURE,
    CHANGE_EXTRA_ORAL_DENTIST
} from "../../../../ActionTypes/v1/dentist/clinicalExamDentist";
import { softTissueData, thyroidData, skeletalData } from "../../../../../utils/dentistExtraOralConstant";
import { softTissueCacheData, thyroidCacheData, skeletalCacheData } from "../../../../../utils/cacheDataConstant/dentistExtraOralConstant";
import { jsonParseAndStringfy } from "../../../../../utils/helper";

const initialState: ExtraOralDentistInterface = {
    extra_oral_comment: '',
    softTissue: softTissueData,
    thyroid: thyroidData,
    skeletal: skeletalData,
    bruxism_clenching: '',
    curr_nightguard: ''
}

export const extraOralDentistReducer = (
    state = initialState,
    action: any
) => {
    switch (action.type) {
        case UPDATE_EXTRA_ORAL_DENTIST:
            return {
                ...state,
                ...action.payload,
            };
        case UPDATE_EXTRA_ORAL_DENTIST_FAILURE:
            const newSoftTissue = jsonParseAndStringfy(softTissueCacheData)
            const newThyroidData = jsonParseAndStringfy(thyroidCacheData)
            const newSkeletalData = jsonParseAndStringfy(skeletalCacheData)
            return {
                softTissue: newSoftTissue,
                thyroid: newThyroidData,
                skeletal: newSkeletalData,
                extra_oral_comment: '',
                bruxism_clenching: '',
                curr_nightguard: ''
            }
        case CHANGE_EXTRA_ORAL_DENTIST:
            const { toFinding, value, key } = action.payload as any
            const newState = state as any;
            let newFinding = newState[toFinding] as any
            if (typeof newFinding !== 'string') {
                newFinding = newFinding?.map((res: any) => {
                    if (res.name === key) {
                        return { ...res, value: value }
                    }
                    return res
                })
            }
            else {
                newFinding = value
            }
            return {
                ...state,
                [toFinding]: newFinding
            };
        default:
            return { ...state }
    }
};
