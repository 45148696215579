import {
    GET_DENTIST_PRE_INTAKE_REQUEST,
    GET_DENTIST_PRE_INTAKE_REQUEST_SUCCESS,
    GET_DENTIST_PRE_INTAKE_REQUEST_FAILURE,
} from "../../../ActionTypes/v1/dentist/dentistPreIntake";
import {
    dentistPreIntakeActions, dentistPreIntakeState
} from "../../../types/dentistTypes";
import {
    PUT_DENTIST_PRE_INTAKE_REQUEST, PUT_DENTIST_PRE_INTAKE_REQUEST_FAILURE,
    PUT_DENTIST_PRE_INTAKE_REQUEST_SUCCESS
} from "../../../ActionTypes/v1/dentist/dentistPreIntake";

const initialState: dentistPreIntakeState = {
    pending: false,
    data: null,
    error: null
}

const dentistPreIntakeReducer = (state = initialState, action: dentistPreIntakeActions) => {
    switch (action.type) {
        case GET_DENTIST_PRE_INTAKE_REQUEST:
            return {
                ...state,
                pending: true
            }
        case GET_DENTIST_PRE_INTAKE_REQUEST_SUCCESS:
            return {
                ...state,
                pending: false,
                data: action.payload,
                error: null
            };
        case GET_DENTIST_PRE_INTAKE_REQUEST_FAILURE:
            return {
                ...state,
                pending: false,
                data: null,
                error: action.payload.error
            };

        case PUT_DENTIST_PRE_INTAKE_REQUEST:
            return {
                ...state,
                pending: true
            };
        case PUT_DENTIST_PRE_INTAKE_REQUEST_SUCCESS:
            return {
                ...state,
                pending: false,
                data: action.payload,
                error: null
            };
        case PUT_DENTIST_PRE_INTAKE_REQUEST_FAILURE:
            return {
                ...state,
                pending: false,
                data: null,
                error: action.payload.error
            };

        default:
            return {
                ...state
            };

    }
}

export default dentistPreIntakeReducer
