export const SELECT_ADDITIONAL_TREATMENT_DATA = 'SELECT_ADDITIONAL_TREATMENT_DATA';

export const SELECT_NEW_ADDITIONAL_TREATMENT_DATA = 'SELECT_NEW_ADDITIONAL_TREATMENT_DATA';

// get odontogram treatment
export const GET_ODONTOGRAM_TREATMENT_DATA_REQUEST = 'GET_ODONTOGRAM_TREATMENT_DATA_REQUEST';
export const GET_ODONTOGRAM_TREATMENT_DATA_SUCCESS = 'GET_ODONTOGRAM_TREATMENT_DATA_SUCCESS';
export const GET_ODONTOGRAM_TREATMENT_DATA_FAIL = 'GET_ODONTOGRAM_TREATMENT_DATA_FAIL';

//checkbox selected data
export const SELECTED_CHECKBOX_DATA = 'SELECTED_CHECKBOX_DATA';

export const OPEN_PROPOSED_TOOLTIP = 'OPEN_PROPOSED_TOOLTIP';

export const OPEN_ODONTOGRAM_TOOLTIP = 'OPEN_ODONTOGRAM_TOOLTIP';

export const UPDATE_ODONTOGRAM_TREATMENT_DATA_REQUEST = 'UPDATE_ODONTOGRAM_TREATMENT_DATA_REQUEST';

export const CAME_IMAGE_XRAY_FROM_TREATMENT = 'CAME_IMAGE_XRAY_FROM_TREATMENT';

export const MASTER_PROPOSED_DATA = 'MASTER_PROPOSED_DATA';

export const STORE_MULTIPLE_CODE = 'STORE_MULTIPLE_CODE';
