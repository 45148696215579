export const grade = [
  { label: "I", value: "I", isDisabled: false },
  { label: "II", value: "II", isDisabled: false },
  { label: "III", value: "III", isDisabled: false },
  { label: "IV", value: "IV", isDisabled: false },
];
export const tonsilGrade = [
  { label: "0", value: "0", isDisabled: false },
  { label: "1", value: "1", isDisabled: false },
  { label: "2", value: "2", isDisabled: false },
  { label: "3", value: "3", isDisabled: false },
  { label: "4", value: "4", isDisabled: false },
];
export const softPalateSubType = [
  { label: "WNL", value: "WNL" },
  { label: "elongated soft palate", value: "elongated soft palate" },
];
export const uvalasubType = [
  { label: "WNL", value: "WNL" },
  { label: "elongated uvula", value: "elongated uvula" },
];
export const salivaryFlowSubType = [
  { label: "Normal", value: "Normal" },
  { label: "Reduced Flow", value: "Reduced Flow" },
];
export const softTissueSubType = [
  {
    label: "WNL",
    value: "WNL",
    isDisabled: false,
  },
  {
    label: "Abnormality",
    value: "Abnormality",
    isDisabled: false,
  },
];
export const defaultCacheMpclass = [
  {
    name: "grade",
    value: "",
    option: grade,
    type: "select",
    title: "Grade",
    dataType: "string",
  },
];
export const defaultCacheTonsilClass = [
  {
    name: "radio",
    value: false,
    type: "radio",
    title: "Present",
    dataType: "",
  },
  {
    name: "grade",
    value: "",
    type: "select",
    option: tonsilGrade,
    title: "Grade",
    dataType: "string",
  },
];
export const defaultCacheTongueScalloping = [
  {
    name: "radio",
    value: false,
    type: "radio",
    title: "Present",
    dataType: "",
  },
  {
    name: "note",
    value: "",
    type: "string",
    title: "Note",
    dataType: "string",
  },
];
export const defaultCacheTongueTies = [
  {
    name: "radio",
    value: false,
    type: "radio",
    title: "Present",
    dataType: "",
  },
  {
    name: "note",
    value: "",
    type: "string",
    title: "Notes",
    dataType: "string",
  },
];
export const defaultCacheSoftPalete = [
  {
    name: "select",
    value: "",
    option: softPalateSubType,
    type: "select",
    title: "Sub type",
    dataType: "string",
  },
];
export const defaultCacheUvala = [
  {
    title: "Sub type",
    name: "sub_type",
    value: "",
    option: uvalasubType,
    type: "select",
    dataType: "string",
  },
];

export const defaultCacheSalivaryFlow = [
  {
    title: "Sub Type",
    value: "",
    name: "sub_type",
    type: "select",
    option: salivaryFlowSubType,
    dataType: "string",
  },
  {
    title: "Notes",
    value: "",
    name: "note",
    type: "string",
    dataType: "string",
  },
];
export const defaultCacheSoftTissueData = [
  {
    option: softTissueSubType,
    type: "select",
    title: "Sub Type",
    name: "sub_type",
    value: "",
  },
  {
    name: "tissue_notes",
    type: "string",
    title: "Notes",
    value: "",
  },
];
