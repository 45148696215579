

export const cancerSubType = [
    { label: "WNL", value: "WNL" },
    { label: "Referral Given", value: "Referral Given" },
  ];
  
  export const salivaryFlowSubType = [
    { label: "Normal", value: "Normal" },
    { label: "Reduced Flow", value: "Reduced Flow" },
  ];

  export const softTissueSubType = [
    {
        label: "WNL",
        value: "WNL",
        isDisabled: false,
    },
    {
        label: "Abnormality",
        value: "Abnormality",
        isDisabled: false,
    },
]
  
  export const cancerScreenCacheData = [
    {
      title: "Sub Type",
      value: "",
      name: "sub_type",
      type: "select",
      option: cancerSubType,
    },
    {
      title: "Notes",
      value: "",
      name: "note",
      type: "string",
    },
  ];
  export const salivaryFlowCahceData = [
    {
      title: "Sub Type",
      value: "",
      name: "sub_type",
      type: "select",
      option: salivaryFlowSubType,
    },
    {
      title: "Notes",
      value: "",
      name: "note",
      type: "string",
    },
  ];

  export const softTissueCacheData = [
    {
        option: softTissueSubType,
        type: "select",
        title: "Sub Type",
        name: "sub_type",
        value: "",
    },
    {
        name: "tissue_notes",
        type: "string",
        title: "Notes",
        value: ""
    },
];