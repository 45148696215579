import { otpActions, otpState } from "../../../types/patientTypes";
import {
    VERIFY_OTP_REQUEST,
    VERIFY_OTP_REQUEST_SUCCESS,
    VERIFY_OTP_REQUEST_FAILURE,
    SEND_OTP_REQUEST,
    SEND_OTP_REQUEST_SUCCESS,
    SEND_OTP_REQUEST_FAILURE,
} from '../../../ActionTypes/v1/otp'


const initialState: otpState = {
    pending: false,
    otpData: null,
    error: null,
    mobile: null
};

const otpReducer = (state = initialState, action: otpActions) => {
    switch (action.type) {

        case VERIFY_OTP_REQUEST:
            return {
                ...state,
                pending: true
            };

        case VERIFY_OTP_REQUEST_SUCCESS:
            return {
                ...state,
                pending: false,
                otpData: action?.payload,
                error: null
            };
        case VERIFY_OTP_REQUEST_FAILURE:
            return {
                ...state,
                pending: false,
                // otpData: null,
                error: action?.payload?.error
            };
        case SEND_OTP_REQUEST:
            return {
                ...state,
                pending: true
            };

        case SEND_OTP_REQUEST_SUCCESS:
            return {
                ...state,
                pending: false,
                mobile: action?.payload,
                error: null
            };
        case SEND_OTP_REQUEST_FAILURE:
            return {
                ...state,
                pending: false,
                mobile: null,
                // otpData: null,
                error: action?.payload?.error
            };
        default:
            return {
                ...state
            };
    }

}

export default otpReducer