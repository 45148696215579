import { toothNumberForFindings } from '../helper';

export const maximumComfortableOpeningCacheData = [
  {
    title: 'Dimensions (mm)',
    value: null,
    name: 'dimension',
    type: 'string',
    placeholder: 'mm',
    dataType: 'number'
  }
];
export const maximumAssistedOpeningCacheData = [
  {
    title: 'Dimensions (mm)',
    value: null,
    name: 'dimension',
    type: 'string',
    placeholder: 'mm',
    dataType: 'number'
  }
];
export const presentOfPainOnAssistedOpeningCacheData = [
  {
    title: 'Present',
    value: false,
    name: 'radio',
    type: 'radio'
  },
  {
    title: 'Notes',
    value: '',
    name: 'note',
    type: 'string'
  }
];
export const rangeOfMotionRightLateralCache = [
  {
    title: 'Dimensions (mm)',
    value: null,
    name: 'dimension',
    type: 'string',
    placeholder: 'mm',
    dataType: 'number'
  }
];
export const rangeOfMotionLeftLateralCache = [
  {
    title: 'Dimensions (mm)',
    value: null,
    name: 'dimension',
    type: 'string',
    placeholder: 'mm',
    dataType: 'number'
  }
];
export const rangeOfMotionProtrusiveLateralCache = [
  {
    title: 'Dimensions (mm)',
    value: null,
    name: 'dimension',
    type: 'string',
    placeholder: 'mm',
    dataType: 'number'
  }
];
export const shimbashiNumberCache = [
  {
    title: 'Dimensions (mm) - measure from 21/31',
    value: null,
    name: 'dimension',
    type: 'string',
    placeholder: 'mm',
    dataType: 'number'
  }
];
export const fremitusCache = [
  {
    title: 'Tooth Number',
    value: [],
    name: 'tooth_number',
    type: 'multi-select',
    option: toothNumberForFindings
  },
  {
    title: 'Present',
    value: false,
    name: 'radio',
    type: 'radio'
  },
  {
    title: 'Notes',
    value: '',
    name: 'note',
    type: 'string'
  }
];
