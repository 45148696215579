import {
  DENTIST_WELCOME_REQUEST,
  DENTIST_WELCOME_REQUEST_FAILURE,
  DENTIST_WELCOME_REQUEST_SUCCESS,
  TERMS_CONDITION_AND_PRIVACY_POLICY_REQUEST,
  TERMS_CONDITION_AND_PRIVACY_POLICY_REQUEST_SUCCESS,
  TERMS_CONDITION_AND_PRIVACY_POLICY_REQUEST_FAILURE
} from '../../../../ActionTypes/v1/dentist/auth';
import {
  WelcomeRequestPayload,
  WelcomeRequest,
  WelcomeRequestSuccess,
  WelcomeRequestSuccessPayload,
  WelcomeRequestFailure,
  WelcomeRequestFailurePayload,
  TermsConditionsRequestss,
  TermsConditionsRequestSucccess,
  TermsConditionsRequestFailure,
  TermsConditionsRequestPayload,
  TermsConditionsSuccessPayload,
  TermsConditionsFailurePayload
} from '../../../../types/dentistTypes';

export const welcomeRequest = (payload: WelcomeRequestPayload): WelcomeRequest => ({
  type: DENTIST_WELCOME_REQUEST,
  payload
});
export const welcomeRequestSuccess = (payload: WelcomeRequestSuccessPayload): WelcomeRequestSuccess => ({
  type: DENTIST_WELCOME_REQUEST_SUCCESS,
  payload
});

export const welcomeRequestFailure = (payload: WelcomeRequestFailurePayload): WelcomeRequestFailure => ({
  type: DENTIST_WELCOME_REQUEST_FAILURE,
  payload
});

export const termsConditionRequest = (payload: TermsConditionsRequestPayload): TermsConditionsRequestss => ({
  type: TERMS_CONDITION_AND_PRIVACY_POLICY_REQUEST,
  payload
});

export const termsConditionSuccess = (payload: TermsConditionsSuccessPayload): TermsConditionsRequestSucccess => ({
  type: TERMS_CONDITION_AND_PRIVACY_POLICY_REQUEST_SUCCESS,
  payload
});

export const termsConditionFailure = (payload: TermsConditionsFailurePayload): TermsConditionsRequestFailure => ({
  type: TERMS_CONDITION_AND_PRIVACY_POLICY_REQUEST_FAILURE,
  payload
});
