import {
  TO_REDIRECT_CLINICAL_EXAM_STEP,
  TO_REDIRECT_CLINICAL_EXAM_SUB_STEP,
  TO_HIDE_FOOTER_REQUEST,
  UPDATE_APPOINTMENT_STEP_REQUEST,
  UPDATE_APPOINTMENT_STEP_REQUEST_SUCCESS,
  UPDATE_APPOINTMENT_STEP_REQUEST_FAILURE,
  GET_APPOINTMENT_TYPE_REQUEST,
  GET_APPOINTMENT_TYPE_REQUEST_SUCCESS,
  GET_APPOINTMENT_TYPE_REQUEST_FAILURE,
  GET_CLINICAL_EXAM_NOTES_REQUEST,
  GET_CLINICAL_EXAM_NOTES_REQUEST_SUCCESS,
  GET_CLINICAL_EXAM_NOTES_REQUEST_FAILURE,
} from "../../../../ActionTypes/v1/dentist/clinicalExam";
import {
  GetAppointmentTypeRequestPayload,
  GetAppointmentTypeRequestSuccessPayload,
  GetAppointmentTypeRequestFailurePayload,
  GetAppointmentTypeRequest,
  GetAppointmentTypeRequestSuccess,
  GetAppointmentTypeRequestFailure,
  GetClinicalExamNotesRequestPayload,
  GetClinicalExamNotesRequestSuccessPayload,
  GetClinicalExamNotesRequestFailurePayload,
  GetClinicalExamNotesRequest,
  GetClinicalExamNotesRequestSuccess,
  GetClinicalExamNotesRequestFailure,
} from "../../../../types/dentistTypes";

export const toRedirectClinicalExamStepAction = (payload: any): any =>
  ({
    type: TO_REDIRECT_CLINICAL_EXAM_STEP,
    payload,
  } as any);

export const toHideFooterAction = (payload: any): any => ({
  type: TO_HIDE_FOOTER_REQUEST,
  payload,
});

export const toRedirectClinicalExamSubStepAction = (payload: any): any =>
  ({
    type: TO_REDIRECT_CLINICAL_EXAM_SUB_STEP,
    payload,
  } as any);

export const updateAppointmentStepRequest = (payload: any): any =>
  ({
    type: UPDATE_APPOINTMENT_STEP_REQUEST,
    payload,
  } as any);
export const updateAppointmentStepRequestSuccess = (payload: any): any =>
  ({
    type: UPDATE_APPOINTMENT_STEP_REQUEST_SUCCESS,
    payload,
  } as any);
export const updateAppointmentStepRequestFailure = (payload: any): any =>
  ({
    type: UPDATE_APPOINTMENT_STEP_REQUEST_FAILURE,
    payload,
  } as any);

export const getAppointmentTypeRequest = (
  payload: GetAppointmentTypeRequestPayload
): GetAppointmentTypeRequest =>
  ({
    type: GET_APPOINTMENT_TYPE_REQUEST,
    payload,
  } as any);
export const getAppointmentTypeRequestSuccess = (
  payload: GetAppointmentTypeRequestSuccessPayload
): GetAppointmentTypeRequestSuccess =>
  ({
    type: GET_APPOINTMENT_TYPE_REQUEST_SUCCESS,
    payload,
  } as any);
export const getAppointmentTypeRequestFailure = (
  payload: GetAppointmentTypeRequestFailurePayload
): GetAppointmentTypeRequestFailure =>
  ({
    type: GET_APPOINTMENT_TYPE_REQUEST_FAILURE,
    payload,
  } as any);

export const getClinicalExamNotesRequest = (
  payload: GetClinicalExamNotesRequestPayload
): GetClinicalExamNotesRequest => ({
  type: GET_CLINICAL_EXAM_NOTES_REQUEST,
  payload,
});
export const getClinicalExamNotesRequestSuccess = (
  payload: GetClinicalExamNotesRequestSuccessPayload
): GetClinicalExamNotesRequestSuccess => ({
  type: GET_CLINICAL_EXAM_NOTES_REQUEST_SUCCESS,
  payload,
});
export const getClinicalExamNotesRequestFailure = (
  payload: GetClinicalExamNotesRequestFailurePayload
): GetClinicalExamNotesRequestFailure => ({
  type: GET_CLINICAL_EXAM_NOTES_REQUEST_FAILURE,
  payload,
});
