import {preIntakeActions} from "../../../types/patientTypes"
import { GET_SET_PRE_INTAKE_ERROR_REQUEST, UPDATE_PRE_INTAKE_ERROR_REQUEST, CONTINUE_PRE_INTAKE_HANDLER_REQUEST } from "../../../ActionTypes/v1/preIntake"
import { stepOneFormFromContinueValidator } from "../../../../validation";
import validator from "validator";

const initialState: any = {
    errorData: {}
}

export const getSetPreIntakeErrorReducer = (state = initialState, action: preIntakeActions) => {
    switch (action.type) {

        case GET_SET_PRE_INTAKE_ERROR_REQUEST:
            return {
                ...state,
                errorData: action.payload.errorSet
            }

        case UPDATE_PRE_INTAKE_ERROR_REQUEST:
            const temp = state.errorData
            Object.keys(state.errorData)?.map((res:any) => {
                if(res === action.payload.key) {
                    // let {errors, isValid} = stepOneFormFromContinueValidator(action.payload.key, temp);

                    if (validator.isEmpty(action.payload.value)) {
                        // state.errorData[res] = `Please enter your ${action.payload.key}`;
                        return {
                            ...state,
                            errorData: state.errorData
                        }
                    } else {
                        state.errorData[res] = ``;
                        // delete state.errorData[res]
                        return {
                            ...state,
                            errorData: state.errorData
                        }
                    }
                }
            })
            return {
                ...state,
                errorData: state.errorData
            }

        // case CONTINUE_PRE_INTAKE_HANDLER_REQUEST:
        //     return {
        //         ...state,
        //         errorData: state.errorData
        //     }


            default:
                return {...state}
    }
}
