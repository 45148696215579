import {
  UPDATE_INSIGHTS_AND_RECOMMENDATIONS,
  UPDATE_INSIGHTS_AND_RECOMMENDATIONS_CACHE_DATA_CLEAR_REQUEST,
  CHANGE_INSIGHTS_AND_RECOMMENDATIONS,
} from "../../../../../ActionTypes/v1/dentist/insightAndRecommendation";
import { InsightAndRecommendationInterface } from "../../../../../types/dentistTypes";

export const updateInsightRecommendationRequest = (payload: any): any =>
  ({
    type: UPDATE_INSIGHTS_AND_RECOMMENDATIONS,
    payload,
  } as any);

export const updateInsightRecommendationCacheDataClearRequest = ():any => ({
  type: UPDATE_INSIGHTS_AND_RECOMMENDATIONS_CACHE_DATA_CLEAR_REQUEST
})  

export const changeInsightRecommendationAction = (payload: any): any =>
  ({
    type: CHANGE_INSIGHTS_AND_RECOMMENDATIONS,
    payload,
  } as any);
