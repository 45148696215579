import { intraOralDentistInterface } from "../../../../types/dentistTypes";
import {
  UPDATE_INTRA_ORAL_DENTIST,
  CHANGE_INTRA_ORAL_DENTIST, UPDATE_INTRA_ORAL_DENTIST_FAILURE
} from "../../../../ActionTypes/v1/dentist/clinicalExamDentist";
import { cancerScreenData, salivaryFlowData, softTissueData } from "../../../../../utils/dentistIntraOralConstant"
import { cancerScreenCacheData, salivaryFlowCahceData, softTissueCacheData } from "../../../../../utils/cacheDataConstant/dentistIntraoralConstant";
import { jsonParseAndStringfy } from "../../../../../utils/helper";

const initialState: intraOralDentistInterface = {
  cancerScreen: cancerScreenData,
  salivaryFlow: salivaryFlowData,
  softTissue: softTissueData,
  intra_oral_soft_tissue_exam: "",
  intra_oral_comment: '',
};

export const intraOralDentistReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case UPDATE_INTRA_ORAL_DENTIST:
      return {
        ...state,
        ...action.payload,
      };
    case UPDATE_INTRA_ORAL_DENTIST_FAILURE:
      const newCancerScreenData = jsonParseAndStringfy(cancerScreenCacheData) 
      const newSalivaryData = jsonParseAndStringfy(salivaryFlowCahceData)
      const newSoftTissueData = jsonParseAndStringfy(softTissueCacheData)
      return {
        cancerScreen: newCancerScreenData,
        salivaryFlow: newSalivaryData,
        softTissue: newSoftTissueData,
        intra_oral_soft_tissue_exam: "",
        intra_oral_comment: '',
      }  
    case CHANGE_INTRA_ORAL_DENTIST:
      const { toFinding, value, key } = action.payload as any;
      const newState = state as any;
      let newFinding = newState[toFinding] as any;
      if (typeof newFinding !== "string" && typeof newFinding !== "number") {
        newFinding = newFinding?.map((res: any) => {
          if (res.name === key) {
            return { ...res, value: value };
          }
          return res;
        });
      } else {
        newFinding = value;
      }
      return {
        ...state,
        [toFinding]: newFinding,
      };
    default:
      return { ...state };
  }
};
