import React from 'react'
import { Link, useParams, useSearchParams } from 'react-router-dom'
import { Box, Grid, Typography, useMediaQuery } from '@mui/material'
import { makeStyles } from '@mui/styles'
import PatientLeftSideImage from '../../../../../components/v2/patient/PatientLeftSideImage'
import theme from '../../../../../theme'
import HelmetComponent from '../../../../../components/v1/Common/HelmetComponent'
import OraQLogo from '../../../../../assets/images/v2/logo.png';
import OraQFooterBranding from '../OraQFooterBranding'

const imageText = {
    text1: "Elevating your oral health and",
    text2: "wellness journey with access",
    text3: "in the palm of your hand.",
};

const useStyles = makeStyles((theme: any) => ({
    rightSection: {
        paddingLeft: "10%",
        paddingRight: "10%",
        display: "flex",
        height: "100%",
        flexDirection: "column",
        justifyContent: "center",
        [theme.breakpoints.down("md")]: {
            backgroundColor: theme.palette.common.white,
            height: "100vh",
            width: "100vw",
            paddingLeft: "20%",
            paddingRight: "20%",
            borderLeft: "none",
            position: 'relative'
        },
        [theme.breakpoints.down("sm")]: {
            paddingLeft: "10%",
            paddingRight: "10%",
        },
    },
    logoBox: {
        justifyContent: "center",
        alignItems: "center",
    },
}));

const SubmitHealthHistoryPage = () => {
    const classes = useStyles();
    const { clinicId, patientId }: any = useParams();
    const [searchParams]: any = useSearchParams();

    const isSmallScreen = useMediaQuery(theme.breakpoints.down(960));

    const { palette } = theme;

    return (
        <>
            <HelmetComponent title={"Redirect Register | OraQ"} />
            <Grid
                container
                alignContent={"middle"}
                style={{
                    backgroundColor: "",
                    height: "100vh",
                }}
            >
                <PatientLeftSideImage imageText={imageText} />

                <Grid
                    item xs={12} sm={12} md={6} lg={6}
                    className={classes.rightSection}
                >
                    <Box className={classes.logoBox} display={isSmallScreen ? "flex" : "none"}>
                        <img src={OraQLogo} width={140} height={50} alt={'OraQLogo'} />
                    </Box>

                    {searchParams.get("account") == "true" ?
                        <Typography className='fw-regular labelColor' my={3} fontSize={18} lineHeight={'22px'}>
                            Your health history form has been submitted successfully.
                            You already have an account with OraQ, please click on &nbsp;
                            <Link
                                to={{ pathname: `/patient/login` }}
                                style={{ color: palette.secondary.main }}
                            >
                                Login Page
                            </Link>.
                        </Typography>
                        :
                        <Typography className='fw-regular labelColor' my={3} fontSize={18} lineHeight={'22px'}>
                            Your health history form has been submitted successfully. To register with OraQ
                            and gain more insight into your dental risk, treatment plans and images, sign up for a portal today. &nbsp;
                            <Link
                                to={{ pathname: `/patient/patient-register/${clinicId}/${patientId}` }}
                                style={{ color: palette.secondary.main }}
                            >
                                Register Here
                            </Link>.
                        </Typography>
                    }

                    <OraQFooterBranding />

                </Grid>
            </Grid>
        </>
    )
}

export default SubmitHealthHistoryPage