import {
  RECENT_XRAY_REQUEST,
  RECENT_XRAY_CACHE_DATA_CLEAR_REQUEST,
  RECENT_XRAY_REQUEST_SUCCESS,
  RECENT_XRAY_REQUEST_FAILURE,
} from "../../../../ActionTypes/v1/dentist/clinicalExamDentist";
import {
  getRecentXrayState,
  RadioGraphicAnalysisAndFindingAction,
} from "../../../../types/dentistTypes";

const initialState: getRecentXrayState = {
  pending: false,
  data: null,
  error: null,
};
const getRecentXrayReducer = (
  state = initialState,
  action: RadioGraphicAnalysisAndFindingAction
) => {
  switch (action.type) {
    case RECENT_XRAY_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case RECENT_XRAY_CACHE_DATA_CLEAR_REQUEST:
      return {
        ...state,
        data: null,
      };
    case RECENT_XRAY_REQUEST_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.payload.data,
        error: null,
      };

    case RECENT_XRAY_REQUEST_FAILURE:
      return {
        ...state,
        pending: false,
        data: null,
        error: action.payload.error,
      };
    default:
      return {
        ...state,
      };
  }
};
export default getRecentXrayReducer;
