import { PeriodontalRecallExamInterface } from "../../../../types/dentistTypes";
import {
  UPDATE_PERIODONTAL_RECALL_EXAM,
  UPDATE_PERIODONTAL_RECALL_EXAM_FAILURE,
  ADD_PERIODONTAL_FINDING_ROW_RECALL_EXAM,
  CHANGE_PERIODONTAL_FINDING_RECALL_EXAM,
  DELETE_PERIODONTAL_FINDING_ROW_RECALL_EXAM,
} from "../../../../ActionTypes/v1/dentist/clinicalExamDentist";
import {
  defaultMinimalAttachedTissueRecallExam,
  defaultNonCariousCervicalLesionsRecallExam,
  defaultRecessionRecallExam,
} from "../../../../../utils/recallFlowPeriodontalConstant";
import {
  defaultCacheMinimalAttachedTissueRecallExam,
  defaultCacheNonCariousCervicalLesionsRecallExam,
  defaultCacheRecessionRecallExam,
} from "../../../../../utils/cacheDataConstant/recallPeriodontalConstant";
import { jsonParseAndStringfy } from "../../../../../utils/helper";

const initialState: PeriodontalRecallExamInterface = {
  staging: "",
  grading: "",
  gingivitis_class: "",
  periodontitis_class: "",
  nccls: [defaultNonCariousCervicalLesionsRecallExam],
  recession: [defaultRecessionRecallExam],
  minimalAttachedTissue: [defaultMinimalAttachedTissueRecallExam],
  heavy_plaque: false,
  presence_of_white_decalcification_spot: false,
  periodontal_comment: "",
};

export const periodontalDentistRecallFlowReducer = (
  state = initialState,
  action: any
) => {
  switch (action.type) {
    case UPDATE_PERIODONTAL_RECALL_EXAM:
      return {
        ...state,
        ...action.payload,
      };
    case UPDATE_PERIODONTAL_RECALL_EXAM_FAILURE:
      const newNCCLData = jsonParseAndStringfy(
        defaultCacheNonCariousCervicalLesionsRecallExam
      );
      const newRecessionData = jsonParseAndStringfy(
        defaultCacheRecessionRecallExam
      );
      const newMinimalAttachedTissue = jsonParseAndStringfy(
        defaultCacheMinimalAttachedTissueRecallExam
      );
      return {
        gingivitis_class: "",
        periodontitis_class: "",
        nccls: [newNCCLData],
        recession: [newRecessionData],
        minimalAttachedTissue: [newMinimalAttachedTissue],
        heavy_plaque: false,
        presence_of_white_decalcification_spot: false,
        periodontal_comment: "",
      };
    case ADD_PERIODONTAL_FINDING_ROW_RECALL_EXAM:
      const finding = action.payload as string;
      const newStateAdd = state as any;
      const updatedFinding = newStateAdd[finding];
      if (finding === "nccls") {
        updatedFinding.push(defaultNonCariousCervicalLesionsRecallExam);
      } else if (finding === "recession") {
        updatedFinding.push(defaultRecessionRecallExam);
      } else if (finding === "minimalAttachedTissue") {
        updatedFinding.push(defaultMinimalAttachedTissueRecallExam);
      }
      return {
        ...state,
        [finding]: updatedFinding,
      };
    case CHANGE_PERIODONTAL_FINDING_RECALL_EXAM:
      const { toFinding, value, type, index, key } = action.payload as any;
      const newState = state as any;
      let newFinding = newState[toFinding] as any;
      if (
        newFinding &&
        typeof newFinding !== "string" &&
        typeof newFinding !== "boolean"
      ) {
        newFinding[index] = newFinding[index]?.map((res: any) => {
          if (res.name === key) {
            return { ...res, value: value };
          }
          return res;
        });
      } else {
        newFinding = value;
      }
      return {
        ...state,
        [toFinding]: newFinding,
      };
    case DELETE_PERIODONTAL_FINDING_ROW_RECALL_EXAM:
      const { deletingFrom, deleteIndex } = action.payload as any;
      const newStateDelete = state as any;
      let findingDeletingFrom = newStateDelete[deletingFrom] as any;

      findingDeletingFrom = newStateDelete[deletingFrom].filter(
        (f: any, i: number) => i !== deleteIndex
      );
      return {
        ...state,
        [deletingFrom]: findingDeletingFrom,
      };
    default:
      return { ...state };
  }
};
