import React from 'react';
import { AppBar, Box, Paper, Toolbar, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: any) => ({
    paperContainer: {
        backgroundColor: `${theme.palette.common.white} !important`,
        marginBottom: '80px'
    },
    appBarContainer: {
        zIndex: 700,
        background: theme.palette.secondary.light,
        boxShadow: "none !important",
        position: 'fixed'
    },
    toolbarClass: {
        background: `${theme.palette.common.white} !important`,
        paddingLeft: "20px !important",
        borderBottom: `1px solid ${theme.palette.divider}`,
        minHeight: "80px !important",
    },
    innerAppBar: {
        background: `${theme.palette.common.white} !important`,
        color: theme.palette.common.black,
        boxShadow: "none !important",
        padding: "7px 0",
        paddingRight: "0px !important",
        position: 'static'
    }

}))

interface Props {
    headerText: string
}

const HealthHistoryHeader = (props: Props) => {
    const { headerText } = props
    const classes = useStyles()
    return (
        <Paper className={classes.paperContainer}>
            <AppBar className={classes.appBarContainer} >
                <Toolbar className={classes.toolbarClass} >
                    <Box className="w-100">
                        <AppBar className={classes.innerAppBar} >
                            <Box display={"flex"} alignItems={"center"}>
                                <Typography className="fw-medium label-color f-26 d-flex align-center" >
                                    <Box className={'label-color'} sx={{ textTransform: 'capitalize' }}>
                                        {headerText}
                                    </Box>
                                </Typography>
                            </Box>
                        </AppBar>
                    </Box>
                </Toolbar>
            </AppBar>
        </Paper>
    )
}

export default HealthHistoryHeader