
import {
    LOGIN_V2_REQUEST, LOGIN_V2_REQUEST_FAILURE, LOGIN_V2_REQUEST_SUCCESS, UPDATE_DEVICE_TOKEN,
    GOOGLE_LOGIN_REQUEST,
    GOOGLE_LOGIN_REQUEST_SUCCESS,
    GOOGLE_LOGIN_REQUEST_FAILURE
} from '../../../ActionTypes/v2/auth';
import { loginInterface, loginActions } from '../../../types/v2/authActionTypes/loginActionTypes';

const initialState: loginInterface = {
    loginLoader: false,
    loginData: null,
    loginError: null,
    deviceToken: null,
    googleLoginLoader: false,
    googleLoginData: null,
    googleLoginError: null

};

const loginReducer = (state = initialState, action: loginActions) => {
    switch (action.type) {
        case LOGIN_V2_REQUEST:
            return {
                ...state,
                loginLoader: true
            };
        case LOGIN_V2_REQUEST_SUCCESS:
            return {
                ...state,
                loginLoader: false,
                loginData: action.payload,
                loginError: null
            };
        case LOGIN_V2_REQUEST_FAILURE:
            return {
                ...state,
                loginLoader: false,
                loginData: null,
                loginError: action?.payload?.loginError
            };
        case UPDATE_DEVICE_TOKEN:
            return {
                ...state,
                deviceToken: action.payload
            };
             case GOOGLE_LOGIN_REQUEST:
            return {
                ...state,
                googleLoginLoader: true
            };
        case GOOGLE_LOGIN_REQUEST_SUCCESS:
            return {
                ...state,
                googleLoginLoader: false,
                googleLoginData: action.payload,
                googleLoginError: null
            };
        case GOOGLE_LOGIN_REQUEST_FAILURE:
            return {
                ...state,
                googleLoginLoader: false,
                googleLoginData: null,
                googleLoginError: action?.payload?.loginError
            };
        default:
            return {
                ...state
            };
    }
};
export default loginReducer
