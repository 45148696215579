import {
    VerifyOtpRequest,
    VerifyOtpRequestSuccess,
    VerifyOtpRequestFailure,
    VerifyOtpRequestPayload,
    VerifyOtpRequestSuccessPayload,
    VerifyOtpRequestFailurePayload,
    SendOtpRequest,
    SendOtpRequestSuccess,
    SendOtpRequestFailure,
    SendOtpRequestPayload,
    SendOtpRequestSuccessPayload,
    SendOtpRequestFailurePayload

} from '../../../types/patientTypes'
import {
    VERIFY_OTP_REQUEST,
    VERIFY_OTP_REQUEST_SUCCESS,
    VERIFY_OTP_REQUEST_FAILURE,
    SEND_OTP_REQUEST,
    SEND_OTP_REQUEST_SUCCESS,
    SEND_OTP_REQUEST_FAILURE,
} from '../../../ActionTypes/v1/otp'



export const verifyOtpRequest = (payload: VerifyOtpRequestPayload): VerifyOtpRequest => ({
    type: VERIFY_OTP_REQUEST,
    payload,
});
export const verifyOtpRequestSuccess = (
    payload: VerifyOtpRequestSuccessPayload
): VerifyOtpRequestSuccess => ({
    type: VERIFY_OTP_REQUEST_SUCCESS,
    payload
});

export const verifyOtpRequestFailure = (
    payload: VerifyOtpRequestFailurePayload
): VerifyOtpRequestFailure => ({
    type: VERIFY_OTP_REQUEST_FAILURE,
    payload
});



export const sendOtpRequest = (payload: SendOtpRequestPayload): SendOtpRequest => ({
    type: SEND_OTP_REQUEST,
    payload,
});
export const sendOtpRequestSuccess = (
    payload: SendOtpRequestSuccessPayload
): SendOtpRequestSuccess => ({
    type: SEND_OTP_REQUEST_SUCCESS,
    payload
});

export const sendOtpRequestFailure = (
    payload: SendOtpRequestFailurePayload
): SendOtpRequestFailure => ({
    type: SEND_OTP_REQUEST_FAILURE,
    payload
});
