
import { call, put, takeEvery, all } from "redux-saga/effects";
import API from "../../../../utils/api";
import { errorToaster, successToaster } from '../../../../utils/helper';
import { recoverPasswordRequestFailure, recoverPasswordRequestSuccess } from '../../../actions/v2/authAction/resetPasswordAction';
import { RECOVER_PASSWORD_V2_REQUEST } from '../../../ActionTypes/v2/auth';


function* recoverPasswordRequestSaga(payload: any): any {
  try {
    const response = yield API.post('/v2/auth/reset/password', payload.payload.payload)
    yield call(payload.payload.callback, response.detail);
    yield put(
      recoverPasswordRequestSuccess({
        recoverPasswordData: response?.data?.detail,
      })
    );
    successToaster(response?.data?.meta?.message)
  } catch (e: any) {
    errorToaster(e?.message)
    yield put(
      recoverPasswordRequestFailure({
        recoverPasswordError: e?.message
      })
    );
  }
}

function* recoverPasswordSaga() {
  yield takeEvery(RECOVER_PASSWORD_V2_REQUEST, recoverPasswordRequestSaga);
}

export default function* rootSaga() {
  yield all([
    recoverPasswordSaga(),
  ])
}