
import { put, takeEvery, all } from "redux-saga/effects";
import API from "../../../../utils/api";
import { errorToaster } from '../../../../utils/helper';
import { privacyPolicyFailure, privacyPolicySuccess } from '../../../actions/v2/authAction/privacyPolicyAction';
import { PRIVACY_POLICY_V2_REQUEST } from '../../../ActionTypes/v2/auth';

function* acceptPolicyRequestSaga(payload: any): any {
  try {
    const response = yield API.put(`/v2/auth/policy/accepted?is_owner=${payload?.payload?.isOwner}`, payload?.payload?.payload)
    yield put(
      privacyPolicySuccess({
        privacyPolicyData: response?.termsConditions
      })
    );
    if (payload?.payload?.callback) {
      payload?.payload?.callback(response)
    }
  } catch (e: any) {
    errorToaster(e?.message)
    yield put(
      privacyPolicyFailure({
        privacyPolicyError: e?.message || "Something went wrong!"
      })
    );
  }
}

function* acceptPolicySaga() {
  yield takeEvery(PRIVACY_POLICY_V2_REQUEST, acceptPolicyRequestSaga);
}

export default function* rootSaga() {
  yield all([
    acceptPolicySaga()
  ])
}
