import {UPDATE_DENTIST_EXISTING_TREATMENT, UPDATE_DENTIST_EXISTING_TREATMENT_FAILURE,
    ADD_DENTIST_EXISTING_TREATMENT_FINDING_ROW,
    CHANGE_DENTIST_EXISTING_TREATMENT_FINDING,
    DELETE_DENTIST_EXISTING_TREATMENT_FINDING_ROW
} from "../../../../ActionTypes/v1/dentist/clinicalExamDentist"

import {ExistingTreatmentDentistInterface} from "../../../../types/dentistTypes";

export const updateExistingTreatmentDentistRequest = (
    payload: ExistingTreatmentDentistInterface
): ExistingTreatmentDentistInterface => ({
    type: UPDATE_DENTIST_EXISTING_TREATMENT,
    payload
}) as any;

export const updateExistingTreatmentDentistRequestFailure = (): any => ({
    type: UPDATE_DENTIST_EXISTING_TREATMENT_FAILURE,
}) as any;

export const addExistingTreatmentFindingRowAction = (payload: string): any => ({
    type: ADD_DENTIST_EXISTING_TREATMENT_FINDING_ROW,
    payload
}) as any;

export const changeExistingTreatmentFindingAction = (payload: any): any => ({
    type: CHANGE_DENTIST_EXISTING_TREATMENT_FINDING,
    payload
}) as any;

export const deleteExistingTreatmentFindingRowAction = (payload: any): any => ({
    type: DELETE_DENTIST_EXISTING_TREATMENT_FINDING_ROW,
    payload
}) as any;