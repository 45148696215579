import React from 'react';

export const watchIcon = (color?: string) => {
  return (
    <svg width='12' height='9' viewBox='0 0 12 9' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M9.80875 0H11.3809L9.21073 8.84082H7.21939L5.69659 1.98518L4.14914 8.84082H2.1578L0 0H1.56595L3.20588 7.20706L4.77182 0H6.67069L8.2243 7.21322L9.80875 0Z'
        fill={color || '#483698'}
      />
    </svg>
  );
};
