import { call, put, takeEvery, all } from "redux-saga/effects";

import API from "../../../../utils/api";
import { errorToaster, successToaster } from '../../../../utils/helper';
import { changePasswordRequestFailure, changePasswordRequestSuccess } from '../../../actions/v2/authAction/changePasswordAction';
import { CHANGE_PASSWORD_V2_REQUEST } from '../../../ActionTypes/v2/auth';

function* changePasswordRequestSaga(payload: any): any {
  try {
      const response = yield API.put("/v2/auth/password", payload.payload.payload)
      yield call(payload.payload.callback, response.data);
      yield put(
          changePasswordRequestSuccess({
            changePasswordData: response?.data?.detail,
          })
      );
      successToaster(response?.data?.meta?.message)
  } catch (e: any) {
      errorToaster(e?.message)
      yield put(
          changePasswordRequestFailure({
            changePasswordError: e?.message
          })
      );
  }
}

function* changePasswordSaga() {
  yield takeEvery(CHANGE_PASSWORD_V2_REQUEST, changePasswordRequestSaga);
}

export default function* rootSaga() {
  yield all([
    changePasswordSaga(),
  ])
}