import {
    UPDATE_TMJ_DENTIST, UPDATE_TMJ_DENTIST_FAILURE,
    CHANGE_TMJ_DENTIST
} from "../../../../ActionTypes/v1/dentist/clinicalExamDentist";
import { TmjDentistInterface } from "../../../../types/dentistTypes";

export const updateTmjRequest = (
    payload: TmjDentistInterface
): TmjDentistInterface => ({
    type: UPDATE_TMJ_DENTIST,
    payload,
}) as any;

export const updateTmjRequestFailure = (): any => ({
    type: UPDATE_TMJ_DENTIST_FAILURE,
}) as any;


export const changeTmjFindingAction = (payload: any): any => ({
    type: CHANGE_TMJ_DENTIST,
    payload
}) as any;