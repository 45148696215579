import theme from "../../theme";

const {palette} = theme
export const procedureColors = {
  'Completed': {
    color: palette.procedure.completed.color,
    bgColor: palette.procedure.completed.bgColor,
  },
  'Planned': {
    color: palette.procedure.planned.color,
    bgColor: palette.procedure.planned.bgColor,
  },
  'Existing': {
    color: palette.secondary.main,
    bgColor: palette.procedure.existing.bgColor,
  },
} as any
