import {
  GET_OCCLUSION_DENTIST_RECALL_EXAM_REQUEST,
  GET_OCCLUSION_DENTIST_RECALL_EXAM_REQUEST_SUCCESS,
  GET_OCCLUSION_DENTIST_RECALL_EXAM_REQUEST_FAILURE,
  PUT_OCCLUSION_DENTIST_RECALL_EXAM_REQUEST,
  PUT_OCCLUSION_DENTIST_RECALL_EXAM_REQUEST_SUCCESS,
  PUT_OCCLUSION_DENTIST_RECALL_EXAM_REQUEST_FAILURE,
} from "../../../../ActionTypes/v1/dentist/clinicalExamDentistRecallExam"
import {
  OcclusionExamDentistRecallFlowState,
  OcclusionDentistRecallExamActions
} from "../../../../types/dentistTypes"

const initialState: OcclusionExamDentistRecallFlowState = {
  put_occlusion_dentist_recall_exam_loader: false,
  occlusion_dentist_recall_exam_loader: false,
  occlusion_dentist_recall_data: null,
  error: null
};

const occlusionDentistRecallExamReducer = (
  state = initialState,
  action: OcclusionDentistRecallExamActions
) => {
  switch (action.type) {

    case GET_OCCLUSION_DENTIST_RECALL_EXAM_REQUEST:
      return {
        ...state,
        occlusion_dentist_recall_exam_loader: true,
      };
    case GET_OCCLUSION_DENTIST_RECALL_EXAM_REQUEST_SUCCESS:
      return {
        ...state,
        occlusion_dentist_recall_exam_loader: false,
        occlusion_dentist_recall_data: action.payload,
        error: null,
      };

    case GET_OCCLUSION_DENTIST_RECALL_EXAM_REQUEST_FAILURE:
      return {
        ...state,
        occlusion_dentist_recall_exam_loader: false,
        occlusion_dentist_recall_data: null,
        error: action.payload.error,
      };
    case PUT_OCCLUSION_DENTIST_RECALL_EXAM_REQUEST:
      return {
        ...state,
        occlusion_dentist_recall_exam_loader: true,
      }
    case PUT_OCCLUSION_DENTIST_RECALL_EXAM_REQUEST_SUCCESS:
      return {
        ...state,
        occlusion_dentist_recall_exam_loader: false,
        occlusion_dentist_recall_data: action.payload,
        error: null,
      }
    case PUT_OCCLUSION_DENTIST_RECALL_EXAM_REQUEST_FAILURE:
      return {
        ...state,
        occlusion_dentist_recall_exam_loader: true,
        occlusion_dentist_recall_data: null,
        error: action.payload.error,
      };
    default:
      return {
        ...state,
      };
  }
};
export default occlusionDentistRecallExamReducer;