import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { implantRoot } from '../../../../../../assets/images/v2/odontogramFindings/odontogramIcons';
import BopIcon from '../../../../../../assets/images/v2/periogramFindings/bop.svg';
import Furcation1 from '../../../../../../assets/images/v2/periogramFindings/furcation1.svg';
import Furcation2 from '../../../../../../assets/images/v2/periogramFindings/furcation2.svg';
import Furcation3 from '../../../../../../assets/images/v2/periogramFindings/furcation3.svg';
import Plaque from '../../../../../../assets/images/v2/periogramFindings/plaque.svg';
import Recession from '../../../../../../assets/images/v2/periogramFindings/recession.svg';
import { toothImages } from '../../../../../../assets/images/v2/tooth/toothImages';
import theme from "../../../../../../theme";
import { convertValueToRoman, removeRefFromJson, stringToArray } from '../../../../../../utils/helper';
import { procedureColors } from '../../../../../../utils/v2/procedureHelper';
import { getCrownSvg } from '../../Odontogram/Crown';
import { getLingualSvg } from '../../Odontogram/Lingual';
import abfraction from '../../../../../../assets/images/v2/odontogramFindings/abfraction.svg'
import PeriogramNcclFinding from '../PeriogramNcclFinding';
import { examConstantInterface, findingData, findingTypes } from '../../../../../../utils/v2/examConstant';
import { isArray } from 'lodash';

const useStyles = makeStyles((theme: any) => ({
  odontogramTeeth: {
    height: '85px',
    flexDirection: 'column',
    zIndex: 1,
  },
  missingToothLine: {
    '&::before': {
      position: "absolute",
      content: '""',
      transform: 'rotate(25deg)',
      width: 2,
      height: 80,
      background: theme.palette.error.main,
      display: 'flex',
      zIndex: 1024,
      borderRadius: 10
    }
  },
  isMobilityIconContainer: {
    top: -3,
    right: -2,
    position: 'absolute'
  },
  isBOPIconContainer: {
    top: '-5px',
    position: 'absolute',
    zIndex: 1024
  },
  impactedTooth: {
    width: '46.52px',
    height: '90px',
    borderRadius: '50%',
  },
  rootContainer: {
    height: '46.28px !important',
    maxHeight: '46.28px !important',
    minHeight: '46.28px !important',
    overflow: 'hidden !important',
    width: '100%',
    position: 'relative',
  },
  crownContainer: {
    height: '32.5px !important',
    maxHeight: '32.5px !important',
    minHeight: '32.5px !important',
    overflow: 'hidden !important',
  },
}));

type Props = {
  tooth: number;
  lingual?: boolean;
};

const PeriogramTeeth = (props: Props) => {
  const { tooth, lingual } = props;
  const classes = useStyles();
  const { palette } = theme
  const toothRoot = useRef(null) as any;
  const {
    examFindingReducer: { examFindingData, examDataFromAPI },
    odontogram: { procedureFilters, showRejectedTreatmentsOnly },
    clinicalExamFilters: { riskFilters, findingType },
  } = useSelector((state: any) => state) as any;
  const bottomJaw = tooth > 28;
  const isMissingTooth = stringToArray(examDataFromAPI?.occlusion_exam?.missing_tooth_tooth_number || '').includes(`${tooth}`)
  const isImpactedTooth = useMemo(() => {
    let hasTooth = false;
    let impactedToothSubType = null;
    let color = null;
    const updatedFindingData = examDataFromAPI?.['occlusion_exam']?.['impacted_tooth'];
    if (updatedFindingData?.length === 1) {
      const impactedToothData = updatedFindingData[0];
      const isTooth = impactedToothData['tooth_number'];
      const isSubType = impactedToothData['subtype'];
      const procedure_status = impactedToothData['procedure_status'];
      const isRejected = impactedToothData['rejected'];
      if (
        (procedureFilters?.includes(procedure_status) || procedureFilters?.length < 1) &&
        (!showRejectedTreatmentsOnly || (showRejectedTreatmentsOnly && isRejected)) &&
        isTooth?.includes(`${tooth}`) &&
        isSubType
      ) {
        hasTooth = true;
        impactedToothSubType = isSubType ? true : true;
        color = procedureColors[procedure_status]?.color;
      }
    } else if (updatedFindingData) {
      updatedFindingData?.forEach((impactedToothData: any) => {
        const isTooth = impactedToothData['tooth_number'];
        const isSubType = impactedToothData['subtype'];
        const procedure_status = impactedToothData['procedure_status'];
        const isRejected = impactedToothData['rejected'];
        if (
          (procedureFilters?.includes(procedure_status) || procedureFilters?.length < 1) &&
          (!showRejectedTreatmentsOnly || isRejected) &&
          isTooth?.includes(`${tooth}`) &&
          isSubType
        ) {
          hasTooth = true;
          impactedToothSubType = isSubType ? true : true;
          color = procedureColors[procedure_status]?.color;
        }
      });
    }
    return {
      hasTooth,
      impactedToothSubType,
      color,
    };
  }, [tooth, examDataFromAPI, procedureFilters, showRejectedTreatmentsOnly]);
  const isImplant = useMemo(() => {
    let hasTooth = false;
    let implantSubType = null;
    let color = null;
    const updatedFindingData = examDataFromAPI?.['existing_treatment']?.['implant'];

    if (updatedFindingData?.length === 1) {
      const implantData = updatedFindingData[0];
      const isTooth = implantData['tooth_number'];
      const isSubType = implantData['subtype'];
      const procedure_status = implantData['procedure_status'];
      const isRejected = implantData['rejected'];
      if (
        (procedureFilters?.includes(procedure_status) || procedureFilters?.length < 1) &&
        (!showRejectedTreatmentsOnly || (showRejectedTreatmentsOnly && isRejected)) &&
        isTooth?.includes(`${tooth}`) &&
        isSubType
      ) {
        hasTooth = true;
        implantSubType = isSubType === 'Fixture' ? false : true;
        color = procedureColors[procedure_status]?.color;
      }
    } else if (updatedFindingData) {
      updatedFindingData?.forEach((implantData: any) => {
        const isTooth = implantData['tooth_number'];
        const isSubType = implantData['subtype'];
        const procedure_status = implantData['procedure_status'];
        const isRejected = implantData['rejected'];
        if (
          (procedureFilters?.includes(procedure_status) || procedureFilters?.length < 1) &&
          (!showRejectedTreatmentsOnly || isRejected) &&
          isTooth?.includes(`${tooth}`) &&
          isSubType
        ) {
          hasTooth = true;
          implantSubType = isSubType === 'Fixture' ? false : true;
          color = procedureColors[procedure_status]?.color;
        }
      });
    }
    return {
      hasTooth,
      implantSubType,
      color,
    };
  }, [tooth, examDataFromAPI, procedureFilters, showRejectedTreatmentsOnly]);
  const isNCCLs = useMemo(() => {
    let hasTooth = false;
    let color = null;
    let ncclsSubType = null;
    const updatedFindingData = examDataFromAPI?.['periodontal_exam']?.['non_carious_cervical_lesions'];
    if (updatedFindingData?.length === 1) {
      const ncclsSubTypeOption = updatedFindingData[0];
      const isTooth = ncclsSubTypeOption['tooth_number'];
      const procedure_status = ncclsSubTypeOption['procedure_status'];
      const isRejected = ncclsSubTypeOption['rejected'];
      if (
        (procedureFilters?.includes(procedure_status) || procedureFilters?.length < 1) &&
        (!showRejectedTreatmentsOnly || (showRejectedTreatmentsOnly && isRejected)) &&
        isTooth?.includes(`${tooth}`)
      ) {
        hasTooth = ncclsSubTypeOption;
        ncclsSubType = [
          ncclsSubTypeOption['erosion'] ? 'erosion' : null,
          ncclsSubTypeOption['attrition'] ? 'attrition' : null,
          ncclsSubTypeOption['is_dentin_exposed'] ? 'is_dentin_exposed' : null,
          ncclsSubTypeOption['abrasion'] ? 'abrasion' : null,
          ncclsSubTypeOption['abfraction'] ? 'abfraction' : null,
        ];
        color = procedureColors[procedure_status]?.color;
      }
    } else if (updatedFindingData) {
      updatedFindingData.forEach((ncclsSubTypeOption: any, i: number) => {
        const isTooth = ncclsSubTypeOption['tooth_number'];
        const procedure_status = ncclsSubTypeOption['procedure_status'];
        const isRejected = ncclsSubTypeOption['rejected'];

        if ((procedureFilters?.includes(procedure_status) || procedureFilters?.length < 1) && (!showRejectedTreatmentsOnly
          || isRejected) &&
          isTooth?.includes(`${tooth}`)
        ) {
          hasTooth = true;
          ncclsSubType = [
            (ncclsSubTypeOption['erosion'] === 'yes' || ncclsSubTypeOption['erosion'] === true) ? 'erosion' : null,
            (ncclsSubTypeOption['attrition'] === 'yes' || ncclsSubTypeOption['attrition'] === true) ? 'attrition' : null,
            (ncclsSubTypeOption['is_dentin_exposed'] === 'yes' || ncclsSubTypeOption['is_dentin_exposed'] === true) ? 'is_dentin_exposed' : null,
            (ncclsSubTypeOption['abrasion'] === 'yes' || ncclsSubTypeOption['abrasion'] === true) ? 'abrasion' : null,
            (ncclsSubTypeOption['abfraction'] === 'yes' || ncclsSubTypeOption['abfraction'] === true) ? 'abfraction' : null,
          ];
          color = procedureColors[procedure_status]?.color;
        }
      });
    }
    return {
      hasTooth,
      color,
      ncclsSubType,
    };
  }, [tooth, examDataFromAPI, procedureFilters, showRejectedTreatmentsOnly]);
  const getTooth = (part: string) => {
    return toothImages[`${part}${tooth}`];
  };
  const getFinding = useMemo(() => {
    if (isImplant.hasTooth) {
      return 'implant'
    } else {
      return null
    }
  }, [isImplant]);
  const getColor = useMemo(() => {
    if (isImplant.hasTooth) {
      return isImplant?.color
    } else {
      return null
    }
  }, [isImplant,]);
  const getSubType = useMemo(() => {
    if (isImplant.hasTooth) {
      return (isImplant?.implantSubType ? "Fixture and Crown" : 'Fixture')
    }
  }, [isImplant]);
  const lingualStroke = useMemo(() => {
    if (isImplant?.hasTooth) {
      return isImplant?.color;
    } else {
      return null;
    }
  }, [isImplant]);
  const getCrown = useMemo(() => {
    let toothNumber = tooth as any;
    return getCrownSvg[`Crown${toothNumber}`](getFinding, getColor, null, lingualStroke, getSubType);
  }, [tooth, getFinding, getColor, getSubType, lingualStroke]);
  const getLingual = useMemo(() => {
    let toothNumber = tooth as any;
    return getLingualSvg[`Lingual${toothNumber}`](getFinding, getColor, null, lingualStroke, getSubType)
  }, [tooth, getFinding, getColor, getSubType, lingualStroke])
  const getSite = (site: string) => {
    let newSite = '' as string
    if (site === 'center') {
      return lingual ? 'L' : 'B'
    }
    if ((tooth > 10 && tooth < 19) || (tooth > 30 && tooth < 39)) {
      newSite = (site === 'right') ? 'M' : (site === 'left') ? 'D' : ''
    } else {
      newSite = (site === 'right') ? 'D' : (site === 'left') ? 'M' : ''
    }
    if (newSite) {
      return `${newSite}${lingual ? 'L' : 'B'}`
    }
    return ''
  }

  const riskWiseFilteredData = useMemo(() => {
    const findingTypeWiseFilteredData = findingData?.filter((f: examConstantInterface) =>
      f?.findingTypes?.includes(findingTypes[findingType])
    ) as any;
    const riskWiseFiltered = riskFilters?.length
      ? (findingTypeWiseFilteredData?.filter((f: examConstantInterface) => f?.risks?.filter((ff: string) => riskFilters.includes(ff))?.length) as any)
      : findingData; /*Filter the data only if there is at least one risk is selected else will show data without risk filters*/
    return riskWiseFiltered
  }, [riskFilters, riskFilters?.length, findingData, findingType]);

  const isFindingPresent = (site: string, finding: string) => {
    const newSite = getSite(site)
    let value = '' as boolean | string | number | any
    const filteredFindingData = removeRefFromJson(riskWiseFilteredData) as any
    if (examFindingData && examFindingData?.periodontal_exam && examFindingData?.periodontal_exam[finding] && filteredFindingData) {
      filteredFindingData?.filter((findingKey: any) => findingKey?.key === finding)?.map((fk: any) => {
        examFindingData?.periodontal_exam[fk?.key]?.map((res: any) => {
          if (res?.tooth_number === `${tooth}`) {
            if ((typeof res?.tooth_site === 'string' ? stringToArray(res?.tooth_site) : res?.tooth_site)?.includes(newSite) && (finding === 'bleed_on_probing' || finding === 'plaque')) {
              value = true
            }
            if (res?.grade && res?.tooth_number === `${tooth}` && finding === 'mobility') {
              value = res?.grade
            }
            if (finding === 'furcation') {
              let toothSite = lingual ? 'lingual_grade' : 'buccal_grade'
              if (res[toothSite]) {
                value = res[toothSite] || ''
              }
            }
            if (finding === 'recession') {
              let toothSite = lingual ? 'lingual_mm' : 'buccal_mm'
              value = res[toothSite] || 0
            }
            if (finding === 'probing_depth') {
              let toothSite = '' as any
              if (site === 'center') {
                toothSite = lingual ? 'lingual_mm' : 'buccal_mm'
              } else {
                if ((tooth > 10 && tooth < 19) || (tooth > 30 && tooth < 39)) {
                  toothSite = site === 'right' ? `mesial_${lingual ? 'lingual' : 'buccal'}_mm` : site === 'left' ? `distal_${lingual ? 'lingual' : 'buccal'}_mm` : ''
                } else {
                  toothSite = site === 'right' ? `distal_${lingual ? 'lingual' : 'buccal'}_mm` : site === 'left' ? `mesial_${lingual ? 'lingual' : 'buccal'}_mm` : ''
                }
              }
              value = res[toothSite] || 0
            }
          }
          return true
        })
        return true
      })
    }
    return value
  };
  const getFurcationIcons = (val?: any) => {
    return val === '1' ? Furcation1 : val === '2' ? Furcation2 : Furcation3
  }
  return (
    <Box
      sx={{
        transform: bottomJaw ? (lingual ? '' : 'rotate(180deg) scaleX(1)') : lingual ? 'rotate(180deg)' : '',
        opacity: isMissingTooth ? 0 : 1
      }}
      className={`${classes.odontogramTeeth} pos-relative d-flex-all-center ${isMissingTooth ? classes.missingToothLine : ''}`}
    >
      {isFindingPresent('', 'mobility') && !lingual &&
        <Box className={classes.isMobilityIconContainer}>
          <Typography sx={{ fontFamily: 'Iowan Old Style !important' }} className='f-16'>
            {convertValueToRoman(`${isFindingPresent('', 'mobility')}`)}
          </Typography>
        </Box>
      }
      <Box width={42} className={`d-flex align-center justify-content-between ${classes.isBOPIconContainer}`}>
        {[isFindingPresent(((lingual && !bottomJaw) || (!lingual && bottomJaw)) ? 'right' : 'left', 'bleed_on_probing'),
        isFindingPresent('center', 'bleed_on_probing'),
        isFindingPresent(((lingual && !bottomJaw) || (!lingual && bottomJaw)) ? 'left' : 'right', 'bleed_on_probing'),
        ]?.map((show: any, index: number) => <Box height={'10px'} width={'10px'} key={index}>
          {show &&
            <img
              style={{ transform: ((lingual && !bottomJaw) || (!lingual && bottomJaw)) ? 'rotate(180deg)' : '' }}
              src={BopIcon} alt={'bleed_on_probing'} width={10} height={10}
            />
          }
        </Box>)
        }
      </Box>
      <Box className={isImpactedTooth?.hasTooth ? classes.impactedTooth : ''} sx={{ border: isImpactedTooth?.hasTooth ? `1px solid ${isImpactedTooth?.color}` : 'none', }}>
        {/*Root*/}
        <Box className={`d-flex-all-center ${classes.rootContainer}`}
          sx={{
            transform: bottomJaw ? (lingual ? '' : ' scaleX(-1)') : '',
            alignItems: !bottomJaw ? 'flex-end !important' : 'flex-end !important'
          }}
        >
          {isFindingPresent('', 'recession') > 0 &&
            <Box className='pos-absolute d-flex align-flex-end '
              sx={{
                bottom: `${(parseInt(`${isFindingPresent('', 'recession')}`) * 3) - 5}px`,
                zIndex: 1,
                transform: lingual ? bottomJaw ? '' : 'scaleX(-1)' : bottomJaw ? 'scaleX(-1)' : ''
              }}
            >
              <img src={Recession} alt={'Recession'} />
            </Box>
          }
          <Box className='pos-absolute d-flex align-flex-end '
            sx={{
              bottom: 0,
              zIndex: 1,
              transform: lingual ? (bottomJaw ? '' : 'scaleX(-1)') : (bottomJaw ? 'scaleX(1)' : '')
            }}
          >
            {['left', 'center', 'right'].map((site: string, index: number) => <Box
              key={index}
              sx={{
                height: `${parseInt(`${isFindingPresent(site, 'probing_depth')}`) * 3}px`,
                width: '6px',
                background: isFindingPresent(site, 'probing_depth') > 3 ? 'red' : palette.common.black50,
                mx: index === 1 ? 0.5 : ''
              }}
            >
              {/*Probing*/}
            </Box>)
            }
          </Box>
          {isFindingPresent('', 'furcation') &&
            <Box className='pos-absolute d-flex align-flex-end '
              sx={{
                bottom: 5,
                zIndex: 1,
                transform: bottomJaw ? (!lingual ? '' : 'rotate(180deg)') : !lingual ? 'rotate(180deg)' : '',
              }} >
              <img
                style={{ transform: ((lingual && !bottomJaw) || (!lingual && bottomJaw)) ? 'rotate(180deg)' : '' }}
                src={getFurcationIcons(isFindingPresent('', 'furcation'))} alt={'furcation'} width={10} height={10}
              />
            </Box>
          }

          {isImplant?.hasTooth ?
            <div className={'d-flex align-flex-end'} dangerouslySetInnerHTML={{ __html: implantRoot(isImplant?.color) }} /> : <img
              style={{
                transform: ((lingual && !bottomJaw) || (!lingual && bottomJaw)) ? 'rotate(180deg)' : ''
              }}
              ref={toothRoot}
              src={getTooth(lingual ? 'LingualRoot' : 'Root')}
              alt={`tooth${tooth}`}
            />}
          {/*  */}
        </Box>
        {/*Crown*/}
        <Box className='pos-relative'>  <Box
          className={`d-flex-all-center pos-relative ${classes.crownContainer}`}
          sx={{
            transform: bottomJaw ? lingual ? 'rotate(180deg)' : 'rotate(180deg) scaleX(1)' : '',
            alignItems: !bottomJaw ? 'flex-start !important' : 'flex-end !important'
          }}
        >
          <Box width={42} className='d-flex align-center justify-content-between pos-absolute' sx={{
            zIndex: 100,
            bottom: bottomJaw ? 9 : ''
          }}>
            {
              [lingual ? 'right' : 'left', 'center', lingual ? 'left' : 'right'].map((s: string, ind: number) => <Box
                height={'10px'}
                width={'10px'}
                key={ind}
              >
                {isFindingPresent(s, 'plaque') && <img src={Plaque} alt={'Plaque'} width={10} height={10} />}
              </Box>)
            }
          </Box>
          <Box className='d-flex' sx={{
            p: 0,
            m: 0,
            transform: lingual ? 'rotate(180deg)' : bottomJaw ? 'scaleX(-1)' : ''
          }}>
            {isNCCLs?.hasTooth && <PeriogramNcclFinding isNCCLs={isNCCLs} bottomJaw={bottomJaw} lingual={lingual} />}
            {lingual ? getLingual : getCrown}
          </Box>
        </Box>
          {!lingual && isNCCLs?.hasTooth && isNCCLs?.ncclsSubType?.includes('abfraction') &&
            <Box className='pos-absolute' sx={{ top: bottomJaw ? '-10px' : '-11px', left: bottomJaw ? 0 : 3 }}>
              <img src={abfraction} alt="abfraction" />
            </Box>
          }
        </Box>
      </Box>
    </Box>
  );
};

export default PeriogramTeeth;

