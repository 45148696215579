export const SELECT_ANNOTATION = 'SELECT_ANNOTATION';

// get annotation

export const GET_ANNOTATION_REQUEST = 'GET_ANNOTATION_REQUEST';
export const GET_ANNOTATION_REQUEST_SUCCESS = 'GET_ANNOTATION_REQUEST_SUCCESS';
export const GET_ANNOTATION_REQUEST_FAILURE = 'GET_ANNOTATION_REQUEST_FAILURE';

// put annotation
export const PUT_ANNOTATION_REQUEST = 'PUT_ANNOTATION_REQUEST';
export const PUT_ANNOTATION_REQUEST_SUCCESS = 'PUT_ANNOTATION_REQUEST_SUCCESS';
export const PUT_ANNOTATION_REQUEST_FAILURE = 'PUT_ANNOTATION_REQUEST_FAILURE';

//select marker
export const SELECT_MARKER = 'SELECT_MARKER';
