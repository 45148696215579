import { Typography } from '@mui/material'
import React from 'react'
import { makeStyles } from '@mui/styles'
import theme from '../../../../../../theme'

interface Props {
    isNCCLs: any
    bottomJaw: boolean | undefined
    lingual:boolean | undefined
}
const useStyles = makeStyles((theme: any) => ({
    ncclTypesLineWidth: {
        width: '44px'
    }
}));

const PeriogramNcclFinding = (props: Props) => {
    const { isNCCLs, bottomJaw,lingual } = props
    const { palette } = theme
    const classes = useStyles()
    return (
        <React.Fragment>
            {!lingual && isNCCLs?.hasTooth && isNCCLs?.ncclsSubType?.includes('abrasion') &&
              <React.Fragment>
                <Typography sx={{ border: `1px solid ${isNCCLs?.color}`, bottom: bottomJaw ? 0 : '', top: bottomJaw ? '' : 0, }} className={`${classes.ncclTypesLineWidth} f-10 pos-absolute`} ></Typography>
                <Typography sx={{ border: `1px solid ${isNCCLs?.color}`, bottom: bottomJaw ? 4 : '', top: bottomJaw ? '' : 4, }} className={`${classes.ncclTypesLineWidth} f-10 pos-absolute`} ></Typography>
              </React.Fragment>
            }
            {!lingual && isNCCLs?.hasTooth && isNCCLs?.ncclsSubType?.includes('erosion') &&
              <Typography sx={{ bottom: bottomJaw ? '-1px' : '', left: '35%', top: bottomJaw ? '' : 0, color: isNCCLs?.color }} className='f-10 pos-absolute' >ER</Typography>}
            {!lingual && isNCCLs?.hasTooth && isNCCLs?.ncclsSubType?.includes('attrition') &&
              <Typography sx={{ border: `1px solid ${isNCCLs?.color}`, bottom: bottomJaw ? '13px' : '', top: bottomJaw ? '' : 13, }} className={` ${classes.ncclTypesLineWidth} f-10 pos-absolute`}></Typography>}
            {!lingual && isNCCLs?.hasTooth && isNCCLs?.ncclsSubType?.includes('is_dentin_exposed') &&
              <Typography sx={{ width: '18px', height: '18px', border: `2px solid ${palette.warning.main}`, borderRadius: '50%', bottom: bottomJaw ? 0 : '', left: '27%', top: bottomJaw ? '' : 1, }} className='f-10 pos-absolute' ></Typography>}
        </React.Fragment>
    )
}

export default PeriogramNcclFinding