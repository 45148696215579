import React from 'react';
import { InputLabel, Tabs } from "@mui/material";
import Tab from "@mui/material/Tab";
import { makeStyles } from "@mui/styles";
import theme from "../../../../theme";

const useStyles = makeStyles((theme: any) => ({
    indicator: (isPatient: any) => ({
        backgroundColor: `${isPatient === true ? theme.palette.secondary.main : theme.palette.v2.primary.main} !important`,
        top: 0,
        borderRadius: '10px !important',
        height: '3px'
    }),
}));

type TabsProps = {
    value: number,
    tabData: any[]
    handleChange: any
    isPatient?: boolean | undefined
}
const TabsComponent = (props: TabsProps) => {
    const { value, tabData, handleChange, isPatient } = props;
    const classes = useStyles(isPatient)

    return (
        <Tabs
            classes={{ indicator: classes.indicator }}
            sx={{
                width: isPatient ? '100% !important' : 'auto',
                minHeight: "32px",
                position: 'relative',
                bottom: '-1px',
                "& .Mui-selected": {
                    background: `${isPatient ? theme.palette.secondary.light : theme.palette.common.white} !important`,
                    borderLeft: `1px solid ${theme.palette.divider}`,
                    borderRight: `1px solid ${theme.palette.divider}`,
                    borderBottom: 'none !important',
                    borderRadius: '0px !important',
                },
            }}
            value={value}
            aria-label="exam tabs"
            onChange={handleChange}
        >
            {tabData?.map((res: any, index: number) => (
                <Tab
                    key={index}
                    label={
                        <InputLabel
                            sx={{ color: theme.palette.text.darkGrey, }}
                            className={`fw-regular f-12 lh-14 f-w-400 cursor-pointer`}
                        >
                            {res?.title}
                        </InputLabel>
                    }
                    value={res?.value}
                />
            ))}
        </Tabs>
    )
}
export default TabsComponent