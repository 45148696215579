import {all, call, put, takeEvery} from 'redux-saga/effects';
import API from '../../../../../utils/api';
import {errorToaster, removeRefFromJson, successToaster} from '../../../../../utils/helper';
import {riskData} from '../../../../../utils/v2/preIntakeConstant/riskAssessmentConstant';
import {updateLoadingScreenAction} from '../../../../actions/v2/dentist/clinicalExam/clinicalExamFilterAction';
import {
    generateRiskAssessmentRequestFailureAction,
    generateRiskAssessmentRequestSuccessAction, getRiskAssessmentRequestAction,
    getRiskAssessmentRequestFailureAction,
    getRiskAssessmentRequestSuccessAction,
    putRiskAssessmentRequestFailureAction,
    putRiskAssessmentRequestSuccessAction,
    updateRiskAssessmentAction
} from '../../../../actions/v2/dentist/clinicalExam/riskAssessmentAction';
import {
    GENERATE_RISK_ASSESSMENT_REQUEST,
    GET_RISK_ASSESSMENT_REQUEST,
    PUT_RISK_ASSESSMENT_REQUEST
} from '../../../../ActionTypes/v2/dentist/clinicalExam/riskAssessment';
import {appointmentDetailsRequestAction} from '../../../../actions/v2/dentist/appointmentsAction';
import {addTreatment} from "../../../../actions/v2/dentist/keyFindingActions";


function* riskAssessmentRequestSaga(payload: any): any {
    try {
        const response = yield API.get(`/v2/risk/profile/${payload.payload?.appointment_id}`)
        if (payload?.payload?.callback) {
            yield call(payload?.payload?.callback, response?.data?.detail)
        }
        const data = response?.data?.detail
        yield put(
            getRiskAssessmentRequestSuccessAction(removeRefFromJson(data))
        )
        yield put(
            updateRiskAssessmentAction(removeRefFromJson(data))
        )

    } catch (e: any) {
        // TODO: not display error message for GET (for now)
        // errorToaster(e?.message)
        yield put(
            getRiskAssessmentRequestFailureAction({
                error: e?.message,
            })
        )
    }
}

function* putRiskAssessmentRequestSaga(payload: any): any {
    try {
        const risk_data = JSON.parse(JSON.stringify(payload?.payload)) as any
        riskData?.map((res: any) => {
            if (risk_data && res && risk_data?.data && risk_data?.data[res?.key] && risk_data?.data[res?.key]['status'] === 'Accept') {
                risk_data.data[res?.key]['score'] = risk_data?.data[res?.key]['ai_score']
            }
            if (risk_data && risk_data?.data && risk_data?.data[res?.key] && risk_data?.data[res?.key]) {
                delete risk_data?.data[res?.key]['ai_reason']
                delete risk_data?.data[res?.key]['ai_score']
                delete risk_data?.data[res?.key]['appointmentImages']
                delete risk_data?.data[res?.key]['appointmentXrays']
            }
        })
        delete risk_data?.data['datetime_created']
        delete risk_data?.data['lastUpdated']
        delete risk_data?.data['submit']

        let risk_assessment_data = {
            appointment_id: payload?.payload?.appointment_id,
            submit: payload?.payload?.submit,
            ...risk_data?.data
        }

        const response = yield API.put(`/v2/risk/profile/reviewed`, risk_assessment_data)

        yield put(getRiskAssessmentRequestAction({
            appointment_id: payload?.payload?.appointment_id
        }))
        if (payload?.payload?.callback) {
            yield call(payload?.payload?.callback, response?.data)
        }
        if (risk_data['submit']) {
            yield put(
                appointmentDetailsRequestAction({
                    appointment_id: payload?.payload?.appointment_id,
                })
            );
        } else {
            successToaster(response?.data?.meta?.message)
        }
        yield put(addTreatment(null));
        yield put(
            putRiskAssessmentRequestSuccessAction(response?.data?.detail)
        )

    } catch (e: any) {
        errorToaster(e?.message)
        yield put(
            putRiskAssessmentRequestFailureAction({
                error: e?.message,
            })
        )
    }
}


// generate risk assessment 

function* generateRiskAssessmentRequestSaga(payload: any): any {
    try {
        const response = yield API.get(`/v2/generate/global/risk/${payload?.payload?.patient_id}?appointment_id=${payload.payload?.appointment_id}?core_apis=true&is_pusher=true`)
        if (response && !payload?.payload?.fromPreClinical) {
            yield put(updateLoadingScreenAction(true))
        }
        yield put(
            generateRiskAssessmentRequestSuccessAction(
                response?.data?.detail
            )
        )
    } catch (e: any) {
        generateRiskAssessmentRequestFailureAction(
            e?.message,
        )
    }
}

function* riskAssessmentWatch() {
    yield takeEvery(GET_RISK_ASSESSMENT_REQUEST, riskAssessmentRequestSaga)
}

function* putRiskAssessmentWatch() {
    yield takeEvery(PUT_RISK_ASSESSMENT_REQUEST, putRiskAssessmentRequestSaga)
}

function* generateRiskAssessmentWatch() {
    yield takeEvery(GENERATE_RISK_ASSESSMENT_REQUEST, generateRiskAssessmentRequestSaga)
}

export default function* riskAssessmentSaga() {
    yield all([
        riskAssessmentWatch(),
        putRiskAssessmentWatch(),
        generateRiskAssessmentWatch()
    ])
}
