import { toothNumberForFindings } from "../helper";

export const impactedTeethSubtypes = [
  {
    value: "Partial Bone Impaction",
    label: "Partial Bone Impaction",
  },
  {
    value: "Complete Bone Impaction",
    label: "Complete Bone Impaction ",
  },
  {
    value: "Difficult Impaction",
    label: "Difficult Impaction ",
  },
];
export const angleSubtypes = [
  {
    value: "I Normal",
    label: "I Normal",
  },
  {
    value: "1 Malocclusion",
    label: "1 Malocclusion",
  },
  {
    value: "II Div 1",
    label: "II Div 1",
  },
  {
    value: "II Div 2",
    label: "II Div 2",
  },
  {
    value: "III",
    label: "III",
  },
  {
    value: "III Tendency",
    label: "III Tendency",
  },
];
export const defaultCacheAngleRightCuspid = [
  {
    name: "sub_type",
    value: "",
    option: angleSubtypes,
    type: "select",
    title: "Sub Type",
    dataType: "string",
  },
];
export const defaultCacheAngleRightMolar = [
  {
    name: "sub_type",
    value: "",
    option: angleSubtypes,
    type: "select",
    title: "Sub Type",
    dataType: "string",
  },
];
export const defaultCacheAngleLeftCuspid = [
  {
    name: "sub_type",
    value: "",
    option: angleSubtypes,
    type: "select",
    title: "Sub Type",
    dataType: "string",
  },
];
export const defaultCacheAngleLeftMolar = [
  {
    name: "sub_type",
    value: "",
    option: angleSubtypes,
    type: "select",
    title: "Sub Type",
    dataType: "string",
  },
];
export const lateralExclusionSubtypes = [
  {
    value: "Cuspid Rise",
    label: "Cuspid Rise",
  },
  {
    value: "Group Function",
    label: "Group Function",
  },
  {
    value: "With Crossover",
    label: "With Crossover",
  },
];
export const defaultCacheLateralRight = [
  {
    name: "sub_type",
    value: [],
    option: lateralExclusionSubtypes,
    type: "multi-select",
    title: "Sub Type",
    dataType: "string",
  },
];
export const defaultCacheLateralLeft = [
  {
    name: "sub_type",
    value: [],
    option: lateralExclusionSubtypes,
    type: "multi-select",
    title: "Sub Type",
    dataType: "string",
  },
];
export const protrusiveGuidanceSubType = [
  {
    label: "Centrals",
    value: "Centrals",
  },
  {
    label: "Canine to Canine",
    value: "Canine to Canine",
  },
  {
    label: "Posterior",
    value: "Posterior",
  },
];
export const defaultCacheProtrusiveGuidance = [
  {
    name: "sub_type",
    value: [],
    option: protrusiveGuidanceSubType,
    type: "multi-select",
    title: "Sub Type",
    dataType: "string",
  },
  // TODO: client's requirement to remove
  // {
  //   name: "tooth_number",
  //   value: [],
  //   option: toothNumberForFindings,
  //   type: "multi-select",
  //   title: "Tooth Number",
  //   dataType: "string",
  // },
  // {
  //   name: "note",
  //   value: "",
  //   type: "string",
  //   title: "Notes",
  //   dataType: "string",
  // },
];
export const midlineSubtypes = [
  {
    value: "Coincident with facial midline",
    label: "Coincident with facial midline",
  },
  {
    value: "Shifted to Left",
    label: "Shifted to Left",
  },
  {
    value: "Shifted to Right",
    label: "Shifted to Right",
  },
];
export const mandMidlineSubtypes = [
  {
    value: "Coincident with maxillary dental midline",
    label: "Coincident with maxillary dental midline",
  },
  {
    value: "Shifted to Left",
    label: "Shifted to Left",
  },
  {
    value: "Shifted to Right",
    label: "Shifted to Right",
  },
];
export const defaultCacheMaxMidlinePhiltrum = [
  {
    name: "sub_type",
    value: "",
    option: midlineSubtypes,
    type: "select",
    title: "Sub Type",
    dataType: "string",
  },
  {
    name: "dimension",
    value: null,
    type: "string",
    title: "Dimension (mm)",
    dataType: "number",
  },
];
export const defaultCacheMandMidline = [
  {
    name: "sub_type",
    value: "",
    option: mandMidlineSubtypes,
    type: "select",
    title: "Sub Type",
    dataType: "string",
  },
  {
    name: "dimension",
    value: null,
    type: "string",
    title: "Dimension (mm)",
    dataType: "number",
  },
];
export const overjetSubtypes = [
  {
    value: "Positive",
    label: "Positive",
  },
  {
    value: "Negative",
    label: "Negative",
  },
];
export const defaultCacheOverjet = [
  {
    name: "sub_type",
    value: "",
    option: overjetSubtypes,
    type: "select",
    title: "Sub Type",
    dataType: "string",
  },
  {
    name: "dimension",
    value: null,
    type: "string",
    title: "Dimension (mm)",
    placeholder: "mm",
    dataType: "number",
  },
];
export const defaultCacheOverjetAtmm = [
  {
    name: "dimensionPer",
    value: null,
    type: "string",
    title: "Dimension (%)",
    placeholder: "Percentage",
    dataType: "number",
  },
  {
    name: "dimensionMM",
    value: null,
    type: "string",
    title: "Dimension (mm)",
    placeholder: "mm",
    dataType: "number",
  },
];
export const defaultCacheOverjetAtPerc = [
  {
    name: "dimension",
    value: null,
    type: "string",
    title: "Dimension (%)",
    placeholder: "Percentage",
    dataType: "number",
  },
];
export const grade = [
  { label: "None", value: "None" },
  { label: "Mild (< 5 mm)", value: "Mild (< 5 mm)" },
  {
    label: "Moderate (>= 5 mm, < 10 mm)",
    value: "Moderate (>= 5 mm, < 10 mm)",
  },
  { label: "Severe (>= 10 mm)", value: "Severe (>= 10 mm)" },
];
export const defaultCacheCrowding = [
  {
    name: "grade",
    value: "",
    option: grade,
    type: "select",
    title: "Grade",
    dataType: "string",
  },
];
export const defaultCacheCrowdingMandibular = [
  {
    name: "grade",
    value: "",
    option: grade,
    type: "select",
    title: "Grade",
    dataType: "string",
  },
]
export const defaultCacheSpacingMaxillary = [
  {
    name: "grade",
    value: "",
    option: grade,
    type: "select",
    title: "Grade",
    dataType: "string",
  },
];
export const defaultCacheSpacingMandibular = [
  {
    name: "grade",
    value: "",
    option: grade,
    type: "select",
    title: "Grade",
    dataType: "string",
  },
];

export const defaultCacheMissingTeeth = [
  {
    name: "tooth_number",
    value: [],
    option: toothNumberForFindings,
    type: "multi-select",
    title: "Tooth Number",
    dataType: "string",
  },
];

export const defaultCacheImpactedTeeth = [
  {
    name: "tooth_number",
    value: [],
    option: toothNumberForFindings,
    type: "multi-select",
    title: "Tooth Number",
    dataType: "string",
  },
  {
    name: "sub_type",
    value: "",
    option: impactedTeethSubtypes,
    type: "select",
    title: "Sub Type",
    dataType: "string",
  },
];
