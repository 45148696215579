import { InsightAndRecommendationInterface } from "../../../../../types/dentistTypes";
import {
  UPDATE_INSIGHTS_AND_RECOMMENDATIONS,
  UPDATE_INSIGHTS_AND_RECOMMENDATIONS_CACHE_DATA_CLEAR_REQUEST,
  CHANGE_INSIGHTS_AND_RECOMMENDATIONS,
} from "../../../../../ActionTypes/v1/dentist/insightAndRecommendation";

const defaultInsightRecommendationData = [
  {
    title: "Occlusion Risk",
    status: true,
    reason: "",
    desc: "Nulla Lorem mollit cupidatat irure. Laborum magna nulla duis ullamco cillum dolor. Voluptate exercitation incididunt aliquip deserunt reprehenderit elit laborum.  Nulla Lorem mollit cupidatat irure.",
  },
  {
    title: "Caries Risk",
    status: false,
    reason: "",
    desc: "Nulla Lorem mollit cupidatat irure. Laborum magna nulla duis ullamco cillum dolor. Voluptate exercitation incididunt aliquip deserunt reprehenderit elit laborum.  Nulla Lorem mollit cupidatat irure. Laborum magna nulla duis ullamco cillum dolor. Voluptate exercitation incididunt aliquip deserunt reprehenderit elit laborum. ",
  },
];
const initialState: InsightAndRecommendationInterface = {
  insightRecommendation: null,
};
export const UpdateInsightRecommendationReducer = (
  state = initialState,
  action: any
) => {
  switch (action.type) {
    case UPDATE_INSIGHTS_AND_RECOMMENDATIONS:
      return {
        ...state,
        ...action.payload,
      };
    case UPDATE_INSIGHTS_AND_RECOMMENDATIONS_CACHE_DATA_CLEAR_REQUEST:
      return {
        ...state,
        insightRecommendation: null
      }  
    case CHANGE_INSIGHTS_AND_RECOMMENDATIONS:
      const { value, key, subKey } = action.payload.payload as any;
      let newRisk = state?.insightRecommendation as any;
      newRisk[key][subKey] = value;
      return {
        ...state,
        insightRecommendation: newRisk,
      };
    default:
      return { ...state };
  }
};
