import React, { useMemo, useRef } from 'react'
import CardComponent from '../../../common/CardComponent'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { Box, Grid, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useSelector } from "react-redux";
import theme from '../../../../../theme'
import SpeedoMeter from '../../speedometer'
import NoDataFound from '../../../../v1/Common/NoDataFound'
import { filters } from "../../../../../utils/v2/clinicalExamConstant";
import { RootState } from "../../../../../app/reducers/v2/rootReducer";

const useStyles = makeStyles((theme: any) => ({
  box: {
    padding: '12px',
    borderRadius: '8px',
    width: '269px',//184
    height: '54px',
    marginRight: '20px',
    '&:nth-child(7)': {
      marginRight: '0px',
    }
  },
  riskAssessment: {
    width: "275px",
    maxWidth: "275px",
  },
  boxGreen: {
    border: `1px solid ${theme.palette.v2.riskColor.green}`,
    color: theme.palette.v2.riskColor.green,
    backgroundColor: theme.palette.success.lightColor
  },
  boxYellow: {
    border: `1px solid ${theme.palette.warning.main}`,
    color: theme.palette.warning.main,
    backgroundColor: theme.palette.warning.lightColor
  },
  boxGreenYellow: {
    border: `1px solid ${theme.palette.v2.riskColor.greenYellow}`,
    color: theme.palette.v2.riskColor.greenYellow,
    backgroundColor: theme.palette.v2.riskColor.lightGreenYellow
  },
  boxYellowRed: {
    border: `1px solid ${theme.palette.v2.riskColor.yellowRed}`,
    color: theme.palette.v2.riskColor.yellowRed,
    backgroundColor: theme.palette.v2.riskColor.lightYellowRed
  },

  boxRed: {
    border: `1px solid ${theme.palette.v2.riskColor.red}`,
    color: theme.palette.v2.riskColor.red,
    backgroundColor: theme.palette.error.lightColor
  },
  label: {
    lineHeight: '17px',
    // width: '60%',
    fontSize: '14px',
    color: theme.palette.common.black50,
    wordBreak: 'break-word',
    padding: '0px 10px'

  },
  value: {
    lineHeight: '34px',
    // width: '20%',
    fontSize: '26px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
}))


const WellnessRiskScore = () => {
  const classes = useStyles();
  const ref = useRef() as any
  const [openSpeedometer, setOpenSpeedometer] = React.useState<boolean>(true) as any;
  const [isLeftButtonClickable, setIsLeftButtonClickable] = React.useState(false) as any;
  const [isRightButtonClickable, setIsRightButtonClickable] = React.useState(true) as any;
  const {
    wellnessProfileFilters: { wellnessRiskScoreDataData, wellnessProfileData }
  } = useSelector((state: RootState) => state);
  const wellnessData = wellnessRiskScoreDataData ? wellnessRiskScoreDataData : wellnessProfileData

  const riskData = useMemo(() => {
    if (!wellnessData?.risk_profile) return null;
    const formattedData = filters.map((item: any) => ({
      key: item?.key,
      name: `${item?.label} Risk`,
      score: wellnessData.risk_profile[item?.key]?.score || 0,
      confidence: `${wellnessData.risk_profile[item?.key]?.confidence || 0}%`,
    }));
    formattedData?.sort((a: any, b: any) => {
      return b?.score - a?.score;
    });
    return formattedData?.reduce((acc: any, curr: any) => {
      acc[curr.key] = { name: curr?.name, score: curr?.score, confidence: curr?.confidence };
      return acc;
    }, {}) as any;
  }, [wellnessData]);

  const handleScroll = (scrollOffset: any) => {
    if (ref.current) {
      const newScrollLeft = ref.current.scrollLeft + scrollOffset;
      const maxScrollLeft = ref.current.scrollWidth - ref.current.clientWidth;
      // Update the state variables based on scrollability
      setIsLeftButtonClickable(newScrollLeft > 0);
      setIsRightButtonClickable(newScrollLeft < maxScrollLeft);

      ref.current.scrollLeft += scrollOffset;
    }
  };

  const riskColorClass = (score: number) => {
    if (score <= 0.9) {
      return classes.boxGreen
    } else if (score <= 1.5) {
      return classes.boxGreenYellow
    } else if (score <= 3) {
      return classes.boxYellow
    } else if (score <= 3.7) {
      return classes.boxYellowRed
    } else {
      return classes.boxRed
    }
  }
  const dynamicRiskNameDisplay = (name: any) => {
    // Find the last occurrence of '/'

    const splitPoint = name?.lastIndexOf('/');

    // Split the text into two parts
    const part1 = name?.substring(0, splitPoint + 1); // Include the '/'
    const part2 = name?.substring(splitPoint + 1); // The part after the '/
    return {
      part1, part2
    }
  }

  return (
    <CardComponent bgColor={theme.palette.v2.primary.primary25} color={theme.palette.v2.primary.main} title='Risk Score' fromRiskScore openSpeedometer={openSpeedometer}
      setOpenSpeedometer={setOpenSpeedometer}>
      <Box className='d-flex'>
        {
          (isLeftButtonClickable) &&
          (riskData !== null) &&
          <Box
            sx={{
              borderRadius: '50%',
              background: theme.palette.v2.primary.main,
              marginTop: openSpeedometer ? '74px' : '23px',
              marginRight: '10px'
            }}
            width='20px'
            height={'20px'}
            className='d-flex-all-center'
          >
            <FontAwesomeIcon
              icon={faAngleLeft}
              onClick={() => handleScroll(-260)}
              className='cursor-pointer'
              color={theme.palette.common.white}
            />
          </Box>
        }
        <Grid
          container
          display={'flex'}
          overflow={'auto'}
          sx={{
            flexWrap: 'nowrap',
            scrollBehavior: 'smooth !important',
            "::-webkit-scrollbar": {
              display: " none",
              scrollBehavior: 'smooth !important'
            },
          }}
          ref={ref}
        >
          {riskData && Object.keys(riskData).map((res: any, index: number) => (
            <React.Fragment key={index}>
              {openSpeedometer ?
                <Grid
                  item
                  className={classes.riskAssessment}
                  display={'flex'} flexDirection={'column'} margin={'5px 0'}
                >
                  <SpeedoMeter data={riskData[res]?.score} />
                  <Box
                    className={`${classes.box} ${riskColorClass(riskData[res]?.score)}`}
                    display={'flex'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                  >
                    <Typography sx={{
                      lineHeight: '34px',
                      fontSize: '20px',
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }} className={` fw-regular`} color={theme.palette.common.black50} >
                      {riskData[res]?.confidence}
                    </Typography>
                    <Typography className={`${classes.label} fw-regular`}>
                      {dynamicRiskNameDisplay(riskData[res]?.name)?.part1}
                      <Typography className={`fw-regular`}> {dynamicRiskNameDisplay(riskData[res]?.name)?.part2}</Typography>
                    </Typography>
                    <Typography className={`${classes.value} fw-medium`} pt={'5px'}>
                      {(riskData[res]?.score)?.toFixed(1)}
                    </Typography>
                  </Box>
                </Grid>
                :
                <Grid item className={classes.riskAssessment}>
                  <Box
                    margin={'5px 0'}
                    sx={{ width: '260px !important' }}
                    className={`${classes.box} ${riskColorClass(riskData[res]?.score)}`}
                    display={'flex'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                  >
                    <Typography sx={{
                      lineHeight: '34px',
                      fontSize: '20px',
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }} className={` fw-regular`} color={theme.palette.common.black50} >
                      {riskData[res]?.confidence}
                    </Typography>
                    <Typography className={`${classes.label} fw-regular`}>
                      {dynamicRiskNameDisplay(riskData[res]?.name)?.part1}
                      <Typography className={`fw-regular`}> {dynamicRiskNameDisplay(riskData[res]?.name)?.part2}</Typography>
                    </Typography>
                    <Typography className={`${classes.value} fw-medium`} pt={'5px'}>
                      {(riskData[res]?.score)?.toFixed(1)}
                    </Typography>
                  </Box>
                </Grid>
              }
            </React.Fragment>
          ))}
          {
            (riskData === null) &&
            <Box className={'d-flex-all-center fw-regular'} width={'100%'} mt={1.5} mb={1.5}>
              <NoDataFound errorMessage={"Data"} />
            </Box>
          }
        </Grid>
        {
          (isRightButtonClickable) &&
          (riskData !== null) &&
          <Box
            sx={{
              borderRadius: '50%',
              background: theme.palette.v2.primary.main,
              marginTop: openSpeedometer ? '74px' : '23px',
              marginLeft: '10px'
            }}
            width='20px'
            height={'20px'}
            className='d-flex-all-center'
          >
            <FontAwesomeIcon
              icon={faAngleRight}
              onClick={() => handleScroll(260)}
              className='cursor-pointer'
              color={theme.palette.common.white}
            />
          </Box>
        }
      </Box>
    </CardComponent>
  )
}

export default WellnessRiskScore