import {all, call, put, takeEvery} from "redux-saga/effects";
import {
    staffProfileNewCacheDataClearRequest
} from "../../../actions/v2/dentistProfileNewAction";
import API from "../../../../utils/api";
import {GET_PATIENT_LIST_REQUEST} from "../../../ActionTypes/v2/dentist/patientsList";
import {
    getPatientListRequestFailure,
    getPatientListRequestSuccess
} from "../../../actions/v2/dentist/patientsListActions";

function* patientsListRequestSaga(payload: any): any {
    yield put(staffProfileNewCacheDataClearRequest());
    try {
        const params = [
            {key: 'search', value: payload.payload.search},
            {key: 'page', value: payload.payload.page},
            {key: 'size', value: payload.payload.size},
        ]
        if (payload.payload.clinic_id) {
            params.push({key: 'clinic_id', value: payload.payload.clinic_id})
        }
        let tmpUrl = `?`
        params?.map((p: any) => {
            if (tmpUrl.length > 1) {
                if (p.value) {
                    tmpUrl = `${tmpUrl}&${p.key}=${p.value}`
                }
            } else {
                if (p.value) {
                    tmpUrl = `${tmpUrl}${p.key}=${p.value}`
                }
            }
            return true
        })
        const response = yield API.get(`/v2/patient/get/all${tmpUrl}`)
        yield put(
            getPatientListRequestSuccess({
                getPatientData: response?.data?.detail,
                getPatientMeta: response?.data?.meta
            })
        );
        if (payload.payload.callback) {
            yield call(payload.payload.callback, response?.data?.detail);
        }
    } catch (e: any) {
        yield put(
            getPatientListRequestFailure({
                getPatientError: e?.detail
            })
        );
    }
}

function* patientsListRequestWatch() {
    yield takeEvery(GET_PATIENT_LIST_REQUEST, patientsListRequestSaga);
}

export default function* patientsListSaga() {
    yield all([patientsListRequestWatch()]);
}
