import { muscleDentistInterface } from "../../../../types/dentistTypes";
import {
  UPDATE_MUSCLE_DENTIST, UPDATE_MUSCLE_DENTIST_FAILURE,
  CHANGE_MUSCLE_DENTIST,
} from "../../../../ActionTypes/v1/dentist/clinicalExamDentist";
import { masseterData, temporalisData, medialPterygoidData, lateralPterygoidData, otherData } from "../../../../../utils/dentistMuscleExamConstant"
import { masseterCacheData, temporalisCacheData, medialPterygoidCacheData, lateralPterygoidCacheData, otherCacheData } from "../../../../../utils/cacheDataConstant/dentistMuscleExamConstant";
import { jsonParseAndStringfy } from "../../../../../utils/helper";

const initialState: muscleDentistInterface = {
  masseter: masseterData,
  temporalis: temporalisData,
  medialPterygoid: medialPterygoidData,
  lateralPterygoid: lateralPterygoidData,
  other: otherData,
  muscle_comment: ''
};

export const muscleDentistReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case UPDATE_MUSCLE_DENTIST:
      return {
        ...state,
        ...action.payload,
      };
    case UPDATE_MUSCLE_DENTIST_FAILURE:
      const newMasseterData = jsonParseAndStringfy(masseterCacheData)
      const newTemporalisData = jsonParseAndStringfy(temporalisCacheData)
      const newMedialPterygoidData = jsonParseAndStringfy(medialPterygoidCacheData)
      const newLateralPterygoidData = jsonParseAndStringfy(lateralPterygoidCacheData)
      const newOtherData = jsonParseAndStringfy(otherCacheData)
      return {
        masseter: newMasseterData,
        temporalis: newTemporalisData,
        medialPterygoid: newMedialPterygoidData,
        lateralPterygoid: newLateralPterygoidData,
        other: newOtherData,
        muscle_comment: ''
      }
    case CHANGE_MUSCLE_DENTIST:
      const { toFinding, value, key } = action.payload as any;
      const newState = state as any;
      let newFinding = newState[toFinding] as any;
      if (typeof newFinding !== "string") {
        newFinding = newFinding?.map((res: any) => {
          if (res.name === key) {
            return { ...res, value: value };
          }
          return res;
        });
      } else {
        newFinding = value;
      }
      return {
        ...state,
        [toFinding]: newFinding,
      };
    default:
      return { ...state };
  }
};
