import { all, call, put, takeEvery } from "redux-saga/effects";
import API from "../../../../../utils/api";
import {
  getIntraOralDentistRecallExamDataRequestSuccess,
  getIntraOralDentistRecallExamDataRequestFailure,
  putIntraOralDentistRecallExamDataRequestFailure,
  putIntraOralDentistRecallExamDataRequestSuccess,
} from "../../../../actions/v1/dentist/clinicalExamDentistRecallExamActions/intraoralDentistRecallExamAction";
import {
  GET_INTRA_ORAL_DENTIST_RECALL_EXAM_REQUEST,
  PUT_INTRA_ORAL_DENTIST_RECALL_EXAM_REQUEST,
} from "../../../../ActionTypes/v1/dentist/clinicalExamDentistRecallExam";
import { updateIntraOralDentistRecallExamRequestFailure } from "../../../../actions/v1/dentist/clinicExamDentistActions/intraOralDentistRecallExamAction";
import { errorToaster } from '../../../../../utils/helper';

function* getIntraOralDentistRecallExamRequestSaga(payload: any): any {
  yield put(updateIntraOralDentistRecallExamRequestFailure())
  try {
    const response = yield API.get(
      `/exam/dentist/recall/intraOral/${payload?.payload?.appointment_id}?appt_type=${payload?.payload?.appt_type}`
    );
    yield put(
      getIntraOralDentistRecallExamDataRequestSuccess({
        data: response.data,
      })
    );
    yield call(payload.payload.callback, response.data);

    if (payload.payload.callback) {
      payload.payload.callback(response.data);
    }
  } catch (e: any) {
    yield put(updateIntraOralDentistRecallExamRequestFailure())
    yield put(
      getIntraOralDentistRecallExamDataRequestFailure({
        error: e?.detail,
      })
    );
  }
}
function* putIntraOralDentistRecallExamRequestSaga(payload: any): any {
  try {
    const response = yield API.put(
      `/exam/dentist/recall/intraOral`,
      payload?.payload?.intraOralData
    );
    if (payload?.payload?.callback) {
      yield call(payload?.payload?.callback, response?.data);
    }
    yield put(
      putIntraOralDentistRecallExamDataRequestSuccess({
        data: response.data,
      })
    );
  } catch (e: any) {
    errorToaster(e?.detail)
    yield put(
      putIntraOralDentistRecallExamDataRequestFailure({
        error: e?.detail,
      })
    );
  }
}
function* getIntraOralDentistRecallSaga() {
  yield takeEvery(
    GET_INTRA_ORAL_DENTIST_RECALL_EXAM_REQUEST,
    getIntraOralDentistRecallExamRequestSaga
  );
}
function* putIntraOralDentistRecallSage() {
  yield takeEvery(
    PUT_INTRA_ORAL_DENTIST_RECALL_EXAM_REQUEST,
    putIntraOralDentistRecallExamRequestSaga
  );
}
export default function* rootSaga() {
  yield all([getIntraOralDentistRecallSaga(), putIntraOralDentistRecallSage()]);
}
