export const UPDATE_PATIENT_INFO = 'UPDATE_PATIENT_INFO';
export const UPDATE_DENTIST_INSURANCE_INFO = 'UPDATE_DENTIST_INSURANCE_INFO';
export const UPDATE_EMERGENCY_CONTACT_INFO = 'UPDATE_EMERGENCY_CONTACT_INFO';
export const UPDATE_GENERAL_HEALTH = 'UPDATE_GENERAL_HEALTH';
export const UPDATE_SLEEP_QUALITY = 'UPDATE_SLEEP_QUALITY';
export const UPDATE_MEDICAL_HEALTH_HISTORY = 'UPDATE_MEDICAL_HEALTH_HISTORY';
export const UPDATE_LETS_GET_TO_KNOW_YOU_BETTER = 'UPDATE_LETS_GET_TO_KNOW_YOU_BETTER';
export const UPDATE_DENTAL_INFO = 'UPDATE_DENTAL_INFO';
export const UPDATE_COMMUNICATION_PREFERENCES = 'UPDATE_COMMUNICATION_PREFERENCES';

/*Get pre-intake api*/
export const GET_PRE_INTAKE_DATA_REQUEST = 'GET_PRE_INTAKE_DATA_REQUEST';
export const GET_PRE_INTAKE_DATA_SUCCESS = 'GET_PRE_INTAKE_DATA_SUCCESS';
export const GET_PRE_INTAKE_DATA_FAIL = 'GET_PRE_INTAKE_DATA_FAIL';

/*Put pre-intake api*/
export const PUT_PRE_INTAKE_DATA_REQUEST = 'PUT_PRE_INTAKE_DATA_REQUEST';
export const PUT_PRE_INTAKE_DATA_SUCCESS = 'PUT_PRE_INTAKE_DATA_SUCCESS';
export const PUT_PRE_INTAKE_DATA_FAIL = 'PUT_PRE_INTAKE_DATA_FAIL';

/*pre-intake validations*/
export const UPDATE_PRE_INTAKE_VALIDATIONS = 'UPDATE_PRE_INTAKE_VALIDATIONS';

export const IS_PRE_INTAKE_SUBMIT = 'IS_PRE_INTAKE_SUBMIT';

/*Download pre-intake*/
export const DOWNLOAD_PRE_INTAKE_PDF_REQUEST = 'DOWNLOAD_PRE_INTAKE_PDF_REQUEST';
export const DOWNLOAD_PRE_INTAKE_PDF_SUCCESS = 'DOWNLOAD_PRE_INTAKE_PDF_SUCCESS';
export const DOWNLOAD_PRE_INTAKE_PDF_FAIL = 'DOWNLOAD_PRE_INTAKE_PDF_FAIL';


// get field for pre-intake
export const GET_FIELDS_REQUEST = 'GET_FIELDS_REQUEST';
export const GET_FIELDS_REQUEST_SUCCESS = 'GET_FIELDS_REQUEST_SUCCESS';
export const GET_FIELDS_REQUEST_FAIL = 'GET_FIELDS_REQUEST_FAIL';