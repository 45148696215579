import { faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Divider, Grid, Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { makeStyles } from '@mui/styles';
import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setExamFindingsAction } from '../../../../../app/actions/v2/dentist/clinicalExam/exam/examFindingsTypesAction';
import { updateChatBoxValueAction } from '../../../../../app/actions/v2/master/chatBoxActions';
import { RootState } from '../../../../../app/reducers/v2/rootReducer';
import theme from '../../../../../theme';
import ExamsCustomFields from '../ExamsCustomFields';
import TagField from "../TagField";
import QLogo from '../../../../../assets/images/v2/QLogo.png'
import {
  completeCheckout,
  limitedExam,
  photo,
  viewExam,
  xray
} from '../../../../../utils/v2/clinicalExamConstant';
import { periogramFindingNotDisplayInForm } from '../../../../../utils/v2/examConstant';


const useStyles = makeStyles((theme: any) => ({
  accordionRoot: {
    margin: '0 !important',
    '& .MuiAccordion-root': {
      margin: '0 !important'
    }
  },
  accordionSummary: {
    color: theme.palette.v2.primary.main,
    height: '42px',
    backgroundColor: theme.palette.v2.primary.primary25,
    padding: '10px 12px',
    border: `1px solid ${theme.palette.v2.primary.main}`
  },
  accordionDetails: {
    padding: '16px 20px !important',
    backgroundColor: theme.palette.common.white
  },
  expanded: {
    '&$expanded': {
      minHeight: 42,
      maxHeight: 42,
    }
  },
  addButton: {
    color: theme.palette.common.white,
    background: theme.palette.v2.primary.main,
    height: '34px',
    minWidth: '30px',
    '&:hover': {
      color: theme.palette.common.white,
      background: theme.palette.v2.primary.main,
    },
  },
  deleteButton: {
    color: theme.palette.error.main,
    background: theme.palette.common.white,
    borderColor: theme.palette.error.main,
    height: '34px',
    minWidth: '30px',
    '&:hover': {
      color: theme.palette.error.main,
      background: theme.palette.common.white,
      borderColor: theme.palette.error.main,
    },
  }
}));
type Props = {
  meta: any;
  data: any;

};
type examConstantInterface = {
  key?: string;
  title?: string;
  risks?: Array<string>;
  examType?: Array<string>;
  findingTypes?: Array<string>;
  examGroup?: Array<string>;
  options?: Array<any>;
  isMulti: boolean;
  hasTooltip: boolean
  disableFindingTypes?: Array<string>;
  displayOdontogramForm?:boolean
};
const ExamGroup = (props: Props) => {
  const { meta, data } = props;
  const classes = useStyles();
  const dispatch = useDispatch()
  const location = useLocation()
  const isWellnessProfile = location?.pathname?.includes('/v2/dentist/wellness-profile/')
  const [expandedSubMenu, setExpandedSubMenu] = React.useState<boolean>(true) as any;
  const {
    examFindingReducer: { addExistingFindingData, examFindingData },
    appointmentsReducer: { appointmentDetails },
    clinicalExamFilters: { findingType },
    wellnessProfileFilters: {wellnessProfileStep},
  } = useSelector((state: RootState) => state) as any
    const tabData = isWellnessProfile ? wellnessProfileStep : findingType
  const isPhotoXray = tabData === photo || tabData === xray

  const showNormalLabel = (info: any) => {
    return info?.options?.filter((f: any) => f?.label?.length < 1)?.length
  }
  const multiplyFindings = (exam: string, finding: any) => {
    const updatedExamFindingData = examFindingData
    const defaultValue = {} as any
    Object.keys(updatedExamFindingData[exam][finding][0])?.map((key: string) => {
      defaultValue[key] = null
      return true
    })
    const updatedFinding = updatedExamFindingData[exam][finding];
    updatedFinding?.push(defaultValue)
    dispatch(setExamFindingsAction(updatedExamFindingData))
  }
  const deleteFinding = (deleteIndex: number, exam: string, finding: any) => {
    const updatedExamFindingData = examFindingData as any
    updatedExamFindingData[exam][finding] =
      updatedExamFindingData[exam][finding]?.filter((_: any, i: number) => i !== deleteIndex);
    dispatch(setExamFindingsAction(updatedExamFindingData))
  }


  const getMultipleFindingsData = (res: any) => {
    if (res?.isMulti && res?.examGroup && examFindingData && examFindingData[res?.examGroup] && examFindingData[res?.examGroup][res?.key]) {
      const findingLength = examFindingData[res?.examGroup][res?.key]?.length
      const newFindings = []
      for (let index = findingLength; index > 0; index--) {
        newFindings?.push(res)
      }
      return newFindings
    }
    return [res]
  }

  const disabledField = useMemo(() => {
    return (
        [completeCheckout, viewExam]?.includes(appointmentDetails?.sub_status) ||
      isWellnessProfile 
    );
  }, [appointmentDetails, location.pathname]);


  return (
    <Accordion
      expanded={expandedSubMenu}
      onChange={() => setExpandedSubMenu(!expandedSubMenu)}
      className={expandedSubMenu ? classes.accordionRoot : ''}
    >
      <AccordionSummary
        expandIcon={
          <FontAwesomeIcon className='cursor-pointer f-16' icon={faAngleUp}
            color={theme.palette.v2.primary.main} />
        }
        className={`${classes.accordionSummary} ${classes.expanded}`}
      >        <Box className='d-flex justify-content-between align-center w-100' mr={2.5}>
          <Typography className='f-16 f-w-400 fw-regular'>{meta?.label}</Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>
        {isPhotoXray ? <React.Fragment>
          {/* When it's from photos or xray */}
          <TagField meta={meta} data={data} />
        </React.Fragment> : data.map((res: examConstantInterface, i: number) =>
        (!periogramFindingNotDisplayInForm?.includes(res?.key) && <React.Fragment key={i}>
          {/* Check if finding is from  existing exam then show only findings those are selected from popup*/}
          {meta?.key !== 'existing_treatment' || addExistingFindingData?.includes(res?.key) ?
            <Box>
              <Box className='d-flex align-center' mb={1}>
                <Typography mr={1.2} className={`${showNormalLabel(res) ? 'f-14 fw-regular' : 'f-18 f-w-500 fw-medium'} `}
                  sx={{
                    // textTransform: 'capitalize !important',
                    // TODO: use in future for disable title
                    // opacity: (disabledField || res?.disableFindingTypes?.includes(findingTypes[tabData])) ? 0.5 : 1
                  }}
                  color={showNormalLabel(res) ? theme.palette.common.black50 : theme.palette.v2.primary.main}>{res?.title}</Typography>
                {res?.hasTooltip &&
                  <img
                    width={25}
                    height={25}
                    className={"cursor-pointer"}
                    src={QLogo}
                    alt="logo"
                    onClick={() => dispatch(updateChatBoxValueAction({ chatBoxValue: true, keyName: res?.key }))}
                  />
                }
              </Box>
              {getMultipleFindingsData(res)?.map((_: any, j: number) => <React.Fragment key={j}>
                <Grid container columnSpacing={1} rowSpacing={1} mb={1}>
                  {res?.options?.map((option: any, k: number) => (
                    <React.Fragment key={k}>
                      <ExamsCustomFields displayOdontogramForm={res?.displayOdontogramForm} exam={tabData !== limitedExam ? meta?.key : res?.examGroup} fieldData={option} finding={res?.key} index={j} isMulti={res?.isMulti} findingData={getMultipleFindingsData(res)} disableFindingTypes={res?.disableFindingTypes} />
                    </React.Fragment>
                  ))}
                </Grid>
                {getMultipleFindingsData(res)?.length > 1 && (!disabledField) &&
                  <React.Fragment>
                    <Box className='d-flex justify-end' mb={1}>
                      <Button variant='outlined' className={classes.deleteButton}
                        onClick={() => deleteFinding(j, meta?.key, res?.key)}>
                        Delete
                      </Button>
                    </Box>
                  </React.Fragment>
                }
                {res?.isMulti && <Divider sx={{ width: '100%', margin: '10px 0px' }} />}

              </React.Fragment>
              )}
              {(!isPhotoXray && res?.isMulti) && (!disabledField) && <Box className='d-flex justify-end' mb={1}>
                <Button variant='contained' className={classes.addButton}
                  onClick={() => multiplyFindings(meta?.key, res?.key)}>
                  Add
                </Button>
              </Box>}
              {res?.isMulti && <Divider sx={{ width: '100%', margin: '10px 0px', borderColor: theme.palette.v2.primary.main }} />}
            </Box>
            : null
          }
          {!res?.isMulti && (data?.length !== (i + 1)) && <Divider sx={{ width: '100%', margin: '15px 0px' }} />}
        </React.Fragment>)
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default ExamGroup;
