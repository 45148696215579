import { Box, Button, Tooltip, Typography } from '@mui/material'
import React, { useMemo, useState } from 'react'
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { makeStyles } from '@mui/styles';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from 'react-router-dom';
import PDFPasswordInfo from '../PDFPasswordInfo';
import theme from '../../../../../theme';
import { downloadPdfRequest } from "../../../../../app/actions/v1/downloadPdf/downloadPdf";
import { downloadPdfFromUrl, patientProfileData } from "../../../../../utils/helper";
import FullScreenLoader from "../../../../v1/Common/FullScreenLoader";
import { RootState } from '../../../../../app/reducers/v2/rootReducer';
import { downloadPdfWithoutAccountRequest } from '../../../../../app/actions/v2/patient/WellnessProfileWithoutAccountAction';

const useStyles = makeStyles((theme: any) => ({
    tooltip: {
        color: theme.palette.common.black,
    },
    arrow: {
        color: `${theme.palette.common.white} !important`,
    },
    customWidth: {
        fontWeight: 600,
        backgroundColor: `${theme.palette.common.white} !important`,
        color: theme.palette.common.black,
        padding: "20px !important",
        borderRadius: "10px",
        boxShadow:
            "0px 1px 3px 0px rgba(0, 0, 0, 0.2),0px 1px 1px 0px rgba(0, 0, 0, 0.14),0px 2px 1px -1px rgba(0, 0, 0, 0.12)",
    },
    button: {
        borderRadius: "10px !important",
        width: 147,
        height: 40,
        [theme.breakpoints.down(750)]: {
            width: 120,
        },
        [theme.breakpoints.down(539)]: {
            width: 115,
            fontSize: "11px!important",
            padding: "0 !important",
            lineHeight: "10px !important",
            marginRight: "0 !important ",
            margin: "0 5px 0 5px !important",
        },
        [theme.breakpoints.down(454)]: {
            width: 110,
        },
        [theme.breakpoints.down(360)]: {
            width: 90,
        },
    },
}))

const PatientWellnessButtons = () => {
    const classes = useStyles();
    const dispatch = useDispatch()
    const location = useLocation()
    const { clinicId, patientId } = useParams()
    const [openTooltip, setOpenTooltip] = useState(false);
    const patient_id = patientProfileData?.id;
    const {
        patientWellnessProfileWithoutAccount: { withoutAccountGetWellnessProfileData },
    } = useSelector((state: RootState) => state);
    const { downloadPdfLoader } = useSelector((state: any) => state?.downloadPdfReducer)

    const clinicData = localStorage.getItem('patientClinicInfo');
    const clinicIdLocal = clinicData && JSON.parse(clinicData)?.clinic_id

    const withoutAccountWellnessProfile: boolean = useMemo(() => {
        return location?.pathname?.includes('/patient/without-account/wellness-profile/')
    }, [location.pathname]);

    const handlePdf = () => {

        if (withoutAccountWellnessProfile) {
            let pdfName = `${withoutAccountGetWellnessProfileData.first_name}_${withoutAccountGetWellnessProfileData.last_name}_Risk_Profile`;
            dispatch(
                downloadPdfWithoutAccountRequest({
                    patientID: patientId,
                    clinicID: clinicId,
                    callback: (data: any) => {
                        downloadPdfFromUrl(data?.detail?.pdf_url, pdfName);
                    },
                })
            );
        } else {
            let pdfName = `${patientProfileData.first_name}_${patientProfileData.last_name}_Risk_Profile`;
            dispatch(
                downloadPdfRequest({
                    patientID: patient_id,
                    clinicID: clinicIdLocal,
                    callback: (data: any) => {
                        downloadPdfFromUrl(data?.detail?.pdf_url, pdfName);
                    },
                })
            );
        }
    };

    return (
        <Box display={"flex"} flexDirection="row" alignItems={'center'}>
            {downloadPdfLoader && <FullScreenLoader />}
            <Button
                onClick={handlePdf}
                className={`${classes.button} fw-regular`}
                sx={{
                    background: theme.palette.secondary.main,
                    color: theme.palette.common.white,
                    "&:hover": {
                        background: theme.palette.secondary.main,
                        color: theme.palette.common.white,
                    },
                }}
            >
                Download PDF
            </Button>
            <ClickAwayListener onClickAway={() => setOpenTooltip(false)}>
                <Tooltip
                    className={`${classes.tooltip} fw-regular`}
                    classes={{
                        tooltip: classes.customWidth,
                        arrow: classes.arrow,
                    }}
                    arrow
                    open={openTooltip}
                    placement="bottom-start"
                    title={<PDFPasswordInfo />}
                >
                    <Typography
                        className={`fw-regular`}
                        sx={{
                            cursor: "pointer",
                            textDecoration: "underline",
                            marginTop: "6px",
                            color: `${theme.palette.secondary.main} !important`,
                            marginLeft: "10px",
                            fontSize: {
                                xs: '12px',
                                sm: '16px'
                            }
                        }}
                        onClick={() => setOpenTooltip(!openTooltip)}
                    >
                        <Tooltip title={'Click here to know your password.'}>
                            <FontAwesomeIcon icon={faCircleInfo} />
                        </Tooltip>
                        {/*Know your Password?*/}
                    </Typography>
                </Tooltip>
            </ClickAwayListener>
        </Box>
    )
}

export default PatientWellnessButtons