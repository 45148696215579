import { downloadPdfActions, downloadPdfInterface } from "../../../types/dentistTypes";
import {
    DOWNLOAD_PDF_REQUEST,
    DOWNLOAD_PDF_REQUEST_SUCCESS,
    DOWNLOAD_PDF_REQUEST_FAILURE,
} from '../../../ActionTypes/v1/downloadPdf'


const initialState: downloadPdfInterface = {
    downloadPdfData: null,
    downloadPdfLoader: false,
    downloadPdfError: null,

};

const downloadPdfReducer = (state = initialState, action: downloadPdfActions) => {
    switch (action.type) {
        case DOWNLOAD_PDF_REQUEST:
            return {
                ...state,
                downloadPdfLoader: true
            };

        case DOWNLOAD_PDF_REQUEST_SUCCESS:
          
            return {
                ...state,
                downloadPdfLoader: false,
                downloadPdfError: null,
                downloadPdfData: action?.payload?.downloadPdfData
            };
        case DOWNLOAD_PDF_REQUEST_FAILURE:
            return {
                ...state,
                downloadPdfLoader: false,
                downloadPdfError: action?.payload?.downloadPdfError
            };
        default:
            return {
                ...state
            };
    }

}

export default downloadPdfReducer