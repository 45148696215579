import {
    GET_OCCLUSION_DENTIST_RECALL_EXAM_REQUEST,
    GET_OCCLUSION_DENTIST_RECALL_EXAM_REQUEST_SUCCESS,
    GET_OCCLUSION_DENTIST_RECALL_EXAM_REQUEST_FAILURE,
    PUT_OCCLUSION_DENTIST_RECALL_EXAM_REQUEST,
  PUT_OCCLUSION_DENTIST_RECALL_EXAM_REQUEST_SUCCESS,
  PUT_OCCLUSION_DENTIST_RECALL_EXAM_REQUEST_FAILURE,
} from "../../../../ActionTypes/v1/dentist/clinicalExamDentistRecallExam";

import {
    GetOcclusionDentistRecallExamRequestPayload,
    GetOcclusionDentistRecallExamRequestSuccessPayload,
    GetOcclusionDentistRecallExamRequestFailurePayload,
    GetOcclusionDentistRecallExamRequest,
    GetOcclusionDentistRecallExamRequestSuccess,
    GetOcclusionDentistRecallExamRequestFailure,

    PutOcclusionDentistRecallExamRequestPayload,
    PutOcclusionDentistRecallExamRequestSuccessPayload,
    PutOcclusionDentistRecallExamRequestFailurePayload,
    PutOcclusionDentistRecallExamRequest,
    PutOcclusionDentistRecallExamRequestSuccess,
    PutOcclusionDentistRecallExamRequestFailure
} from "../../../../types/dentistTypes";

export const getOcclusionDentistRecallExamRequest = (payload: GetOcclusionDentistRecallExamRequestPayload ): GetOcclusionDentistRecallExamRequest => ({
    type: GET_OCCLUSION_DENTIST_RECALL_EXAM_REQUEST,
    payload
}) as any

export const getOcclusionDentistRecallExamDataRequestSuccess = (payload: GetOcclusionDentistRecallExamRequestSuccessPayload): GetOcclusionDentistRecallExamRequestSuccess => ({
    type: GET_OCCLUSION_DENTIST_RECALL_EXAM_REQUEST_SUCCESS,
    payload
}) as any

export const getOcclusionDentistRecallExamDataRequestFailure = (payload: GetOcclusionDentistRecallExamRequestFailurePayload): GetOcclusionDentistRecallExamRequestFailure => ({
    type: GET_OCCLUSION_DENTIST_RECALL_EXAM_REQUEST_FAILURE,
    payload
}) as any

export const putOcclusionDentistRecallExamRequest = (payload: PutOcclusionDentistRecallExamRequestPayload ): PutOcclusionDentistRecallExamRequest => ({
    type: PUT_OCCLUSION_DENTIST_RECALL_EXAM_REQUEST,
    payload
}) as any

export const putOcclusionDentistRecallExamDataRequestSuccess = (payload: PutOcclusionDentistRecallExamRequestSuccessPayload): PutOcclusionDentistRecallExamRequestSuccess => ({
    type: PUT_OCCLUSION_DENTIST_RECALL_EXAM_REQUEST_SUCCESS,
    payload
}) as any

export const putOcclusionDentistRecallExamDataRequestFailure = (payload: PutOcclusionDentistRecallExamRequestFailurePayload): PutOcclusionDentistRecallExamRequestFailure => ({
    type: PUT_OCCLUSION_DENTIST_RECALL_EXAM_REQUEST_FAILURE,
    payload
}) as any
