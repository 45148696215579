import { clinicalExamDentistActions } from "../../../../types/dentistTypes";
import {
  POST_CLINICAL_EXAM_DENTIST_DATA_REQUEST,
  POST_ADD_MULTIPLE_FINDING_DENTIST,
  POST_DELETE_MULTIPLE_FINDING_DENTIST,
} from "../../../../ActionTypes/v1/dentist/clinicalExamDentist";

const initialState: any = {
  data: {
    appointment_id: "",
    patient_id: "",
    asa_classification: "",
    dental_medical_risk_notes: "",
    overall_medical_risk: " ",
    extra_oral_exam: {
      tissue_subtype: "",
      tissue_notes: "",
      thyroid_subtype: "",
      thyroid_notes: "",
      skeletal_class_subtype: "",
      tag_count_xray: "",
      tag_count_image: "",
    },
    tmj_exam: {
      click_subtype: "",
      click_notes: "",
      pain_subtype: "",
      pain_notes: "",
      crepitus_subtype: "",
      crepitus_notes: "",
      click_change_2_years: "",
      click_start_date: "",
      click_hx_notes: "",
      locked_jaw_hx_notes: "",
      pain_scale: " ",
      trigger_notes: "",
      headache_present: "",
    },
    muscle_exam: {
      temporalis_subtype: "",
      temporalis_notes: "",
      medial_pterygoid_subtype: "",
      medial_pterygoid_notes: "",
      lateral_pterygoid_subtype: "",
      lateral_pterygoid_notes: "",
      masseter_pain_subtype: "",
      masseter_pain_notes: "",
      other_muscle_notes: "",

    },
    intra_oral_exam: {
      cancer_screen_subtype: "",
      cancer_screen_notes: "",
      salivary_flow_subtype: "",
      salivary_flow_notes: "",
      soft_tissue_exam: "",

      tag_count_xray: "",
      tag_count_image: "",
    },
    occlusion_exam: {
      max_comfortable_opening_mm: "",
      max_assisted_opening_mm: " ",
      pain_assisted_opening_presence: false,
      pain_assisted_opening_notes: "",
      rom_lateral_right_mm: " ",
      rom_lateral_left_mm: " ",
      rom_protrusive_lateral_mm: "",
      shimbashi_mm: " ",
      fremitus_tooth_number: [],
      fremitus_tooth_presence: false,
      fremitus_notes: "",
      tag_count_xray: "",
      tag_count_image: "",
    },
    wear_exam: {
      appearance_tooth_number: [],
      appearance_tooth_surface: [],
      appearance_subtype: "",
      appearance_notes: "",
      shape_tooth_number: [],
      shape_tooth_surface: [],
      shape_subtype: "",
      shape_notes: "",
      sharpness_tooth_number: [],
      sharpness_tooth_surface: [],
      sharpness_subtype: "",
      sharpness_notes: "",
      general_pattern_subtype: "",
      general_pattern_notes: "",
      ant_grade: "",
      post_grade: "",
      length_21_mm: " ",
      width_21_mm: " ",
      tag_count_xray: "",
      tag_count_image: "",
    },
    periodontal_exam: {
      tag_count_xray: "",
      tag_count_image: "",
      mobility: [
        {
          mob_tooth_number: [],
          mob_grade: "",
        },
      ],

      occlusal_trauma_present: [
        {
          occlusal_trauma_presence: false,
          occlusal_trauma_notes: "",
        },
      ],

      localized_bone_loss: [
        {
          loc_bone_loss_tooth_number: [],
          loc_bone_loss_tooth_site: " ",
          loc_bone_loss_grade: "",
          loc_bone_loss_subtype: [],
          loc_bone_loss_notes: " ",
        },
      ],

      recession: [
        {
          recession_tooth_number: [],
          recession_tooth_site: " ",
          recession_grade: "",
          recession_mm: " ",
          recession_notes: " ",

        },
      ],

      furcation: [
        {
          furcation_tooth_number: [],
          furcation_tooth_site: " ",
          furcation_grade: 1,
          furcation_presence: false,
          furcation_notes: " ",
        },
      ],
      non_carious_cervical_lesions: [
        {
          nccl_tooth_number: [],
          nccl_tooth_surface: [],
          nccl_subtype: [],
          nccl_notes: " ",
        },
      ],
      generalized_bone_loss: [
        {
          generalized_bone_grade: "",
          generalized_bone_subtype: [],
          generalized_bone_notes: "",
        },
      ],
      gingivitis_class: "",
      periodontitis_class: "",
      overall_periodontal_risk: " ",
    },
    existing_treatment: {
      decay_tooth_number: [],
      decay_tooth_surface: [],
      decay_grade: [],
      decay_notes: "",
      filling_tooth_number: [],
      filling_subtype: [],
      filling_surface: [],
      inlay_tooth_number: [],
      inlay_tooth_surface: [],
      inlay_subtype: [],
      crown_tooth_number: [],
      crown_tooth_surface: [],
      crown_subtype: [],
      onlay_tooth_number: [],
      onlay_tooth_surface: [],
      onlay_subtype: [],
      onlay__notes: "",
      veneer_tooth_number: [],
      veneer_tooth_surface: [],
      veneer_subtype: [],
      pontic_tooth_number: [],
      pontic_subtype: [],
      abutment_tooth_number: [],
      abutment_tooth_surface: [],
      abutment_subtype: [],
      implant_tooth_number: [],
      implant_subtype: [],
      denture_tooth_number: [],
      denture_subtype: [],
      open_margin_tooth_number: [],
      open_margin_tooth_surface: [],
      overhang_tooth_number: [],
      overhang_tooth_surface: [],
      contour_tooth_number: [],
      contour_tooth_surface: [],
      contour_subtype: [],
      open_contact_tooth_number: [],
      open_contact_tooth_surface: [],
      open_contact_mm: "",
      sct_tooth_number: [],
      watch_tooth_number: [],
      watch_tooth_surface: [],
      watch_note: "",
      existing_treatments: "",
      existingteeth_restorations: "",
      odontogram: "",
      teeth_restoration: "",
      tag_count_xray: "",
      tag_count_image: "",
    },
  },
};

export const postClinicalExamDentistDataReducer = (
  state = initialState,
  action: clinicalExamDentistActions
) => {
  switch (action.type) {
    case POST_CLINICAL_EXAM_DENTIST_DATA_REQUEST:
      const temp = state.data;
      if (action?.payload?.mainType) {
        if (action.payload.finding) {
          temp[action.payload.mainType][action.payload.finding][
            action.payload.index
          ][action.payload.key] = action.payload.value;
        } else {
          temp[action.payload.mainType][action.payload.key] =
            action.payload.value;
        }
      } else {
        temp[action.payload.key] = action.payload.value;
      }

      return {
        ...state,
        data: temp,
      };
    // const temp = state.data;
    // temp[action.payload.key] = action.payload.value;

    // return {
    //     ...state,
    //     data: temp,
    // };
    // const temp = state.data
    // if (action?.payload?.mainType) {
    //     temp[action.payload.mainType][action.payload.key] = action.payload.value
    // } else {
    //     temp[action.payload.key] = action.payload.value
    // }

    // return {
    //     ...state,
    //     data: temp
    // }

    case POST_ADD_MULTIPLE_FINDING_DENTIST:
      const tempData = state.data;
      const tempObject = action.payload.object;
      tempData[action.payload.mainType][action.payload.finding].push(
        action.payload.object
      );
      return {
        ...state,
        data: tempData,
      };

    case POST_DELETE_MULTIPLE_FINDING_DENTIST:
      let tempDelete = state.data;

      tempDelete[action.payload.mainType][action.payload.finding] = tempDelete[action.payload.mainType][action.payload.finding].filter((f: any, i: number) => i !== action.payload.index)


      return {
        ...state,
        data: tempDelete,
      };

    default:
      return { ...state };
  }
};
