import { all, call, put, takeEvery } from "redux-saga/effects";
import API from "../../../../utils/api";
import {
  getPreIntakeRequestFailure,
  getPreIntakeRequestSuccess,
  preIntakeRequestFailure,
  preIntakeRequestSuccess,
  postPreIntakeDatafailureRequest,
  getPreIntakeCacheDataClearRequest,
} from "../../../actions/v1/preIntakeActions/preIntakeActions";
import {
  GET_PRE_INTAKE_REQUEST,
  preIntakeTypes,
} from "../../../ActionTypes/v1/preIntake";
import { errorToaster, stringToArray, successToaster } from "../../../../utils/helper";

function* preIntakeRequestSaga(payload: any): any {
  try {
    const response = yield API.put(
      `/preIntakeForm?patientID=${payload.payload.patient_id}`,
      payload.payload.data
    );
    yield call(payload.payload.callback, response.data);
    yield put(
      preIntakeRequestSuccess({
        data: response.data,
      })
    );
    successToaster("The form has been successfully submitted")
  } catch (e: any) {
    errorToaster(e?.detail)
    yield put(
      preIntakeRequestFailure({
        error: e?.detail,
      })
    );
  }
}

function* getPreIntakeRequestSaga(payload: any): any {
  yield put(getPreIntakeCacheDataClearRequest())
  yield put(postPreIntakeDatafailureRequest());
  try {
    const response = yield API.get("/preIntakeForm");
    if (response?.data?.data === null) {
      
      yield put(
        getPreIntakeRequestSuccess({
          data: response?.data,
        })
      );
    } else {
      yield call(payload.payload.callback, response.data);
      const newData = response.data;
      newData.list_of_checked_items = stringToArray(
        newData.list_of_checked_items
      );
      newData.allergies_list = stringToArray(newData?.allergies_list);
      newData.dental_info = stringToArray(newData?.dental_info);
      yield put(
        getPreIntakeRequestSuccess({
          data: newData,
        })
      );
    }
  } catch (e: any) {
    errorToaster(e?.detail)
    yield put(getPreIntakeCacheDataClearRequest())
    yield put(postPreIntakeDatafailureRequest());
    yield put(
      getPreIntakeRequestFailure({
        error: e?.detail,
      })
    );
  }
}

function* postPreIntakeSaga() {
  yield takeEvery(preIntakeTypes.PUT_PRE_INTAKE_REQUEST, preIntakeRequestSaga);
}

function* getPreIntakeSaga() {
  yield takeEvery(GET_PRE_INTAKE_REQUEST, getPreIntakeRequestSaga);
}

export default function* preIntakeSaga() {
  yield all([postPreIntakeSaga(), getPreIntakeSaga()]);
}
