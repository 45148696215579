import {
    UPDATE_MUSCLE_DENTIST,UPDATE_MUSCLE_DENTIST_FAILURE,
    CHANGE_MUSCLE_DENTIST
} from "../../../../ActionTypes/v1/dentist/clinicalExamDentist";
import { muscleDentistInterface } from "../../../../types/dentistTypes";

export const updateMuscleRequest = (
    payload: muscleDentistInterface
): muscleDentistInterface => ({
    type: UPDATE_MUSCLE_DENTIST,
    payload,
}) as any;

export const updateMuscleRequestFailure = (): any => ({
    type: UPDATE_MUSCLE_DENTIST_FAILURE,
}) as any;


export const changeMuscleFindingAction = (payload: any): any => ({
    type: CHANGE_MUSCLE_DENTIST,
    payload
}) as any;