export const PUT_READINESS_BAR_REQUEST = "PUT_READINESS_BAR_REQUEST";
export const PUT_READINESS_BAR_REQUEST_SUCCESS =
  "PUT_READINESS_BAR_REQUEST_SUCCESS";
export const PUT_READINESS_BAR_REQUEST_FAILURE =
  "PUT_READINESS_BAR_REQUEST_FAILURE";

export const GET_READINESS_BAR_REQUEST = "GET_READINESS_BAR_REQUEST";
export const GET_READINESS_BAR_REQUEST_SUCCESS =
  "GET_READINESS_BAR_REQUEST_SUCCESS";
export const GET_READINESS_BAR_REQUEST_FAILURE =
  "GET_READINESS_BAR_REQUEST_FAILURE";

export const CHANGE_READINESS_BAR_REQUEST = "CHANGE_READINESS_BAR_REQUEST";
export const UPDATE_READINESS_BAR = "UPDATE_READINESS_BAR";
