import CrownMetalCeramic11 from './crownMetalCeramic11.svg';
import CrownMetalCeramic12 from './crownMetalCeramic12.svg';
import CrownMetalCeramic13 from './crownMetalCeramic13.svg';
import CrownMetalCeramic14 from './crownMetalCeramic14.svg';
import CrownMetalCeramic15 from './crownMetalCeramic15.svg';
import CrownMetalCeramic16 from './crownMetalCeramic16.svg';
import CrownMetalCeramic17 from './crownMetalCeramic17.svg';
import CrownMetalCeramic18 from './crownMetalCeramic18.svg';
import CrownMetalCeramic21 from './crownMetalCeramic21.svg';
import CrownMetalCeramic22 from './crownMetalCeramic22.svg';
import CrownMetalCeramic23 from './crownMetalCeramic23.svg';
import CrownMetalCeramic24 from './crownMetalCeramic24.svg';
import CrownMetalCeramic25 from './crownMetalCeramic25.svg';
import CrownMetalCeramic26 from './crownMetalCeramic26.svg';
import CrownMetalCeramic27 from './crownMetalCeramic27.svg';
import CrownMetalCeramic28 from './crownMetalCeramic28.svg';
import CrownMetalCeramic31 from './crownMetalCeramic31.svg';
import CrownMetalCeramic32 from './crownMetalCeramic32.svg';
import CrownMetalCeramic33 from './crownMetalCeramic33.svg';
import CrownMetalCeramic34 from './crownMetalCeramic34.svg';
import CrownMetalCeramic35 from './crownMetalCeramic35.svg';
import CrownMetalCeramic36 from './crownMetalCeramic36.svg';
import CrownMetalCeramic37 from './crownMetalCeramic37.svg';
import CrownMetalCeramic38 from './crownMetalCeramic38.svg';
import CrownMetalCeramic41 from './crownMetalCeramic41.svg';
import CrownMetalCeramic42 from './crownMetalCeramic42.svg';
import CrownMetalCeramic43 from './crownMetalCeramic43.svg';
import CrownMetalCeramic44 from './crownMetalCeramic44.svg';
import CrownMetalCeramic45 from './crownMetalCeramic45.svg';
import CrownMetalCeramic46 from './crownMetalCeramic46.svg';
import CrownMetalCeramic47 from './crownMetalCeramic47.svg';
import CrownMetalCeramic48 from './crownMetalCeramic48.svg';
import LingualMetalCeramic11 from './lingualMetalCeramic11.svg';
import LingualMetalCeramic12 from './lingualMetalCeramic12.svg';
import LingualMetalCeramic13 from './lingualMetalCeramic13.svg';
import LingualMetalCeramic14 from './lingualMetalCeramic14.svg';
import LingualMetalCeramic15 from './lingualMetalCeramic15.svg';
import LingualMetalCeramic16 from './lingualMetalCeramic16.svg';
import LingualMetalCeramic17 from './lingualMetalCeramic17.svg';
import LingualMetalCeramic18 from './lingualMetalCeramic18.svg';
import LingualMetalCeramic21 from './lingualMetalCeramic21.svg';
import LingualMetalCeramic22 from './lingualMetalCeramic22.svg';
import LingualMetalCeramic23 from './lingualMetalCeramic23.svg';
import LingualMetalCeramic24 from './lingualMetalCeramic24.svg';
import LingualMetalCeramic25 from './lingualMetalCeramic25.svg';
import LingualMetalCeramic26 from './lingualMetalCeramic26.svg';
import LingualMetalCeramic27 from './lingualMetalCeramic27.svg';
import LingualMetalCeramic28 from './lingualMetalCeramic28.svg';
import LingualMetalCeramic31 from './lingualMetalCeramic31.svg';
import LingualMetalCeramic32 from './lingualMetalCeramic32.svg';
import LingualMetalCeramic33 from './lingualMetalCeramic33.svg';
import LingualMetalCeramic34 from './lingualMetalCeramic34.svg';
import LingualMetalCeramic35 from './lingualMetalCeramic35.svg';
import LingualMetalCeramic36 from './lingualMetalCeramic36.svg';
import LingualMetalCeramic37 from './lingualMetalCeramic37.svg';
import LingualMetalCeramic38 from './lingualMetalCeramic38.svg';
import LingualMetalCeramic41 from './lingualMetalCeramic41.svg';
import LingualMetalCeramic42 from './lingualMetalCeramic42.svg';
import LingualMetalCeramic43 from './lingualMetalCeramic43.svg';
import LingualMetalCeramic44 from './lingualMetalCeramic44.svg';
import LingualMetalCeramic45 from './lingualMetalCeramic45.svg';
import LingualMetalCeramic46 from './lingualMetalCeramic46.svg';
import LingualMetalCeramic47 from './lingualMetalCeramic47.svg';
import LingualMetalCeramic48 from './lingualMetalCeramic48.svg';

export const crownMetalCeramicIcon = {
  CrownMetalCeramic11,
  CrownMetalCeramic12,
  CrownMetalCeramic13,
  CrownMetalCeramic14,
  CrownMetalCeramic15,
  CrownMetalCeramic16,
  CrownMetalCeramic17,
  CrownMetalCeramic18,
  CrownMetalCeramic21,
  CrownMetalCeramic22,
  CrownMetalCeramic23,
  CrownMetalCeramic24,
  CrownMetalCeramic25,
  CrownMetalCeramic26,
  CrownMetalCeramic27,
  CrownMetalCeramic28,
  CrownMetalCeramic31,
  CrownMetalCeramic32,
  CrownMetalCeramic33,
  CrownMetalCeramic34,
  CrownMetalCeramic35,
  CrownMetalCeramic36,
  CrownMetalCeramic37,
  CrownMetalCeramic38,
  CrownMetalCeramic41,
  CrownMetalCeramic42,
  CrownMetalCeramic43,
  CrownMetalCeramic44,
  CrownMetalCeramic45,
  CrownMetalCeramic46,
  CrownMetalCeramic47,
  CrownMetalCeramic48,

  LingualMetalCeramic11,
  LingualMetalCeramic12,
  LingualMetalCeramic13,
  LingualMetalCeramic14,
  LingualMetalCeramic15,
  LingualMetalCeramic16,
  LingualMetalCeramic17,
  LingualMetalCeramic18,
  LingualMetalCeramic21,
  LingualMetalCeramic22,
  LingualMetalCeramic23,
  LingualMetalCeramic24,
  LingualMetalCeramic25,
  LingualMetalCeramic26,
  LingualMetalCeramic27,
  LingualMetalCeramic28,
  LingualMetalCeramic31,
  LingualMetalCeramic32,
  LingualMetalCeramic33,
  LingualMetalCeramic34,
  LingualMetalCeramic35,
  LingualMetalCeramic36,
  LingualMetalCeramic37,
  LingualMetalCeramic38,
  LingualMetalCeramic41,
  LingualMetalCeramic42,
  LingualMetalCeramic43,
  LingualMetalCeramic44,
  LingualMetalCeramic45,
  LingualMetalCeramic46,
  LingualMetalCeramic47,
  LingualMetalCeramic48
};
