import {
  SELECT_ADDITIONAL_TREATMENT_DATA,
  SELECT_NEW_ADDITIONAL_TREATMENT_DATA,
  GET_ODONTOGRAM_TREATMENT_DATA_REQUEST,
  GET_ODONTOGRAM_TREATMENT_DATA_SUCCESS,
  GET_ODONTOGRAM_TREATMENT_DATA_FAIL,
  SELECTED_CHECKBOX_DATA,
  UPDATE_ODONTOGRAM_TREATMENT_DATA_REQUEST,
  OPEN_PROPOSED_TOOLTIP,
  OPEN_ODONTOGRAM_TOOLTIP,
  CAME_IMAGE_XRAY_FROM_TREATMENT,
  MASTER_PROPOSED_DATA,
} from '../../../../../../ActionTypes/v2/dentist/clinicalExam/treatment/proposedTreatments/oraqProposed'


const initialState: any = {
  selectAdditionalTreatment: [],
  newSelectAdditionalTreatment: [],
  getOdontogramLoader: false,
  getOdontogramData: [],
  getOdontogramError: null,
  selectedCheckboxData: [],
  proposedTooltipIndex: null,
  odontogramTooltipIndex: null,
  cameImageXrayFromTreatmentPlan: false,
  masterProposedData: [],
  selectedCodeData: []
};

const oraqProposedReducer = (state = initialState, action: any) => {
  switch (action.type) {
    // get resource data
    case SELECT_ADDITIONAL_TREATMENT_DATA:
      return {
        ...state,
        selectAdditionalTreatment: action?.payload,
      }
    case SELECT_NEW_ADDITIONAL_TREATMENT_DATA:
      return {
        ...state,
        newSelectAdditionalTreatment: action?.payload,
      }
    /*Master Api for Get recommendation*/
    case GET_ODONTOGRAM_TREATMENT_DATA_REQUEST:
      return {
        ...state,
        getOdontogramLoader: true
      };
    case GET_ODONTOGRAM_TREATMENT_DATA_SUCCESS:
      return {
        ...state,
        getOdontogramLoader: false,
        getOdontogramData: action?.payload,
        getOdontogramError: null
      };
    case GET_ODONTOGRAM_TREATMENT_DATA_FAIL:
      return {
        ...state,
        getOdontogramLoader: false,
        getOdontogramData: null,
        getOdontogramError: action?.payload?.error,
      };
    //selcted checkbox data
    case SELECTED_CHECKBOX_DATA:
      return {
        ...state,
        selectedCheckboxData: action?.payload,
      }
    case UPDATE_ODONTOGRAM_TREATMENT_DATA_REQUEST:
      return {
        ...state,
        getOdontogramData: action?.payload
      }
    case OPEN_PROPOSED_TOOLTIP:
      return {
        ...state,
        proposedTooltipIndex: action?.payload
      }
    case OPEN_ODONTOGRAM_TOOLTIP:
      return {
        ...state,
        odontogramTooltipIndex: action?.payload
      }
    case CAME_IMAGE_XRAY_FROM_TREATMENT:
      return {
        ...state,
        cameImageXrayFromTreatmentPlan: action?.payload
      }
    case MASTER_PROPOSED_DATA:
      return {
        ...state,
        masterProposedData: action?.payload
      }
    default:
      return {
        ...state
      };
  }
}

export default oraqProposedReducer
