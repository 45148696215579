import {
  GET_CLINIC_DETAILS_REQUEST,
  GET_CLINIC_DETAILS_REQUEST_FAILURE,
  GET_CLINIC_DETAILS_REQUEST_SUCCESS,
  SAVE_ANONYMOUS_PRE_INTAKE_REQUEST,
  SAVE_ANONYMOUS_PRE_INTAKE_REQUEST_SUCCESS,
  SAVE_ANONYMOUS_PRE_INTAKE_REQUEST_FAILURE,
  SELECT_EMAIL,
  VERIFY_ANONYMOUS_PATIENT_EMAIL_REQUEST,
  VERIFY_ANONYMOUS_PATIENT_EMAIL_REQUEST_SUCCESS,
  VERIFY_ANONYMOUS_PATIENT_EMAIL_REQUEST_FAILURE,
  UPDATE_EMAIL_ERROR
} from '../../../ActionTypes/v2/anonymousPreIntake/anonymousPreIntake';
import {
  anonymousPreIntakeAction,
  anonymousPreIntakeInterface
} from '../../../types/v2/anonymousPreIntake/anonymousPreIntakeTypes';

const initialState: anonymousPreIntakeInterface = {
  getClinicDetailsLoader: false,
  getClinicDetailsData: null,
  getClinicDetailsError: null,
  saveAnonymousPreIntakeLoader: false,
  saveAnonymousPreIntakeData: null,
  saveAnonymousPreIntakeError: null,
  selectedEmail: null,
  verifyAnonymousPatientEmailLoader: false,
  verifyAnonymousPatientEmail: null,
  verifyAnonymousPatientEmailError: null,
  emailError: null
};

const anonymousPreIntakeReducer = (state = initialState, action: anonymousPreIntakeAction) => {
  switch (action.type) {
    case GET_CLINIC_DETAILS_REQUEST:
      return {
        ...state,
        getClinicDetailsLoader: true,
        getClinicDetailsData: null,
        getClinicDetailsError: null
      };
    case GET_CLINIC_DETAILS_REQUEST_SUCCESS:
      return {
        ...state,
        getClinicDetailsLoader: false,
        getClinicDetailsData: action.payload,
        getClinicDetailsError: null
      };
    case GET_CLINIC_DETAILS_REQUEST_FAILURE:
      return {
        ...state,
        getClinicDetailsLoader: false,
        getClinicDetailsData: null,
        getClinicDetailsError: action.payload
      };
    case SAVE_ANONYMOUS_PRE_INTAKE_REQUEST:
      return {
        ...state,
        saveAnonymousPreIntakeLoader: true,
        saveAnonymousPreIntakeData: null,
        saveAnonymousPreIntakeError: action.payload
      };
    case SAVE_ANONYMOUS_PRE_INTAKE_REQUEST_SUCCESS:
      return {
        ...state,
        saveAnonymousPreIntakeLoader: false,
        saveAnonymousPreIntakeData: action.payload,
        saveAnonymousPreIntakeError: null
      };
    case SAVE_ANONYMOUS_PRE_INTAKE_REQUEST_FAILURE:
      return {
        ...state,
        saveAnonymousPreIntakeLoader: false,
        saveAnonymousPreIntakeData: null,
        saveAnonymousPreIntakeError: action.payload
      };
    case SELECT_EMAIL:
      return {
        ...state,
        selectedEmail: action.payload
      };
    case VERIFY_ANONYMOUS_PATIENT_EMAIL_REQUEST:
      return {
        ...state,
        verifyAnonymousPatientEmailLoader: true,
        verifyAnonymousPatientEmail: null,
        verifyAnonymousPatientEmailError: action.payload
      };
    case VERIFY_ANONYMOUS_PATIENT_EMAIL_REQUEST_SUCCESS:
      return {
        ...state,
        verifyAnonymousPatientEmailLoader: false,
        verifyAnonymousPatientEmail: action.payload,
        verifyAnonymousPatientEmailError: null
      };
    case VERIFY_ANONYMOUS_PATIENT_EMAIL_REQUEST_FAILURE:
      return {
        ...state,
        verifyAnonymousPatientEmailLoader: false,
        verifyAnonymousPatientEmail: null,
        verifyAnonymousPatientEmailError: action.payload
      };
    case UPDATE_EMAIL_ERROR:
      return {
        ...state,
        emailError: action.payload
      };
    default:
      return {
        ...state
      };
  }
};

export default anonymousPreIntakeReducer;
