import {
  RECOVER_PASSWORD_V2_REQUEST,
  RECOVER_PASSWORD_V2_REQUEST_FAILURE,
  RECOVER_PASSWORD_V2_REQUEST_SUCCESS
} from '../../../ActionTypes/v2/auth';
import {
  RecoverPasswordRequest,
  RecoverPasswordRequestFailure,
  RecoverPasswordRequestFailurePayload,
  RecoverPasswordRequestPayload,
  RecoverPasswordRequestSuccess,
  RecoverPasswordRequestSuccessPayload
} from '../../../types/v2/authActionTypes/resetPasswordActionTypes';

export const recoverPasswordRequest = (payload: RecoverPasswordRequestPayload): RecoverPasswordRequest => ({
  type: RECOVER_PASSWORD_V2_REQUEST,
  payload
});

export const recoverPasswordRequestSuccess = (
  payload: RecoverPasswordRequestSuccessPayload
): RecoverPasswordRequestSuccess => ({
  type: RECOVER_PASSWORD_V2_REQUEST_SUCCESS,
  payload
});

export const recoverPasswordRequestFailure = (
  payload: RecoverPasswordRequestFailurePayload
): RecoverPasswordRequestFailure => ({
  type: RECOVER_PASSWORD_V2_REQUEST_FAILURE,
  payload
});
