import React, {useEffect, useState} from "react";
import { makeStyles } from "@mui/styles";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createPasswordValidator } from "../../../../validation";
import dentistBanner from "../../../../assets/images/v1/verification_banner.png";
import CreatePasswordComponent from "../../auth/CreatePasswordComponent";
import { createPasswordRequest } from "../../../../app/actions/v1/patient/authActions/authActions";
import themes from "../../../../theme";
import {patientProfileData} from "../../../../utils/helper";

const useStyles = makeStyles((theme: any) => ({
  mainWrapper: {
    position: "relative",
    overflow: "hidden",
    maxHeight: "100vh",
  },
  rightSection: {
    paddingLeft: "10%",
    paddingRight: "10%",

  },
  cssOutlinedInput: {
    borderRadius: "10px !important",
    "&$cssFocused $notchedOutline": {
      borderColor: `${theme.palette.secondary.main} !important`,
      [theme.breakpoints.down("md")]: {
        borderColor: themes.palette.primary.main,
      },
    },
  },
  notchedOutline: {
    borderWidth: "1px",
    borderColor: `${theme.palette.secondary.main} !important`,
  },
  input: {
    color: `${theme.palette.secondary.main} !important`,
    padding: "10px !important",
    fontWeight: 400,
  },
  floatingLabelRootStyle: {
    color: `${theme.palette.secondary.main} !important`,
    opacity: "50%",
    top: "-6px !important",
  },
  floatingLabelFocusStyle: {
    color: `${theme.palette.secondary.main} !important`,
  },
  danger: {
    borderWidth: "1px",
    borderColor: `${theme.palette.error.main} !important`,
  },
  button: {
    background: `${theme.palette.secondary.main} !important`,
    marginBottom: "30px !important",
    [theme.breakpoints.down("md")]: {
      background: `${theme.palette.secondary.main} !important`,
    },
  },
  showPassword: {
    color: `${theme.palette.secondary.main} !important`,
  },
  imageText: {
    position: "absolute",
    top: "24%",
    right: "50%",
    transform: "translate(50%,-50%)",
    width: "100%",
    padding: "0 20px",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
  },
}));

const CreatePasswordPatient = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useNavigate();

  const [formData, setFormData] = useState({
    password: "",
    confirmPassword: "",
    showPassword: false,
    showNewPassword: false,
  });
  const [error, setError]: any = useState({});
  const imageText = {
    title: "Create Password",
    text1: "Elevating your oral health and",
    text2: "wellness journey with access",
    text3: "in the palm of your hand.",
    label: "Enter your mobile number to continue",
  };

  useEffect(() => {
    if (patientProfileData?.password_updated) {
      history({ pathname: '/patient/wellness-profile' })
    }
  }, [history])

  const submitHandler = (e: any) => {
    e.preventDefault();
    let { errors, isValid } = createPasswordValidator(formData);
    setError(errors);
    if (isValid) {
      dispatch(
        createPasswordRequest({
          payload: { password: formData.password },
          callback: (data: any) => {
            localStorage.setItem(
              "patientUserData",
              JSON.stringify(data?.data?.detail)
            );
            history({
              pathname: "/patient/wellness-profile",
            });
          },
        })
      );
    }
  };

  return (
      <CreatePasswordComponent
        isPatient={true}
        banner={dentistBanner}
        classes={classes}
        formData={formData}
        setFormData={setFormData}
        error={error}
        setError={setError}
        imageText={imageText}
        submitHandler={submitHandler}
      />
  );
};

export default CreatePasswordPatient;
