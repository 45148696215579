import validator from "validator";
import {
  invalidPassword,
  invalidUserName,
  requireMessage,
  samePassword,
} from "../validationMessage";

const username_regex = new RegExp("^[a-zA-Z0-9@._-]{4,25}$");

const password_regex = new RegExp(
  "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,25}$"
);

function patientRegistrationValidations(data) {
  const errors = {};

  if (validator.isEmpty(data.userName.trim()))
    errors.userName = "This field is required";
  else if (!username_regex.test(data?.userName))
    errors.userName = invalidUserName;

  if (validator.isEmpty(data?.email?.trim()))
    errors.email = "This field is required";

  if (data?.email && !validator.isEmail(data?.email))
    errors.email = requireMessage("valid email address");

  if (validator.isEmpty(data?.mobile)) errors.mobile = "This field is required";

  if (data?.mobile && data?.mobile.length !== 14)
    errors.mobile = requireMessage("valid mobile number");

  if (validator.isEmpty(data.password.trim()))
    errors.password = "This field is required";
  else if (!password_regex.test(data?.password))
    errors.password = invalidPassword;

  if (validator.isEmpty(data.confirmPassword.trim()))
    errors.confirmPassword = "This field is required";
  else if (!validator.equals(data?.confirmPassword, data?.password)) {
    errors.confirmPassword = samePassword;
  }

  return { errors, isValid: Object.keys(errors).length <= 0 };
}

export default patientRegistrationValidations;
