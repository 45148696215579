import { all, call, put, takeEvery } from "redux-saga/effects";
import API from "../../../../../utils/api";
import {
    getTagXrayAllRequestSuccess,
    getTagXrayAllRequestFailure,
    getXrayAppointmentRequestSuccess,
    getXrayAppointmentRequestFailure,
    deleteXrayAppointmentRequestSuccess,
    deleteXrayAppointmentRequestFailure,
    createTagRequestFailure,
    updateTagRequestFailure,
    deleteTagRequestFailure,
    putContrastRequestFailure,
    putCroppedImageRequestFailure,
    putContrastRequestSuccess,
    getXrayAppointmentCacheDataClearRequest
} from '../../../../actions/v1/dentist/clinicExamDentistActions/radiographicAnalysisAndFindingAction'
import { CREATE_TAG_REQUEST, DELETE_TAG_REQUEST, GET_TAG_XRAY_ALL_REQUEST, GET_XRAY_APPOINTMENT_REQUEST, DELETE_XRAY_APPOINTMENT_REQUEST, PUT_CONTRAST_REQUEST, PUT_CROPPED_IMAGE_REQUEST, UPDATE_TAG_DATA_REQUEST } from '../../../../ActionTypes/v1/dentist/clinicalExamDentist'
import { errorToaster, successToaster } from '../../../../../utils/helper';


// for xray and images tag list
function* getTagXrayAllRequestSaga(payload: any): any {
    try {
        if (payload.payload.data.xray_id || payload.payload.data.image_id) {
            const response = yield API.get(payload.payload.data.isXray ? `/exam/image/tags/xray/all/${payload.payload.data.xray_id}` : `/exam/image/tags/image/all/${payload.payload.data.image_id}`)
            yield put(getTagXrayAllRequestSuccess({
                tagData: response?.data
            }))
            if (payload.payload.callback) {
                payload.payload.callback(response?.data)

            }
        }
    } catch (e: any) {
        //  errorToaster(e?.detail)
        yield put(getTagXrayAllRequestFailure({
            error: e?.detail
        }))
    }
}

// for xray and images  list
function* getXrayAppointmentRequestSaga(payload: any): any {

    try {
        yield put(getXrayAppointmentCacheDataClearRequest())
        const response = yield API.get(payload?.payload?.isXray ? `/exam/image/xray/appointment/${payload?.payload?.appointment_id}` : `/exam/image/appointment/${payload?.payload?.appointment_id}`)

        if (payload?.payload?.callback) {
            yield call(payload?.payload?.callback, response.data)
        }
        yield put(getXrayAppointmentRequestSuccess({
            data: response.data
        }))
        // yield put(updateContrastAction({
        //     data: response.data
        // }))

    } catch (e: any) {
        yield put(getXrayAppointmentCacheDataClearRequest())
        //TODO: update toast according to status code
        //  errorToaster(e?.detail)
        yield put(getXrayAppointmentRequestFailure({
            error: e?.detail
        }))
    }
}

function* deleteXrayAppointmentRequestSaga(payload: any): any {
    try {
        const response = yield API.delete(payload.payload.isXray ? `/exam/image/xray/delete?xray_id=${payload.payload.xray_id}` : `/exam/image/delete?image_id=${payload.payload.image_id}`)
        yield put(deleteXrayAppointmentRequestSuccess({
            data: response.data
        }))
        yield call(payload.payload.callback, response);
        if (response) {
            successToaster(response?.data?.detail)
        }

    } catch (e: any) {
        errorToaster(e?.detail)
        yield put(deleteXrayAppointmentRequestFailure({
            error: e?.detail
        }))
    }
}

//for create tag (annotations)
function* postCreateTagRequestSaga(payload: any): any {
    try {
        const response = yield API.post(payload.payload.isXray ? `/exam/image/tags/xray/create` : `/exam/image/tags/image/create`, payload.payload)
        if (response) {
            successToaster('Data saved successfully ')
        }
    } catch (e: any) {
        errorToaster(e?.detail)
        yield put(
            createTagRequestFailure({
                error: e?.detail
            })
        );
    }
}

//for update tag (annotations)
function* putUpdateTagRequestSaga(payload: any): any {
    try {
        const response = yield API.put(payload.payload.data.isXray ? `exam/image/tags/xray/update/${payload.payload.data.tag_id}` : `exam/image/tags/image/update/${payload.payload.data.tag_id}`, payload.payload.data)
        if (response) {
            successToaster('Annotation updated successfully ')
        }
        if (payload.payload.callback) {
            payload.payload.callback()
        }
    }
    catch (e: any) {
        errorToaster(e?.detail)
        yield put(
            updateTagRequestFailure({
                error: e?.detail
            })
        );
    }
}

//for delete tag(annotations)
function* deleteTagRequestSaga(payload: any): any {
    try {
        const response = yield API.delete(payload.payload.data.isXray ? `/exam/image/tags/xray/delete/${payload.payload.data.tag_id}` : `/exam/image/tags/image/delete/${payload.payload.data.tag_id}`, payload.payload.data)
        if (response) {
            successToaster('Annotation Deleted successfully ')
        }
        if (payload.payload.callback) {
            payload.payload.callback()
        }
    }
    catch (e: any) {
        errorToaster(e?.detail)
        yield put(
            deleteTagRequestFailure({
                error: e?.detail
            })
        );
    }
}
// for put contrast value

function* putContrastRequestSaga(payload: any): any {
    try {
        const response = yield API.put(`/exam/image/images/contrast/xray/${payload.payload?.xray_id}`, payload.payload)
        yield put(putContrastRequestSuccess({
            contrast: response.data.contrast
        }))
        if (payload?.payload?.callback) {
            payload?.payload?.callback()
        }
    }
    catch (e: any) {
        errorToaster(e?.detail)
        yield put(
            putContrastRequestFailure({
                error: e?.detail
            })
        );
    }
}

//for crop image(put cropped image)
function* putCroppedImageRequestSaga(payload: any): any {
    try {
        const response = yield API.put(`/exam/image/images/crop/${payload.payload?.image_id}`, payload.payload)
        if (payload.payload.callback) {
            payload.payload.callback()
        }
    }
    catch (e: any) {
        errorToaster(e?.detail)
        yield put(
            putCroppedImageRequestFailure({
                error: e?.detail
            })
        );
    }
}


function* tagXrayAllRequestSaga() {
    yield takeEvery(GET_TAG_XRAY_ALL_REQUEST, getTagXrayAllRequestSaga);
}
function* xrayAppointmentRequestSaga() {
    yield takeEvery(GET_XRAY_APPOINTMENT_REQUEST, getXrayAppointmentRequestSaga);
}
function* delXrayAppointmentRequestSaga() {
    yield takeEvery(DELETE_XRAY_APPOINTMENT_REQUEST, deleteXrayAppointmentRequestSaga)
}
function* createTagRequestSaga() {
    yield takeEvery(CREATE_TAG_REQUEST, postCreateTagRequestSaga);
}
function* updateTagRequestSaga() {
    yield takeEvery(UPDATE_TAG_DATA_REQUEST, putUpdateTagRequestSaga);
}
function* deleteTagSaga() {
    yield takeEvery(DELETE_TAG_REQUEST, deleteTagRequestSaga);
}
function* putContrastSaga() {
    yield takeEvery(PUT_CONTRAST_REQUEST, putContrastRequestSaga);
}
function* putCroppedImageSaga() {
    yield takeEvery(PUT_CROPPED_IMAGE_REQUEST, putCroppedImageRequestSaga);
}



export default function* rootSaga() {
    yield all([
        tagXrayAllRequestSaga(),
        xrayAppointmentRequestSaga(),
        delXrayAppointmentRequestSaga(),
        createTagRequestSaga(),
        updateTagRequestSaga(),
        deleteTagSaga(),
        putContrastSaga(),
        putCroppedImageSaga()
    ])
}
