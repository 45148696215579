export const requireMessage = (msg) => `Please enter your ${msg}.`;

export const requireForSelect = (msg) => `Please select ${msg}.`;

export const requireValidMsg = (msg) => `Please enter valid ${msg}.`;

export const restrictNumberMessage = "numbers are not allowed";
export const restrictSpecialCharacterMessage =
  "special character are not allowed";

export const invalidUserName =
  "Usernames must be 4-25 characters long and can include letters, numbers and @ . _ - ";

export const invalidPassword =
  "Password must be at least 8 to 25 characters long and should contain 1 uppercase, 1 lowercase, 1 digit and 1 special character.";

export const samePassword = "Password and confirmed password do not match.";
