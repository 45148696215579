import { call, put, takeEvery, all } from "redux-saga/effects";
import API from "../../../../utils/api";
import { errorToaster, successToaster } from '../../../../utils/helper';

import { FORGOT_USERNAME_V2_REQUEST } from "../../../ActionTypes/v2/patient/auth";
import { forgotUserNameRequestFailure, forgotUserNameRequestSuccess, multipleNumberPopUp } from "../../../actions/v2/patient/authAction";


function* forgotUserNameRequestSaga(payload: any): any {
    try {
        const response = yield API.post(`/v2/auth/forgot-username`, payload.payload.payload)
        yield call(payload.payload.callback, response);
        yield put(
            forgotUserNameRequestSuccess(response?.data?.detail)
        );
        if (response) {
            successToaster(response?.data?.meta?.message)
        }
    } catch (e: any) {
        if (e?.data?.length) {
            yield put(multipleNumberPopUp({
                multipleNumberPopUp: true,
                multipleNumberData: e?.data
            }))
        }else{
            errorToaster(e?.meta?.message)
        }
        yield put(
            forgotUserNameRequestFailure(e?.meta?.message)
        );
    }
}


function* forgotUserNameSaga() {
    yield takeEvery(FORGOT_USERNAME_V2_REQUEST, forgotUserNameRequestSaga);
}

export default function* patientAuthSaga() {
    yield all([
        forgotUserNameSaga(),
    ])
}