import {
  PRIVACY_POLICY_V2_REQUEST,
  PRIVACY_POLICY_V2_REQUEST_FAILURE,
  PRIVACY_POLICY_V2_REQUEST_SUCCESS
} from '../../../ActionTypes/v2/auth';
import {
  PrivacyPolicyRequest,
  PrivacyPolicyRequestFailure,
  PrivacyPolicyRequestFailurePayload,
  PrivacyPolicyRequestPayload,
  PrivacyPolicyRequestSuccess,
  PrivacyPolicyRequestSuccessPayload
} from '../../../types/v2/authActionTypes/privacyPolicyActionTypes';

export const privacyPolicyRequest = (payload: PrivacyPolicyRequestPayload): PrivacyPolicyRequest => ({
  type: PRIVACY_POLICY_V2_REQUEST,
  payload
});

export const privacyPolicySuccess = (payload: PrivacyPolicyRequestSuccessPayload): PrivacyPolicyRequestSuccess => ({
  type: PRIVACY_POLICY_V2_REQUEST_SUCCESS,
  payload
});

export const privacyPolicyFailure = (payload: PrivacyPolicyRequestFailurePayload): PrivacyPolicyRequestFailure => ({
  type: PRIVACY_POLICY_V2_REQUEST_FAILURE,
  payload
});
