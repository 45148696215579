import {
  FORGOT_PASSWORD_V2_REQUEST,
  FORGOT_PASSWORD_V2_REQUEST_FAILURE,
  FORGOT_PASSWORD_V2_REQUEST_SUCCESS,
  IS_MULTIPLE_ACCOUNT_FORGOT_PASSWORD_POPUP
} from '../../../ActionTypes/v2/auth';
import { forgotPasswordInterface, forgotPasswordActions } from '../../../types/v2/authActionTypes/forgotPasswordActionTypes';

const initialState: forgotPasswordInterface = {
  forgotPasswordLoader: false,
  forgotPasswordData: null,
  forgotPasswordError: null,

  isMultipleAccount: false
};

const ForgotPasswordReducer = (state = initialState, action: forgotPasswordActions) => {
  switch (action.type) {
    case FORGOT_PASSWORD_V2_REQUEST:
      return {
        ...state,
        forgotPasswordLoader: true,
        forgotPasswordData: null,
        forgotPasswordError: null
      };
    case FORGOT_PASSWORD_V2_REQUEST_SUCCESS:
      return {
        ...state,
        forgotPasswordLoader: false,
        forgotPasswordData: action.payload,
        forgotPasswordError: null
      };
    case FORGOT_PASSWORD_V2_REQUEST_FAILURE:
      return {
        ...state,
        forgotPasswordLoader: false,
        forgotPasswordData: null,
        forgotPasswordError: action.payload.forgotPasswordError
      };

    case IS_MULTIPLE_ACCOUNT_FORGOT_PASSWORD_POPUP:
      return {
        ...state,
        isMultipleAccount: action.payload
      }

    default:
      return {
        ...state
      };
  }
};
export default ForgotPasswordReducer;
