import { PeriodontalInterface } from "../../../../types/dentistTypes";
import {
  ADD_PERIODONTAL_FINDING_ROW,
  CHANGE_PERIODONTAL_FINDING,
  DELETE_PERIODONTAL_FINDING_ROW,
  UPDATE_PERIODONTAL, UPDATE_PERIODONTAL_FAILURE
} from "../../../../ActionTypes/v1/dentist/clinicalExamDentist";
import {
  defaultMobility, defaultOcclusalTraumaPresent, defaultGeneralizedBoneLossFields, defaultLocalizedBoneLossPresent,
  defaultNonCariousCervicalLesions, defaultRecession, defaultFurcation
} from "../../../../../utils/dentistPeriodontalExamConstant"
import {
  defaultCacheMobility, defaultCacheFurcation, defaultCacheGeneralizedBoneLossFields, defaultCacheLocalizedBoneLossPresent
  , defaultCacheNonCariousCervicalLesions, defaultCacheOcclusalTraumaPresent, defaultCacheRecession
} from "../../../../../utils/cacheDataConstant/dentistPeriodontalExamConstant";
import { jsonParseAndStringfy } from "../../../../../utils/helper";

const initialState: PeriodontalInterface = {
  mobility: [defaultMobility],
  occlusalTraumaPresent: [defaultOcclusalTraumaPresent],
  generalizedBoneLoss: [defaultGeneralizedBoneLossFields],
  localizedBoneLossPresent: [defaultLocalizedBoneLossPresent],
  gingivitis_class: "",
  periodontitis_class: "",
  nccls: [defaultNonCariousCervicalLesions],
  recession: [defaultRecession],
  furcation: [defaultFurcation],
  periodontal_comment: "",
};

export const periodontalReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case UPDATE_PERIODONTAL:
      return {
        ...state,
        ...action.payload,
      };
    case UPDATE_PERIODONTAL_FAILURE:
      const newMobilityData = jsonParseAndStringfy(defaultCacheMobility)
      const newOcclusalTraumaPresentData = jsonParseAndStringfy(defaultCacheOcclusalTraumaPresent)
      const newGeneralizedBoneLossFieldsData = jsonParseAndStringfy(defaultCacheGeneralizedBoneLossFields)
      const newLocalizedBoneLossPresentData = jsonParseAndStringfy(defaultCacheLocalizedBoneLossPresent)
      const newNonCariousCervicalLesionsData = jsonParseAndStringfy(defaultCacheNonCariousCervicalLesions)
      const newRecessionData = jsonParseAndStringfy(defaultCacheRecession)
      const newFurcationData = jsonParseAndStringfy(defaultCacheFurcation)
      return {
        mobility: [newMobilityData],
        occlusalTraumaPresent: [newOcclusalTraumaPresentData],
        generalizedBoneLoss: [newGeneralizedBoneLossFieldsData],
        localizedBoneLossPresent: [newLocalizedBoneLossPresentData],
        gingivitis_class: "",
        periodontitis_class: "",
        nccls: [newNonCariousCervicalLesionsData],
        recession: [newRecessionData],
        furcation: [newFurcationData],
        periodontal_comment: "",
      }
    case ADD_PERIODONTAL_FINDING_ROW:
      const finding = action.payload as string;
      const newStateAdd = state as any;
      const updatedFinding = newStateAdd[finding];
      if (finding === "mobility") {
        updatedFinding.push(defaultMobility);
      } else if (finding === "furcation") {
        updatedFinding.push(defaultFurcation);
      } else if (finding === "occlusalTraumaPresent") {
        updatedFinding.push(defaultOcclusalTraumaPresent);
      } else if (finding === "generalizedBoneLoss") {
        updatedFinding.push(defaultGeneralizedBoneLossFields);
      } else if (finding === "localizedBoneLossPresent") {
        updatedFinding.push(defaultLocalizedBoneLossPresent);
      } else if (finding === "nccls") {
        updatedFinding.push(defaultNonCariousCervicalLesions);
      } else if (finding === "recession") {
        updatedFinding.push(defaultNonCariousCervicalLesions);
      }
      return {
        ...state,
        [finding]: updatedFinding,
      };
    case CHANGE_PERIODONTAL_FINDING:
      const { toFinding, value, type, index, key } = action.payload as any;
      const newState = state as any;
      let newFinding = newState[toFinding] as any;
      if (typeof newFinding !== "string") {
        newFinding[index] = newFinding[index]?.map((res: any) => {
          if (res.name === key) {
            return { ...res, value: value };
          }
          return res;
        });
      } else {
        newFinding = value;
      }
      return {
        ...state,
        [toFinding]: newFinding,
      };
    case DELETE_PERIODONTAL_FINDING_ROW:
      const { deletingFrom, deleteIndex } = action.payload as any;
      const newStateDelete = state as any;
      let findingDeletingFrom = newStateDelete[deletingFrom] as any;
      findingDeletingFrom = newStateDelete[deletingFrom].filter(
        (f: any, i: number) => i !== deleteIndex
      );
      return {
        ...state,
        [deletingFrom]: findingDeletingFrom,
      };
    default:
      return { ...state };
  }
};
