import React, { useMemo, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons/faArrowLeft";
import { Box, Button, CircularProgress, Divider, IconButton, Tooltip, useMediaQuery, } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import Chip from "@mui/material/Chip";
import copy from 'clipboard-copy'
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
    isPreIntakeSubmitAction,
    putPreIntakeDataAction,
    updatePreIntakeValidationsAction,
} from "../../../../app/actions/v2/dentist/preIntakeActions";
import { RootState } from "../../../../app/reducers/v2/rootReducer";
import theme from "../../../../theme";
import {
    communicationPreferencesValidator,
    dentalInfoValidator,
    emergencyContactInfoValidator,
    generalHealthValidator,
    letsGetToKnowYouBetterValidator,
    medicalHealthHistoryValidator,
    patientInformationSecondValidator,
} from "../../../../validation/v2/preIntakeValidator";
import QIcon from '../../../../assets/images/v2/QIcon.png'
import AdditionalNotes from '../../dentist/clinicalExam/AdditionalNotes';
import {
    openAdditionalNotesAction,
    syncImageXrayRequestAction,
    updateAppointmentStepAction
} from '../../../../app/actions/v2/dentist/clinicalExam/clinicalExamFilterAction';
import {
    completeCheckout,
    hardTissue,
    HeathHistoryCompleted,
    HeathHistoryPending,
    limitedExam,
    medical,
    odontogram,
    periogram,
    photo,
    softTissue,
    viewExam,
    xray
} from '../../../../utils/v2/clinicalExamConstant';
import {
    openPopupAppointmentTypeChangeAction,
    putPreClinicalDataAction
} from '../../../../app/actions/v2/dentist/preClinicalActions';
import { appointmentDetailsRequestAction } from '../../../../app/actions/v2/dentist/appointmentsAction';
import { putAnnotationRequestAction } from '../../../../app/actions/v2/dentist/clinicalExam/exam/annotationAction';
import {
    putHardTissueRequestAction,
    putLimitedExamRequestAction,
    putOdontogramRequestAction,
    putOtherFormFindingRequestAction,
    putPeriogramRequestAction,
    putSoftTissueRequestAction,
    syncOdontogramPmsDataRequestAction,
    syncPeriogramPmsDataRequestAction
} from '../../../../app/actions/v2/dentist/clinicalExam/exam/examFindingsTypesAction';
import {
    copyExamNotesRequestAction,
    putInsuranceDataRequestAction
} from '../../../../app/actions/v2/dentist/clinicalExam/insuranceAction';
import { updateTreatmentDataAction } from "../../../../app/actions/v2/dentist/recommendationsTreatmentActions";
import {
    isPatientReviewSubmitAction,
    putPatientReviewTreatmentRequestAction
} from "../../../../app/actions/v2/dentist/clinicalExam/patientReviewAction";
import QSummaryPopUp from "../QSummaryPopUp";
import { dentistProfileData, getLocalStorageItem, successToaster } from "../../../../utils/helper";
import {
    updateRiskAssessmentValidationAction
} from "../../../../app/actions/v2/dentist/clinicalExam/riskAssessmentAction";
import {
    createAppointmentFromWellnessRequest,
    generateRiskWellnessPMSRequestAction
} from "../../../../app/actions/v2/dentist/wellnessProfile/wellnessProfileFiltersAction";
import OutstandingTreatment from "../../dentist/OutstandingTreatment";
import CopyPopUpForSafari from "../CopyPopUpForSafari";
import { getTreatmentDataFeesAction } from "../../../../app/actions/v2/dentist/plannedTreatments";
import { getPhotosRequestAction } from "../../../../app/actions/v2/dentist/clinicalExam/exam/uploadPhotosXrayAction";

const useStyle = makeStyles((theme: any) => ({
    appBar: {
        background: `${theme.palette.common.white} !important`,
        color: theme.palette.common.black,
        boxShadow: "none !important",
        padding: "7px 0",
        paddingRight: "0px !important",
    },
    arrowStyle: {
        color: theme.palette.common.black50,
        position: "absolute",
        bottom: -1,
        left: -63,

        zIndex: "2147483647 !important",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "64px",
        height: "81px",
        background: theme.palette.common.white,
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
    buttonHover: {
        padding: "0px",
        color: theme.palette.v2.primary.main,
        "&:hover": {
            background: "transparent",
        },
    },
    boxGreen: {
        color: theme.palette.v2.riskColor.green,
        backgroundColor: theme.palette.success.lightColor
    },
    boxYellow: {
        color: theme.palette.warning.main,
        backgroundColor: theme.palette.warning.lightColor
    },
    boxGreenYellow: {
        color: theme.palette.v2.riskColor.greenYellow,
        backgroundColor: theme.palette.v2.riskColor.lightGreenYellow
    },
    boxYellowRed: {
        color: theme.palette.v2.riskColor.yellowRed,
        backgroundColor: theme.palette.v2.riskColor.lightYellowRed
    },

    boxRed: {
        color: theme.palette.v2.riskColor.red,
        backgroundColor: theme.palette.error.lightColor
    },
    wellnessIcon: {
        height: 15, marginLeft: "10px", color: theme.palette.secondary.main
    },
    customPopper: {
        zIndex: 1024,
    },
    customWidth: {
        fontSize: '12px',
        fontFamily: "FavoritPro-Regular",
        padding: '5px 7px !important',
        border: '1px solid rgba(51, 51, 51, 0.1)',
        boxShadow: '0px 0px 19px -1px rgba(136, 136, 136, 0.07)',
    },
}));

const AppointmentHeader = (props: any) => {
    const { palette } = theme;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { appointmentId, patientId } = useParams();
    const isWellnessProfile: boolean = useMemo(() => {
        return location?.pathname?.includes('/v2/dentist/wellness-profile/')
    }, [location.pathname]);
    const isClinicalExam: boolean = useMemo(() => {
        return location?.pathname.includes('/clinical-exam/')
    }, [location.pathname]);
    const classes = useStyle();
    const preIntakeData = useSelector((state: RootState) => state.preIntake);
    const {
        odontogram: { cameOdontogramFromTreatment },
        preClinical: { appointmentInfo, preClinicalData, appointmentPreIntakeData },
        clinicalExamFilters: {
            appointmentStep,
            findingType,
            selectedImage,
            openAdditionalNotes,
        },
        appointmentsReducer: { appointmentDetails },
        examFindingReducer: { examFindingData },
        recommendationsTreatment: {
            treatmentData,
            insightRecommendationData,
        },
        patientReview: { patientConsent, patientReview },
        wellnessProfileFilters: { wellnessProfileStep, wellnessProfileDataLocal, wellnessProfileData, wellnessRiskScoreDataData },
        preIntake: { getFiledData },
        insurance: {
            hygieneRegimeData,
            insuranceTreatmentData,
            preauthChecklist,
            copyNotesLoader
        },
    } = useSelector((state: RootState) => state);
    const isPhotosXray: boolean = (findingType === photo || findingType === xray) && appointmentStep === 1
    const wellnessData = wellnessRiskScoreDataData ? wellnessRiskScoreDataData : wellnessProfileDataLocal

    const clinicInfo = localStorage.getItem('clinicInfo') || ''
    const clinicData = clinicInfo ? JSON.parse(clinicInfo) : null as any

    const isMobile = useMediaQuery(theme.breakpoints.down(768));
    const isTablet = useMediaQuery(theme.breakpoints.between(769, 992));
    const [isSafari, setIsSafari] = useState(false)
    const [oepnCopyPopUp, setOpenCopyPopUp] = useState(false)

    useEffect(() => {
        const userAgent = window?.navigator?.userAgent;
        if (userAgent?.includes("Safari") && !userAgent?.includes("Chrome")) {
            setIsSafari(true)
        }
    }, []);

    const showViewPreIntake: boolean = useMemo(() => {
        const showOption: string[] = ["/clinical-exam"];
        return showOption.filter((f: string) => location.pathname.includes(f)).length > 0;
    }, [location.pathname]);

    const disabledField: boolean = useMemo(() => {
        if (isWellnessProfile) {
            return false
        } else {
            return (
                [completeCheckout, viewExam]?.includes(appointmentDetails?.sub_status)
            );
        }

    }, [appointmentDetails]);
    const getAveRiskScore: number = useMemo(() => {
        if (wellnessData?.risk_profile) {
            const scores: number[] = Object.values(wellnessData?.risk_profile)?.map((item: any) => item?.score);
            const totalScore: number = scores?.reduce((acc: any, score: any) => acc + score, 0);
            const averageScore: string = (totalScore / 7)?.toFixed(1);
            return parseFloat(averageScore)
        }
        return 0.0;
    }, [wellnessData?.risk_profile, wellnessData, wellnessRiskScoreDataData])
    const handleExamSave = (): void => {
        const is_submit = false
        const deviceType = isMobile ? "mobile" : isTablet ? "tablet" : 'Desktop'
        const isExamCompleted = (appointmentDetails?.sub_status === completeCheckout || appointmentDetails?.sub_status === viewExam)
        if (!isExamCompleted) {
            if (appointmentStep === 0) {
                // Save appointment details and pre-intake data while changing appointment step
                if (appointmentInfo?.appointmentType === "NPE") {
                    appointmentInfo.lastDateOfExamination = null;
                    appointmentInfo.recallCyclePeriod = null;
                }
                if (appointmentInfo?.appointmentType !== preClinicalData?.appointment_details?.appt_type) {
                    dispatch(openPopupAppointmentTypeChangeAction({ isOpen: true, flag: 'fromPreIntake' }))
                } else {
                    dispatch(
                        putPreClinicalDataAction({
                            appointmentDetails: appointmentInfo,
                            preIntakeData: appointmentPreIntakeData,
                            profile_id: dentistProfileData?.id,
                            appointmentID: appointmentId,
                            device: deviceType,
                            callback: () => {
                                navigate({ pathname: `/v2/dentist/appointment/health-history/${appointmentId}/${patientId}` })
                                dispatch(
                                    appointmentDetailsRequestAction({
                                        appointment_id: appointmentId,
                                    })
                                );

                            },
                        })
                    );
                }
            } else if (appointmentStep === 1) {
                if (isPhotosXray) {
                    if (selectedImage) {
                        dispatch(
                            putAnnotationRequestAction({
                                imageId: selectedImage,
                                data: examFindingData,
                            })
                        );
                    } else {
                        navigate({ pathname: `/v2/dentist/appointment/health-history/${appointmentId}/${patientId}` })
                    }

                }
                if (findingType === softTissue) {
                    dispatch(
                        putSoftTissueRequestAction({
                            appointment_id: appointmentId,
                            submit: is_submit,
                            callback: (() => {
                                navigate({ pathname: `/v2/dentist/appointment/health-history/${appointmentId}/${patientId}` })
                            })
                        })
                    );
                }
                if (findingType === hardTissue) {
                    dispatch(
                        putHardTissueRequestAction({
                            appointment_id: appointmentId,
                            submit: is_submit,
                            callback: (() => {
                                navigate({ pathname: `/v2/dentist/appointment/health-history/${appointmentId}/${patientId}` })
                            })
                        })
                    );
                }
                if (findingType === odontogram) {
                    dispatch(
                        putOdontogramRequestAction({
                            appointment_id: appointmentId,
                            submit: is_submit,
                            treatment_planning: cameOdontogramFromTreatment,
                            callback: (() => {
                                navigate({ pathname: `/v2/dentist/appointment/health-history/${appointmentId}/${patientId}` })
                            })
                        })
                    );
                }
                if (findingType === periogram) {
                    dispatch(
                        putPeriogramRequestAction({
                            appointment_id: appointmentId,
                            submit: is_submit,
                            callback: (() => {
                                navigate({ pathname: `/v2/dentist/appointment/health-history/${appointmentId}/${patientId}` })
                            })
                        })
                    );
                }
                if (findingType === medical) {
                    dispatch(
                        putOtherFormFindingRequestAction({
                            appointment_id: appointmentId,
                            submit: is_submit,
                            callback: (() => {
                                navigate({ pathname: `/v2/dentist/appointment/health-history/${appointmentId}/${patientId}` })
                            })
                        })
                    );
                }
                if (findingType === limitedExam) {
                    dispatch(
                        putLimitedExamRequestAction({
                            appointment_id: appointmentId,
                            submit: is_submit,
                            callback: (() => {
                                navigate({ pathname: `/v2/dentist/appointment/health-history/${appointmentId}/${patientId}` })
                            })
                        })
                    );
                }
            } else if (appointmentStep === 2) {
                dispatch(updateRiskAssessmentValidationAction({ errors: {} })) // remove validation while changing appointment step
                navigate({ pathname: `/v2/dentist/appointment/health-history/${appointmentId}/${patientId}` })
            } else if (appointmentStep === 3) {
                // Save treatment data while changing appointment step
                dispatch(
                    updateTreatmentDataAction({
                        recommendations: insightRecommendationData,
                        treatments: treatmentData,
                        appointmentID: appointmentId,
                        callback: () => {
                            navigate({ pathname: `/v2/dentist/appointment/health-history/${appointmentId}/${patientId}` })
                        }
                    })
                );
            } else if (appointmentStep === 4) {
                // Save patient review data while changing appointment step
                const readinessBar = {
                    is_selected: patientConsent?.is_selected,
                    additional_patient_comments:
                        patientConsent?.additional_patient_comments,
                    score: patientConsent?.score,
                };
                dispatch(
                    putPatientReviewTreatmentRequestAction({
                        treatmentData: patientReview,
                        appointmentID: appointmentId,
                        readinessBar,
                        is_second_screen: true,
                        callback: () => {
                            dispatch(isPatientReviewSubmitAction(false))
                            navigate({ pathname: `/v2/dentist/appointment/health-history/${appointmentId}/${patientId}` })
                        },
                    })
                );
            } else if (appointmentStep === 5) {
                // call hand off page API
                const data = {
                    appointmentID: appointmentId,
                    hygiene_regime: hygieneRegimeData,
                    treatments: insuranceTreatmentData,
                    checklist: preauthChecklist,
                };
                dispatch(
                    putInsuranceDataRequestAction({
                        data,
                        callback: () => {
                            navigate({ pathname: `/v2/dentist/appointment/health-history/${appointmentId}/${patientId}` })
                        }
                    })
                );
            }
        } else {
            navigate({ pathname: `/v2/dentist/appointment/health-history/${appointmentId}/${patientId}` })
        }
    };
    const saveChanges = (submit_form?: boolean): void => {
        dispatch(isPreIntakeSubmitAction(true))
        const deviceType = isMobile ? "mobile" : isTablet ? "tablet" : 'Desktop'
        if (submit_form) {
            const patientInformation = patientInformationSecondValidator(
                preIntakeData.patientInformation
            );
            const emergencyContactInfo = emergencyContactInfoValidator(
                preIntakeData.emergencyContactInfo
            );
            const letsGetToKnowYouBetter = letsGetToKnowYouBetterValidator(
                preIntakeData.letsGetToKnowYouBetter
            );
            const dentalInfo = dentalInfoValidator(preIntakeData.dentalInfo);
            const medicalHealthHistory = medicalHealthHistoryValidator(
                preIntakeData.medicalHealthHistory, getFiledData
            );
            const communicationPreferences = communicationPreferencesValidator(
                preIntakeData.communicationPreferences
            );
            const generalHealth = generalHealthValidator(preIntakeData.generalHealth);
            // TODO : Dental Insurance Information - Hide until we have import/export support (7558, 7054,5245)
            // const dentistInsuranceInfo = dentistInsuranceInfoValidator(
            //   preIntakeData?.dentistInsuranceInfo,
            //   preIntakeData.letsGetToKnowYouBetter
            // );
            const errors = {
                emergencyContactInfo: emergencyContactInfo.errors,
                // dentistInsuranceInfo: dentistInsuranceInfo.errors,     // TODO : Dental Insurance Information - Hide until we have import/export support (7558, 7054,5245)
                generalHealth: generalHealth.errors,
                letsGetToKnowYouBetter: letsGetToKnowYouBetter.errors,
                dentalInfo: dentalInfo.errors,
                // sleepQuality: sleepQuality.errors,
                medicalHealthHistory: medicalHealthHistory.errors,
                patientInformation: patientInformation.errors,
                communicationPreferences: communicationPreferences.errors,
            };
            dispatch(updatePreIntakeValidationsAction(errors));
            //Save PreIntake
            if (
                patientInformation?.isValid &&
                emergencyContactInfo?.isValid &&
                letsGetToKnowYouBetter?.isValid &&
                medicalHealthHistory?.isValid &&
                communicationPreferences?.isValid &&
                // dentistInsuranceInfo?.isValid && // TODO : Dental Insurance Information - Hide until we have import/export support (7558, 7054,5245)
                dentalInfo?.isValid &&
                generalHealth?.isValid
            ) {
                dispatch(
                    putPreIntakeDataAction({
                        preIntakeData: preIntakeData,
                        patient_id: patientId,
                        clinic_id: localStorage?.getItem('clinic'),
                        submit_form: submit_form,
                        profile_id: dentistProfileData?.id,
                        device: deviceType,
                        callback: () => {
                            if (submit_form && appointmentId) {
                                navigate({
                                    pathname: `/v2/dentist/appointment/clinical-exam/${appointmentDetails?.appointmentID}/${appointmentDetails?.patientID}`
                                });
                                const step = 0 as any
                                dispatch(updateAppointmentStepAction(step as any));
                            } else {
                                navigate(`/v2/dentist/wellness-profile/${patientId}`)
                            }
                        },
                    })
                );
            }
        } else {
            dispatch(
                putPreIntakeDataAction({
                    preIntakeData: preIntakeData,
                    patient_id: patientId,
                    submit_form: submit_form,
                    device: deviceType,
                    clinic_id: localStorage?.getItem('clinic'),
                    profile_id: dentistProfileData?.id,
                })
            )
        }
    };
    const handleBack = (): void => {
        if (appointmentId) {
            // Redirect back to the clinical exam at the appointment details page
            const step = 0 as any
            dispatch(updateAppointmentStepAction(step as any));
        }
        navigate(-1)
    }
    const copyExamNotes = () => {
        dispatch(
            copyExamNotesRequestAction({
                appointment_id: appointmentId,
                callback: (response: any) => {
                    if (isSafari) {
                        setOpenCopyPopUp(true)
                    } else {
                        copy(response)
                        successToaster("Notes copied to clipboard!");
                    }
                }
            })
        );
    };
    const riskColorClass = (score: number) => {
        if (score <= 0.9) {
            return classes.boxGreen
        } else if (score <= 1.5) {
            return classes.boxGreenYellow
        } else if (score <= 3) {
            return classes.boxYellow
        } else if (score <= 3.7) {
            return classes.boxYellowRed
        } else {
            return classes.boxRed
        }
    }

    const startNewExam = () => {
        dispatch(createAppointmentFromWellnessRequest({
            patient_id: patientId || '',
            clinic_id: getLocalStorageItem('clinic') || '',
            staff_id: dentistProfileData?.id,
            callback: (data: any) => {
                window.location.href = `/v2/dentist/appointment/clinical-exam/${data?.appointment_id}/${patientId}`
            }
        }))
    }

    const syncPMSData = () => {
        if (isWellnessProfile) {
            dispatch(generateRiskWellnessPMSRequestAction({ patient_id: patientId }))
        }

        if (clinicData?.pms_integration) {
            dispatch(syncOdontogramPmsDataRequestAction({
                appointment_id: appointmentId,
                patientId: patientId,
                preventGetApiCall: (findingType || wellnessProfileStep) !== odontogram,
                callback: () => {
                    dispatch(getTreatmentDataFeesAction({
                        appointmentID: isWellnessProfile ? wellnessProfileData?.latest_appointment_id : appointmentId,
                        patientID: patientId
                    }))
                }
            }))
            dispatch(syncPeriogramPmsDataRequestAction({
                appointment_id: appointmentId,
                patientId: patientId,
                preventGetApiCall: (findingType || wellnessProfileStep) !== periogram,
            }))
            dispatch(syncImageXrayRequestAction({
                appointment_id: appointmentId || '',
                patientId: patientId,
                callback: () => {
                    const shouldGetPhotos = isWellnessProfile
                        ? wellnessProfileStep === photo || wellnessProfileStep === xray
                        : appointmentStep === 1 && (findingType === photo || findingType === xray);

                    if (shouldGetPhotos) {
                        dispatch(
                            getPhotosRequestAction({
                                appointment_id: isWellnessProfile ? undefined : appointmentId,
                                image_type: (findingType || wellnessProfileStep) === photo ? 'image' : 'xray',
                                patient_id: patientId,
                            })
                        );
                    }
                }
            }))
        }
    }

    return <>
        <Box sx={{ width: "64px" }} className={classes.arrowStyle}>
            <IconButton
                disableFocusRipple
                disableRipple
                onClick={() => props.setMobileOpen(!props.mobileOpen)}
            >
                <FontAwesomeIcon icon={faBars} className={`f-24 f-w-900`} />
            </IconButton>
        </Box>
        <Box sx={{
            width: "100% !important",
            background: `${theme.palette.common.white} !important`,
            padding: "0px !important",
        }}>
            <AppBar position="static" className={classes.appBar}>
                <Box sx={{
                    display: "flex",
                    textAlign: "center",
                    color: "black",
                    justifyContent: "space-between",
                    flexDirection: "row",
                }}>
                    <Box display={"flex"} alignItems={"center"}>
                        {!showViewPreIntake && !isWellnessProfile && <React.Fragment>
                            <Button
                                variant="text"
                                startIcon={
                                    <FontAwesomeIcon
                                        icon={faArrowLeft}
                                        className="f-16"
                                        width={16}
                                        height={14}
                                    />
                                }
                                className={classes.buttonHover}
                                onClick={handleBack}
                            >
                                Back
                            </Button>
                            <Divider
                                sx={{ margin: "0px 20px" }}
                                orientation="vertical"
                                flexItem
                            />
                        </React.Fragment>}

                        <Box display={'flex'} alignItems={'center'}>
                            {isClinicalExam ?
                                <Tooltip
                                    title="Wellness Profile"
                                    classes={{ popper: classes.customPopper, tooltip: classes.customWidth }}
                                >
                                    <Link
                                        to={!isWellnessProfile ? `/v2/dentist/wellness-profile/${appointmentDetails?.patientID}` : ''}
                                        target={!isWellnessProfile ? "_blank" : ''}
                                        className={`cursor-pointer`}
                                        style={{
                                            textDecoration: "none",
                                        }}
                                    >
                                        <Typography
                                            className="fw-medium"
                                            color={theme.palette.common.black50}
                                            fontSize={"26px"}
                                            display={"flex"}
                                            alignItems={"center"}
                                        >
                                            <Box color={palette.v2.primary.main} sx={{ textTransform: 'capitalize' }}>
                                                {props?.headerText}
                                            </Box>
                                        </Typography>
                                    </Link>
                                </Tooltip>
                                :
                                <Typography
                                    className="fw-medium"
                                    color={theme.palette.common.black50}
                                    fontSize={"26px"}
                                    display={"flex"}
                                    alignItems={"center"}>
                                    <Box color={palette.v2.primary.main} sx={{ textTransform: 'capitalize' }}>
                                        {props?.headerText}
                                    </Box>
                                </Typography>
                            }
                            {clinicData?.is_q_summarization &&
                                (isWellnessProfile && wellnessProfileData?.latest_appointment_id) ?
                                <QSummaryPopUp appointmentId={wellnessProfileData?.latest_appointment_id} />
                                :
                                <QSummaryPopUp appointmentId={appointmentId} />
                            }
                            {isWellnessProfile &&
                                <React.Fragment>
                                    <Chip sx={{ ml: '20px', height: '43px', borderRadius: '30px' }}
                                        className={` f-w-500 fw-medium f-16 ${riskColorClass(getAveRiskScore)}`}
                                        label={`Wellness Score ${getAveRiskScore}`} />
                                    <Tooltip classes={{ popper: classes.customPopper }}
                                        title={"For Research Purposes Only"}>
                                        <FontAwesomeIcon
                                            icon={faInfoCircle}
                                            color={theme.palette.secondary.main}
                                            className={classes.wellnessIcon}
                                        />
                                    </Tooltip>
                                </React.Fragment>
                            }
                        </Box>
                    </Box>
                    <Box
                        mr={0}
                        marginRight={0}
                        sx={{ marginRight: "0px !important" }}
                        display={"flex"}
                        alignItems={"center"}
                        justifySelf={"end"}
                    >
                        {isWellnessProfile ?
                            <></>
                            // <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                            //     <FontAwesomeIcon icon={faEye} color={theme.palette.text.darkGrey}/>
                            //     <Box ml={1} display={'flex'} flexDirection={'column'} alignItems={'start'}>
                            //         <Typography className='f-w-400 fw-regular f-14'
                            //                     color={theme.palette.text.darkGrey}>Last updated on </Typography>
                            //         <Typography className='f-w-400 fw-regular f-14'
                            //                     color={theme.palette.text.darkGrey}>24 Jul 2020, 11:30
                            //             AM</Typography>
                            //     </Box>
                            // </Box> 
                            :
                            <React.Fragment>
                                {showViewPreIntake ?
                                    <Box display={'flex'}>
                                        {clinicData?.pms_integration &&
                                            <Button
                                                variant="outlined"
                                                onClick={syncPMSData}
                                                className='f-14 f-w-400 fw-regular'
                                                sx={{
                                                    height: "40px",
                                                    minWidth: "80px",
                                                    borderColor: palette.v2.primary.main,
                                                    color: palette.v2.primary.main,
                                                    marginRight: '20px',
                                                    textTransform: "none"
                                                }}
                                            >
                                                Sync
                                            </Button>
                                        }
                                        {clinicData?.pms_integration &&
                                            <OutstandingTreatment />
                                        }

                                        <Link style={{
                                            color: palette.v2.primary.main,
                                            textTransform: "none",
                                            textDecoration: 'none'
                                        }}
                                            to={`/dentist/second-screen/${appointmentDetails?.appointmentID}/${patientId}`}
                                            target='_blank'>
                                            <Box className='f-14 f-w-400 fw-regular' display={'flex'}
                                                alignItems={'center'} justifyContent={'center'} sx={{
                                                    display: 'flex',
                                                    textAlign: 'center',
                                                    height: "40px",
                                                    minWidth: "130px",
                                                    border: `1px solid ${palette.v2.primary.main}`,
                                                    marginRight: '20px',
                                                    borderRadius: '4px'
                                                }}> Patient Review </Box>
                                        </Link>

                                        <Button
                                            variant="outlined"
                                            onClick={copyExamNotes}
                                            className='f-14 f-w-400 fw-regular'
                                            sx={{
                                                height: "40px",
                                                minWidth: "150px",
                                                borderColor: palette.v2.primary.main,
                                                color: palette.v2.primary.main,
                                                marginRight: '20px',
                                                textTransform: "none"
                                            }}
                                        >
                                            {copyNotesLoader ? <CircularProgress size={19} /> : 'Copy Clinical Note'}
                                        </Button>

                                        <Button
                                            variant={"outlined"}
                                            className={`fw-regular`}
                                            sx={{
                                                height: "40px",
                                                width: "89px",
                                                borderColor: palette.v2.primary.main,
                                                color: palette.v2.primary.main,
                                                marginRight: '20px'
                                            }}
                                            disabled={preIntakeData.savePreIntakeLoader}
                                            onClick={() =>
                                                dispatch(openAdditionalNotesAction(true))
                                            }
                                        >
                                            <img src={QIcon} width={20} height={20} alt={'Q'} />
                                            <Typography ml={1} className='f-14 f-w-400 fw-regular'> Note</Typography>
                                        </Button>

                                        <Button
                                            variant={"outlined"}
                                            className={`fw-regular`}
                                            sx={{
                                                height: "40px",
                                                width: "170px",
                                                borderColor: palette.v2.primary.main,
                                                color: palette.v2.primary.main,
                                            }}
                                            disabled={preIntakeData.savePreIntakeLoader}
                                            onClick={() =>
                                                showViewPreIntake
                                                    ? handleExamSave()
                                                    : saveChanges()
                                            }
                                        >
                                            View Health History
                                        </Button>
                                    </Box> : <Box className="d-flex justify-end">
                                        {preIntakeData?.preIntakeData?.completion_status ===
                                            HeathHistoryPending && (
                                                <Button
                                                    color="error"
                                                    variant={"contained"}
                                                    className={`button fw-regular`}
                                                    sx={{
                                                        height: "40px",
                                                        width: "140px",
                                                        marginRight: "20px",
                                                    }}
                                                    disabled={disabledField || preIntakeData.savePreIntakeLoader}
                                                    onClick={() => saveChanges(false)}
                                                >
                                                    {preIntakeData.savePreIntakeLoader ? (
                                                        <CircularProgress color={"primary"} size={19} />
                                                    ) : (
                                                        "Save as Draft"
                                                    )}
                                                </Button>
                                            )}
                                        <Button
                                            color="error"
                                            variant={"contained"}
                                            className={`button fw-regular`}
                                            sx={{
                                                height: "40px",
                                                width: "200px",
                                            }}
                                            disabled={
                                                disabledField || preIntakeData.savePreIntakeLoader
                                            }
                                            onClick={() => saveChanges(true)}
                                        >
                                            {preIntakeData.savePreIntakeLoader ? (
                                                <CircularProgress color={"primary"} size={19} />
                                            ) : preIntakeData?.preIntakeData?.completion_status ===
                                                HeathHistoryCompleted ? (
                                                "Update Health History"
                                            ) : (
                                                "Submit Health History"
                                            )}
                                        </Button>

                                    </Box>}

                            </React.Fragment>}
                    </Box>
                    {isWellnessProfile &&
                        <Box display={'flex'}>
                            {clinicData?.pms_integration &&
                                <Button
                                    variant="outlined"
                                    onClick={syncPMSData}
                                    className='f-14 f-w-400 fw-regular'
                                    sx={{
                                        height: "40px",
                                        minWidth: "80px",
                                        borderColor: palette.v2.primary.main,
                                        color: palette.v2.primary.main,
                                        marginRight: '20px',
                                        textTransform: "none"
                                    }}
                                >
                                    Sync
                                </Button>
                            }
                            {clinicData?.pms_integration &&
                                <OutstandingTreatment />
                            }
                            <Button
                                color="error"
                                variant={"contained"}
                                className={`button fw-regular`}
                                sx={{
                                    width: "190px",
                                    height: "77px",
                                }}
                                onClick={startNewExam}
                            >
                                Start New Exam
                            </Button>
                        </Box>
                    }

                </Box>
            </AppBar >
        </Box >
        <AdditionalNotes open={openAdditionalNotes} />
        {oepnCopyPopUp && <CopyPopUpForSafari open={oepnCopyPopUp} setOpen={setOpenCopyPopUp} />}
    </>;
};
export default AppointmentHeader;
