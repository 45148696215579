import {
    GET_INSURANCE_APPROVAL_CHECKLIST_FAILURE,
    GET_INSURANCE_APPROVAL_CHECKLIST_REQUEST,
    GET_INSURANCE_APPROVAL_CHECKLIST_CACHE_DATA_CLEAR_REQUEST,
    GET_INSURANCE_APPROVAL_CHECKLIST_SUCCESS,
    GET_INSURANCE_APPROVAL_FAILURE,
    GET_INSURANCE_APPROVAL_REQUEST,
    GET_INSURANCE_APPROVAL_CACHE_DATA_CLEAR_REQUEST,
    GET_INSURANCE_APPROVAL_SUCCESS,
    PUT_INSURANCE_APPROVAL_CHECKLIST_FAILURE,
    PUT_INSURANCE_APPROVAL_CHECKLIST_REQUEST,
    PUT_INSURANCE_APPROVAL_CHECKLIST_SUCCESS,
    PUT_INSURANCE_APPROVAL_FAILURE,
    PUT_INSURANCE_APPROVAL_REQUEST,
    PUT_INSURANCE_APPROVAL_SUCCESS
} from "../../../../ActionTypes/v1/dentist/insuranceApproval";
import {insuranceApprovalActions, InsuranceApprovalInterface} from "../../../../types/dentistTypes";

const initialState: InsuranceApprovalInterface = {
    putInsuranceApprovalData: null,
    putInsuranceApprovalLoader: false,
    putInsuranceApprovalError: null,
    insuranceApprovalData: null,
    insuranceApprovalDataError: null,
    loading: false,
    insuranceApprovalCheckListData: null,
    insuranceApprovalCheckListError: null,
    putInsuranceApprovalCheckListError: null
};

const insuranceApprovalReducer = (
    state = initialState,
    action: insuranceApprovalActions
) => {
    switch (action.type) {
        case GET_INSURANCE_APPROVAL_REQUEST:
            return  {
                ...state,
                loading: true
            };
        case GET_INSURANCE_APPROVAL_CACHE_DATA_CLEAR_REQUEST:
            return {
                ...state,
                insuranceApprovalData: null
            }    
        case GET_INSURANCE_APPROVAL_SUCCESS:
            return {
                ...state,
                loading: false,
                insuranceApprovalData: action.payload,
                insuranceApprovalDataError: null
            };
        case GET_INSURANCE_APPROVAL_FAILURE:
            return {
                ...state,
                insuranceApprovalData: null,
                insuranceApprovalDataError: action.payload
            };
        case PUT_INSURANCE_APPROVAL_REQUEST:
            return {
                ...state,
                putInsuranceApprovalLoader: true,
            };
        case PUT_INSURANCE_APPROVAL_SUCCESS:
            return {
                ...state,
                putInsuranceApprovalLoader: false,
                putInsuranceApprovalData: action.payload,
                putInsuranceApprovalError: null,
            };
        case PUT_INSURANCE_APPROVAL_FAILURE:
            return {
                ...state,
                putInsuranceApprovalLoader: false,
                putInsuranceApprovalData: null,
                putInsuranceApprovalError: action.payload,
            };
        case GET_INSURANCE_APPROVAL_CHECKLIST_REQUEST:
            return  {
                ...state,
                loading: true
            }
        case GET_INSURANCE_APPROVAL_CHECKLIST_CACHE_DATA_CLEAR_REQUEST:
            return {
                ...state,
                insuranceApprovalCheckListData: null
            }    
        case GET_INSURANCE_APPROVAL_CHECKLIST_SUCCESS:
            return {
                ...state,
                loading: false,
                insuranceApprovalCheckListData: action.payload,
                insuranceApprovalCheckListError: null
            };
        case GET_INSURANCE_APPROVAL_CHECKLIST_FAILURE:
            return {
                ...state,
                loading: false,
                insuranceApprovalCheckListData: null,
                insuranceApprovalCheckListError: action.payload
            };
        case PUT_INSURANCE_APPROVAL_CHECKLIST_REQUEST:
            return  {
                ...state,
                loading: true
            }
        case PUT_INSURANCE_APPROVAL_CHECKLIST_SUCCESS:
            return {
                ...state,
                loading: false,
                putInsuranceApprovalCheckListError: null
            };
        case PUT_INSURANCE_APPROVAL_CHECKLIST_FAILURE:
            return {
                ...state,
                loading: false,
                putInsuranceApprovalCheckListError: action.payload
            };
        default:
            return {
                ...state,
            };
    }
};

export default insuranceApprovalReducer;


