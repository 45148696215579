import {CssBaseline, Paper} from '@mui/material';
import {Box} from '@mui/system';
import React from 'react';
import {useSelector} from 'react-redux';
import {RootState} from '../../../../app/reducers/v2/rootReducer';
import theme from '../../../../theme';
import HelmetComponent from '../../../v1/Common/HelmetComponent';
import Sidebar from '../../dentist/Sidebar';

export const MainLayout = (props: any) => {
    const {component, name} = props
    const sidebarWidth = useSelector((state: RootState) => state.sidebar?.width)

    return (
        <Paper sx={{background: `${theme.palette.background.secondaryPaper} !important`, height: '100vh'}}
               elevation={0}>
            <HelmetComponent title={name}/>
            <CssBaseline/>
            <Sidebar headerTitle={name}/>
            <Paper
                sx={{
                    marginLeft: {md: `${sidebarWidth}px`},
                    transition: theme.transitions.create('margin', {
                        easing: theme.transitions.easing.sharp,
                        duration: theme.transitions.duration.enteringScreen
                    }),
                    marginTop: '80px',
                    background: `${theme.palette.background.secondaryPaper} !important`,
                    height: 'calc(100vh - 101px)'
                }}
                elevation={0}
            >
                <Box sx={{
                    background: `${theme.palette.background.secondaryPaper} !important`,
                    height: 'calc(100vh - 80px)',
                    overflowY: 'auto',
                }} p={'20px'}>
                    {component}
                </Box>
            </Paper>
        </Paper>
    );
};
