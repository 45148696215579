//

import {
  ADD_ADDITIONAL_IMAGE_XRAY,
  CHANGE_ADDITIONAL_IMAGE_XRAY_TITLE,
  CLEAR_PHOTO_XRAY_DATA,
  DELETE_PHOTOS_REQUEST,
  DELETE_PHOTOS_REQUEST_FAILURE,
  DELETE_PHOTOS_REQUEST_SUCCESS, GET_PHOTOS_DETAILS_REQUEST,
  GET_PHOTOS_REQUEST, GET_PHOTOS_REQUEST_DETAILS_FAILURE, GET_PHOTOS_REQUEST_DETAILS_SUCCESS,
  GET_PHOTOS_REQUEST_FAILURE,
  GET_PHOTOS_REQUEST_SUCCESS,
  JUST_UPLOADED_INDEX,
  PUT_PHOTOS_REQUEST,
  PUT_PHOTOS_REQUEST_FAILURE,
  PUT_PHOTOS_REQUEST_SUCCESS,
  SELECT_PHOTO,
  UPDATE_PHOTOS_XRAY_VALIDATIONS,
  UPLOAD_PHOTO,
  UPLOAD_PHOTO_MODEL
} from '../../../../../ActionTypes/v2/dentist/clinicalExam/exams/uploadPhotosXray';
import {
  addAdditionalImageXrayRequest,
  changeAdditionalImageXrayTitleRequest,
  clearPhotoXrayDataRequest,
  deletePhotosRequest,
  deletePhotosRequestFailure,
  deletePhotosRequestFailurePayload,
  deletePhotosRequestPayload,
  GetPhotosDetailsRequest, GetPhotosDetailsRequestFailure, GetPhotosDetailsRequestFailurePayload,
  GetPhotosDetailsRequestPayload, GetPhotosDetailsRequestSuccess,
  GetPhotosDetailsRequestSuccessPayload,
  getPhotosRequest,
  getPhotosRequestFailure,
  getPhotosRequestFailurePayload,
  getPhotosRequestPayload,
  getPhotosRequestSuccess,
  getPhotosRequestSuccessPayload,
  justUploadedIndex,
  putPhotosRequest,
  putPhotosRequestFailure,
  putPhotosRequestFailurePayload,
  putPhotosRequestPayload,
  putPhotosRequestSuccess,
  putPhotosRequestSuccessPayload,
  selectPhotosRequest,
  updatePhotosXrayValidationRequest,
  uploadPhotosModelRequest,
  uploadPhotosRequest
} from '../../../../../types/v2/dentist/clinicalExam/exam/uploadPhotosXray';

export const selectPhotosAction = (payload: any): selectPhotosRequest => ({
  type: SELECT_PHOTO,
  payload
});
export const uploadPhotosAction = (payload: any): uploadPhotosRequest => ({
  type: UPLOAD_PHOTO,
  payload
});

export const uploadPhotosModelAction = (payload: any): uploadPhotosModelRequest => ({
  type: UPLOAD_PHOTO_MODEL,
  payload
});

export const getPhotosRequestAction = (payload: getPhotosRequestPayload): getPhotosRequest => ({
  type: GET_PHOTOS_REQUEST,
  payload
});

export const getPhotosRequestSuccessAction = (payload: getPhotosRequestSuccessPayload): getPhotosRequestSuccess => ({
  type: GET_PHOTOS_REQUEST_SUCCESS,
  payload
});

export const getPhotosRequestFailureAction = (payload: getPhotosRequestFailurePayload): getPhotosRequestFailure => ({
  type: GET_PHOTOS_REQUEST_FAILURE,
  payload
});

//put image/xray

export const putPhotosRequestAction = (payload: putPhotosRequestPayload): putPhotosRequest => ({
  type: PUT_PHOTOS_REQUEST,
  payload
});

export const putPhotosRequestSuccessAction = (payload: putPhotosRequestSuccessPayload): putPhotosRequestSuccess => ({
  type: PUT_PHOTOS_REQUEST_SUCCESS,
  payload
});

export const putPhotosRequestFailureAction = (payload: putPhotosRequestFailurePayload): putPhotosRequestFailure => ({
  type: PUT_PHOTOS_REQUEST_FAILURE,
  payload
});

//Delete image/xray
export const deletePhotosRequestAction = (payload: deletePhotosRequestPayload): deletePhotosRequest => ({
  type: DELETE_PHOTOS_REQUEST,
  payload
});
export const deletePhotosRequestSuccessAction = (payload: any): any => ({
  type: DELETE_PHOTOS_REQUEST_SUCCESS,
  payload
});

export const deletePhotosRequestFailureAction = (payload: deletePhotosRequestFailurePayload): deletePhotosRequestFailure => ({
  type: DELETE_PHOTOS_REQUEST_FAILURE,
  payload
});

export const addAdditionalImageXrayAction = (payload: any): addAdditionalImageXrayRequest => ({
  type: ADD_ADDITIONAL_IMAGE_XRAY,
  payload
});

export const changeAdditionalImageXrayTitleRequestAction = (payload: any): changeAdditionalImageXrayTitleRequest => ({
  type: CHANGE_ADDITIONAL_IMAGE_XRAY_TITLE,
  payload
});

export const updatePhotosXrayValidationRequestRequestAction = (payload: any): updatePhotosXrayValidationRequest => ({
  type: UPDATE_PHOTOS_XRAY_VALIDATIONS,
  payload
});

//clear redux data
export const clearPhotoXrayDataRequestAction = (payload: any): clearPhotoXrayDataRequest => ({
  type: CLEAR_PHOTO_XRAY_DATA,
  payload
});

// set uploaded photo/xray index
export const justUploadedIndexAction = (payload: number): justUploadedIndex => ({
  type: JUST_UPLOADED_INDEX,
  payload
});


// get photos/xray details by id
export const getPhotosDetailsRequestAction = (payload: GetPhotosDetailsRequestPayload): GetPhotosDetailsRequest => ({
  type: GET_PHOTOS_DETAILS_REQUEST,
  payload
});

export const getPhotosDetailsRequestSuccessAction = (payload: GetPhotosDetailsRequestSuccessPayload): GetPhotosDetailsRequestSuccess => ({
  type: GET_PHOTOS_REQUEST_DETAILS_SUCCESS,
  payload
});

export const getPhotosDetailsRequestFailureAction = (payload: GetPhotosDetailsRequestFailurePayload): GetPhotosDetailsRequestFailure => ({
  type: GET_PHOTOS_REQUEST_DETAILS_FAILURE,
  payload
});