import {Box, useMediaQuery} from '@mui/material';
import {makeStyles} from '@mui/styles';
import React, { useState} from 'react'
import Slider from 'react-slick';
import {faExpand} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faComment} from "@fortawesome/free-regular-svg-icons";
import theme from "../../../../theme";
import AddCommentsPopup from "../../dentist/clinicalExam/PhotosXrayList/PhotoXrayCard/AddCommentsPopup";
import ImageXrayPupUp from "../ImageXrayPupUp";

export const settings = {
    dots: false,
    infinite: false,
    speed: 300,
    arrows: true,
    autoplay: false,
    slidesToScroll: 1,
};

const useStyles = makeStyles(() => ({
    imageBg: {
        display: 'flex',
        width: '100%',
        height: '362px',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        borderRadius: '30px',
    },
    marginRight: (isPatient: any) => ({
        '& .slick-slide': {
            marginRight: '12px !important',
        },
        '& .slick-list': {
            marginRight: '-12px !important',
            height: isPatient ? '400px' : '362px'
        }
    })
}))

type Props = {
    data: any
    isPatient?: boolean | undefined
    patientReview?: boolean | undefined
    disabledComment?: boolean | undefined
    isPhoto: boolean
}
const ImageXraySlider = (props: Props) => {
    const {data, isPatient, patientReview, disabledComment, isPhoto} = props
    const classes = useStyles(isPatient)
    const isSmallScreen = useMediaQuery(theme.breakpoints.down(500));
    const [openCommentsPopup, setOpenCommentsPopup] = useState(false);
    const [openImageXrayPopup, setOpenImageXrayPopup] = useState(false);
    const [selectImageXray, setSelectImageXray] = useState(null)


    const handleClick = (data?: any) => {
        setSelectImageXray(data)
        setOpenCommentsPopup(true)
    };

    const handleImageXrayPopUp = (res: any) => {
        setOpenImageXrayPopup(true)
        setSelectImageXray(res)
    }
        return (
        <Box className='patient_review_slider'>
            <Slider  {...settings} slidesToShow={isSmallScreen ? 1 : data?.length === 2 ? 1.99 : 2.1}
                     className={classes.marginRight}>
                {data && data?.map((res: any, i: number) =>
                    <Box key={i} mr={'12px'}>
                        <Box
                            className={classes.imageBg}
                            style={{
                                backgroundImage: `url(${res?.image_path})`,
                            }}>
                        </Box>

                        {
                            <Box className={'cursor-pointer'} width={'100%'} display={'flex'} justifyContent={'end'}
                                 pt={2}>
                                <Box mr={1}>
                                    <FontAwesomeIcon className={'cursor-pointer f-w-900 f-16 lh-16 labelColor'}
                                                     onClick={() => handleImageXrayPopUp(res)}
                                                     icon={faExpand}/>
                                </Box>
                                <FontAwesomeIcon
                                    onClick={() => handleClick(res)}
                                    icon={faComment}
                                    className="f-w-900 f-16 lh-16 labelColor"
                                />
                            </Box>}
                    </Box>
                )}
            </Slider>
            {openCommentsPopup &&
                <AddCommentsPopup disabledComment={(patientReview ? disabledComment : isPatient) || false}
                                  isPatient={isPatient}
                                  open={openCommentsPopup}
                                  setOpen={setOpenCommentsPopup}
                                  isPhoto={isPhoto} patientReview={patientReview}
                                  selectImageXray={selectImageXray} setSelectImageXray={setSelectImageXray}
                />}
            {openImageXrayPopup && <ImageXrayPupUp open={openImageXrayPopup} setOpen={setOpenImageXrayPopup}
                                                   setSelectImageXray={setSelectImageXray}
                                                   selectImageXray={selectImageXray} isPatient={isPatient}
                                                   patientReview={patientReview}
            />}
        </Box>
    )
}

export default ImageXraySlider