import React, { useMemo, useState } from "react";
import { Box, CardActionArea } from "@mui/material";
import { useSelector } from "react-redux";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import { faComment } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocation } from "react-router-dom";
import { RootState } from "../../../../../app/reducers/v2/rootReducer";
import NoDataFound from "../../../../../components/v1/Common/NoDataFound";
import theme from "../../../../../theme";
import AddCommentsPopup
    from "../../../../../components/v2/dentist/clinicalExam/PhotosXrayList/PhotoXrayCard/AddCommentsPopup";
import ImageXrayPupUp from "../../../../../components/v2/common/ImageXrayPupUp";


const useStyles = makeStyles((theme: any) => ({
    noHover: {
        padding: "16px 0 !important",
    },
    actionArea: {
        "&:hover .MuiCardActionArea-focusHighlight": {
            opacity: 0, // remove the opacity on hover
        },
    },
    imageBox: {
        [theme.breakpoints.between(1760, 1920)]: {
            height: '205px',
        },
        [theme.breakpoints.between(1640, 1760)]: {
            height: '190px',
        },
        [theme.breakpoints.down(1415)]: {
            height: '190px',
        },
        [theme.breakpoints.between(1000, 1235)]: {
            height: '220px',
        },
        [theme.breakpoints.between(750, 835)]: {
            height: '170px',
        },
        [theme.breakpoints.down(750)]: {
            height: '220px',
        },
    },
    imageXrayGrid: {
        height: '280px',
        width: '440px',
        [theme.breakpoints.down('xl')]: {
            height: '265px',
            width: '440px'
        },
        [theme.breakpoints.down('lg')]: {
            height: '250px',
            width: '440px'
        },
        [theme.breakpoints.down('md')]: {
            height: '245px !important',
            width: '380px'
        },
        [theme.breakpoints.down('sm')]: {
            height: '195px',
            width: '420px'
        },
        [theme.breakpoints.down('xs')]: {
            height: '195px',
            width: '440px'
        },
        [theme.breakpoints.between(1760, 1920)]: {
            width: '410px',
        },
        [theme.breakpoints.between(1640, 1760)]: {
            width: '380px',
            height: '250px'
        },
        [theme.breakpoints.down(1415)]: {
            width: '380px',
            height: '250px'
        },
        [theme.breakpoints.between(1000, 1235)]: {
            width: '440px',
            height: '275px'
        },
        [theme.breakpoints.between(750, 835)]: {
            width: '340px',
            height: '225px !important'
        },
        [theme.breakpoints.down(750)]: {
            width: '440px',
            height: '275px !important'
        },
        [theme.breakpoints.down(500)]: {
            width: '285px',
            height: '275px !important'
        }
    },
    imageXrayGridParentBox: {
        [theme.breakpoints.up('xl')]: {
            gridTemplateColumns: 'repeat(auto-fit, minmax(440px, max-content))'
        },
        [theme.breakpoints.up('lg')]: {
            gridTemplateColumns: 'repeat(auto-fit, minmax(440px, max-content))'
        },
        [theme.breakpoints.up('md')]: {
            gridTemplateColumns: 'repeat(auto-fit, minmax(380px, max-content))'
        },
        [theme.breakpoints.up('sm')]: {
            gridTemplateColumns: 'repeat(auto-fit, minmax(420px, max-content))'
        },
        [theme.breakpoints.up('xs')]: {
            gridTemplateColumns: 'repeat(auto-fit, minmax(440px, max-content))'
        },
        [theme.breakpoints.between(1760, 1920)]: {
            gridTemplateColumns: 'repeat(auto-fit, minmax(410px, max-content))'
        },
        [theme.breakpoints.between(1640, 1760)]: {
            gridTemplateColumns: 'repeat(auto-fit, minmax(380px, max-content))'
        },
        [theme.breakpoints.down(1415)]: {
            gridTemplateColumns: 'repeat(auto-fit, minmax(380px, max-content))'
        },
        [theme.breakpoints.between(1000, 1235)]: {
            gridTemplateColumns: 'repeat(auto-fit, minmax(440px, max-content))'
        },
        [theme.breakpoints.between(650, 835)]: {
            gridTemplateColumns: 'repeat(auto-fit, minmax(340px, max-content))'
        },
        [theme.breakpoints.down(750)]: {
            gridTemplateColumns: 'repeat(auto-fit, minmax(440px, max-content))'
        },
        [theme.breakpoints.down(500)]: {
            gridTemplateColumns: 'repeat(auto-fit, minmax(300px, max-content))'
        }
    },
    dropDownBox: {
        border: `1px solid ${theme.palette.grey["Gray80"]}`,
        borderRadius: "50% !important",
        width: "32px !important",
        height: "32px !important",
        minWidth: "32px !important",
    },
    menuItem: {
        marginBottom: "10px",
        alignItems: "center !important",
    },
}));
type Props = {
    flag: string
}
const PatientImageXrayList = (props: Props) => {
    const { flag } = props
    const classes = useStyles();
    const location = useLocation();
    const {
        wellnessProfileFilters: {
            wellnessProfileData,
            wellnessProfileStep
        },
        patientWellnessProfileWithoutAccount: { withoutAccountGetWellnessProfileData },
    } = useSelector((state: RootState) => state);

    const withoutAccountWellnessProfile: boolean = useMemo(() => {
        return location?.pathname?.includes('/patient/without-account/wellness-profile/')
    }, [location.pathname]);

    const wellnessData = withoutAccountWellnessProfile ? withoutAccountGetWellnessProfileData : wellnessProfileData;
    const handleClick = (data?: any) => {
        setSelectImageXray(data)
        setOpenCommentsPopup(true)
    };
    const [openImageXrayPopup, setOpenImageXrayPopup] = useState(false);

    const [openCommentsPopup, setOpenCommentsPopup] = useState(false);
    const [selectImageXray, setSelectImageXray] = useState(null) as any
    const handleImageXrayPopUp = (res: any) => {
        setOpenImageXrayPopup(true)
        setSelectImageXray(res)
    }
    return (
        <React.Fragment>
            {wellnessData?.[flag]?.length > 0 ?
                <Box
                    margin={'0 auto'}
                    width={'100%'}
                    gap={'25px'}
                    className={classes.imageXrayGridParentBox}
                    sx={{
                        display: 'grid', paddingInline: '20px',
                        justifyContent: {
                            xl: wellnessData?.[flag]?.length < 4 ? 'flex-start' : "center",
                            lg: wellnessData?.[flag]?.length < 4 ? 'flex-start' : "center",
                            md: wellnessData?.[flag]?.length < 2 ? 'flex-start' : "center",
                            sm: wellnessData?.[flag]?.length < 2 ? 'flex-start' : "center",
                            xs: "center"
                        },
                        [theme.breakpoints.between(1000, 1280)]: {
                            justifyContent: wellnessData?.[flag]?.length < 3 ? 'flex-start' : "center"
                        },
                        gridRowGap: '0px !important'
                    }}
                >
                    {wellnessData?.[flag]?.map((res: any, index: number) =>
                        <Box key={index}
                            className={classes.imageXrayGrid}
                            sx={{ display: 'inline-block', boxSizing: 'border-box', m: 1 }}
                        >

                            <Card key={index}>
                                <CardActionArea disableRipple className={classes.actionArea}>
                                    <CardMedia
                                        onClick={() => handleImageXrayPopUp(res)}
                                        className={classes.imageBox}
                                        component="img"
                                        image={res?.image_path}
                                        alt="tooth"
                                        sx={{
                                            borderRadius: "8px",
                                            maxWidth: "440px !important",
                                            objectFit: "cover !important ",
                                            width: "100% !important",
                                            height: {
                                                xl: '220px',
                                                lg: '200px',
                                                md: '190px',
                                                sm: '210px',
                                                xs: '220px',
                                            },
                                        }}
                                    />
                                    <CardContent className="d-flex justify-content-between"
                                        classes={{ root: classes.noHover }}>
                                        <Box className="d-flex flex-col align-start">
                                            <Typography className="fw-medium f-16 f-w-500 lh-16 labelColor">
                                                {res?.image_name}
                                            </Typography>
                                        </Box>

                                        <FontAwesomeIcon
                                            onClick={() => handleClick(res)}
                                            icon={faComment}
                                            className="f-w-900 f-16 lh-16 labelColor"
                                        />

                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Box>)}
                </Box>
                : <Box><NoDataFound errorMessage={flag} /></Box>}
            {openCommentsPopup && <AddCommentsPopup disabledComment={true} isPatient open={openCommentsPopup} setOpen={setOpenCommentsPopup}
                isPhoto={wellnessProfileStep === 'photo'}
                selectImageXray={selectImageXray} setSelectImageXray={setSelectImageXray}
            />}
            <ImageXrayPupUp open={openImageXrayPopup} setOpen={setOpenImageXrayPopup}
                setSelectImageXray={setSelectImageXray}
                selectImageXray={selectImageXray} isPatient={true} />
        </React.Fragment>
    )
}

export default PatientImageXrayList;