

export const muscleSubType = [
    { label: "Neither", value: "Neither" },
    { label: "Left", value: "Left" },
    { label: "Right", value: "Right" },
    { label: "Both", value: "Both" },
  ];
  export const masseterCacheData = [
    {
      title: "Sub Type",
      value: "",
      name: "sub_type",
      type: "select",
      option: muscleSubType,
    },
    {
      title: "Notes",
      value: "",
      name: "note",
      type: "string",
    },
  ];
  export const temporalisCacheData = [
    {
      title: "Sub Type",
      value: "",
      name: "sub_type",
      type: "select",
      option: muscleSubType,
    },
    {
      title: "Notes",
      value: "",
      name: "note",
      type: "string",
    },
  ];
  export const lateralPterygoidCacheData = [
    {
      title: "Sub Type",
      value: "",
      name: "sub_type",
      type: "select",
      option: muscleSubType,
    },
    {
      title: "Notes",
      value: "",
      name: "note",
      type: "string",
    },
  ];
  export const medialPterygoidCacheData = [
    {
      title: "Sub Type",
      value: "",
      name: "sub_type",
      type: "select",
      option: muscleSubType,
    },
    {
      title: "Notes",
      value: "",
      name: "note",
      type: "string",
    },
  ];
  export const otherCacheData = [
    {
      title: "Notes",
      value: "",
      name: "note",
      type: "string",
    },
  ];