export const GET_RISK_PROFILE_REQUEST = "GET_RISK_PROFILE_REQUEST";
export const GET_RISK_PROFILE_CACHE_DATA_CLEAR_REQUEST = "GET_RISK_PROFILE_CACHE_DATA_CLEAR_REQUEST";
export const GET_RISK_PROFILE_REQUEST_SUCCESS =
    "GET_RISK_PROFILE_REQUEST_SUCCESS";
export const GET_RISK_PROFILE_REQUEST_FAILURE =
    "GET_RISK_PROFILE_REQUEST_FAILURE";

export const POST_RISK_PROFILE_REQUEST = "POST_RISK_PROFILE_REQUEST";
export const UPDATE_RISK_PROFILE_REQUEST = "UPDATE_RISK_PROFILE_REQUEST";
export const UPDATE_RISK_PROFILE_CACHE_DATA_CLEAR_REQUEST = "UPDATE_RISK_PROFILE_CACHE_DATA_CLEAR_REQUEST";

export const PUT_RISK_PROFILE_REQUEST = "PUT_RISK_PROFILE_REQUEST";
export const PUT_RISK_PROFILE_REQUEST_SUCCESS =
    "PUT_RISK_PROFILE_REQUEST_SUCCESS";
export const PUT_RISK_PROFILE_REQUEST_FAILURE =
    "PUT_RISK_PROFILE_REQUEST_FAILURE";

export const GET_RISK_PROFILE_TREATMENT_REQUEST = "GET_RISK_PROFILE_TREATMENT_REQUEST";
export const GET_RISK_PROFILE_TREATMENT_CACHE_DATA_CLEAR_REQUEST = "GET_RISK_PROFILE_TREATMENT_CACHE_DATA_CLEAR_REQUEST";
export const GET_RISK_PROFILE_TREATMENT_REQUEST_SUCCESS =
    "GET_RISK_PROFILE_TREATMENT_REQUEST_SUCCESS";
export const GET_RISK_PROFILE_TREATMENT_REQUEST_FAILURE =
    "GET_RISK_PROFILE_TREATMENT_REQUEST_FAILURE";
