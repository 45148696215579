import { toothNumberForFindings } from './helper';

export const maximumComfortableOpeningData = [
  {
    title: 'Dimensions (mm)',
    value: null,
    name: 'dimension',
    type: 'string',
    placeholder: 'mm',
    dataType: 'number'
  }
];
export const maximumAssistedOpeningData = [
  {
    title: 'Dimensions (mm)',
    value: null,
    name: 'dimension',
    type: 'string',
    placeholder: 'mm',
    dataType: 'number'
  }
];
export const presentOfPainOnAssistedOpeningData = [
  {
    title: 'Present',
    value: false,
    name: 'radio',
    type: 'radio'
  },
  {
    title: 'Notes',
    value: '',
    name: 'note',
    type: 'string'
  }
];
export const rangeOfMotionRightLateral = [
  {
    title: 'Dimensions (mm)',
    value: null,
    name: 'dimension',
    type: 'string',
    placeholder: 'mm',
    dataType: 'number'
  }
];
export const rangeOfMotionLeftLateral = [
  {
    title: 'Dimensions (mm)',
    value: null,
    name: 'dimension',
    type: 'string',
    placeholder: 'mm',
    dataType: 'number'
  }
];
export const rangeOfMotionProtrusiveLateral = [
  {
    title: 'Dimensions (mm)',
    value: null,
    name: 'dimension',
    type: 'string',
    placeholder: 'mm',
    dataType: 'number'
  }
];
export const shimbashiNumber = [
  {
    title: 'Dimensions (mm) - measure from 21/31',
    value: null,
    name: 'dimension',
    type: 'string',
    placeholder: 'mm',
    dataType: 'number'
  }
];
export const fremitus = [
  {
    title: 'Tooth Number',
    value: [],
    name: 'tooth_number',
    type: 'multi-select',
    option: toothNumberForFindings
  },
  {
    title: 'Present',
    value: false,
    name: 'radio',
    type: 'radio'
  },
  {
    title: 'Notes',
    value: '',
    name: 'note',
    type: 'string'
  }
];
