import {
    GET_INSURANCE_APPROVAL_CHECKLIST_FAILURE,
    GET_INSURANCE_APPROVAL_CHECKLIST_REQUEST,
    GET_INSURANCE_APPROVAL_CHECKLIST_CACHE_DATA_CLEAR_REQUEST,
    GET_INSURANCE_APPROVAL_CHECKLIST_SUCCESS,
    GET_INSURANCE_APPROVAL_FAILURE,
    GET_INSURANCE_APPROVAL_REQUEST,
    GET_INSURANCE_APPROVAL_CACHE_DATA_CLEAR_REQUEST,
    GET_INSURANCE_APPROVAL_SUCCESS,
    PUT_INSURANCE_APPROVAL_CHECKLIST_FAILURE,
    PUT_INSURANCE_APPROVAL_CHECKLIST_REQUEST,
    PUT_INSURANCE_APPROVAL_CHECKLIST_SUCCESS,
    PUT_INSURANCE_APPROVAL_FAILURE,
    PUT_INSURANCE_APPROVAL_REQUEST,
    PUT_INSURANCE_APPROVAL_SUCCESS
} from "../../../../ActionTypes/v1/dentist/insuranceApproval";

import {
    GetInsuranceApprovalChecklistFailurePayload,
    GetInsuranceApprovalChecklistRequest,
    GetInsuranceApprovalChecklistRequestFailure,
    GetInsuranceApprovalChecklistRequestPayload,
    GetInsuranceApprovalChecklistRequestSuccess,
    GetInsuranceApprovalChecklistSuccessPayload,
    GetInsuranceApprovalFailurePayload,
    GetInsuranceApprovalRequest,
    GetInsuranceApprovalRequestFailure,
    GetInsuranceApprovalRequestPayload,
    GetInsuranceApprovalRequestSuccess,
    GetInsuranceApprovalSuccessPayload,
    PutInsuranceApprovalChecklistFailurePayload,
    PutInsuranceApprovalChecklistRequest, PutInsuranceApprovalChecklistRequestFailure,
    PutInsuranceApprovalChecklistRequestPayload, PutInsuranceApprovalChecklistRequestSuccess,
    PutInsuranceApprovalChecklistSuccessPayload,
    PutInsuranceApprovalFailurePayload,
    PutInsuranceApprovalRequest,
    PutInsuranceApprovalRequestFailure,
    PutInsuranceApprovalRequestPayload,
    PutInsuranceApprovalRequestSuccess,
    PutInsuranceApprovalSuccessPayload
} from "../../../../types/dentistTypes";


export const getInsuranceApprovalRequest = (
    payload: GetInsuranceApprovalRequestPayload
): GetInsuranceApprovalRequest => ({
    type: GET_INSURANCE_APPROVAL_REQUEST,
    payload
});

export const getInsuranceApprovalCacheDataClearRequest = ():any => ({
    type: GET_INSURANCE_APPROVAL_CACHE_DATA_CLEAR_REQUEST
})

export const getInsuranceApprovalRequestSuccess = (
    payload: GetInsuranceApprovalSuccessPayload
): GetInsuranceApprovalRequestSuccess => ({
    type: GET_INSURANCE_APPROVAL_SUCCESS,
    payload
});

export const getInsuranceApprovalRequestFailure = (
    payload: GetInsuranceApprovalFailurePayload
): GetInsuranceApprovalRequestFailure => ({
    type: GET_INSURANCE_APPROVAL_FAILURE,
    payload
});

export const putInsuranceApprovalRequest = (
    payload: PutInsuranceApprovalRequestPayload
): PutInsuranceApprovalRequest => ({
    type: PUT_INSURANCE_APPROVAL_REQUEST,
    payload,
});

export const putInsuranceApprovalRequestSuccess = (
    payload: PutInsuranceApprovalSuccessPayload
): PutInsuranceApprovalRequestSuccess => ({
    type: PUT_INSURANCE_APPROVAL_SUCCESS,
    payload,
});

export const putInsuranceApprovalRequestFailure = (
    payload: PutInsuranceApprovalFailurePayload
): PutInsuranceApprovalRequestFailure => ({
    type: PUT_INSURANCE_APPROVAL_FAILURE,
    payload,
});


export const getInsuranceApprovalChecklistRequest = (
    payload: GetInsuranceApprovalChecklistRequestPayload
): GetInsuranceApprovalChecklistRequest => ({
    type: GET_INSURANCE_APPROVAL_CHECKLIST_REQUEST,
    payload
});

export const getInsuranceApprovalChecklistCacheDataClearRequest = ():any => ({
    type: GET_INSURANCE_APPROVAL_CHECKLIST_CACHE_DATA_CLEAR_REQUEST
})
export const getInsuranceApprovalChecklistRequestSuccess = (
    payload: GetInsuranceApprovalChecklistSuccessPayload
): GetInsuranceApprovalChecklistRequestSuccess => ({
    type: GET_INSURANCE_APPROVAL_CHECKLIST_SUCCESS,
    payload
});

export const getInsuranceApprovalChecklistRequestFailure = (
    payload: GetInsuranceApprovalChecklistFailurePayload
): GetInsuranceApprovalChecklistRequestFailure => ({
    type: GET_INSURANCE_APPROVAL_CHECKLIST_FAILURE,
    payload
});


export const putInsuranceApprovalChecklistRequest = (
    payload: PutInsuranceApprovalChecklistRequestPayload
): PutInsuranceApprovalChecklistRequest => ({
    type: PUT_INSURANCE_APPROVAL_CHECKLIST_REQUEST,
    payload
});

export const putInsuranceApprovalChecklistRequestSuccess = (
    payload: PutInsuranceApprovalChecklistSuccessPayload
): PutInsuranceApprovalChecklistRequestSuccess => ({
    type: PUT_INSURANCE_APPROVAL_CHECKLIST_SUCCESS,
    payload
});

export const putInsuranceApprovalChecklistRequestFailure = (
    payload: PutInsuranceApprovalChecklistFailurePayload
): PutInsuranceApprovalChecklistRequestFailure => ({
    type: PUT_INSURANCE_APPROVAL_CHECKLIST_FAILURE,
    payload
});

