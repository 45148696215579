import {Box} from '@mui/material';
import {makeStyles} from '@mui/styles';
import React, {useMemo} from 'react';
import {getLingualSvg} from '../Lingual';
import useOdontogramFinding from "../useOdontogramFinding";

const useStyles = makeStyles(() => ({
    flippedTeethWrapper: {
        display: 'flex',
        height: '33px !important'
    },
}));

type Prop = {
    tooth: number,
    isBottomTeeth?: boolean
}

const FlippedTeeth = (props: Prop) => {
    const {tooth, isBottomTeeth} = props
    const classes = useStyles();

    const toothNumber = isBottomTeeth ? tooth + 20 : tooth;

    const {isCrown} = useOdontogramFinding(toothNumber, isBottomTeeth);
    const {isImplant} = useOdontogramFinding(toothNumber, isBottomTeeth);
    const {isSct} = useOdontogramFinding(toothNumber, isBottomTeeth);

    const crownAllCeramicProvisionalSubType = useMemo(() => {
        return isCrown?.crownAllCeramicProvisionalSubType === 'All Ceramic' || isCrown?.crownAllCeramicProvisionalSubType === 'Provisional';
    }, [isCrown]);

    const crownFullMetalSubType = useMemo(() => {
        return isCrown?.crownAllCeramicProvisionalSubType === 'Full Metal';
    }, [isCrown]);

    const crownMetalCeramicSubType = useMemo(() => {
        return isCrown?.crownAllCeramicProvisionalSubType === 'Metal Ceramic';
    }, [isCrown]);

    const lingualStroke = useMemo(() => {
        if (isImplant?.hasTooth && isImplant?.implantSubType) {
            return isImplant?.color;
        } else if (isCrown?.hasTooth && (crownAllCeramicProvisionalSubType || crownMetalCeramicSubType)) {
            return isCrown?.color;
        } else {
            return null;
        }
    }, [isImplant, isCrown, crownAllCeramicProvisionalSubType, crownMetalCeramicSubType]);

    const lingualFill = useMemo(() => {
        if (isCrown?.hasTooth && crownFullMetalSubType) {
            return isCrown?.color;
        } else {
            return null;
        }
    }, [isCrown, crownFullMetalSubType]);

    const getColor = useMemo(() => {
        if (isImplant?.hasTooth) {
            return isImplant?.color;
        } else if (isCrown?.hasTooth && crownMetalCeramicSubType) {
            return isCrown?.color;
        } else if (isSct?.hasTooth) {
            return isSct?.color
        } else {
            return null;
        }
    }, [isImplant, isCrown, crownMetalCeramicSubType, isSct]);

    const getSubType = useMemo(() => {
        if (isImplant?.hasTooth) {
            return isImplant?.implantSubType ? "Fixture and Crown" : 'Fixture';
        } else if (isCrown?.hasTooth && crownMetalCeramicSubType) {
            return isCrown?.crownAllCeramicProvisionalSubType;
        }
    }, [isImplant, isCrown, crownMetalCeramicSubType]);

    const getFinding = useMemo(() => {
        if (isImplant?.hasTooth) {
            return 'implant';
        } else if (isCrown?.hasTooth) {
            return "crown";
        } else if (isSct?.hasTooth) {
            return "sct"
        } else {
            return null;
        }
    }, [isImplant, isCrown, isSct]);

    const getLingual = useMemo(() => {
        let toothNumber = isBottomTeeth ? (tooth + 20) : tooth as any;
        return getLingualSvg[`Lingual${toothNumber}`](getFinding, getColor, lingualFill, lingualStroke, getSubType);
    }, [tooth, isBottomTeeth, getFinding, getColor, lingualFill, lingualStroke, getSubType]);

    return (
        <Box className={classes.flippedTeethWrapper} sx={{
            transform: isBottomTeeth ? 'rotate(-180deg)' : '',
            alignItems: isBottomTeeth ? 'flex-start' : 'flex-end'
        }}>
            <Box className='d-flex'>
                {getLingual}
            </Box>
        </Box>
    );
};

export default FlippedTeeth;
