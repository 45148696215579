import {
  GET_READINESS_BAR_REQUEST,
  GET_READINESS_BAR_REQUEST_FAILURE,
  GET_READINESS_BAR_REQUEST_SUCCESS,
  PUT_READINESS_BAR_REQUEST,
  PUT_READINESS_BAR_REQUEST_FAILURE,
  PUT_READINESS_BAR_REQUEST_SUCCESS,
  CHANGE_READINESS_BAR_REQUEST
} from '../../../../../ActionTypes/v1/dentist/readinessBar';
import {
  PutReadinessBarRequest,
  PutReadinessBarRequestSuccess,
  PutReadinessBarRequestFailure,
  GetReadinessBarRequest,
  GetReadinessBarRequestSuccess,
  GetReadinessBarRequestFailure,
  PutReadinessBarRequestPayload,
  PutReadinessBarSuccessPayload,
  PutReadinessBarFailurePayload,
  GetReadinessBarRequestPayload,
  GetReadinessBarSuccessPayload,
  GetReadinessBarFailurePayload,
  changeReadinessBarPayload,
  changeReadinessBarRequest
} from '../../../../../types/dentistTypes';

export const changeReadinessBar = (payload: changeReadinessBarPayload): changeReadinessBarRequest => ({
  type: CHANGE_READINESS_BAR_REQUEST,
  payload
});

export const putReadinessBarRequest = (payload: PutReadinessBarRequestPayload): PutReadinessBarRequest => ({
  type: PUT_READINESS_BAR_REQUEST,
  payload
});

export const putReadinessBarRequestSuccess = (payload: PutReadinessBarSuccessPayload): PutReadinessBarRequestSuccess => ({
  type: PUT_READINESS_BAR_REQUEST_SUCCESS,
  payload
});
export const putReadinessBarRequestFailure = (payload: PutReadinessBarFailurePayload): PutReadinessBarRequestFailure => ({
  type: PUT_READINESS_BAR_REQUEST_FAILURE,
  payload
});

export const getReadinessBarRequest = (payload: GetReadinessBarRequestPayload): GetReadinessBarRequest => ({
  type: GET_READINESS_BAR_REQUEST,
  payload
});

export const getReadinessBarRequestSuccess = (payload: GetReadinessBarSuccessPayload): GetReadinessBarRequestSuccess => ({
  type: GET_READINESS_BAR_REQUEST_SUCCESS,
  payload
});
export const getReadinessBarRequestFailure = (payload: GetReadinessBarFailurePayload): GetReadinessBarRequestFailure => ({
  type: GET_READINESS_BAR_REQUEST_FAILURE,
  payload
});
