import {
    GET_HEALTH_HISTORY_WITHOUT_AC_FAIL,
    GET_HEALTH_HISTORY_WITHOUT_AC_REQUEST,
    GET_HEALTH_HISTORY_WITHOUT_AC_SUCCESS,
    PUT_HEALTH_HISTORY_WITHOUT_AC_FAIL,
    PUT_HEALTH_HISTORY_WITHOUT_AC_REQUEST,
    PUT_HEALTH_HISTORY_WITHOUT_AC_SUCCESS,
    REGISTER_POP_UP_REQUEST
} from "../../../ActionTypes/v2/patient/healthHistoryWithoutAccount";
import {
    GetHealthHistoryWithoutAcFail,
    GetHealthHistoryWithoutAcFailPayload,
    GetHealthHistoryWithoutAcRequest,
    GetHealthHistoryWithoutAcRequestPayload,
    GetHealthHistoryWithoutAcSuccess,
    GetHealthHistoryWithoutAcSuccessPayload,
    PutHealthHistoryWithoutAcFail,
    PutHealthHistoryWithoutAcFailPayload,
    PutHealthHistoryWithoutAcRequest,
    PutHealthHistoryWithoutAcRequestPayload,
    PutHealthHistoryWithoutAcSuccess,
    PutHealthHistoryWithoutAcSuccessPayload,
    RegisterPopUpRequest,
} from "../../../types/v2/patient/healthHistoryWithoutAccountActionTypes";

export const getHealthHistoryWithoutAcRequest = (payload: GetHealthHistoryWithoutAcRequestPayload): GetHealthHistoryWithoutAcRequest => ({
    type: GET_HEALTH_HISTORY_WITHOUT_AC_REQUEST,
    payload
});
export const getHealthHistoryWithoutAcSuccess = (payload: GetHealthHistoryWithoutAcSuccessPayload): GetHealthHistoryWithoutAcSuccess => ({
    type: GET_HEALTH_HISTORY_WITHOUT_AC_SUCCESS,
    payload
});

export const getHealthHistoryWithoutAcFailure = (payload: GetHealthHistoryWithoutAcFailPayload): GetHealthHistoryWithoutAcFail => ({
    type: GET_HEALTH_HISTORY_WITHOUT_AC_FAIL,
    payload
});

export const putHealthHistoryWithoutAcRequest = (payload: PutHealthHistoryWithoutAcRequestPayload): PutHealthHistoryWithoutAcRequest => ({
    type: PUT_HEALTH_HISTORY_WITHOUT_AC_REQUEST,
    payload
});
export const putHealthHistoryWithoutAcSuccess = (payload: PutHealthHistoryWithoutAcSuccessPayload): PutHealthHistoryWithoutAcSuccess => ({
    type: PUT_HEALTH_HISTORY_WITHOUT_AC_SUCCESS,
    payload
});

export const putHealthHistoryWithoutAcFailure = (payload: PutHealthHistoryWithoutAcFailPayload): PutHealthHistoryWithoutAcFail => ({
    type: PUT_HEALTH_HISTORY_WITHOUT_AC_FAIL,
    payload
});

export const registerPopUpRequestAction = (payload: boolean): RegisterPopUpRequest => ({
    type: REGISTER_POP_UP_REQUEST,
    payload
});