import React, { useMemo } from 'react'
import { useLocation } from 'react-router-dom';
import { Box, Tooltip, Typography, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfo, faNoteSticky } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from "react-redux";
import Divider from "@mui/material/Divider";
import WellnessProfileTreatmentButtons from "./WellnessProfileTreatmentButtons";
import { treatmentDescription } from '../../../../../utils/helper';
import theme from '../../../../../theme';
import CardComponent from '../../../common/CardComponent';
import ALIcon from '../../../../../assets/images/v2/alternativeIcon.svg'
import { RootState } from "../../../../../app/reducers/v2/rootReducer";
import CollapsibleBox from "../../../common/CollapsibleBox";
import NoDataFound from "../../../../v1/Common/NoDataFound";
import TextBox from "../../../common/TextBox";

const useStyles = makeStyles((theme: any) => ({
    noteClass: {
        marginLeft: '8px !important',
        width: '15px',
        height: '15px'
    },
    treatmentContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        [theme.breakpoints.down(790)]: {
            flexDirection: 'column',
            justifyContent: 'start',
            alignItems: 'start',
        }
    },
    btnContainer: {
        [theme.breakpoints.down(790)]: {
            paddingTop: '10px',
            width: '100%'
        },
        [theme.breakpoints.down(475)]: {
            width: '100%'
        }
    }
}));

const PatientWellnessPlannedTreatments = () => {
    const classes = useStyles();
    const { palette } = theme
    const location = useLocation();
    const {
        wellnessProfileFilters: { wellnessProfileData },
        patientWellnessProfileWithoutAccount: { withoutAccountGetWellnessProfileData },
    } = useSelector((state: RootState) => state);

    const withoutAccountWellnessProfile: boolean = useMemo(() => {
        return location?.pathname?.includes('/patient/without-account/wellness-profile/')
    }, [location.pathname]);

    const wellnessData = withoutAccountWellnessProfile ? withoutAccountGetWellnessProfileData : wellnessProfileData;
    const isSmallScreen = useMediaQuery(theme.breakpoints.down(475));

    return (
        <CardComponent bgColor={theme.palette.secondary.lightShade} color={theme.palette.secondary.main}
            title='Planned Treatments'>
            {wellnessData?.treatments?.length ? wellnessData?.treatments?.map((res: any, mainIndex: number) =>
                <CollapsibleBox title={`Appointment ${mainIndex + 1}`} key={mainIndex} id={`${mainIndex}`} isPatient>
                    {res?.treatments?.map((row: any, index: number) =>
                        <React.Fragment>
                            <Box key={index}>
                                <Box className={classes.treatmentContainer} py={1.5}>
                                    <Box display="flex" mr={1} justifyContent="space-between" alignItems="start" flexDirection="column" >
                                        {row?.treatment_finding ?
                                            <Typography className='f-14 fw-medium f-w-500 labelColor'
                                                sx={{ textTransform: 'capitalize' }}>
                                                {treatmentDescription(row?.treatment_finding, true,true)}
                                            </Typography>
                                            :
                                            null}
                                        <Box display={'flex'} >
                                            {row?.treatment_type === 'other' ?
                                                <Typography
                                                    className={`f-14 fw-regular f-w-400 labelColor`}>
                                                    {row?.procedure_text}
                                                </Typography>
                                                :
                                                row?.treatment_type === 'additional' ?
                                                    (<Box className='d-flex'>
                                                        <Typography
                                                            className={`${row?.treatment_finding ? 'f-12 ' : 'f-14'} fw-regular f-w-400 labelColor`}>
                                                            {row?.treatment_procedure[0]?.description}
                                                            {row?.treatment_procedure[0]?.notes &&
                                                                <Tooltip
                                                                    title={row?.treatment_procedure[0]?.notes}
                                                                    arrow
                                                                    classes={{ tooltip: 'fw-regular f-14 lh-18' }}>
                                                                    <FontAwesomeIcon icon={faNoteSticky}
                                                                        color={palette.v2.primary.main}
                                                                        className={`${classes.noteClass} cursor-pointer f-14`}
                                                                    />
                                                                </Tooltip>
                                                            }
                                                        </Typography>
                                                    </Box>)
                                                    : row?.treatment_procedure?.map((res: any, i: number) => (
                                                        <Box className='d-flex' key={i}>
                                                            <Typography key={i}
                                                                className={`${row?.treatment_finding ? 'f-12 ' : 'f-14'} fw-regular f-w-400 labelColor`}>
                                                                {res?.quantity === null && res?.description}
                                                            </Typography>
                                                        </Box>
                                                    ))
                                            }
                                        </Box>
                                    </Box>
                                    <Box className={`${classes.btnContainer}`}>
                                        <WellnessProfileTreatmentButtons
                                            isAlternative={false}
                                            resData={row}
                                            treatmentData={res}
                                        />
                                    </Box>
                                </Box>

                            </Box>

                            {(row?.alternative_procedure || row?.alternative_treatment_type === 'other') && <Box>
                                <Box />
                                <Box className={classes.treatmentContainer} py={1.5}>
                                    <Box className='d-flex'>
                                        <img src={ALIcon} alt='alternative' />
                                        <Box ml={3} mt={'2px'}>
                                            {row?.alternative_finding ?
                                                <Typography className='f-14 fw-medium f-w-500 labelColor'
                                                    sx={{ textTransform: 'capitalize' }}>
                                                    {treatmentDescription(row?.alternative_finding, true,true)}
                                                </Typography>
                                                : null}
                                            {row?.alternative_treatment_type === 'other' ?
                                                <Typography
                                                    className={`f-14 fw-regular f-w-400  labelColor`}>
                                                    {row?.alternative_procedure_text}
                                                </Typography>
                                                : <Typography
                                                    className={`${row?.alternative_finding ? 'f-12' : 'f-14 '} fw-regular f-w-400  labelColor`}>
                                                    {row?.alternative_procedure?.description}
                                                </Typography>}
                                            <Box className='d-flex'>
                                                <FontAwesomeIcon icon={faInfo} color={palette.error.main}
                                                    className='f-14 fw-regular f-w-400' />
                                                <Typography
                                                    ml={1}
                                                    className='f-10 fw-regular f-w-400'
                                                    color='error'>
                                                    Patient declines optimal treatment recommendation and
                                                    opts for alternative</Typography>
                                            </Box>
                                        </Box>
                                    </Box>


                                    <Box className={`${classes.btnContainer}`}>
                                        <WellnessProfileTreatmentButtons
                                            isAlternative={true}
                                            resData={row}
                                            treatmentData={res} />
                                    </Box>
                                </Box>
                            </Box>}
                            {isSmallScreen ?
                                <Divider sx={{
                                    mt: 3,
                                    mb: 2,
                                    border: '1px solid',
                                    borderColor: theme.palette.secondary.main
                                }} /> :
                                <Divider />}
                        </React.Fragment>
                    )}
                    <Box className='d-flex' mt={2}
                        sx={{ flexWrap: 'wrap' }}>
                        <TextBox
                            label={'Appointment Notes'}
                            labelProps={{
                                htmlFor: 'notes'
                            }}
                            isPadding={true}
                            inputBackColor={theme?.palette?.common?.white}
                            disabled
                            inputProps={{
                                id: 'notes',
                                name: 'notes',
                                value: res?.notes || '',
                                fullWidth: true,
                            }}
                        />
                    </Box>
                </CollapsibleBox>
            ) : <NoDataFound errorMessage={"Treatments"}
            />}
        </CardComponent>
    )
}

export default PatientWellnessPlannedTreatments