import {
    UPDATE_WEAR_DENTIST,UPDATE_WEAR_DENTIST_FAILURE,
    CHANGE_WEAR_DENTIST
} from "../../../../ActionTypes/v1/dentist/clinicalExamDentist";
import { wearDentistInterface } from "../../../../types/dentistTypes";

export const updateWearRequest = (
    payload: wearDentistInterface
): wearDentistInterface => ({
    type: UPDATE_WEAR_DENTIST,
    payload,
}) as any;

export const updateWearRequestFailure = (): any => ({
    type: UPDATE_WEAR_DENTIST_FAILURE,
}) as any;  


export const changeWearFindingAction = (payload: any): any => ({
    type: CHANGE_WEAR_DENTIST,
    payload
}) as any;