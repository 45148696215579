import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Button, Typography} from "@mui/material";
import {Box} from "@mui/system";
import PopupComponent from "../PopupComponent";
import theme from "../../../../theme";
import {
    exceptThisSymbols,
    formatPhoneNumber, getLocalStorageItem,
    handleLogout,
    handlePatientLogout,
    patientProfileData
} from "../../../../utils/helper";
import TextBox from "../TextBox";
import {updateOtpRequest, updateOtpValidationRequest} from "../../../../app/actions/v2/common/mobileVerificationAction";
import {otpValidator} from "../../../../validation";
import {sendOtpRequest, verifyOtpRequest} from "../../../../app/actions/v1/otpActions/otpAction";
import FullScreenLoader from "../../../v1/Common/FullScreenLoader";

type Props = {
    open: boolean
    setOpen: any
    isPatient?: boolean | undefined
}
const MobileVerificationPopUp = (props: Props) => {
    const {open, setOpen, isPatient} = props;
    const dispatch = useDispatch()
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);
    const {otp, otpError} = useSelector((state: any) => state?.mobileVerification)
    const {pending} = useSelector((state: any) => state?.otpReducer)
   const socialLogin = JSON.parse(
            getLocalStorageItem("social_login") as string
        );
    useEffect(() => {
        if (patientProfileData?.mobile_number) {
            resendOtp();
        }
    }, [patientProfileData?.mobile_number]);

    useEffect(() => {
        let otpInterval: any;

        if (minutes > 0 || seconds > 0) {
            otpInterval = setInterval(() => {
                if (seconds === 0) {
                    if (minutes > 0) {
                        setMinutes((prevMinutes) => prevMinutes - 1);
                        setSeconds(59);
                    }
                } else {
                    setSeconds((prevSeconds) => prevSeconds - 1);
                }
            }, 1000);
        }

        return () => {
            clearInterval(otpInterval);
        };
    }, [minutes, seconds]);

    const handleChange = (event: any) => {
        const {value} = event.target
        dispatch(updateOtpRequest(value))
        if (otpError?.['otp']) {
            dispatch(
                updateOtpValidationRequest({
                    ...otpError,
                    ['otp']: '',
                })
            )
        }
    }

    const resendOtp = () => {
        dispatch(
            sendOtpRequest({
                profile_id: patientProfileData?.id,
                mobile: patientProfileData?.mobile_number,
                callback: () => {
                    setMinutes(2)
                    setSeconds(0)
                },
            })
        );
    };

    const submitHandler = (e: any) => {
        e.preventDefault();
        let {errors, isValid} = otpValidator({otp: otp});
        if (isValid) {
            dispatch(
                verifyOtpRequest({
                    mobile: patientProfileData?.mobile_number,
                    otp: otp,
                     social_login: socialLogin === null ? false : socialLogin,
                    callback: (data: any) => {
                        const opt = null as any
                        dispatch(updateOtpRequest(opt))
                        if (isPatient) {
                            localStorage.setItem("patientUserData", JSON.stringify(data?.data?.detail));
                        } else {
                            localStorage.setItem("dentistUserData", JSON.stringify(data?.data?.detail));
                        }
                        dispatch(
                            updateOtpValidationRequest({
                                ...otpError,
                                ['otp']: '',
                            })
                        )
                        setOpen(false)
                        window.location.reload();
                    },
                })
            );
        } else {
            const errorPayload = errors as any
            dispatch(updateOtpValidationRequest(errorPayload))
        }
    };

    return (
        <React.Fragment>
            {pending && <FullScreenLoader/>}
            <PopupComponent
                heading={"Mobile Verification"}
                width={'530px'}
                open={open}
                saveBtnText={'Verify'}
                handleClose={isPatient ? handlePatientLogout : handleLogout}
                cancelBtnText={'Logout'}
                handleSave={submitHandler}
                isPatient={isPatient} notDisplayCancelIcon
                footerAction headerAction isOraqIcon
                isLogoutOncancel

            >
                <Box mt={1}>
                    <Typography className={'fw-regular f-14'} color={theme.palette.common.black50}>OTP send on your
                        registered mobile number, Please verify it.</Typography>
                    <Box my={2} display={"flex"} justifyContent={"center"}>
                        {patientProfileData?.mobile_number &&
                            <Typography className={'fw-regular f-14'} color={theme.palette.common.black50}>
                                +1 {formatPhoneNumber(patientProfileData?.mobile_number)}
                            </Typography>}
                    </Box>
                    <TextBox
                        isPatient={isPatient}
                        label={'OTP'}
                        required
                        isPadding
                        inputProps={{
                            name: "otp",
                            maxLength: 6,
                            onWheel: (e: any) => {
                                e.target.blur()
                            },
                            onKeyDown: (e: any) => exceptThisSymbols.includes(e.key) && e.preventDefault(),
                            onChange: (e: any) => {
                                handleChange(e)
                            },
                            value: otp,
                            error: otpError?.otp,
                            helperText: otpError?.otp,
                            fullWidth: true,
                        }}
                    />
                    <Box
                        display={"flex"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                    >
                        <Typography className={'fw-regular'}
                                    color={isPatient ? theme.palette.secondary.main : theme.palette.v2.primary.main}>
                            {(minutes || seconds) <= 0 ? "" : `${minutes <= 9 ? `0${minutes}` : minutes}:${seconds <= 9 ? `0${seconds}` : seconds}`}
                        </Typography>

                        <Button
                            variant={"text"}
                            sx={{
                                color: `${isPatient ? theme.palette.secondary.main : theme.palette.v2.primary.main}`,
                                opacity: (minutes || seconds) > 0 ? 0.5 : 1,
                                "&:hover": {backgroundColor: 'transparent'}
                            }}
                            className={'fw-regular'}
                            onClick={() => resendOtp()}
                            disabled={(minutes || seconds) > 0}
                        >
                            Resend OTP
                        </Button>
                    </Box>

                </Box>
            </PopupComponent>
        </React.Fragment>
    )
}

export default MobileVerificationPopUp;