import {put, takeEvery, all, call, select} from "redux-saga/effects";
import API from "../../../../utils/api";
import {arrayToString, dateFormat, errorToaster, removeRefFromJson, successToaster} from '../../../../utils/helper';
import {checkSleep, passTrueFalse} from '../../../../utils/v2/preIntakeConstant/preIntakeConstant';
import {
    getClinicDetailsRequestFailureAction,
    getClinicDetailsRequestSuccessAction,
    saveAnonymousPreIntakeRequestFailureAction,
    saveAnonymousPreIntakeRequestSuccessAction,
    verifyAnonymousPatientEmailRequestFailureAction,
    verifyAnonymousPatientEmailRequestSuccessAction
} from '../../../actions/v2/anonymousPreIntake/anonymousPreIntakeAction';
import {updatePatientInfoAction} from '../../../actions/v2/dentist/preIntakeActions';
import {
    GET_CLINIC_DETAILS_REQUEST,
    SAVE_ANONYMOUS_PRE_INTAKE_REQUEST,
    VERIFY_ANONYMOUS_PATIENT_EMAIL_REQUEST
} from '../../../ActionTypes/v2/anonymousPreIntake/anonymousPreIntake';
import {RootState} from '../../../reducers/v2/rootReducer';

function* getClinicRequestSaga(payload: any): any {
    try {
        const response = yield API.get(`/v2/clinic/${payload.payload?.clinic_id}/details`)
        yield put(
            getClinicDetailsRequestSuccessAction(response?.data?.detail)
        );
    } catch (e: any) {
        errorToaster(e?.message)
        yield put(
            getClinicDetailsRequestFailureAction(e?.message)
        );
    }
}

function* saveAnonymousPreIntakeRequestSaga(payload: any): any {
    const payloadData = payload?.payload?.anonymousPreIntakeData
    const {
        patientInformation,
        emergencyContactInfo,
        letsGetToKnowYouBetter,
        dentistInsuranceInfo,
        generalHealth,
        dentalInfo,
        sleepQuality,
        medicalHealthHistory,
        communicationPreferences,
    } = payloadData;
    const patientDetails = {
        email: patientInformation?.emailAddress,
        first_name: patientInformation?.firstName,
        middle_name: patientInformation?.middleName,
        last_name: patientInformation?.lastName,
        mobile_phone_number: patientInformation?.mobilePhoneNumber,
        address: patientInformation?.address,
        postal_code: patientInformation?.postalCode,
        date_of_birth: patientInformation?.dateOfBirth,
        home_phone_number: patientInformation?.homePhoneNumber,
        country: patientInformation?.country,
        state: patientInformation?.state,
        city: patientInformation?.city,
        gender: patientInformation?.gender === '' ? null : patientInformation?.gender,
        clinic_id: payload?.payload?.clinicID
    }
    const preIntakeData = {
        device: payload?.payload?.device,
        emergency_contact: emergencyContactInfo?.emergencyContactPerson,
        emergency_contact_phone_number:
        emergencyContactInfo?.emergencyContactNumber,
        relationship_to_emergency_contact:
            "Other" === emergencyContactInfo.relationshipToEmergencyContactPerson
                ? emergencyContactInfo.other
                : emergencyContactInfo.relationshipToEmergencyContactPerson,

        //Let’s get to know you better
        occupation: letsGetToKnowYouBetter?.occupation,
        does_your_work_involve_night_shift: passTrueFalse(
            letsGetToKnowYouBetter?.isNightShiftInvolvedWithYourOccupation
        ),
        referred_by: letsGetToKnowYouBetter?.referredBy,
        marital_status: letsGetToKnowYouBetter?.maritalStatus,
        dental_insurance: passTrueFalse(
            letsGetToKnowYouBetter?.doYouHaveDentalInsurance
        ),

        //General Health
        history_of_smoking: generalHealth?.historyOfSmoking,
        alcohol_usage_in_week: generalHealth?.alcoholAverageWeek,
        recreational_drug_use: passTrueFalse(generalHealth?.drugUse),
        recreational_drug_use_other: generalHealth?.drugInputUse,
        height:
            (generalHealth?.heightUnit === "ft" && generalHealth?.heightFT
                ?
                `${generalHealth?.heightFT}.${generalHealth?.heightIN ? generalHealth?.heightIN : 0}`
                : generalHealth?.heightCM || ''),
        height_unit: (generalHealth?.heightFT || generalHealth?.heightCM) ? generalHealth?.heightUnit : '',
        weight: generalHealth?.weight,
        weight_unit: generalHealth?.weight ? generalHealth?.weightUnit : '',

        //Dental Information
        dental_anxiety: dentalInfo?.dentalAnxiety || 0,
        reason_for_scheduling_this_visit:
            dentalInfo?.reasonForSchedulingThisVisit || '',
        previous_dental_office_name: dentalInfo?.nameOfPreviousOffice,
        last_see_dental_hygienist: dateFormat(
            dentalInfo?.lastVisitAtDentistOffice
        ),
        date_of_last_dental_x_ray: dateFormat(
            dentalInfo?.lastTimeYouHadDentalXrayTaken
        ),
        antibiotic_premedication_require_prior_dental_appointments: passTrueFalse(
            dentalInfo?.doesYourPhysicianRecommendAntibiotic
        ),
        antibiotic_premedication: dentalInfo?.antibioticPremeditation,
        dental_health_average: dentalInfo?.feelDentalHealth,
        dental_info: arrayToString(dentalInfo?.checkIfYouHaveEverHadFollowing),
        appearance_smile_scale_to_10: dentalInfo?.rateAppearanceOfYourSmile,
        wish_correct_appearance_smile: passTrueFalse(
            dentalInfo?.correctAppearanceOfYourSmile
        ),
        wish_whiter_smile: passTrueFalse(dentalInfo?.hadWhiterSmile),
        snack_3x_day: passTrueFalse(dentalInfo?.doYouSnackMoreThatDay),
        brand_toothpaste: dentalInfo?.toothpasteBrand,
        tooth_extracted_last_3_year: passTrueFalse(
            dentalInfo?.toothExtractedInLastThreeYear
        ),
        filling_had_in_last_3_year: dentalInfo?.fillingsInLastThreeYear,
        family_history_of_cavities_toothloss: passTrueFalse(
            dentalInfo?.familyHistoryOfCavities
        ),
        homecare_protective_factors: arrayToString(
            dentalInfo?.homeCareProtectiveFactors
        ),

        //Sleep Quality

        diagnosed_sleep_apnea_ever: passTrueFalse(
            sleepQuality?.diagnosedSleepApnea
        ),
        neck_circumference: sleepQuality?.neckCircumferenceMeasured,
        loud_snore: sleepQuality?.snoreLoudly,
        fatigue_daily_bases: passTrueFalse(sleepQuality?.feelDuringDay),
        breathing_stop_sleep: passTrueFalse(sleepQuality?.gaspDuringSleep),
        difficulty_breathing_through_your_nose: passTrueFalse(
            sleepQuality?.difficultyBreathingNose
        ),
        cpap_machine: passTrueFalse(sleepQuality?.useCPAPMachine),
        oral_appliance_sleep: passTrueFalse(sleepQuality?.useOralAppliance),
        sitting_reading_epworth_scale: checkSleep(
            sleepQuality?.additionalSleep?.map((res: any) => res)[0]
        ),
        watching_tv_epworth_scale: checkSleep(
            sleepQuality?.additionalSleep?.map((res: any) => res)[1]
        ),
        sitting_inactive_epworth_scale: checkSleep(
            sleepQuality?.additionalSleep?.map((res: any) => res)[2]
        ),
        being_passenger_car_epworth_scale: checkSleep(
            sleepQuality?.additionalSleep?.map((res: any) => res)[3]
        ),
        lying_down_afternoon_epworth_scale: checkSleep(
            sleepQuality?.additionalSleep?.map((res: any) => res)[4]
        ),
        sitting_talking_someone_epworth_scale: checkSleep(
            sleepQuality?.additionalSleep?.map((res: any) => res)[5]
        ),
        sitting_quietly_lunch_epworth_scale: checkSleep(
            sleepQuality?.additionalSleep?.map((res: any) => res)[6]
        ),
        stopping_fewminutes_driving_epworth_scale: checkSleep(
            sleepQuality?.additionalSleep?.map((res: any) => res)[7]
        ),

        // Medical Health History
        physicians_name: medicalHealthHistory?.physicianName,
        physician_phone_number: medicalHealthHistory?.physicianContactNumber,
        last_visit_to_physician: dateFormat(
            medicalHealthHistory?.dateOfLastVisit
        ),
        serious_illness_or_operation_existance: passTrueFalse(
            medicalHealthHistory?.seriousIllnessesOrOperations
        ),
        serious_illness_or_operation_name:
        medicalHealthHistory?.seriousIllnessesOrOperationsInput,
        currently_taking_any_medication: passTrueFalse(
            medicalHealthHistory?.takingMedicationsOrSupplements
        ),
        medication_list:
        medicalHealthHistory?.takingMedicationsOrSupplementsInput,
        chest_pain_shortness_breath: passTrueFalse(
            medicalHealthHistory?.shortnessBreath
        ),
        nursing: passTrueFalse(medicalHealthHistory?.nursing),
        birth_control: passTrueFalse(medicalHealthHistory?.takingBirthControl),
        pregnant: passTrueFalse(medicalHealthHistory?.pregnant),
        medical_conditions: arrayToString(medicalHealthHistory?.medicalConditions),
        medical_conditions_other: medicalHealthHistory?.medicalConditionsOther || "",
        allergies_list: arrayToString(medicalHealthHistory?.checkboxListTwo),
        allergies_list_other: medicalHealthHistory?.checkboxListTwoOtherInput,

        //Communication Preferences
        appearance_teeth_scale: communicationPreferences?.appearanceOfYourTeeth,
        loose_tooth_feeling_scale:
        communicationPreferences?.feelIfYouWereToLoseTooth,
        chew_importance_scale: communicationPreferences?.yourTeethFunctionWell,
        importancy_of_teeth_health_scale:
        communicationPreferences?.keepYourTeethForRestOfYourLife,
        concerns_appearance_teeth_existance: passTrueFalse(
            communicationPreferences?.concernsWithAppearanceOfYourTeeth
        ),
        concerns_appearance_teeth_existance_explain:
        communicationPreferences?.concernsWithAppearanceOfYourTeethReason,
        teeth_sensitivity: passTrueFalse(
            communicationPreferences?.teethSensitive
        ),
        teeth_sensitivity_answer_yes:
        communicationPreferences?.teethSensitiveReason,
        describe_yourself_1: communicationPreferences?.describeYourselfMore1,
        describe_yourself_2: communicationPreferences?.describeYourselfMore2,
        social_note: communicationPreferences?.socialNotes,
        patient_preferences: communicationPreferences?.patientPreferences,
    } as any;
    if (letsGetToKnowYouBetter?.doYouHaveDentalInsurance === "yes") {
        preIntakeData.insurance = dentistInsuranceInfo?.map((res: any, index: number) => {
            return {
                insurance_provider: res.insuranceProvider,
                insurance_group_number: res.insuranceGroup,
                insurance_id: res.insuranceID,
                plan_member_name: res.planMemberName,
                plan_member_dob: res.planMemberDob,
                position: index
            };
        });
    }
    const updatedPayload = {
        patient_details: patientDetails,
        pre_intake: preIntakeData
    }
    try {
        const response = yield API.post(`/v2/pre-intake/form/anonymous`, updatedPayload)
        yield put(saveAnonymousPreIntakeRequestSuccessAction(response?.data?.detail));
        successToaster(response?.data?.meta?.message)
        setTimeout(() => {
            window.location.reload();
        }, 2000)
    } catch (e: any) {
        errorToaster(e?.message)
        yield put(
            saveAnonymousPreIntakeRequestFailureAction(e?.message)
        );
    }
}

function* verifyAnonymousPatientEmailRequestSaga(payload: any): any {
    const {
        preIntake: {patientInformation},
        anonymousPreIntake: {selectedEmail}
    } = yield select((state: RootState) => state) as any
    try {
        const response = yield API.post(`/v2/patient/verify-email`, payload?.payload)
        yield put(
            verifyAnonymousPatientEmailRequestSuccessAction(response?.data?.meta)
        );
        if (payload.payload.callback) {
            yield call(payload.payload.callback, response);
        }
        if (response?.status === 200) {
            let updatedPatientInformation = removeRefFromJson(patientInformation);
            updatedPatientInformation['emailAddress'] = selectedEmail;
            yield put(updatePatientInfoAction(updatedPatientInformation));
        }
    } catch (e: any) {
        // errorToaster(e?.message)
        if (payload?.payload?.errorCallback) {
            payload?.payload?.errorCallback(e)
        }
        yield put(
            verifyAnonymousPatientEmailRequestFailureAction(e?.message)
        );
    }
}

function* getClinicRequestRequestWatch() {
    yield takeEvery(GET_CLINIC_DETAILS_REQUEST, getClinicRequestSaga);
}

function* saveAnonymousPreIntakeRequestWatch() {
    yield takeEvery(SAVE_ANONYMOUS_PRE_INTAKE_REQUEST, saveAnonymousPreIntakeRequestSaga);
}

function* verifyAnonymousPatientEmailRequestWatch() {
    yield takeEvery(VERIFY_ANONYMOUS_PATIENT_EMAIL_REQUEST, verifyAnonymousPatientEmailRequestSaga);
}

export default function* anonymousPreIntakeSaga() {
    yield all([
        getClinicRequestRequestWatch(),
        saveAnonymousPreIntakeRequestWatch(),
        verifyAnonymousPatientEmailRequestWatch()
    ])
}