import { toothNumberForFindings,toothSurface } from "./helper";

const flatCuppedSubtype = [
  { label: "Flat", value: "Flat" },
  { label: "Cupped", value: "Cupped" },
];
const facet_sharpness_Subtype = [
  { label: "Sharp", value: "Sharp" },
  { label: "Round", value: "Round" },
];
const facet_appearance_Subtype = [
  { label: "Shiny", value: "Shiny" },
  { label: "Matte", value: "Matte" },
];

const generalPatternSubtype = [
  // (Checkbox, not multiple choice) End-to-end, Pathway, Crossover, Posterior Molar ("Cupped-Out")
  { label: "End-to-end", value: "End-to-end" },
  { label: "Pathway", value: "Pathway" },
  { label: "Crossover", value: "Crossover" },
  {
    label: 'Posterior Molar ("Cupped-Out")',
    value: 'Posterior Molar ("Cupped-Out")',
  },
];

export const WearFacetAppearanceData = [
  {
    title: "Tooth Number",
    value: [],
    name: "tooth_number",
    type: "multi-select",
    option: toothNumberForFindings,
  },

  {
    title: "Tooth Surface",
    value: [],
    name: "tooth_surface",
    type: "multi-select",
    option: toothSurface,
  },
  {
    title: "Sub Type",
    value: "",
    name: "sub_type",
    type: "select",
    option: facet_appearance_Subtype,
  },
  {
    title: "Notes",
    value: "",
    name: "note",
    type: "string",
  },
];
export const WearFacetShapeData = [
  {
    title: "Tooth Number",
    value: [],
    name: "tooth_number",
    type: "multi-select",
    option: toothNumberForFindings,
  },

  {
    title: "Tooth Surface",
    value: [],
    name: "tooth_surface",
    type: "multi-select",
    option: toothSurface,
  },
  {
    title: "Sub Type",
    value: "",
    name: "sub_type",
    type: "select",
    option: flatCuppedSubtype,
  },
  {
    title: "Notes",
    value: "",
    name: "note",
    type: "string",
  },
];
export const WearFacetSharpnessData = [
  {
    title: "Tooth Number",
    value: [],
    name: "tooth_number",
    type: "multi-select",
    option: toothNumberForFindings,
  },

  {
    title: "Tooth Surface",
    value: [],
    name: "tooth_surface",
    type: "multi-select",
    option: toothSurface,
  },
  {
    title: "Sub Type",
    value: "",
    name: "sub_type",
    type: "select",
    option: facet_sharpness_Subtype,
  },
  {
    title: "Notes",
    value: "",
    name: "note",
    type: "string",
  },
];
export const GeneralWearPatternData = [
  {
    title: "Sub Type",
    value: "",
    name: "sub_type",
    type: "select",
    option: generalPatternSubtype,
  },
  {
    title: "Notes",
    value: "",
    name: "note",
    type: "string",
  },
];