import {
    CHANGE_WELLNESS_COMPLAINTS_LOCAL_REQUEST,
    CHANGE_WELLNESS_COMPLAINTS_REQUEST,
    CHANGE_WELLNESS_PATIENT_PREFERENCE_REQUEST,
    CHANGE_WELLNESS_SOCIAL_NOTES_REQUEST,
    CREATE_APPOINTMENT_FROM_WELLNESS_REQUEST, CREATE_APPOINTMENT_FROM_WELLNESS_REQUEST_FAILURE,
    CREATE_APPOINTMENT_FROM_WELLNESS_REQUEST_SUCCESS,
    GET_WELLNESS_REQUEST,
    GET_WELLNESS_REQUEST_FAILURE,
    GET_WELLNESS_REQUEST_SUCCESS,
    UPDATE_DENTIST_WELLNESS_COMPLAINTS_REQUEST,
    UPDATE_DENTIST_WELLNESS_COMPLAINTS_REQUEST_FAILURE,
    UPDATE_DENTIST_WELLNESS_COMPLAINTS_REQUEST_SUCCESS,
    UPDATE_DENTIST_WELLNESS_PROFILE_REQUEST,
    UPDATE_DENTIST_WELLNESS_PROFILE_REQUEST_FAILURE,
    UPDATE_DENTIST_WELLNESS_PROFILE_REQUEST_SUCCESS,
    UPDATE_WELLNESS_PROFILE_STEP,
    CHANGE_WELLNESS_HYGIENE_REGIME_LOCAL_REQUEST,
    UPDATE_WELLNESS_HYGIENE_REGIME_REQUEST,
    UPDATE_WELLNESS_HYGIENE_REGIME_REQUEST_SUCCESS,
    UPDATE_WELLNESS_HYGIENE_REGIME_REQUEST_FAILURE,
    GENERATE_RISK_SCORE_WELLNESS_REQUEST,
    GENERATE_RISK_SCORE_WELLNESS_REQUEST_SUCCESS,
    GENERATE_RISK_SCORE_WELLNESS_REQUEST_FAILURE,
} from '../../../../ActionTypes/v2/dentist/wellnessProfile/wellnessProfileFilters';
import {
    ChangeWellnessHygieneRegimePayload,
    ChangeWellnessHygieneRegimeRequest,
    ChangeWellnessComplaintsLocalRequest,
    ChangeWellnessComplaintsRequest,
    ChangeWellnessPatientPreferencesRequest,
    ChangeWellnessSocialNotesRequest,
    CreateAppointmentFromWellnessFailure,
    CreateAppointmentFromWellnessFailurePayload,
    CreateAppointmentFromWellnessRequest,
    CreateAppointmentFromWellnessRequestPayload,
    CreateAppointmentFromWellnessSuccess,
    CreateAppointmentFromWellnessSuccessPayload,
    GetWellnessProfileFailure,
    GetWellnessProfileFailurePayload,
    GetWellnessProfileRequest,
    GetWellnessProfileRequestPayload,
    GetWellnessProfileSuccess,
    GetWellnessProfileSuccessPayload,
    UpdateDentistWellnessComplaintsRequestFailure,
    UpdateDentistWellnessComplaintsRequestSuccess,
    UpdateDentistWellnessProfileRequest,
    UpdateDentistWellnessProfileRequestFailure,
    UpdateDentistWellnessProfileRequestFailurePayload,
    UpdateDentistWellnessProfileRequestPayload,
    UpdateDentistWellnessProfileRequestSuccess,
    UpdateDentistWellnessProfileRequestSuccessPayload, UpdateWellnessHygieneRegimeFailure, UpdateWellnessHygieneRegimeFailurePayload,
    UpdateWellnessHygieneRegimeRequest,
    UpdateWellnessHygieneRegimeRequestPayload, UpdateWellnessHygieneRegimeSuccess,
    UpdateWellnessHygieneRegimeSuccessPayload,
    UpdateWellnessProfileStepPayload,
    UpdateWellnessProfileStepRequest,
    GenerateRiskScoreWellnessRequestPayload,
    GenerateRiskScoreWellnessRequest,
    GenerateRiskScoreWellnessSuccessPayload,
    GenerateRiskScoreWellnessSuccess,
    GenerateRiskScoreWellnessFailurePayload,
    GenerateRiskScoreWellnessFailure
} from '../../../../types/v2/dentist/wellnessProfile/wellnessProfileFilters';

export const updateWellnessProfileStepAction = (
    payload: UpdateWellnessProfileStepPayload
): UpdateWellnessProfileStepRequest => ({
    type: UPDATE_WELLNESS_PROFILE_STEP,
    payload
});

export const getWellnessProfileRequestAction = (payload: GetWellnessProfileRequestPayload): GetWellnessProfileRequest => ({
    type: GET_WELLNESS_REQUEST,
    payload
});

export const getWellnessProfileSuccessAction = (payload: GetWellnessProfileSuccessPayload): GetWellnessProfileSuccess => ({
    type: GET_WELLNESS_REQUEST_SUCCESS,
    payload
});

export const getWellnessProfileFailureAction = (payload: GetWellnessProfileFailurePayload): GetWellnessProfileFailure => ({
    type: GET_WELLNESS_REQUEST_FAILURE,
    payload
});


export const updateDentistWellnessProfileRequest = (payload: UpdateDentistWellnessProfileRequestPayload): UpdateDentistWellnessProfileRequest => ({
    type: UPDATE_DENTIST_WELLNESS_PROFILE_REQUEST,
    payload
});
export const updateDentistWellnessProfileRequestSuccess = (
    payload: UpdateDentistWellnessProfileRequestSuccessPayload
): UpdateDentistWellnessProfileRequestSuccess => ({
    type: UPDATE_DENTIST_WELLNESS_PROFILE_REQUEST_SUCCESS,
    payload
});
export const updateDentistWellnessProfileRequestFailure = (
    payload: UpdateDentistWellnessProfileRequestFailurePayload
): UpdateDentistWellnessProfileRequestFailure => ({
    type: UPDATE_DENTIST_WELLNESS_PROFILE_REQUEST_FAILURE,
    payload
});

export const changeWellnessComplaintsRequestAction = (payload: any): ChangeWellnessComplaintsRequest => ({
    type: CHANGE_WELLNESS_COMPLAINTS_REQUEST,
    payload
});
export const changeWellnessPatientPreferencesRequestAction = (payload: any): ChangeWellnessPatientPreferencesRequest => ({
    type: CHANGE_WELLNESS_PATIENT_PREFERENCE_REQUEST,
    payload
});
export const changeWellnessSocialNotesRequestAction = (payload: any): ChangeWellnessSocialNotesRequest => ({
    type: CHANGE_WELLNESS_SOCIAL_NOTES_REQUEST,
    payload
});

export const updateDentistWellnessComplaintsRequest = (payload: any): any => ({
    type: UPDATE_DENTIST_WELLNESS_COMPLAINTS_REQUEST,
    payload
});
export const updateDentistWellnessComplaintsRequestSuccess = (payload: any): UpdateDentistWellnessComplaintsRequestSuccess => ({
    type: UPDATE_DENTIST_WELLNESS_COMPLAINTS_REQUEST_SUCCESS,
    payload
});
export const updateDentistWellnessComplaintsRequestFailure = (payload: any): UpdateDentistWellnessComplaintsRequestFailure => ({
    type: UPDATE_DENTIST_WELLNESS_COMPLAINTS_REQUEST_FAILURE,
    payload
});
export const changeWellnessComplaintsLocalRequestAction = (payload: any): ChangeWellnessComplaintsLocalRequest => ({
    type: CHANGE_WELLNESS_COMPLAINTS_LOCAL_REQUEST,
    payload
});

export const createAppointmentFromWellnessRequest = (payload: CreateAppointmentFromWellnessRequestPayload): CreateAppointmentFromWellnessRequest => ({
    type: CREATE_APPOINTMENT_FROM_WELLNESS_REQUEST,
    payload
});
export const createAppointmentFromWellnessRequestSuccess = (
    payload: CreateAppointmentFromWellnessSuccessPayload
): CreateAppointmentFromWellnessSuccess => ({
    type: CREATE_APPOINTMENT_FROM_WELLNESS_REQUEST_SUCCESS,
    payload
});
export const createAppointmentFromWellnessRequestFailure = (
    payload: CreateAppointmentFromWellnessFailurePayload
): CreateAppointmentFromWellnessFailure => ({
    type: CREATE_APPOINTMENT_FROM_WELLNESS_REQUEST_FAILURE,
    payload
});

export const changeWellnessHygieneRegimeRequestAction = (
    payload: ChangeWellnessHygieneRegimePayload
): ChangeWellnessHygieneRegimeRequest => ({
    type: CHANGE_WELLNESS_HYGIENE_REGIME_LOCAL_REQUEST,
    payload
});

export const updateWellnessHygieneRegimeRequestAction = (payload: UpdateWellnessHygieneRegimeRequestPayload): UpdateWellnessHygieneRegimeRequest => ({
    type: UPDATE_WELLNESS_HYGIENE_REGIME_REQUEST,
    payload
});
export const updateWellnessHygieneRegimeRequestSuccessAction = (
    payload: UpdateWellnessHygieneRegimeSuccessPayload
): UpdateWellnessHygieneRegimeSuccess => ({
    type: UPDATE_WELLNESS_HYGIENE_REGIME_REQUEST_SUCCESS,
    payload
});
export const updateWellnessHygieneRegimeRequestFailureAction = (
    payload: UpdateWellnessHygieneRegimeFailurePayload
): UpdateWellnessHygieneRegimeFailure => ({
    type: UPDATE_WELLNESS_HYGIENE_REGIME_REQUEST_FAILURE,
    payload
});

export const generateRiskWellnessPMSRequestAction = (payload: GenerateRiskScoreWellnessRequestPayload): GenerateRiskScoreWellnessRequest => ({
    type: GENERATE_RISK_SCORE_WELLNESS_REQUEST,
    payload
});
export const generateRiskWellnessPMSRequestSuccessAction = (payload: GenerateRiskScoreWellnessSuccessPayload): GenerateRiskScoreWellnessSuccess => ({
    type: GENERATE_RISK_SCORE_WELLNESS_REQUEST_SUCCESS,
    payload
});
export const generateRiskWellnessPMSRequestFailureAction = (payload: GenerateRiskScoreWellnessFailurePayload): GenerateRiskScoreWellnessFailure => ({
    type: GENERATE_RISK_SCORE_WELLNESS_REQUEST_FAILURE,
    payload
});