import {all, call, put, takeEvery} from "redux-saga/effects";
import API from "../../../../utils/api";
import {changeAnnotationResponse, errorToaster, successToaster} from '../../../../utils/helper';
import {
    WITHOUT_ACCOUNT_GET_PATIENT_WELLNESS_PROFILE_REQUEST,
    WITHOUT_ACCOUNT_UPDATE_PATIENT_WELLNESS_PROFILE_REQUEST,
    DOWNLOAD_PDF_WITHOUT_ACCOUNT_REQUEST
} from "../../../ActionTypes/v2/patient/WellnessProfileWithoutAccount";
import {
    downloadPdfWithoutAccountRequestFailure,
    downloadPdfWithoutAccountRequestSuccess,
    getWellnessProfileWithoutAccountFailureAction,
    getWellnessProfileWithoutAccountSuccessAction,
    withoutAccountUpdatePatientWellnessProfileRequestFailure,
    withoutAccountUpdatePatientWellnessProfileRequestSuccess
} from "../../../actions/v2/patient/WellnessProfileWithoutAccountAction";

//Get Without Account Patient Wellness Profile
function* getWellnessProfileWithoutAccountRequest(payload: any): any {
    try {
        const response = yield API.get(`/v2/wellness-profile/patient/${payload?.payload?.patient_id}?clinic_id=${payload?.payload?.clinic_id}`)
        const updatedData = {
            ...response?.data?.detail,
            images: response?.data?.detail?.images?.map((image: any) => ({
                ...image,
                tags: changeAnnotationResponse(image?.tags)
            })),
            xrays: response?.data?.detail?.xrays?.map((xrays: any) => ({
                ...xrays,
                tags: changeAnnotationResponse(xrays?.tags)
            }))
        }
        yield put(getWellnessProfileWithoutAccountSuccessAction(updatedData))
    } catch (e: any) {
        errorToaster(e?.message)
        yield put(getWellnessProfileWithoutAccountFailureAction(e?.message))
    }
}

//Edit patient wellness Profile
function* updatePatientWellnessProfileWithoutAccountRequestSaga(payload: any): any {
    try {
        const response = yield API.put(`/v2/wellness-profile/update-patient-treatment`, payload.payload)
        yield put(
            withoutAccountUpdatePatientWellnessProfileRequestSuccess(response?.data?.detail)
        );
        if (payload.payload.callback) {
            yield call(payload.payload.callback, response.data?.detail);
        }
        successToaster(response?.data?.meta?.message);
    } catch (e: any) {
        errorToaster(e?.message)
        yield put(
            withoutAccountUpdatePatientWellnessProfileRequestFailure(e?.message)
        );
    }
}

//download PDF
function* downloadPdfWithoutAccountRequest(payload: any): any {
    try {
        const response = yield API.get(`/v2/wellness-profile/download/pdf/${payload.payload.patientID}?clinic_id=${payload?.payload?.clinicID}`);
        yield put(
            downloadPdfWithoutAccountRequestSuccess(response.data)
        );
        if (payload.payload.callback) {
            payload.payload.callback(response.data);
        }
    } catch (e: any) {
        errorToaster(e?.message)
        yield put(
            downloadPdfWithoutAccountRequestFailure(e?.detail)
        );
    }
}

function* getWellnessProfileWithoutAccountWatch() {
    yield takeEvery(WITHOUT_ACCOUNT_GET_PATIENT_WELLNESS_PROFILE_REQUEST, getWellnessProfileWithoutAccountRequest)
}

function* updatePatientWellnessProfileWithoutAccountWatch() {
    yield takeEvery(WITHOUT_ACCOUNT_UPDATE_PATIENT_WELLNESS_PROFILE_REQUEST, updatePatientWellnessProfileWithoutAccountRequestSaga);
}

function* downloadPdfWithoutAccountWatch() {
    yield takeEvery(DOWNLOAD_PDF_WITHOUT_ACCOUNT_REQUEST, downloadPdfWithoutAccountRequest);
}

export default function* patientWellnessProfileWithoutAccountSaga() {
    yield all([
        getWellnessProfileWithoutAccountWatch(),
        updatePatientWellnessProfileWithoutAccountWatch(),
        downloadPdfWithoutAccountWatch()
    ]);
}
