import {
    GET_CLINICAL_EXAM_NOTES_REQUEST,
  GET_CLINICAL_EXAM_NOTES_REQUEST_SUCCESS,
  GET_CLINICAL_EXAM_NOTES_REQUEST_FAILURE
} from "../../../../ActionTypes/v1/dentist/clinicalExam";
import { ClinicalExamNotesState, AppointmentTypeActions } from "../../../../types/dentistTypes"

const initialState: ClinicalExamNotesState = {
    loader: false,
    data: null,
    error: null
};

const clinicalExamNotesReducer = (state = initialState, action: AppointmentTypeActions) => {
    switch (action.type) {
        case GET_CLINICAL_EXAM_NOTES_REQUEST:
            return {
                ...state,
                loader: true,
            };
        case GET_CLINICAL_EXAM_NOTES_REQUEST_SUCCESS:
            return {
                ...state,
                loader: false,
                data: action.payload,
                error: null,
            };
        case GET_CLINICAL_EXAM_NOTES_REQUEST_FAILURE:
            return {
                ...state,
                loader: false,
                data: null,
                error: action.payload.error,
            };
        default:
            return {
                ...state,
            };
    }
}
export default clinicalExamNotesReducer;   