import {
    PUT_CLINICAL_EXAM_DENTIST_REQUEST,
    PUT_CLINICAL_EXAM_DENTIST_REQUEST_SUCCESS,
    PUT_CLINICAL_EXAM_DENTIST_REQUEST_FAILURE,
    GET_CLINICAL_EXAM_DENTIST_REQUEST,
    GET_CLINICAL_EXAM_DENTIST_REQUEST_SUCCESS,
    GET_CLINICAL_EXAM_DENTIST_REQUEST_FAILURE,
} from "../../../../ActionTypes/v1/dentist/clinicalExamDentist";
import {
    ClinicalExamDentistRequest,
    ClinicalExamDentistRequestSuccess,
    ClinicalExamDentistRequestFailure,
    ClinicalExamDentistRequestPayload,
    ClinicalExamDentistRequestSuccessPayload,
    ClinicalExamDentistRequestFailurePayload,
    GetClinicalExamDentistRequest,
    GetClinicalExamDentistRequestSuccess,
    GetClinicalExamDentistRequestFailure,
    GetClinicalExamDentistRequestPayload,
    GetClinicalExamDentistRequestSuccessPayload,
    GetClinicalExamDentistRequestFailurePayload
} from "../../../../types/dentistTypes";

//for clinical exam dentist PUT API integration
export const clinicalExamDentistDataRequest = (payload: ClinicalExamDentistRequestPayload): ClinicalExamDentistRequest => ({
    type: PUT_CLINICAL_EXAM_DENTIST_REQUEST,
    payload
})
export const clinicalExamDentistDataRequestFailure = (payload: ClinicalExamDentistRequestFailurePayload): ClinicalExamDentistRequestFailure => ({
    type: PUT_CLINICAL_EXAM_DENTIST_REQUEST_FAILURE,
    payload
})
export const clinicalExamDentistDataRequestSuccess = (payload: ClinicalExamDentistRequestSuccessPayload): ClinicalExamDentistRequestSuccess => ({
    type: PUT_CLINICAL_EXAM_DENTIST_REQUEST_SUCCESS,
    payload
})

// for get the data (clinical exam dentist)

export const getClinicalExamDentistDataRequest = (payload: GetClinicalExamDentistRequestPayload): GetClinicalExamDentistRequest => ({
    type: GET_CLINICAL_EXAM_DENTIST_REQUEST,
    payload
})
export const getClinicalExamDentistDataRequestFailure = (payload: GetClinicalExamDentistRequestFailurePayload): GetClinicalExamDentistRequestFailure => ({
    type: GET_CLINICAL_EXAM_DENTIST_REQUEST_FAILURE,
    payload
})
export const getClinicallExamDentistDataRequestSuccess = (payload: GetClinicalExamDentistRequestSuccessPayload): GetClinicalExamDentistRequestSuccess => ({
    type: GET_CLINICAL_EXAM_DENTIST_REQUEST_SUCCESS,
    payload
})
