import {
  GET_RESOURCE_DATA_REQUEST,
  GET_RESOURCE_DATA_SUCCESS,
  GET_RESOURCE_DATA_FAIL,
  GET_RESOURCE_CACHE_DATA_CLEAR_REQUEST
} from '../../../ActionTypes/v2/dentist/resource';
import {
  GetResourceTypeFailure,
  GetResourceTypeFailurePayload,
  GetResourceTypeRequest,
  GetResourceTypeRequestPayload,
  GetResourceTypeSuccess,
  GetResourceTypeSuccessPayload
} from '../../../types/v2/dentist/resource';

// Get Resource data api actions
export const getResourceListTypeRequestAction = (payload: GetResourceTypeRequestPayload): GetResourceTypeRequest =>
({
  type: GET_RESOURCE_DATA_REQUEST,
  payload
} as any);

export const getResourceListTypeSuccessAction = (payload: GetResourceTypeSuccessPayload): GetResourceTypeSuccess => {
  return {
    type: GET_RESOURCE_DATA_SUCCESS,
    payload
  };
};

export const getResourceListTypeFailureAction = (payload: GetResourceTypeFailurePayload): GetResourceTypeFailure =>
({
  type: GET_RESOURCE_DATA_FAIL,
  payload
} as any);

export const getResourceListCacheDataClearRequest = (): any => ({
  type: GET_RESOURCE_CACHE_DATA_CLEAR_REQUEST
});

