import { IconButton, InputAdornment } from "@mui/material";
import React from "react";
import theme from "../../../../theme";

const StartAdornmentIcon = (props: any) => {
  const { text, disabled } = props;
  return (
    <InputAdornment
      position="end"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginLeft: "0px",
        maxHeight: "",
        "& :hover": {
          cursor: disabled ? "default" : "pointer",
        },
      }}
    >
      <IconButton
        disableFocusRipple
        disableRipple
        edge="end"
        sx={{ width: "48px", height: "48px" }}
      >
        <span
          className=" f-w-400 fw-regular f-14"
          style={{
            color: disabled
              ? theme.palette.text.secondary
              : theme.palette.common.black50,
          }}
        >
          {text}
        </span>
      </IconButton>
    </InputAdornment>
  );
};

export default StartAdornmentIcon;
