import React, {useState} from "react";
import {makeStyles} from "@mui/styles";
import {Box, MenuItem, Typography} from "@mui/material";
import Select from "@mui/material/Select";
import {useSelector} from "react-redux";
import theme from "../../../../theme";
import {getLocalStorageItem} from "../../../../utils/helper";
import {MenuProps} from "../../../../utils/v2/appointmentConstant";
import admin from "../../../../assets/images/v2/clinicAvatar.png";
import VerifyPasswordPopup from "./VerifyPasswordPopup";

const useStyle = makeStyles(() => ({
    select: {
        paddingTop: "10px",
        paddingBottom: "10px",
        paddingLeft: "15px!important",
    },
    outlined: {
        paddingTop: "10px",
        paddingBottom: "10px",
        paddingLeft: "15px!important",
    },
    cssOutlinedInput: {
        width: 280,
        height: 50,
        [theme.breakpoints.down(465)]: {
            width: 220,
        },
        [theme.breakpoints.down(400)]: {
            width: 180,
        },
        [theme.breakpoints.down(360)]: {
            width: 130,
        },
        borderRadius: "8px !important",
        "& .MuiOutlinedInput-notchedOutline": {
            border: "none  !important",
            borderRadius: "5px 5px 0 0",
            width: "98px !important",
        },
        "& .MuiSelect-select": {
            paddingLeft: "1px !important",
        },
    },
    selectOptionImage: {
        borderRadius: "10px !important",
    },
}));

type Props = {
    isPatient?: boolean | undefined
}
const ChangeClinicDropDown = (props: Props) => {
    const {isPatient} = props
    const classes = useStyle();
    const [verifyPassword, setVerifyPassword] = useState(false);
    const [newClinic, setNewClinic] = useState("");
    const clinicData = useSelector((state: any) => state.Clinic?.data?.data);
    const getSelectedClinicData = (id?: string) => {
        return clinicData?.filter((f: any) => f.clinic_id === id)[0];
    }
    return (
        <React.Fragment>
            <VerifyPasswordPopup open={verifyPassword} setOpen={setVerifyPassword} clinic={newClinic}
                                 setNewClinic={setNewClinic} isPatient={isPatient}/>
            <Select
                sx={{
                    backgroundColor: isPatient ? theme.palette.common.white : theme.palette.grey.GrayBg
                }}
                defaultValue={isPatient ? getLocalStorageItem('patientClinic') : getLocalStorageItem('clinic')}
                value={isPatient ? getLocalStorageItem('patientClinic') : getLocalStorageItem('clinic')}
                onChange={(e: any) => {
                    setVerifyPassword(true)
                    setNewClinic(e.target.value);
                }}
                displayEmpty
                MenuProps={MenuProps}
                className={`${classes.cssOutlinedInput} border-radius-8 fw-regular f-w-400 f-14`}
                classes={{select: classes.select, standard: classes.outlined}}
                renderValue={(value) => {
                    return (
                        <Box display={'flex'} alignItems="center" gap={1} padding={'5px'}>
                            <Box className="d-flex-all-center fw-regular f-w-400 f-14">
                                <img
                                    src={getSelectedClinicData(getLocalStorageItem(isPatient ? "patientClinic" : 'clinic') || "")?.profile_picture || admin}
                                    alt="clinic"
                                    height={isPatient ? "35px" : '40px'}
                                    className={classes.selectOptionImage}
                                    width={isPatient ? "35px" : '40px'}
                                />
                            </Box>
                            <Typography className={`text-overflow-ellipsis fw-regular f-14 `}>
                                {getSelectedClinicData(getLocalStorageItem(isPatient ? "patientClinic" : 'clinic') || "")?.clinic_name}
                            </Typography>
                        </Box>
                    );
                }}
            >
                {clinicData?.map((option: any, key: number) => (
                    <MenuItem className={'fw-regular f-w-400 f-14'} key={key} value={option.clinic_id}>
                        {option.clinic_name}
                    </MenuItem>
                ))}
            </Select>
        </React.Fragment>
    )
}
export default ChangeClinicDropDown;