import React from 'react'
import {useSelector} from "react-redux";
import {Box, Typography} from "@mui/material";
import WellnessPastHygieneRegimen from "./WellnessPastHygieneRegimen";
import WellnessPastTreatmentList from "./WellnessPastTreatmentList";
import {RootState} from "../../../../../../app/reducers/v2/rootReducer";
import NoDataFound from "../../../../../v1/Common/NoDataFound";
import ReferralsSection from "../../../../common/ReferralsSection";

const WellnessTreatmentsList = () => {
    const {wellnessProfileFilters: {wellnessProfileData},} = useSelector((state: RootState) => state);

    return (
        <React.Fragment>
            {wellnessProfileData?.past_treatments?.length > 0 ? wellnessProfileData?.past_treatments?.map((res: any, index: number) =>
                <Box key={index}>
                    <Typography className={'f-14 f-w-400 fw-regular'} pb={1}>{res?.date}:</Typography>
                    <WellnessPastHygieneRegimen hygieneRegimeData={res?.hygiene_regime}/>
                    {res?.referrals?.length > 0 && <ReferralsSection referralsData={res?.referrals}
                                                                     disabledField={true}/>}
                    <WellnessPastTreatmentList treatmentData={res?.treatments}/>

                </Box>
            ) : <Box py={2}><NoDataFound errorMessage={'past treatment'}/></Box>}
        </React.Fragment>
    )
}

export default WellnessTreatmentsList