
import { Typography } from '@mui/material'
import React from 'react'
import overhangIcon from '../../../../../../../assets/images/v2/odontogramFindings/overhangIcon.svg'

interface Props {
  isOverhang: any
  isBottomTeeth: boolean | undefined
  toothNumber: number
}
const OdontogramOverHangFinding = (props: Props) => {
  const { isOverhang, isBottomTeeth, toothNumber } = props
  const left = isOverhang?.toothSurface?.includes((toothNumber > 30 && toothNumber < 39) || (toothNumber > 20 && toothNumber < 29) ? 'Mesial' : 'Distal')
  const right = isOverhang?.toothSurface?.includes((toothNumber > 30 && toothNumber < 39) || (toothNumber > 20 && toothNumber < 29) ? 'Distal' : 'Mesial')

  return (
    <React.Fragment>
      {isOverhang?.hasTooth && left &&
        (<Typography className='pos-absolute' sx={{ top: 42, left: isBottomTeeth ? "" : 0, right: isBottomTeeth ? 0 : '' }} >
          <img src={overhangIcon} alt='overhang' height={13} />
        </Typography>)
      }
      {isOverhang?.hasTooth && right &&
        (<Typography className='pos-absolute' sx={{ top: 42, right: isBottomTeeth ? "" : 0, left: isBottomTeeth ? 0 : '' }} >
          <img src={overhangIcon} alt='overhang' height={13} />
        </Typography>)
      }
    </React.Fragment>
  )
}
export default OdontogramOverHangFinding