import {
  PATIENT_PROFILE_EDIT_REQUEST_V2,
  PATIENT_PROFILE_EDIT_REQUEST_FAILURE_V2,
  PATIENT_PROFILE_REQUEST_V2,
  PATIENT_PROFILE_CACHE_DATA_CLEAR_REQUEST_V2,
  PATIENT_PROFILE_REQUEST_FAILURE_V2,
  PATIENT_PROFILE_REQUEST_SUCCESS_V2,
  PATIENT_PROFILE_EDIT_REQUEST_SUCCESS_V2
} from '../../../ActionTypes/v2/patient/patientProfile';
import {
  PatientProfileEditRequest,
  PatientProfileEditRequestFailure,
  PatientProfileEditRequestFailurePayload,
  PatientProfileEditRequestPayload,
  PatientProfileEditRequestSuccess,
  PatientProfileEditRequestSuccessPayload,
  PatientProfileRequest,
  PatientProfileRequestFailure,
  PatientProfileRequestFailurePayload,
  PatientProfileRequestPayload,
  PatientProfileRequestSuccess,
  PatientProfileRequestSuccessPayload
} from '../../../types/v2/patient/patientProfileActionTypes';

//Patient Profile Data
export const patientProfileRequest = (payload: PatientProfileRequestPayload): PatientProfileRequest => ({
  type: PATIENT_PROFILE_REQUEST_V2,
  payload
});
export const patientProfileCacheDataClearRequest = (): any => ({
  type: PATIENT_PROFILE_CACHE_DATA_CLEAR_REQUEST_V2
});

export const patientProfileRequestSuccess = (payload: PatientProfileRequestSuccessPayload): PatientProfileRequestSuccess => ({
  type: PATIENT_PROFILE_REQUEST_SUCCESS_V2,
  payload
});

export const patientProfileRequestFailure = (payload: PatientProfileRequestFailurePayload): PatientProfileRequestFailure => ({
  type: PATIENT_PROFILE_REQUEST_FAILURE_V2,
  payload
});

//Edit Patient Profile

export const patientProfileEditRequest = (payload: PatientProfileEditRequestPayload): PatientProfileEditRequest => ({
  type: PATIENT_PROFILE_EDIT_REQUEST_V2,
  payload
});
export const patientProfileEditRequestSuccess = (
  payload: PatientProfileEditRequestSuccessPayload
): PatientProfileEditRequestSuccess => ({
  type: PATIENT_PROFILE_EDIT_REQUEST_SUCCESS_V2,
  payload
});
export const patientProfileEditRequestFailure = (
  payload: PatientProfileEditRequestFailurePayload
): PatientProfileEditRequestFailure => ({
  type: PATIENT_PROFILE_EDIT_REQUEST_FAILURE_V2,
  payload
});
