// set appointment filter in dentist side
export const SET_DATE_NEW_APPOINTMENT = 'SET_DATE_NEW_APPOINTMENT';
export const SET_SEARCH_DATA_APPOINTMENT = 'SET_SEARCH_DATA_APPOINTMENT';
export const SET_ROLE_APPOINTMENT = 'SET_ROLE_APPOINTMENT';
export const SET_STATUS_APPOINTMENT = 'SET_STATUS_APPOINTMENT';
export const SET_EXAM_APPOINTMENT = 'SET_EXAM_APPOINTMENT';
export const SET_TEAM_MEMBER_APPOINTMENT = 'SET_TEAM_MEMBER_APPOINTMENT';

//dentist new appointment table data
export const DENTIST_NEW_APPOINTMENT_REQUEST = 'DENTIST_NEW_APPOINTMENT_REQUEST';
export const DENTIST_NEW_APPOINTMENT_CACHE_DATA_CLEAR_REQUEST = 'DENTIST_NEW_APPOINTMENT_CACHE_DATA_CLEAR_REQUEST';
export const DENTIST_NEW_APPOINTMENT_REQUEST_SUCCESS = 'DENTIST_NEW_APPOINTMENT_REQUEST_SUCCESS';
export const DENTIST_NEW_APPOINTMENT_REQUEST_FAILURE = 'DENTIST_NEW_APPOINTMENT_REQUEST_FAILURE';

//dashboard members
export const DASHBOARD_MEMBERS_REQUEST = 'DASHBOARD_MEMBERS_REQUEST';
export const DASHBOARD_MEMBERS_CACHE_DATA_CLEAR_REQUEST = 'DASHBOARD_MEMBERS_CACHE_DATA_CLEAR_REQUEST';
export const DASHBOARD_MEMBERS_REQUEST_SUCCESS = 'DASHBOARD_MEMBERS_REQUEST_SUCCESS';
export const DASHBOARD_MEMBERS_REQUEST_FAILURE = 'DASHBOARD_MEMBERS_REQUEST_FAILURE';

// appointment details
export const APPOINTMENT_DETAILS_REQUEST = ' APPOINTMENT_DETAILS_REQUEST';
export const APPOINTMENT_DETAILS_REQUEST_SUCCESS = ' APPOINTMENT_DETAILS_REQUEST_SUCCESS';
export const UPDATE_APPOINTMENT_DETAILS = ' UPDATE_APPOINTMENT_DETAILS' as string;
export const APPOINTMENT_DETAILS_REQUEST_FAILURE = ' APPOINTMENT_DETAILS_REQUEST_FAILURE';

// role base update
export const CHANGE_LOGIN_USER_PERMISSION_REQUEST = 'CHANGE_LOGIN_USER_PERMISSION_REQUEST';
export const CHANGE_LOGIN_USER_PERMISSION_SUCCESS = 'CHANGE_LOGIN_USER_PERMISSION_SUCCESS';
export const CHANGE_LOGIN_USER_PERMISSION_FAILURE = 'CHANGE_LOGIN_USER_PERMISSION_FAILURE';

export const UPDATE_APPOINTMENT_DATA_REQUEST = 'UPDATE_APPOINTMENT_DATA_REQUEST';

export const SET_DESCENDING_ASCENDING = 'SET_DESCENDING_ASCENDING';

// update appointment status
export const UPDATE_APPOINTMENT_STATUS_REQUEST = 'UPDATE_APPOINTMENT_STATUS_REQUEST'
export const UPDATE_APPOINTMENT_STATUS_REQUEST_SUCCESS = 'UPDATE_APPOINTMENT_STATUS_REQUEST_SUCCESS'
export const UPDATE_APPOINTMENT_STATUS_REQUEST_FAILURE = 'UPDATE_APPOINTMENT_STATUS_REQUEST_FAILURE'
