export const CHANGE_REVIEW_TREATMENT_PLAN = "CHANGE_REVIEW_TREATMENT_PLAN";
export const UPDATE_REVIEW_TREATMENT_PLAN = "UPDATE_REVIEW_TREATMENT_PLAN";

export const GET_REVIEW_TREATMENT_PLAN_REQUEST =
  "GET_REVIEW_TREATMENT_PLAN_REQUEST";
export const GET_REVIEW_TREATMENT_PLAN_CACHE_DATA_CLEAR_REQUEST = "GET_REVIEW_TREATMENT_PLAN_CACHE_DATA_CLEAR_REQUEST"
export const GET_REVIEW_TREATMENT_PLAN_REQUEST_SUCCESS =
  "GET_REVIEW_TREATMENT_PLAN_REQUEST_SUCCESS";
export const GET_REVIEW_TREATMENT_PLAN_REQUEST_FAILURE =
  "GET_REVIEW_TREATMENT_PLAN_REQUEST_FAILURE";

export const PUT_REVIEW_TREATMENT_PLAN_REQUEST =
  "PUT_REVIEW_TREATMENT_PLAN_REQUEST";
export const PUT_REVIEW_TREATMENT_PLAN_REQUEST_SUCCESS =
  "PUT_REVIEW_TREATMENT_PLAN_REQUEST_SUCCESS";
export const PUT_REVIEW_TREATMENT_PLAN_REQUEST_FAILURE =
  "PUT_REVIEW_TREATMENT_PLAN_REQUEST_FAILURE";
