import {

    PostXrayDataAction, postXrayTagState
} from "../../../../types/dentistTypes";
import {
    POST_XRAY_TAG_DATA, POST_EDIT_XRAY_TAG_DATA, POST_IMAGE_TAG_DATA, POST_EDIT_IMAGE_TAG_DATA, POST_DELETE_XRAY_TAG_DATA,
    POST_DELETE_IMAGE_TAG_DATA
} from "../../../../ActionTypes/v1/dentist/clinicalExamDentist";

const initialState: postXrayTagState = {
    tags: [],
    tagsImage: [],
    xPosition: null

}

export const PostXrayDataReducer = (state = initialState, action: PostXrayDataAction) => {

    switch (action.type) {

        case POST_XRAY_TAG_DATA:
            return {
                ...state,
                tags: action.payload.reset ? action?.payload?.tags : [...state.tags, action.payload]
            }

        case POST_EDIT_XRAY_TAG_DATA:

            const currentTag = Array.from(state.tags);

            currentTag[action.payload.editingTagIndex] = action.payload.tags;
            return { ...state, tags: currentTag };

        case POST_DELETE_XRAY_TAG_DATA:
            return {
                ...state,
                tags: state.tags.filter((item: any) => item.xPosition !== action.payload),

            }
        case POST_IMAGE_TAG_DATA:
            return {
                ...state,
                // tagsImage: [...state.tagsImage, action.payload]
                tagsImage: action.payload.reset ? action?.payload?.tagsImage : [...state.tagsImage, action.payload]
            }

        case POST_EDIT_IMAGE_TAG_DATA:
            const currentImageTag = Array.from(state.tagsImage);
            currentImageTag[action.payload.editingImageTagIndex] = action.payload.tagsImage;
            return { ...state, tagsImage: currentImageTag };

        case POST_DELETE_IMAGE_TAG_DATA:
            return {
                ...state,
                tagsImage: state.tagsImage.filter((item: any) => item.xPosition !== action.payload),

            }
        default:
            return state;
    }
}
export default PostXrayDataReducer