import {put, takeEvery, all, call} from "redux-saga/effects";
import API from "../../../utils/api";
import {
    unsubscribeEmailRequestFailure,
    unsubscribeEmailRequestSuccess
} from "../../actions/unsubscribeEmail/unsubscribeEmailActions";
import {errorToaster} from "../../../utils/helper";
import {UNSUBSCRIBE_EMAIL_REQUEST} from "../../ActionTypes/unsubscribeEmail/unsubscribeActionTypes";


function* unsubscribeEmailRequestSaga(payload: any): any {
    try {
        const response = yield API.post(`/v2/auth/unsubscribe`, payload?.payload)
        yield put(
            unsubscribeEmailRequestSuccess(response?.data?.detail)
        );
        if (payload?.payload?.callback) {
            yield call(payload?.payload?.callback, response?.data?.detail);
        }
    } catch (e: any) {
        errorToaster(e?.message)
        yield put(
            unsubscribeEmailRequestFailure(e?.message)
        );
    }
}


function* unsubscribeEmailRequestWatch() {
    yield takeEvery(UNSUBSCRIBE_EMAIL_REQUEST, unsubscribeEmailRequestSaga);
}

export default function* unsubscribeEmailSaga() {
    yield all([
        unsubscribeEmailRequestWatch()
    ])
}