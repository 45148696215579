import { all, put, takeEvery } from 'redux-saga/effects';
import API from '../../../../../utils/api';
import {
  getRecentXrayCacheDataClearRequest,
  getRecentXrayRequestFailure,
  getRecentXrayRequestSuccess
} from '../../../../actions/v1/dentist/clinicExamDentistActions/getRecentXrayAction';
import { RECENT_XRAY_REQUEST } from '../../../../ActionTypes/v1/dentist/clinicalExamDentist';

function* getRecentXrayRequestSaga(payload: any): any {
  yield put(getRecentXrayCacheDataClearRequest());
  try {
    const response: any = yield API.get(
      `/v2/images/recent/${payload.payload.patient_id}?image_type=${payload.payload?.image_type}`
      
    );

    yield put(
      getRecentXrayRequestSuccess({
        data: response.data?.detail
      })
    );
  } catch (e:any) {
    //TODO: update toast according to status code
    // errorToaster(e?.detail)
    yield put(getRecentXrayCacheDataClearRequest());
    yield put(
      getRecentXrayRequestFailure({
        error: e?.message
      })
    );
  }
}

function* recentXrayRequestSaga() {
  yield takeEvery(RECENT_XRAY_REQUEST, getRecentXrayRequestSaga);
}
export default function* rootSaga() {
  yield all([recentXrayRequestSaga()]);
}
