import {
  ADD_ALTERNATIVE_TREATMENT, ADD_TREATMENT_PLAN,
  CHANGE_TREATMENT_PLAN,
  UPDATE_TREATMENT_PLAN,
  UPDATE_TREATMENT_PLAN_CACHE_DATA_CLEAR,
  GET_TREATMENT_PLAN_REQUEST, GET_TREATMENT_PLAN_CACHE_DATA_CLEAR_REQUEST,
  GET_TREATMENT_PLAN_REQUEST_FAILURE,
  GET_TREATMENT_PLAN_REQUEST_SUCCESS, HANDLE_TREATMENT_PLAN_VALUE_CHANGE,
  PUT_TREATMENT_PLAN_REQUEST,
  PUT_TREATMENT_PLAN_REQUEST_FAILURE,
  PUT_TREATMENT_PLAN_REQUEST_SUCCESS, REMOVE_ALTERNATIVE_TREATMENT, REMOVE_TREATMENT_PLAN, DELETE_TREATMENT_PLAN,
  DELETE_TREATMENT_PLAN_FAILURE,
  DELETE_TREATMENT_PLAN_SUCCESS

} from "../../../../../ActionTypes/v1/dentist/treatmentPlan";
import {
  AddAlternativeTreatment,
  AddAlternativeTreatmentPayload,
  AddTreatmentPlan,
  ChangeTreatmentPlanInterface,
  GetTeatmentPlanSuccessPayload,
  GetTreatmentPlanFailurePayload,
  GetTreatmentPlanRequest,
  GetTreatmentPlanRequestFailure,
  GetTreatmentPlanRequestPayload,
  GetTreatmentPlanRequestSuccess, HandleTreatmentPlanValueChange, HandleTreatmentPlanValueChangePayload,
  PutTreatmentPlanFailurePayload,
  PutTreatmentPlanRequest,
  PutTreatmentPlanRequestFailure,
  PutTreatmentPlanRequestPayload,
  PutTreatmentPlanRequestSuccess,
  PutTreatmentPlanSuccessPayload,
  RemoveAlternativeTreatment,
  RemoveAlternativeTreatmentPayload,
  RemoveTreatmentPlan,
  RemoveTreatmentPlanPayload,
  UpdateTreatmentPlanInterface,
  UpdateTreatmentPlanRequest,
  DeleteTreatmentPlanRequest,
  DeleteTreatmentPlanPayload,
  DeleteTreatmentPlanFailureRequest,
  DeleteTreatmentPlanFailurePayload,
  DeleteTreatmentPlanSuccessRequest,
  DeleteTreatmentPlanSuccessPayload
} from "../../../../../types/dentistTypes";

export const changeTreatmentPlan = (
  payload: ChangeTreatmentPlanInterface
): any => ({
  type: CHANGE_TREATMENT_PLAN,
  payload,
});
export const deleteTreatmentPlan = (
  payload: DeleteTreatmentPlanPayload
): DeleteTreatmentPlanRequest => ({
  type: DELETE_TREATMENT_PLAN,
  payload,
});
export const deleteTreatmentPlanFailure = (
  payload: DeleteTreatmentPlanFailurePayload
): DeleteTreatmentPlanFailureRequest => ({
  type: DELETE_TREATMENT_PLAN_FAILURE,
  payload,
});
export const deleteTreatmentPlanSuccess = (
  payload: DeleteTreatmentPlanSuccessPayload
): DeleteTreatmentPlanSuccessRequest => ({
  type: DELETE_TREATMENT_PLAN_SUCCESS,
  payload,
});
export const updateTreatmentPlan = (
  payload: UpdateTreatmentPlanInterface
): UpdateTreatmentPlanRequest => ({
  type: UPDATE_TREATMENT_PLAN,
  payload,
});
export const updateTreatmentPlanCacheDataClearRequest = ():any => ({
  type: UPDATE_TREATMENT_PLAN_CACHE_DATA_CLEAR
})
export const putTreatmentPlanRequest = (
  payload: PutTreatmentPlanRequestPayload
): PutTreatmentPlanRequest => ({
  type: PUT_TREATMENT_PLAN_REQUEST,
  payload,
});

export const putTreatmentPlanRequestSuccess = (
  payload: PutTreatmentPlanSuccessPayload
): PutTreatmentPlanRequestSuccess => ({
  type: PUT_TREATMENT_PLAN_REQUEST_SUCCESS,
  payload,
});
export const putTreatmentPlanRequestFailure = (
  payload: PutTreatmentPlanFailurePayload
): PutTreatmentPlanRequestFailure => ({
  type: PUT_TREATMENT_PLAN_REQUEST_FAILURE,
  payload,
});

export const getTreatmentPlanRequest = (
  payload: GetTreatmentPlanRequestPayload
): GetTreatmentPlanRequest => ({
  type: GET_TREATMENT_PLAN_REQUEST,
  payload,
});

export const getTreatmentPlanCacheDataClearRequest = ():any => ({
  type: GET_TREATMENT_PLAN_CACHE_DATA_CLEAR_REQUEST
})

export const getTreatmentPlanRequestSuccess = (
  payload: GetTeatmentPlanSuccessPayload
): GetTreatmentPlanRequestSuccess => ({
  type: GET_TREATMENT_PLAN_REQUEST_SUCCESS,
  payload,
});
export const getTreatmentPlanRequestFailure = (
  payload: GetTreatmentPlanFailurePayload
): GetTreatmentPlanRequestFailure => ({
  type: GET_TREATMENT_PLAN_REQUEST_FAILURE,
  payload,
});

export const addAlternativeTreatmentAction = (
  payload: AddAlternativeTreatmentPayload
): AddAlternativeTreatment => ({
  type: ADD_ALTERNATIVE_TREATMENT,
  payload
})

export const removeAlternativeTreatmentAction = (
  payload: RemoveAlternativeTreatmentPayload
): RemoveAlternativeTreatment => ({
  type: REMOVE_ALTERNATIVE_TREATMENT,
  payload
})

export const addTreatmentPlanAction = (): AddTreatmentPlan => ({
  type: ADD_TREATMENT_PLAN,
})

export const removeTreatmentPlanAction = (
  payload: RemoveTreatmentPlanPayload
): RemoveTreatmentPlan => ({
  type: REMOVE_TREATMENT_PLAN,
  payload
})

export const handleTreatmentPlanValueChangeAction = (
  payload: HandleTreatmentPlanValueChangePayload
): HandleTreatmentPlanValueChange => ({
  type: HANDLE_TREATMENT_PLAN_VALUE_CHANGE,
  payload
})