import { all, call, put, takeEvery } from 'redux-saga/effects';
import API from '../../../../../utils/api';
import { STAFF_PROFILE_REQUEST, STAFF_PROFILE_EDIT_REQUEST } from '../../../../ActionTypes/v1/dentist/dentistProfile';
import {
    staffProfileRequestSuccess,
    staffProfileRequestFailure,
    staffProfileEditRequestFailure,
    staffProfileCacheDataClearRequest,
    staffProfileEditRequestSuccess
} from '../../../../actions/v1/dentist/staffProfileActions/staffProfileActions';
import { errorToaster, successToaster } from '../../../../../utils/helper';

//Staff Profile Data
function* staffProfileRequestSaga(payload: any): any {
    yield put(staffProfileCacheDataClearRequest());
    try {
        const response = yield API.get(`/user/staff/${payload.payload.staff_id}`);
        if (payload.payload.callback()) {
            yield call(payload.payload.callback, response.data);
        }
        yield put(
            staffProfileRequestSuccess({
                data: response?.data
            })
        );
    } catch (e: any) {
        //TODO: update toast according to status code
        //  errorToaster(e?.detail)
        yield put(staffProfileCacheDataClearRequest());
        yield put(
            staffProfileRequestFailure({
                error: e?.detail
            })
        );
    }
}
//Edit Staff Profile
function* staffProfileEditRequestSaga(payload: any): any {
    try {
        const response = yield API.put(`/user/staff`, payload.payload.profile_picutre);
        if (response) {
            successToaster('Profile update successfully ')
        }
        yield put(
            staffProfileEditRequestSuccess({
                data: response.data
            })
        );
    } catch (e: any) {
        errorToaster(e?.detail)
        yield put(
            staffProfileEditRequestFailure({
                error: e.detail
            })
        );
    }
}

function* getStaffProfileSaga() {
    yield takeEvery(STAFF_PROFILE_REQUEST, staffProfileRequestSaga);
}

function* staffProfileEditSaga() {
    yield takeEvery(STAFF_PROFILE_EDIT_REQUEST, staffProfileEditRequestSaga);
}
export default function* staffProfileSaga() {
    yield all([getStaffProfileSaga(), staffProfileEditSaga()]);
}
