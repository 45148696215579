import {
  CHANGE_PASSWORD_V2_REQUEST,
  CHANGE_PASSWORD_V2_REQUEST_FAILURE,
  CHANGE_PASSWORD_V2_REQUEST_SUCCESS,
  LOGOUT_V2_REQUEST,
  LOGOUT_V2_REQUEST_FAILURE,
  LOGOUT_V2_REQUEST_SUCCESS
} from '../../../ActionTypes/v2/auth';
import { changePasswordActions, changePasswordInterface } from '../../../types/v2/authActionTypes/changePasswordActionTypes';
import { logoutActions, logoutInterface } from '../../../types/v2/authActionTypes/logOutActionTypes';

const initialState: logoutInterface = {
  logoutLoader: false,
  logoutData: null,
  logoutError: null
};

const logoutReducer = (state = initialState, action: logoutActions) => {
  switch (action.type) {
    case LOGOUT_V2_REQUEST:
      return {
        ...state,
        logoutLoader: true
      };

    case LOGOUT_V2_REQUEST_SUCCESS:
      return {
        ...state,
        logoutLoader: false,
        logoutData: action.payload,
        logoutError: null
      };

    case LOGOUT_V2_REQUEST_FAILURE:
      return {
        ...state,
        logoutLoader: false,
        logoutData: null,
        logoutError: action.payload.logoutError
      };

    default:
      return {
        ...state
      };
  }
};
export default logoutReducer;
