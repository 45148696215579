import { call, put, takeEvery, all } from 'redux-saga/effects';
import API from '../../../../utils/api';
import { errorToaster } from '../../../../utils/helper';
import {
  getResourceListTypeSuccessAction,
  getResourceListTypeFailureAction,
  getResourceListCacheDataClearRequest
} from '../../../actions/v2/dentist/resourceAction';
import { GET_RESOURCE_DATA_REQUEST } from '../../../ActionTypes/v2/dentist/resource';

// get resources data
function* getResourcesTypeRequest(payload: any): any {
  yield put(getResourceListCacheDataClearRequest())
  try {
    const params = [
      { key: 'portal', value: payload?.payload?.portalName },
    ]
    let tmpUrl = `?`
    params?.map((p: any) => {
      if (tmpUrl.length > 1) {
        if (p.value) {
          tmpUrl = `${tmpUrl}&${p.key}=${p.value}`
        }
      } else {
        if (p.value) {
          tmpUrl = `${tmpUrl}${p.key}=${p.value}`
        }
      }
      return true
    })

    const newUrl = `v2/resources${tmpUrl}`
    let url = newUrl
    const response = yield API.get(url)

    yield put(
      getResourceListTypeSuccessAction({
        resourceData: response?.data?.detail,
        resourceMeta: response?.data?.meta,
      })
    )

    if (payload?.payload?.callback) {
      yield call(payload?.payload?.callback)
    }
  } catch (e: any) {
    errorToaster(e?.message)
    yield put(
      getResourceListTypeFailureAction({
        resourceError: e?.message,
      })
    )
  }
}

function* getResourcesSaga() {
  yield takeEvery(GET_RESOURCE_DATA_REQUEST, getResourcesTypeRequest)
}

export default function* resourcesSaga() {
  yield all([
    getResourcesSaga()
  ])
}
