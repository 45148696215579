import {
  ADD_EXISTING_FINDING,
  CHECKED_EXISTING_FINDING,
  FAILURE_EXAM_API_DATA,
  GET_HARD_TISSUE_REQUEST,
  GET_ODONTOGRAM_REQUEST,
  GET_OTHER_FORM_FINDING_REQUEST,
  GET_PERIOGRAM_REQUEST,
  GET_SOFT_TISSUE_REQUEST,
  PUT_HARD_TISSUE_REQUEST,
  PUT_ODONTOGRAM_REQUEST,
  PUT_OTHER_FORM_FINDING_REQUEST,
  PUT_PERIOGRAM_REQUEST,
  PUT_SOFT_TISSUE_REQUEST,
  SET_EXAM_FINDINGS,
  SUCCESS_EXAM_API_DATA,
  UPDATE_EXAM_API_DATA,
  EXAM_GROUP_FINDING_DATA_COUNT,
  SYNC_PERIOGRAM_PMS_DATA_REQUEST,
  SYNC_PERIOGRAM_PMS_DATA_REQUEST_FAILURE,
  SYNC_PERIOGRAM_PMS_DATA_REQUEST_SUCCESS,
  SYNC_ODONTOGRAM_PMS_DATA_REQUEST,
  SYNC_ODONTOGRAM_PMS_DATA_REQUEST_FAILURE,
  SYNC_ODONTOGRAM_PMS_DATA_REQUEST_SUCCESS,
  FAILURE_PUT_EXAM_API_DATA,
  GET_LIMITED_EXAM_REQUEST,
  PUT_LIMITED_EXAM_REQUEST
} from '../../../../../ActionTypes/v2/dentist/clinicalExam/exams/examFindingsTypes';
import {
  AddExistingFindingsDataRequest,
  CheckedExistingFindingsRequest,
  ExamFindingDataCountRequest,
  FailureExamApiRequest,
  GetHardTissueRequest,
  GetHardTissueRequestPayload,
  GetOdontogramRequest,
  GetOdontogramRequestPayload,
  GetOtherFormFindingRequest,
  GetOtherFormFindingRequestPayload,
  GetPeriogramRequest,
  GetPeriogramRequestPayload,
  GetSoftTissueRequest,
  GetSoftTissueRequestPayload,
  PutHardTissueRequest,
  PutHardTissueRequestPayload,
  PutOdontogramRequest,
  PutOdontogramRequestPayload,
  PutOtherFormFindingRequest,
  PutOtherFormFindingRequestPayload,
  PutPeriogramRequest,
  PutPeriogramRequestPayload,
  PutSoftTissueRequest,
  PutSoftTissueRequestPayload,
  SetExamFindingsRequest,
  SuccessExamApiRequest,
  syncPeriogramPmsDataRequest,
  syncPeriogramPmsDataRequestFailure,
  syncPeriogramPmsDataRequestFailurePayload,
  syncPeriogramPmsDataRequestPayload,
  syncPeriogramPmsDataRequestSuccess,
  syncPeriogramPmsDataRequestSuccessPayload,
  syncOdontogramPmsDataRequest,
  syncOdontogramPmsDataRequestFailure,
  syncOdontogramPmsDataRequestFailurePayload,
  syncOdontogramPmsDataRequestPayload,
  syncOdontogramPmsDataRequestSuccess,
  syncOdontogramPmsDataRequestSuccessPayload,
  GetLimitedExamRequestPayload,
  GetLimitedExamRequest,
  PutLimitedExamRequestPayload,
  PutLimitedExamRequest
} from '../../../../../types/v2/dentist/clinicalExam/exam/examFindingsTypes';

export const setExamFindingsAction = (payload: any): SetExamFindingsRequest => ({
  type: SET_EXAM_FINDINGS,
  payload
});

// add finding in existing exam
export const addExistingFindingsDataAction = (payload: any): AddExistingFindingsDataRequest => ({
  type: ADD_EXISTING_FINDING,
  payload
});

export const checkedExistingFindingsDataAction = (payload: any): CheckedExistingFindingsRequest => ({
  type: CHECKED_EXISTING_FINDING,
  payload
});

// get Soft tissue
export const getSoftTissueRequestAction = (payload: GetSoftTissueRequestPayload): GetSoftTissueRequest => ({
  type: GET_SOFT_TISSUE_REQUEST,
  payload
});

// put Soft tissue
export const putSoftTissueRequestAction = (payload: PutSoftTissueRequestPayload): PutSoftTissueRequest => ({
  type: PUT_SOFT_TISSUE_REQUEST,
  payload
});

// get hard tissue
export const getHardTissueRequestAction = (payload: GetHardTissueRequestPayload): GetHardTissueRequest => ({
  type: GET_HARD_TISSUE_REQUEST,
  payload
});

// put hard tissue
export const putHardTissueRequestAction = (payload: PutHardTissueRequestPayload): PutHardTissueRequest => ({
  type: PUT_HARD_TISSUE_REQUEST,
  payload
});

// get Odontogram
export const getOdontogramRequestAction = (payload: GetOdontogramRequestPayload): GetOdontogramRequest => ({
  type: GET_ODONTOGRAM_REQUEST,
  payload
});

// put Odontogram
export const putOdontogramRequestAction = (payload: PutOdontogramRequestPayload): PutOdontogramRequest => ({
  type: PUT_ODONTOGRAM_REQUEST,
  payload
});

// get Periogram
export const getPeriogramRequestAction = (payload: GetPeriogramRequestPayload): GetPeriogramRequest => ({
  type: GET_PERIOGRAM_REQUEST,
  payload
});

// put Periogram
export const putPeriogramRequestAction = (payload: PutPeriogramRequestPayload): PutPeriogramRequest => ({
  type: PUT_PERIOGRAM_REQUEST,
  payload
});

// get other form finding
export const getOtherFormFindingRequestAction = (payload: GetOtherFormFindingRequestPayload): GetOtherFormFindingRequest => ({
  type: GET_OTHER_FORM_FINDING_REQUEST,
  payload
});

// put other form finding
export const putOtherFormFindingRequestAction = (payload: PutOtherFormFindingRequestPayload): PutOtherFormFindingRequest => ({
  type: PUT_OTHER_FORM_FINDING_REQUEST,
  payload
});

// get Limited Exam
export const getLimitedExamRequestAction = (payload: GetLimitedExamRequestPayload): GetLimitedExamRequest => ({
  type: GET_LIMITED_EXAM_REQUEST,
  payload
});

// put Limited Exam
export const putLimitedExamRequestAction = (payload: PutLimitedExamRequestPayload): PutLimitedExamRequest => ({
  type: PUT_LIMITED_EXAM_REQUEST,
  payload
});

export const updateExamAPIDataAction = (payload: any): any => ({
  type: UPDATE_EXAM_API_DATA,
  payload
});
export const successExamAPIDataAction = (payload: any): any => ({
  type: SUCCESS_EXAM_API_DATA
});

export const failureExamAPIDataAction = (payload: any): any => ({
  type: FAILURE_EXAM_API_DATA
});
export const failurePutExamAPIDataAction = (payload: any): any => ({
  type: FAILURE_PUT_EXAM_API_DATA
});
export const examFindingDataCountAction = (payload: any): ExamFindingDataCountRequest => ({
  type: EXAM_GROUP_FINDING_DATA_COUNT,
  payload
});

export const syncPeriogramPmsDataRequestAction = (payload: any): any => ({
  type: SYNC_PERIOGRAM_PMS_DATA_REQUEST,
  payload
});

export const syncOdontogramPmsDataRequestAction = (payload: any): any => ({
  type: SYNC_ODONTOGRAM_PMS_DATA_REQUEST,
  payload
});

export const syncPeriogramPmsDataRequestSuccessAction = (
  payload: syncPeriogramPmsDataRequestSuccessPayload
): syncPeriogramPmsDataRequestSuccess => ({
  type: SYNC_PERIOGRAM_PMS_DATA_REQUEST_SUCCESS,
  payload
});

export const syncPeriogramPmsDataRequestFailureAction = (
  payload: syncPeriogramPmsDataRequestFailurePayload
): syncPeriogramPmsDataRequestFailure => ({
  type: SYNC_PERIOGRAM_PMS_DATA_REQUEST_FAILURE,
  payload
});

export const syncOdontogramPmsDataRequestSuccessAction = (
  payload: syncOdontogramPmsDataRequestSuccessPayload
): syncOdontogramPmsDataRequestSuccess => ({
  type: SYNC_ODONTOGRAM_PMS_DATA_REQUEST_SUCCESS,
  payload
});

export const syncOdontogramPmsDataRequestFailureAction = (
  payload: syncOdontogramPmsDataRequestFailurePayload
): syncOdontogramPmsDataRequestFailure => ({
  type: SYNC_ODONTOGRAM_PMS_DATA_REQUEST_FAILURE,
  payload
});
