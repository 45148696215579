import {
    STAFF_PROFILE_REQUEST, STAFF_PROFILE_CACHE_DATA_CLEAR_REQUEST,
    STAFF_PROFILE_REQUEST_SUCCESS,
    STAFF_PROFILE_REQUEST_FAILURE,
    STAFF_PROFILE_EDIT_REQUEST,
    STAFF_PROFILE_EDIT_REQUEST_FAILURE,
    STAFF_PROFILE_EDIT_REQUEST_SUCCESS
} from '../../../../ActionTypes/v1/dentist/dentistProfile'
import { StaffProfileActions, staffProfileState } from "../../../../types/dentistTypes";

const initialState: staffProfileState = {
    pending: false,
    data: null,
    error: null,

};

const staffProfileReducer = (state = initialState, action: StaffProfileActions) => {
    switch (action.type) {
        case STAFF_PROFILE_REQUEST:
            return {
                ...state,
                pending: true
            }

        case STAFF_PROFILE_CACHE_DATA_CLEAR_REQUEST:
            return {
                ...state,
                data: null
            }
        case STAFF_PROFILE_REQUEST_SUCCESS:
            return {
                ...state,
                pending: false,
                data: action.payload,
                error: null
            }

        case STAFF_PROFILE_REQUEST_FAILURE:
            return {
                ...state,
                pending: false,
                data: null,
                error: action.payload.error
            }
        case STAFF_PROFILE_EDIT_REQUEST:
            return {
                ...state,
                pending: true
            }
        case STAFF_PROFILE_EDIT_REQUEST_SUCCESS:
            return {
                ...state,
                pending: false,
                error: null,
                data: action.payload,
            }
        case STAFF_PROFILE_EDIT_REQUEST_FAILURE:
            return {
                ...state,
                pending: false,
                data: null,
                error: action.payload.error
            }
        default:
            return {
                ...state
            };
    }
}
export default staffProfileReducer