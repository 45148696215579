import {
  PATIENT_PROFILE_EDIT_REQUEST_V2,
  PATIENT_PROFILE_EDIT_REQUEST_FAILURE_V2,
  PATIENT_PROFILE_REQUEST_V2,
  PATIENT_PROFILE_CACHE_DATA_CLEAR_REQUEST_V2,
  PATIENT_PROFILE_REQUEST_FAILURE_V2,
  PATIENT_PROFILE_REQUEST_SUCCESS_V2,
  PATIENT_PROFILE_EDIT_REQUEST_SUCCESS_V2
} from '../../../ActionTypes/v2/patient/patientProfile';
import { patientProfileInterface, PatientProfileActions } from '../../../types/v2/patient/patientProfileActionTypes';

const initialState: patientProfileInterface = {
  pending: false,
  data: null,
  error: null,
  getProfileData: null
};
const patientProfileReducer = (state = initialState, action: PatientProfileActions) => {
  switch (action.type) {
    case PATIENT_PROFILE_REQUEST_V2:
      return {
        ...state,
        pending: true
      };

    case PATIENT_PROFILE_REQUEST_SUCCESS_V2:
      return {
        ...state,
        pending: false,
        getProfileData: action.payload,
        error: null
      };

    case PATIENT_PROFILE_REQUEST_FAILURE_V2:
      return {
        ...state,
        pending: false,
        getProfileData: null,
        error: action.payload.error
      };
    case PATIENT_PROFILE_CACHE_DATA_CLEAR_REQUEST_V2:
      return {
        ...state,
        getProfileData: null
      };
    case PATIENT_PROFILE_EDIT_REQUEST_V2:
      return {
        ...state,
        pending: true
      };
    case PATIENT_PROFILE_EDIT_REQUEST_SUCCESS_V2:
      return {
        ...state,
        pending: false,
        data: action.payload,
        error: null
      };

    case PATIENT_PROFILE_EDIT_REQUEST_FAILURE_V2:
      return {
        ...state,
        pending: false,
        data: null,
        error: action.payload.error
      };

    default:
      return {
        ...state
      };
  }
};
export default patientProfileReducer;
