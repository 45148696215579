import { call, put, takeEvery, all } from "redux-saga/effects";
import API from "../../../../../utils/api";
import { errorToaster } from '../../../../../utils/helper';
import {
    getInsuranceApprovalRequestSuccess,
    getInsuranceApprovalRequestFailure,
    getInsuranceApprovalChecklistRequestSuccess,
    getInsuranceApprovalChecklistRequestFailure,
    putInsuranceApprovalChecklistRequestSuccess,
    putInsuranceApprovalChecklistRequestFailure, putInsuranceApprovalRequestSuccess, putInsuranceApprovalRequestFailure, getInsuranceApprovalCacheDataClearRequest, getInsuranceApprovalChecklistCacheDataClearRequest
} from '../../../../actions/v1/dentist/insuranceApproval/insuranceApproval'
import {
    GET_INSURANCE_APPROVAL_CHECKLIST_REQUEST,
    GET_INSURANCE_APPROVAL_REQUEST, PUT_INSURANCE_APPROVAL_CHECKLIST_REQUEST, PUT_INSURANCE_APPROVAL_REQUEST
} from "../../../../ActionTypes/v1/dentist/insuranceApproval";


function* getInsuranceApprovalRequestSaga(payload: any): any {
    yield put(getInsuranceApprovalCacheDataClearRequest())
    try {
        // const response = yield API.get(`clinic/staff/get?staff_id=${payload.payload.staff_id}`)
        // TODO: Url endpoint need to change after BE complete
        const response = yield API.get(`/exam/dentist/treatment/plan?appointment_id=${payload?.payload?.appointmentID}`)
        if (response?.status === 200) {
            yield call(payload.payload.callback, response?.data)
            yield put(
                getInsuranceApprovalRequestSuccess({
                    data: response.data
                })
            );
        }
    } catch (e: any) {
        yield put(getInsuranceApprovalCacheDataClearRequest())
        yield put(
            getInsuranceApprovalRequestFailure({
                error: e?.detail
            })
        );
    }
}

function* putInsuranceApprovalRequestSaga(payload: any): any {
    try {
        const response = yield API.put(`/exam/dentist/treatment/create?appointment_id=${payload?.payload?.appointmentID}`, {
            treatmentData: payload.payload.treatmentData
        })
        if (response?.status === 200) {
            yield call(payload.payload.callback, response?.data)
            yield put(
                putInsuranceApprovalRequestSuccess({
                    data: response.data
                })
            );
        }
    } catch (e: any) {
        errorToaster(e?.detail)
        yield put(
            putInsuranceApprovalRequestFailure({
                error: e?.detail
            })
        );
    }
}

function* getInsuranceApprovalChecklistRequestSaga(payload: any): any {
    yield put(getInsuranceApprovalChecklistCacheDataClearRequest())
    try {
        const response = yield API.get(`/exam/dentist/checklist/${payload?.payload?.appointmentID}`)
        if (response?.status === 200) {
            yield call(payload.payload.callback, response?.data)
            yield put(
                getInsuranceApprovalChecklistRequestSuccess({
                    data: response.data
                })
            );
        }
    } catch (e: any) {
        yield put(getInsuranceApprovalChecklistCacheDataClearRequest())
        yield put(
            getInsuranceApprovalChecklistRequestFailure({
                error: e?.detail
            })
        );
    }
}

function* putInsuranceApprovalChecklistRequestSaga(payload: any): any {
    try {
        const response = yield API.put(`/exam/dentist/checklist/create?appointment_id=${payload.payload.appointmentID}`, payload.payload.checklist)
        if (response.status === 200) {
            yield put(
                putInsuranceApprovalChecklistRequestSuccess({
                    data: response.data
                })
            );
        }
    } catch (e: any) {
        errorToaster(e?.detail)
        yield put(
            putInsuranceApprovalChecklistRequestFailure({
                error: e?.detail
            })
        );
    }
}

function* insuranceApprovalSaga() {
    yield takeEvery(GET_INSURANCE_APPROVAL_REQUEST, getInsuranceApprovalRequestSaga);
}
function* putInsuranceApprovalSaga() {
    yield takeEvery(PUT_INSURANCE_APPROVAL_REQUEST, putInsuranceApprovalRequestSaga);
}

function* insuranceApprovalChecklistSaga() {
    yield takeEvery(GET_INSURANCE_APPROVAL_CHECKLIST_REQUEST, getInsuranceApprovalChecklistRequestSaga);
}

function* putInsuranceApprovalChecklistSaga() {
    yield takeEvery(PUT_INSURANCE_APPROVAL_CHECKLIST_REQUEST, putInsuranceApprovalChecklistRequestSaga);
}

export default function* rootSaga() {
    yield all([
        insuranceApprovalSaga(),
        insuranceApprovalChecklistSaga(),
        putInsuranceApprovalChecklistSaga(),
        putInsuranceApprovalSaga()
    ])
}
