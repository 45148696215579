import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useNavigate } from 'react-router-dom';
import theme from "../../../../theme";
import {
    exceptThisSymbols,
    hideMiddleDigits,
    patientProfileData
} from "../../../../utils/helper";
import { updateOtpRequest, updateOtpValidationRequest } from "../../../../app/actions/v2/common/mobileVerificationAction";
import { otpValidator } from "../../../../validation";
import FullScreenLoader from "../../../v1/Common/FullScreenLoader";
import PopupComponent from '../../common/PopupComponent';
import TextBox from '../../common/TextBox';
import { RootState } from '../../../../app/reducers/v2/rootReducer';
import { multipleNumberPopUp } from '../../../../app/actions/v2/patient/authAction';
import { patientEmailSendOtpRequest, patientEmailVerifyOtpRequest } from '../../../../app/actions/v2/patient/emailAndQrCodeFormAction';

type Props = {
    open: boolean
    setOpen: any
    selectedOption?: string
    isPatient?: boolean | undefined
}
const MobileVerificationPopUp = (props: Props) => {
    const { open, setOpen, selectedOption, isPatient } = props;
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [minutes, setMinutes] = useState(2);
    const [seconds, setSeconds] = useState(0);
    const { otp, otpError } = useSelector((state: any) => state?.mobileVerification)
    const { pending } = useSelector((state: any) => state?.otpReducer)

    const {
        patientAuthV2: { multipleNumberData },
    } = useSelector((state: RootState) => state) as any;

    const selectedMobileData = multipleNumberData?.find((item: any) => item?.id === selectedOption)

    useEffect(() => {
        if (patientProfileData?.mobile_number) {
            resendOtp();
        }
    }, [patientProfileData?.mobile_number]);

    useEffect(() => {
        let otpInterval: any;

        if (minutes > 0 || seconds > 0) {
            otpInterval = setInterval(() => {
                if (seconds === 0) {
                    if (minutes > 0) {
                        setMinutes((prevMinutes) => prevMinutes - 1);
                        setSeconds(59);
                    }
                } else {
                    setSeconds((prevSeconds) => prevSeconds - 1);
                }
            }, 1000);
        }

        return () => {
            clearInterval(otpInterval);
        };
    }, [minutes, seconds]);

    const handleChange = (event: any) => {
        const { value } = event.target
        dispatch(updateOtpRequest(value))
        if (otpError?.['otp']) {
            dispatch(
                updateOtpValidationRequest({
                    ...otpError,
                    ['otp']: '',
                })
            )
        }
    }

    const resendOtp = () => {
        dispatch(
            patientEmailSendOtpRequest({
                profile_id: selectedMobileData?.id,
                mobile_number: selectedMobileData?.mobile_number,
                account_type: "patient",
                callback: () => {
                    setMinutes(2)
                    setSeconds(0)
                },
            })
        );
    };

    const submitHandler = (e: any) => {
        e.preventDefault();
        let { errors, isValid } = otpValidator({ otp: otp });
        if (isValid) {
            const payload = {
                otp: otp?.trim(),
                profile_id: selectedMobileData?.id,
                mobile_number: selectedMobileData?.mobile_number,
                callback: (data: any) => {
                    const opt = null as any
                    dispatch(updateOtpRequest(opt))
                    navigate({ pathname: `/patient/login` })
                    dispatch(
                        updateOtpValidationRequest({
                            ...otpError,
                            ['otp']: '',
                        })
                    )
                    setOpen(false)
                }
            } as any;

            dispatch(
                patientEmailVerifyOtpRequest(payload)
            );
        } else {
            const errorPayload = errors as any
            dispatch(updateOtpValidationRequest(errorPayload))
        }
    };

    return (
        <React.Fragment>
            {pending && <FullScreenLoader />}
            <PopupComponent
                heading={"Mobile Verification"}
                width={'530px'}
                open={open}
                saveBtnText={'Verify'}
                handleClose={() => {
                    setOpen(false)
                    dispatch(multipleNumberPopUp({
                        multipleNumberPopUp: false,
                        multipleNumberData: null
                    }))
                    const opt = null as any
                    dispatch(updateOtpRequest(opt))
                }}
                cancelBtnText={'Cancel'}
                handleSave={submitHandler}
                isPatient={isPatient}
                footerAction headerAction isOraqIcon
            >
                <Box mt={1}>
                    <Typography className={'fw-regular f-14'} color={theme.palette.common.black50} mb={2}>
                        {`OTP will be sent to the following mobile number: ${hideMiddleDigits(selectedMobileData?.mobile_number)}`}
                    </Typography>

                    <TextBox
                        isPatient={isPatient}
                        label={'OTP'}
                        required
                        isPadding
                        inputProps={{
                            name: "otp",
                            maxLength: 6,
                            onWheel: (e: any) => {
                                e.target.blur()
                            },
                            onKeyDown: (e: any) => exceptThisSymbols.includes(e.key) && e.preventDefault(),
                            onChange: (e: any) => {
                                handleChange(e)
                            },
                            value: otp,
                            error: otpError?.otp,
                            helperText: otpError?.otp,
                            fullWidth: true,
                        }}
                    />
                    <Box
                        display={"flex"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                    >
                        <Typography className={'fw-regular'}
                            color={isPatient ? theme.palette.secondary.main : theme.palette.v2.primary.main}>
                            {(minutes || seconds) <= 0 ? "" : `${minutes <= 9 ? `0${minutes}` : minutes}:${seconds <= 9 ? `0${seconds}` : seconds}`}
                        </Typography>

                        <Button
                            variant={"text"}
                            sx={{
                                color: `${isPatient ? theme.palette.secondary.main : theme.palette.v2.primary.main}`,
                                opacity: (minutes || seconds) > 0 ? 0.5 : 1,
                                "&:hover": { backgroundColor: 'transparent' }
                            }}
                            className={'fw-regular'}
                            onClick={() => resendOtp()}
                            disabled={(minutes || seconds) > 0}
                        >
                            Resend OTP
                        </Button>
                    </Box>

                </Box>
            </PopupComponent>
        </React.Fragment>
    )
}

export default MobileVerificationPopUp;