import {UPDATE_ASSISTANT_EXISTING_TREATMENT, UPDATE_ASSISTANT_EXISTING_TREATMENT_FAILURE,
    ADD_ASSISTANT_EXISTING_TREATMENT_FINDING_ROW,
    CHANGE_ASSISTANT_EXISTING_TREATMENT_FINDING,
    DELETE_ASSISTANT_EXISTING_TREATMENT_FINDING_ROW
} from "../../../../ActionTypes/v1/dentist/clinicalExamAssistant"
import {ExistingTreatmentAssistantInterface} from "../../../../types/dentistTypes";

export const updateExistingTreatmentRequest = (
    payload: ExistingTreatmentAssistantInterface
): ExistingTreatmentAssistantInterface => ({
    type: UPDATE_ASSISTANT_EXISTING_TREATMENT,
    payload
}) as any;

export const updateExistingTreatmentRequestFailure = ():any => ({
    type: UPDATE_ASSISTANT_EXISTING_TREATMENT_FAILURE
}) as any;

export const addExistingTreatmentFindingRowAction = (payload: string): any => ({
    type: ADD_ASSISTANT_EXISTING_TREATMENT_FINDING_ROW,
    payload
}) as any;

export const changeExistingTreatmentFindingAction = (payload: any): any => ({
    type: CHANGE_ASSISTANT_EXISTING_TREATMENT_FINDING,
    payload
}) as any;

export const deleteExistingTreatmentFindingRowAction = (payload: any): any => ({
    type: DELETE_ASSISTANT_EXISTING_TREATMENT_FINDING_ROW,
    payload
}) as any;