export const PATIENT_PROFILE_REQUEST = "PATIENT_PROFILE_REQUEST";
export const PATIENT_PROFILE_CACHE_DATA_CLEAR_REQUEST =
  "PATIENT_PROFILE_CACHE_DATA_CLEAR_REQUEST";
export const PATIENT_PROFILE_REQUEST_SUCCESS =
  "PATIENT_PROFILE_REQUEST_SUCCESS";
export const PATIENT_PROFILE_REQUEST_FAILURE =
  "PATIENT_PROFILE_REQUEST_FAILURE";

// Edit Patient Profile
export const PATIENT_PROFILE_EDIT_REQUEST = "PATIENT_PROFILE_EDIT_REQUEST";
export const PATIENT_PROFILE_EDIT_REQUEST_FAILURE = "PATIENT_PROFILE_EDIT_REQUEST_FAILURE";
export const PATIENT_PROFILE_EDIT_REQUEST_SUCCESS = "PATIENT_PROFILE_EDIT_REQUEST_SUCCESS";

//Check PreIntake
export const CHECK_PRE_INTAKE_REQUEST = "CHECK_PRE_INTAKE_REQUEST";
export const CHECK_PRE_INTAKE_REQUEST_SUCCESS =
  "CHECK_PRE_INTAKE_REQUEST_SUCCESS";
export const CHECK_PRE_INTAKE_REQUEST_FAILURE =
  "CHECK_PRE_INTAKE_REQUEST_FAILURE";
