import { call, put, takeEvery, all } from "redux-saga/effects";
import API from "../../../../../utils/api";
import {
  updateAppointmentStepRequestFailure,
  updateAppointmentStepRequestSuccess,
  getAppointmentTypeRequestSuccess,
  getAppointmentTypeRequestFailure,
  getClinicalExamNotesRequestSuccess,
  getClinicalExamNotesRequestFailure
} from "../../../../actions/v1/dentist/clinicalExam/clinicalExam";
import { UPDATE_APPOINTMENT_STEP_REQUEST, GET_APPOINTMENT_TYPE_REQUEST, GET_CLINICAL_EXAM_NOTES_REQUEST } from "../../../../ActionTypes/v1/dentist/clinicalExam";

function* updateAppointmentStepRequestSaga(payload: any): any {
  try {
    const response = yield API.put(
      `/appointments/steps?appointmentID=${payload.payload.appointmentID}`,
      payload.payload
    );
    if (payload.payload.callback) {
      payload.payload.callback(response.data);
    }
    yield put(
      updateAppointmentStepRequestSuccess({
        data: response,
      })
    );
  } catch (e: any) {
    yield put(
      updateAppointmentStepRequestFailure({
        error: e?.detail,
      })
    );
  }
}

function* getAppointmentRequestSaga(payload:any): any {
  try {
    const response = yield API.get(`/appointments/${payload.payload.appointment_id}/appointmentType`);
    yield call(payload.payload.callback, response.data);
    yield put(getAppointmentTypeRequestSuccess({
      data: response.data
    }))
  } catch(e:any) {
    yield put(getAppointmentTypeRequestFailure({
      error: e?.detail
    }))
  }
}

function* getClinicalExamNotesRequestSaga(payload:any): any {
  try {
    const response = yield API.get(`/exam/dentist/appointment/notes?appointmentID=${payload?.payload?.appointment_id}`);
    yield call(payload.payload.callback, response.data);
    yield put(getClinicalExamNotesRequestSuccess({
      data: response.data
    }))
  } catch(e:any) {
    yield put(getClinicalExamNotesRequestFailure({
      error: e?.detail
    }))
  }
}

function* updateAppointmentStepSaga() {
  yield takeEvery(
    UPDATE_APPOINTMENT_STEP_REQUEST,
    updateAppointmentStepRequestSaga
  );
}
function* getAppointmentTypeSaga() {
  yield takeEvery(GET_APPOINTMENT_TYPE_REQUEST, getAppointmentRequestSaga)
}
function* getClinicalExamNotesSaga() {
  yield takeEvery(GET_CLINICAL_EXAM_NOTES_REQUEST, getClinicalExamNotesRequestSaga)
}
export default function* rootSaga() {
  yield all([updateAppointmentStepSaga(), getAppointmentTypeSaga(), getClinicalExamNotesSaga()]);
}
