import {
    CHANGE_PASSWORD_REQUEST,
    CHANGE_PASSWORD_REQUEST_FAILURE,
    CHANGE_PASSWORD_REQUEST_SUCCESS,
    CREATE_PASSWORD_REQUEST,
    CREATE_PASSWORD_REQUEST_FAILURE,
    CREATE_PASSWORD_REQUEST_SUCCESS,
    FORGOT_PASSWORD_REQUEST,
    FORGOT_PASSWORD_REQUEST_FAILURE,
    FORGOT_PASSWORD_REQUEST_SUCCESS,
    LOGIN_REQUEST,
    LOGIN_REQUEST_FAILURE,
    LOGIN_REQUEST_SUCCESS,
    RECOVER_PASSWORD_REQUEST,
    RECOVER_PASSWORD_REQUEST_FAILURE,
    RECOVER_PASSWORD_REQUEST_SUCCESS,
} from "../../../../ActionTypes/v1/patient/auth";
import { authActions, authState } from "../../../../types/patientTypes";

const initialState: authState = {
    pending: false,
    data: null,
    error: null
};

const authReducer = (state = initialState, action: authActions) => {
    switch (action.type) {
        case LOGIN_REQUEST:
            return {
                ...state,
                pending: true
            };
        case LOGIN_REQUEST_SUCCESS:
            return {
                ...state,
                pending: false,
                data: action.payload,
                error: null
            };
        case LOGIN_REQUEST_FAILURE:
            return {
                ...state,
                pending: false,
                data: null,
                error: action?.payload?.error
            };


        case CHANGE_PASSWORD_REQUEST:
            return {
                ...state,
                pending: true
            };

        case CHANGE_PASSWORD_REQUEST_SUCCESS:
            return {
                ...state,
                pending: false,
                data: action.payload,
                error: null
            };

        case CHANGE_PASSWORD_REQUEST_FAILURE:
            return {
                ...state,
                pending: false,
                data: null,
                error: action.payload.error
            };


        case FORGOT_PASSWORD_REQUEST:
            return {
                ...state,
                pending: true
            };
        case FORGOT_PASSWORD_REQUEST_SUCCESS:
            return {
                ...state,
                pending: false,
                data: action.payload,
                error: null
            };
        case FORGOT_PASSWORD_REQUEST_FAILURE:
            return {
                ...state,
                pending: false,
                data: null,
                error: action.payload.error
            };
            

        case RECOVER_PASSWORD_REQUEST:
            return {
                ...state,
                pending: true
            };
        case RECOVER_PASSWORD_REQUEST_SUCCESS:
            return {
                ...state,
                pending: false,
                data: action.payload,
                error: null
            };
        case RECOVER_PASSWORD_REQUEST_FAILURE:
            return {
                ...state,
                pending: false,
                data: null,
                error: action.payload.error
            };
        case CREATE_PASSWORD_REQUEST:
            return {
                ...state,
                pending: true
            };
        case CREATE_PASSWORD_REQUEST_SUCCESS:
            return {
                ...state,
                pending: false,
                data: action.payload,
                error: null
            };
        case CREATE_PASSWORD_REQUEST_FAILURE:
            return {
                ...state,
                pending: false,
                data: null,
                error: action.payload.error
            };
        default:
            return {
                ...state
            };
    }
};
export default authReducer
