import {
    PATIENT_EMAIL_MOBILE_DATA,
    PATIENT_EMAIL_MOBILE_ERROR,
    PATIENT_EMAIL_SEND_OTP_REQUEST,
    PATIENT_EMAIL_SEND_OTP_REQUEST_FAILURE,
    PATIENT_EMAIL_SEND_OTP_REQUEST_SUCCESS,
    PATIENT_EMAIL_VERIFY_OTP_REQUEST,
    PATIENT_EMAIL_VERIFY_OTP_REQUEST_FAILURE,
    PATIENT_EMAIL_VERIFY_OTP_REQUEST_SUCCESS,
    PATIENT_QR_CODE_DATA,
    PATIENT_QR_CODE_ERROR,
    PATIENT_QR_SUBMIT_DATA_REQUEST,
    PATIENT_QR_SUBMIT_DATA_REQUEST_FAILURE,
    PATIENT_QR_SUBMIT_DATA_REQUEST_SUCCESS,
} from "../../../ActionTypes/v2/patient/emailAndQrCodeForm";
import {
    EmailAndQrCodeFormActions,
    emailAndQrCodeFormInterface
} from "../../../types/v2/patient/emailAndQrCodeFormActionTypes";

const initialState: emailAndQrCodeFormInterface = {
    emailMobileData: {
        otp: ''
    },
    emailMobileError: null,
    qrCodeFormData: {
        accessCode: '',
        mobile: '',
        dob: ''
    },
    qrCodeFormError: null,
    otpLoader: false,
    otpData: null,
    otpError: null,
    mobile: null,
    qrCodeLoader: false,
    qrCodeData: null,
    qrCodeError: null,
};
const emailAndQrCodeFormReducer = (state = initialState, action: EmailAndQrCodeFormActions) => {
    switch (action.type) {
        case PATIENT_EMAIL_MOBILE_DATA:
            return {
                ...state,
                emailMobileData: action.payload
            };

        case PATIENT_EMAIL_MOBILE_ERROR:
            return {
                ...state,
                emailMobileError: action.payload
            };
        case PATIENT_QR_CODE_DATA:
            return {
                ...state,
                qrCodeFormData: action.payload
            };

        case PATIENT_QR_CODE_ERROR:
            return {
                ...state,
                qrCodeFormError: action.payload
            };

        //send OTP
        case PATIENT_EMAIL_SEND_OTP_REQUEST:
            return {
                ...state,
                otpLoader: true
            };

        case PATIENT_EMAIL_SEND_OTP_REQUEST_SUCCESS:
            return {
                ...state,
                otpLoader: false,
                mobile: action?.payload,
                otpError: null
            };
        case PATIENT_EMAIL_SEND_OTP_REQUEST_FAILURE:
            return {
                ...state,
                otpLoader: false,
                mobile: null,
                otpError: action?.payload
            };

        //verify OTP
        case PATIENT_EMAIL_VERIFY_OTP_REQUEST:
            return {
                ...state,
                otpLoader: true
            };

        case PATIENT_EMAIL_VERIFY_OTP_REQUEST_SUCCESS:
            return {
                ...state,
                otpLoader: false,
                otpData: action?.payload,
                otpError: null
            };
        case PATIENT_EMAIL_VERIFY_OTP_REQUEST_FAILURE:
            return {
                ...state,
                otpLoader: false,
                otpError: action?.payload
            };

        //Qr Code Form Submit
        case PATIENT_QR_SUBMIT_DATA_REQUEST:
            return {
                ...state,
                qrCodeLoader: true
            };

        case PATIENT_QR_SUBMIT_DATA_REQUEST_SUCCESS:
            return {
                ...state,
                qrCodeLoader: false,
                qrCodeData: action?.payload,
                qrCodeError: null
            };
        case PATIENT_QR_SUBMIT_DATA_REQUEST_FAILURE:
            return {
                ...state,
                qrCodeLoader: false,
                qrCodeError: action?.payload
            };
        default:
            return {
                ...state
            };
    }
};
export default emailAndQrCodeFormReducer;