import {
  CREATE_PASSWORD_V2_REQUEST,
  CREATE_PASSWORD_V2_REQUEST_FAILURE,
  CREATE_PASSWORD_V2_REQUEST_SUCCESS
} from '../../../ActionTypes/v2/auth';
import { createPasswordActions, createPasswordInterface } from '../../../types/v2/authActionTypes/createPasswordActionTypes';

const initialState: createPasswordInterface = {
  createPasswordLoader: false,
  createPasswordData: null,
  createPasswordError: null
};

const createPasswordReducer = (state = initialState, action: createPasswordActions) => {
  switch (action.type) {
    case CREATE_PASSWORD_V2_REQUEST:
      return {
        ...state,
        createPasswordLoader: true
      };
    case CREATE_PASSWORD_V2_REQUEST_SUCCESS:
      return {
        ...state,
        createPasswordLoader: false,
        createPasswordData: action.payload,
        createPasswordError: null
      };
    case CREATE_PASSWORD_V2_REQUEST_FAILURE:
      return {
        ...state,
        createPasswordLoader: false,
        createPasswordData: null,
        createPasswordError: action.payload.createPasswordError
      };
    default:
      return {
        ...state
      };
  }
};
export default createPasswordReducer;
