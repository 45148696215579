import {faGauge, faTableColumns} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Box} from '@mui/material';
import React from 'react'
import theme from '../../../../theme';
import {useDispatch} from "react-redux";
import {
    changeRiskDialsViewRequestAction
} from "../../../../app/actions/v2/dentist/clinicalExam/clinicalExamFilterAction";

type Props = {
    openSpeedometer?: boolean
    setOpenSpeedometer?: any
    color?: string
    isPatient?: boolean
    fromClinical?: boolean | undefined
}
const ChangeRiskDialsView = (props: Props) => {
    const {palette} = theme
    const dispatch = useDispatch()
    const {openSpeedometer, setOpenSpeedometer, color, fromClinical} = props

    return (
        <Box display={'flex'} alignItems={'center'} mr={'20px'}>
            <Box sx={{
                backgroundColor: openSpeedometer ? fromClinical ? palette.background.secondaryPaper : palette.common.white : color,
                cursor: 'pointer'
            }}
                 height={'32px'} width={'32px'} margin={'8px 0'} borderRadius={'8px 0 0 8px'}
                 display={'flex'} alignItems={'center'} justifyContent={'center'}
                 onClick={(e) => {
                     if (fromClinical) {
                         dispatch(changeRiskDialsViewRequestAction(false))
                     } else {
                         setOpenSpeedometer(false)
                     }
                     e?.preventDefault();
                     e?.stopPropagation();
                 }}>
                <FontAwesomeIcon
                    icon={faTableColumns}
                    className={`f-18`}
                    color={openSpeedometer ? palette.text.darkGrey : palette.common.white}
                />
            </Box>
            <Box sx={{
                backgroundColor: !openSpeedometer ? fromClinical ? palette.background.secondaryPaper : palette.common.white : color,
                cursor: 'pointer'
            }}
                 height={'32px'} width={'32px'} margin={'8px 0'} borderRadius={'0 8px 8px 0'}
                 display={'flex'} alignItems={'center'} justifyContent={'center'}
                 onClick={(e) => {
                     if (fromClinical) {
                         dispatch(changeRiskDialsViewRequestAction(true))
                     } else {
                         setOpenSpeedometer(true)
                     }
                     e?.preventDefault();
                     e?.stopPropagation();
                 }}>
                <FontAwesomeIcon
                    icon={faGauge}
                    className={`f-18`}
                    color={!openSpeedometer ? palette.text.darkGrey : palette.common.white}
                />
            </Box>
        </Box>
    )
}

export default ChangeRiskDialsView