import {
    DASHBOARD_APPOINTMENT_STAFF_REQUEST,
    DASHBOARD_APPOINTMENT_STAFF_SUCCESS,
    DASHBOARD_APPOINTMENT_STAFF_FAILURE,
    DASHBOARD_APPOINTMENT_STAFF_CACHE_DATA_CLEAR_REQUEST,
    DASHBOARD_APPOINTMENT_STAFF_COUNT_REQUEST,
    DASHBOARD_APPOINTMENT_STAFF_COUNT_SUCCESS,
    DASHBOARD_APPOINTMENT_STAFF_COUNT_FAILURE,
    DASHBOARD_APPOINTMENT_STAFF_COUNT_CACHE_DATA_CLEAR_REQUEST,
    SET_DESCENDING_ASCENDING,
} from '../../../ActionTypes/v2/dentist/dashboard'
import {DashboardTypeActions, DashboardTypeState} from '../../../types/v2/dentist/appointment';

const initialState: DashboardTypeState = {
    tableLoader: false,
    tableData: [],
    tableMeta: null,
    tableError: null,

    countLoader: false,
    countData: null,
    countMeta: null,
    countError: null,

    sortBy: '',
    desc: null,
};

const dashboardReducer = (state = initialState, action: DashboardTypeActions) => {
    switch (action.type) {
        // dashboard APPOINTMENT staff
        case DASHBOARD_APPOINTMENT_STAFF_REQUEST:
            return {
                ...state,
                tableLoader: true,
            }
        case DASHBOARD_APPOINTMENT_STAFF_CACHE_DATA_CLEAR_REQUEST:
            return {
                ...state,
                tableData: [],
                tableMeta: null
            }
        case DASHBOARD_APPOINTMENT_STAFF_SUCCESS:
            const existingIds = state.tableData?.map((item:any) => item.appointmentID) || [];
            const newData = action?.payload?.tableData?.filter(
                (item:any) => !existingIds.includes(item.appointmentID)
            ) || [];
            return {
                ...state,
                tableLoader: false,
                tableData: [
                    ...state.tableData || [], // Keep the existing data
                    ...newData || [], // Append new data
                ],
                tableMeta: action.payload?.tableMeta,
                tableError: null
            }
        case DASHBOARD_APPOINTMENT_STAFF_FAILURE:
            return {
                ...state,
                tableLoader: false,
                tableData: [],
                tableMeta: null,
                tableError: action.payload
            }
        // dashboard APPOINTMENT count staff
        case DASHBOARD_APPOINTMENT_STAFF_COUNT_REQUEST:
            return {
                ...state,
                countLoader: true,
            }
        case DASHBOARD_APPOINTMENT_STAFF_COUNT_CACHE_DATA_CLEAR_REQUEST:
            return {
                ...state,
                countData: null
            }
        case DASHBOARD_APPOINTMENT_STAFF_COUNT_SUCCESS:
            return {
                ...state,
                countLoader: false,
                countData: action.payload?.countData,
                countMeta: action.payload?.countMeta,
                countError: null
            }
        case DASHBOARD_APPOINTMENT_STAFF_COUNT_FAILURE:
            return {
                ...state,
                countLoader: false,
                countData: null,
                countError: action.payload
            }
        case SET_DESCENDING_ASCENDING:
            return {
                ...state,
                desc: action?.payload?.desc,
                sortBy: action?.payload?.sortBy,
            }
        default:
            return {
                ...state
            };
    }
}

export default dashboardReducer
