import {
  GET_READINESS_BAR_REQUEST,
  GET_READINESS_BAR_REQUEST_FAILURE,
  GET_READINESS_BAR_REQUEST_SUCCESS,
  PUT_READINESS_BAR_REQUEST,
  PUT_READINESS_BAR_REQUEST_FAILURE,
  PUT_READINESS_BAR_REQUEST_SUCCESS,
  CHANGE_READINESS_BAR_REQUEST
} from '../../../../../ActionTypes/v1/dentist/readinessBar';
import { ReadinessBarInterface, ReadinessBarAction } from '../../../../../types/dentistTypes';

const initialState: ReadinessBarInterface = {
  readinessBarData: null,
  readinessBarLoader: false,
  readinessBarError: null,
  score: null,
  is_selected: false,
  additional_patient_comments: null
};

const readinessBarReducer = (state = initialState, action: ReadinessBarAction) => {
  switch (action.type) {
    case CHANGE_READINESS_BAR_REQUEST:
      return {
        ...state,
        ...action.payload
      };

    case GET_READINESS_BAR_REQUEST:
      return {
        ...state,
        readinessBarLoader: true
      };
    case GET_READINESS_BAR_REQUEST_SUCCESS:
      return {
        ...state,
        readinessBarLoader: false,
        readinessBarData: action.payload,
        readinessBarError: null
      };
    case GET_READINESS_BAR_REQUEST_FAILURE:
      return {
        ...state,
        readinessBarLoader: false,
        readinessBarData: null,
        readinessBarError: action.payload
      };
    case PUT_READINESS_BAR_REQUEST:
      return {
        ...state,
        readinessBarLoader: true
      };
    case PUT_READINESS_BAR_REQUEST_SUCCESS:
      return {
        ...state,
        readinessBarLoader: false,
        readinessBarData: action.payload,
        readinessBarError: null
      };
    case PUT_READINESS_BAR_REQUEST_FAILURE:
      return {
        ...state,
        readinessBarLoader: false,
        readinessBarData: null,
        readinessBarError: action.payload
      };
    default:
      return {
        ...state
      };
  }
};

export default readinessBarReducer;
