import { all, call, put, takeEvery } from "redux-saga/effects"
import {
    GET_SUMMARIZATION_DATA_REQUEST, UPDATE_SUMMARIZATION_DATA_REQUEST
} from "../../../ActionTypes/v2/dentist/qSummarization"
import api from "../../../../utils/api"
import { getSummarizationFailure, getSummarizationSuccess, updateSummarizationFailure, updateSummarizationSuccess } from "../../../actions/v2/dentist/qSummarizationActions"
import { errorToaster, successToaster } from "../../../../utils/helper"

function* getSummarizationDataRequest(payload: any): any {
    try {
        const response = yield api.get(`/v2/q-summarization/${payload?.payload?.appointment_id}`)
        yield put(
            getSummarizationSuccess(response?.data?.detail)
        )
        if (payload?.payload?.callback) {
            yield call(payload?.payload?.callback, response?.data?.detail)
        }

    } catch (e: any) {
        errorToaster(e?.message)
        yield put(
            getSummarizationFailure(e?.message)
        )
    }
}

function* updateSummarizationDataRequest(payload: any): any {
    try {
        const response = yield api.post(`/v2/q-summarization/add_data`, payload?.payload?.updatedData)
        yield put(
            updateSummarizationSuccess(response?.data?.detail)
        )
        if (payload?.payload?.callback) {
            yield call(payload?.payload?.callback, response?.data?.detail)
        }

        successToaster(response?.data?.meta?.message);

    } catch (e: any) {
        errorToaster(e?.message)
        yield put(
            updateSummarizationFailure(e?.message)
        )
    }
}

function* getSummarizationDataSaga() {
    yield takeEvery(GET_SUMMARIZATION_DATA_REQUEST, getSummarizationDataRequest)
}

function* updateSummarizationDataSaga() {
    yield takeEvery(UPDATE_SUMMARIZATION_DATA_REQUEST, updateSummarizationDataRequest)
}

export default function* qSummarizationSaga() {
    yield all([
        getSummarizationDataSaga(),
        updateSummarizationDataSaga(),
    ])
}