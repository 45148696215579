import {Button, Grid, Paper, Typography} from '@mui/material';
import {Box} from '@mui/system';
import {makeStyles} from '@mui/styles';
import React from 'react';
import {useLocation, useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import theme from '../../../../../theme';
import UploadPhotosXray from '../UploadPhotosXray';
import {
    getPhotosRequestAction,
    uploadPhotosModelAction
} from '../../../../../app/actions/v2/dentist/clinicalExam/exam/uploadPhotosXrayAction';
import {disabledField, photo} from '../../../../../utils/v2/clinicalExamConstant';

const useStyles = makeStyles((theme: any) => ({
    actionButton: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        border: 'none',
        height: '40px',
        width: '140px',
        textAlign: 'center',
        marginRight: '16px',
        '&:hover': {
            backgroundColor: theme.palette.primary.main
        }
    }
}));
const ReloadPage = () => {
    const {palette} = theme;
    const classes = useStyles();
    const takeFromUrl = useParams();
    const location = useLocation()
    const dispatch = useDispatch();
    const isWellnessProfile = location?.pathname?.includes('/v2/dentist/wellness-profile/')

    const {
        clinicalExamFilters: {findingType, openSpeedometerClinical},
        appointmentsReducer: {appointmentDetails},
        wellnessProfileFilters: {
            wellnessProfileStep, wellnessProfileData
        },
    } = useSelector((state: any) => state);

    const tabData = isWellnessProfile ? wellnessProfileStep : findingType
    const isPhoto = tabData === photo
    const openUploadPhotosModel = () => {
        dispatch(uploadPhotosModelAction(true));
    };

    const reloadPage = () => {
        dispatch(
            getPhotosRequestAction({
                patient_id: takeFromUrl.patientId,
                appointment_id: takeFromUrl?.appointmentId,
                image_type: isPhoto ? 'image' : 'xray'
            })
        );
    }


    return (
        <React.Fragment>
            <Paper
                sx={{
                    background: isWellnessProfile ? theme.palette.background.secondaryPaper : theme.palette.common.white,
                    height: `calc(100vh - ${openSpeedometerClinical ? (isWellnessProfile ? "132px" : '488px') : '337px'})`,
                    width: '100%'
                }}
                className='d-flex-all-center'
            >
                <Grid container>
                    <Grid item xs={12} className='d-flex align-center flex-col'>
                        <Typography
                            className='fw-regular f-18 f-w-500 lh-28 labelColor'
                        >
                            This patient doesn’t have any {isPhoto ? "photo" : "x-ray"} yet.
                        </Typography>
                        <Typography
                            className='fw-regular f-16 f-w-400 lh-24'
                            sx={{
                                paddingTop: '16px',
                                paddingBottom: '25px',
                                color: palette.text.darkGrey
                            }}
                        >
                            {isPhoto ? 'Reload this page to fetch them or upload the photos manually.' : 'Please use office PMS to capture x-rays. Upload manually here, once completed'}
                        </Typography>
                        <Box>
                            <Button onClick={reloadPage} className='fw-regular f-14 f-w-500 button border-radius-4'
                                    classes={{root: classes.actionButton}}>
                                Reload page
                            </Button>
                            <Button
                                disabled={disabledField(appointmentDetails) || wellnessProfileData?.is_wellness_locked}
                                variant={'outlined'}
                                className={`fw-regular`}
                                sx={{
                                    height: '40px',
                                    width: '140px',
                                    borderColor: palette.v2.primary.main,
                                    color: palette.v2.primary.main
                                }}
                                onClick={openUploadPhotosModel}
                            >
                                Upload {isPhoto ? "photos" : "x-rays"}
                            </Button>
                        </Box>
                    </Grid>

                </Grid>
            </Paper>
            <UploadPhotosXray/>
        </React.Fragment>
    );
};

export default ReloadPage;
