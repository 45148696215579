//get summarization data
export const GET_SUMMARIZATION_DATA_REQUEST = 'GET_SUMMARIZATION_DATA_REQUEST';
export const GET_SUMMARIZATION_DATA_REQUEST_SUCCESS = 'GET_SUMMARIZATION_DATA_REQUEST_SUCCESS';
export const GET_SUMMARIZATION_DATA_REQUEST_FAILURE = 'GET_SUMMARIZATION_DATA_REQUEST_FAILURE';

export const UPDATE_SUMMARIZATION_DATA_LOCALLY = 'UPDATE_SUMMARIZATION_DATA_LOCALLY';

//update summarization data
export const UPDATE_SUMMARIZATION_DATA_REQUEST = 'UPDATE_SUMMARIZATION_DATA_REQUEST';
export const UPDATE_SUMMARIZATION_DATA_REQUEST_SUCCESS = 'UPDATE_SUMMARIZATION_DATA_REQUEST_SUCCESS';
export const UPDATE_SUMMARIZATION_DATA_REQUEST_FAILURE = 'UPDATE_SUMMARIZATION_DATA_REQUEST_FAILURE';