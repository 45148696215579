import { all, call, put, takeEvery } from "redux-saga/effects";
import API from "../../../../../utils/api";
import {
  getPeriodontalDentistRecallExamDataRequestSuccess,
  getPeriodontalDentistRecallExamDataRequestFailure,
  putPeriodontalDentistRecallExamDataRequestFailure,
  putPeriodontalDentistRecallExamDataRequestSuccess,
} from "../../../../actions/v1/dentist/clinicalExamDentistRecallExamActions/periodontalDentistRecallExamAction";
import {
  GET_PERIODONTAL_DENTIST_RECALL_EXAM_REQUEST,
  PUT_PERIODONTAL_DENTIST_RECALL_EXAM_REQUEST,
} from "../../../../ActionTypes/v1/dentist/clinicalExamDentistRecallExam";
import { updatePeriodontalRecallExamRequestFailure } from "../../../../actions/v1/dentist/clinicExamDentistActions/periodontalDentistRecallExamAction";
import { errorToaster } from '../../../../../utils/helper';

function* getPeriodontalDentistRecallExamRequestSaga(payload: any): any {
  yield put(updatePeriodontalRecallExamRequestFailure())
  try {
    const response = yield API.get(
      `/exam/dentist/recall/periodontal/${payload?.payload?.appointment_id}?appt_type=${payload?.payload?.appt_type}`
    );
    yield put(
      getPeriodontalDentistRecallExamDataRequestSuccess({
        data: response?.data,
      })
    );
    yield call(payload?.payload?.callback, response?.data);

    if (payload.payload.callback) {
      payload.payload.callback(response?.data);
    }
  } catch (e: any) {
    yield put(updatePeriodontalRecallExamRequestFailure())
    yield put(
      getPeriodontalDentistRecallExamDataRequestFailure({
        error: e?.detail,
      })
    );
  }
}
function* putPeriodontalDentistRecallExamRequestSaga(payload: any): any {
  try {
    const response = yield API.put(
      `/exam/dentist/recall/periodontal`,
      payload?.payload?.periodontalData
    );
    if (payload?.payload?.callback) {
      yield call(payload?.payload?.callback, response?.data);
    }
    yield put(
      putPeriodontalDentistRecallExamDataRequestSuccess({
        data: response?.data,
      })
    );
  } catch (e: any) {
    errorToaster(e?.detail)
    yield put(
      putPeriodontalDentistRecallExamDataRequestFailure({
        error: e?.detail,
      })
    );
  }
}
function* getPeriodontalDentistRecallSaga() {
  yield takeEvery(
    GET_PERIODONTAL_DENTIST_RECALL_EXAM_REQUEST,
    getPeriodontalDentistRecallExamRequestSaga
  );
}
function* putPeriodontalDentistRecallSaga() {
  yield takeEvery(
    PUT_PERIODONTAL_DENTIST_RECALL_EXAM_REQUEST,
    putPeriodontalDentistRecallExamRequestSaga
  );
}

export default function* rootSaga() {
  yield all([
    getPeriodontalDentistRecallSaga(),
    putPeriodontalDentistRecallSaga(),
  ]);
}
