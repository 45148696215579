import {
  GET_KEY_FINDING_REQUEST, GET_KEY_FINDING_CACHE_DATA_CLEAR_REQUEST,
  GET_KEY_FINDING_REQUEST_SUCCESS,
  GET_KEY_FINDING_REQUEST_FAILURE,
} from "../../../../../ActionTypes/v1/dentist/keyFinding";
import {
  KeyFindingRequest,
  KeyFindingRequestSuccess,
  KeyFindingRequestFailure,
  KeyFindingRequestPayload,
  KeyFindingSuccessPayload,
  KeyFindingFailurePayload,
} from "../../../../../types/dentistTypes";

export const keyFindingRequest = (
  payload: KeyFindingRequestPayload
): KeyFindingRequest => ({
  type: GET_KEY_FINDING_REQUEST,
  payload,
});

export const keyFindingCacheDataClearRequest = ():any => ({
  type: GET_KEY_FINDING_CACHE_DATA_CLEAR_REQUEST
})

export const keyFindingRequestSuccess = (
  payload: KeyFindingSuccessPayload
): KeyFindingRequestSuccess => ({
  type: GET_KEY_FINDING_REQUEST_SUCCESS,
  payload,
});
export const keyFindingRequestFailure = (
  payload: KeyFindingFailurePayload
): KeyFindingRequestFailure => ({
  type: GET_KEY_FINDING_REQUEST_FAILURE,
  payload,
});
