import {Box, Typography} from '@mui/material';
import {styled} from '@mui/material/styles';
import React from 'react';
import {useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';
import theme from '../../../../../../theme';
import {viewExam, completeCheckout} from '../../../../../../utils/v2/clinicalExamConstant';
import {examConstantInterface, findingData, findingTypes} from '../../../../../../utils/v2/examConstant';

const ChartLabel = styled(Typography)(() => ({
    color: theme.palette.common.black50,
    fontSize: '12px',
    fontFamily: 'FavoritPro-Regular'
}));

const PeriogramLeftLabels = () => {
    const location = useLocation()
    const {
        appointmentsReducer: {appointmentDetails},
        clinicalExamFilters: {riskFilters, findingType},
        wellnessProfileFilters: {wellnessProfileData}
    } = useSelector((state: any) => state) as any

    const isWellnessProfile = location?.pathname?.includes('/v2/dentist/wellness-profile/')

    const disabledField = (finding: string) => {
        const examType = appointmentDetails?.appointmentType;
        const findingTypeWiseFilteredData = findingData?.filter((f: examConstantInterface) =>
            f?.findingTypes?.includes(findingTypes[findingType])
        ) as any;
        const examTypeWiseFilteredData = findingTypeWiseFilteredData?.filter((f: examConstantInterface) => f?.examType?.includes(examType));
        const getData = isWellnessProfile ? findingData : examTypeWiseFilteredData?.filter((f: examConstantInterface) => f?.key === finding);
        return (
            [completeCheckout, viewExam]?.includes(appointmentDetails?.sub_status) || wellnessProfileData?.is_wellness_locked ||
            (isWellnessProfile ? false : !getData[0]?.examType?.includes(appointmentDetails?.appointmentType)) ||
            (riskFilters?.length ? (getData[0] && getData[0]?.risks?.filter((ff: string) => riskFilters?.includes(ff))?.length) === 0 : false)
        )
    };

    return (
        <Box display={'flex'} alignItems={'flex-end'} flexDirection={'column'} paddingRight={1}>
            <ChartLabel sx={{opacity: disabledField('mobility') ? 0.7 : 1}}>Mobility</ChartLabel>
            <ChartLabel mt={0.6} sx={{opacity: disabledField('probing_depth') ? 0.7 : 1}}>Probing Depth</ChartLabel>
            <ChartLabel mt={0.6} sx={{opacity: disabledField('bleed_on_probing') ? 0.7 : 1}}>Bleeding on
                Probing</ChartLabel>
            <ChartLabel mt={0.5} sx={{opacity: disabledField('plaque') ? 0.7 : 1}}>Plaque</ChartLabel>
            <ChartLabel mt={0.35} sx={{opacity: disabledField('furcation') ? 0.7 : 1}}>Furcation</ChartLabel>
            <ChartLabel mt={0.3} sx={{opacity: disabledField('recession') ? 0.7 : 1}}>Recession</ChartLabel>
            <ChartLabel variant={'h5'} fontSize={'16px !important'} mt={9}>
                Buccal
            </ChartLabel>
            <ChartLabel variant={'h5'} fontSize={'16px !important'} mt={12}>
                Lingual
            </ChartLabel>
            <ChartLabel mt={5.3} sx={{opacity: disabledField('recession') ? 0.7 : 1}}>Recession</ChartLabel>
            <ChartLabel mt={0.6} sx={{opacity: disabledField('furcation') ? 0.7 : 1}}>Furcation</ChartLabel>
            <ChartLabel mt={0.4} sx={{opacity: disabledField('plaque') ? 0.7 : 1}}>Plaque</ChartLabel>
            <ChartLabel mt={0.5} sx={{opacity: disabledField('bleed_on_probing') ? 0.7 : 1}}>Bleeding on
                Probing</ChartLabel>
            <ChartLabel mt={0.5} sx={{opacity: disabledField('probing_depth') ? 0.7 : 1}}>Probing Depth</ChartLabel>

            {/*---------------------------------------------------------------------------*/}
            {/*----------------------------------Divider----------------------------------*/}
            {/*---------------------------------------------------------------------------*/}
            <ChartLabel mt={5.5} sx={{opacity: disabledField('probing_depth') ? 0.7 : 1}}>Probing Depth</ChartLabel>
            <ChartLabel mt={0.5} sx={{opacity: disabledField('bleed_on_probing') ? 0.7 : 1}}>Bleeding on
                Probing</ChartLabel>
            <ChartLabel mt={0.4} sx={{opacity: disabledField('plaque') ? 0.7 : 1}}>Plaque</ChartLabel>
            <ChartLabel mt={0.5} sx={{opacity: disabledField('furcation') ? 0.7 : 1}}>Furcation</ChartLabel>
            <ChartLabel mt={0.4} sx={{opacity: disabledField('recession') ? 0.7 : 1}}>Recession</ChartLabel>
            <ChartLabel variant={'h5'} fontSize={'16px !important'} mt={4.5}>
                Lingual
            </ChartLabel>
            <ChartLabel variant={'h5'} fontSize={'16px !important'} mt={11.75}>
                Buccal
            </ChartLabel>
            <ChartLabel mt={11} sx={{opacity: disabledField('recession') ? 0.7 : 1}}>Recession</ChartLabel>
            <ChartLabel mt={0.5} sx={{opacity: disabledField('furcation') ? 0.7 : 1}}>Furcation</ChartLabel>
            <ChartLabel mt={0.5} sx={{opacity: disabledField('plaque') ? 0.7 : 1}}>Plaque</ChartLabel>
            <ChartLabel mt={0.6} sx={{opacity: disabledField('bleed_on_probing') ? 0.7 : 1}}>Bleeding on
                Probing</ChartLabel>
            <ChartLabel mt={0.6} sx={{opacity: disabledField('probing_depth') ? 0.7 : 1}}>Probing Depth</ChartLabel>
            <ChartLabel mt={0.44} sx={{opacity: disabledField('mobility') ? 0.7 : 1}}>Mobility</ChartLabel>
        </Box>
    );
};

export default PeriogramLeftLabels;
