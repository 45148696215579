import {faClose} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Box, Button, Dialog, DialogActions, DialogContent, Divider, Grid, TextField, Typography} from '@mui/material';
import {makeStyles} from '@mui/styles';
import React, {useEffect, useMemo, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {
    openAdditionalNotesAction,
    putAdditionalNotesRequestAction
} from '../../../../../app/actions/v2/dentist/clinicalExam/clinicalExamFilterAction';
import {RootState} from '../../../../../app/reducers/v2/rootReducer';
import QIcon from '../../../../../assets/images/v2/QIcon.png'
import theme from '../../../../../theme';
import {disabledField} from '../../../../../utils/v2/clinicalExamConstant';
import {AvoidSpace} from '../../../../../utils/helper';

const useStyles = makeStyles((theme: any) => ({
    DialogActionsClass: {
        padding: '16px 20px',
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between'
    },
    closeButton: {
        padding: '0px !important',
        display: 'flex',
        justifyContent: 'flex-end',
        backgroundColor: 'transparent',
        color: theme.palette.common.black,
        '&:hover': {
            backgroundColor: theme.palette.common.white,
            boxShadow: 'none'
        }
    },
    dialogContent: {
        width: '100% !important',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center'
    },

    verifyButton: {
        height: '40px',
        padding: '10px 20px !important',
        minWidth: '100px',
        borderRadius: '4px',
        background: theme.palette.v2.primary.main,
        color: theme.palette.common.white,
        textDecoration: 'none',
        textAlign: 'center',
        marginLeft: '35px',
        '&:hover': {
            background: theme.palette.v2.primary.main,
            color: theme.palette.common.white
        }
    },
    verifyButtonContainer: {
        padding: '12px 20px',
        justifyContent: 'space-between !important'
    },
    cancelBtn: {
        height: '40px',
        padding: '8.5px 20px',
        border: `1px solid ${theme.palette.v2.primary.main}`,
        color: theme.palette.v2.primary.main,
        '&:hover': {
            backgroundColor: 'transparent',
            borderColor: theme.palette.v2.primary.main
        }
    },
    copyButton: {
        color: theme.palette.v2.primary.main,
        "&:hover": {
            backgroundColor: 'transparent',
        },
    }
}));

type Props = {
    open: boolean;
};

const AdditionalNotes = (props: Props) => {
    const noteRef = useRef(null) as any
    const {open} = props;
    const classes = useStyles();
    const dispatch = useDispatch();
    const takeFromUrl = useParams();
    const {appointmentsReducer: {appointmentDetails}} = useSelector((state: RootState) => state);
    const [additionalNotes, setAdditionalNotes] = useState('') as any

    useEffect(() => {
        if (additionalNotes !== appointmentDetails?.additional_notes) {
            setAdditionalNotes(appointmentDetails?.additional_notes)
        }
    }, [appointmentDetails?.additional_notes, open])

    const handleClose = () => {
        dispatch(openAdditionalNotesAction(false));
    };

    const handleSave = () => {
        dispatch(putAdditionalNotesRequestAction({
            appointment_id: takeFromUrl?.appointmentId,
            additional_notes: additionalNotes
        }))
    };

    const calculateRows = useMemo(() => {
        const defaultLine = 10
        const numLines = additionalNotes?.split('\n')?.length || 0;
        let currantHeight = noteRef?.current?.clientHeight || 0 as any
        let getHeight = ((currantHeight - 30) / 20 > 10 ? (currantHeight - 30) / 20 : 10)
        return Math.max(getHeight, numLines, defaultLine)
    }, [additionalNotes]);


    // TODO: commented copy button in Q notes :GitHub:7608
    // const copyNotes = () => {
    //   navigator?.clipboard?.writeText(additionalNotes)
    //   successToaster("Q Note copied to clipboard!");
    // }

    return (
        <Dialog
            sx={{
                '& .MuiDialogContent-root': {
                    width: '100% !important',
                    padding: '12px 20px'
                },
                '& .MuiPaper-root ': {
                    width: 'fit-content',
                    maxWidth: '100%',
                    background: theme.palette.common.white,
                    boxShadow: ' 0px 10px 20px rgba(0, 0, 0, 0.07)',
                    borderRadius: '10px !important',
                    display: 'flex !important',
                    alignItems: 'center !important',
                    justifyContent: 'center !important'
                }
            }}
            open={open}
            onClose={handleClose}
        >
            <DialogActions className={classes.DialogActionsClass}>
                <Box className='d-flex align-center'>
                    <img src={QIcon} width={20} height={20} alt={'Q'}/>
                    <Typography ml={1} className='f-14 f-w-500 fw-medium'> Note</Typography>
                </Box>
                <Button onClick={handleClose} className={classes.closeButton} disableRipple>
                    <FontAwesomeIcon
                        icon={faClose}
                        color={theme.palette.common.black50}
                        className='f-20'
                        width={'20px'}
                        height={'20px'}
                    />
                </Button>
            </DialogActions>
            <Divider sx={{width: '100%', borderColor: theme.palette.divider}}/>
            <DialogContent>
                <Box className={classes.dialogContent}>
                    <Grid container>
                        <Grid item={true} xs={12} className='d-flex flex-column'>
                            <TextField
                                disabled={disabledField(appointmentDetails)}
                                onKeyPress={(e: any) => {
                                    AvoidSpace(e)
                                }}
                                InputProps={{
                                    classes: {
                                        root: `border-radius-8 textFieldEndIconPadding textFieldStartIconPadding labelColor bg-white `,
                                        input: 'textFieldMultilineClass textField',
                                    },
                                }}
                                id='additionalNotes'
                                name='additionalNotes'
                                onChange={(e: any) => setAdditionalNotes(e?.target?.value)}
                                value={additionalNotes}
                                fullWidth={true}
                                multiline={true}
                                rows={calculateRows}
                                ref={noteRef}
                                sx={{
                                    input: {
                                        backgroundColor: 'theme?.palette?.common?.white',
                                        color: `${theme.palette.common.black50} !important`,
                                    },
                                    "& .Mui-disabled": {
                                        "& .MuiOutlinedInput-root": {
                                            "& fieldset": {
                                                border: `1px solid ${theme.palette.text.secondary} !important`,
                                            },
                                        },
                                        color: `${theme.palette.text.secondary} !important`,
                                        opacity: 1,
                                    },
                                    '& .MuiOutlinedInput-input': {
                                        // resize: 'both',
                                        minWidth: '600px',
                                    },
                                    "& .MuiOutlinedInput-root": {
                                        color: `${theme.palette.common.black50} !important`,
                                        padding: "15px 10px 15px 16px",
                                        overflowY: 'hidden !important',
                                        background: "white !important",
                                        "& fieldset": {
                                            borderColor: theme.palette.text.darkGrey,
                                        },
                                        "&:hover fieldset": {
                                            borderColor: disabledField(appointmentDetails) ? "none" : theme.palette.common.black50,
                                        },
                                        "&.Mui-focused fieldset": {
                                            border: `2px solid ${theme.palette.v2.primary.main} !important`,
                                        },
                                    },
                                }}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </DialogContent>
            <Divider sx={{width: '100%', borderColor: theme.palette.divider}}/>
            <DialogActions className={`d-flex justify-content-between ${classes.verifyButtonContainer} w-100`}>
                <Button variant='outlined' onClick={handleClose} className={classes.cancelBtn}>
                    Cancel
                </Button>
                <Box>
                    {/* TODO: commented copy button in Q notes :GitHub:7608 */}
                    {/* {additionalNotes && <Button
            disableRipple
            onClick={copyNotes}
            className={classes.copyButton}
            startIcon={<FontAwesomeIcon icon={faClone} className='f-16' width={16} height={16} />}
          > Copy</Button>} */}
                    <Button
                        onClick={handleSave}
                        className={classes.verifyButton}
                        variant='contained'
                        style={{textTransform: 'capitalize'}}
                        disabled={disabledField(appointmentDetails)}
                    >
                        Save
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    );
};

export default AdditionalNotes;
