import { Box, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles';
import React from 'react'

const useStyles = makeStyles((theme: any) => ({
  modalContainer: {
    display: "flex",
    justifyContent: "start !important",
    alignItems: "start !important",
    flexDirection: "column",
  },
  passwordExample: {
    marginTop: "10px",
    background: theme.palette.background.paper,
    padding: "10px",
    borderRadius: "10px",
    color: theme.palette.common.black,
  },
  toolTipTitle: {
    backgroundColor: `${theme.palette.common.white} !important`,
  },
  spanFont: {
    fontWeight: "600",
  },
}))
const PDFPasswordInfo = () => {
  const classes = useStyles();

  return (
    <Box className={classes.toolTipTitle}>
      <Box className={`fw-regular ${classes.modalContainer}`}>
        <Typography className={'fw-regular'}>
          Your password is the first four letters of your first name and the last four digit of your mobile number
        </Typography>
        <Box className={`fw-regular ${classes.passwordExample}`} mb={2}>
          <Typography className={'fw-regular'}>
            For example:
            <Typography className={'fw-regular'}>
              If{" "}
              <span className={`fw-regular ${classes.spanFont}`}>Isra</span>
              el Bracey's is your name and the last four digit of your mobile number is{" "}
              <span className={`fw-regular ${classes.spanFont}`}>1234</span>
              , then the password will be{" "}
              <span className={`fw-regular ${classes.spanFont}`}>
                Isra1234
              </span>
            </Typography>
          </Typography>
        </Box>
        Or
        <Typography mt={1.6} className={'fw-regular'}>
          In case the first name has less then four letters,
          then use the first name letter followed by '#' to
          complete the first four letters in the password.
        </Typography>
        <Box className={classes.passwordExample}>
          <Typography className={'fw-regular'}>
            For example:
            <Typography className={'fw-regular'}>
              If{" "}
              <span className={`fw-regular ${classes.spanFont}`}>Avi</span>{" "}
              Bracey's is your name and the last four digit of your mobile number is
              <span className={`fw-regular ${classes.spanFont}`}>1234</span>
              , then the password will be{" "}
              <span className={`fw-regular ${classes.spanFont}`}>
                Avi#1234
              </span>
            </Typography>
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default PDFPasswordInfo