import React from 'react'
import { makeStyles } from '@mui/styles';
import { Box, Typography, useMediaQuery } from '@mui/material'
import theme from '../../../../theme'
import fdaLogo from '../../../../assets/images/v2/auth/fdaOraq.png'
import healthCanadaLogo from '../../../../assets/images/v2/auth/health-canada-logo-oraq.png'
import hipaLogo from '../../../../assets/images/v2/auth/hipaaOraq.png'

const useStyles = makeStyles((theme: any) => ({
    image1: {
        width: '100px', height: '55px',
        [theme.breakpoints.down("sm")]: {
            width: '77px', height: '35px',
        }
    },
    image2: {
        width: '160px', height: '80px',
        [theme.breakpoints.down("sm")]: {
            width: '114px', height: '51px',
        }
    },
    image3: {
        width: '150px', height: '80px',
        [theme.breakpoints.down("sm")]: {
            width: '85px', height: '54px',
        }
    }
}));

type Props = {
    isRegister?: boolean
}

const OraQFooterBranding = (props: Props) => {
    const { isRegister } = props as Props;
    const classes = useStyles();

    let newDate = new Date();
    let year = newDate.getFullYear();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down(960));

    return (
        <Box
            display="flex"
            flexDirection={"column"}
            position={"absolute"}
            right="0%"
            bottom="1%"
            width={isSmallScreen ? '100%' : '50%'}
            gap={'10px'}
        >
            <Typography
                sx={{
                    [theme.breakpoints.down("sm")]: {
                        display: "none",
                    }
                }}
                display={"flex"} justifyContent="center" fontSize={"18px"}
                textAlign={"center"}
                color={theme.palette.secondary.main} className={'fw-regular'}
                lineHeight={'26px'} mb={isRegister ? 0 : 1}
            >
                OraQ™ is approved for use by The FDA and Health<br /> Canada and is privacy and
                regulatory compliant.
            </Typography>

            <Box display="flex" justifyContent={'space-around'} alignItems={'center'}>
                <img
                    src={fdaLogo}
                    className={`cursor-pointer ${classes.image1}`}
                    alt='fdaLogo'
                />

                <img
                    src={hipaLogo}
                    className={`cursor-pointer ${classes.image2}`}
                    alt='fdaLogo'
                />

                <img
                    src={healthCanadaLogo}
                    className={`cursor-pointer ${classes.image3}`}
                    alt='fdaLogo'
                />
            </Box>

            <Typography display={"flex"} justifyContent="flex-end" fontSize={"12px"}
                color={"text.secondary"} className={'fw-regular'} mr={1}>
                @{year} ORAQ All Rights Reserved.
            </Typography>
        </Box>
    )
}

export default OraQFooterBranding