import {
  GET_CLINIC_DETAILS_REQUEST,
  GET_CLINIC_DETAILS_REQUEST_FAILURE,
  GET_CLINIC_DETAILS_REQUEST_SUCCESS,
  SAVE_ANONYMOUS_PRE_INTAKE_REQUEST,
  SAVE_ANONYMOUS_PRE_INTAKE_REQUEST_SUCCESS,
  SAVE_ANONYMOUS_PRE_INTAKE_REQUEST_FAILURE,
  SELECT_EMAIL,
  VERIFY_ANONYMOUS_PATIENT_EMAIL_REQUEST,
  VERIFY_ANONYMOUS_PATIENT_EMAIL_REQUEST_SUCCESS,
  VERIFY_ANONYMOUS_PATIENT_EMAIL_REQUEST_FAILURE,
  UPDATE_EMAIL_ERROR
} from '../../../ActionTypes/v2/anonymousPreIntake/anonymousPreIntake';
import {
  getClinicDetailsRequest,
  getClinicDetailsRequestFailure,
  getClinicDetailsRequestFailurePayload,
  getClinicDetailsRequestPayload,
  getClinicDetailsRequestSuccess,
  getClinicDetailsRequestSuccessPayload,
  saveAnonymousPreIntakeRequest,
  saveAnonymousPreIntakeRequestFailure,
  saveAnonymousPreIntakeRequestFailurePayload,
  saveAnonymousPreIntakeRequestPayload,
  saveAnonymousPreIntakeRequestSuccess,
  saveAnonymousPreIntakeRequestSuccessPayload,
  selectEmail,
  verifyAnonymousPatientEmailRequest,
  verifyAnonymousPatientEmailRequestSuccess,
  verifyAnonymousPatientEmailRequestFailure,
  verifyAnonymousPatientEmailRequestPayload,
  verifyAnonymousPatientEmailRequestSuccessPayload,
  verifyAnonymousPatientEmailRequestFailurePayload,
  updateEmailError
} from '../../../types/v2/anonymousPreIntake/anonymousPreIntakeTypes';

export const getClinicDetailsRequestAction = (payload: getClinicDetailsRequestPayload): getClinicDetailsRequest => ({
  type: GET_CLINIC_DETAILS_REQUEST,
  payload
});
export const getClinicDetailsRequestSuccessAction = (
  payload: getClinicDetailsRequestSuccessPayload
): getClinicDetailsRequestSuccess => ({
  type: GET_CLINIC_DETAILS_REQUEST_SUCCESS,
  payload
});
export const getClinicDetailsRequestFailureAction = (
  payload: getClinicDetailsRequestFailurePayload
): getClinicDetailsRequestFailure => ({
  type: GET_CLINIC_DETAILS_REQUEST_FAILURE,
  payload
});

export const saveAnonymousPreIntakeRequestAction = (
  payload: saveAnonymousPreIntakeRequestPayload
): saveAnonymousPreIntakeRequest => ({
  type: SAVE_ANONYMOUS_PRE_INTAKE_REQUEST,
  payload
});
export const saveAnonymousPreIntakeRequestSuccessAction = (
  payload: saveAnonymousPreIntakeRequestSuccessPayload
): saveAnonymousPreIntakeRequestSuccess => ({
  type: SAVE_ANONYMOUS_PRE_INTAKE_REQUEST_SUCCESS,
  payload
});
export const saveAnonymousPreIntakeRequestFailureAction = (
  payload: saveAnonymousPreIntakeRequestFailurePayload
): saveAnonymousPreIntakeRequestFailure => ({
  type: SAVE_ANONYMOUS_PRE_INTAKE_REQUEST_FAILURE,
  payload
});

export const selectEmailAction = (payload: string | null): selectEmail => ({
  type: SELECT_EMAIL,
  payload
});

export const verifyAnonymousPatientEmailRequestAction = (
  payload: verifyAnonymousPatientEmailRequestPayload
): verifyAnonymousPatientEmailRequest => ({
  type: VERIFY_ANONYMOUS_PATIENT_EMAIL_REQUEST,
  payload
});
export const verifyAnonymousPatientEmailRequestSuccessAction = (
  payload: verifyAnonymousPatientEmailRequestSuccessPayload
): verifyAnonymousPatientEmailRequestSuccess => ({
  type: VERIFY_ANONYMOUS_PATIENT_EMAIL_REQUEST_SUCCESS,
  payload
});
export const verifyAnonymousPatientEmailRequestFailureAction = (
  payload: verifyAnonymousPatientEmailRequestFailurePayload
): verifyAnonymousPatientEmailRequestFailure => ({
  type: VERIFY_ANONYMOUS_PATIENT_EMAIL_REQUEST_FAILURE,
  payload
});
export const updateEmailErrorAction = (payload: any): updateEmailError => ({
  type: UPDATE_EMAIL_ERROR,
  payload
});
