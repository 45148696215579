import { put, takeEvery, all } from "redux-saga/effects";
import API from "../../../../../../utils/api";
import {
  keyFindingRequestSuccess,
  keyFindingRequestFailure,
  keyFindingCacheDataClearRequest,
} from "../../../../../actions/v1/dentist/assignedTreatment/keyFindingAction/keyFindingAction";
import { GET_KEY_FINDING_REQUEST } from "../../../../../ActionTypes/v1/dentist/keyFinding";

function* getKeyFIndingRequestSaga(payload: any): any {
  yield put(keyFindingCacheDataClearRequest())
  try {
    const response = yield API.get(
      `exam/dentist/key/findings/${payload?.payload?.appointmentId}?appt_type=${payload?.payload?.appointmentType}`
    );
    yield put(
      keyFindingRequestSuccess({
        keyFindingData: response?.data,
      })
    );
  } catch (e: any) {
    //TODO: update toast according to status code
    //   errorToaster(e?.detail);
    yield put(keyFindingCacheDataClearRequest())
    yield put(
      keyFindingRequestFailure({
        error: e?.detail,
      })
    );
  }
}

function* keyFindingSaga() {
  yield takeEvery(GET_KEY_FINDING_REQUEST, getKeyFIndingRequestSaga);
}

export default function* rootSaga() {
  yield all([keyFindingSaga()]);
}
