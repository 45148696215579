import React, { useEffect } from 'react'
import { Box } from '@mui/material'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import HelmetComponent from '../../../../../components/v1/Common/HelmetComponent'
import HealthHistoryHeader from '../../../../../components/v2/patient/VerificationsWithoutAccount/AccessHealthHistoryWithoutAccount/HealthHistoryHeader'
import HealthHistoryFooter from '../../../../../components/v2/patient/VerificationsWithoutAccount/AccessHealthHistoryWithoutAccount/HealthHistoryFooter'
import { getHealthHistoryWithoutAcRequest } from '../../../../../app/actions/v2/patient/healthHistoryWithoutAccountAction'
import HealthHistoryWithoutAccPreIntake from '../../../../../components/v2/patient/VerificationsWithoutAccount/AccessHealthHistoryWithoutAccount/HealthHistoryWithoutAccPreintake'

const AccessHealthHistoryWithoutAccount = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const takeFromUrl: any = useParams();
    const [searchParams]: any = useSearchParams();

    const token = searchParams.get("token")

    useEffect(() => {
        const payload = {
            clinic_id: takeFromUrl?.clinicId,
            patient_id: takeFromUrl?.patientId,
            errorCallback: (data: any) => {
                if (data?.token_expired) {
                    navigate('/patient/session-expired')
                }
            }
        } as any;

        if (token) {
            payload.token = token;
        }

        dispatch(getHealthHistoryWithoutAcRequest(payload));
    }, [])


    return (
        <React.Fragment>
            <HelmetComponent title='Health History | OraQ' />
            <HealthHistoryHeader headerText={'Health History'} />
            <Box sx={{ overflowY: "auto", height: `calc(100vh - 163px)` }}>
                <HealthHistoryWithoutAccPreIntake />
            </Box>
            <Box position={'sticky'} bottom={'0'}>
                <HealthHistoryFooter />
            </Box>
        </React.Fragment>
    )
}

export default AccessHealthHistoryWithoutAccount