import { all, put, takeEvery } from "redux-saga/effects";
import API from "../../../../utils/api";

import { errorToaster, successToaster } from '../../../../utils/helper';
import { sendOtpRequestFailure, sendOtpRequestSuccess, verifyOtpRequestFailure, verifyOtpRequestSuccess } from '../../../actions/v2/authAction/verifyMobileAction';
import { SEND_OTP_V2_REQUEST, VERIFY_OTP_V2_REQUEST } from '../../../ActionTypes/v2/auth';

function* verifyOtpRequestSaga(payload: any): any {
  try {
    const response = yield API.post(`/v2/auth/verify/otp?is_owner=${payload?.payload?.isOwner}`, payload.payload)
    yield put(
      verifyOtpRequestSuccess({
        otpData: response?.data?.detail
      })
    );
    if (response) {
      successToaster(response?.data?.meta?.message)
      if (payload?.payload?.callback) {
        payload?.payload?.callback(response)
      }
    }
  } catch (e: any) {
    errorToaster(e?.message)
    yield put(
      verifyOtpRequestFailure({
        otpError: e?.message
      })
    );
  }
}

function* sendOtpRequestSaga(payload: any): any {
  try {
    const response = yield API.post(`/v2/auth/send/otp`, payload?.payload)
    yield put(
      sendOtpRequestSuccess({
        mobile: response?.mobile
      })
    );
    if (response) {
      successToaster(response?.data?.meta?.message)
      if (payload?.payload?.callback) {
        payload?.payload?.callback()
      }
    }
  } catch (e: any) {
    errorToaster(e?.message)
    yield put(
      sendOtpRequestFailure({
        otpError: e?.message
      })
    );
  }
}

function* verifyOtpSaga() {
  yield takeEvery(VERIFY_OTP_V2_REQUEST, verifyOtpRequestSaga);
}

function* sendOtpSaga() {
  yield takeEvery(SEND_OTP_V2_REQUEST, sendOtpRequestSaga);
}

export default function* otpSaga() {
  yield all([
    verifyOtpSaga(),
    sendOtpSaga()
  ])
}

