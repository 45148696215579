import {
  GET_KEY_FINDING_REQUEST,GET_KEY_FINDING_CACHE_DATA_CLEAR_REQUEST,
  GET_KEY_FINDING_REQUEST_SUCCESS,
  GET_KEY_FINDING_REQUEST_FAILURE,
} from "../../../../../ActionTypes/v1/dentist/keyFinding";
import {
  KeyFindingInterface,
  KeyFindingActions,
} from "../../../../../types/dentistTypes";

const initialState: KeyFindingInterface = {
  keyFindingData: null,
  KeyFindingLoader: false,
  KeyFindingError: null,
};

const getKeyFindingReducer = (
  state = initialState,
  action: KeyFindingActions
) => {
  switch (action.type) {
    case GET_KEY_FINDING_REQUEST:
      return {
        ...state,
        KeyFindingLoader: true,
      };
    case GET_KEY_FINDING_CACHE_DATA_CLEAR_REQUEST:
      return {
        ...state,
        keyFindingData: null
      }  
    case GET_KEY_FINDING_REQUEST_SUCCESS:
      return {
        ...state,
        KeyFindingLoader: false,
        keyFindingData: action.payload?.keyFindingData,
        KeyFindingError: null,
      };
    case GET_KEY_FINDING_REQUEST_FAILURE:
      return {
        ...state,
        KeyFindingLoader: false,
        keyFindingData: null,
        KeyFindingError: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};

export default getKeyFindingReducer;
