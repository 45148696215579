
import { call, put, takeEvery, all } from "redux-saga/effects";
import {
  welcomeRequestSuccess,
  welcomeRequestFailure,
  termsConditionSuccess,
  termsConditionFailure,
} from "../../../../actions/v1/dentist/authActions/dentistAuthActions";
import {
  DENTIST_WELCOME_REQUEST,
  TERMS_CONDITION_AND_PRIVACY_POLICY_REQUEST
} from "../../../../ActionTypes/v1/dentist/auth";
import API from "../../../../../utils/api";
import { errorToaster } from '../../../../../utils/helper';

function* welcomeRequestSaga(payload: any): any {
  try {
    const response = yield API.put("/user/staff", payload.payload.payload)
    yield call(payload.payload.callback, response.data);
    yield put(
      welcomeRequestSuccess({
        data: response.data
      })
    );
  } catch (e: any) {
    errorToaster(e?.message)
    yield put(
      welcomeRequestFailure({
        error: e?.message
      })
    );
  }
}

function* acceptPolicyRequestSaga(payload: any): any {
  try {
    const response = yield API.put(`/v2/auth/policy/accepted?social_login=${payload?.payload?.social_login}`, payload.payload.payload)
    yield put(
      termsConditionSuccess({
        termsConditions: response.termsConditions
      })
    );
    if (payload?.payload?.callback) {
      payload?.payload?.callback(response)
    }
  } catch (e: any) {
    errorToaster(e?.message)
    yield put(
      termsConditionFailure({
        error: e?.message || "Something went wrong!"
      })
    );
  }
}

function* welcomeSaga() {
  yield takeEvery(DENTIST_WELCOME_REQUEST, welcomeRequestSaga);
}

function* acceptPolicySaga() {
  yield takeEvery(TERMS_CONDITION_AND_PRIVACY_POLICY_REQUEST, acceptPolicyRequestSaga);
}

export default function* rootSaga() {
  yield all([
    welcomeSaga(),
    acceptPolicySaga()
  ])
}
