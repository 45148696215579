import {
  CREATE_PASSWORD_V2_REQUEST,
  CREATE_PASSWORD_V2_REQUEST_FAILURE,
  CREATE_PASSWORD_V2_REQUEST_SUCCESS
} from '../../../ActionTypes/v2/auth';
import {
  CreatePasswordRequest,
  CreatePasswordRequestFailure,
  CreatePasswordRequestFailurePayload,
  CreatePasswordRequestPayload,
  CreatePasswordRequestSuccess,
  CreatePasswordRequestSuccessPayload
} from '../../../types/v2/authActionTypes/createPasswordActionTypes';

export const createPasswordRequest = (payload: CreatePasswordRequestPayload): CreatePasswordRequest => ({
  type: CREATE_PASSWORD_V2_REQUEST,
  payload
});

export const createPasswordRequestSuccess = (payload: CreatePasswordRequestSuccessPayload): CreatePasswordRequestSuccess => ({
  type: CREATE_PASSWORD_V2_REQUEST_SUCCESS,
  payload
});

export const createPasswordRequestFailure = (payload: CreatePasswordRequestFailurePayload): CreatePasswordRequestFailure => ({
  type: CREATE_PASSWORD_V2_REQUEST_FAILURE,
  payload
});
