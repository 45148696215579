import React, {useState} from 'react'
import {
    Box,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography
} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEye} from '@fortawesome/free-solid-svg-icons';
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import WellnessRescheduleAppointmentPopUp from '../WellnessRescheduleAppointmentPopUp';
import theme from '../../../../../../theme';
import {RootState} from "../../../../../../app/reducers/v2/rootReducer";
import NoDataFound from "../../../../../v1/Common/NoDataFound";
import {appointmentStatus, completeExam, startExam} from "../../../../../../utils/v2/clinicalExamConstant";
import {appointmentColumns} from "../../../../../../utils/v2/wellnessProfileConstant";
import {updateSubStatusAction} from "../../../../../../app/actions/v2/master/updateSubStatusActions";

const useStyles = makeStyles((theme: any) => ({
    table: {
        borderCollapse: "separate",
        boxShadow: "none",
        "& .MuiGrid-root": {
            backgroundColor: theme.palette.background.secondaryPaper,
        },
    },
    thead: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black50,
        height: '51px',
        position: 'relative',
        top: '-1px'
    },
    th: {
        padding: "13px 16px",
        fontSize: "13px",
        lineHeight: "150%",
        color: theme.palette.common.black50,
        overflowX: "auto",
        whiteSpace: "nowrap",
        borderBottom: `1px solid ${theme.palette.v2.primary.main}`,
        '&:first-child': {
            borderBottomLeftRadius: "5px", // Border radius for the first th
        },
        '&:last-child': {
            borderBottomRightRadius: "5px", // Border radius for the last th
        },
    },
    td: {
        borderBottom: `1px solid ${theme.palette.divider}`,
        '&:first-child': {
            borderBottomLeftRadius: "5px", // Border radius for the first th
        },
        '&:last-child': {
            borderBottomRightRadius: "5px", // Border radius for the last th
        },
        height: `40px !important`,
    },

}));

type Props = {
    appointment: string
}

const WellnessUpcomingAppointment = (props: Props) => {
    const {appointment} = props
    const classes = useStyles();
    const {palette} = theme
    const dispatch = useDispatch()
    const takeFromUrl = useParams();
    const history = useNavigate();
    const {Upcoming, Ongoing} = appointmentStatus
    const [open, setOpen] = useState(false)
    const {wellnessProfileFilters: {wellnessProfileData},} = useSelector((state: RootState) => state);
    const [selectedAppointment, setSelectedAppointment] = useState(null)

    const handleClose = () => {
        setOpen(false)
    }
    // const handleOpen = (data: any) => {
    //     setSelectedAppointment(data)
    //     setOpen(true)
    // }

    const viewAppointment = (data: any) => {
        if (data?.status === Upcoming && data?.sub_status === startExam) {
            dispatch(updateSubStatusAction({
                appointment_id: data?.appointmentID,
                status: Ongoing,
                sub_status: completeExam,
                callback: () => {
                    history({
                        pathname: `/v2/dentist/appointment/clinical-exam/${data?.appointmentID}/${takeFromUrl.patientId}`
                    })
                }
            }))

        } else {
            history({
                pathname: `/v2/dentist/appointment/clinical-exam/${data?.appointmentID}/${takeFromUrl.patientId}`
            })
        }
    }

    const isDisabled = (status: any) => {
        return appointment === 'past' && status === 'Upcoming'
    }

    return (
        <Grid className={`d-flex-all-center text-align-center`} sx={{overflow: 'hidden'}}>
            {wellnessProfileData?.appointments?.[appointment]?.length > 0 ?
                <TableContainer component={Paper}>
                    <Table sx={{minWidth: 650}} classes={{root: classes.table}}>
                        <TableHead classes={{root: classes.thead}}>
                            <TableRow>
                                {appointmentColumns?.map((column: any, i: number) => <TableCell
                                        key={i}
                                        className="fw-regular"
                                        classes={{root: classes.th}}
                                        align={'left'}
                                        sx={{minWidth: column.minWidth, maxWidth: column.maxWidth, width: column.width,}}
                                    >
                                        <Box
                                            sx={{
                                                borderLeft: `${i === 0 && 'none !important'}`,
                                                paddingLeft: `${i === 0 && '0px !important'}`
                                            }}>
                                            {column.label}
                                        </Box>
                                    </TableCell>
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody sx={{backgroundColor: palette.common.white}}>
                            {wellnessProfileData?.appointments?.[appointment]?.map((row: any, index: number) =>
                                <TableRow key={index}>
                                    <TableCell classes={{root: classes.td}}
                                               className='f-14 fw-regular f-w-400 labelColor' align='left'>
                                        <Box className={'d-flex'} flexDirection={'column'}>
                                            <Typography
                                                className='fw-regular f-w-400'>{row?.appointment_date || '-'}</Typography>
                                            <Typography
                                                className='fw-regular f-w-400'>{row?.appointment_time || '-'}</Typography>
                                        </Box>
                                    </TableCell>
                                    <TableCell classes={{root: classes.td}}
                                               className='f-14 fw-regular f-w-400 labelColor' align='center'>
                                        <Box className={'d-flex'}>
                                            <Typography
                                                className='fw-regular f-w-400 labelColor'>{row?.clinicName || '-'}</Typography>
                                        </Box>
                                    </TableCell>
                                    <TableCell classes={{root: classes.td}} align='left'>
                                        <Box>
                                            <Typography className='f-14 fw-regular f-w-500 labelColor'
                                                        sx={{textTransform: 'capitalize'}}>
                                                {row?.dentistName || '-'}
                                            </Typography>
                                        </Box>
                                    </TableCell>
                                    <TableCell classes={{root: classes.td}}
                                               className='f-14 fw-regular f-w-400 labelColor' align='center'>
                                        <Box className={'d-flex'}>
                                            <Typography className='fw-regular f-w-400 labelColor'
                                                        textTransform={'capitalize'}>{row?.assistantName || '-'}</Typography>
                                        </Box>
                                    </TableCell>
                                    <TableCell classes={{root: classes.td}}
                                               className='f-14 fw-regular f-w-400 labelColor' align='center'>
                                        <Box className={'d-flex'}>
                                            <Typography className='fw-regular f-w-400 labelColor'
                                                        textTransform={'capitalize'}>{row?.hygienistName || '-'}</Typography>
                                        </Box>
                                    </TableCell>
                                    <TableCell classes={{root: classes.td}}
                                               className='f-14 fw-regular f-w-400 labelColor' align='center'>
                                        <Box display={'flex'}>
                                            <Tooltip
                                                title={isDisabled(row?.status) ? 'This exam was not done in OraQ' : ''}>
                                                <Box
                                                    className={`d-flex ${isDisabled(row?.status) ? 'cursor-default' : 'cursor-pointer'} `}
                                                    onClick={() => isDisabled(row?.status) ? "" : viewAppointment(row)}>
                                                    <FontAwesomeIcon icon={faEye} className='f-16'
                                                                     color={isDisabled(row?.status) ? theme.palette.text.secondary : theme.palette.v2.primary.main}/>
                                                    <Typography ml={0.5}
                                                                className='fw-regular f-14 f-w-400'
                                                                color={isDisabled(row?.status) ? theme.palette.text.secondary : theme.palette.common.black50}>View</Typography>
                                                </Box>
                                            </Tooltip>
                                            {/*TODO:removed Reschedule option as per gitHub issue:8796*/}
                                            {/*{appointment === 'upcoming' &&*/}
                                            {/*    <Divider orientation="vertical" variant="middle" flexItem*/}
                                            {/*             sx={{color: palette.grey.Gray80, my: 0, mx: 1.3}}/>}*/}
                                            {/*{appointment === 'upcoming' &&*/}
                                            {/*    <Box className={'d-flex cursor-pointer'}*/}
                                            {/*         onClick={() => handleOpen(row)}>*/}
                                            {/*        <FontAwesomeIcon icon={faCalendarDays} className='f-16'*/}
                                            {/*                         color={theme.palette.common.black50}/>*/}
                                            {/*        <Typography ml={0.5}*/}
                                            {/*                    className='fw-regular f-14 f-w-400 labelColor'>Reschedule</Typography>*/}
                                            {/*    </Box>}*/}

                                        </Box>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer> :
                <Box py={2}><NoDataFound errorMessage={`${appointment} appointment`}/></Box>}
            {open &&
                <WellnessRescheduleAppointmentPopUp open={open} appointmentData={selectedAppointment}
                                                    handleClose={handleClose}/>}
        </Grid>
    )
}

export default WellnessUpcomingAppointment