import React, {useEffect, useState} from "react";
import {
    Box,
    Grid,
    Typography,
    Button,
    TextField,
    IconButton,
    InputAdornment, useMediaQuery,
} from "@mui/material";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import dentistBanner from "../../../assets/images/v1/login_page_banner.png";
import {makeStyles} from "@mui/styles";
import patientBanner from "../../../assets/images/v1/patient_banner.png";
import {getLocalStorageItem} from "../../../utils/helper";
import theme from "../../../theme";
import HelmetComponent from "../../../components/v1/Common/HelmetComponent";
import colorLogo from "../../../assets/images/v2/logo.png";
import transparentLogo from "../../../assets/images/v1/transparent_logo.png";


const useStyles = makeStyles((theme: any) => ({
    leftSection: {
        width: "100%",
        overflow: "hidden",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        height: "auto",
        backgroundPosition: "center",
        zIndex: 1024,
        position: "relative",
        [theme.breakpoints.down("md")]: {
            display: "none",
            height: "100vh",
            position: "absolute",
            zIndex: -1,
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
        },
    },
}));
const CreatePasswordComponent = (props: any) => {
    const classes = useStyles();
    // const { isPatient } = props?;
    const [userData, setUserData] = useState("");
    let newDate = new Date();
    let year = newDate.getFullYear();

    useEffect(() => {
        let userData: any = {};
        userData = window.location?.pathname.split("/")?.includes("patient")
            ? getLocalStorageItem("patientUserData")
            : getLocalStorageItem("dentistUserData");
        setUserData(
            JSON.parse(userData).first_name + " " + JSON.parse(userData).last_name
        );
    }, [userData]);

    const handleClickShowPassword = (pwd: any) => {
        if (pwd === "showPassword") {
            props.setFormData((prevState: { showPassword: any }) => {
                return {
                    ...prevState,
                    showPassword: !prevState.showPassword,
                };
            });
        }
        if (pwd === "showNewPassword") {
            props.setFormData((prevState: { showNewPassword: any }) => {
                return {
                    ...prevState,
                    showNewPassword: !prevState.showNewPassword,
                };
            });
        }
    };
    const isSmallScreen = useMediaQuery(theme.breakpoints.down(960));


    return (
        <>
            <HelmetComponent title={"Create Password"}/>
            <Grid container justifyContent={"center"} sx={{height: "100vh"}}>
                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    className={classes?.leftSection}
                    sx={{
                        backgroundImage: `url(${
                            props?.isPatient ? patientBanner : dentistBanner
                        })`,
                    }}
                >
                    <Typography
                        color={theme.palette.common.white}
                        fontWeight="bold"
                        fontSize={"30px"}
                        style={{lineHeight: "40px"}}
                        className={`fw-medium f-w-500 ${props.classes.imageText}`}
                        component={"span"}

                    >
            <span className={'fw-medium'}>
              {props.imageText.text1}
                <br/>
                {props.imageText.text2}
                <br/>
                {props.imageText.text3}
            </span>
                    </Typography>
                </Grid>

                <Grid
                    item
                    alignItems={"center"}
                    alignSelf={"center"}
                    display={"flex"}
                    justifyContent={"center"}
                    flexDirection={"column"}
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    className={props?.classes?.rightSection}
                >
                    <Box width={"100%"} className={props?.classes?.formBox}>
                        <Box className={`whiteLogoContainer`} position={'absolute'} top={'24px'} left={'24px'}
                             zIndex={1024}>
                            <img style={{width: '85px', height: '30px'}}
                                 src={isSmallScreen ? colorLogo : transparentLogo}
                                 className={'cursor-pointer'}
                                 alt='logo'/>
                        </Box>
                        {props?.isPatient && (
                            <Typography
                                color={
                                    props?.isPatient ? theme.palette.secondary.main : `${theme.palette.common.white} !important`
                                }
                                fontWeight={400}
                                fontSize={"16px"}
                                mb={3}
                                sx={{marginBottom: "30px !important"}}
                                textAlign="center"
                                className={`fw-regular ${props?.classes?.textCenter}`}
                                component={"span"}
                            >
                                Welcome {userData}!
                            </Typography>
                        )}
                        <Typography
                            color={props?.isPatient ? "rgba(74,54,153,1)" : `${theme.palette.common.white} !important`}
                            fontWeight={600}
                            fontSize={"30px"}
                            mb={3}
                            mt={2}
                            className={`fw-regular ${props?.classes?.textCenter}`}
                        >
                            Create Password
                        </Typography>
                        <form onSubmit={props?.submitHandler}>
                            <Box mt={5} mb={1.25}>
                                <Box mb={1.25}>
                                    <TextField
                                        type={props?.formData?.showPassword ? "text" : "password"}
                                        label="New Password"
                                        InputProps={{
                                            classes: {
                                                notchedOutline: props?.error?.password
                                                    ? props?.classes?.danger
                                                    : props?.classes?.notchedOutline,
                                                root: props?.classes?.cssOutlinedInput,
                                                focused: props?.classes?.cssFocused,
                                                input: props?.classes?.input,
                                            },
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={() =>
                                                            handleClickShowPassword("showPassword")
                                                        }
                                                        edge="end"
                                                        className={props?.classes?.showPassword}
                                                    >
                                                        {props?.formData?.showPassword ? (
                                                            <Visibility/>
                                                        ) : (
                                                            <VisibilityOff/>
                                                        )}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                        InputLabelProps={{
                                            classes: {
                                                root: `fw-regular ${props?.classes?.floatingLabelRootStyle}`,
                                                focused: props?.classes?.floatingLabelFocusStyle,
                                            },
                                        }}
                                        variant="outlined"
                                        fullWidth
                                        value={props?.formData?.password}
                                        onChange={(e) => {
                                            props?.setFormData((prevState: any) => {
                                                return {...prevState, password: e.target.value};
                                            });
                                            props?.setError((prevState: any) => {
                                                return {...prevState, password: ""};
                                            });
                                        }}
                                        error={!!props?.error?.password}
                                        helperText={props?.error?.password}
                                    />
                                </Box>
                                <Box mt={2} mb={1.25}>
                                    <TextField
                                        type={
                                            props?.formData?.showNewPassword ? "text" : "password"
                                        }
                                        label="Confirm Password"
                                        InputProps={{
                                            classes: {
                                                notchedOutline: props?.error?.confirmPassword
                                                    ? props?.classes?.danger
                                                    : props?.classes?.notchedOutline,
                                                root: props?.classes?.cssOutlinedInput,
                                                focused: props?.classes?.cssFocused,
                                                input: props?.classes?.input,
                                            },
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={() =>
                                                            handleClickShowPassword("showNewPassword")
                                                        }
                                                        edge="end"
                                                        className={props?.classes?.showPassword}
                                                    >
                                                        {props?.formData?.showNewPassword ? (
                                                            <Visibility/>
                                                        ) : (
                                                            <VisibilityOff/>
                                                        )}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                        InputLabelProps={{
                                            classes: {
                                                root: `fw-regular ${props?.classes?.floatingLabelRootStyle}`,
                                                focused: props?.classes?.floatingLabelFocusStyle,
                                            },
                                        }}
                                        variant="outlined"
                                        fullWidth
                                        value={props?.formData?.confirmPassword}
                                        onChange={(e) => {
                                            props?.setFormData((prevState: any) => {
                                                return {
                                                    ...prevState,
                                                    confirmPassword: e.target.value,
                                                };
                                            });
                                            props?.setError((prevState: any) => {
                                                return {...prevState, confirmPassword: ""};
                                            });
                                        }}
                                        error={!!props?.error?.confirmPassword}
                                        helperText={props?.error?.confirmPassword}
                                    />
                                </Box>
                            </Box>
                            <Box mt={2.8}>
                                <Button
                                    type={"submit"}
                                    fullWidth
                                    variant={"contained"}
                                    sx={{borderRadius: "10px"}}
                                    className={`fw-regular ${props?.classes?.button}`}
                                >
                                    Create Password
                                </Button>
                            </Box>
                        </form>
                        {props?.isPatient && (
                            <Box
                                display="flex"
                                justifyContent="flex-end"
                                position={"absolute"}
                                right="2%"
                                bottom="2%"
                            >
                                <Typography className={'fw-regular'} fontSize={"18px"} color={"text.secondary"}>
                                    @{year} ORAQ All Rights Reserved.
                                </Typography>
                            </Box>
                        )}
                    </Box>
                </Grid>
            </Grid>
        </>
    );
};

export default CreatePasswordComponent;
