import { convertMultiSelectStringToArray } from '../../../../../../utils/helper';
import {
  SET_EXAM_FINDINGS,
  ADD_EXISTING_FINDING,
  CHECKED_EXISTING_FINDING,
  GET_SOFT_TISSUE_REQUEST,
  PUT_SOFT_TISSUE_REQUEST,
  GET_HARD_TISSUE_REQUEST,
  PUT_HARD_TISSUE_REQUEST,
  GET_ODONTOGRAM_REQUEST,
  PUT_ODONTOGRAM_REQUEST,
  GET_PERIOGRAM_REQUEST,
  PUT_PERIOGRAM_REQUEST,
  GET_OTHER_FORM_FINDING_REQUEST,
  PUT_OTHER_FORM_FINDING_REQUEST,
  UPDATE_EXAM_API_DATA,
  SUCCESS_EXAM_API_DATA,
  FAILURE_EXAM_API_DATA,
  EXAM_GROUP_FINDING_DATA_COUNT,
  SYNC_PERIOGRAM_PMS_DATA_REQUEST,
  SYNC_PERIOGRAM_PMS_DATA_REQUEST_SUCCESS,
  SYNC_PERIOGRAM_PMS_DATA_REQUEST_FAILURE,
  FAILURE_PUT_EXAM_API_DATA,
  SYNC_ODONTOGRAM_PMS_DATA_REQUEST,
  SYNC_ODONTOGRAM_PMS_DATA_REQUEST_SUCCESS,
  SYNC_ODONTOGRAM_PMS_DATA_REQUEST_FAILURE,
  GET_LIMITED_EXAM_REQUEST,
  PUT_LIMITED_EXAM_REQUEST
} from '../../../../../ActionTypes/v2/dentist/clinicalExam/exams/examFindingsTypes';
import { examFindingAction, examFindingInterface } from '../../../../../types/v2/dentist/clinicalExam/exam/examFindingsTypes';

const initialState: examFindingInterface = {
  examFindingData: null,
  getExamLoader: false,
  addExistingFindingData: null,
  checkedExistingFinding: [],
  putExamDataFromAPI: null,
  examDataFromAPI: null,
  examLoader: false,
  examFindingCount: null,
  syncPeriogramPmsData: null,
  syncPeriogramPmsLoader: false,
  syncPeriogramPmsError: ''
};

const examFindingReducer = (state = initialState, action: examFindingAction) => {
  switch (action.type) {
    case SET_EXAM_FINDINGS:
      return {
        ...state,
        examFindingData: convertMultiSelectStringToArray(action.payload)
      };
    case ADD_EXISTING_FINDING:
      return {
        ...state,
        addExistingFindingData: action.payload
      };
    case CHECKED_EXISTING_FINDING:
      return {
        ...state,
        checkedExistingFinding: action.payload
      };
    case GET_SOFT_TISSUE_REQUEST:
      return {
        ...state,
        getExamLoader: true
      };
    case PUT_SOFT_TISSUE_REQUEST:
      return {
        ...state,
        examLoader: true
      };
    case GET_HARD_TISSUE_REQUEST:
      return {
        ...state,
        getExamLoader: true
      };
    case PUT_HARD_TISSUE_REQUEST:
      return {
        ...state,
        examLoader: true
      };
    case GET_ODONTOGRAM_REQUEST:
      return {
        ...state,
        getExamLoader: true
      };
    case PUT_ODONTOGRAM_REQUEST:
      return {
        ...state,
        examLoader: true
      };
    case GET_PERIOGRAM_REQUEST:
      return {
        ...state,
        getExamLoader: true
      };
    case PUT_PERIOGRAM_REQUEST:
      return {
        ...state,
        examLoader: true
      };
    case GET_OTHER_FORM_FINDING_REQUEST:
      return {
        ...state,
        getExamLoader: true
      };
    case PUT_OTHER_FORM_FINDING_REQUEST:
      return {
        ...state,
        examLoader: true
      };
    case GET_LIMITED_EXAM_REQUEST:
      return {
        ...state,
        getExamLoader: true
      };
    case PUT_LIMITED_EXAM_REQUEST:
      return {
        ...state,
        examLoader: true
      };
    case UPDATE_EXAM_API_DATA:
      return {
        ...state,
        examLoader: false,
        getExamLoader: false,
        examDataFromAPI: action.payload
      };
    case SUCCESS_EXAM_API_DATA:
      return { ...state, examLoader: false };
    case FAILURE_EXAM_API_DATA:
      return { ...state, examLoader: false, examDataFromAPI: null, getExamLoader: false };
    case FAILURE_PUT_EXAM_API_DATA:
      return { ...state, examLoader: false, getExamLoader: false };
    case EXAM_GROUP_FINDING_DATA_COUNT:
      return {
        ...state,
        examFindingCount: action.payload
      };
    case SYNC_PERIOGRAM_PMS_DATA_REQUEST:
      return {
        ...state,
        syncPeriogramPmsData: null,
        syncPeriogramPmsLoader: true,
        syncPeriogramPmsError: null
      };
    case SYNC_PERIOGRAM_PMS_DATA_REQUEST_SUCCESS:
      return {
        ...state,
        syncPeriogramPmsData: action.payload,
        syncPeriogramPmsLoader: false,
        syncPeriogramPmsError: null
      };
    case SYNC_PERIOGRAM_PMS_DATA_REQUEST_FAILURE:
      return {
        ...state,
        syncPeriogramPmsData: null,
        syncPeriogramPmsLoader: false,
        syncPeriogramPmsError: action.payload
      };
    case SYNC_ODONTOGRAM_PMS_DATA_REQUEST:
      return {
        ...state,
        syncOdontogramPmsData: null,
        syncOdontogramPmsLoader: true,
        syncOdontogramPmsError: null
      };
    case SYNC_ODONTOGRAM_PMS_DATA_REQUEST_SUCCESS:
      return {
        ...state,
        syncOdontogramPmsData: action.payload,
        syncOdontogramPmsLoader: false,
        syncOdontogramPmsError: null
      };
    case SYNC_ODONTOGRAM_PMS_DATA_REQUEST_FAILURE:
      return {
        ...state,
        syncOdontogramPmsData: null,
        syncOdontogramPmsLoader: false,
        syncOdontogramPmsError: action.payload
      };
    default:
      return {
        ...state
      };
  }
};
export default examFindingReducer;
