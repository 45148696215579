import {
  RECOVER_PASSWORD_V2_REQUEST,
  RECOVER_PASSWORD_V2_REQUEST_FAILURE,
  RECOVER_PASSWORD_V2_REQUEST_SUCCESS
} from '../../../ActionTypes/v2/auth';
import { recoverPasswordActions, recoverPasswordInterface } from '../../../types/v2/authActionTypes/resetPasswordActionTypes';

const initialState: recoverPasswordInterface = {
  recoverPasswordLoader: false,
  recoverPasswordData: null,
  recoverPasswordError: null
};

const resetPasswordReducer = (state = initialState, action: recoverPasswordActions) => {
  switch (action.type) {
    case RECOVER_PASSWORD_V2_REQUEST:
      return {
        ...state,
        recoverPasswordLoader: true
      };
    case RECOVER_PASSWORD_V2_REQUEST_SUCCESS:
      return {
        ...state,
        recoverPasswordLoader: false,
        recoverPasswordData: action.payload,
        recoverPasswordError: null
      };
    case RECOVER_PASSWORD_V2_REQUEST_FAILURE:
      return {
        ...state,
        recoverPasswordLoader: false,
        recoverPasswordData: null,
        recoverPasswordError: action.payload.recoverPasswordError
      };

    default:
      return {
        ...state
      };
  }
};
export default resetPasswordReducer;
