import {Box, Button, Grid, Tooltip, Typography} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {isArray} from "lodash";
import React, {useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useLocation} from "react-router-dom";
import {
    addExistingFindingsDataAction,
    checkedExistingFindingsDataAction,
    setExamFindingsAction
} from '../../../../../../app/actions/v2/dentist/clinicalExam/exam/examFindingsTypesAction';
import {
    openOdontogramGroupFindingPopupAction,
    selectOdontrogramFindingAction,
    selectOdontrogramToothAction,
    setEditOdontogramFindingDataAction
} from '../../../../../../app/actions/v2/dentist/clinicalExam/odontogramAction';
import theme from '../../../../../../theme';
import {errorToaster, removeRefFromJson, stringToArray} from '../../../../../../utils/helper';
import {odontogram, periogram, viewExam, completeCheckout} from '../../../../../../utils/v2/clinicalExamConstant';
import {
    findingData,
    FindingList,
    findingTypes,
    PMSEnableFindingsForOdontogram
} from "../../../../../../utils/v2/examConstant";
import RadioSelect from '../../../../common/RadioSelect';
import ExamsCustomFields from "../../ExamsCustomFields";

const useStyles = makeStyles((theme: any) => ({
    findingTooltip: {
        width: '250px',
        backgroundColor: theme.palette.common.white,
        color: 'black',
        zIndex: 2
    },
    tooltip: {
        color: `${theme.palette.common.white} !important`,
    },
    customWidth: {
        maxWidth: 500,
        width: "314px !important",
        backgroundColor: `${theme.palette.common.white} !important`,
        padding: "8px 13px 12px 13px !important",
        borderRadius: '8px',
        border: '1px solid rgba(51, 51, 51, 0.1)',
        boxShadow: '0px 0px 19px -1px rgba(136, 136, 136, 0.07)'
    },
    findingBoxWrapper: {
        boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.3)',
        borderRadius: '4px',
        position: 'relative'
    },
    selectedFindingBox: {
        '& img': {
            filter: `invert(100%) sepia(100%) brightness(150%) contrast(102%)`,
        },
        backgroundColor: `${theme.palette.v2.primary.main} !important`
    },
    saveButton: {
        width: "190px",
        height: "40px",
        background: theme.palette.v2.primary.main
    },
    cancelButton: {
        width: "134px",
        height: "40px",
        marginRight: "20px",
        textTransform: "none",
        color: theme.palette.v2.primary.main,
        border: `1px solid ${theme.palette.v2.primary.main}`
    },
    toolTipPopper: {
        zIndex: 1024
    }
}));

type examConstantInterface = {
    key?: string;
    title?: string;
    risks?: Array<string>;
    examType?: Array<string>;
    findingTypes?: Array<string>;
    examGroup?: string;
    options?: Array<any>;
};

type Props = {
    findingInfo: any
}

const OdontogramFindingTooltip = (props: Props) => {
    const {findingInfo} = props as any
    const classes = useStyles();
    const dispatch = useDispatch()
    const location = useLocation()
    const isWellnessProfile = location?.pathname?.includes('/v2/dentist/wellness-profile/')
    const {
        odontogram: {
            editFindingData,
            finding,
            teeth,
            showForm,
            cameOdontogramFromTreatment,
            openOdontogramGroupFindingPopup
        },
        examFindingReducer: {examFindingData, addExistingFindingData},
        appointmentsReducer: {appointmentDetails},
        clinicalExamFilters: {riskFilters, findingType},
        wellnessProfileFilters: {wellnessProfileStep, wellnessProfileData}
    } = useSelector((state: any) => state) as any;
    const tabData = isWellnessProfile ? wellnessProfileStep : findingType
    const [chartFindingData, setChartFindingData] = useState(null) as any

    useEffect(() => {
        if (examFindingData && finding) {
            const newChartFindingData = JSON.parse(JSON.stringify(examFindingData)) as any
            const currentFindingData = findingData?.filter((f: any) => f?.key === finding)[0] as any
            const findingToBeAdd = {} as any
            if (editFindingData?.ID) {
                findingToBeAdd.ID = editFindingData?.ID
            }
            currentFindingData?.options?.map((opt: any) => {
                if (editFindingData?.key === currentFindingData?.key) {
                    findingToBeAdd[opt?.key] = editFindingData[opt?.key] || null
                } else {
                    if (opt?.key !== 'tooth_number') {
                        findingToBeAdd[opt?.key] = null
                    }
                }
                return true
            })
            if (newChartFindingData && newChartFindingData[currentFindingData?.examGroup] && newChartFindingData[currentFindingData?.examGroup][finding]) {
                newChartFindingData[currentFindingData?.examGroup][finding][0] = findingToBeAdd
                setChartFindingData(newChartFindingData)
            }
        }
    }, [examFindingData, finding, editFindingData])

    const getData = () => {
        const examType = appointmentDetails?.appointmentType;
        const findingTypeWiseFilteredData = findingData?.filter((f: examConstantInterface) =>
            f?.findingTypes?.includes(findingTypes[tabData])
        ) as any;
        const examTypeWiseFilteredData = findingTypeWiseFilteredData?.filter((f: examConstantInterface) => f?.examType?.includes(examType));
        return (isWellnessProfile ? findingData : examTypeWiseFilteredData)?.filter((f: examConstantInterface) =>
            findingInfo?.type === 'group' ? f?.key === finding : f?.key === findingInfo?.key);
    };

    const getMultipleFindingsData = (res: any) => {
        if (res?.isMulti && res?.examGroup && examFindingData && examFindingData[res?.examGroup] && examFindingData[res?.examGroup][res?.key]) {
            const findingLength = examFindingData[res?.examGroup][res?.key]?.length
            const newFindings = []
            for (let index = findingLength; index >= 0; index--) {
                newFindings?.push(res)
            }
            return newFindings
        }
        return [res]
    }
    const handleToothSelection = (clickedFinding: string, findingType: string) => {
        dispatch(selectOdontrogramFindingAction(clickedFinding))
        if (findingType === 'group') {
            dispatch(openOdontogramGroupFindingPopupAction(openOdontogramGroupFindingPopup ? null : clickedFinding))
            dispatch(selectOdontrogramFindingAction(''))
        } else {
            // setOpenGroupFinding(null)
            if (findingInfo?.group !== 'restro_defect') {
                dispatch(openOdontogramGroupFindingPopupAction(null))
            }
            if (clickedFinding === finding) {
                dispatch(selectOdontrogramFindingAction(''))
            } else {
                if (teeth?.length >= 1) {
                    dispatch(selectOdontrogramFindingAction(clickedFinding))
                    let updatedExamFindingData = removeRefFromJson(examFindingData) as any;
                    if (clickedFinding === 'missing_tooth') {
                        teeth?.map((t: any) => {
                            if (!updatedExamFindingData.occlusion_exam[`${clickedFinding}_tooth_number`]?.includes(`${t}`)) {
                                let selectedTeeth: any
                                if (typeof updatedExamFindingData?.occlusion_exam[`${clickedFinding}_tooth_number`] === 'string') {
                                    selectedTeeth = stringToArray(updatedExamFindingData?.occlusion_exam[`${clickedFinding}_tooth_number`]) || [] as string[]
                                } else {
                                    selectedTeeth = updatedExamFindingData?.occlusion_exam[`${clickedFinding}_tooth_number`] || [] as string[]
                                }
                                //add newly selected tooth to array
                                teeth?.map((res: number) => {
                                    selectedTeeth?.push(`${res}`)
                                    return true
                                })
                                updatedExamFindingData.occlusion_exam[`${clickedFinding}_tooth_number`] = selectedTeeth
                                dispatch(setExamFindingsAction(updatedExamFindingData));
                            }

                            return true
                        })
                        dispatch(selectOdontrogramToothAction([]))
                        dispatch(selectOdontrogramFindingAction(''))
                    }
                }
            }
        }
    }
    const optionType = (option: any) => {
        let newOption = option as any
        if (option.type === 'multi-select') {
            let type = 'checkbox'
            newOption = {
                ...option,
                type
            }
        }
        if (option.type === "select") {
            let type = 'radio'
            newOption = {
                ...option,
                type
            }
        }
        return newOption
    }
    const handleSubmit = () => {
        const updatedChartFindingData = JSON.parse(JSON.stringify(examFindingData)) as any;
        const selectedTeeth = teeth?.toString()?.split(",");
        const currentFindingData = findingData?.find((f: any) => f.key === finding) as any;
        const findingToBeAdd = {
            tooth_number: selectedTeeth, ...currentFindingData.options.reduce((acc: any, opt: any) => {
                if (opt.key !== 'tooth_number') {
                    acc[opt.key] = chartFindingData[currentFindingData.examGroup][finding][0][opt.key] || null;
                }
                return acc;
            }, {})
        };
        //Check validations
        if (Object.keys(findingToBeAdd)?.filter((f) => (isArray(findingToBeAdd[f]) ? findingToBeAdd[f]?.length > 0 : findingToBeAdd[f]))?.length < Object.keys(findingToBeAdd).filter((f) => !['grade', 'notes'].includes(f))?.length) {
            errorToaster(`You haven't completed all the fields. Please fill data in all the fields to add a finding.`)
        } else {
            if (editFindingData?.ID) {
                findingToBeAdd.ID = editFindingData?.ID
            }
            //Particulate finding where data will add(New) or updated(Edit)
            let updatedFindingData = updatedChartFindingData[currentFindingData.examGroup][finding];
            //dynamicIndex will help to identify the index of finding's data which need to be updated
            let dynamicIndex = editFindingData ? updatedFindingData?.findIndex((item: any) => {
                if (item) {
                    return Object.keys(item)?.filter((r: any) => JSON.stringify(item[r]) === JSON.stringify(editFindingData[r]))?.length === Object.keys(item)?.length
                } else {
                    return false
                }
            }) : -1;
            if (updatedFindingData) {
                if (dynamicIndex >= 0) {
                    // For edit
                    updatedFindingData[dynamicIndex] = findingToBeAdd
                } else {
                    selectedTeeth?.map((st: string) => {
                        const updatedFindingToBeAdd = {...findingToBeAdd, tooth_number: [`${st}`]} as any
                        const alreadyPresentDataIndex = updatedFindingData?.findIndex((f: any) => Object.keys(updatedFindingToBeAdd).filter((r: any) => (isArray(updatedFindingToBeAdd[r]) ? updatedFindingToBeAdd[r]?.toString() : updatedFindingToBeAdd[r]) === (isArray(f[r]) ? f[r]?.toString() : f[r])).length === Object.keys(updatedFindingToBeAdd).length)
                        // if (alreadyPresentDataIndex < 0) {
                        updatedFindingData.push({...findingToBeAdd, tooth_number: [`${st}`]})
                        // }
                        return true
                    })
                }
            } else {
                const findingGroupTobeAdded = [] as any
                selectedTeeth?.map((st: string) => {
                    findingGroupTobeAdded.push({...findingToBeAdd, tooth_number: [`${st}`]})
                    return true
                })
                updatedFindingData = findingGroupTobeAdded;
            }
            updatedChartFindingData[currentFindingData.examGroup][finding] = updatedFindingData;
            dispatch(setExamFindingsAction(updatedChartFindingData));
            setChartFindingData(updatedChartFindingData);
            if (getData()[0]?.examGroup === 'existing_treatment') {
                if (!addExistingFindingData?.includes(finding)) {
                    const updatedAddExistingFindingData = addExistingFindingData ? [...addExistingFindingData, finding] : [finding];
                    dispatch(addExistingFindingsDataAction(updatedAddExistingFindingData));
                    dispatch(checkedExistingFindingsDataAction(updatedAddExistingFindingData));
                }
            }
            dispatch(setEditOdontogramFindingDataAction(null));
            dispatch(selectOdontrogramFindingAction(''));
            dispatch(selectOdontrogramToothAction([]));
            dispatch(openOdontogramGroupFindingPopupAction(null))
        }
    };
    const disabledField = useMemo(() => {
        return (
            (!cameOdontogramFromTreatment || (cameOdontogramFromTreatment && !findingInfo?.procedures?.includes('Planned'))) &&
            ([completeCheckout, viewExam]?.includes(appointmentDetails?.sub_status) ||
            isWellnessProfile ? wellnessProfileData?.is_wellness_locked : (!getData()[0]?.examType?.includes(appointmentDetails?.appointmentType)) ||
                (riskFilters?.length ? (getData()[0] && getData()[0]?.risks?.filter((ff: string) => riskFilters?.includes(ff))?.length) === 0 : false)) ||
            (
                (tabData === odontogram || tabData === periogram) &&
                appointmentDetails?.pms_appointment_no !== null &&
                !PMSEnableFindingsForOdontogram?.includes((findingInfo?.key))
            )
        )
    }, [cameOdontogramFromTreatment,wellnessProfileData?.is_wellness_locked, appointmentDetails?.sub_status, appointmentDetails?.appointmentType, riskFilters?.length, appointmentDetails, location?.pathname]);

    const selectFinding = (e: any) => {
        const {value} = e.target as any;
        dispatch(selectOdontrogramFindingAction(value))
    }

    return (
        <React.Fragment>
            {<Tooltip
                className={classes.tooltip}
                classes={{tooltip: classes.customWidth, arrow: classes.tooltip, popper: classes.toolTipPopper}}
                open={(findingInfo?.type === 'group' ? openOdontogramGroupFindingPopup === findingInfo?.key : (findingInfo?.key === finding && findingInfo?.group !== 'restro_defect')) && showForm && teeth?.length >= 1 && finding !== 'missing_tooth'}
                placement="right-start"
                title={
                    <React.Fragment>
                        <Box className={classes.findingTooltip}>
                            {findingInfo?.type === 'group' && <Grid container columnSpacing={1} rowSpacing={1} mb={1}>
                                <Grid item
                                      xs={12}
                                      sm={12}
                                      md={12}
                                      lg={12}
                                      xl={12}
                                >
                                    <Typography color={theme.palette.v2.primary.main}
                                                className='f-w-500 fw-medium f-14 lh-16'>
                                        {'Select Finding'}
                                    </Typography>
                                    {FindingList?.filter((f: any) => f?.group === 'restro_defect')?.map((res: any, index: number) => (
                                        <OdontogramFindingTooltip findingInfo={res} key={index}/>
                                    ))}
                                </Grid>
                            </Grid>
                            }
                            {finding && <Grid container columnSpacing={1} rowSpacing={1}>
                                {
                                    getData()[0]?.options?.map((option: any, index: number) => <React.Fragment
                                        key={index}>
                                        <ExamsCustomFields
                                            exam={getData()[0]?.examGroup}
                                            fieldData={optionType(option)}
                                            finding={getData()[0]?.key} index={0}
                                            isMulti={getData()[0]?.isMulti}
                                            findingData={getMultipleFindingsData(getData()[0])}
                                            disableFindingTypes={option?.disableInFindingType}
                                            fromChart={true}
                                            chartFindingData={chartFindingData}
                                            handleChartChange={setChartFindingData}
                                        />
                                    </React.Fragment>)
                                }
                            </Grid>}
                        </Box>
                        {finding && <Box pt={2} display={"flex"}
                                         justifyContent={"center"}
                                         alignItems={"center"}>
                            <Button
                                variant={"outlined"}
                                className={`fw-regular ${classes.cancelButton}`}
                                onClick={() => {
                                    if (editFindingData) {
                                        dispatch(selectOdontrogramToothAction([]));
                                    }
                                    dispatch(setEditOdontogramFindingDataAction(null));
                                    dispatch(selectOdontrogramFindingAction(''));
                                    dispatch(openOdontogramGroupFindingPopupAction(null))
                                    setChartFindingData(examFindingData)

                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                variant={"contained"}
                                className={`button fw-regular ${classes.saveButton}`}
                                onClick={handleSubmit}
                            >
                                Save
                            </Button>
                        </Box>}
                    </React.Fragment>
                }
            >
                {findingInfo?.group !== 'restro_defect' ? <Box
                        mt={1}
                        height={'30px'}
                        width={'30px'}
                        className={`${classes.findingBoxWrapper} d-flex-all-center ${(findingInfo?.type === 'group' ? openOdontogramGroupFindingPopup === findingInfo.key : (finding === findingInfo.key)) ? classes.selectedFindingBox : ''} `}
                        sx={{cursor: ((teeth?.length >= 1) && (!disabledField || findingInfo?.type === 'group')) ? 'pointer' : 'not-allowed',}}
                        onClick={() => ((!disabledField || findingInfo?.type === 'group') && (teeth?.length >= 1)) ? handleToothSelection(findingInfo?.key, findingInfo?.type) : ''}
                    >
                        {<Tooltip title={findingInfo?.title} placement="right-start">
                            <img src={findingInfo?.icon} alt={findingInfo?.title}/>
                        </Tooltip>}
                    </Box> :
                    <RadioSelect
                        disabled={disabledField}
                        options={[
                            {
                                label: findingInfo?.title,
                                value: findingInfo?.key
                            },
                        ]}
                        groupProps={{
                            name: 'finding',
                            id: 'finding',
                            value: finding,
                            onChange: (e: any) => selectFinding(e)
                        }}
                    />}
            </Tooltip>}
        </React.Fragment>
    );
};

export default OdontogramFindingTooltip;
