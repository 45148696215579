//dashboard table data
export const DASHBOARD_APPOINTMENT_STAFF_REQUEST = 'DASHBOARD_APPOINTMENT_STAFF_REQUEST-';
export const DASHBOARD_APPOINTMENT_STAFF_SUCCESS = 'DASHBOARD_APPOINTMENT_STAFF_SUCCESS';
export const DASHBOARD_APPOINTMENT_STAFF_FAILURE = 'DASHBOARD_APPOINTMENT_STAFF_FAILURE';
export const DASHBOARD_APPOINTMENT_STAFF_CACHE_DATA_CLEAR_REQUEST = 'DASHBOARD_APPOINTMENT_STAFF_CACHE_DATA_CLEAR_REQUEST';

//dashboard top data
export const DASHBOARD_APPOINTMENT_STAFF_COUNT_REQUEST = 'DASHBOARD_APPOINTMENT_STAFF_COUNT_REQUEST';
export const DASHBOARD_APPOINTMENT_STAFF_COUNT_SUCCESS = 'DASHBOARD_APPOINTMENT_STAFF_COUNT_SUCCESS';
export const DASHBOARD_APPOINTMENT_STAFF_COUNT_FAILURE = 'DASHBOARD_APPOINTMENT_STAFF_COUNT_FAILURE';
export const DASHBOARD_APPOINTMENT_STAFF_COUNT_CACHE_DATA_CLEAR_REQUEST =
    'DASHBOARD_APPOINTMENT_STAFF_COUNT_CACHE_DATA_CLEAR_REQUEST';

export const SET_DESCENDING_ASCENDING = 'SET_DESCENDING_ASCENDING'
