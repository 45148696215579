import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Typography } from '@mui/material'
import React from 'react'
import theme from '../../../../../../../theme'


interface Props {
  isContour: any
}
const OdontogramContourFinding = (props: Props) => {
  const { isContour } = props
  const { palette } = theme

  return (
    <React.Fragment>
      {isContour?.hasTooth && isContour?.contoursSubType === 'Over contoured' &&
        <Typography className='pos-absolute'
          sx={{
            right: '42%',
            bottom: '49%'
          }}>
          <FontAwesomeIcon icon={faPlus} color={palette.v2.primary.main} />
        </Typography>

      }
      {
        isContour?.hasTooth && isContour?.contoursSubType === 'Under contoured' &&
        <Typography className='pos-absolute'
          sx={{
            right: '42%',
            bottom: '49%'
          }} >
          <FontAwesomeIcon icon={faMinus} color={palette.v2.primary.main} />
        </Typography>
      }
    </React.Fragment >
  )
}
export default OdontogramContourFinding