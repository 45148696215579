export const UPDATE_RISK_ASSESSMENT = 'UPDATE_RISK_ASSESSMENT';

export const GET_RISK_ASSESSMENT_REQUEST = 'GET_RISK_ASSESSMENT_REQUEST';
export const GET_RISK_ASSESSMENT_REQUEST_SUCCESS = 'GET_RISK_ASSESSMENT_REQUEST_SUCCESS';
export const GET_RISK_ASSESSMENT_REQUEST_FAILURE = 'GET_RISK_ASSESSMENT_REQUEST_FAILURE';

export const PUT_RISK_ASSESSMENT_REQUEST = 'PUT_RISK_ASSESSMENT_REQUEST';
export const PUT_RISK_ASSESSMENT_REQUEST_SUCCESS = 'PUT_RISK_ASSESSMENT_REQUEST_SUCCESS';
export const PUT_RISK_ASSESSMENT_REQUEST_FAILURE = 'PUT_RISK_ASSESSMENT_REQUEST_FAILURE';

export const UPDATE_RISK_ASSESSMENT_VALIDATION = 'UPDATE_RISK_ASSESSMENT_VALIDATION';

export const GENERATE_RISK_ASSESSMENT_REQUEST = 'GENERATE_RISK_ASSESSMENT';
export const GENERATE_RISK_ASSESSMENT_REQUEST_SUCCESS = 'GENERATE_RISK_ASSESSMENT_REQUEST_SUCCESS';
export const GENERATE_RISK_ASSESSMENT_REQUEST_FAILURE = 'GENERATE_RISK_ASSESSMENT_REQUEST_FAILURE';
