import {
    UPDATE_EXTRA_ORAL_DENTIST, UPDATE_EXTRA_ORAL_DENTIST_FAILURE,
    CHANGE_EXTRA_ORAL_DENTIST
} from "../../../../ActionTypes/v1/dentist/clinicalExamDentist";
import { ExtraOralDentistInterface } from "../../../../types/dentistTypes";

export const updateExtraOralRequest = (
    payload: ExtraOralDentistInterface
): ExtraOralDentistInterface => ({
    type: UPDATE_EXTRA_ORAL_DENTIST,
    payload,
}) as any;

export const updateExtraOralRequestFailure = (): any => ({
    type: UPDATE_EXTRA_ORAL_DENTIST_FAILURE,
}) as any;

export const changeExtraOralFindingAction = (payload: any): any => ({
    type: CHANGE_EXTRA_ORAL_DENTIST,
    payload
}) as any;