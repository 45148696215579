export const PATIENT_EMAIL_MOBILE_DATA = 'PATIENT_EMAIL_MOBILE_DATA';
export const PATIENT_EMAIL_MOBILE_ERROR = 'PATIENT_EMAIL_MOBILE_ERROR';

export const PATIENT_QR_CODE_DATA = 'PATIENT_QR_CODE_DATA';
export const PATIENT_QR_CODE_ERROR = 'PATIENT_QR_CODE_ERROR';

export const PATIENT_EMAIL_VERIFY_OTP_REQUEST = 'PATIENT_EMAIL_VERIFY_OTP_REQUEST';
export const PATIENT_EMAIL_VERIFY_OTP_REQUEST_SUCCESS = 'PATIENT_EMAIL_VERIFY_OTP_REQUEST_SUCCESS';
export const PATIENT_EMAIL_VERIFY_OTP_REQUEST_FAILURE = 'PATIENT_EMAIL_VERIFY_OTP_REQUEST_FAILURE';

export const PATIENT_EMAIL_SEND_OTP_REQUEST = 'PATIENT_EMAIL_SEND_OTP_REQUEST';
export const PATIENT_EMAIL_SEND_OTP_REQUEST_SUCCESS = 'PATIENT_EMAIL_SEND_OTP_REQUEST_SUCCESS';
export const PATIENT_EMAIL_SEND_OTP_REQUEST_FAILURE = 'PATIENT_EMAIL_SEND_OTP_REQUEST_FAILURE';

export const PATIENT_QR_SUBMIT_DATA_REQUEST = 'PATIENT_QR_SUBMIT_DATA_REQUEST';
export const PATIENT_QR_SUBMIT_DATA_REQUEST_SUCCESS = 'PATIENT_QR_SUBMIT_DATA_REQUEST_SUCCESS';
export const PATIENT_QR_SUBMIT_DATA_REQUEST_FAILURE = 'PATIENT_QR_SUBMIT_DATA_REQUEST_FAILURE';