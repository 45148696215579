import {
  PRIVACY_POLICY_V2_REQUEST,
  PRIVACY_POLICY_V2_REQUEST_FAILURE,
  PRIVACY_POLICY_V2_REQUEST_SUCCESS
} from '../../../ActionTypes/v2/auth';
import { privacyPolicyActions, privacyPolicyInterface } from '../../../types/v2/authActionTypes/privacyPolicyActionTypes';

const initialState: privacyPolicyInterface = {
  privacyPolicyLoader: false,
  privacyPolicyData: null,
  privacyPolicyError: null
};

const privacyPolicyReducer = (state = initialState, action: privacyPolicyActions) => {
  switch (action.type) {
    case PRIVACY_POLICY_V2_REQUEST:
      return {
        ...state,
        privacyPolicyLoader: true
      };
    case PRIVACY_POLICY_V2_REQUEST_SUCCESS:
      return {
        ...state,
        privacyPolicyLoader: false,
        privacyPolicyData: action.payload,
        privacyPolicyError: null
      };
    case PRIVACY_POLICY_V2_REQUEST_FAILURE:
      return {
        ...state,
        privacyPolicyLoader: false,
        privacyPolicyData: null,
        privacyPolicyError: action.payload.privacyPolicyError
      };

    default:
      return {
        ...state
      };
  }
};
export default privacyPolicyReducer;
