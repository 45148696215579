import {
    GET_INTRA_ORAL_DENTIST_RECALL_EXAM_REQUEST,
    GET_INTRA_ORAL_DENTIST_RECALL_EXAM_REQUEST_SUCCESS,
    GET_INTRA_ORAL_DENTIST_RECALL_EXAM_REQUEST_FAILURE,

    PUT_INTRA_ORAL_DENTIST_RECALL_EXAM_REQUEST,
    PUT_INTRA_ORAL_DENTIST_RECALL_EXAM_REQUEST_SUCCESS,
    PUT_INTRA_ORAL_DENTIST_RECALL_EXAM_REQUEST_FAILURE,
} from "../../../../ActionTypes/v1/dentist/clinicalExamDentistRecallExam";

import {
    GetIntraOralDentistRecallExamRequestPayload,
    GetIntraOralDentistRecallExamRequestSuccessPayload,
    GetIntraOralDentistRecallExamRequestFailurePayload,
    GetIntraOralDentistRecallExamRequest,
    GetIntraOralDentistRecallExamRequestSuccess,
    GetIntraOralDentistRecallExamRequestFailure,

    PutIntraOralDentistRecallExamRequest,
    PutIntraOralDentistRecallExamRequestSuccess,
    PutIntraOralDentistRecallExamRequestFailure,
    PutIntraOralDentistRecallExamRequestPayload,
    PutIntraOralDentistRecallExamRequestSuccessPayload,
    PutIntraOralDentistRecallExamRequestFailurePayload

} from "../../../../types/dentistTypes";

export const getIntraOralDentistRecallExamRequest = (payload: GetIntraOralDentistRecallExamRequestPayload): GetIntraOralDentistRecallExamRequest => ({
    type: GET_INTRA_ORAL_DENTIST_RECALL_EXAM_REQUEST,
    payload
}) as any

export const getIntraOralDentistRecallExamDataRequestSuccess = (payload: GetIntraOralDentistRecallExamRequestSuccessPayload): GetIntraOralDentistRecallExamRequestSuccess => ({
    type: GET_INTRA_ORAL_DENTIST_RECALL_EXAM_REQUEST_SUCCESS,
    payload
}) as any

export const getIntraOralDentistRecallExamDataRequestFailure = (payload: GetIntraOralDentistRecallExamRequestFailurePayload): GetIntraOralDentistRecallExamRequestFailure => ({
    type: GET_INTRA_ORAL_DENTIST_RECALL_EXAM_REQUEST_FAILURE,
    payload
}) as any

export const putIntraOralDentistRecallExamRequest = (payload: PutIntraOralDentistRecallExamRequestPayload): PutIntraOralDentistRecallExamRequest => ({
    type: PUT_INTRA_ORAL_DENTIST_RECALL_EXAM_REQUEST,
    payload
}) as any

export const putIntraOralDentistRecallExamDataRequestSuccess = (payload: PutIntraOralDentistRecallExamRequestSuccessPayload): PutIntraOralDentistRecallExamRequestSuccess => ({
    type: PUT_INTRA_ORAL_DENTIST_RECALL_EXAM_REQUEST_SUCCESS,
    payload
}) as any

export const putIntraOralDentistRecallExamDataRequestFailure = (payload: PutIntraOralDentistRecallExamRequestFailurePayload): PutIntraOralDentistRecallExamRequestFailure => ({
    type: PUT_INTRA_ORAL_DENTIST_RECALL_EXAM_REQUEST_FAILURE,
    payload
}) as any
