import {
    MobileVerificationActions,
    MobileVerificationInterface
} from "../../../types/v2/common/mobileVerificarionActionTypes";
import {UPDATE_OTP, UPDATE_OTP_VALIDATION} from "../../../ActionTypes/v2/common/mobileVerification";


const initialState: MobileVerificationInterface = {
    otp: null,
    otpError: {}
};
const mobileVerificationReducer = (state = initialState, action: MobileVerificationActions) => {
    switch (action.type) {
        case UPDATE_OTP:
            return {
                ...state,
                otp: action.payload,
            };
        case UPDATE_OTP_VALIDATION:
            return {
                ...state,
                otpError: action.payload,
            };
        default:
            return {
                ...state
            };
    }
};
export default mobileVerificationReducer;
