import {
    CHANGE_RISK_DIALS_VIEW,
    GET_RISK_FILTER_SCORE_REQUEST,
    GET_RISK_FILTER_SCORE_REQUEST_FAILURE,
    GET_RISK_FILTER_SCORE_REQUEST_SUCCESS,
    LOADER_SCREEN_TIMING_STATE,
    OPEN_ADDITIONAL_NOTES,
    OPEN_LOADING_SCREEN,
    PUT_ADDITIONAL_NOTES_REQUEST,
    PUT_ADDITIONAL_NOTES_REQUEST_FAILURE,
    PUT_ADDITIONAL_NOTES_REQUEST_SUCCESS,
    SAVE_FINDING_TYPE_STEP,
    SELECT_IMAGE_ANNOTATION,
    SELECT_RISK_DATA,
    UPDATE_APPOINTMENT_STEP,
    UPDATE_EXAM_ACCORDION,
    UPDATE_EXAM_FORM_SIZE,
    UPDATE_FINDING_TYPE,
    UPDATE_OPEN_COMPLETE_STEP,
    UPDATE_RISK_DATA,
    UPDATE_RISK_FILTERS,
    SEND_WELLNESS_EMAIL_LINK_REQUEST,
    SEND_WELLNESS_EMAIL_LINK_REQUEST_SUCCESS,
    SEND_WELLNESS_EMAIL_LINK_REQUEST_FAILURE,
    SYNC_IMAGE_XRAY_REQUEST,
    SYNC_IMAGE_XRAY_REQUEST_SUCCESS,
    SYNC_IMAGE_XRAY_REQUEST_FAILURE
} from '../../../../ActionTypes/v2/dentist/clinicalExam/clinicalExamFilters';
import {
    ChangeRiskDialsViewRequest,
    getRiskFilterScoreFailurePayload,
    getRiskFilterScoreRequest,
    getRiskFilterScoreRequestFailure,
    getRiskFilterScoreRequestPayload,
    getRiskFilterScoreRequestSuccess,
    getRiskFilterScoreRequestSuccessPayload,
    loadingScreenTiming,
    openAdditionalNotes,
    openLoadingScreen,
    putAdditionalNotesFailurePayload,
    putAdditionalNotesRequest,
    putAdditionalNotesRequestFailure,
    putAdditionalNotesRequestPayload,
    putAdditionalNotesRequestSuccess,
    putAdditionalNotesRequestSuccessPayload,
    saveFindingTypeStep,
    selectImageAnnotation,
    SelectRiskDataRequest,
    sendWellnessEmailLinkFailurePayload,
    sendWellnessEmailLinkRequest,
    sendWellnessEmailLinkRequestFailure,
    sendWellnessEmailLinkRequestPayload,
    sendWellnessEmailLinkRequestSuccess,
    sendWellnessEmailLinkRequestSuccessPayload,
    SyncImageXrayRequest,
    SyncImageXrayRequestPayload,
    UpdateAppointmentStepPayload,
    UpdateAppointmentStepRequest,
    UpdateExamAccordionRequest,
    UpdateExamFormSizePayload,
    UpdateExamFormSizeRequest,
    UpdateFindingTypeRequest,
    UpdateRiskDataRequest,
    UpdateRiskFiltersPayload,
    UpdateRiskFiltersRequest
} from '../../../../types/v2/dentist/clinicalExam/clinicalExamFilters';

export const updateAppointmentStepAction = (payload: UpdateAppointmentStepPayload): UpdateAppointmentStepRequest => ({
    type: UPDATE_APPOINTMENT_STEP,
    payload
});

export const updateRiskFiltersAction = (payload: UpdateRiskFiltersPayload): UpdateRiskFiltersRequest => ({
    type: UPDATE_RISK_FILTERS,
    payload
});

export const updateFindingTypeAction = (payload: string | null): UpdateFindingTypeRequest => ({
    type: UPDATE_FINDING_TYPE,
    payload
});

export const updateExamAccordionAction = (payload: boolean): UpdateExamAccordionRequest => ({
    type: UPDATE_EXAM_ACCORDION,
    payload
});

export const updateExamFormSizeAction = (payload: UpdateExamFormSizePayload): UpdateExamFormSizeRequest => ({
    type: UPDATE_EXAM_FORM_SIZE,
    payload
});
export const selectImageAnnotationAction = (payload: any): selectImageAnnotation => ({
    type: SELECT_IMAGE_ANNOTATION,
    payload
});

export const updateOpenCompleteStepAction = (payload: any): any => ({
    type: UPDATE_OPEN_COMPLETE_STEP,
    payload
});

export const updateLoadingScreenAction = (payload: any): openLoadingScreen => ({
    type: OPEN_LOADING_SCREEN,
    payload
});

export const loadingScreenTimingAction = (payload: any): loadingScreenTiming => ({
    type: LOADER_SCREEN_TIMING_STATE,
    payload
});

export const getRiskFilterScoreRequestAction = (payload: getRiskFilterScoreRequestPayload): getRiskFilterScoreRequest => ({
    type: GET_RISK_FILTER_SCORE_REQUEST,
    payload
});

export const getRiskFilterScoreRequestSuccessAction = (
    payload: getRiskFilterScoreRequestSuccessPayload
): getRiskFilterScoreRequestSuccess => ({
    type: GET_RISK_FILTER_SCORE_REQUEST_SUCCESS,
    payload
});

export const getRiskFilterScoreRequestFailureAction = (
    payload: getRiskFilterScoreFailurePayload
): getRiskFilterScoreRequestFailure => ({
    type: GET_RISK_FILTER_SCORE_REQUEST_FAILURE,
    payload
});

//open additional notes popup
export const openAdditionalNotesAction = (payload: any): openAdditionalNotes => ({
    type: OPEN_ADDITIONAL_NOTES,
    payload
});

export const putAdditionalNotesRequestAction = (payload: putAdditionalNotesRequestPayload): putAdditionalNotesRequest => ({
    type: PUT_ADDITIONAL_NOTES_REQUEST,
    payload
});

export const putAdditionalNotesRequestSuccessAction = (
    payload: putAdditionalNotesRequestSuccessPayload
): putAdditionalNotesRequestSuccess => ({
    type: PUT_ADDITIONAL_NOTES_REQUEST_SUCCESS,
    payload
});

export const putAdditionalNotesRequestFailureAction = (
    payload: putAdditionalNotesFailurePayload
): putAdditionalNotesRequestFailure => ({
    type: PUT_ADDITIONAL_NOTES_REQUEST_FAILURE,
    payload
});

export const saveFindingTypeStepAction = (payload: string | null): saveFindingTypeStep => ({
    type: SAVE_FINDING_TYPE_STEP,
    payload
});

export const changeRiskDialsViewRequestAction = (payload: boolean): ChangeRiskDialsViewRequest => ({
    type: CHANGE_RISK_DIALS_VIEW,
    payload
});

export const selectRiskDataRequestAction = (payload: any): SelectRiskDataRequest => ({
    type: SELECT_RISK_DATA,
    payload
});

export const updateRiskDataRequestAction = (payload: any): UpdateRiskDataRequest => ({
    type: UPDATE_RISK_DATA,
    payload
})

export const sendWellnessEmailLinkRequestAction = (
    payload: sendWellnessEmailLinkRequestPayload): sendWellnessEmailLinkRequest => ({
        type: SEND_WELLNESS_EMAIL_LINK_REQUEST,
        payload
    });

export const sendWellnessEmailLinkRequestSuccessAction = (
    payload: sendWellnessEmailLinkRequestSuccessPayload
): sendWellnessEmailLinkRequestSuccess => ({
    type: SEND_WELLNESS_EMAIL_LINK_REQUEST_SUCCESS,
    payload
});

export const sendWellnessEmailLinkRequestFailureAction = (
    payload: sendWellnessEmailLinkFailurePayload
): sendWellnessEmailLinkRequestFailure => ({
    type: SEND_WELLNESS_EMAIL_LINK_REQUEST_FAILURE,
    payload
});

export const syncImageXrayRequestAction = (payload: SyncImageXrayRequestPayload): SyncImageXrayRequest => ({
    type: SYNC_IMAGE_XRAY_REQUEST,
    payload
});
export const syncImageXrayRequestSuccessAction = () => ({
    type: SYNC_IMAGE_XRAY_REQUEST_SUCCESS,
});
export const syncImageXrayRequestFailureAction = () => ({
    type: SYNC_IMAGE_XRAY_REQUEST_FAILURE,
});