import patientAuthReducer from './patientReducer/authReducer/authReducer';
import dentistAuthReducer from './dentistReducer/authReducer/authReducer';
import preIntakeReducer from './preIntakeReducer/preIntakeReducer';
import { postPreIntakeDataReducer } from './preIntakeReducer/postPreIntakeDataReducer';
import patientProfileReducer from './patientReducer/patientProfileReducer';
import patientAppointmentReducer from './patientReducer/patientAppointmentReducer';
import patientRiskProfileReducer from './patientReducer/patientRiskProfileReducer';
import getClinicReducer from './dentistReducer/clinicReducer/clinicReducer';
import getPatientListReducer from './dentistReducer/clinicReducer/patientListReducer';
import verifyPasswordReducer from './dentistReducer/clinicReducer/verifyPasswordReducer';
import dentistPreIntakeReducer from './preIntakeReducer/dentistPreIntakeReducer';
import { postDentistPreIntakeDataReducer } from './preIntakeReducer/postDentistPreIntakeDataReducer';
import { postClinicalExamAssistantDataReducer } from './dentistReducer/clinicalExamAssistantReducer/postClinicalExamAssistantReducer';
import clinicalExamAssistantReducer from './dentistReducer/clinicalExamAssistantReducer/clinicalExamAssistantReducer';
import { postClinicalExamDentistDataReducer } from './dentistReducer/clinicExamDentistReducer/postClinicalExamDentistDataReducer';
import getRecentXrayReducer from './dentistReducer/clinicExamDentistReducer/recentXrayReducer';
import radiographicAnalysisAndFindingReducer from './dentistReducer/clinicExamDentistReducer/radiographicAnalysisAndFindingReducer';
import PostXrayDataReducer from './dentistReducer/clinicExamDentistReducer/postXrayTagDataReducer';
import staffProfileReducer from './dentistReducer/staffReducer/staffReducer';
import { getSetPreIntakeErrorReducer } from './preIntakeReducer/getSetPreintakeErrorReducer';
import otpReducer from './otpReducer/otpReducer';
import clinicalExamDentistReducer from './dentistReducer/clinicExamDentistReducer/clinicalExamDentistReducer';
import { periodontalReducer } from './dentistReducer/clinicExamDentistReducer/periodontalReducer';
import { medicalHistoryDentistReducer } from './dentistReducer/clinicExamDentistReducer/medicalHistoryDentistReducer';
import { extraOralDentistReducer } from './dentistReducer/clinicExamDentistReducer/extraOralDentistReducer';
import { existingTreatmentReducer } from './dentistReducer/clinicalExamAssistantReducer/existingTreatmentAssistantReducer';
import { tmjDentistReducer } from './dentistReducer/clinicExamDentistReducer/tmjDentistReducer';
import { muscleDentistReducer } from './dentistReducer/clinicExamDentistReducer/muscleDentistReducer';
import { intraOralDentistReducer } from './dentistReducer/clinicExamDentistReducer/intraOralDentistReducer';
import { occlusionDentistReducer } from './dentistReducer/clinicExamDentistReducer/OcclusionDentistReducer';
import { wearDentistReducer } from './dentistReducer/clinicExamDentistReducer/wearDentistReducer';
import { occlusionReducer } from './dentistReducer/clinicalExamAssistantReducer/occlusionAssistantReducer';
import { intraOralReducer } from './dentistReducer/clinicalExamAssistantReducer/intraOralAssistantReducer';
import { appointmentInfoReducer } from './dentistReducer/clinicalExamAssistantReducer/appointmentInfoAssistantReducer';
import { extraOralReducer } from './dentistReducer/clinicalExamAssistantReducer/extraOralAssistantReducer';
import { preClinicalInfoReducer } from './dentistReducer/clinicalExamAssistantReducer/preClinicalInfoAssistantReducer';
import { existingTreatmentDentistReducer } from './dentistReducer/clinicExamDentistReducer/existingTreatmentDentistReducer';
import { riskProfileReducer } from './patientReducer/postRiskProfileReducer';
import clinicalExamReducer from './dentistReducer/clinicalExamReducer/clinicalExamReducer';
import getKeyFindingReducer from './dentistReducer/AssignedTreatmentReducer/keyFindingReducer/keyFindingReducer';
import insightRecommendationReducer from './dentistReducer/AssignedTreatmentReducer/insightAndRecommendationReducer/insightRecommendationReducer';
import { UpdateInsightRecommendationReducer } from './dentistReducer/AssignedTreatmentReducer/insightAndRecommendationReducer/updateInsightRecommendationReducer';
import treatmentPlanReducer from './dentistReducer/AssignedTreatmentReducer/treatmentPlanReducer/treatmentPlanReducer';
import readinessBarReducer from './dentistReducer/AssignedTreatmentReducer/readinessBarReducer/readinessBarReducer';
import reviewTreatmentPlanReducer from './dentistReducer/AssignedTreatmentReducer/reviewTreatmentPlanReducer/reviewTreatmentPlanReducer';
import insuranceApprovalReducer from './dentistReducer/insuranceApprovalReducer/insuranceApprovalReducer';
import downloadPdfReducer from './downloadPdfReducer/downloadPdfReducer';
import { intraOralDentalTeamReducer } from './dentistReducer/clinicExamDentistReducer/intraOralDentalTeamReducer';
import { occlusionDentistRecallFlowReducer } from './dentistReducer/clinicExamDentistReducer/occlusionDentistRecallFlowReducer';
import { periodontalDentistRecallFlowReducer } from './dentistReducer/clinicExamDentistReducer/periodontalDentistRecallExamReducer';
import intraOralDentistRecallExamReducer from './dentistReducer/clinicalExamDentistRecallFlowReducer/intraOralDentistRecallExamDataReducer';
import occlusionDentistRecallExamReducer from './dentistReducer/clinicalExamDentistRecallFlowReducer/occlusionDentistRecallExamReducer';
import periodontalDentistRecallExamReducer from './dentistReducer/clinicalExamDentistRecallFlowReducer/periodontalDentistRecallExamReducer';
import appointmentTypeReducer from './dentistReducer/clinicalExamReducer/appointmentTypeReducer';
import clinicalExamNotesReducer from './dentistReducer/clinicalExamReducer/clinicalExamNotes';

const v1rootReducer = {
  patientAuth: patientAuthReducer,
  dentistAuth: dentistAuthReducer,
  preIntake: preIntakeReducer,
  postPreIntake: postPreIntakeDataReducer,
  patientProfile: patientProfileReducer,
  patientAppointment: patientAppointmentReducer,
  patientRiskProfile: patientRiskProfileReducer,
  dentistPreIntake: dentistPreIntakeReducer,
  postDentistPreIntake: postDentistPreIntakeDataReducer,
  postClinicalExamAssistant: postClinicalExamAssistantDataReducer,
  Clinic: getClinicReducer,
  PatientList: getPatientListReducer,
  verifyPassword: verifyPasswordReducer,
  clinicalExamAssistant: clinicalExamAssistantReducer,
  postClinicalExamDentist: postClinicalExamDentistDataReducer,
  getRecentXray: getRecentXrayReducer,
  radiographicAnalysisAndFinding: radiographicAnalysisAndFindingReducer,
  PostXrayData: PostXrayDataReducer,
  staffProfile: staffProfileReducer,
  preIntakeError: getSetPreIntakeErrorReducer,
  otpReducer: otpReducer,
  clinicalExamDentist: clinicalExamDentistReducer,
  periodontalReducer: periodontalReducer,
  medicalHistoryDentist: medicalHistoryDentistReducer,
  extraOralDentist: extraOralDentistReducer,
  existingTreatmentReducer: existingTreatmentReducer,
  tmjDentist: tmjDentistReducer,
  muscleDentist: muscleDentistReducer,
  intraOralDentist: intraOralDentistReducer,
  intraOralDentalTeam: intraOralDentalTeamReducer,
  occlusionDentist: occlusionDentistReducer,
  wearDentist: wearDentistReducer,
  occlusionReducer: occlusionReducer,
  intraOralReducer: intraOralReducer,
  appointmentInfoReducer: appointmentInfoReducer,
  extraOralReducer: extraOralReducer,
  preClinicalInfoReducer: preClinicalInfoReducer,
  clinicalExamAssistantReducer: clinicalExamAssistantReducer,
  existingTreatmentDentistReducer: existingTreatmentDentistReducer,
  riskProfileReducer: riskProfileReducer,
  clinicalExamReducer: clinicalExamReducer,
  getKeyFinding: getKeyFindingReducer,
  insightRecommendation: insightRecommendationReducer,
  UpdateInsightRecommendation: UpdateInsightRecommendationReducer,
  treatmentPlan: treatmentPlanReducer,
  readinessBar: readinessBarReducer,
  reviewTreatmentPlan: reviewTreatmentPlanReducer,
  insuranceApprovalReducer: insuranceApprovalReducer,
  downloadPdfReducer: downloadPdfReducer,
  occlusionDentistRecallFlowReducer: occlusionDentistRecallFlowReducer,
  periodontalDentistRecallFlowReducer: periodontalDentistRecallFlowReducer,
  intraOralDentistRecallExamReducer: intraOralDentistRecallExamReducer,
  occlusionDentistRecallExamReducer: occlusionDentistRecallExamReducer,
  periodontalDentistRecallExamReducer: periodontalDentistRecallExamReducer,
  appointmentTypeReducer: appointmentTypeReducer,
  clinicalExamNotes: clinicalExamNotesReducer
};
export type RootState = any;
export default v1rootReducer;
