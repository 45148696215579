import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    Box,
    Grid,
    Typography,
    Button,
    TextField,
    Link,
    InputLabel,
    useMediaQuery,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleArrowLeft } from "@fortawesome/free-solid-svg-icons";

import { forgotUsernameValidator } from "../../../../validation";
import { RootState } from "../../../../app/reducers/v1/rootReducer";
import FullScreenLoader from "../../../../components/v1/Common/FullScreenLoader";
import { forgotUserNameRequest } from "../../../../app/actions/v2/patient/authAction";
import HelmetComponent from "../../../../components/v1/Common/HelmetComponent";
import PatientLeftSideImage from "../../../../components/v2/patient/PatientLeftSideImage";
import theme from "../../../../theme";
import TextBox from "../../../../components/v2/common/TextBox";
import RequiredStar from "../../../../components/v2/common/RequiredStar";
import { dateFormat, usDateFormat } from "../../../../utils/helper";
import MultipleNumberPopUp from "../../../../components/v2/patient/Auth/multipleNumberPopUp";
import colorLogo from "../../../../assets/images/v2/logo.png";

const useStyles = makeStyles((theme: any) => ({
    rightSection: {
        paddingLeft: "10%",
        paddingRight: "10%",
        [theme.breakpoints.down("md")]: {
            backgroundColor: theme.palette.common.white,
            height: "100vh",
            width: "100vw",
            paddingLeft: "20%",
            paddingRight: "20%",
            borderLeft: "none",
            position: 'relative'
        },
        [theme.breakpoints.down("sm")]: {
            paddingLeft: "10%",
            paddingRight: "10%",
        },
    },
    cssOutlinedInput: {
        borderRadius: "10px !important",
        "&$cssFocused $notchedOutline": {
            borderColor: `${theme.palette.secondary.main} !important`,
            [theme.breakpoints.down("md")]: {
                borderColor: theme.palette.primary.main,
            },
        },
    },
    notchedOutline: {
        borderWidth: "1px",
        borderColor: `${theme.palette.secondary.main} !important`,
    },
    backButtonBox: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.common.white,
        padding: "8px",
        borderRadius: "8px",
        position: "absolute",
        top: "5%",
        left: "55%",
        cursor: "pointer",
        [theme.breakpoints.down("md")]: {
            left: "5%",
        },
    }
}));

const imageText = {
    text1: "Elevating your oral health and",
    text2: "wellness journey with access",
    text3: "in the palm of your hand.",
};

const PatientForgotUserName = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        email: "",
        dob: "",
    });
    const [error, setError]: any = useState({});

    const {
        patientAuthV2: {
            forgotUserNameLoader,
            multipleNumberPopUp,
        },
    } = useSelector((state: RootState) => state) as any;

    const isSmallScreen = useMediaQuery(theme.breakpoints.down(960));

    let newDate = new Date();
    let year = newDate.getFullYear();

    const submitHandler = (e: any) => {
        e.preventDefault();
        let { errors, isValid } = forgotUsernameValidator(formData);
        setError(errors);
        if (isValid) {
            dispatch(
                forgotUserNameRequest({
                    payload: {
                        account_type: "patient",
                        email: formData.email,
                        date_of_birth: formData?.dob,
                    },
                    callback: () => {
                        navigate("/patient/login");
                    },
                })
            );
        } else {
            setError(errors);
        }
    };

    useEffect(() => {
        if (!forgotUserNameLoader) {
            setFormData({
                email: "",
                dob: "",
            });
        }
    }, [forgotUserNameLoader]);

    const timeValid = formData?.dob?.length > 7 && formData?.dob?.toLocaleLowerCase()?.includes('invalid')

    const dateValidation = () => {
        let errorMsg = '' as string
        const newFormatted = moment(new Date(formData?.dob))?.format(usDateFormat)
        const todayNewFormatted = moment(new Date())?.format(usDateFormat)
        const pastYearLimit = moment().year(1900)
        const pastDate = dateFormat(formData?.dob)?.length > 9 && !moment(newFormatted).isSameOrBefore(todayNewFormatted)

        const momentDate = moment(formData?.dob)
        if (
            formData?.dob &&
            (moment(formData?.dob).isBefore(pastYearLimit) || !momentDate.isValid() || !momentDate.invalidAt())
        ) {
            errorMsg = 'Please enter valid date.'
        } else if (formData?.dob && pastDate) {
            errorMsg = "Please enter today's date or a past date."
        }
        return errorMsg
    }

    const todayDate = new Date().toISOString().split('T')[0]

    const handleChangeVal = (val?: any, key?: any) => {
        const newFormatted = moment(new Date(val))?.format(usDateFormat)
        const isEmpty = val === null || val?.toString() === 'Thu Jan 01 1970 00:00:00 GMT+0000 (Coordinated Universal Time)'

        let updatedFormData = formData as any;
        updatedFormData[key] = key === 'dob' ? isEmpty ? '' : newFormatted : val;

        setFormData((prevState: any) => {
            return { ...prevState, dob: isEmpty ? '' : newFormatted };
        });

        setError((prevState: any) => {
            return { ...prevState, dob: "" };
        });
    };

    return (
        <>
            {forgotUserNameLoader && <FullScreenLoader />}

            <HelmetComponent title={"Forgot User Name"} />
            <Grid
                container
                alignContent={"middle"}
                style={{
                    backgroundColor: "",
                    height: "100vh",
                }}
            >
                <PatientLeftSideImage imageText={imageText} />

                <Grid
                    item
                    alignItems={"center"}
                    alignSelf={"center"}
                    display={"flex"}
                    justifyContent={"center"}

                    flexDirection={"column"}
                    className={classes.rightSection}
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                >
                    <Box width={"100%"}>
                        <Box
                            className={classes.backButtonBox}
                            onClick={() => {
                                navigate(`/patient/login`)
                            }}
                        >
                            <FontAwesomeIcon icon={faCircleArrowLeft} fontSize={'25px'} />
                        </Box>
                        <Box display={isSmallScreen ? 'flex' : 'none'} justifyContent={'center'} mb={3}>
                            <img style={{ width: '140px', height: '50px' }}
                                src={colorLogo}
                                alt='logo' />
                        </Box>
                        <Typography
                            color={"secondary"}
                            fontWeight={600}
                            fontSize={"30px"}
                            mb={1.25}
                            className={'fw-regular'}
                        >
                            Forgot User Name
                        </Typography>
                        <Typography
                            color={"text.secondary"}
                            fontWeight={400}
                            fontSize={"14px"}
                            mb={1.25}
                            mt={2.5}
                            className={'fw-regular'}
                        >
                            Enter your registered email address and date of birth.
                            <br />
                            We will share your username.
                        </Typography>

                        <form onSubmit={submitHandler}>
                            <Box my={3}>
                                <TextBox
                                    required
                                    isPatient
                                    label={'Email Address'}
                                    labelProps={{
                                        htmlFor: "email",
                                    }}
                                    autocomplete='new-pass'
                                    inputProps={{
                                        id: "email",
                                        name: "email",
                                        onChange: (e: any) => {
                                            setFormData((prevState: any) => {
                                                return { ...prevState, email: e.target.value };
                                            });
                                            setError((prevState: any) => {
                                                return { ...prevState, email: "" };
                                            });
                                        },
                                        value: formData?.email,
                                        error: !!error?.email,
                                        helperText: error?.email,
                                        fullWidth: true,
                                    }}
                                />
                            </Box>

                            <Box my={3}>
                                <InputLabel
                                    htmlFor="dob"
                                    sx={{
                                        mb: "8px", whiteSpace: {
                                            xs: 'break-spaces',
                                            sm: 'nowrap'
                                        },
                                    }}
                                    className={`labelColor fw-regular f-14 lh-19 f-w-350`}
                                >
                                    Date of Birth <RequiredStar isPatient />
                                </InputLabel>
                                <LocalizationProvider dateAdapter={AdapterDayjs} sx={{ width: '100%', minWidth: '100% !important' }}>
                                    <DesktopDatePicker
                                        onChange={(newValue: any) => handleChangeVal(newValue, "dob")}
                                        maxDate={todayDate}
                                        value={formData?.dob ? dateFormat(formData?.dob) : null}
                                        disableFuture
                                        InputProps={{
                                            classes: {
                                                root: 'cssOutlinedInput bg-white fw-regular',
                                                input: 'textField',
                                            },
                                        }}
                                        renderInput={(params: any) => (
                                            <TextField
                                                {...params}
                                                fullWidth={true}
                                                sx={{
                                                    '& .MuiOutlinedInput-notchedOutline': {
                                                        border: `1px solid ${(error?.dob || dateValidation()) ? theme.palette.error.main : theme.palette.secondary.main} !important`,
                                                    },
                                                    "& .MuiOutlinedInput-root": {
                                                        color: `${theme.palette.common.black50} !important`,
                                                        "& fieldset": {
                                                            borderColor: `${(error?.dob || dateValidation()) ? theme.palette.error.main : theme.palette.secondary.main}`
                                                        },
                                                        "&:hover fieldset": {
                                                            borderColor: theme.palette.secondary.main,
                                                        },
                                                        "&.Mui-focused fieldset": {
                                                            border: `2px solid ${theme.palette.secondary.main} !important`,
                                                        },
                                                    },
                                                    ".Mui-error ": {
                                                        fontSize: "12px",
                                                        lineHeight: "14px",
                                                    },
                                                    ".MuiFormHelperText-root": {
                                                        marginLeft: "10px",
                                                        marginTop: "5px",
                                                    },
                                                    '& fieldset': {
                                                        borderRadius: '10px',
                                                        borderColor: `${theme.palette.secondary.main} !important`, //  TODO: base border color -- >> #666666
                                                    },
                                                }}
                                                value={formData?.dob ? dateFormat(formData?.dob) : null}
                                                placeholder={usDateFormat}
                                                className={timeValid ? 'datePickerCustom w-100' : 'w-100'}
                                                error={error?.dob || dateValidation()}
                                                helperText={error?.dob || dateValidation()}
                                            />
                                        )}
                                    />
                                </LocalizationProvider>
                            </Box>

                            <Box>
                                <Button
                                    type={"submit"}
                                    fullWidth
                                    variant={"contained"}
                                    color={"secondary"}
                                    sx={{ borderRadius: "10px" }}
                                    className={'fw-regular'}
                                >
                                    Send Mail
                                </Button>
                            </Box>
                        </form>
                        <Box right="2%" bottom="2%" position="absolute">
                            <Typography sx={{
                                fontSize: {
                                    sm: 15,
                                    xs: 13
                                }
                            }} className={'fw-regular'} color={"text.secondary"}>
                                @{year} ORAQ All Rights Reserved.
                            </Typography>
                        </Box>
                        {
                            <Typography
                                color={theme.palette.text.darkGrey}
                                fontWeight={400}
                                fontSize={"14px"}
                                mt={3}
                                className={'fw-regular'}
                                sx={{
                                    marginBottom: {
                                        lg: "0px !important",
                                        md: "20px !important",
                                        sm: "20px !important",
                                        sx: "20px !important",
                                    },
                                }}
                            >
                                Remember username?{" "}
                                <Link
                                    variant="body1"
                                    color={"secondary"}
                                    underline="none"
                                    href={"/patient/login"}
                                    className={'fw-regular'}
                                >
                                    Login now!
                                </Link>
                            </Typography>
                        }
                    </Box>
                </Grid>
            </Grid>
            {multipleNumberPopUp &&
                <MultipleNumberPopUp
                    open={multipleNumberPopUp}
                />
            }
        </>
    );
};

export default PatientForgotUserName;
