import React, { useRef, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, Divider, Modal, Slider, Typography } from '@mui/material';
import { Box } from '@mui/system';
import AvatarEditor from "react-avatar-editor";
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { uploadPhotosAction } from '../../../../app/actions/v2/dentist/clinicalExam/exam/uploadPhotosXrayAction';
import Heading from '../../../v1/Common/Heading';
import theme from '../../../../theme';
import { errorToaster } from '../../../../utils/helper';
import { photo } from '../../../../utils/v2/clinicalExamConstant';

const boxStyle = {
  width: "530px",
  height: "307px",
  display: "flex",
  flexFlow: "column",
  justifyContent: "center",
  alignItems: "center"
};

const useStyles = makeStyles((theme: any) => ({
  headingText: {
    color: theme.palette.common.black,
    fontSize: '35px',
    marginBottom: '15px'
  },
  DialogActionsClass: {
    padding: '16px 20px',
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between'
  },
  closeButton: {
    padding: '0px !important',
    display: 'flex',
    justifyContent: 'flex-end',
    backgroundColor: 'transparent',
    color: theme.palette.common.black,
    '&:hover': {
      backgroundColor: theme.palette.common.white,
      boxShadow: 'none'
    }
  },
  dividerClass: {
    width: '100%', borderColor: '#EBEBEB'
  },
  verifyButtonContainer: {
    padding: '12px 20px',
    justifyContent: 'space-between !important'
  },
  cancelBtn: {
    height: '40px',
    padding: '8.5px 20px',
    border: `1px solid ${theme.palette.v2.primary.main}`,
    color: theme.palette.v2.primary.main,
    '&:hover': {
      backgroundColor: 'transparent',
      borderColor: theme.palette.v2.primary.main
    }
  },
  verifyButton: {
    height: '40px',
    padding: '10px 20px !important',
    maxWidth: '170px',
    borderRadius: '4px',
    background: theme.palette.v2.primary.main,
    color: theme.palette.common.white,
    textDecoration: 'none',
    textAlign: 'center',
    marginLeft: '10px',
    '&:hover': {
      background: theme.palette.v2.primary.main,
      color: theme.palette.common.white
    }
  },
  MuiSliderMarkLabel: {
    padding: '14px 0px',
    color: theme.palette.v2.primary.main,
    '.MuiSlider-thumb': {
    },
    '& .MuiSlider-mark': {
      backgroundColor: theme.palette.v2.primary.main,
      width: '3px',
      height: '3px',
      borderRadius: '50%'
    },
    '.MuiSlider-markLabel': {
      marginTop: '2px',
      color: theme.palette.text.darkGrey,
      fontSize: '12px',
      fontFamily: 'FavoritPro-Regular !important',
    }
  }
}))

type Props = {
  imagePath: any
  modalOpen: boolean | any
  setModalOpen: boolean | any
  uploadImageAPICall: any
}

const ImageCropper = (props: Props) => {
  const { imagePath, modalOpen, setModalOpen, uploadImageAPICall } = props as Props
  const classes = useStyles()
  const dispatch = useDispatch()
  const {
    uploadPhotoXray: { selectPhotosData, addAdditionalImageXray },
    clinicalExamFilters: { findingType }
  } = useSelector((state: any) => state);
  const [slideValue, setSlideValue] = useState(10) as any;
  const cropRef = useRef(null) as any;
  const { palette } = theme
  const isPhoto = findingType === photo;

  //handle save
  const handleSave = async () => {
    if (cropRef) {
      if (imagePath && (imagePath?.type === 'image/jpeg' || imagePath?.type === 'image/jpg' || imagePath?.type === 'image/png')) {
        const dataUrl = cropRef.current.getImage().toDataURL();
        const result = await fetch(dataUrl);
        const blob = await result.blob();
        const imageUrl = URL.createObjectURL(blob);
        dispatch(uploadPhotosAction(imageUrl))
        if (!addAdditionalImageXray) {
          uploadImageAPICall(blob, selectPhotosData?.title)
        }
      } else {
        errorToaster("Image type should be JPEG or JPG or PNG")
      }
      setModalOpen(false);
    }
  };

  const handleClose = () => {
    setModalOpen(false);
  }

  return (
    <React.Fragment>
      <Dialog open={modalOpen} onClose={handleClose}>
        <DialogActions className={classes.DialogActionsClass}>
          <Heading className='f-20 f-w-500 fw-medium' sx={{ mb: '0' }} heading={`Adjust your ${isPhoto ? 'Photo' : 'X-Ray'}`} />
          <Button onClick={handleClose} className={classes.closeButton} disableRipple>
            <FontAwesomeIcon
              icon={faClose}
              color={palette.common.black50}
              className='f-20'
              width={'20px'}
              height={'20px'}
            />
          </Button>
        </DialogActions>
        <Divider className={classes.dividerClass} />
        <DialogContent sx={{ height: 'fit-content', overflowY: 'hidden' }}>
          <Box sx={boxStyle}>
            <AvatarEditor
              ref={cropRef}
              image={imagePath}
              crossOrigin="anonymous"
              width={440}
              height={220}
              border={50}
              color={[0, 0, 0, 0.72]}
              scale={slideValue / 10}
              rotate={0}
            />
            {/* MUI Slider */}
            <Slider
              className={classes.MuiSliderMarkLabel}
              min={10}
              max={50}
              sx={{
                margin: "0 auto",
                width: "80%",
                color: theme.palette.v2.primary.main
              }}
              size="medium"
              defaultValue={slideValue}
              value={slideValue}
              onChange={(e: any) => setSlideValue(e.target.value)}
            />
          </Box>
        </DialogContent>
        <Divider className={classes.dividerClass} />
        <DialogActions className={`d-flex justify-content-between ${classes.verifyButtonContainer} w-100`}>
          <Button variant='outlined' onClick={handleClose} className={classes.cancelBtn}>
            Cancel
          </Button>
          <Button variant='contained' className={classes.verifyButton} onClick={handleSave} >
            Crop
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment >
  );
};

export default ImageCropper;
