import { createStore, applyMiddleware, combineReducers } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { all } from 'redux-saga/effects';
import { composeWithDevTools } from 'redux-devtools-extension';
import v1rootReducer from './reducers/v1/rootReducer';
import v2rootReducer from './reducers/v2/rootReducer';
import { v1Sagas } from './sagas/v1/rootSaga';
import { v2Sagas } from './sagas/v2/rootSaga';

//Combine all versions of saga [v1, v2]
export function* rootSaga() {
  yield all([...v1Sagas, ...v2Sagas]);
}

//Combine all versions of reducers [v1, v2]
const rootReducer = combineReducers({ ...v1rootReducer, ...v2rootReducer });

const sagaMiddleware = createSagaMiddleware();
const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(sagaMiddleware)));
sagaMiddleware.run(rootSaga);
export default store;
