
import {
    FORGOT_USERNAME_V2_REQUEST,
    FORGOT_USERNAME_V2_REQUEST_FAILURE,
    FORGOT_USERNAME_V2_REQUEST_SUCCESS,
    MULTIPLE_NUMBER_POPUP_REQUEST
} from '../../../ActionTypes/v2/patient/auth';
import { patientAuthActions, patientAuthInterface } from '../../../types/v2/patient/auth/authActionType';

const initialState: patientAuthInterface = {
    forgotUserNameLoader: false,
    forgotUserNameData: null,
    forgotUserNameError: null,

    multipleNumberPopUp: false,
    multipleNumberData: null
};

const PatientAuthReducer = (state = initialState, action: patientAuthActions) => {
    switch (action.type) {
        case FORGOT_USERNAME_V2_REQUEST:
            return {
                ...state,
                forgotUserNameLoader: true,
                forgotUserNameData: null,
                forgotUserNameError: null
            };
        case FORGOT_USERNAME_V2_REQUEST_SUCCESS:
            return {
                ...state,
                forgotUserNameLoader: false,
                forgotUserNameData: action.payload,
                forgotUserNameError: null
            };
        case FORGOT_USERNAME_V2_REQUEST_FAILURE:
            return {
                ...state,
                forgotUserNameLoader: false,
                forgotUserNameData: null,
                forgotUserNameError: action.payload
            };

        case MULTIPLE_NUMBER_POPUP_REQUEST:
            return {
                ...state,
                multipleNumberPopUp: action.payload?.multipleNumberPopUp,
                multipleNumberData: action.payload?.multipleNumberData
            };

        default:
            return {
                ...state
            };
    }
};
export default PatientAuthReducer;
