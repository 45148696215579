export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_REQUEST_SUCCESS = "LOGIN_REQUEST_SUCCESS";
export const LOGIN_REQUEST_FAILURE = "LOGIN_REQUEST_FAILURE";

export const CHANGE_PASSWORD_REQUEST = "CHANGE_PASSWORD_REQUEST";
export const CHANGE_PASSWORD_REQUEST_SUCCESS = "CHANGE_PASSWORD_REQUEST_SUCCESS";
export const CHANGE_PASSWORD_REQUEST_FAILURE = "CHANGE_PASSWORD_REQUEST_FAILURE";

export const FORGOT_PASSWORD_REQUEST = "FORGOT_PASSWORD_REQUEST";
export const FORGOT_PASSWORD_REQUEST_SUCCESS = "FORGOT_PASSWORD_REQUEST_SUCCESS";
export const FORGOT_PASSWORD_REQUEST_FAILURE = "FORGOT_PASSWORD_REQUEST_FAILURE";

export const RECOVER_PASSWORD_REQUEST = "RECOVER_PASSWORD_REQUEST";
export const RECOVER_PASSWORD_REQUEST_SUCCESS = "RECOVER_PASSWORD_REQUEST_SUCCESS";
export const RECOVER_PASSWORD_REQUEST_FAILURE = "RECOVER_PASSWORD_REQUEST_FAILURE"

export const CREATE_PASSWORD_REQUEST = "CREATE_PASSWORD_REQUEST";
export const CREATE_PASSWORD_REQUEST_SUCCESS = "CREATE_PASSWORD_REQUEST_SUCCESS";
export const CREATE_PASSWORD_REQUEST_FAILURE = "FORGOT_PASSWORD_REQUEST_FAILURE";
