import {UPDATE_ASSISTANT_OCCLUSION, 
    ADD_ASSISTANT_OCCLUSION_FINDING_ROW,
    CHANGE_ASSISTANT_OCCLUSION_FINDING,
    DELETE_ASSISTANT_OCCLUSION_FINDING_ROW, UPDATE_ASSISTANT_OCCLUSION_FAILURE
} from "../../../../ActionTypes/v1/dentist/clinicalExamAssistant"
import {OcclusionAssistantInterface} from "../../../../types/dentistTypes";

export const updateOcclusionRequest = (
    payload: OcclusionAssistantInterface
): OcclusionAssistantInterface => ({
    type: UPDATE_ASSISTANT_OCCLUSION,
    payload
}) as any;

export const updateOcclusionRequestFailure = ():any => ({
    type: UPDATE_ASSISTANT_OCCLUSION_FAILURE
}) as any;

export const addOcclusionFindingRowAction = (payload: string): any => ({
    type: ADD_ASSISTANT_OCCLUSION_FINDING_ROW,
    payload
}) as any;

export const changeOcclusionFindingAction = (payload: any): any => ({
    type: CHANGE_ASSISTANT_OCCLUSION_FINDING,
    payload
}) as any;

export const deleteOcclusionFindingRowAction = (payload: any): any => ({
    type: DELETE_ASSISTANT_OCCLUSION_FINDING_ROW,
    payload
}) as any;