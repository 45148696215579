import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { RootState } from "../../../../../app/reducers/v2/rootReducer";
import ReferralsSection from "../../../common/ReferralsSection";

const PatientWellnessReferrals = () => {
    const location = useLocation();
    const {
        wellnessProfileFilters: { wellnessProfileData },
        patientWellnessProfileWithoutAccount: { withoutAccountGetWellnessProfileData },
    } = useSelector((state: RootState) => state);

    const withoutAccountWellnessProfile: boolean = useMemo(() => {
        return location?.pathname?.includes('/patient/without-account/wellness-profile/')
    }, [location.pathname]);

    const wellnessData = withoutAccountWellnessProfile ? withoutAccountGetWellnessProfileData : wellnessProfileData;

    return (
        <>
            <ReferralsSection referralsData={wellnessData?.referrals} disabledField={true} isPatient={true} />
        </>
    )
}

export default PatientWellnessReferrals;