import {
    UPDATE_MEDICAL_HISTORY_DATA,UPDATE_MEDICAL_HISTORY_DATA_FAILURE,
    CHANGE_MEDICAL_HISTORY_DATA
} from "../../../../ActionTypes/v1/dentist/clinicalExamDentist";
import { MedicalHistoryDentistInterface } from "../../../../types/dentistTypes";

export const updateMedicalHistoryRequest = (
    payload: MedicalHistoryDentistInterface
): MedicalHistoryDentistInterface => ({
    type: UPDATE_MEDICAL_HISTORY_DATA,
    payload,
}) as any;

export const updateMedicalHistoryRequestFailure = (): any => ({
    type: UPDATE_MEDICAL_HISTORY_DATA_FAILURE,
}) as any;

export const changeMedicalHistoryAction = (payload: any): any => ({
    type: CHANGE_MEDICAL_HISTORY_DATA,
    payload
}) as any;

