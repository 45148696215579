import {
  SELECT_ANNOTATION,
  GET_ANNOTATION_REQUEST,
  GET_ANNOTATION_REQUEST_FAILURE,
  GET_ANNOTATION_REQUEST_SUCCESS,
  PUT_ANNOTATION_REQUEST,
  PUT_ANNOTATION_REQUEST_FAILURE,
  PUT_ANNOTATION_REQUEST_SUCCESS, SELECT_MARKER
} from '../../../../../ActionTypes/v2/dentist/clinicalExam/exams/annotation';
import { annotationInterface, selectAnnotationAction } from '../../../../../types/v2/dentist/clinicalExam/exam/annotation';

const initialState: annotationInterface = {
  selectAnnotation: null,
  getAnnotationLoader: false,
  annotationDataFromAPI: null,
  getAnnotationError: null,
  putAnnotationLoader: false,
  putAnnotationData: null,
  putAnnotationError: null,
  selectedMarker: null
};

const annotationReducer = (state = initialState, action: selectAnnotationAction) => {
  switch (action.type) {
    case SELECT_ANNOTATION:
      return {
        ...state,
        selectAnnotation: action.payload
      };
    case SELECT_MARKER:
      return {
        ...state,
        selectedMarker: action.payload
      };
    case GET_ANNOTATION_REQUEST:
      return {
        ...state,
        getAnnotationLoader: true
      };
    case GET_ANNOTATION_REQUEST_SUCCESS:
      return {
        ...state,
        getAnnotationLoader: false,
        annotationDataFromAPI: action.payload,
        getAnnotationError: null
      };
    case GET_ANNOTATION_REQUEST_FAILURE:
      return {
        ...state,
        getAnnotationLoader: false,
        annotationDataFromAPI: null,
        getAnnotationError: action.payload
      };
    case PUT_ANNOTATION_REQUEST:
      return {
        ...state,
        putAnnotationLoader: true
      };
    case PUT_ANNOTATION_REQUEST_SUCCESS:
      return {
        ...state,
        putAnnotationLoader: false,
        putAnnotationData: action.payload,
        putAnnotationError: null
      };
    case PUT_ANNOTATION_REQUEST_FAILURE:
      return {
        ...state,
        putAnnotationLoader: false,
        putAnnotationData: null,
        putAnnotationError: action.payload
      };
    default:
      return {
        ...state
      };
  }
};

export default annotationReducer;
