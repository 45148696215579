import { call, put, takeEvery, all } from "redux-saga/effects";
import API from "../../../../utils/api";
import { errorToaster, successToaster } from '../../../../utils/helper';
import { createPasswordRequestFailure, createPasswordRequestSuccess } from '../../../actions/v2/authAction/createPasswordAction';
import { CREATE_PASSWORD_V2_REQUEST } from '../../../ActionTypes/v2/auth';

function* createPasswordRequestSaga(payload: any): any {
  try {
      const response = yield API.post(`/v2/auth/password`, payload.payload.payload)
      yield call(payload.payload.callback, response);
      yield put(
          createPasswordRequestSuccess({
            createPasswordData: response?.data?.detail,
          })
      );
      if (response) {
          successToaster(response?.data?.meta?.message)
      }
  } catch (e: any) {
      errorToaster(e?.message)
      yield put(
          createPasswordRequestFailure({
            createPasswordError: e?.message
          })
      );
  }
}

function* createPasswordSaga() {
  yield takeEvery(CREATE_PASSWORD_V2_REQUEST, createPasswordRequestSaga);
}

export default function* rootSaga() {
  yield all([
    createPasswordSaga()
  ])
}