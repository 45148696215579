import {styled} from '@mui/material/styles'
import Switch from '@mui/material/Switch'
import {appointmentStatus} from './clinicalExamConstant';
import theme from '../../theme';

const {Upcoming,Ongoing,Finished,Completed,Cancelled,NoShow} = appointmentStatus
export const dropDownName = [
    {
        id: "role",
        heading: "Role",
    },
    {
        id: "teamMember",
        heading: "Team Member",
    },
    {
        id: "status",
        heading: "Status",
    },
    {
        id: "exam",
        heading: "Exam",
    },
];
export const examTypes = [
    {
        label: 'ALL',
        value: ''
    },
    {
        label: 'NPE',
        value: 'NPE'
    },
    {
        label: 'RC',
        value: 'RE'
    },
    {
        label: 'LE',
        value: 'LE'
    }
]
export const examRoles = [
    {
        label: 'All',
        value: ''
    },
    {
        label: 'Assistant',
        value: 'Assistant'
    },
    {
        label: 'Dentist',
        value: 'Dentist'
    }, {
        label: 'Hygienist',
        value: 'Hygienist'
    }

]
export const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: "10rem",
            overflow: "auto",
            boxShadow: "10px 12px 25px 0px rgb(0 0 0 / 25%)",
        },
    },
};
export const AntSwitch = styled(Switch)(() => ({
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
    color: `${theme.palette.v2.primary.main}`,
    '&:active': {
        '& .MuiSwitch-thumb': {
            width: 15,
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(9px) ',
        },
    },
    '& .MuiSwitch-switchBase': {
        padding: 2,
        '&.Mui-checked': {
            transform: 'translateX(12px)',
            color: theme.palette.common.white,
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: `${theme.palette.v2.primary.main}`,
            },
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 12,
        height: 12,
        borderRadius: 6,
        transition: theme.transitions.create(['width'], {
            duration: 200,
        }),
    },
    '& .MuiSwitch-track': {
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor: 'rgba(0,0,0,.25)',
        boxSizing: 'border-box',
    },
}))
export interface columnInterface {
    label?: string;
    minWidth?: number;
    maxWidth?: number;
    textAlign?: any;
    topArrow?: any;
    downArrow?: any;
    info?: any;
    id?: any;
}

export const appointmentTypesOption = [
    {
        label: "New Patient Comprehensive Exam",
        value: "NPE",
    },
    {
        label: "Recall Exam",
        value: "RE",
    },
    {
        label: "Limited Exam",
        value: "LE",
    },
];

export const verbalConsentObtained = [
    {
        label: "No",
        value: "no",
    },
    {
        label: "Yes",
        value: "yes",
    },
];

export const recallCyclePeriod = [
    {
        value: "3 months",
        label: "3 months",
    },
    {
        value: "4 months",
        label: "4 months",
    },
    {
        value: "6 months",
        label: "6 months",
    },
    {
        value: "12 months",
        label: "12 months",
    },
    {
        value: "Overdue",
        label: "Overdue",
    },
];

export const statusOption = [
    {
        label: "All",
        value: "",
    },
    {
        label: "Upcoming",
        value: Upcoming,
    },
    {
        label: "Ongoing",
        value: Ongoing,
    },
    {
        label: "Completed",
        value: Completed,
    },
    {
        label: "Cancelled",
        value: Cancelled,
    }, {
        label: "Finished",
        value: Finished,
    },
    {
        label: "No Show",
        value: NoShow,
    },

]
export const examStatus = [
    {
        label: 'Upcoming',
        value: appointmentStatus?.Upcoming
    },
    {
        label: 'Ongoing',
        value: appointmentStatus?.Ongoing
    },
    {
        label: 'Finished',
        value: appointmentStatus?.Finished
    },
    {
        label:'Completed',
        value: appointmentStatus?.Completed
    },
    {
        label: 'Cancelled',
        value: appointmentStatus?.Cancelled
    },
    {
        label: 'No Show',
        value: appointmentStatus?.NoShow
    },
]