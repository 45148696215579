import {InputLabel} from '@mui/material';
import React from 'react';
import theme from '../../../../theme';

type Props = {
    title?: any;
    titleProps?: any;
    whiteSpace?: boolean;
    disabled?: boolean;
    sx?: any
};
const InputHeading = (props: Props) => {
    const {title, titleProps, whiteSpace, sx, disabled} = props as Props;
    return (
        <InputLabel
            htmlFor='firstName'
            sx={{
                mb: '8px',
                whiteSpace: {
                    xs: 'break-spaces',
                    sm: whiteSpace && 'normal'
                },
                textTransform: 'capitalize !important',
                color: theme.palette.common.black50,
                ...sx
            }}
            className={`labelColor fw-regular f-14 lh-19 f-w-350`}
            {...titleProps}
        >
            {title}
        </InputLabel>
    );
};

export default InputHeading;
