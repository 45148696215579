import {faArrowLeft} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Box, Button} from '@mui/material';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation, useParams} from 'react-router-dom';
import {
    updateAppointmentStepAction,
    updateExamFormSizeAction
} from '../../../../../app/actions/v2/dentist/clinicalExam/clinicalExamFilterAction';
import {getPhotosRequestAction} from '../../../../../app/actions/v2/dentist/clinicalExam/exam/uploadPhotosXrayAction';
import {
    handleImageXrayFromTreatmentPlanComingAction
} from '../../../../../app/actions/v2/dentist/proposedTreatments/oraqProposedAction';
import {RootState} from '../../../../../app/reducers/v2/rootReducer';
import theme from '../../../../../theme';
import {photo, xray} from '../../../../../utils/v2/clinicalExamConstant';
import FullScreenLoader from '../../../../v1/Common/FullScreenLoader';
import Annotation from '../Annotation';
import PhotosXrayList from '../PhotosXrayList';
import ReloadPage from '../ReloadPage';
import UploadPhotosXray from '../UploadPhotosXray';
import pusher from "../../../../../utils/pusher";
import {getPatientReviewRequestAction} from "../../../../../app/actions/v2/dentist/clinicalExam/patientReviewAction";


const PhotoXray = () => {
    const dispatch = useDispatch();
    const takeFromUrl = useParams();
    const location = useLocation()
    const isWellnessProfile = location?.pathname?.includes('/v2/dentist/wellness-profile/')
    const {
        uploadPhotoXray: {photosXrayData, photosXrayLoader},
        clinicalExamFilters: {selectedImage, findingType},
        oraqProposedReducer: {cameImageXrayFromTreatmentPlan},
        wellnessProfileFilters: {wellnessProfileStep},
    } = useSelector((state: RootState) => state);

    useEffect(() => {
        if ((findingType === photo || findingType === xray) || (wellnessProfileStep === 'photo' || wellnessProfileStep === 'xray')) {
            dispatch(
                getPhotosRequestAction({
                    appointment_id: takeFromUrl?.appointmentId,
                    image_type: (isWellnessProfile ? wellnessProfileStep === 'photo' : findingType === photo) ? 'image' : 'xray',
                    patient_id: takeFromUrl?.patientId,
                })
            );
            dispatch(updateExamFormSizeAction(false));
        }
    }, [findingType, wellnessProfileStep]);

    const backToTreatment = () => {
        const step = 3 as any;
        dispatch(handleImageXrayFromTreatmentPlanComingAction(false));
        dispatch(updateAppointmentStepAction(step));
    }

    return <React.Fragment>
        {photosXrayLoader && <FullScreenLoader/>}
        <React.Fragment>
            {(photosXrayData && photosXrayData?.length > 0) ?
                <React.Fragment>{selectedImage !== '' ? <Annotation/> : <PhotosXrayList/>}</React.Fragment>
                : <React.Fragment>
                    {cameImageXrayFromTreatmentPlan &&
                        <Box mt={'20px'} ml={'20px'}> <Button onClick={backToTreatment} variant='outlined'
                                                              startIcon={<FontAwesomeIcon icon={faArrowLeft}/>} sx={{
                            color: theme.palette.v2.primary.main,
                            borderColor: theme.palette.v2.primary.main
                        }}>
                            Back to Treatment Planning</Button></Box>}
                    <ReloadPage/>
                </React.Fragment>
            }
            <UploadPhotosXray/>
        </React.Fragment>

    </React.Fragment>;
};

export default PhotoXray;
