import {
    GET_RISK_CATEGORY_DATA_REQUEST,
    GET_RISK_CATEGORY_DATA_SUCCESS,
    GET_RISK_CATEGORY_DATA_FAIL,
} from '../../../ActionTypes/v2/master/riskCategories';

const initialState: any = {
    loader: false,
    riskCategoryData: null,
};

const riskCategoryReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case GET_RISK_CATEGORY_DATA_REQUEST:
            return {
                ...state,
                loader: true
            };
        case GET_RISK_CATEGORY_DATA_SUCCESS:
            return {
                ...state,
                riskCategoryData: action?.payload,
                loader: false
            };
        case GET_RISK_CATEGORY_DATA_FAIL:
            return {
                ...state,
                riskCategoryData: null,
                loader: false
            };
        default:
            return {
                ...state
            };
    }
};

export default riskCategoryReducer;
