import {
  PUT_INSIGHTS_RECOMMENDATIONS_REQUEST,
  PUT_INSIGHTS_RECOMMENDATIONS_SUCCESS,
  PUT_INSIGHTS_RECOMMENDATIONS_FAILURE,
  GET_INSIGHTS_RECOMMENDATIONS_REQUEST,
  GET_INSIGHTS_RECOMMENDATIONS_CACHE_DATA_CLEAR_REQUEST ,
  GET_INSIGHTS_RECOMMENDATIONS_REQUEST_SUCCESS,
  GET_INSIGHTS_RECOMMENDATIONS_REQUEST_FAILURE,
} from "../../../../../ActionTypes/v1/dentist/insightAndRecommendation";
import {
  PutInsightRecommendationRequest,
  PutInsightRecommendationRequestSuccess,
  PutInsightRecommendationRequestFailure,
  PutInsightRecommendationRequestPayload,
  PutInsightRecommendationSuccessPayload,
  PutInsightRecommendationFailurePayload,
  GetInsightRecommendationRequest,
  GetInsightRecommendationRequestSuccess,
  GetInsightRecommendationRequestFailure,
  getInsightRecommendationRequestPayload,
  getInsightRecommendationSuccessPayload,
  getInsightRecommendationFailurePayload,
} from "../../../../../types/dentistTypes";

export const getInsightRecommendationRequest = (
  payload: getInsightRecommendationRequestPayload
): GetInsightRecommendationRequest => ({
  type: GET_INSIGHTS_RECOMMENDATIONS_REQUEST,
  payload,
});

export const getInsightRecommendationCacheDataClearRequest = ():any => ({
  type: GET_INSIGHTS_RECOMMENDATIONS_CACHE_DATA_CLEAR_REQUEST
})

export const getInsightRecommendationRequestSuccess = (
  payload: getInsightRecommendationSuccessPayload
): GetInsightRecommendationRequestSuccess => ({
  type: GET_INSIGHTS_RECOMMENDATIONS_REQUEST_SUCCESS,
  payload,
});
export const getInsightRecommendationRequestFailure = (
  payload: getInsightRecommendationFailurePayload
): GetInsightRecommendationRequestFailure => ({
  type: GET_INSIGHTS_RECOMMENDATIONS_REQUEST_FAILURE,
  payload,
});

export const putInsightRecommendationRequest = (
  payload: PutInsightRecommendationRequestPayload
): PutInsightRecommendationRequest => ({
  type: PUT_INSIGHTS_RECOMMENDATIONS_REQUEST,
  payload,
});

export const putInsightRecommendationRequestSuccess = (
  payload: PutInsightRecommendationSuccessPayload
): PutInsightRecommendationRequestSuccess => ({
  type: PUT_INSIGHTS_RECOMMENDATIONS_SUCCESS,
  payload,
});
export const putInsightRecommendationRequestFailure = (
  payload: PutInsightRecommendationFailurePayload
): PutInsightRecommendationRequestFailure => ({
  type: PUT_INSIGHTS_RECOMMENDATIONS_FAILURE,
  payload,
});
