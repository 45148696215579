import { all, call, put, takeEvery } from 'redux-saga/effects';
import API from '../../../../utils/api';
import { STAFF_NEW_PROFILE_REQUEST, STAFF_NEW_PROFILE_EDIT_REQUEST } from '../../../ActionTypes/v2/dentist/profileDentist';
import {
    staffProfileNewRequestSuccess,
    staffProfileNewRequestFailure,
    staffProfileNewEditRequestFailure,
    staffProfileNewCacheDataClearRequest,
    staffProfileNewEditRequestSuccess
} from '../../../actions/v2/dentistProfileNewAction';
import { errorToaster, successToaster } from '../../../../utils/helper';

//Staff Profile Data
function* staffProfileNewRequestSaga(payload: any): any {
    yield put(staffProfileNewCacheDataClearRequest());
    try {
        const response = yield API.get(`/v2/staff/${payload?.payload?.staff_id}`);
        yield put(
            staffProfileNewRequestSuccess({
                data: response?.data?.detail
            })
        );
        if (payload.payload.callback) {
            yield call(payload.payload.callback, response?.data?.detail);
        }
    } catch (e: any) {
        //TODO: update toast according to status code
        //  errorToaster(e?.detail)
        yield put(staffProfileNewCacheDataClearRequest());
        yield put(
            staffProfileNewRequestFailure({
                error: e?.detail
            })
        );
    }
}
//Edit Staff Profile
function* staffProfileNewEditRequestSaga(payload: any): any {
    try {
        const response = yield API.put(`/v2/staff?staff_id=${payload?.payload?.staff_id}&clinic_id=${payload?.payload?.clinic_id}`, payload.payload.profile_picture);
        if (response) {
            successToaster('Profile update successfully ')
        }
        if (payload.payload.callback) {
            yield call(payload.payload.callback, response?.data?.detail);
        }
        yield put(
            staffProfileNewEditRequestSuccess({
                data: response.data
            })
        );
    } catch (e: any) {
        errorToaster(e?.message)
        yield put(
            staffProfileNewEditRequestFailure({
                error: e.detail
            })
        );
    }
}

function* getStaffProfileNewSaga() {
    yield takeEvery(STAFF_NEW_PROFILE_REQUEST, staffProfileNewRequestSaga);
}

function* staffProfileNewEditSaga() {
    yield takeEvery(STAFF_NEW_PROFILE_EDIT_REQUEST, staffProfileNewEditRequestSaga);
}
export default function* staffProfileNewSaga() {
    yield all([getStaffProfileNewSaga(), staffProfileNewEditSaga()]);
}
