import { Box } from '@mui/material';
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import { isSupported } from 'firebase/messaging';
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import Loader from './components/v1/Common/Loader';
import { onMessageListener, requestForToken } from './firebase';
import ErrorUI from "./pages/ErrorUi";
import { paths } from "./routing/AppRoutes";
import theme from './theme';



const version = process.env.REACT_APP_VERSION

const PrivateRoute = React.lazy(() => import("./routing/PrivateRoute"));

interface routeInterface {
  path: string
  name: string
  exact: boolean
  component?: any
  private?: boolean
}

const App = () => {
  const { hide } = useSelector((state: any) => state.clinicalExamReducer);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    isSupported()?.then((res: any) => {
      if (res) {
        requestForToken().then(() => {
          onMessageListener()
            .then((data: any) => {
            })
            .catch((err) => {
            });
        });
      }
    })
  }, [])


  useEffect(() => {
    const errorHandler = (error: any) => {
      console.error('sss', error);
      setHasError(true);
    };

    window.addEventListener('error', errorHandler);
    return () => window.removeEventListener('error', errorHandler);
  }, []);

  return (
    <>
      <React.Suspense fallback={<Loader />}>
        <Box sx={{
          backgroundColor: `${theme.palette.background.secondaryPaper} !important`,
          display: 'flex',
          flexDirection: 'column',
        }}
          className={version}
        >
          {/*Handle errors*/}
          <ErrorUI>
            <Routes>
              <Route path="/" element={<Navigate to="/home" replace />} />
              {paths?.map((res: routeInterface, i: number): any => (
                <Route
                  key={i}
                  path={res.path}
                  element={
                    res.private ? (
                      <PrivateRoute path={res}>
                        <span>{res.component}</span>
                      </PrivateRoute>
                    ) : (
                      res.component
                    )
                  }
                />
              ))}
            </Routes>
          </ErrorUI>
        </Box>
        {/*{!hide && <PatientFooter />}*/}
      </React.Suspense>
    </>
  );
};

export default App;
