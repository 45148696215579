import { LOGOUT_V2_REQUEST, LOGOUT_V2_REQUEST_FAILURE, LOGOUT_V2_REQUEST_SUCCESS } from '../../../ActionTypes/v2/auth';
import {
  LogoutRequest,
  LogoutRequestFailure,
  logoutRequestFailurePayload,
  LogoutRequestSuccess,
  logoutRequestSuccessPayload
} from '../../../types/v2/authActionTypes/logOutActionTypes';

export const logoutRequest = (): LogoutRequest => ({
  type: LOGOUT_V2_REQUEST
});

export const logoutRequestSuccess = (payload: logoutRequestSuccessPayload): LogoutRequestSuccess => ({
  type: LOGOUT_V2_REQUEST_SUCCESS,
  payload
});

export const logoutRequestFailure = (payload: logoutRequestFailurePayload): LogoutRequestFailure => ({
  type: LOGOUT_V2_REQUEST_FAILURE,
  payload
});
