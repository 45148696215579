import { toothNumberForFindings, toothSurface } from "./helper";

export const fillingSubTypes = [
  { value: "Composite", label: "Composite" },
  { value: "Amalgam", label: "Amalgam" },
  { value: "Temporary/Provisional", label: "Temporary/Provisional" },
];
export const decayGrade = [
  {
    value: "Enamel/Incipient",
    label: "Enamel/Incipient",
  },
  {
    value: "Dentin",
    label: "Dentin",
  },
  {
    value: "Pulp",
    label: "Pulp",
  },
];
export const dentureSubTypes = [
  {
    value: "Complete Upper",
    label: "Complete Upper",
  },
  {
    value: "Complete Lower",
    label: "Complete Lower",
  },
  {
    value: "Partial Upper",
    label: "Partial Upper",
  },
  {
    value: "Partial Lower",
    label: "Partial Lower",
  },
];
export const inlaySubTypes = [
  {
    value: "Metal",
    label: "Metal",
  },
  {
    value: "Ceramic",
    label: "Ceramic",
  },
  {
    value: "Provisional",
    label: "Provisional",
  },
];
export const subType = [
  {
    value: "Full Metal",
    label: "Full Metal",
  },
  {
    value: "All Ceramic",
    label: "All Ceramic",
  },
  {
    value: "Metal-Ceramic",
    label: "Metal-Ceramic",
  },
  {
    value: "Provisional",
    label: "Provisional",
  },
];
export const ponticSubTypes = [
  {
    value: "Full Metal",
    label: "Full Metal",
  },
  {
    value: "All Ceramic",
    label: "All Ceramic",
  },
  {
    value: "Metal-Ceramic",
    label: "Metal-Ceramic",
  },
  {
    value: "Provisional",
    label: "Provisional",
  },
];
export const contourSubTypes = [
  {
    value: "Overcontoured",
    label: "Overcontoured",
  },
  {
    value: "Undercontoured",
    label: "Undercontoured",
  },
];
export const implantSubTypes = [
  {
    value: "Unrestored",
    label: "Unrestored",
  },
  {
    value: "Restored",
    label: "Restored",
  },
];
export const defaultConditionDecay = [
  {
    name: "tooth_number",
    value: [],
    option: toothNumberForFindings,
    type: "multi-select",
    title: "Tooth Number",
  },
  {
    name: "tooth_surface",
    option: toothSurface,
    type: "multi-select",
    title: "Tooth Surface",
    value: [],
  },
  {
    name: "grade",
    value: [],
    option: decayGrade,
    type: "multi-select",
    title: "Grade",
    disabled: true,
  },
  {
    name: "note",
    value: "",
    type: "string",
    title: "Notes",
  },
];
export const defaultDenture = [
  {
    name: "tooth_number",
    value: [],
    option: toothNumberForFindings,
    type: "multi-select",
    title: "Tooth Number",
  },
  {
    name: "sub_type",
    value: [],
    option: dentureSubTypes,
    type: "multi-select",
    title: "Sub Type",
  },
];
export const defaultInlay = [
  {
    name: "tooth_number",
    value: [],
    option: toothNumberForFindings,
    type: "multi-select",
    title: "Tooth Number",
  },
  {
    name: "tooth_surface",
    option: toothSurface,
    type: "multi-select",
    title: "Tooth Surface",
    value: [],
  },
  {
    name: "sub_type",
    value: [],
    option: inlaySubTypes,
    type: "multi-select",
    title: "Sub Type",
  },
];
export const defaultVaneer = [
  {
    name: "tooth_number",
    value: [],
    option: toothNumberForFindings,
    type: "multi-select",
    title: "Tooth Number",
  },
  {
    name: "tooth_surface",
    option: toothSurface,
    type: "multi-select",
    title: "Tooth Surface",
    value: [],
  },
  {
    name: "sub_type",
    value: [],
    option: subType,
    type: "multi-select",
    title: "Sub Type",
  },
];
export const defaultBridgePontic = [
  {
    name: "tooth_number",
    value: [],
    option: toothNumberForFindings,
    type: "multi-select",
    title: "Tooth Number",
  },
  {
    name: "sub_type",
    value: [],
    option: ponticSubTypes,
    type: "multi-select",
    title: "Sub Type",
  },
];
export const defaultBridgeAbutment = [
  {
    name: "tooth_number",
    value: [],
    option: toothNumberForFindings,
    type: "multi-select",
    title: "Tooth Number",
  },
  {
    name: "tooth_surface",
    option: toothSurface,
    type: "multi-select",
    title: "Tooth Surface",
    value: [],
  },
  {
    name: "sub_type",
    value: [],
    option: subType,
    type: "multi-select",
    title: "Sub Type",
  },
];
export const defaultImplant = [
  {
    name: "tooth_number",
    value: [],
    option: toothNumberForFindings,
    type: "multi-select",
    title: "Tooth Number",
  },
  {
    name: "sub_type",
    value: [],
    option: implantSubTypes,
    type: "multi-select",
    title: "Sub Type",
  },
];
export const defaultContour = [
  {
    name: "tooth_number",
    value: [],
    option: toothNumberForFindings,
    type: "multi-select",
    title: "Tooth Number",
  },
  {
    name: "tooth_surface",
    option: toothSurface,
    type: "multi-select",
    title: "Tooth Surface",
    value: [],
  },
  {
    name: "sub_type",
    value: [],
    option: contourSubTypes,
    type: "multi-select",
    title: "Sub Type",
  },
];
export const defaultOpenMargin = [
  {
    name: "tooth_number",
    value: [],
    option: toothNumberForFindings,
    type: "multi-select",
    title: "Tooth Number",
  },
  {
    name: "tooth_surface",
    option: toothSurface,
    type: "multi-select",
    title: "Tooth Surface",
    value: [],
  },
];
export const defaultOverhang = [
  {
    name: "tooth_number",
    value: [],
    option: toothNumberForFindings,
    type: "multi-select",
    title: "Tooth Number",
  },
  {
    name: "tooth_surface",
    option: toothSurface,
    type: "multi-select",
    title: "Tooth Surface",
    value: [],
  },
];
export const defaultOpenContact = [
  {
    name: "tooth_number",
    value: [],
    option: toothNumberForFindings,
    type: "multi-select",
    title: "Tooth Number",
  },
  {
    name: "tooth_surface",
    option: toothSurface,
    type: "multi-select",
    title: "Tooth Surface",
    value: [],
  },
  {
    name: "dimension",
    value: "",
    type: "string",
    title: "Dimensions (mm)",
  },
];
export const defaultSCT = [
  {
    name: "tooth_number",
    value: [],
    option: toothNumberForFindings,
    type: "multi-select",
    title: "Tooth Number",
  },
];
export const defaultWatch = [
  {
    name: "tooth_number",
    value: [],
    option: toothNumberForFindings,
    type: "multi-select",
    title: "Tooth Number",
  },
  {
    name: "tooth_surface",
    option: toothSurface,
    type: "multi-select",
    title: "Tooth Surface",
    value: [],
  },
  {
    name: "note",
    value: "",
    type: "string",
    title: "Notes",
  },
];
export const defaultFilling = [
  {
    name: "tooth_number",
    value: [],
    option: toothNumberForFindings,
    type: "multi-select",
    title: "Tooth Number",
  },
  {
    name: "tooth_surface",
    option: toothSurface,
    type: "multi-select",
    title: "Tooth Surface",
    value: [],
  },
  {
    name: "sub_type",
    value: [],
    option: fillingSubTypes,
    type: "multi-select",
    title: "Sub type",
  },
];
export const crownSubType = [
  {
    value: "Full Metal",
    label: "Full Metal",
  },
  {
    value: "All Ceramic",
    label: "All Ceramic",
  },
  {
    value: "Metal-Ceramic",
    label: "Metal-Ceramic",
  },
  {
    value: "Provisional",
    label: "Provisional",
  },
  {
    value: "Implant",
    label: "Implant",
  },
];
export const defaultCrown = [
  {
    name: "tooth_number",
    value: [],
    option: toothNumberForFindings,
    type: "multi-select",
    title: "Tooth Number",
  },
  {
    name: "tooth_surface",
    option: toothSurface,
    type: "multi-select",
    title: "Tooth Surface",
    value: [],
  },
  {
    name: "sub_type",
    value: [],
    option: crownSubType,
    type: "multi-select",
    title: "Sub Type",
  },
];
export const defaultOnlay = [
  {
    name: "tooth_number",
    value: [],
    option: toothNumberForFindings,
    type: "multi-select",
    title: "Tooth Number",
  },

  {
    name: "tooth_surface",
    option: toothSurface,
    type: "multi-select",
    title: "Tooth Surface",
    value: [],
  },
  {
    name: "sub_type",
    value: [],
    option: subType,
    type: "multi-select",
    title: "Sub Type",
  },
  {
    name: "note",
    value: "",
    type: "string",
    title: "Notes",
  },
];
