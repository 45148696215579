import { OcclusionAssistantInterface } from "../../../../types/dentistTypes";
import {
  UPDATE_ASSISTANT_OCCLUSION, UPDATE_ASSISTANT_OCCLUSION_FAILURE,
  ADD_ASSISTANT_OCCLUSION_FINDING_ROW,
  CHANGE_ASSISTANT_OCCLUSION_FINDING,
  DELETE_ASSISTANT_OCCLUSION_FINDING_ROW,
} from "../../../../ActionTypes/v1/dentist/clinicalExamAssistant";
import {
  defaultAngleRightCuspid,
  defaultAngleRightMolar,
  defaultAngleLeftCuspid,
  defaultAngleLeftMolar,
  defaultLateralRight,
  defaultLateralLeft,
  defaultProtrusiveGuidance,
  defaultMaxMidlinePhiltrum,
  defaultMandMidline,
  defaultOverjet,
  defaultOverbiteAtPercAtmm,
  defaultOverjetAtPerc,
  defaultCrowding,
  defaultCrowdingMandibular,
  defaultSpacingMaxillary,
  defaultSpacingMandibular,
  defaultMissingTeeth,
  defaultImpactedTeeth
} from "../../../../../utils/occlusionConstant"
import {
  defaultCacheAngleRightCuspid,
  defaultCacheAngleRightMolar,
  defaultCacheAngleLeftCuspid,
  defaultCacheAngleLeftMolar,
  defaultCacheLateralRight,
  defaultCacheLateralLeft,
  defaultCacheProtrusiveGuidance,
  defaultCacheMaxMidlinePhiltrum,
  defaultCacheMandMidline,
  defaultCacheOverjet,
  defaultCacheOverjetAtmm,
  defaultCacheOverjetAtPerc,
  defaultCacheCrowding,
  defaultCacheCrowdingMandibular,
  defaultCacheSpacingMaxillary,
  defaultCacheSpacingMandibular,
  defaultCacheMissingTeeth,
  defaultCacheImpactedTeeth
} from "../../../../../utils/cacheDataConstant/occlusionConstant";
import { jsonParseAndStringfy } from "../../../../../utils/helper";

const initialState: OcclusionAssistantInterface = {
  angleRightCuspid: [defaultAngleRightCuspid],
  angleRightMolar: [defaultAngleRightMolar],
  angleLeftCuspid: [defaultAngleLeftCuspid],
  angleLeftMolar: [defaultAngleLeftMolar],
  lateralRight: [defaultLateralRight],
  lateralLeft: [defaultLateralLeft],
  protrusiveGuidance: [defaultProtrusiveGuidance],
  maxMidlinePhiltrum: [defaultMaxMidlinePhiltrum],
  mandMidlineToMaxMidline: [defaultMandMidline],
  overjet: [defaultOverjet],
  overbiteAt31mm: [defaultOverbiteAtPercAtmm],
  overbiteAt31Perc: [defaultOverjetAtPerc],
  crowding: [defaultCrowding],
  crowdingMandibular: [defaultCrowdingMandibular],
  spacingMaxillary: [defaultSpacingMaxillary],
  spacingMandibular: [defaultSpacingMandibular],
  missingTeeth: [defaultMissingTeeth],
  impactedTeeth: [defaultImpactedTeeth],
};

export const occlusionReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case UPDATE_ASSISTANT_OCCLUSION:
      return {
        ...state,
        ...action.payload
      };
    case UPDATE_ASSISTANT_OCCLUSION_FAILURE:
      const newAngleRightCuspid = jsonParseAndStringfy(defaultCacheAngleRightCuspid); 
      const newAngleRightMolar = jsonParseAndStringfy(defaultCacheAngleRightMolar);
      const newAngleLeftCuspid = jsonParseAndStringfy(defaultCacheAngleLeftCuspid);
      const newAngleLeftMolar = jsonParseAndStringfy(defaultCacheAngleLeftMolar); 
      const newLateralRight = jsonParseAndStringfy(defaultCacheLateralRight);
      const newLateralLeft = jsonParseAndStringfy(defaultCacheLateralLeft);
      const newProtrusiveGuidances = jsonParseAndStringfy(defaultCacheProtrusiveGuidance);
      const newMaxMidlinePhiltrum = jsonParseAndStringfy(defaultCacheMaxMidlinePhiltrum);
      const newMandMidline = jsonParseAndStringfy(defaultCacheMandMidline);
      const newOverjet = jsonParseAndStringfy(defaultCacheOverjet);
      const newOverjetAtmm = jsonParseAndStringfy(defaultCacheOverjetAtmm);
      const newOverjetAtPerc = jsonParseAndStringfy(defaultCacheOverjetAtPerc); // TODO: combined with mm
      const newCrowding = jsonParseAndStringfy(defaultCacheCrowding); 
      const newCrowdingMandibular = jsonParseAndStringfy(defaultCacheCrowdingMandibular) ;
      const newSpacingMaxillary = jsonParseAndStringfy(defaultCacheSpacingMaxillary);
      const newSpacingMandibular = jsonParseAndStringfy(defaultCacheSpacingMandibular);
      const newMissingTeeth = jsonParseAndStringfy(defaultCacheMissingTeeth);
      const newImpactedTeeth = jsonParseAndStringfy(defaultCacheImpactedTeeth);
      return {
        angleRightCuspid: [newAngleRightCuspid],
        angleRightMolar: [newAngleRightMolar],
        angleLeftCuspid: [newAngleLeftCuspid],
        angleLeftMolar: [newAngleLeftMolar],
        lateralRight: [newLateralRight],
        lateralLeft: [newLateralLeft],
        protrusiveGuidance: [newProtrusiveGuidances],
        maxMidlinePhiltrum: [newMaxMidlinePhiltrum],
        mandMidlineToMaxMidline: [newMandMidline],
        overjet: [newOverjet],
        overbiteAt31mm: [newOverjetAtmm],
        overbiteAt31Perc: [newOverjetAtPerc], // TODO: combined with mm
        crowding: [newCrowding],
        crowdingMandibular: [newCrowdingMandibular],
        spacingMaxillary: [newSpacingMaxillary],
        spacingMandibular: [newSpacingMandibular],
        missingTeeth: [newMissingTeeth],
        impactedTeeth: [newImpactedTeeth],
      }
    case ADD_ASSISTANT_OCCLUSION_FINDING_ROW:
      const finding = action.payload as string;
      const newStateAdd = state as any;
      const updatedFinding = newStateAdd[finding];
      if (finding === "angleRightCuspid") {
        updatedFinding.push(defaultAngleRightCuspid);
      } else if (finding === "angleRightMolar") {
        updatedFinding.push(defaultAngleRightMolar);
      } else if (finding === "angleLeftCuspid") {
        updatedFinding.push(defaultAngleLeftCuspid);
      } else if (finding === "angleLeftMolar") {
        updatedFinding.push(defaultAngleLeftMolar);
      } else if (finding === "lateralRight") {
        updatedFinding.push(defaultLateralRight);
      } else if (finding === "lateralLeft") {
        updatedFinding.push(defaultLateralLeft);
      } else if (finding === "protrusiveGuidance") {
        updatedFinding.push(defaultProtrusiveGuidance);
      } else if (finding === "maxMidlinePhiltrum") {
        updatedFinding.push(defaultMaxMidlinePhiltrum);
      } else if (finding === "mandMidlineToMaxMidline") {
        updatedFinding.push(defaultMandMidline);
      } else if (finding === "overjet") {
        updatedFinding.push(defaultOverjet);
      } else if (finding === "overbiteAt31mm") {
        updatedFinding.push(defaultOverbiteAtPercAtmm);
      } 
      else if (finding === "overbiteAt31Perc") {
        updatedFinding.push(defaultOverjetAtPerc);
      } 
      else if (finding === "crowding") {
        updatedFinding.push(defaultCrowding);
      } else if (finding === "crowdingMandibular") {
        updatedFinding.push(defaultCrowdingMandibular);
      } else if (finding === "spacingMaxillary") {
        updatedFinding.push(defaultSpacingMaxillary);
      } else if (finding === "spacingMandibular") {
        updatedFinding.push(defaultSpacingMandibular);
      } else if (finding === "missingTeeth") {
        updatedFinding.push(defaultMissingTeeth);
      } else if (finding === "impactedTeeth") {
        updatedFinding.push(defaultImpactedTeeth);
      }
      return {
        ...state,
        [finding]: updatedFinding,
      };

    case CHANGE_ASSISTANT_OCCLUSION_FINDING:
      const { toFinding, value, type, index, key } = action.payload as any;
      const newState = state as any;
      let newFinding = newState[toFinding] as any;
      if (typeof newFinding !== "string") {
        newFinding[index] = newFinding[index]?.map((res: any) => {
          if (res.name === key) {
            return { ...res, value: value };
          }
          return res;
        });
      } else {
        newFinding = value;
      }
      return {
        ...state,
        [toFinding]: newFinding,
      };
    case DELETE_ASSISTANT_OCCLUSION_FINDING_ROW:
      const { deletingFrom, deleteIndex } = action.payload as any;
      const newStateDelete = state as any;
      let findingDeletingFrom = newStateDelete[deletingFrom] as any;
      findingDeletingFrom = Object.values(
        Object.assign({}, findingDeletingFrom)
      ).filter((f: any, i: number) => i !== deleteIndex);
      return {
        ...state,
        [deletingFrom]: findingDeletingFrom,
      };
    default:
      return { ...state };
  }
};
