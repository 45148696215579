import {
    GET_DENTIST_PRE_INTAKE_REQUEST,
    GET_DENTIST_PRE_INTAKE_REQUEST_FAILURE,
    GET_DENTIST_PRE_INTAKE_REQUEST_SUCCESS,

    POST_DENTIST_PRE_INTAKE_DATA_REQUEST,
    POST_DENTIST_PRE_INTAKE_DATA_REQUEST_FAILURE,

    PUT_DENTIST_PRE_INTAKE_REQUEST,
    PUT_DENTIST_PRE_INTAKE_REQUEST_SUCCESS,
    PUT_DENTIST_PRE_INTAKE_REQUEST_FAILURE
} from "../../../ActionTypes/v1/dentist/dentistPreIntake";

import {
    DentistPreIntakeRequest, DentistPreIntakeRequestFailure, DentistPreIntakeRequestFailurePayload,
    DentistPreIntakeRequestPayload, DentistPreIntakeRequestSuccess, DentistPreIntakeRequestSuccessPayload,
    GetDentistPreIntakeRequest,
    GetDentistPreIntakeRequestFailure,
    GetDentistPreIntakeRequestFailurePayload,
    GetDentistPreIntakeRequestPayload,
    GetDentistPreIntakeRequestSuccess,
    GetDentistPreIntakeRequestSuccessPayload,
    PostDentistDataPreIntakeFailureRequest,
    PostDentistDataPreIntakeRequest
} from "../../../types/dentistTypes";
import {
    PreIntakeRequest, PreIntakeRequestFailure, PreIntakeRequestFailurePayload,
    PreIntakeRequestPayload,
    PreIntakeRequestSuccess,
    PreIntakeRequestSuccessPayload
} from "../../../types/patientTypes";
import {preIntakeTypes} from "../../../ActionTypes/v1/preIntake";

export const getDentistPreIntakeRequest = (payload: GetDentistPreIntakeRequestPayload): GetDentistPreIntakeRequest => ({
    type: GET_DENTIST_PRE_INTAKE_REQUEST,
    payload,
});

export const getDentistPreIntakeRequestSuccess = (payload: GetDentistPreIntakeRequestSuccessPayload): GetDentistPreIntakeRequestSuccess => ({
    type: GET_DENTIST_PRE_INTAKE_REQUEST_SUCCESS,
    payload
});

export const getDentistPreIntakeRequestFailure = (payload: GetDentistPreIntakeRequestFailurePayload): GetDentistPreIntakeRequestFailure => ({
    type: GET_DENTIST_PRE_INTAKE_REQUEST_FAILURE,
    payload
});

export const postDentistPreIntakeDataRequest = (payload: any): PostDentistDataPreIntakeRequest => ({
    type: POST_DENTIST_PRE_INTAKE_DATA_REQUEST,
    payload
})
export const postDentistPreIntakeDataFailureRequest = (): PostDentistDataPreIntakeFailureRequest => ({
    type: POST_DENTIST_PRE_INTAKE_DATA_REQUEST_FAILURE
})  

export const dentistPreIntakeRequest = (payload: DentistPreIntakeRequestPayload): DentistPreIntakeRequest => ({
    type: PUT_DENTIST_PRE_INTAKE_REQUEST,
    payload,
});

export const dentistPreIntakeRequestSuccess = (
    payload: DentistPreIntakeRequestSuccessPayload
): DentistPreIntakeRequestSuccess => ({
    type: PUT_DENTIST_PRE_INTAKE_REQUEST_SUCCESS,
    payload
});

export const dentistPreIntakeRequestFailure = (
    payload: DentistPreIntakeRequestFailurePayload
): DentistPreIntakeRequestFailure => ({
    type: PUT_DENTIST_PRE_INTAKE_REQUEST_FAILURE,
    payload
});
