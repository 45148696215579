import { makeStyles } from "@mui/styles";
import theme from "../../../../theme";
import { Button, Dialog, DialogContent, Divider, Typography } from "@mui/material";
import { DialogActions } from '@material-ui/core';
import Heading from '../../../v1/Common/Heading';

const useStyles = makeStyles((theme: any) => ({
    arrowBox: {
        position: 'relative',
        borderRadius: '50%',
        backgroundColor: theme?.palette?.common?.black,
        height: '40px',
        width: '40px',
        '&:hover': {
            backgroundColor: theme?.palette?.text?.darkGrey,
        },
    },
    heading: {
        fontSize: '14px',
        lineHeight: '19px',
        color: theme?.palette?.common?.black50,
    },
    arrowIcon: {
        color: theme?.palette?.common?.black50,
        fontWeight: '300'
    },
    bodyText: {
        padding: '20px',
        color: theme?.palette?.common?.black50,
        fontSize: '16px',
        lineHeight: '26px'
    },
    button: {
        width: '85px',
        height: '40px',
        color: theme?.palette?.common?.white,
        backgroundColor: theme?.palette?.v2?.primary?.main,
    }
}))

export default function DisclaimerPopUp(props: any) {
    const classes = useStyles()
    return (
        <Dialog
            sx={{
                '& .MuiDialogContent-root': {
                    width: '100% !important',
                },
                '& .MuiPaper-root ': {
                    width: '600px !important',
                    maxWidth: '600px !important',
                    background: theme.palette.common.white,
                    boxShadow: ' 0px 10px 20px rgba(0, 0, 0, 0.07)',
                    borderRadius: '10px !important',
                    display: 'flex !important',
                    alignItems: 'center !important',
                    justifyContent: 'center !important'
                }
            }}
            open={props?.open}
        >
            <DialogActions style={{
                display: 'flex',
                justifyContent: 'space-between',
                padding: '20px 15.5px',
                width: '100%'
            }} >
                <Heading className='f-14 f-w-500 fw-medium' sx={{ mb: "0" }} heading={'Disclaimer'} />
            </DialogActions>

            <Divider sx={{ width: '100%', borderColor: theme.palette.divider }} />

            <DialogContent style={{ padding: '0px' }}>
                <Typography className={`${classes.bodyText} fw-regular`}>
                    OraQ processes collected data and provides a customized OraQ Wellness risk and recommendation profile that is reviewed by the clinicians.
                    The dentist now has the autonomy to either accept or reject the insights and recommendations, generated by OraQ,
                    thus providing the clinician with direct control of the visit.
                    OraQ does not diagnosis treatment for the patient and the clinician.
                </Typography>
            </DialogContent>

            <Divider sx={{ width: '100%', borderColor: theme.palette.divider }} />

            <DialogActions style={{
                display: 'flex',
                justifyContent: 'flex-end',
                padding: '12px 20px 12px 0',
                width: '100%'
            }} >
                <Button
                    sx={{ padding: '6px 23.5px' }}
                    variant={'contained'}
                    className={`${classes.button} fw-regular`}
                    onClick={props?.handleClose}
                >
                    Got it
                </Button>
            </DialogActions>
        </Dialog>
    );
}