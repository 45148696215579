// @ts-ignore
import { call, put, takeEvery, all } from "redux-saga/effects";
import API from "../../../../utils/api";
import { GOOGLE_LOGIN_REQUEST, LOGIN_V2_REQUEST } from "../../../ActionTypes/v2/auth";
import { errorToaster, getLocalStorageItem } from "../../../../utils/helper";
import {
  googleLoginRequestFailureAction,
  googleLoginRequestSuccessAction,
  loginRequestFailure,
  loginRequestSuccess,
} from "../../../actions/v2/authAction/loginAction";

function* loginRequestSaga({ payload }: any): any {
  let newPayload = {
    email: payload?.payload?.email,
    password: payload?.payload?.password,
    scopes: payload?.payload?.scopes,
    isOwner: payload?.payload?.isOwner,
  } as any;
  if (payload?.payload?.device_token) {
    newPayload = {
      ...newPayload,
      device_token: payload?.payload?.device_token,
    };
  }
  try {
    const response = yield API.post(
      `/v2/auth/login?is_owner=${payload?.payload?.isOwner}`,
      newPayload,

    );
    yield call(payload?.callback, response?.data);
    yield put(
      loginRequestSuccess({
        loginData: response?.data,
      })
    );
  } catch (e: any) {
    payload.errorCallback();
    errorToaster(e?.message);
    yield put(
      loginRequestFailure({
        loginError: e?.message,
      })
    );
  }
}


function* googleLoginRequestSaga({ payload }: any): any {
  let newPayload = {
    code: payload?.code,
  } as any;
  if (payload?.device_token) {
    newPayload = {
      ...newPayload,
      device_token: payload?.device_token,
    };
  }
  
  try {
    const response = yield API.post(
      `/v2/auth/google/signin`,
      newPayload,

    );
    yield call(payload?.callback, response?.data);
    yield put(
      googleLoginRequestSuccessAction(response?.data,)
    );
  } catch (e: any) {
    errorToaster(e?.message);
    yield put(
      googleLoginRequestFailureAction(e?.message)
    );
  }
}
function* loginWatch() {
  yield takeEvery(LOGIN_V2_REQUEST, loginRequestSaga);
}
function* googleLoginWatch() {
  yield takeEvery(GOOGLE_LOGIN_REQUEST, googleLoginRequestSaga);
}
export default function* rootSaga() {
  yield all([loginWatch(), googleLoginWatch()]);
}
