import {Box, Grid, Typography} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {isArray} from "lodash";
import React, {useCallback, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';
import {updateProcedureFiltersAction} from "../../../../../app/actions/v2/dentist/clinicalExam/odontogramAction";
import theme from "../../../../../theme";
import {removeRefFromJson} from "../../../../../utils/helper";
import {aboveTeeth, examConstantInterface, findingData, findingTypes} from "../../../../../utils/v2/examConstant";
import {procedureColors} from "../../../../../utils/v2/procedureHelper";
import FlippedTeeth from "./FlippedTeeth";
import MeasureScaleBg from "./MeasureScaleBg";
import OdontogramFindingList from "./OdontogramFindingList";
import OdontogramSurface from "./OdontogramSurface";
import OdontogramSync from './OdontogramSyncPms';
import OdontogramTeeth from "./OdontogramTeeth";
import ToothNumberWrapper from "./ToothNumberWrapper";
import ShowRejectedTreatments from './ShowRejectedTreatments';
import OdontogramContourFinding from './OdontogramTeeth/OdontogramContourFinding';

const useStyles = makeStyles((theme: any) => ({
    odontogramWrapper: {
        width: '854px',
        margin: 'auto',
        [theme.breakpoints.down(1300)]: {
            margin: 'auto 20px'
        }
    },
    toothSectionWrapper: {
        position: 'relative',
        height: '204px',
        paddingTop: 5.5,
        marginTop: 1.75,
        width: '100%',
        justifyContent: 'start !important',
        flexDirection: 'column'
    },
    selectedTooth: {
        '&:before, &:after': {
            content: '""',
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            width: '100%',
            height: '100%',
            background: `rgba(51, 51, 51, 0.05)`,
            borderRadius: '30px',
        },
    },
    procedureFilterWrapper: {
        padding: '6px 12px',
        marginRight: '8px',
        borderRadius: '14px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    procedureCountWrapper: {
        display: 'flex',
        background: theme.palette.common.white,
        padding: '3px 4px',
        borderRadius: '50%',
        marginLeft: '10px'
    }
}));

const Odontogram = () => {
    const classes = useStyles();
    const {palette} = theme
    const location = useLocation()
    const dispatch = useDispatch()
    const isWellnessProfile = location?.pathname?.includes('/v2/dentist/wellness-profile/')
    const {
        odontogram: {teeth, procedureFilters, cameOdontogramFromTreatment, showRejectedTreatmentsOnly},
        examFindingReducer: {examFindingData},
        appointmentsReducer: {appointmentDetails},
        clinicalExamFilters: {riskFilters, findingType},
    } = useSelector((state: any) => state) as any;

    const isMissingTooth = (tooth: number) => {
        const missingTeeth = examFindingData?.occlusion_exam?.missing_tooth_tooth_number || [] as string[]
        return missingTeeth.includes(`${tooth}`) && (procedureFilters?.includes('Existing') || (procedureFilters?.length < 1 && !showRejectedTreatmentsOnly))
    }
    const filteredFinding = useMemo(() => {
        let filteredData = [] as any
        const findingsWithSurfaces = ["decay", "watch", "filling", "crown", 'non_carious_cervical_lesions', 'implant']
        const findingTypeWiseFilteredData = findingData?.filter((f: examConstantInterface) =>
            f?.findingTypes?.includes(findingTypes[findingType]) && findingsWithSurfaces.includes(f?.key || '')
        ) as any;
        findingTypeWiseFilteredData?.map((res: any) => {
            if (riskFilters?.length) {
                if (res?.risks?.filter((ff: string) => riskFilters.includes(ff))?.length) {
                    filteredData.push(res?.key)
                }
            } else {
                filteredData.push(res?.key)
            }
            return true
        })
        return filteredData
    }, [riskFilters, riskFilters?.length, findingType]);

    const handleProcedureFilters = (key: string) => {
        let newProcedureFilters = removeRefFromJson(procedureFilters)
        if (newProcedureFilters.includes(key)) {
            newProcedureFilters = newProcedureFilters?.filter((f: string) => f !== key)
        } else {
            newProcedureFilters.push(key)
        }
        dispatch(updateProcedureFiltersAction(newProcedureFilters))
    }
    const getProcedureCount = useMemo(() => {
        const count = {
            Completed: 0,
            Planned: 0,
            Existing: 0,
        }
        if (examFindingData) {
            if (examFindingData?.occlusion_exam && examFindingData?.occlusion_exam?.missing_tooth_tooth_number && examFindingData?.occlusion_exam?.missing_tooth_tooth_number) {
                if (isArray(examFindingData?.occlusion_exam?.missing_tooth_tooth_number)) {
                    count.Existing += examFindingData?.occlusion_exam?.missing_tooth_tooth_number?.length
                } else {
                    count.Existing += examFindingData?.occlusion_exam?.missing_tooth_tooth_number?.split(',')?.length
                }
            }
            Object.keys(examFindingData)?.map((eg: string) => {
                if (examFindingData[eg] && typeof examFindingData[eg] !== 'string') {
                    Object.keys(examFindingData[eg]) && Object.keys(examFindingData[eg])?.map((f: string) => {
                        if (examFindingData[eg][f]) {
                            if (isArray(examFindingData[eg][f])) {
                                examFindingData[eg][f]?.map((item: any) => {
                                    if (item?.procedure_status === 'Completed') {
                                        count.Completed++
                                    } else if (item?.procedure_status === 'Planned') {
                                        count.Planned++
                                    } else if (item?.procedure_status === 'Existing') {
                                        count.Existing++
                                    }
                                    return true
                                })
                            }
                        }
                    })
                }
            })
        }
        return count
    }, [examFindingData]) as any

    const riskWiseFilteredData = useMemo(() => {
        /*Filter the data only if there is at least one risk is selected else will show data without risk filters*/
        return riskFilters?.length
            ? (findingData?.filter((f: examConstantInterface) => f?.risks?.filter((ff: string) => riskFilters.includes(ff))?.length) as any)
            : findingData
    }, [riskFilters, riskFilters?.length, findingData]);

    const displayOnlyPlannedFindingForTreatment = (finding: any) => {
        return cameOdontogramFromTreatment ? finding['procedure_status'] === 'Planned' : true
    }

    const isContour = useCallback((tooth: any, isBottomTeeth: boolean) => {
        const toothNumber = isBottomTeeth ? tooth + 20 : tooth;
        let hasTooth = false;
        let contoursSubType = null;
        let color = null;
        let toothSite = null
        const currentFindingData = riskWiseFilteredData?.find((f: any) => f?.key === 'contour') || {} as any
        const updatedFindingData = currentFindingData && examFindingData?.[currentFindingData?.examGroup]?.['contour'];

        if (updatedFindingData?.length === 1) {
            const contourData = updatedFindingData[0];
            const isTooth = contourData['tooth_number'];
            const isSubType = contourData['subtype'];
            const procedure_status = contourData['procedure_status'];
            const isRejected = contourData['rejected'];
            const isToothSite = contourData['tooth_site'];
            if (displayOnlyPlannedFindingForTreatment(contourData) &&
                (procedureFilters?.includes(procedure_status) || procedureFilters?.length < 1) &&
                (!showRejectedTreatmentsOnly || (showRejectedTreatmentsOnly && isRejected)) &&
                isTooth?.includes(`${toothNumber}`) &&
                isSubType
            ) {
                hasTooth = true;
                contoursSubType = isSubType;
                color = procedureColors[procedure_status]?.color;
                toothSite = isToothSite
            }
        } else if (updatedFindingData) {
            updatedFindingData?.forEach((contourData: any) => {
                const isTooth = contourData['tooth_number'];
                const isSubType = contourData['subtype'];
                const procedure_status = contourData['procedure_status'];
                const isRejected = contourData['rejected'];
                const isToothSite = contourData['tooth_site'];
                if (displayOnlyPlannedFindingForTreatment(contourData) &&
                    (procedureFilters?.includes(procedure_status) || procedureFilters?.length < 1) &&
                    (!showRejectedTreatmentsOnly || isRejected) &&
                    isTooth?.includes(`${toothNumber}`) &&
                    isSubType
                ) {
                    hasTooth = true;
                    contoursSubType = isSubType;
                    color = procedureColors[procedure_status]?.color;
                    toothSite = isToothSite
                }
            });
        }

        return {
            hasTooth,
            contoursSubType,
            color,
            toothSite
        };
    }, [examFindingData, procedureFilters, showRejectedTreatmentsOnly, riskWiseFilteredData]);
    return (
        <Box className='d-flex pos-relative'>
            {!cameOdontogramFromTreatment &&
                <Box className='d-flex pos-absolute' sx={{top: '10px', left: '50px'}}>
                    {Object.keys(procedureColors).map((res: any, ind: number) => <Box
                        key={ind}
                        className={classes.procedureFilterWrapper + ' cursor-pointer fw-regular'}
                        sx={{
                            border: `2px solid ${procedureColors[res][procedureFilters.includes(res) ? 'color' : 'bgColor']}`,
                            backgroundColor: procedureColors[res].bgColor,
                            color: procedureColors[res].color,
                        }}
                        onClick={() => handleProcedureFilters(res)}
                    > {res}
                        <Typography
                            className={`fw-regular ${classes.procedureCountWrapper}`}>{getProcedureCount[res] < 10 ? '0' : ''}{getProcedureCount[res]}</Typography>
                    </Box>)}
                </Box>
            }
            <OdontogramFindingList/>

            <Box sx={{
                overflowY: 'scroll',
                width: 1085,
                margin: 'auto',
                paddingTop: '45px',
            }}>
                {(appointmentDetails?.pms_appointment_no !== null || appointmentDetails?.patient?.pms_id !== null) && !isWellnessProfile &&
                    <OdontogramSync/>
                }
                <Box className={classes.odontogramWrapper}>
                    {/*Up side*/}
                    <Grid container className='w-100'>
                        {
                            aboveTeeth?.map((tooth: number, index: number) => <Grid
                                item
                                xs={0.75} md={0.75} key={index}
                                className={`d-flex-all-center`}
                            >
                                <Box className='w-100'>
                                    <ToothNumberWrapper tooth={tooth}/>
                                    {/*Tooth section start*/}
                                    <Box
                                        className={classes.toothSectionWrapper + ' d-flex-all-center ' + `${teeth.includes(tooth) ? classes.selectedTooth : ''}`}
                                        sx={{
                                            borderTop: `0.122819px solid #8b8b8a66`,
                                            borderRight: index === 7 ? `1px solid ${palette.grey.lightBorderGray}` : `0.122819px solid #8b8b8a66`,
                                            borderBottom: `0.5px solid ${palette.grey.lightBorderGray}`,
                                            borderLeft: index === 0 ? `0.122819px solid #8b8b8a66` : '',
                                        }}
                                    >
                                        {isContour(tooth, false)?.hasTooth &&
                                            <OdontogramContourFinding
                                                isContour={isContour(tooth, false)}
                                            />
                                        }

                                        {/* measureScale bg */}
                                        <MeasureScaleBg/>
                                        {
                                            !isMissingTooth(tooth) && <React.Fragment>
                                                <OdontogramTeeth tooth={tooth}/>
                                                <OdontogramSurface tooth={tooth} filteredFinding={filteredFinding}/>
                                                <FlippedTeeth tooth={tooth}/>
                                            </React.Fragment>
                                        }
                                    </Box>
                                    {/*Tooth section end*/}
                                </Box>
                            </Grid>)
                        }
                    </Grid>

                    {/*Down side*/}
                    <Grid container className='w-100' sx={{
                        transform: 'rotate(180deg)'
                    }}>
                        {aboveTeeth?.map((tooth: number, index: any) =>
                            <Grid item
                                  xs={0.75} md={0.75} key={index}
                                  className={`d-flex-all-center`}
                            >
                                <Box className='w-100'>
                                    <Box sx={{transform: 'rotate(-180deg)'}}>
                                        <ToothNumberWrapper tooth={tooth + 20}/>
                                    </Box>
                                    {/*Tooth section start*/}
                                    <Box
                                        className={classes.toothSectionWrapper + ' d-flex-all-center ' + `${teeth.includes(tooth + 20) ? classes.selectedTooth : ''}`}
                                        sx={{
                                            borderTop: `0.122819px solid #8b8b8a66`,
                                            borderLeft: index === 8 ? `1px solid ${palette.grey.lightBorderGray}` : `0.122819px solid #8b8b8a66`,
                                            // borderBottom: `0.5px solid ${palette.grey.lightBorderGray}`,
                                            borderRight: index === 15 ? `0.122819px solid #8b8b8a66` : '',
                                        }}
                                    >
                                        {isContour(tooth, true)?.hasTooth &&
                                            <OdontogramContourFinding
                                                isContour={isContour(tooth, true)}
                                            />
                                        }
                                        {/* measureScale bg */}
                                        <MeasureScaleBg/>
                                        {
                                            !isMissingTooth(tooth + 20) && <React.Fragment>
                                                <OdontogramTeeth tooth={tooth} isBottomTeeth/>
                                                <OdontogramSurface tooth={tooth} isBottomTeeth
                                                                   filteredFinding={filteredFinding}/>
                                                <FlippedTeeth tooth={tooth} isBottomTeeth/>
                                            </React.Fragment>
                                        }
                                    </Box>
                                    {/*Tooth section end*/}
                                </Box>
                            </Grid>)
                        }
                    </Grid>
                </Box>
            </Box>

            {/*TODO: Show rejected treatments switch*/}
            <Box
                color={palette.text.secondary}
                sx={{position: 'absolute', left: '55px', bottom: 0, height: 0}}
            >
                <ShowRejectedTreatments/>
            </Box>
        </Box>
    );
};

export default Odontogram;
