export const VERIFY_PASSWORD_REQUEST = 'VERIFY_PASSWORD_REQUEST';
export const VERIFY_PASSWORD_REQUEST_SUCCESS = 'VERIFY_PASSWORD_REQUEST_SUCCESS';
export const VERIFY_PASSWORD_REQUEST_FAILURE = 'VERIFY_PASSWORD_REQUEST_FAILURE';

export const GET_CLINIC_REQUEST = "GET_CLINIC_REQUEST";
export const GET_CLINIC_CACHE_DATA_CLEAR_REQUEST = "GET_CLINIC_CACHE_DATA_CLEAR_REQUEST";
export const GET_CLINIC_REQUEST_SUCCESS = "GET_CLINIC_REQUEST_SUCCESS";
export const GET_CLINIC_REQUEST_FAILURE = "GET_CLINIC_REQUEST_FAILURE";

export const GET_LIST_OF_PATIENT_REQUEST = "GET_LIST_OF_PATIENT_REQUEST";
export const GET_LIST_OF_PATIENT_REQUEST_SUCCESS = "GET_LIST_OF_PATIENT_REQUEST_SUCCESS";
export const GET_LIST_OF_PATIENT_REQUEST_FAILURE = "GET_LIST_OF_PATIENT_REQUEST_FAILURE";