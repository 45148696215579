import {
  SEND_OTP_V2_REQUEST,
  SEND_OTP_V2_REQUEST_FAILURE,
  SEND_OTP_V2_REQUEST_SUCCESS,
  VERIFY_OTP_V2_REQUEST,
  VERIFY_OTP_V2_REQUEST_FAILURE,
  VERIFY_OTP_V2_REQUEST_SUCCESS
} from '../../../ActionTypes/v2/auth';
import { otpActions, otpInterface } from '../../../types/v2/authActionTypes/verifyMobileActionTypes';


const initialState: otpInterface = {
  otpLoader: false,
  otpData: null,
  otpError: null,
  mobile: null
};

const otpReducer = (state = initialState, action: otpActions) => {
  switch (action.type) {

    case VERIFY_OTP_V2_REQUEST:
      return {
        ...state,
        otpLoader: true
      };

    case VERIFY_OTP_V2_REQUEST_SUCCESS:
      return {
        ...state,
        otpLoader: false,
        otpData: action?.payload,
        otpError: null
      };
    case VERIFY_OTP_V2_REQUEST_FAILURE:
      return {
        ...state,
        otpLoader: false,
        // otpData: null,
        otpError: action?.payload?.otpError
      };
    case SEND_OTP_V2_REQUEST:
      return {
        ...state,
        otpLoader: true
      };

    case SEND_OTP_V2_REQUEST_SUCCESS:
      return {
        ...state,
        otpLoader: false,
        mobile: action?.payload,
        otpError: null
      };
    case SEND_OTP_V2_REQUEST_FAILURE:
      return {
        ...state,
        otpLoader: false,
        mobile: null,
        // otpData: null,
        otpError: action?.payload?.otpError
      };
    default:
      return {
        ...state
      };
  }

}

export default otpReducer