import {
  STAFF_NEW_PROFILE_REQUEST,
  STAFF_NEW_PROFILE_REQUEST_SUCCESS,
  STAFF_NEW_PROFILE_REQUEST_FAILURE,
  STAFF_NEW_PROFILE_CACHE_DATA_CLEAR_REQUEST,
  STAFF_NEW_PROFILE_EDIT_REQUEST,
  STAFF_NEW_PROFILE_EDIT_REQUEST_FAILURE,
  STAFF_NEW_PROFILE_EDIT_REQUEST_SUCCESS
} from '../../ActionTypes/v2/dentist/profileDentist';
import {
  StaffProfileNewRequest,
  StaffProfileNewRequestSuccess,
  StaffProfileNewRequestFailure,
  StaffProfileNewEditRequest,
  StaffProfileNewEditRequestFailure,
  StaffProfileNewEditRequestSuccess,
  StaffProfileNewRequestPayload,
  StaffProfileNewRequestSuccessPayload,
  StaffProfileNewRequestFailurePayload,
  staffProfileNewEditRequestPayload,
  StaffProfileNewEditRequestFailurePayload,
  StaffProfileNewEditRequestSuccessPayload
} from '../../types/v2/dentist/dentistProfile';

//dentist Profile Data
export const staffProfileNewRequest = (payload: StaffProfileNewRequestPayload): StaffProfileNewRequest => ({
  type: STAFF_NEW_PROFILE_REQUEST,
  payload
});

export const staffProfileNewCacheDataClearRequest = (): any => ({
  type: STAFF_NEW_PROFILE_CACHE_DATA_CLEAR_REQUEST
});

export const staffProfileNewRequestSuccess = (
  payload: StaffProfileNewRequestSuccessPayload
): StaffProfileNewRequestSuccess => ({
  type: STAFF_NEW_PROFILE_REQUEST_SUCCESS,
  payload
});

export const staffProfileNewRequestFailure = (
  payload: StaffProfileNewRequestFailurePayload
): StaffProfileNewRequestFailure => ({
  type: STAFF_NEW_PROFILE_REQUEST_FAILURE,
  payload
});

//Edit Staff Profile

export const staffProfileNewEditRequest = (payload: staffProfileNewEditRequestPayload): StaffProfileNewEditRequest => ({
  type: STAFF_NEW_PROFILE_EDIT_REQUEST,
  payload
});
export const staffProfileNewEditRequestFailure = (
  payload: StaffProfileNewEditRequestFailurePayload
): StaffProfileNewEditRequestFailure => ({
  type: STAFF_NEW_PROFILE_EDIT_REQUEST_FAILURE,
  payload
});

export const staffProfileNewEditRequestSuccess = (
  payload: StaffProfileNewEditRequestSuccessPayload
): StaffProfileNewEditRequestSuccess => ({
  type: STAFF_NEW_PROFILE_EDIT_REQUEST_SUCCESS,
  payload
});
