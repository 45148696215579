import React from 'react'
import {useSelector} from "react-redux";
import {Typography} from "@mui/material";
import moment from "moment";
import {RootState} from "../../../../../../app/reducers/v2/rootReducer";
import theme from "../../../../../../theme";
import NoDataFound from "../../../../../v1/Common/NoDataFound";

const PatientPreferences = () => {
    const {wellnessProfileFilters: {wellnessProfileData},} = useSelector((state: RootState) => state);
    const formattedDate: string | null = wellnessProfileData?.updated_at ? moment(wellnessProfileData?.updated_at).format("MMMM DD, YYYY") : null
    return (
        <React.Fragment>
            {wellnessProfileData?.patient_preferences ?
                <Typography className={'f-16 f-w-400 fw-regular'} color={theme.palette.common.black50}
                            ml={1}>
                    {wellnessProfileData?.patient_preferences} {`(${formattedDate ? formattedDate : ''})`}
                </Typography> :
                <NoDataFound errorMessage={'data'}/>}
        </React.Fragment>
    )
}

export default PatientPreferences 