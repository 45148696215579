import { toothNumberForFindings, toothSurface } from "../helper";

//mobility stuff
export const mobilityGrade = [
  { label: "0", value: "0" },
  { label: "1", value: "1" },
  { label: "2", value: "2" },
  { label: "3", value: "3" },
];
//furcation stuff
export const furcationGrade = [
  { label: "1", value: "1" },
  { label: "2", value: "2" },
  { label: "3", value: "3" },
];
export const furcationToothSite = [
  { label: "B", value: "B" },
  { label: "L", value: "L" },
  { label: "M", value: "M" },
  { label: "D", value: "D" },
];

//localize stuff
export const localizeToothSite = [
  { label: "M", value: "M" },
  { label: "D", value: "D" },
];
//generalized_bone_lossFields stuff
export const grade = [
  { label: "None", value: "None" },
  { label: "Mild", value: "Mild" },
  { label: "Moderate", value: "Moderate" },
  { label: "Severe", value: "Severe" },
];
export const boneLoss = [
  { label: "Horizontal", value: "Horizontal" },
  { label: "Vertical", value: "Vertical" },
];

// nccl stuff

export const non_carious_subType = [
  { label: "Erosion", value: "Erosion" },
  { label: "Abrasion", value: "Abrasion" },
  { label: "Abfraction", value: "Abfraction" },
];
// recession stuff
export const recessionToothSite = [
  { label: "MB", value: "MB" },
  { label: "B", value: "B" },
  { label: "DB", value: "DB" },
  { label: "ML", value: "ML" },
  { label: "L", value: "L" },
  { label: "DL", value: "DL" },
];

export const defaultCacheMobility = [
  {
    value: [],
    option: toothNumberForFindings,
    type: "multi-select",
    title: "Tooth Number",
    name: "tooth_number",
  },
  {
    value: "",
    option: mobilityGrade,
    type: "select",
    title: "Grade",
    name: "grade",
  },
];
export const defaultCacheGeneralizedBoneLossFields = [
  {
    value: "",
    option: grade,
    type: "select",
    title: "Grade",
    name: "grade",
  },
  {
    value: "",
    option: boneLoss,
    type: "select",
    title: "Sub Type",
    name: "sub_type",
  },

  {
    value: "",
    name: "note",
    type: "string",
    title: "Notes",
  },
];
export const defaultCacheOcclusalTraumaPresent = [
  {
    value: false,
    type: "radio",
    title: "Present",
    name: "radio",
  },
  {
    value: "",
    name: "note",
    type: "string",
    title: "Notes",
  },
];

export const defaultCacheFurcation = [
  {
    value: false,
    name: "radio",
    type: "radio",
    title: "Present",
  },

  {
    value: [],
    option: toothNumberForFindings,
    type: "multi-select",
    title: "Tooth Number",
    name: "tooth_number",
  },
  {
    value: "",
    option: furcationToothSite,
    type: "select",
    title: "Tooth site",
    name: "tooth_site",
  },

  {
    value: "",
    option: furcationGrade,
    type: "select",
    title: "Grade",
    name: "grade",
  },

  {
    value: "",
    name: "note",
    type: "string",
    title: "Notes",
  },
];
export const defaultCacheLocalizedBoneLossPresent = [
  {
    value: [],
    option: toothNumberForFindings,
    type: "multi-select",
    title: "Tooth Number",
    name: "tooth_number",
  },
  {
    value: "",
    option: localizeToothSite,
    type: "select",
    title: "Tooth site",
    name: "tooth_site",
    disabled: true
  },
  {
    value: "",
    option: grade,
    type: "select",
    title: "Grade",
    name: "grade",
  },
  {
    value: "",
    option: boneLoss,
    type: "select",
    title: "Sub Type",
    name: "sub_type",
    disabled: true
  },
  {
    value: "",
    name: "note",
    type: "string",
    title: "Notes",
  },
];
export const defaultCacheNonCariousCervicalLesions = [
  {
    value: [],
    option: toothNumberForFindings,
    type: "multi-select",
    title: "Tooth Number",
    name: "tooth_number",
  },
  {
    option: toothSurface,
    type: "multi-select",
    title: "Tooth Surface",
    name: "tooth_surface",
    value: [],
  },
  {
    value: "",
    option: non_carious_subType,
    type: "select",
    title: "Sub Type",
    name: "sub_type",
    disabled: true
  },

  {
    value: "",
    name: "note",
    type: "string",
    title: "Notes",
  },
  {
    value: false,
    name: "radio",
    type: "radio",
    title: "Erosion",
  },
  {
    value: false,
    name: "radio1",
    type: "radio",
    title: "Attrition",
  },
  {
    value: false,
    name: "radio2",
    type: "radio",
    title: "Is dentin exposed",
  },
];
export const defaultCacheRecession = [
  {
    value: [],
    option: toothNumberForFindings,
    type: "multi-select",
    title: "Tooth Number",
    name: "tooth_number",
  },

  {
    value: "",
    option: recessionToothSite,
    type: "select",
    title: "Tooth site",
    name: "tooth_site",
  },
  {
    value: "",
    option: grade,
    type: "select",
    title: "Grade",
    name: "grade",
  },

  {
    value: "",
    name: "dimension",
    type: "string",
    title: "Dimensions (mm)",
    dataType: "number",
  },
  {
    value: "",
    name: "note",
    type: "string",
    title: "Notes",
  },
];