export const UPDATE_APPOINTMENT_INFORMATION = 'UPDATE_APPOINTMENT_INFORMATION';

/*Get pre-clinical api*/
export const GET_PRE_CLINICAL_DATA_REQUEST = 'GET_PRE_CLINICAL_DATA_REQUEST';
export const GET_PRE_CLINICAL_DATA_SUCCESS = 'GET_PRE_CLINICAL_DATA_SUCCESS';
export const GET_PRE_CLINICAL_DATA_FAIL = 'GET_PRE_CLINICAL_DATA_FAIL';

/*Put pre-clinical api*/
export const PUT_PRE_CLINICAL_DATA_REQUEST = 'PUT_PRE_CLINICAL_DATA_REQUEST';
export const PUT_PRE_CLINICAL_DATA_SUCCESS = 'PUT_PRE_CLINICAL_DATA_SUCCESS';
export const PUT_PRE_CLINICAL_DATA_FAIL = 'PUT_PRE_CLINICAL_DATA_FAIL';

/*pre-clinical validations*/
export const UPDATE_PRE_CLINICAL_VALIDATIONS = 'UPDATE_PRE_CLINICAL_VALIDATIONS';
export const UPDATE_PRE_CLINICAL_PRE_INTAKE_VALIDATIONS = 'UPDATE_PRE_CLINICAL_PRE_INTAKE_VALIDATIONS';

export const OPEN_POPUP_APPOINTMENT_TYPE_CHANGE = 'OPEN_POPUP_APPOINTMENT_TYPE_CHANGE';

export const IS_PRE_CLINICAL_SUBMIT = 'IS_PRE_CLINICAL_SUBMIT';
