import React, { useMemo, useState } from 'react'
import { useSelector } from "react-redux";
import { Box, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useLocation } from 'react-router-dom';
import { RootState } from "../../../../../app/reducers/v2/rootReducer";
import SpeedoMeter from "../../../dentist/speedometer";
import CollapsibleBox from "../../../common/CollapsibleBox";
import NoDataFound from "../../../../v1/Common/NoDataFound";

const useStyles = makeStyles((theme: any) => ({
    parentBox: {
        justifyContent: 'space-between',
        [theme.breakpoints.down(1600)]: {
            justifyContent: 'start',
        },
    },
    mainBox: {
        width: "14%",
        maxWidth: "14% ",
        [theme.breakpoints.down(1600)]: {
            width: "16% !important",
            maxWidth: "16% !important",
        },
        [theme.breakpoints.down(1510)]: {
            width: "18% !important",
            maxWidth: "18% !important",
        },
        [theme.breakpoints.down(1450)]: {
            width: "20% !important",
            maxWidth: "20% !important",
        },
        [theme.breakpoints.down(1320)]: {
            width: "22% !important",
            maxWidth: "22% !important",
        },
        [theme.breakpoints.down(1186)]: {
            width: "24% !important",
            maxWidth: "24% !important",
        },
        [theme.breakpoints.down(1040)]: {
            width: "26% !important",
            maxWidth: "26% !important",
        },
        [theme.breakpoints.down(937)]: {
            width: "30% !important",
            maxWidth: "30% !important",
        },
        [theme.breakpoints.down(833)]: {
            width: "35% !important",
            maxWidth: "35% !important",
        },
        [theme.breakpoints.down(744)]: {
            width: "50% !important",
            maxWidth: "50% !important",
        },
        [theme.breakpoints.down(560)]: {
            width: "100% !important",
            maxWidth: "100% !important",
        },
    },
    box: {
        padding: '12px',
        borderRadius: '8px',
        width: '269px',//184
        height: '54px',
        '&:nth-child(7)': {
            marginRight: '0px',
        }
    },
    boxGreen: {
        border: `1px solid ${theme.palette.v2.riskColor.green}`,
        color: theme.palette.v2.riskColor.green,
        backgroundColor: theme.palette.success.lightColor
    },
    boxYellow: {
        border: `1px solid ${theme.palette.warning.main}`,
        color: theme.palette.warning.main,
        backgroundColor: theme.palette.warning.lightColor
    },
    boxGreenYellow: {
        border: `1px solid ${theme.palette.v2.riskColor.greenYellow}`,
        color: theme.palette.v2.riskColor.greenYellow,
        backgroundColor: theme.palette.v2.riskColor.lightGreenYellow
    },
    boxYellowRed: {
        border: `1px solid ${theme.palette.v2.riskColor.yellowRed}`,
        color: theme.palette.v2.riskColor.yellowRed,
        backgroundColor: theme.palette.v2.riskColor.lightYellowRed
    },
    boxRed: {
        border: `1px solid ${theme.palette.v2.riskColor.red}`,
        color: theme.palette.v2.riskColor.red,
        backgroundColor: theme.palette.error.lightColor
    },
    label: {
        lineHeight: '17px',
        fontSize: '14px',
        color: theme.palette.common.black50,
        wordBreak: 'break-word',
    },
    value: {
        lineHeight: '34px',
        width: '20%',
        fontSize: '26px',
        display: 'flex',
        justifyContent: 'flex-end'
    },
}));

const PatientWellnessRiskScore = () => {
    const classes = useStyles();
    const location = useLocation();
    const {
        wellnessProfileFilters: { wellnessProfileData },
        patientWellnessProfileWithoutAccount: { withoutAccountGetWellnessProfileData },
    } = useSelector((state: RootState) => state);

    const withoutAccountWellnessProfile: boolean = useMemo(() => {
        return location?.pathname?.includes('/patient/without-account/wellness-profile/')
    }, [location.pathname]);

    const wellnessData = withoutAccountWellnessProfile ? withoutAccountGetWellnessProfileData : wellnessProfileData;

    const riskDataConstance = [
        {
            name: 'Overall Medical Risk',
            key: 'medical_risk'
        },
        {
            name: 'Overall Sleep Disorder Risk',
            key: 'sleep_disorder_risk'
        },
        {
            name: 'Overall Occlusion/TMJ/Orthodontic Risk',
            key: 'occlusion_tmj_orthodontic_risk'
        },
        {
            name: 'Overall Biomechanical Risk',
            key: 'biomechanical_risk'
        },
        {
            name: 'Overall Caries Risk',
            key: 'caries_risk'
        },
        {
            name: 'Overall Periodontal Risk',
            key: 'periodontal_risk'
        },
        {
            name: 'Overall Esthetics Index',
            key: 'esthetics_risk'
        }
    ];
    const [openSpeedometer, setOpenSpeedometer] = useState<boolean>(true)
    const riskData = useMemo(() => {
        const formattedData = {} as any
        riskDataConstance.forEach((item: any) => {
            const score = wellnessData?.risk_profile?.[item?.key];
            formattedData[item?.key] = {
                name: `${item?.key}`,
                score: score
            }
        });
        return wellnessData?.risk_profile ? formattedData : null;
    }, [wellnessData]);
    const newNames = {
        medical_risk: 'Medical Risk',
        biomechanical_risk: 'Risk of Cracks/Breaks',
        sleep_disorder_risk: 'Sleep Disorder Risk',
        occlusion_tmj_orthodontic_risk: 'Risk of Bite/Jaw Issues',
        caries_risk: 'Risk of Cavities',
        periodontal_risk: 'Risk of Gum Disease',
        esthetics_risk: 'Esthetics Index'
    } as any
    const riskColorClass = (score: number) => {
        if (score <= 0.9) {
            return classes.boxGreen
        } else if (score <= 1.5) {
            return classes.boxGreenYellow
        } else if (score <= 3) {
            return classes.boxYellow
        } else if (score <= 3.7) {
            return classes.boxYellowRed
        } else {
            return classes.boxRed
        }
    }

    return (
        <Box>
            <CollapsibleBox
                title={'Risk Assessment'}
                toggle={true}
                openSpeedometer={openSpeedometer}
                setOpenSpeedometer={setOpenSpeedometer}
                isPatient={true}
            >
                {riskData ?
                    <Grid container lg={12} md={12} sm={12} xs={12} className={classes.parentBox}
                        pt={openSpeedometer ? 3 : 0}>
                        {Object.keys(riskData)?.map((res: any) => {
                            return (
                                <Grid
                                    className={classes.mainBox}
                                    textAlign={"center"}
                                    display={'flex'}
                                    alignItems={'center'}
                                    flexDirection={'column'}
                                >
                                    {openSpeedometer && <Box display={'flex'} justifyContent={'center'} mt={'-20px'}>
                                        <SpeedoMeter data={(riskData[res]?.score)?.toFixed(1)} />
                                    </Box>}
                                    <Box
                                        margin={'5px 0'}
                                        sx={{ width: '225px !important' }}
                                        className={`${classes.box} ${riskColorClass(riskData[res]?.score)}`}
                                        display={'flex'}
                                        justifyContent={'space-between'}
                                        alignItems={'center'}
                                    >
                                        <Typography className={`${classes.label} fw-regular`}>
                                            {(newNames[riskData[res]?.name] || res)}
                                        </Typography>
                                        <Typography className={`${classes.value} fw-medium`}>
                                            {(riskData[res]?.score)?.toFixed(1)}
                                        </Typography>
                                    </Box>
                                </Grid>
                            );
                        })}
                    </Grid> : <Box my={1.5}> <NoDataFound errorMessage={'risk score'} /></Box>}
            </CollapsibleBox>
        </Box>

    )
}

export default PatientWellnessRiskScore