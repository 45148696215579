import { clinicalExamAssistantState } from "../../../../types/dentistTypes";
import {
  GET_CLINICAL_EXAM_ASSISTANT_REQUEST,
  GET_CLINICAL_EXAM_ASSISTANT_REQUEST_FAILURE,
  GET_CLINICAL_EXAM_ASSISTANT_REQUEST_SUCCESS,
  GET_DISC_PROFILE_REQUEST,
  GET_DISC_PROFILE_REQUEST_FAILURE,
  GET_DISC_PROFILE_REQUEST_SUCCESS,
  POST_IMAGE_UPLOAD_MULTIPLE_REQUEST,
  POST_IMAGE_UPLOAD_MULTIPLE_REQUEST_SUCCESS,
  POST_IMAGE_UPLOAD_MULTIPLE_REQUEST_FAILURE,
  PUT_CLINICAL_EXAM_ASSISTANT_REQUEST,
  PUT_CLINICAL_EXAM_ASSISTANT_REQUEST_SUCCESS,
  PUT_CLINICAL_EXAM_ASSISTANT_REQUEST_FAILURE,
} from "../../../../ActionTypes/v1/dentist/clinicalExamAssistant";
import { clinicalExamAssistantActions } from "../../../../types/dentistTypes";

const initialState: clinicalExamAssistantState = {
  pending: false,
  data: null,
  error: null,
  assistant_data: null,
  put_assistant_loader: false,
  get_assistant_loader: false,
  get_disk_profile_loader: false,
  upload_image_loader: false,
};

const clinicalExamAssistantReducer = (
  state = initialState,
  action: clinicalExamAssistantActions
) => {
  switch (action.type) {
    case GET_CLINICAL_EXAM_ASSISTANT_REQUEST:
      return {
        ...state,
        get_assistant_loader: true,
      };
    case GET_CLINICAL_EXAM_ASSISTANT_REQUEST_SUCCESS:
      return {
        ...state,
        get_assistant_loader: false,
        data: action.payload,
        error: null,
      };
    case GET_CLINICAL_EXAM_ASSISTANT_REQUEST_FAILURE:
      return {
        ...state,
        get_assistant_loader: false,
        data: null,
        error: action.payload.error,
      };
    case GET_DISC_PROFILE_REQUEST:
      return {
        ...state,
        get_disk_profile_loader: true,
      };
    case GET_DISC_PROFILE_REQUEST_SUCCESS:
      return {
        ...state,
        get_disk_profile_loader: false,
        data: action.payload,
        error: null,
      };
    case GET_DISC_PROFILE_REQUEST_FAILURE:
      return {
        ...state,
        get_disk_profile_loader: false,
        data: null,
        error: action.payload.error,
      };
    case POST_IMAGE_UPLOAD_MULTIPLE_REQUEST:
      return {
        ...state,
        upload_image_loader: true,
      };
    case POST_IMAGE_UPLOAD_MULTIPLE_REQUEST_SUCCESS:
      return {
        ...state,
        upload_image_loader: false,
        data: action.payload,
        error: null,
      };
    case POST_IMAGE_UPLOAD_MULTIPLE_REQUEST_FAILURE:
      return {
        ...state,
        upload_image_loader: false,
        data: null,
        error: action.payload.error,
      };
    case PUT_CLINICAL_EXAM_ASSISTANT_REQUEST:
      return {
        ...state,
        put_assistant_loader: true,
      };
    case PUT_CLINICAL_EXAM_ASSISTANT_REQUEST_SUCCESS:
      return {
        ...state,
        assistant_data: action.payload,
        put_assistant_loader: false,
        error: null,
      };
    case PUT_CLINICAL_EXAM_ASSISTANT_REQUEST_FAILURE:
      return {
        ...state,
        put_assistant_loader: false,
        assistant_data: null,
        error: action.payload.error,
      };
    default:
      return {
        ...state,
      };
  }
};

export default clinicalExamAssistantReducer;
