import React from "react";
import {useSelector} from 'react-redux';
import {Box} from "@mui/system";
import {Grid, Typography} from "@mui/material";
import {getLocalStorageItem, handleLogout, handlePatientLogout} from "../../../../utils/helper";
import FullScreenLoader from '../../../v1/Common/FullScreenLoader';
import PopupComponent from "../PopupComponent";
import ClinicDropDown from "./ClinicDropDown";

type Props = {
    open: boolean;
    setOpen: (open: boolean) => void;
    isPatient?: boolean | undefined
}
const ClinicFilterPopUp = (props: Props) => {
    const {open, setOpen, isPatient} = props;
    const clinicData = useSelector((state: any) => state.Clinic?.data?.data);
    const pending = useSelector((state: any) => state.Clinic?.pending)

    const handleSubmit = () => {
        const selectedClinic = getLocalStorageItem(isPatient ? "patientClinic" : "clinic");
        if (selectedClinic) {
            setOpen(false);
            window.location.reload();
        }
    };
    return (
        <Box>
            {pending ? <FullScreenLoader/> :
                <PopupComponent open={open} headerAction={clinicData?.length > 0} footerAction width={'458px'}
                                notDisplayCancelIcon
                                handleSave={clinicData?.length ? handleSubmit : (isPatient ? handlePatientLogout : handleLogout)}
                                isPatient={isPatient} saveBtnText={clinicData?.length ? 'Continue' : 'logout'}
                                heading={clinicData?.length ? 'Select your clinic' : ''}
                                isOraqIcon={clinicData?.length > 0}
                                notDisplayCancel isLogoutOnSave={clinicData?.length <= 0}>
                    <Box>
                        <Box className={`d-flex-all-center flex-col`}>
                            <Grid
                                item={true}
                                lg={5.9}
                                md={5.7}
                                sm={12}
                                xs={12}
                                className="w-100"
                            >
                                {clinicData?.length ?
                                    <Box my={0.5}>
                                        <ClinicDropDown isPatient={isPatient}/>
                                    </Box> :
                                    <Box my={1} className='d-flex-all-center'> <Typography
                                        className='f-16 fw-regular lh-21 labelColor'>
                                        We apologize, but currently, no clinic has been assigned to you.
                                    </Typography></Box>
                                }
                            </Grid>
                        </Box>
                    </Box>
                </PopupComponent>
            }
        </Box>
    );
};

export default ClinicFilterPopUp;
