import React, { useEffect } from 'react'
import { Box, Button, Grid, Typography, useMediaQuery } from '@mui/material'
import { makeStyles } from '@mui/styles';
import HelmetComponent from '../../../../../components/v1/Common/HelmetComponent'
import PatientLeftSideImage from '../../../../../components/v2/patient/PatientLeftSideImage'
import { hideMiddleDigits } from '../../../../../utils/helper';
import theme from '../../../../../theme';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getPatientDataRequest } from '../../../../../app/actions/v2/patient/patientRegisterAction';
import { RootState } from '../../../../../app/reducers/v2/rootReducer';
import FullScreenLoader from '../../../../../components/v1/Common/FullScreenLoader';
import OraQLogo from '../../../../../assets/images/v2/logo.png';
import OraQFooterBranding from '../OraQFooterBranding';

const imageText = {
    text1: "Elevating your oral health and",
    text2: "wellness journey with access",
    text3: "in the palm of your hand.",
};

const useStyles = makeStyles((theme: any) => ({
    rightSection: {
        paddingLeft: "10%",
        paddingRight: "10%",
        [theme.breakpoints.down("md")]: {
            backgroundColor: theme.palette.common.white,
            height: "100vh",
            width: "100vw",
            paddingLeft: "20%",
            paddingRight: "20%",
            borderLeft: "none",
            position: 'relative'
        },
        [theme.breakpoints.down("sm")]: {
            paddingLeft: "10%",
            paddingRight: "10%",
        },
    },
    logoBox: {
        justifyContent: "center",
        alignItems: "center",
    },
    button: {
        background: `${theme.palette.secondary.main} !important`,
        [theme.breakpoints.down("md")]: {
            background: `${theme.palette.secondary.main} !important`,
        },
    },
}));

const SendOtpScreen = () => {
    const classes = useStyles();
    const { palette } = theme;
    const { clinicId, patientId }: any = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const {
        patientRegister: {
            getPatientDataLoader,
            getPatientData
        },
    } = useSelector((state: RootState) => state) as any;

    const isSmallScreen = useMediaQuery(theme.breakpoints.down(960));

    useEffect(() => {
        dispatch(getPatientDataRequest({ profile_id: patientId }))
    }, [])

    const sendOtp = () => {
        navigate(`/patient/verify-otp/${clinicId}/${patientId}?sendOtp=true`);
    }

    const redirectAccessCode = () => {
        navigate(`/patient/verify-access-code/${clinicId}?sendOtp=true`, { state: { patientId: patientId } });
    }

    return (
        <>
            <HelmetComponent title={"Send Otp | OraQ"} />

            {getPatientDataLoader && <FullScreenLoader />}

            <Grid
                container
                alignContent={"middle"}
                style={{
                    backgroundColor: "",
                    height: "100vh",
                }}
            >
                <PatientLeftSideImage imageText={imageText} />

                <Grid
                    item
                    alignItems={"center"}
                    alignSelf={"center"}
                    display={"flex"}
                    justifyContent={"center"}
                    flexDirection={"column"}
                    className={classes.rightSection}
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                >
                    <Box className={classes.logoBox} display={isSmallScreen ? "flex" : "none"}>
                        <img src={OraQLogo} width={140} height={50} alt={'OraQLogo'} />
                    </Box>

                    <Typography className='fw-medium' my={3} fontSize={24} color={palette.secondary.main}>
                        Verification
                    </Typography>
                    <Typography className='fw-regular' fontSize={14} color={palette.secondary.main} mb={3} textAlign={'center'}>
                        A <span className='fw-medium'>one-time-passcode (OTP)</span> will be sent to the following mobile number: <span className='fw-medium'>{hideMiddleDigits(getPatientData?.detail?.mobile_number)}</span>
                    </Typography>

                    <Button
                        variant={"contained"}
                        className={`fw-regular ${classes.button}`}
                        sx={{ borderRadius: "10px" }}
                        onClick={sendOtp}
                    >
                        Send OTP
                    </Button>

                    <Typography className='fw-medium' fontSize={18} color={palette.secondary.main} my={3} textAlign={'center'}>OR</Typography>

                    <Typography className='fw-regular' fontSize={14} color={palette.secondary.main} mb={3} textAlign={'center'}>
                        Phone number doesn't look right? Please contact your clinic to update your details and obtain your personal access code.
                    </Typography>

                    <Button
                        variant={"contained"}
                        className={`fw-regular ${classes.button}`}
                        sx={{ borderRadius: "10px" }}
                        onClick={redirectAccessCode}
                    >
                        Use Access Code
                    </Button>

                    <OraQFooterBranding />

                </Grid>
            </Grid >
        </>
    )
}

export default SendOtpScreen