export const GET_CLINIC_DETAILS_REQUEST = 'GET_CLINIC_DETAILS_REQUEST';
export const GET_CLINIC_DETAILS_REQUEST_SUCCESS = 'GET_CLINIC_DETAILS_REQUEST_SUCCESS';
export const GET_CLINIC_DETAILS_REQUEST_FAILURE = 'GET_CLINIC_DETAILS_REQUEST_FAILURE';

export const SAVE_ANONYMOUS_PRE_INTAKE_REQUEST = 'SAVE_ANONYMOUS_PRE_INTAKE_REQUEST';
export const SAVE_ANONYMOUS_PRE_INTAKE_REQUEST_SUCCESS = 'SAVE_ANONYMOUS_PRE_INTAKE_REQUEST_SUCCESS';
export const SAVE_ANONYMOUS_PRE_INTAKE_REQUEST_FAILURE = 'SAVE_ANONYMOUS_PRE_INTAKE_REQUEST_FAILURE';

export const SELECT_EMAIL = 'SELECT_EMAIL';
export const VERIFY_ANONYMOUS_PATIENT_EMAIL_REQUEST = 'VERIFY_ANONYMOUS_PATIENT_EMAIL_REQUEST';
export const VERIFY_ANONYMOUS_PATIENT_EMAIL_REQUEST_SUCCESS = 'VERIFY_ANONYMOUS_PATIENT_EMAIL_REQUEST_SUCCESS';
export const VERIFY_ANONYMOUS_PATIENT_EMAIL_REQUEST_FAILURE = 'VERIFY_ANONYMOUS_PATIENT_EMAIL_REQUEST_FAILURE';
export const UPDATE_EMAIL_ERROR = 'UPDATE_EMAIL_ERROR';
