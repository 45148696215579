import {
  FORGOT_PASSWORD_V2_REQUEST,
  FORGOT_PASSWORD_V2_REQUEST_SUCCESS,
  FORGOT_PASSWORD_V2_REQUEST_FAILURE,
  IS_MULTIPLE_ACCOUNT_FORGOT_PASSWORD_POPUP
} from '../../../ActionTypes/v2/auth';
import {
  ForgotPasswordRequest,
  ForgotPasswordRequestFailure,
  ForgotPasswordRequestFailurePayload,
  ForgotPasswordRequestPayload,
  ForgotPasswordRequestSuccess,
  ForgotPasswordRequestSuccessPayload,
  IsMultipleAccountForgotPasswordPopUp
} from '../../../types/v2/authActionTypes/forgotPasswordActionTypes';

export const forgotPasswordRequest = (payload: ForgotPasswordRequestPayload): ForgotPasswordRequest => ({
  type: FORGOT_PASSWORD_V2_REQUEST,
  payload
});

export const forgotPasswordRequestSuccess = (payload: ForgotPasswordRequestSuccessPayload): ForgotPasswordRequestSuccess => ({
  type: FORGOT_PASSWORD_V2_REQUEST_SUCCESS,
  payload
});

export const forgotPasswordRequestFailure = (payload: ForgotPasswordRequestFailurePayload): ForgotPasswordRequestFailure => ({
  type: FORGOT_PASSWORD_V2_REQUEST_FAILURE,
  payload
});

export const isMultipleAccountForgotPasswordPopUp = (payload: any): IsMultipleAccountForgotPasswordPopUp => ({
  type: IS_MULTIPLE_ACCOUNT_FORGOT_PASSWORD_POPUP,
  payload
});
