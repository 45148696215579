import {
  CHECK_PRE_INTAKE_REQUEST,
  CHECK_PRE_INTAKE_REQUEST_FAILURE,
  CHECK_PRE_INTAKE_REQUEST_SUCCESS,
  PATIENT_PROFILE_EDIT_REQUEST,
  PATIENT_PROFILE_EDIT_REQUEST_FAILURE,
  PATIENT_PROFILE_REQUEST,
  PATIENT_PROFILE_CACHE_DATA_CLEAR_REQUEST,
  PATIENT_PROFILE_REQUEST_FAILURE,
  PATIENT_PROFILE_REQUEST_SUCCESS,
  PATIENT_PROFILE_EDIT_REQUEST_SUCCESS,
} from "../../../ActionTypes/v1/patient/patientProfile";
import {
  PatientProfileActions,
  patientProfileState,
} from "../../../types/patientTypes";

const initialState: patientProfileState = {
  pending: false,
  data: null,
  isPreIntake: null,
  error: null,
  loaderCheckPreIntake: false
};
const patientProfileReducer = (
  state = initialState,
  action: PatientProfileActions
) => {
  switch (action.type) {
    case PATIENT_PROFILE_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case PATIENT_PROFILE_CACHE_DATA_CLEAR_REQUEST:
      return {
        ...state,
        data: null,
      };
    case PATIENT_PROFILE_REQUEST_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.payload,
        error: null,
      };

    case PATIENT_PROFILE_REQUEST_FAILURE:
      return {
        ...state,
        pending: false,
        data: null,
        error: action.payload.error,
      };
    case PATIENT_PROFILE_EDIT_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case PATIENT_PROFILE_EDIT_REQUEST_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.payload,
        error: null
      }

    case PATIENT_PROFILE_EDIT_REQUEST_FAILURE:
      return {
        ...state,
        pending: false,
        data: null,
        error: action.payload.error,
      };
    case CHECK_PRE_INTAKE_REQUEST:
      return {
        ...state,
        loaderCheckPreIntake: true,
      };
    case CHECK_PRE_INTAKE_REQUEST_SUCCESS:
      return {
        ...state,
        loaderCheckPreIntake: false,
        isPreIntake: action.payload,
        error: null,
      };
    case CHECK_PRE_INTAKE_REQUEST_FAILURE:
      return {
        ...state,
        loaderCheckPreIntake: false,
        isPreIntake: null,
        error: action.payload.error,
      };
    default:
      return {
        ...state,
      };
  }
};
export default patientProfileReducer;
