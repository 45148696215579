import {
  LOGIN_V2_REQUEST,
  LOGIN_V2_REQUEST_FAILURE,
  LOGIN_V2_REQUEST_SUCCESS,
  UPDATE_DEVICE_TOKEN,
  GOOGLE_LOGIN_REQUEST,
  GOOGLE_LOGIN_REQUEST_SUCCESS,
  GOOGLE_LOGIN_REQUEST_FAILURE
} from '../../../ActionTypes/v2/auth';
import {
  googleLoginRequest,
  googleLoginRequestFailure,
  googleLoginRequestFailurePayload,
  googleLoginRequestPayload,
  googleLoginRequestSuccess,
  googleLoginRequestSuccessPayload,
  LoginRequest,
  LoginRequestFailure,
  LoginRequestFailurePayload,
  LoginRequestPayload,
  LoginRequestSuccess,
  LoginRequestSuccessPayload,
  updateDeviceToken
} from '../../../types/v2/authActionTypes/loginActionTypes';

export const loginRequest = (payload: LoginRequestPayload): LoginRequest => ({
  type: LOGIN_V2_REQUEST,
  payload
});

export const loginRequestSuccess = (payload: LoginRequestSuccessPayload): LoginRequestSuccess => ({
  type: LOGIN_V2_REQUEST_SUCCESS,
  payload
});

export const loginRequestFailure = (payload: LoginRequestFailurePayload): LoginRequestFailure => ({
  type: LOGIN_V2_REQUEST_FAILURE,
  payload
});

export const updateDeviceTokenAction = (payload: any): updateDeviceToken => ({
  type: UPDATE_DEVICE_TOKEN,
  payload
});

export const googleLoginRequestAction = (payload: googleLoginRequestPayload): googleLoginRequest => ({
  type: GOOGLE_LOGIN_REQUEST,
  payload
});

export const googleLoginRequestSuccessAction = (payload: googleLoginRequestSuccessPayload): googleLoginRequestSuccess => ({
  type: GOOGLE_LOGIN_REQUEST_SUCCESS,
  payload
});

export const googleLoginRequestFailureAction = (payload: googleLoginRequestFailurePayload): googleLoginRequestFailure => ({
  type: GOOGLE_LOGIN_REQUEST_FAILURE,
  payload
});
