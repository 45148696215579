export const softTissueSubType = [
    {
        label: "WNL",
        value: "WNL",
        isDisabled: false,
    },
    {
        label: "Abnormality",
        value: "Abnormality",
        isDisabled: false,
    },
]
export const skeletalSubType = [
    {
        label: "I",
        value: "I",
        isDisabled: false,
    },
    {
        label: "II",
        value: "II",
        isDisabled: false,
    },
    {
        label: "III",
        value: "III",
        isDisabled: false,
    },
]
export const softTissueData = [
    {
        option: softTissueSubType,
        type: "select",
        title: "Sub Type",
        name: "sub_type",
        value: "",
    },
    {
        name: "tissue_notes",
        type: "string",
        title: "Notes",
        value: ""
    },
];
export const thyroidData = [
    {

        option: softTissueSubType,
        type: "select",
        title: "Sub Type",
        name: "thyroid_subtype",
        value: "",
    },
    {

        name: "thyroid_notes",
        type: "string",
        title: "Notes",
        value: "",
    },
];
export const skeletalData = [
    {

        option: skeletalSubType,
        type: "select",
        title: "Sub Type",
        name: "skeletal_class_subtype",
        value: "",
    },
];