// get patient list
import {
    GET_PATIENT_LIST_REQUEST,
    GET_PATIENT_LIST_REQUEST_FAILURE,
    GET_PATIENT_LIST_REQUEST_SUCCESS
} from "../../../ActionTypes/v2/dentist/patientsList";
import {
    GetPatientListRequest,
    GetPatientListRequestPayload,
    GetPatientListRequestSuccessPayload,
    GetPatientListRequestSuccess,
    GetPatientListRequestFailurePayload,
    GetPatientListRequestFailure
} from "../../../types/v2/dentist/patientsList";


export const getPatientListRequest = (payload: GetPatientListRequestPayload): GetPatientListRequest => ({
    type: GET_PATIENT_LIST_REQUEST,
    payload,
})
export const getPatientListRequestSuccess = (payload: GetPatientListRequestSuccessPayload): GetPatientListRequestSuccess => ({
    type: GET_PATIENT_LIST_REQUEST_SUCCESS,
    payload,
})
export const getPatientListRequestFailure = (payload: GetPatientListRequestFailurePayload): GetPatientListRequestFailure => ({
    type: GET_PATIENT_LIST_REQUEST_FAILURE,
    payload,
})
