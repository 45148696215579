import {
  UPDATE_EXTRA_ORAL,
  CHANGE_EXTRA_ORAL,
  UPDATE_EXTRA_ORAL_FAILURE,
} from "../../../../ActionTypes/v1/dentist/clinicalExamAssistant";
import { ExtraOralAssistantInterface } from "../../../../types/dentistTypes";

export const updateExtraoralRequest = (
  payload: ExtraOralAssistantInterface
): ExtraOralAssistantInterface =>
  ({
    type: UPDATE_EXTRA_ORAL,
    payload,
  } as any);

export const updateExtraoralRequestFailure = (): any =>
  ({
    type: UPDATE_EXTRA_ORAL_FAILURE,
  } as any);

export const changeExtraoralAction = (payload: any): any =>
  ({
    type: CHANGE_EXTRA_ORAL,
    payload,
  } as any);
