import Reference18 from '../../assets/images/v1/clinicalExamImage/reference18.png';
import Reference1 from '../../assets/images/v1/clinicalExamImage/reference1new.png';
import Reference2 from '../../assets/images/v1/clinicalExamImage/reference2new.png';
import Reference3 from '../../assets/images/v1/clinicalExamImage/reference3new.png';
import Reference4 from '../../assets/images/v1/clinicalExamImage/reference4new.png';
import Reference5 from '../../assets/images/v1/clinicalExamImage/reference5new.png';
import Reference7 from '../../assets/images/v1/clinicalExamImage/reference7new.png';
import Reference8 from '../../assets/images/v1/clinicalExamImage/reference8new.png';
import Reference11 from '../../assets/images/v1/clinicalExamImage/reference11new.png';
import Reference12 from '../../assets/images/v1/clinicalExamImage/reference12new.png';
import Reference13 from '../../assets/images/v1/clinicalExamImage/reference13new.png';
import Reference14 from '../../assets/images/v1/clinicalExamImage/reference14new.png';
import Reference15 from '../../assets/images/v1/clinicalExamImage/reference15new.png';
import Reference16 from '../../assets/images/v1/clinicalExamImage/reference16new.png';
import Reference17 from '../../assets/images/v1/clinicalExamImage/reference17new.png';
import ReferenceImg3 from '../../assets/images/v1/PatientPhotos/image1.png';
import ESoundImage from '../../assets/images/v1/PatientPhotos/eSound.png';
import FrontRefClosed from '../../assets/images/v1/PatientPhotos/frontRefClosed.png';
import FrontRefTeethApart from '../../assets/images/v1/PatientPhotos/frontRefteethApart.png';
import SideRightClosed from '../../assets/images/v1/PatientPhotos/sideRightClose.png';
import PostOrophrynx from '../../assets/images/v1/PatientPhotos/postOropharynx.png';
import SideLeftTeethClose from '../../assets/images/v1/PatientPhotos/sideLeftTeethClose.png';
import TongueSticking from '../../assets/images/v1/PatientPhotos/tongueSticking.png';
import { NPE, RE, LE } from './examConstant';

export const referencePhotos = [
  {
    title: 'Front face profile full smile',
    refImage: Reference18,
    examType: [NPE]
  },
  {
    title: 'Front face profile repose (open mouth)',
    refImage: Reference15,
    examType: [NPE]
  },
  {
    title: 'Side face profile full smile',
    refImage: Reference16,
    examType: [NPE]
  },
  {
    title: 'Side face profile repose (open mouth)',
    refImage: Reference17,
    examType: [NPE]
  },
  {
    title: 'Front face profile with retractors and teeth apart',
    refImage: ReferenceImg3,
    examType: [NPE]
  },
  {
    title: 'E Sound',
    refImage: ESoundImage,
    examType: [NPE]
  },
  {
    title: 'Repose (teeth showing)',
    refImage: Reference1,
    examType: [NPE]
  },
  {
    title: 'Full smile (teeth showing)',
    refImage: Reference2,
    examType: [NPE, RE, LE]
  },
  {
    title: 'Side Left Smile',
    refImage: Reference4,
    examType: [NPE]
  },
  {
    title: 'Side Right Smile',
    refImage: Reference3,
    examType: [NPE]
  },
  {
    title: 'Front retractors closed',
    refImage: FrontRefClosed,
    examType: [NPE, RE, LE]
  },
  {
    title: 'Front retractors teeth apart',
    refImage: FrontRefTeethApart,
    examType: [NPE]
  },
  {
    title: 'Front retractors anterior edge to edge',
    refImage: Reference5,
    examType: [NPE]
  },
  {
    title: 'Side left teeth closed with both retractors',
    refImage: SideLeftTeethClose,
    examType: [NPE]
  },
  {
    title: 'Side left teeth open with both retractors',
    refImage: Reference8,
    examType: [NPE]
  },
  {
    title: 'Side right closed with both retractors',
    refImage: SideRightClosed,
    examType: [NPE]
  },
  {
    title: 'Side right open with both retractors',
    refImage: Reference7,
    examType: [NPE]
  },
  {
    title: 'Maxillary 13-23 with contrastor',
    refImage: Reference12,
    examType: [NPE]
  },
  {
    title: 'Mandibular 33-43 with contrastor',
    refImage: Reference11,
    examType: [NPE]
  },
  {
    title: 'Tongue depressed posterior oropharynx',
    refImage: PostOrophrynx,
    examType: [NPE]
  },
  {
    title: 'Tongue sticking out posterior oropharynx (Saying AH)',
    refImage: TongueSticking,
    examType: [NPE, RE, LE]
  },
  {
    title: 'Maxillary occlusal arch',
    refImage: Reference13,
    examType: [NPE, RE, LE]
  },
  {
    title: 'Mandibular occlusal arch',
    refImage: Reference14,
    examType: [NPE, RE, LE]
  }
];

export const referenceXray = [
  { title: 'Right Molar BW: Q1+4' },
  { title: 'Right Premolar BW: Q1+4' },
  { title: 'Left Premolar BW: Q2+3' },
  { title: 'Left Molar BW: Q2+3' },
  { title: 'Maxillary Anterior PA R Canine-Central: 13-11' },
  { title: 'Maxillary Anterior PA Centrals: 11+21' },
  { title: 'Maxillary Anterior PA L Canine-Centrals: 23-21' },
  { title: 'Mandibular PA Centrals: 32-42' },
  { title: 'Panoramic xray' }
];
