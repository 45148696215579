// dentist Pre-intake
export const GET_DENTIST_PRE_INTAKE_REQUEST = "GET_DENTIST_PRE_INTAKE_REQUEST";
export const GET_DENTIST_PRE_INTAKE_REQUEST_SUCCESS = "GET_DENTIST_PRE_INTAKE_REQUEST_SUCCESS";
export const GET_DENTIST_PRE_INTAKE_REQUEST_FAILURE = "GET_DENTIST_PRE_INTAKE_REQUEST_FAILURE";

export const POST_DENTIST_PRE_INTAKE_DATA_REQUEST = "POST_DENTIST_PRE_INTAKE_DATA_REQUEST";
export const POST_DENTIST_PRE_INTAKE_DATA_REQUEST_FAILURE = "POST_DENTIST_PRE_INTAKE_DATA_REQUEST_FAILURE";

export const PUT_DENTIST_PRE_INTAKE_REQUEST = "PUT_DENTIST_PRE_INTAKE_REQUEST";
export const PUT_DENTIST_PRE_INTAKE_REQUEST_SUCCESS = "PUT_DENTIST_PRE_INTAKE_REQUEST_SUCCESS";
export const PUT_DENTIST_PRE_INTAKE_REQUEST_FAILURE = "PUT_DENTIST_PRE_INTAKE_REQUEST_FAILURE";

