import { IntraOralAssistantInterface } from "../../../../types/dentistTypes";
import {
  UPDATE_ASSISTANT_INTRAORAL,
  ADD_ASSISTANT_INTRAORAL_FINDING_ROW,
  CHANGE_ASSISTANT_INTRAORAL_FINDING,
  UPDATE_ASSISTANT_INTRAORAL_FAILURE,
} from "../../../../ActionTypes/v1/dentist/clinicalExamAssistant";
import {
  defaultMpclass,
  defaultTonsilClass,
  defaultTongueScalloping,
  defaultTongueTies,
  defaultSoftPalete,
  defaultUvala,
  defaultMandTori,
} from "../../../../../utils/intraOralConstant";
import {
  defaultCacheMpclass,
  defaultCacheTonsilClass,
  defaultCacheTongueScalloping,
  defaultCacheTongueTies,
  defaultCacheSoftPalete,
  defaultCacheUvala,
  defaultCacheMandTori,
} from "../../../../../utils/cacheDataConstant/intraOralConstant";
import { jsonParseAndStringfy } from "../../../../../utils/helper";

const initialState: IntraOralAssistantInterface = {
  mpClass: [defaultMpclass],
  tonsilClass: [defaultTonsilClass],
  tongueScalloping: [defaultTongueScalloping],
  tongueTies: [defaultTongueTies],
  softPalate: [defaultSoftPalete],
  uvala: [defaultUvala],
  mandTori: [defaultMandTori],
  concerns_sleep_quality: false,
  concerns_sleep_quality_notes: "",
  hx_snoring: "No",
  hx_sleep_test: "",
};

export const intraOralReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case UPDATE_ASSISTANT_INTRAORAL:
      return {
        ...state,
        ...action.payload,
      };
    case UPDATE_ASSISTANT_INTRAORAL_FAILURE:
      const newMPClass = jsonParseAndStringfy(defaultCacheMpclass);
      const newTonsilClass = jsonParseAndStringfy(defaultCacheTonsilClass);
      const newTongueScalloping = jsonParseAndStringfy(defaultCacheTongueScalloping);
      const newTongueTies = jsonParseAndStringfy(defaultCacheTongueTies);
      const newSoftPalate = jsonParseAndStringfy(defaultCacheSoftPalete);
      const newUvala = jsonParseAndStringfy(defaultCacheUvala);
      const newMandTori = jsonParseAndStringfy(defaultCacheMandTori);
      return {
        mpClass: [newMPClass],
        tonsilClass: [newTonsilClass],
        tongueScalloping: [newTongueScalloping],
        tongueTies: [newTongueTies],
        softPalate: [newSoftPalate],
        uvala: [newUvala],
        mandTori: [newMandTori],
        concerns_sleep_quality: false,
        concerns_sleep_quality_notes: "",
        hx_snoring: "No",
        hx_sleep_test: "",
      };
    case ADD_ASSISTANT_INTRAORAL_FINDING_ROW:
      const finding = action.payload as string;
      const newStateAdd = state as any;
      const updatedFinding = newStateAdd[finding];
      if (finding === "mpClass") {
        updatedFinding.push(defaultMpclass);
      } else if (finding === "tonsilClass") {
        updatedFinding.push(defaultTonsilClass);
      } else if (finding === "tongueScalloping") {
        updatedFinding.push(defaultTongueScalloping);
      } else if (finding === "tongueTies") {
        updatedFinding.push(defaultTongueTies);
      } else if (finding === "softPalate") {
        updatedFinding.push(defaultSoftPalete);
      } else if (finding === "uvala") {
        updatedFinding.push(defaultUvala);
      } else if (finding === "mandTori") {
        updatedFinding.push(defaultMandTori);
      }
      return {
        ...state,
        [finding]: updatedFinding,
      };
    case CHANGE_ASSISTANT_INTRAORAL_FINDING:
      const { toFinding, value, type, index, key } = action.payload as any;
      const newState = state as any;
      let newFinding = newState[toFinding] as any;
      if (
        newFinding &&
        typeof newFinding !== "string" &&
        typeof newFinding !== "boolean"
      ) {
        newFinding[index] = newFinding[index]?.map((res: any) => {
          if (res.name === key) {
            return { ...res, value: value };
          }
          return res;
        });
      } else {
        newFinding = value;
      }
      return {
        ...state,
        [toFinding]: newFinding,
      };
    default:
      return { ...state };
  }
};
