import { preIntakeTypes } from "../../../ActionTypes/v1/preIntake";
import { preIntakeActions } from "../../../types/patientTypes";

const initialState: any = {
  data: {
    consent:
      "I consent to collection, disclosure and use of my personal information as set out above",
    patient_initials: "",
    name: "",
    date_of_birth: "",
    address: "",
    city: "",
    postal_code: "",
    home_phone_number: "",
    mobile_phone_number: "",
    email: "",
    emergency_contact: "",
    emergency_contact_phone_number: "",
    relationship_to_emergency_contact: "",
    occupation: "",
    does_your_work_involve_night_shift: false,
    referred_by: "",
    marital_status: "",
    gender: "",
    dental_insurance: false,
    insurance_provider: "",
    insurance_group_number: null,
    insurance_id: null,
    history_of_smoking: "",
    alcohol_usage_in_week: "",
    recreational_drug_use: "",
    recreational_drug_use_other: "",
    height: "",
    height_unit: "ft",
    // height_ft: '',
    // height_inch: '',
    weight: "",
    weight_unit: "lbs",
    dental_anxiety: 0,
    reason_for_scheduling_this_visit: "",
    dentist_view: null,
    previous_dental_office_name: "",
    antibiotic_premedication_require_prior_dental_appointments: null,
    antibiotic_premedication: "",
    dental_health_average: "",
    appearance_smile_scale_to_10: 0,
    wish_correct_appearance_smile: null,
    wish_whiter_smile: null,
    brand_toothpaste: "",
    diagnosed_sleep_apnea_ever: null,
    neck_circumference: "",
    loud_snore: "",
    fatigue_daily_bases: null,
    breathing_stop_sleep: null,
    difficulty_breathing_through_your_nose: null,
    cpap_machine: false,
    oral_appliance_sleep: false,
    sitting_reading_epworth_scale: null,
    watching_tv_epworth_scale: null,
    sitting_inactive_epworth_scale: null,
    being_passenger_car_epworth_scale: null,
    lying_down_afternoon_epworth_scale: null,
    sitting_talking_someone_epworth_scale: null,
    sitting_quietly_lunch_epworth_scale: null,
    stopping_fewminutes_driving_epworth_scale: null,
    physicians_name: "",
    physician_phone_number: "",
    last_visit_to_physician: null,
    serious_illness_or_operation_existance: null,
    serious_illness_or_operation_name: "",
    medication_list: "",
    birth_control: null,
    pregnant: null,
    nursing: null,
    chest_pain_shortness_breath: null,
    list_of_checked_items: "",
    allergies_list: "",
    importancy_of_teeth_health_scale: 0,
    loose_tooth_feeling_scale: 0,
    chew_importance_scale: 0,
    appearance_teeth_scale: 0,
    concerns_appearance_teeth_existance: null,
    concerns_appearance_teeth_existance_explain: "",
    teeth_sensitivity: null,
    teeth_sensitivity_answer_yes: "",
    describe_yourself_1: "",
    describe_yourself_2: "",
    firstName: "",
    lastName: "",
    patient_id: null,
    dental_info: [],
    snack_3x_day: null,
    filling_had_in_last_3_year: "",
    tooth_extracted_last_3_year: null,
    family_history_of_cavities_toothloss: null,
    flouride_water: false,
    flouride_toothpaste_once_a_day: false,
    flouride_toothpaste_2x_or_more: false,
    flouride_toothpaste_5000_ppm: false,
    flouride_toothpaste_varnish_last_6_month: false,
    sodium_flouride_mouthrinse_daily: false,
    chlorhexidine_gluconate_mouthrinse_daily: false,
    drymouth: false,
  },
};

export const postPreIntakeDataReducer = (
  state = initialState,
  action: preIntakeActions
) => {
  switch (action.type) {
    case preIntakeTypes.POST_PRE_INTAKE_DATA_REQUEST:
        const temp = state.data;
        temp[action.payload.key] = action.payload.value;       
        return {
          ...state,
          data: temp,
        };    
    case preIntakeTypes.POST_PRE_INTAKE_DATA_REQUEST_FAILURE:
      return {
        ...state,
        data: {
          consent:
            "I consent to collection, disclosure and use of my personal information as set out above",
          patient_initials: "",
          // TODO: removed because required field must be there
          // name: "",
          // date_of_birth: "",
          // address: "",
          // city: "",
          // postal_code: "",
          // home_phone_number: "",
          // mobile_phone_number: "",
          // email: "",
          emergency_contact: "",
          emergency_contact_phone_number: "",
          relationship_to_emergency_contact: "",
          occupation: "",
          does_your_work_involve_night_shift: false,
          referred_by: "",
          marital_status: "",
          gender: "",
          dental_insurance: false,
          insurance_provider: "",
          insurance_group_number: null,
          insurance_id: null,
          history_of_smoking: "",
          alcohol_usage_in_week: "",
          recreational_drug_use: "",
          recreational_drug_use_other: "",
          height: "",
          height_unit: "ft",
          weight: "",
          weight_unit: "lbs",
          dental_anxiety: 0,
          reason_for_scheduling_this_visit: "",
          dentist_view: null,
          previous_dental_office_name: "",
          antibiotic_premedication_require_prior_dental_appointments: null,
          antibiotic_premedication: "",
          dental_health_average: "",
          appearance_smile_scale_to_10: 0,
          wish_correct_appearance_smile: null,
          wish_whiter_smile: null,
          brand_toothpaste: "",
          diagnosed_sleep_apnea_ever: null,
          neck_circumference: "",
          loud_snore: "",
          fatigue_daily_bases: null,
          breathing_stop_sleep: null,
          difficulty_breathing_through_your_nose: null,
          cpap_machine: false,
          oral_appliance_sleep: false,
          sitting_reading_epworth_scale: null,
          watching_tv_epworth_scale: null,
          sitting_inactive_epworth_scale: null,
          being_passenger_car_epworth_scale: null,
          lying_down_afternoon_epworth_scale: null,
          sitting_talking_someone_epworth_scale: null,
          sitting_quietly_lunch_epworth_scale: null,
          stopping_fewminutes_driving_epworth_scale: null,
          physicians_name: "",
          physician_phone_number: "",
          last_visit_to_physician: null,
          serious_illness_or_operation_existance: null,
          serious_illness_or_operation_name: "",
          medication_list: "",
          birth_control: null,
          pregnant: null,
          nursing: null,
          chest_pain_shortness_breath: null,
          list_of_checked_items: "",
          allergies_list: "",
          importancy_of_teeth_health_scale: 0,
          loose_tooth_feeling_scale: 0,
          chew_importance_scale: 0,
          appearance_teeth_scale: 0,
          concerns_appearance_teeth_existance: null,
          concerns_appearance_teeth_existance_explain: "",
          teeth_sensitivity: null,
          teeth_sensitivity_answer_yes: "",
          describe_yourself_1: "",
          describe_yourself_2: "",
          firstName: "",
          lastName: "",
          patient_id: null,
          dental_info: [],
          snack_3x_day: null,
          filling_had_in_last_3_year: "",
          tooth_extracted_last_3_year: null,
          family_history_of_cavities_toothloss: null,
          flouride_water: false,
          flouride_toothpaste_once_a_day: false,
          flouride_toothpaste_2x_or_more: false,
          flouride_toothpaste_5000_ppm: false,
          flouride_toothpaste_varnish_last_6_month: false,
          sodium_flouride_mouthrinse_daily: false,
          chlorhexidine_gluconate_mouthrinse_daily: false,
          drymouth: false,
        },
      };
    default:
      return {
        ...state
      }  
  }
  
};
