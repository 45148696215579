import {
  GENERATE_RISK_ASSESSMENT_REQUEST,
  GENERATE_RISK_ASSESSMENT_REQUEST_FAILURE,
  GENERATE_RISK_ASSESSMENT_REQUEST_SUCCESS,
  GET_RISK_ASSESSMENT_REQUEST,
  GET_RISK_ASSESSMENT_REQUEST_FAILURE,
  GET_RISK_ASSESSMENT_REQUEST_SUCCESS,
  PUT_RISK_ASSESSMENT_REQUEST,
  PUT_RISK_ASSESSMENT_REQUEST_FAILURE,
  PUT_RISK_ASSESSMENT_REQUEST_SUCCESS,
  UPDATE_RISK_ASSESSMENT,
  UPDATE_RISK_ASSESSMENT_VALIDATION
} from '../../../../ActionTypes/v2/dentist/clinicalExam/riskAssessment';
import {
  GenerateRiskAssessmentFailureRequest,
  GenerateRiskAssessmentRequest,
  GenerateRiskAssessmentSuccessRequest,
  getRiskAssessmentFailurePayload,
  getRiskAssessmentRequest,
  getRiskAssessmentRequestFailure,
  getRiskAssessmentRequestPayload,
  getRiskAssessmentRequestSuccess,
  getRiskAssessmentRequestSuccessPayload,
  putRiskAssessmentFailurePayload,
  putRiskAssessmentRequest,
  putRiskAssessmentRequestFailure,
  putRiskAssessmentRequestPayload,
  putRiskAssessmentRequestSuccess,
  putRiskAssessmentRequestSuccessPayload,
  UpdateRiskAssessmentPayload,
  UpdateRiskAssessmentRequest
} from '../../../../types/v2/dentist/clinicalExam/riskAssessment';

export const updateRiskAssessmentAction = (payload: any): any => ({
  type: UPDATE_RISK_ASSESSMENT,
  payload
});

export const getRiskAssessmentRequestAction = (payload: getRiskAssessmentRequestPayload): getRiskAssessmentRequest => ({
  type: GET_RISK_ASSESSMENT_REQUEST,
  payload
});

export const getRiskAssessmentRequestSuccessAction = (
  payload: getRiskAssessmentRequestSuccessPayload
): getRiskAssessmentRequestSuccess => ({
  type: GET_RISK_ASSESSMENT_REQUEST_SUCCESS,
  payload
});

export const getRiskAssessmentRequestFailureAction = (
  payload: getRiskAssessmentFailurePayload
): getRiskAssessmentRequestFailure => ({
  type: GET_RISK_ASSESSMENT_REQUEST_FAILURE,
  payload
});

export const putRiskAssessmentRequestAction = (payload: putRiskAssessmentRequestPayload): putRiskAssessmentRequest => ({
  type: PUT_RISK_ASSESSMENT_REQUEST,
  payload
});

export const putRiskAssessmentRequestSuccessAction = (
  payload: putRiskAssessmentRequestSuccessPayload
): putRiskAssessmentRequestSuccess => ({
  type: PUT_RISK_ASSESSMENT_REQUEST_SUCCESS,
  payload
});

export const putRiskAssessmentRequestFailureAction = (
  payload: putRiskAssessmentFailurePayload
): putRiskAssessmentRequestFailure => ({
  type: PUT_RISK_ASSESSMENT_REQUEST_FAILURE,
  payload
});

export const updateRiskAssessmentValidationAction = (payload: any): any => ({
  type: UPDATE_RISK_ASSESSMENT_VALIDATION,
  payload
});

export const generateRiskAssessmentRequestAction = (payload: any): GenerateRiskAssessmentRequest => ({
  type: GENERATE_RISK_ASSESSMENT_REQUEST,
  payload
});

export const generateRiskAssessmentRequestSuccessAction = (payload: any): GenerateRiskAssessmentSuccessRequest => ({
  type: GENERATE_RISK_ASSESSMENT_REQUEST_SUCCESS,
  payload
});

export const generateRiskAssessmentRequestFailureAction = (payload: any): GenerateRiskAssessmentFailureRequest => ({
  type: GENERATE_RISK_ASSESSMENT_REQUEST_FAILURE,
  payload
});
