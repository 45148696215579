import {
  CHECK_PRE_INTAKE_REQUEST,
  CHECK_PRE_INTAKE_REQUEST_FAILURE,
  CHECK_PRE_INTAKE_REQUEST_SUCCESS,
  PATIENT_PROFILE_EDIT_REQUEST,
  PATIENT_PROFILE_EDIT_REQUEST_FAILURE,
  PATIENT_PROFILE_REQUEST,
  PATIENT_PROFILE_CACHE_DATA_CLEAR_REQUEST,
  PATIENT_PROFILE_REQUEST_FAILURE,
  PATIENT_PROFILE_REQUEST_SUCCESS,
  PATIENT_PROFILE_EDIT_REQUEST_SUCCESS,
} from "../../../../ActionTypes/v1/patient/patientProfile";
import {
  CheckPreIntakeRequest,
  CheckPreIntakeRequestFailure,
  CheckPreIntakeRequestFailurePayload,
  CheckPreIntakeRequestPayload,
  CheckPreIntakeRequestSuccess,
  CheckPreIntakeRequestSuccessPayload,
  PatientProfileEditRequest,
  PatientProfileEditRequestFailure,
  PatientProfileEditRequestFailurePayload,
  PatientProfileEditRequestPayload,
  PatientProfileEditRequestSuccess,
  PatientProfileEditRequestSuccessPayload,
  PatientProfileRequest,
  PatientProfileRequestFailure,
  PatientProfileRequestFailurePayload,
  PatientProfileRequestPayload,
  PatientProfileRequestSuccess,
  PatientProfileRequestSuccessPayload,
} from "../../../../types/patientTypes";

//Patient Profile Data
export const patientProfileRequest = (
  payload: PatientProfileRequestPayload
): PatientProfileRequest => ({
  type: PATIENT_PROFILE_REQUEST,
  payload,
});
export const patientProfileCacheDataClearRequest = (): any => ({
  type: PATIENT_PROFILE_CACHE_DATA_CLEAR_REQUEST,
});

export const patientProfileRequestSuccess = (
  payload: PatientProfileRequestSuccessPayload
): PatientProfileRequestSuccess => ({
  type: PATIENT_PROFILE_REQUEST_SUCCESS,
  payload,
});

export const patientProfileRequestFailure = (
  payload: PatientProfileRequestFailurePayload
): PatientProfileRequestFailure => ({
  type: PATIENT_PROFILE_REQUEST_FAILURE,
  payload,
});

//Edit Patient Profile

export const patientProfileEditRequest = (
  payload: PatientProfileEditRequestPayload
): PatientProfileEditRequest => ({
  type: PATIENT_PROFILE_EDIT_REQUEST,
  payload,
});
export const patientProfileEditRequestSuccess = (
    payload: PatientProfileEditRequestSuccessPayload
): PatientProfileEditRequestSuccess => ({
    type: PATIENT_PROFILE_EDIT_REQUEST_SUCCESS,
    payload
});
export const patientProfileEditRequestFailure = (
  payload: PatientProfileEditRequestFailurePayload
): PatientProfileEditRequestFailure => ({
  type: PATIENT_PROFILE_EDIT_REQUEST_FAILURE,
  payload,
});

//check PreIntake

export const checkPreIntakeRequest = (
  payload: CheckPreIntakeRequestPayload
): CheckPreIntakeRequest => ({
  type: CHECK_PRE_INTAKE_REQUEST,
  payload,
});
export const checkPreIntakeRequestSuccess = (
  payload: CheckPreIntakeRequestSuccessPayload
): CheckPreIntakeRequestSuccess => ({
  type: CHECK_PRE_INTAKE_REQUEST_SUCCESS,
  payload,
});

export const checkPreIntakeRequestFailure = (
  payload: CheckPreIntakeRequestFailurePayload
): CheckPreIntakeRequestFailure => ({
  type: CHECK_PRE_INTAKE_REQUEST_FAILURE,
  payload,
});
