import {
  CHANGE_PASSWORD_V2_REQUEST,
  CHANGE_PASSWORD_V2_REQUEST_FAILURE,
  CHANGE_PASSWORD_V2_REQUEST_SUCCESS
} from '../../../ActionTypes/v2/auth';
import { changePasswordActions, changePasswordInterface } from '../../../types/v2/authActionTypes/changePasswordActionTypes';

const initialState: changePasswordInterface = {
  changePasswordLoader: false,
  changePasswordData: null,
  changePasswordError: null
};

const changePasswordReducer = (state = initialState, action: changePasswordActions) => {
  switch (action.type) {
    case CHANGE_PASSWORD_V2_REQUEST:
      return {
        ...state,
        changePasswordLoader: true
      };

    case CHANGE_PASSWORD_V2_REQUEST_SUCCESS:
      return {
        ...state,
        changePasswordLoader: false,
        changePasswordData: action.payload,
        changePasswordError: null
      };

    case CHANGE_PASSWORD_V2_REQUEST_FAILURE:
      return {
        ...state,
        changePasswordLoader: false,
        changePasswordData: null,
        changePasswordError: action.payload.changePasswordError
      };

    default:
      return {
        ...state
      };
  }
};
export default changePasswordReducer;
