import { Button, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { syncOdontogramPmsDataRequestAction } from '../../../../../../app/actions/v2/dentist/clinicalExam/exam/examFindingsTypesAction';
import theme from '../../../../../../theme';
import {completeCheckout, viewExam} from '../../../../../../utils/v2/clinicalExamConstant';


const OdontogramSync = () => {
  const { palette } = theme
  const takeFromUrl = useParams();
  const dispatch = useDispatch()
  const location = useLocation()
  const isWellnessProfile = location?.pathname?.includes('/v2/dentist/wellness-profile/')
  const {
    appointmentsReducer: { appointmentDetails },
  } = useSelector((state: any) => state) as any;

  const disabledField = useMemo(() => {
    return (
      [completeCheckout, viewExam]?.includes(appointmentDetails?.sub_status) || isWellnessProfile
    )
  }, [appointmentDetails, location?.pathname]);

  const syncPms = () => {
    dispatch(syncOdontogramPmsDataRequestAction({
      appointment_id: takeFromUrl?.appointmentId,
      patientId: takeFromUrl?.patientId
    }))
  }
  return (
    <Box className='d-flex justify-content-between align-center w-100' pl={15} mt={1} minWidth={854} >
      <Box />
      <Box className='d-flex ' py={1.5}>
        <Box className='d-flex'>
          <Typography className='f-14 f-w-400 fw-regular' sx={{ color: theme.palette.v2.primary.main, textAlign: 'center' }}>
            Chart the following findings in office PMS. Once completed, Hit SYNC in OraQ.
            <br />
            <Typography className='f-14 f-w-400 fw-regular' sx={{ color: theme.palette.common.black, textAlign: 'center' }} >
              Findings: Missing, Watch, Crown, Implant, Decay, RCT, Filling</Typography>
          </Typography>
        </Box>
      </Box>
      <Button
        disabled={disabledField}
        variant='outlined'
        className='f-14 f-w-400 fw-regular'
        sx={{
          borderColor: palette.v2.primary.main,
          color: palette.v2.primary.main,
          marginRight: '10px'
        }}
        onClick={syncPms}
      >
        Sync
      </Button>
    </Box>
  );
};

export default OdontogramSync;
