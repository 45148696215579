import {all, call, put, takeEvery} from "redux-saga/effects";
import API from "../../../../utils/api";
import {
    getAppointmentNotificationDentistRequestFailure,
    getAppointmentNotificationDentistRequestSuccess,
    patientAppointmentCacheDataClearRequest,
    patientAppointmentDetailCacheDataClearRequest,
    patientAppointmentDetailsRequestFailure,
    patientAppointmentDetailsRequestSuccess,
    patientAppointmentRequestFailure,
    patientAppointmentRequestSuccess,
} from "../../../actions/v1/patient/PatientAppointmentAction/PatientAppointmentAction";
import {
    GET_APPOINTMENT_NOTIFICATION_DENTIST_REQUEST,
    PATIENT_APPOINTMENT_DETAILS_REQUEST,
    PATIENT_APPOINTMENT_REQUEST,
} from "../../../ActionTypes/v1/patient/patientAppointment";
import {errorToaster, getLocalStorageItem, patientProfileData} from '../../../../utils/helper';

// Appointment List
function* patientAppointmentRequestSaga(payload: any): any {
    yield put(patientAppointmentCacheDataClearRequest())
    try {
        const params = [
            {key: "status", value: payload.payload.status},
            {key: "startTime", value: payload.payload.startTime},
            {key: "skip", value: payload.payload.skip},
            {key: "limit", value: payload.payload.limit},
            {key: "clinic_id", value: getLocalStorageItem('patientClinic')},
            {key: "profile_id", value: patientProfileData?.id}
            // TODO: for role wise filter
            // { key: 'position', value: payload.payload.position },
        ];
        if (payload.payload.position && payload.payload.position.length > 0) {
            params.push({key: "position", value: payload.payload.position});
        }
        if (payload.payload.patientID && payload.payload.patientID.length > 0) {
            params.push({key: "patientID", value: payload.payload.patientID});
        }
        if (payload.payload.endTime && payload.payload.endTime.length !== null) {
            params.push({key: "endTime", value: payload.payload.endTime});
        }

        let tmpUrl = `?`;
        params?.map((p: any) => {
            if (tmpUrl.length > 1) {
                if (p.value) {
                    tmpUrl = `${tmpUrl}&${p.key}=${p.value}`;
                }
            } else {
                if (p.value) {
                    tmpUrl = `${tmpUrl}${p.key}=${p.value}`;
                }
            }
            return true
        });
        yield put(patientAppointmentCacheDataClearRequest())

        const response = yield API.get(`/v2/appointments/all${tmpUrl === '?' ? '' : tmpUrl}`);

        if (payload.payload.callback) {
            yield call(payload.payload.callback, response.data);
        }
        yield put(
            patientAppointmentRequestSuccess({
                appointment_data: response?.data?.detail,
                appointment_meta: response?.data?.meta
            })
        );
    } catch (e: any) {
        errorToaster(e?.message)
        yield put(patientAppointmentCacheDataClearRequest())
        yield put(
            patientAppointmentRequestFailure({
                error: e?.message,
            })
        );
    }
}

// Appointment Details
function* patientAppointmentDetailsRequestSaga(payload: any): any {
    yield put(patientAppointmentDetailCacheDataClearRequest())

    try {
        if (payload.payload.appointment_id) {
            const response = yield API.get(
                `/v2/appointments/${payload.payload.appointment_id}`
            );

            if (payload?.payload?.callback) {
                yield call(payload?.payload?.callback, response?.data?.detail)
            }

            yield put(
                patientAppointmentDetailsRequestSuccess({
                    data: response?.data?.detail,
                })
            );
        }

    } catch (e: any) {
        //TODO: update toast according to status code
        //  errorToaster(e?.detail);
        yield put(patientAppointmentDetailCacheDataClearRequest())
        yield put(
            patientAppointmentDetailsRequestFailure({
                error: e?.detail,
            })
        );
    }
}

function* getAppointmentNotificationDentistRequestSaga(payload: any): any {
    try {
        const response = yield API.get(
            `/appointments/update/status?appointmentID=${payload.payload.appointment_id}`
        );
        yield call(payload.payload.callback, response);
        yield put(
            getAppointmentNotificationDentistRequestSuccess({
                data: response,
            })
        );
    } catch (e: any) {
        //TODO: update toast according to status code
        // errorToaster(e?.detail);
        yield put(
            getAppointmentNotificationDentistRequestFailure({
                error: e?.detail,
            })
        );
    }
}

function* patientAppointmentSaga() {
    yield takeEvery(PATIENT_APPOINTMENT_REQUEST, patientAppointmentRequestSaga);
}

function* patientAppointmentDetailsSaga() {
    yield takeEvery(
        PATIENT_APPOINTMENT_DETAILS_REQUEST,
        patientAppointmentDetailsRequestSaga
    );
}

function* getAppointmentNotificationDentistSaga() {
    yield takeEvery(
        GET_APPOINTMENT_NOTIFICATION_DENTIST_REQUEST,
        getAppointmentNotificationDentistRequestSaga
    );
}

export default function* AppointmentSaga() {
    yield all([
        patientAppointmentSaga(),
        patientAppointmentDetailsSaga(),
        getAppointmentNotificationDentistSaga(),
    ]);
}
