import {
  GET_RISK_PROFILE_REQUEST,
  GET_RISK_PROFILE_CACHE_DATA_CLEAR_REQUEST,
  GET_RISK_PROFILE_REQUEST_FAILURE,
  GET_RISK_PROFILE_REQUEST_SUCCESS,
  PUT_RISK_PROFILE_REQUEST,
  PUT_RISK_PROFILE_REQUEST_SUCCESS,
  PUT_RISK_PROFILE_REQUEST_FAILURE,
  POST_RISK_PROFILE_REQUEST,
  UPDATE_RISK_PROFILE_REQUEST,
  UPDATE_RISK_PROFILE_CACHE_DATA_CLEAR_REQUEST,
  GET_RISK_PROFILE_TREATMENT_REQUEST,
  GET_RISK_PROFILE_TREATMENT_CACHE_DATA_CLEAR_REQUEST,
  GET_RISK_PROFILE_TREATMENT_REQUEST_SUCCESS,
  GET_RISK_PROFILE_TREATMENT_REQUEST_FAILURE,
} from "../../../../ActionTypes/v1/patient/riskProfile";
import {
  GetRiskProfileRequest,
  GetRiskProfileRequestFailure,
  GetRiskProfileRequestFailurePayload,
  GetRiskProfileRequestPayload,
  GetRiskProfileRequestSuccess,
  GetRiskProfileRequestSuccessPayload,
  GetRiskProfileTreatmentRequest, GetRiskProfileTreatmentRequestFailure, GetRiskProfileTreatmentRequestFailurePayload,
  GetRiskProfileTreatmentRequestPayload, GetRiskProfileTreatmentRequestSuccess,
  GetRiskProfileTreatmentRequestSuccessPayload,
  PostRiskProfileRequest,
  PostRiskProfileRequestPayload,
  PutRiskProfileFailurePayload,
  PutRiskProfileRequest,
  PutRiskProfileRequestFailure,
  PutRiskProfileRequestPayload,
  PutRiskProfileRequestSuccess,
  PutRiskProfileSuccessPayload,
  UpdateRiskProfileRequest,
  UpdateRiskProfileRequestPayload,
} from "../../../../types/patientTypes";

//Risk Assessment

export const getRiskProfileRequest = (
  payload: GetRiskProfileRequestPayload
): GetRiskProfileRequest => ({
  type: GET_RISK_PROFILE_REQUEST,
  payload,
});
export const getRiskProfileCacheDataClearRequest = (): any => ({
  type: GET_RISK_PROFILE_CACHE_DATA_CLEAR_REQUEST
})

export const getRiskProfileRequestSuccess = (
  payload: GetRiskProfileRequestSuccessPayload
): GetRiskProfileRequestSuccess => ({
  type: GET_RISK_PROFILE_REQUEST_SUCCESS,
  payload,
});

export const getRiskProfileRequestFailure = (
  payload: GetRiskProfileRequestFailurePayload
): GetRiskProfileRequestFailure => ({
  type: GET_RISK_PROFILE_REQUEST_FAILURE,
  payload,
});

export const putRiskProfileRequest = (
  payload: PutRiskProfileRequestPayload
): PutRiskProfileRequest => ({
  type: PUT_RISK_PROFILE_REQUEST,
  payload,
});
export const putRiskProfileRequestSuccess = (
  payload: PutRiskProfileSuccessPayload
): PutRiskProfileRequestSuccess => ({
  type: PUT_RISK_PROFILE_REQUEST_SUCCESS,
  payload,
});
export const putRiskProfileRequestFailure = (
  payload: PutRiskProfileFailurePayload
): PutRiskProfileRequestFailure => ({
  type: PUT_RISK_PROFILE_REQUEST_FAILURE,
  payload,
});
export const postRiskProfileRequest = (
  payload: PostRiskProfileRequestPayload
): PostRiskProfileRequest => ({
  type: POST_RISK_PROFILE_REQUEST,
  payload,
});
export const updateRiskProfileRequest = (
  payload: UpdateRiskProfileRequestPayload
): UpdateRiskProfileRequest => ({
  type: UPDATE_RISK_PROFILE_REQUEST,
  payload,
});
export const updateRiskProfileCacheDataClearRequest = (): any => ({
  type: UPDATE_RISK_PROFILE_CACHE_DATA_CLEAR_REQUEST
})


export const getRiskProfileTreatmentRequestAction = (
  payload: GetRiskProfileTreatmentRequestPayload
): GetRiskProfileTreatmentRequest => ({
  type: GET_RISK_PROFILE_TREATMENT_REQUEST,
  payload,
});

export const getRiskProfileTreatmentCacheDataClearAction = ():any => ({
  type: GET_RISK_PROFILE_TREATMENT_CACHE_DATA_CLEAR_REQUEST
})

export const getRiskProfileTreatmentRequestSuccess = (
  payload: GetRiskProfileTreatmentRequestSuccessPayload
): GetRiskProfileTreatmentRequestSuccess => ({
  type: GET_RISK_PROFILE_TREATMENT_REQUEST_SUCCESS,
  payload,
});

export const getRiskProfileTreatmentRequestFailure = (
  payload: GetRiskProfileTreatmentRequestFailurePayload
): GetRiskProfileTreatmentRequestFailure => ({
  type: GET_RISK_PROFILE_TREATMENT_REQUEST_FAILURE,
  payload,
});
