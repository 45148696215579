export const POST_CLINICAL_EXAM_ASSISTANT_DATA_REQUEST =
  "POST_CLINICAL_EXAM_ASSISTANT_DATA_REQUEST";

export const POST_ADD_MULTIPLE_FINDING = "POST_ADD_MULTIPLE_FINDING";
export const POST_DELETE_MULTIPLE_FINDING = "POST_DELETE_MULTIPLE_FINDING";

export const GET_CLINICAL_EXAM_ASSISTANT_REQUEST =
  "GET_CLINICAL_EXAM_ASSISTANT_REQUEST";
export const GET_CLINICAL_EXAM_ASSISTANT_REQUEST_SUCCESS =
  "GET_CLINICAL_EXAM_ASSISTANT_REQUEST_SUCCESS";
export const GET_CLINICAL_EXAM_ASSISTANT_REQUEST_FAILURE =
  "GET_CLINICAL_EXAM_ASSISTANT_REQUEST_FAILURE";

export const GET_DISC_PROFILE_REQUEST = "GET_DISC_PROFILE_REQUEST";
export const GET_DISC_PROFILE_REQUEST_SUCCESS =
  "GET_DISC_PROFILE_REQUEST_SUCCESS";
export const GET_DISC_PROFILE_REQUEST_FAILURE =
  "GET_DISC_PROFILE_REQUEST_FAILURE";

export const POST_IMAGE_UPLOAD_MULTIPLE_REQUEST =
  "POST_IMAGE_UPLOAD_MULTIPLE_REQUEST";
export const POST_IMAGE_UPLOAD_MULTIPLE_REQUEST_SUCCESS =
  "POST_IMAGE_UPLOAD_MULTIPLE_REQUEST_SUCCESS";
export const POST_IMAGE_UPLOAD_MULTIPLE_REQUEST_FAILURE =
  "POST_IMAGE_UPLOAD_MULTIPLE_REQUEST_FAILURE";

export const PUT_CLINICAL_EXAM_ASSISTANT_REQUEST =
  "PUT_CLINICAL_EXAM_ASSISTANT_REQUEST";
export const PUT_CLINICAL_EXAM_ASSISTANT_REQUEST_SUCCESS =
  "PUT_CLINICAL_EXAM_ASSISTANT_REQUEST_SUCCESS";
export const PUT_CLINICAL_EXAM_ASSISTANT_REQUEST_FAILURE =
  "PUT_CLINICAL_EXAM_ASSISTANT_REQUEST_FAILURE";

// for get data (clinical exam existing treatment)
export const UPDATE_ASSISTANT_EXISTING_TREATMENT =
  "UPDATE_ASSISTANT_EXISTING_TREATMENT";
export const UPDATE_ASSISTANT_EXISTING_TREATMENT_FAILURE =
  "UPDATE_ASSISTANT_EXISTING_TREATMENT_FAILURE";
export const ADD_ASSISTANT_EXISTING_TREATMENT_FINDING_ROW =
  "ADD_ASSISTANT_EXISTING_TREATMENT_FINDING_ROW";
export const CHANGE_ASSISTANT_EXISTING_TREATMENT_FINDING =
  "CHANGE_ASSISTANT_EXISTING_TREATMENT_FINDING";
export const DELETE_ASSISTANT_EXISTING_TREATMENT_FINDING_ROW =
  "DELETE_ASSISTANT_EXISTING_TREATMENT_FINDING_ROW";

// for get data (clinical exam occlusion)
export const UPDATE_ASSISTANT_OCCLUSION = "UPDATE_ASSISTANT_OCCLUSION";
export const UPDATE_ASSISTANT_OCCLUSION_FAILURE =
  "UPDATE_ASSISTANT_OCCLUSION_FAILURE";
export const ADD_ASSISTANT_OCCLUSION_FINDING_ROW =
  "ADD_ASSISTANT_OCCLUSION_FINDING_ROW";
export const CHANGE_ASSISTANT_OCCLUSION_FINDING =
  "CHANGE_ASSISTANT_OCCLUSION_FINDING";
export const DELETE_ASSISTANT_OCCLUSION_FINDING_ROW =
  "DELETE_ASSISTANT_OCCLUSION_FINDING_ROW";

// for get data (clinical exam intar oral)
export const UPDATE_ASSISTANT_INTRAORAL = "UPDATE_ASSISTANT_INTRAORAL";
export const UPDATE_ASSISTANT_INTRAORAL_FAILURE =
  "UPDATE_ASSISTANT_INTRAORAL_FAILURE";
export const CHANGE_ASSISTANT_INTRAORAL_FINDING =
  "CHANGE_ASSISTANT_INTRAORAL_FINDING";
export const ADD_ASSISTANT_INTRAORAL_FINDING_ROW =
  "ADD_ASSISTANT_INTRAORAL_FINDING_ROW";

// for get data (appointment info clinical exam)
export const UPDATE_APPOINTMENT_INFO = "UPDATE_APPOINTMENT_INFO";
export const UPDATE_APPOINTMENT_INFO_FAILURE =
  "UPDATE_APPOINTMENT_INFO_FAILURE";
export const CHANGE_APPOINTMENT_INFO = "CHANGE_APPOINTMENT_INFO";
// for get data (pre-clinical exam in clinical exam)
export const UPDATE_PRE_CLINICAL_INFO = "UPDATE_PRE_CLINICAL_INFO";
export const UPDATE_PRE_CLINICAL_INFO_FAILURE =
  "UPDATE_PRE_CLINICAL_INFO_FAILURE";
export const CHANGE_PRE_CLINICAL_INFO = "CHANGE_PRE_CLINICAL_INFO";
// for get extra oral clinical-exam
export const UPDATE_EXTRA_ORAL = "UPDATE_EXTRA_ORAL";
export const UPDATE_EXTRA_ORAL_FAILURE = "UPDATE_EXTRA_ORAL_FAILURE";
export const CHANGE_EXTRA_ORAL = "CHANGE_EXTRA_ORAL";
