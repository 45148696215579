import {  Grid,InputAdornment} from '@mui/material'
import React from 'react'
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import theme from '../../../../../../theme'
import TextBox from '../../../../common/TextBox'
import {RootState} from "../../../../../../app/reducers/v2/rootReducer";
import {
    changeWellnessComplaintsRequestAction,
    changeWellnessPatientPreferencesRequestAction,
    changeWellnessSocialNotesRequestAction, getWellnessProfileRequestAction, updateDentistWellnessProfileRequest
} from "../../../../../../app/actions/v2/dentist/wellnessProfile/wellnessProfileFiltersAction";
import {dentistProfileData, getLocalStorageItem} from "../../../../../../utils/helper";
import PopupComponent from "../../../../common/PopupComponent";

type Props = {
    open: boolean
    handleClose: any
    tabValue: number
}

const AddPatientPrefAndSocialNotesPopUp = (props: Props) => {
    const {open, handleClose, tabValue} = props
    const dispatch = useDispatch()
    const takeFromUrl = useParams();
    const {
        wellnessProfileFilters: {
            wellnessComplaints,
            wellnessPatientPreferences,
            wellnessSocialNotes,
            wellnessProfileData
        },
    } = useSelector((state: RootState) => state);
    const handleChangeComplaint = (event: any) => {
        const {name, value} = event.target;
        let tempWellnessComplaints = wellnessComplaints
        tempWellnessComplaints[name] = value;
        dispatch(changeWellnessComplaintsRequestAction(tempWellnessComplaints));
    }
    const handleChangePreferences = (event: any) => {
        const {name, value} = event.target;
        let tempWellnessPatientPreferences = wellnessPatientPreferences
        tempWellnessPatientPreferences[name] = value;
        dispatch(changeWellnessPatientPreferencesRequestAction(tempWellnessPatientPreferences));
    }
    const handleChangeSocial = (event: any) => {
        const {name, value} = event.target;
        let tempWellnessSocialNotes = wellnessSocialNotes
        tempWellnessSocialNotes[name] = value;
        dispatch(changeWellnessSocialNotesRequestAction(tempWellnessSocialNotes));
    }
    const saveWellnessData = () => {
        dispatch(updateDentistWellnessProfileRequest({
            patient_id: takeFromUrl?.patientId,
            profile_id: dentistProfileData?.id,
            complaint: {
                chief_complaint: wellnessComplaints?.chief_complaint,
            },
            social_note: wellnessSocialNotes?.social_note,
            patient_preferences: wellnessPatientPreferences?.patient_preferences,
            callback: () => {
                const clinicID = getLocalStorageItem("clinic") as string
                dispatch(getWellnessProfileRequestAction({patient_id: takeFromUrl.patientId, clinic_id: clinicID}))
                handleClose()
            }
        }))
    }

    return (
        <React.Fragment>
            <PopupComponent width={'630px'} saveBtnText={'Save'} cancelBtnText={'Cancel'} headerAction={false} footerAction={true} open={open} handleClose={handleClose} handleSave={saveWellnessData}>
                <Grid container>
                    {/*chiefComplaint*/}
                    {tabValue === 1 && <Grid item xs={12} pt={"12px"}>
                        <TextBox
                            labelSx={{color: theme.palette.common.black50}}
                            label={"Chief Concerns"}
                            labelProps={{
                                htmlFor: "chief_complaint",
                            }}
                            isPadding
                            inputProps={{
                                id: "chief_complaint",
                                name: "chief_complaint",
                                onChange: (e: any) => handleChangeComplaint(e),
                                value: wellnessComplaints?.chief_complaint,
                                error: false,
                                helperText: '',
                                fullWidth: true,
                            }}
                        />
                    </Grid>}
                    {/*patientPreferences*/}
                    {tabValue === 2 && <Grid item xs={12} pt={"12px"}>
                        <TextBox
                            labelSx={{color: theme.palette.common.black50}}
                            label={"Patient Preferences"}
                            labelProps={{
                                htmlFor: "patient_preferences",
                            }}
                            maxLength={255}
                            resize={true}
                            resizeVerticalScreen={true}
                            inputProps={{
                                id: "patient_preferences",
                                name: "patient_preferences",
                                onChange: (e: any) => handleChangePreferences(e),
                                value: wellnessPatientPreferences?.patient_preferences,
                                error: false,
                                helperText: "",
                                fullWidth: true,
                                InputProps: {
                                    classes: {
                                        root: "border-radius-8 textFieldEndIconPadding textFieldStartIconPadding bg-white",
                                        input: "textFieldStartEndIconNumbers",
                                    },
                                    endAdornment: (
                                        <InputAdornment
                                            position="end"
                                            sx={{
                                                alignSelf: 'flex-end',
                                                paddingBottom: '10px',
                                                alignItems: 'flex-start'
                                            }}
                                        >
                                            {`${wellnessPatientPreferences?.patient_preferences?.length || 0} / 255`}
                                        </InputAdornment>
                                    )
                                },
                                multiline: true,
                            }}
                        />
                    </Grid>}
                    {/*socialNotes*/}
                    {tabValue === 3 && <Grid item xs={12} pt={"12px"}>
                        <TextBox
                            labelSx={{color: theme.palette.common.black50}}
                            label={"Social Notes"}
                            labelProps={{
                                htmlFor: "social_note",
                            }}
                            maxLength={255}
                            resize={true}
                            resizeVerticalScreen={true}
                            inputProps={{
                                id: "social_note",
                                name: "social_note",
                                onChange: (e: any) => handleChangeSocial(e),
                                value: wellnessSocialNotes?.social_note,
                                error: false,
                                helperText: "",
                                fullWidth: true,
                                InputProps: {
                                    classes: {
                                        root: "border-radius-8 textFieldEndIconPadding textFieldStartIconPadding bg-white",
                                        input: "textFieldStartEndIconNumbers",
                                    },
                                    endAdornment: (
                                        <InputAdornment
                                            position="end"
                                            sx={{
                                                alignSelf: 'flex-end',
                                                paddingBottom: '10px',
                                                alignItems: 'flex-start'
                                            }}
                                        >
                                            {`${wellnessSocialNotes?.social_note?.length || 0} / 255`}
                                        </InputAdornment>
                                    )
                                },
                                multiline: true,
                            }}
                        />
                    </Grid>}
                </Grid>
            </PopupComponent>
        </React.Fragment>
    )
}

export default AddPatientPrefAndSocialNotesPopUp