import React, { useMemo } from 'react'
import { useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';
import NoDataFound from '../../../../v1/Common/NoDataFound';
import CardComponent from '../../../common/CardComponent'
import theme from '../../../../../theme';
import ImageXraySlider from '../../../common/ImageXraySlider';
import { RootState } from "../../../../../app/reducers/v2/rootReducer";


type Props = {
    setValue: React.Dispatch<React.SetStateAction<number>>
}
const PatientWellnessXrays = (props: Props) => {
    const { setValue } = props
    const location = useLocation();
    const {
        wellnessProfileFilters: { wellnessProfileData },
        patientWellnessProfileWithoutAccount: { withoutAccountGetWellnessProfileData },
    } = useSelector((state: RootState) => state);

    const withoutAccountWellnessProfile: boolean = useMemo(() => {
        return location?.pathname?.includes('/patient/without-account/wellness-profile/')
    }, [location.pathname]);

    const wellnessData = withoutAccountWellnessProfile ? withoutAccountGetWellnessProfileData : wellnessProfileData;

    const viewAll = () => {
        setValue(3)
    }

    return (
        <CardComponent
            bgColor={theme.palette.secondary.lightShade}
            color={theme.palette.secondary.main}
            title='Xrays'
            fromImageXray
            rightText='View all'
            btnClick={viewAll}>
            <React.Fragment>
                {wellnessData && wellnessData?.xrays?.length > 0 ?
                    <ImageXraySlider isPhoto={false} isPatient data={wellnessData?.xrays} />
                    :
                    <NoDataFound errorMessage={"xrays"} />
                }
            </React.Fragment>
        </CardComponent>
    )
}

export default PatientWellnessXrays