import {
  PUT_INSIGHTS_RECOMMENDATIONS_REQUEST,
  PUT_INSIGHTS_RECOMMENDATIONS_SUCCESS,
  PUT_INSIGHTS_RECOMMENDATIONS_FAILURE,
  GET_INSIGHTS_RECOMMENDATIONS_REQUEST,
  GET_INSIGHTS_RECOMMENDATIONS_CACHE_DATA_CLEAR_REQUEST,
  GET_INSIGHTS_RECOMMENDATIONS_REQUEST_SUCCESS,
  GET_INSIGHTS_RECOMMENDATIONS_REQUEST_FAILURE,
} from "../../../../../ActionTypes/v1/dentist/insightAndRecommendation";
import {
  InsightRecommendationInterface,
  insightRecommendationActions,
} from "../../../../../types/dentistTypes";

const initialState: InsightRecommendationInterface = {
  putInsightRecommendationData: null,
  PutInsightRecommendationLoader: false,
  putInsightRecommendationError: "",

  getInsightRecommendationData: null,
  getInsightRecommendationLoader: false,
  getInsightRecommendationError: "",
};

const insightRecommendationReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case PUT_INSIGHTS_RECOMMENDATIONS_REQUEST:
      return {
        ...state,
        PutInsightRecommendationLoader: true,
      };
    case PUT_INSIGHTS_RECOMMENDATIONS_SUCCESS:
      return {
        ...state,
        PutInsightRecommendationLoader: false,
        putInsightRecommendationData: action.payload,
        putInsightRecommendationError: null,
      };
    case PUT_INSIGHTS_RECOMMENDATIONS_FAILURE:
      return {
        ...state,
        PutInsightRecommendationLoader: false,
        putInsightRecommendationData: null,
        putInsightRecommendationError: action.payload,
      };
    case GET_INSIGHTS_RECOMMENDATIONS_REQUEST:
      return {
        ...state,
        getInsightRecommendationLoader: true,
        getInsightRecommendationData: null,
        getInsightRecommendationError: null,
      };
    case GET_INSIGHTS_RECOMMENDATIONS_CACHE_DATA_CLEAR_REQUEST:
      return {
        ...state,
        getInsightRecommendationData: null,

      }
    case GET_INSIGHTS_RECOMMENDATIONS_REQUEST_SUCCESS:
      return {
        ...state,
        getInsightRecommendationLoader: false,
        getInsightRecommendationData: action.payload,
        getInsightRecommendationError: null,
      };
    case GET_INSIGHTS_RECOMMENDATIONS_REQUEST_FAILURE:
      return {
        ...state,
        getInsightRecommendationLoader: false,
        getInsightRecommendationData: null,
        getInsightRecommendationError: action.payload?.getInsightRecommendationError,
      };
    default:
      return {
        ...state,
      };
  }
};

export default insightRecommendationReducer;
