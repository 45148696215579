import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box } from "@mui/system";
import { Button, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import theme from "../../../../theme";
import { exceptThisSymbols, formatPhoneNumber } from "../../../../utils/helper";
import { otpValidator } from "../../../../validation";
import PopupComponent from '../../common/PopupComponent';
import TextBox from '../../common/TextBox';
import { RootState } from '../../../../app/reducers/v2/rootReducer';
import {
    patientEmailMobileDataRequest,
    patientEmailMobileErrorRequest,
    patientEmailSendOtpRequest,
    patientEmailVerifyOtpRequest
} from '../../../../app/actions/v2/patient/emailAndQrCodeFormAction';
import { patientRegisterFormDataRequest } from '../../../../app/actions/v2/patient/patientRegisterAction';
import FullScreenLoader from '../../../v1/Common/FullScreenLoader';

type Props = {
    open: boolean
    setOpen: any
    clinicId: string,
    mobile_number: string,
}
const OtpVerifyPopUp = (props: Props) => {
    const { open, setOpen, clinicId, mobile_number } = props;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [minutes, setMinutes] = useState(2);
    const [seconds, setSeconds] = useState(0);

    const { emailMobileData, emailMobileError, otpLoader } = useSelector((state: RootState) => state.emailAndQrCodeForm)

    useEffect(() => {
        dispatch(
            patientEmailSendOtpRequest({
                clinic_id: clinicId,
                mobile_number: mobile_number,
                callback: () => {
                    if (!otpLoader) {
                        setMinutes(2)
                        setSeconds(0)
                    }
                },
            })
        );
    }, [open]);

    useEffect(() => {
        let otpInterval: any;

        if (minutes > 0 || seconds > 0) {
            otpInterval = setInterval(() => {
                if (seconds === 0) {
                    if (minutes > 0) {
                        setMinutes((prevMinutes) => prevMinutes - 1);
                        setSeconds(59);
                    }
                } else {
                    setSeconds((prevSeconds) => prevSeconds - 1);
                }
            }, 1000);
        }

        return () => {
            clearInterval(otpInterval);
        };
    }, [minutes, seconds]);

    const handleChange = (e: any) => {
        const { value, name } = e.target as any;

        const updatedFormData = emailMobileData
        updatedFormData[name] = value
        dispatch(patientEmailMobileDataRequest(updatedFormData))

        if (emailMobileError?.[name]) {
            dispatch(
                patientEmailMobileErrorRequest({
                    ...emailMobileError,
                    [name]: '',
                })
            )
        }
    }

    const resendOtp = () => {
        dispatch(
            patientEmailSendOtpRequest({
                clinic_id: clinicId,
                mobile_number: mobile_number,
                callback: () => {
                    if (!otpLoader) {
                        setMinutes(2)
                        setSeconds(0)
                    }
                },
            })
        );
    };

    const submitHandler = (e: any) => {
        e.preventDefault();
        let { errors, isValid } = otpValidator(emailMobileData);

        if (isValid) {
            const clearDataPayload = {
                otp: ''
            } as any;

            dispatch(patientEmailMobileDataRequest(clearDataPayload));

            const clearFormDataPayload = {
                userName: '',
                password: '',
                confirmPassword: '',
                email: '',
                mobile: '',
            } as any;

            const payload = {
                otp: emailMobileData?.otp?.trim(),
                mobile_number: mobile_number,
                clinic_id: clinicId,
                callback: (data: any) => {
                    dispatch(patientRegisterFormDataRequest(clearFormDataPayload));
                    navigate(`/patient/login`);
                }
            } as any;

            dispatch(patientEmailVerifyOtpRequest(payload));

        } else {
            const errorPayload = errors as any
            dispatch(patientEmailMobileErrorRequest(errorPayload))
        }
    };

    return (
        <PopupComponent
            heading={"Mobile Verification"}
            width={'530px'}
            open={open}
            saveBtnText={'Verify'}
            handleClose={() => setOpen(false)}
            cancelBtnText={'Cancel'}
            handleSave={submitHandler}
            isPatient notDisplayCancelIcon
            footerAction headerAction isOraqIcon
        >
            {otpLoader && <FullScreenLoader />}

            <Box mt={1}>
                <Typography className={'fw-regular f-14'} color={theme.palette.common.black50}>OTP send on your
                    registered mobile number, Please verify it.</Typography>
                <Box my={2} display={"flex"} justifyContent={"center"}>
                    {mobile_number &&
                        <Typography className={'fw-regular f-14'} color={theme.palette.common.black50}>
                            +1 {formatPhoneNumber(mobile_number)}
                        </Typography>}
                </Box>
                <TextBox
                    isPatient
                    label={'OTP'}
                    required
                    isPadding
                    inputProps={{
                        name: "otp",
                        onWheel: (e: any) => e.target.blur(),
                        onKeyDown: (e: any) => exceptThisSymbols.includes(e.key) && e.preventDefault(),
                        onChange: (e: any) => handleChange(e),
                        value: emailMobileData?.otp,
                        error: emailMobileError?.otp,
                        helperText: emailMobileError?.otp,
                        fullWidth: true,
                    }}
                />
                {!otpLoader &&
                    <Box
                        display={"flex"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                    >
                        <Typography className={'fw-regular'} color={"secondary"}>
                            {(minutes || seconds) <= 0 ? "" : `${minutes <= 9 ? `0${minutes}` : minutes}:${seconds <= 9 ? `0${seconds}` : seconds}`}
                        </Typography>

                        <Button
                            variant={"text"}
                            sx={{
                                color: `${theme.palette.secondary.main}`,
                                opacity: (minutes || seconds) > 0 ? 0.5 : 1,
                                "&:hover": { backgroundColor: 'transparent' }
                            }}
                            className={'fw-regular'}
                            onClick={() => resendOtp()}
                            disabled={(minutes || seconds) > 0}
                        >
                            Resend OTP
                        </Button>
                    </Box>
                }
            </Box>
        </PopupComponent>
    )
}

export default OtpVerifyPopUp;