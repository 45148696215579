import {
    STAFF_PROFILE_REQUEST, STAFF_PROFILE_CACHE_DATA_CLEAR_REQUEST,
    STAFF_PROFILE_REQUEST_SUCCESS,
    STAFF_PROFILE_REQUEST_FAILURE,
    STAFF_PROFILE_EDIT_REQUEST,
    STAFF_PROFILE_EDIT_REQUEST_FAILURE,
    STAFF_PROFILE_EDIT_REQUEST_SUCCESS
} from '../../../../ActionTypes/v1/dentist/dentistProfile'

import {
    StaffProfileRequest,
    StaffProfileRequestSuccess,
    StaffProfileRequestFailure,
    StaffProfileRequestPayload,
    StaffProfileRequestSuccessPayload,
    StaffProfileRequestFailurePayload,
    StaffProfileEditRequest,
    StaffProfileEditRequestFailure,
    staffProfileEditRequestPayload,
    StaffProfileEditRequestFailurePayload,
    StaffProfileEditRequestSuccess,
    StaffProfileEditRequestSuccessPayload

} from '../../../../types/dentistTypes'

//dentist Profile Data
export const staffProfileRequest = (payload: StaffProfileRequestPayload): StaffProfileRequest => ({
    type: STAFF_PROFILE_REQUEST,
    payload,
});

export const staffProfileCacheDataClearRequest = (): any => ({
    type: STAFF_PROFILE_CACHE_DATA_CLEAR_REQUEST
})

export const staffProfileRequestSuccess = (
    payload: StaffProfileRequestSuccessPayload
): StaffProfileRequestSuccess => ({
    type: STAFF_PROFILE_REQUEST_SUCCESS,
    payload
});

export const staffProfileRequestFailure = (
    payload: StaffProfileRequestFailurePayload
): StaffProfileRequestFailure => ({
    type: STAFF_PROFILE_REQUEST_FAILURE,
    payload
});

//Edit Staff Profile

export const staffProfileEditRequest = (payload: staffProfileEditRequestPayload): StaffProfileEditRequest => ({
    type: STAFF_PROFILE_EDIT_REQUEST,
    payload,
});
export const staffProfileEditRequestFailure = (
    payload: StaffProfileEditRequestFailurePayload
): StaffProfileEditRequestFailure => ({
    type: STAFF_PROFILE_EDIT_REQUEST_FAILURE,
    payload
});


export const staffProfileEditRequestSuccess = (
    payload: StaffProfileEditRequestSuccessPayload
): StaffProfileEditRequestSuccess => ({
    type: STAFF_PROFILE_EDIT_REQUEST_SUCCESS,
    payload
});



