import { all, call, put, takeEvery } from "redux-saga/effects";
import API from "../../../../../utils/api";
import {
  clinicalExamDentistDataRequestFailure,
  clinicalExamDentistDataRequestSuccess,
  getClinicalExamDentistDataRequestFailure,
  getClinicallExamDentistDataRequestSuccess,
} from "../../../../actions/v1/dentist/clinicExamDentistActions/clinicalExamDentistAction";
import {
  PUT_CLINICAL_EXAM_DENTIST_REQUEST,
  GET_CLINICAL_EXAM_DENTIST_REQUEST,
} from "../../../../ActionTypes/v1/dentist/clinicalExamDentist";
import { updateExistingTreatmentDentistRequestFailure } from "../../../../actions/v1/dentist/clinicExamDentistActions/existingTreatmentDentistActions";
import { updateExtraOralRequestFailure } from "../../../../actions/v1/dentist/clinicExamDentistActions/extrtaOralDentistAction";
import { updateIntraOralDentistRequestFailure } from "../../../../actions/v1/dentist/clinicExamDentistActions/intraOralDentistAction";
import { updateMedicalHistoryRequestFailure } from "../../../../actions/v1/dentist/clinicExamDentistActions/medicalHistoryDentistAction";
import { updateMuscleRequestFailure } from "../../../../actions/v1/dentist/clinicExamDentistActions/muscleDentistAction";
import { updateOcclusionDentistRequestFailure } from "../../../../actions/v1/dentist/clinicExamDentistActions/occlusionDentistAction";
import { updatePeriodontalRequestFailure } from "../../../../actions/v1/dentist/clinicExamDentistActions/periodontalDentistActions";
import { updateTmjRequestFailure } from "../../../../actions/v1/dentist/clinicExamDentistActions/tmjDentistActions";
import { updateWearRequestFailure } from "../../../../actions/v1/dentist/clinicExamDentistActions/wearDentistAction";
import { errorToaster, successToaster } from '../../../../../utils/helper';

function* clinicalExamDentistRequestSaga(payload: any): any {

  try {
    const response = yield API.put(
      "/exam/dentist/create",
      payload.payload.dentistExam
    );
    yield call(payload.payload.callback, response.data);
    yield put(
      clinicalExamDentistDataRequestSuccess({
        dentistExam: response.data,
      })
    );
    successToaster("dentist exam has been successfully submitted.")
    if (payload.payload.callback) {
      payload.payload.callback();
    }
  } catch (e: any) {
    errorToaster(e?.detail)
    yield put(
      clinicalExamDentistDataRequestFailure({
        error: e?.detail,
      })
    );
  }
}

function* getClinicalExamDentistRequestSaga(payload: any): any {
  try {
    yield put(updateExistingTreatmentDentistRequestFailure())
    yield put(updateExtraOralRequestFailure())
    yield put(updateIntraOralDentistRequestFailure())
    yield put(updateMedicalHistoryRequestFailure())
    yield put(updateMuscleRequestFailure())
    yield put(updateOcclusionDentistRequestFailure())
    yield put(updatePeriodontalRequestFailure())
    yield put(updateTmjRequestFailure())
    yield put(updateWearRequestFailure())
    const response = yield API.get(
      `/exam/dentist/${payload?.payload?.appointment_id}?appt_type=${payload?.payload?.appt_type}`
    );
    yield put(
      getClinicallExamDentistDataRequestSuccess({
        dentistExam: response?.data,
      })
    );
    if (payload?.payload?.callback) {
      yield call(payload?.payload?.callback, response?.data);
    }
  } catch (e: any) {
    //TODO: update toast according to status code
    //  errorToaster(e?.detail);
    yield put(updateExistingTreatmentDentistRequestFailure())
    yield put(updateExtraOralRequestFailure())
    yield put(updateIntraOralDentistRequestFailure())
    yield put(updateMedicalHistoryRequestFailure())
    yield put(updateMuscleRequestFailure())
    yield put(updateOcclusionDentistRequestFailure())
    yield put(updatePeriodontalRequestFailure())
    yield put(updateTmjRequestFailure())
    yield put(updateWearRequestFailure())
    yield put(
      getClinicalExamDentistDataRequestFailure({
        error: e?.detail,
      })
    );
  }
}

function* clinicalExamDentistSaga() {
  yield takeEvery(
    PUT_CLINICAL_EXAM_DENTIST_REQUEST,
    clinicalExamDentistRequestSaga
  );
}
function* getClinicalExamDentistSaga() {
  yield takeEvery(
    GET_CLINICAL_EXAM_DENTIST_REQUEST,
    getClinicalExamDentistRequestSaga
  );
}

export default function* rootSaga() {
  yield all([clinicalExamDentistSaga(), getClinicalExamDentistSaga()]);
}
