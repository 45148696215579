import {
    UPDATE_OCCLUSION_DENTIST, UPDATE_OCCLUSION_DENTIST_FAILURE,
    CHANGE_OCCLUSION_DENTIST,
} from "../../../../ActionTypes/v1/dentist/clinicalExamDentist";
import { occlusionDentistInterface } from "../../../../types/dentistTypes";

export const updateOcclusionDentistRequest = (
    payload: occlusionDentistInterface
): occlusionDentistInterface => ({
    type: UPDATE_OCCLUSION_DENTIST,
    payload,
}) as any;

export const updateOcclusionDentistRequestFailure = ():any => ({
    type: UPDATE_OCCLUSION_DENTIST_FAILURE,
}) as any;


export const changeOcclusionFindingAction = (payload: any): any => ({
    type: CHANGE_OCCLUSION_DENTIST,
    payload
}) as any;
