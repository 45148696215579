import {
  GET_TAG_XRAY_ALL_REQUEST,
  GET_TAG_XRAY_ALL_REQUEST_SUCCESS,
  GET_TAG_XRAY_ALL_REQUEST_FAILURE,
  GET_XRAY_APPOINTMENT_REQUEST,
  GET_XRAY_APPOINTMENT_CACHE_DATA_CLEAR_REQUEST,
  GET_XRAY_APPOINTMENT_REQUEST_SUCCESS,
  GET_XRAY_APPOINTMENT_REQUEST_FAILURE,
  DELETE_XRAY_APPOINTMENT_REQUEST,
  DELETE_XRAY_APPOINTMENT_REQUEST_SUCCESS,
  DELETE_XRAY_APPOINTMENT_REQUEST_FAILURE,
  CREATE_TAG_REQUEST,
  CREATE_TAG_REQUEST_FAILURE,
  DELETE_TAG_REQUEST,
  DELETE_TAG_REQUEST_SUCCESS,
  DELETE_TAG_REQUEST_FAILURE,
  GET_TAG_DATA_REQUEST,
  GET_TAG_DATA_REQUEST_SUCCESS,
  GET_TAG_DATA_REQUEST_FAILURE,
  UPDATE_TAG_DATA_REQUEST,
  UPDATE_TAG_DATA_REQUEST_FAILURE,
  UPDATE_TAG_DATA_REQUEST_SUCCESS,
  PUT_CONTRAST_REQUEST,
  PUT_CONTRAST_REQUEST_SUCCESS,
  PUT_CONTRAST_REQUEST_FAILURE,
  PUT_CROPPED_IMAGE_REQUEST,
  PUT_CROPPED_IMAGE_REQUEST_SUCCESS,
  PUT_CROPPED_IMAGE_REQUEST_FAILURE,
  UPDATE_CONTRAST,
} from "../../../../ActionTypes/v1/dentist/clinicalExamDentist";
import {
  getRadiographicAnalysisAndFinding,
  RadioGraphicAnalysisAndFindingAction,
} from "../../../../types/dentistTypes";

const initialState: getRadiographicAnalysisAndFinding = {
  pending: false,
  data: null,
  xrayData: null,
  deleteImageXrayLoader: false,
  contrastVal: null,
  tagData: null,
  tag: null,
  error: null,
  image_id: null,
  xray_id: null,
  tag_id: null,
  updateTagLoader: null,
  createTagLoader: null,
  getTagLoader: null,
  contrastLoader: false,
  contrast: null,
  crop_image_loader: false,
  croppedImage: null,
  tags_count: null,
  crop_height: null,
  crop_width: null,
  x_coordinate: null,
  y_coordinate: null,
};
const getRadiographicAnalysisAndFindingReducer = (
  state = initialState,
  action: RadioGraphicAnalysisAndFindingAction
) => {
  switch (action.type) {
    case GET_TAG_XRAY_ALL_REQUEST:
      return {
        ...state,
        getTagLoader: true,
      };
    case GET_TAG_XRAY_ALL_REQUEST_SUCCESS:
      return {
        ...state,
        getTagLoader: false,
        tagData: action.payload.tagData,
        error: null,
      };
    case GET_TAG_XRAY_ALL_REQUEST_FAILURE:
      return {
        ...state,
        getTagLoader: false,
        tagData: null,
        error: action.payload.error,
      };

    case GET_XRAY_APPOINTMENT_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case GET_XRAY_APPOINTMENT_CACHE_DATA_CLEAR_REQUEST:
      return {
        ...state,
        data: null
      }
    case GET_XRAY_APPOINTMENT_REQUEST_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.payload.data,
        error: null,
      };
    case GET_XRAY_APPOINTMENT_REQUEST_FAILURE:
      return {
        ...state,
        pending: false,
        data: null,
        error: action.payload.error,
      };
    case UPDATE_CONTRAST:
      let updatedData = state.data;
      updatedData = updatedData?.map((res: any) => {
        if (res.xray_id === action.payload?.xrayData.xray_id) {
          return { ...res, contrast: action.payload?.contrastVal };
        }
        return res;
      });
      return {
        ...state,
        pending: false,
        data: updatedData,
        error: null,
      };
    case DELETE_XRAY_APPOINTMENT_REQUEST:
      return {
        ...state,
        deleteImageXrayLoader: true,
      };
    case DELETE_XRAY_APPOINTMENT_REQUEST_SUCCESS:
      return {
        ...state,
        deleteImageXrayLoader: false,
        error: null,
      };
    case DELETE_XRAY_APPOINTMENT_REQUEST_FAILURE:
      return {
        ...state,
        deleteImageXrayLoader: false,
        data: null,
        error: action.payload.error,
      };
    case GET_TAG_DATA_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case GET_TAG_DATA_REQUEST_SUCCESS:
      return {
        ...state,
        pending: false,
        tag: action.payload,
        error: null,
      };
    case GET_TAG_DATA_REQUEST_FAILURE:
      return {
        ...state,
        pending: false,
        tag: null,
        error: action.payload.error,
      };
    case CREATE_TAG_REQUEST:
      return {
        ...state,
        createTagLoader: true,
      };
    case CREATE_TAG_REQUEST_FAILURE:
      return {
        ...state,
        createTagLoader: false,
        tag: null,
        error: action.payload.error,
      };
    case UPDATE_TAG_DATA_REQUEST:
      return {
        ...state,
        updateTagLoader: true,
        tags: action.payload.data,
      };
    case UPDATE_TAG_DATA_REQUEST_SUCCESS:
      return {
        ...state,
        updateTagLoader: false,
      };
    case UPDATE_TAG_DATA_REQUEST_FAILURE:
      return {
        ...state,
        updateTagLoader: false,
        tag: null,
        error: action.payload.error,
      };
    case DELETE_TAG_REQUEST:
      return {
        ...state,
        // pending: true
      };
    case DELETE_TAG_REQUEST_SUCCESS:
      return {
        ...state,
        // tag: action.payload.tag,
        // pending: false,
      };
    case DELETE_TAG_REQUEST_FAILURE:
      return {
        ...state,
        // pending: false,
        error: action.payload.error,
      };
    case PUT_CONTRAST_REQUEST:
      return {
        ...state,
        contrastLoader: true,
      };
    case PUT_CONTRAST_REQUEST_SUCCESS:
      return {
        ...state,
        contrastLoader: false,
        contrast: action.payload,
      };
    case PUT_CONTRAST_REQUEST_FAILURE:
      return {
        ...state,
        contrastLoader: false,
        contrast: null,
        error: action.payload.error,
      };
    case PUT_CROPPED_IMAGE_REQUEST:
      return {
        ...state,
        crop_image_loader: true,
        croppedImage: action.payload,
      };
    case PUT_CROPPED_IMAGE_REQUEST_SUCCESS:
      return {
        ...state,
        croppedImage: false,
      };
    case PUT_CROPPED_IMAGE_REQUEST_FAILURE:
      return {
        ...state,
        crop_image_loader: false,
        croppedImage: null,
        error: action.payload.error,
      };
    default:
      return {
        ...state,
      };
  }
};
export default getRadiographicAnalysisAndFindingReducer;
