import theme from '../../../../../../theme';
import { subType } from '../../../../../../utils/existingTreatmentConstant';

const lineArray = {
  tooth11: [
    { x1: "12", x2: "30", y1: "1.5", y2: "1.5" },
    { x1: "10", x2: "30.4", y1: "2.5", y2: "2.5" },
    { x1: "9", x2: "32", y1: "4", y2: "4" },
    { x1: "8.5", x2: "32.8", y1: "5.5", y2: "5.5" },
    { x1: "8", x2: "33", y1: "7", y2: "7" },
    { x1: "7.8", x2: "33.4", y1: "8.5", y2: "8.5" },
    { x1: "7", x2: "34", y1: "10", y2: "10" },
    { x1: "6.8", x2: "33.5", y1: "11.5", y2: "11.5" },
    { x1: "6", x2: "34", y1: "13", y2: "13" },
    { x1: "6", x2: "34", y1: "14.5", y2: "14.5" },
    { x1: "6", x2: "34", y1: "16", y2: "16" },
    { x1: "6", x2: "34", y1: "17.5", y2: "17.5" },
    { x1: "6", x2: "34", y1: "19", y2: "19" },
    { x1: "6", x2: "34", y1: "20.5", y2: "20.5" },
    { x1: "6", x2: "34", y1: "22", y2: "22" },
    { x1: "6", x2: "34", y1: "23.5", y2: "23.5" },
    { x1: "7", x2: "34", y1: "25", y2: "25" },
    { x1: "16", x2: "27", y1: "26", y2: "26" }
  ],
  tooth12: [
    { x1: "12", x2: "27.4", y1: "1.5", y2: "1.5" },
    { x1: "11.5", x2: "28", y1: "2.5", y2: "2.5" },
    { x1: "10", x2: "29", y1: "4", y2: "4" },
    { x1: "10", x2: "30", y1: "5.5", y2: "5.5" },
    { x1: "9.5", x2: "30", y1: "7", y2: "7" },
    { x1: "9.5", x2: "30.2", y1: "8.5", y2: "8.5" },
    { x1: "10", x2: "30.5", y1: "10", y2: "10" },
    { x1: "10", x2: "30.6", y1: "11.5", y2: "11.5" },
    { x1: "9.9", x2: "31", y1: "13", y2: "13" },
    { x1: "9.7", x2: "31", y1: "14.5", y2: "14.5" },
    { x1: "9.6", x2: "31", y1: "16", y2: "16" },
    { x1: "9.4", x2: "31", y1: "17.5", y2: "17.5" },
    { x1: "9.3", x2: "31", y1: "19", y2: "19" },
    { x1: "9.1", x2: "31", y1: "20.5", y2: "20.5" },
    { x1: "9", x2: "31", y1: "22", y2: "22" },
    { x1: "9", x2: "31", y1: "23.5", y2: "23.5" }
  ],
  tooth13: [
    { x1: "10", x2: "29", y1: "1.5", y2: "1.5" },
    { x1: "10", x2: "29.3", y1: "2.5", y2: "2.5" },
    { x1: "9", x2: "30.3", y1: "4", y2: "4" },
    { x1: "8", x2: "32", y1: "5.5", y2: "5.5" },
    { x1: "7.8", x2: "32", y1: "7", y2: "7" },
    { x1: "7.3", x2: "33.2", y1: "8.5", y2: "8.5" },
    { x1: "6.7", x2: "33.5", y1: "10", y2: "10" },
    { x1: "6.3", x2: "34", y1: "11.5", y2: "11.5" },
    { x1: "6", x2: "34.5", y1: "13", y2: "13" },
    { x1: "5.8", x2: "34.5", y1: "14.5", y2: "14.5" },
    { x1: "5.6", x2: "34.5", y1: "16", y2: "16" },
    { x1: "5.4", x2: "34", y1: "17.5", y2: "17.5" },
    { x1: "5.3", x2: "33", y1: "19", y2: "19" },
    { x1: "5.2", x2: "31", y1: "20.5", y2: "20.5" },
    { x1: "6.5", x2: "30", y1: "22", y2: "22" },
    { x1: "10.5", x2: "25", y1: "23.5", y2: "23.5" },
    { x1: "10.5", x2: "23", y1: "25", y2: "25" },
    { x1: "11.4", x2: "22", y1: "26.5", y2: "26.5" },
    { x1: "11.9", x2: "20", y1: "28", y2: "28" },
    { x1: "13", x2: "19", y1: "29", y2: "29" }
  ],
  tooth14: [
    { x1: "13", x2: "26.5", y1: "1.5", y2: "1.5" },
    { x1: "13", x2: "26.7", y1: "2.5", y2: "2.5" },
    { x1: "12", x2: "27.5", y1: "4", y2: "4" },
    { x1: "12", x2: "28.2", y1: "5.5", y2: "5.5" },
    { x1: "11", x2: "28.7", y1: "7", y2: "7" },
    { x1: "10.7", x2: "29.4", y1: "8.5", y2: "8.5" },
    { x1: "10.2", x2: "29.7", y1: "10", y2: "10" },
    { x1: "9", x2: "30.1", y1: "11.5", y2: "11.5" },
    { x1: "9", x2: "31", y1: "13", y2: "13" },
    { x1: "9", x2: "31", y1: "14.5", y2: "14.5" },
    { x1: "9", x2: "31", y1: "16", y2: "16" },
    { x1: "9", x2: "31", y1: "17.5", y2: "17.5" },
    { x1: "10", x2: "30", y1: "19", y2: "19" },
    { x1: "10", x2: "29", y1: "20.5", y2: "20.5" },
    { x1: "11", x2: "27.3", y1: "22", y2: "22" },
    { x1: "12", x2: "26", y1: "23.5", y2: "23.5" },
    { x1: "14", x2: "25", y1: "25", y2: "25" }
  ],
  tooth15: [
    { x1: "12", x2: "27.2", y1: "1.5", y2: "1.5" },
    { x1: "12", x2: "27.7", y1: "2.5", y2: "2.5" },
    { x1: "11", x2: "28.5", y1: "4", y2: "4" },
    { x1: "11", x2: "29.3", y1: "5.5", y2: "5.5" },
    { x1: "10", x2: "30", y1: "7", y2: "7" },
    { x1: "10", x2: "31", y1: "8.5", y2: "8.5" },
    { x1: "9", x2: "31", y1: "10", y2: "10" },
    { x1: "9", x2: "32", y1: "11.5", y2: "11.5" },
    { x1: "8", x2: "32", y1: "13", y2: "13" },
    { x1: "8", x2: "32", y1: "14.5", y2: "14.5" },
    { x1: "8", x2: "32", y1: "16", y2: "16" },
    { x1: "8", x2: "32", y1: "17.5", y2: "17.5" },
    { x1: "9", x2: "31.5", y1: "19", y2: "19" },
    { x1: "10", x2: "27.3", y1: "20.5", y2: "20.5" },
    { x1: "12", x2: "26", y1: "22", y2: "22" },
    { x1: "14", x2: "25", y1: "23.5", y2: "23.5" },
    { x1: "16", x2: "23", y1: "25", y2: "25" }
  ],
  tooth16: [
    { x1: "9", x2: "33", y1: "1.5", y2: "1.5" },
    { x1: "8.7", x2: "33.1", y1: "2.5", y2: "2.5" },
    { x1: "8", x2: "34", y1: "4", y2: "4" },
    { x1: "7", x2: "34.5", y1: "5.5", y2: "5.5" },
    { x1: "6", x2: "34.9", y1: "7", y2: "7" },
    { x1: "5.6", x2: "35.5", y1: "8.5", y2: "8.5" },
    { x1: "4.4", x2: "35.7", y1: "10", y2: "10" },
    { x1: "4.2", x2: "35.9", y1: "11.5", y2: "11.5" },
    { x1: "4", x2: "36", y1: "13", y2: "13" },
    { x1: "4", x2: "36.5", y1: "14.5", y2: "14.5" },
    { x1: "4.2", x2: "36", y1: "16", y2: "16" },
    { x1: "4.2", x2: "36", y1: "17.5", y2: "17.5" },
    { x1: "5.2", x2: "14.5", y1: "19", y2: "19" },
    { x1: "35.9", x2: "25.5", y1: "19", y2: "19" },
    { x1: "35", x2: "26", y1: "20.5", y2: "20.5" }
  ],
  tooth17: [
    { x1: "9", x2: "33", y1: "1.5", y2: "1.5" },
    { x1: "8.9", x2: "33", y1: "2.5", y2: "2.5" },
    { x1: "8", x2: "34", y1: "4", y2: "4" },
    { x1: "7", x2: "34.5", y1: "5.5", y2: "5.5" },
    { x1: "6", x2: "35", y1: "7", y2: "7" },
    { x1: "5", x2: "35.5", y1: "8.5", y2: "8.5" },
    { x1: "4.4", x2: "35.6", y1: "10", y2: "10" },
    { x1: "4.2", x2: "36", y1: "11.5", y2: "11.5" },
    { x1: "4", x2: "36", y1: "13", y2: "13" },
    { x1: "4", x2: "36.5", y1: "14.5", y2: "14.5" },
    { x1: "4.2", x2: "36", y1: "16", y2: "16" },
    { x1: "4.2", x2: "35.6", y1: "17.5", y2: "17.5" },
    { x1: "5", x2: "14.5", y1: "19", y2: "19" },
    { x1: "35.2", x2: "25", y1: "19", y2: "19" },
    { x1: "35", x2: "26", y1: "20.5", y2: "20.5" },
    { x1: "34", x2: "30", y1: "22", y2: "22" }
  ],
  tooth18: [
    { x1: "7.3", x2: "30", y1: "1.5", y2: "1.5" },
    { x1: "7.3", x2: "31", y1: "2.5", y2: "2.5" },
    { x1: "7.3", x2: "32", y1: "4", y2: "4" },
    { x1: "7", x2: "33", y1: "5.5", y2: "5.5" },
    { x1: "6.3", x2: "34", y1: "7", y2: "7" },
    { x1: "6", x2: "35", y1: "8.5", y2: "8.5" },
    { x1: "5.6", x2: "35", y1: "10", y2: "10" },
    { x1: "5", x2: "35", y1: "11.5", y2: "11.5" },
    { x1: "5", x2: "36.4", y1: "13", y2: "13" },
    { x1: "4.7", x2: "36", y1: "14.5", y2: "14.5" },
    { x1: "4.7", x2: "35.5", y1: "16", y2: "16" },
    { x1: "4", x2: "14.5", y1: "17.5", y2: "17.5" },
    { x1: "34", x2: "23", y1: "17.5", y2: "17.5" },
  ],
  tooth21: [
    { x1: "10", x2: "28", y1: "1.5", y2: "1.5" },
    { x1: "9.5", x2: "29", y1: "2.5", y2: "2.5" },
    { x1: "8.8", x2: "30.3", y1: "4", y2: "4" },
    { x1: "8", x2: "32", y1: "5.5", y2: "5.5" },
    { x1: "7.6", x2: "32", y1: "7", y2: "7" },
    { x1: "7", x2: "33", y1: "8.5", y2: "8.5" },
    { x1: "6", x2: "33", y1: "10", y2: "10" },
    { x1: "6", x2: "33.5", y1: "11.5", y2: "11.5" },
    { x1: "6", x2: "34", y1: "13", y2: "13" },
    { x1: "6", x2: "34", y1: "14.5", y2: "14.5" },
    { x1: "6", x2: "34", y1: "16", y2: "16" },
    { x1: "6", x2: "34", y1: "17.5", y2: "17.5" },
    { x1: "6", x2: "34", y1: "19", y2: "19" },
    { x1: "6", x2: "34", y1: "20.5", y2: "20.5" },
    { x1: "6", x2: "34", y1: "22", y2: "22" },
    { x1: "6", x2: "34", y1: "23.5", y2: "23.5" },
    { x1: "7", x2: "33", y1: "25", y2: "25" },
    { x1: "12", x2: "27", y1: "26", y2: "26" }
  ],
  tooth22: [
    { x1: "12", x2: "27.4", y1: "1.5", y2: "1.5" },
    { x1: "11.5", x2: "28.2", y1: "2.5", y2: "2.5" },
    { x1: "11", x2: "29", y1: "4", y2: "4" },
    { x1: "10", x2: "30", y1: "5.5", y2: "5.5" },
    { x1: "9.5", x2: "30", y1: "7", y2: "7" },
    { x1: "9.5", x2: "30.2", y1: "8.5", y2: "8.5" },
    { x1: "9", x2: "30.5", y1: "10", y2: "10" },
    { x1: "9", x2: "30.6", y1: "11.5", y2: "11.5" },
    { x1: "9", x2: "31", y1: "13", y2: "13" },
    { x1: "9", x2: "31", y1: "14.5", y2: "14.5" },
    { x1: "9", x2: "31", y1: "16", y2: "16" },
    { x1: "9", x2: "31", y1: "17.5", y2: "17.5" },
    { x1: "9", x2: "31", y1: "19", y2: "19" },
    { x1: "9.1", x2: "31", y1: "20.5", y2: "20.5" },
    { x1: "9", x2: "31", y1: "22", y2: "22" },
    { x1: "9", x2: "31", y1: "23.5", y2: "23.5" }
  ],
  tooth23: [
    { x1: "11", x2: "30", y1: "1.5", y2: "1.5" },
    { x1: "10", x2: "30", y1: "2.5", y2: "2.5" },
    { x1: "9", x2: "31", y1: "4", y2: "4" },
    { x1: "8", x2: "32", y1: "5.5", y2: "5.5" },
    { x1: "7.8", x2: "32.3", y1: "7", y2: "7" },
    { x1: "7.3", x2: "33.2", y1: "8.5", y2: "8.5" },
    { x1: "6.7", x2: "33.5", y1: "10", y2: "10" },
    { x1: "6", x2: "34", y1: "11.5", y2: "11.5" },
    { x1: "5.7", x2: "34.5", y1: "13", y2: "13" },
    { x1: "5.6", x2: "34.5", y1: "14.5", y2: "14.5" },
    { x1: "5.6", x2: "34.5", y1: "16", y2: "16" },
    { x1: "6", x2: "34.5", y1: "17.5", y2: "17.5" },
    { x1: "8", x2: "34.4", y1: "19", y2: "19" },
    { x1: "9", x2: "34", y1: "20.5", y2: "20.5" },
    { x1: "10", x2: "33", y1: "22", y2: "22" },
    { x1: "16", x2: "30", y1: "23.5", y2: "23.5" },
    { x1: "17", x2: "29", y1: "25", y2: "25" },
    { x1: "18", x2: "28.3", y1: "26.5", y2: "26.5" },
    { x1: "20", x2: "28", y1: "28", y2: "28" },
    { x1: "21", x2: "27", y1: "29", y2: "29" }
  ],
  tooth24: [
    { x1: "13", x2: "27", y1: "1.5", y2: "1.5" },
    { x1: "13", x2: "27", y1: "2.5", y2: "2.5" },
    { x1: "12", x2: "27.5", y1: "4", y2: "4" },
    { x1: "12", x2: "28.2", y1: "5.5", y2: "5.5" },
    { x1: "11", x2: "28.7", y1: "7", y2: "7" },
    { x1: "10.7", x2: "29.4", y1: "8.5", y2: "8.5" },
    { x1: "10.2", x2: "29.7", y1: "10", y2: "10" },
    { x1: "9", x2: "30.1", y1: "11.5", y2: "11.5" },
    { x1: "9", x2: "31", y1: "13", y2: "13" },
    { x1: "9", x2: "31", y1: "14.5", y2: "14.5" },
    { x1: "9", x2: "31", y1: "16", y2: "16" },
    { x1: "9", x2: "31", y1: "17.5", y2: "17.5" },
    { x1: "10", x2: "30", y1: "19", y2: "19" },
    { x1: "11", x2: "30", y1: "20.5", y2: "20.5" },
    { x1: "12", x2: "28.2", y1: "22", y2: "22" },
    { x1: "13", x2: "27", y1: "23.5", y2: "23.5" },
    { x1: "15", x2: "26", y1: "25", y2: "25" }
  ],
  tooth25: [
    { x1: "12", x2: "27.2", y1: "1.5", y2: "1.5" },
    { x1: "12", x2: "27.7", y1: "2.5", y2: "2.5" },
    { x1: "11", x2: "28.5", y1: "4", y2: "4" },
    { x1: "10", x2: "29.3", y1: "5.5", y2: "5.5" },
    { x1: "10", x2: "30", y1: "7", y2: "7" },
    { x1: "9", x2: "30", y1: "8.5", y2: "8.5" },
    { x1: "8", x2: "30.5", y1: "10", y2: "10" },
    { x1: "8", x2: "31", y1: "11.5", y2: "11.5" },
    { x1: "8", x2: "31.5", y1: "13", y2: "13" },
    { x1: "8", x2: "31.7", y1: "14.5", y2: "14.5" },
    { x1: "8", x2: "32", y1: "16", y2: "16" },
    { x1: "8", x2: "32", y1: "17.5", y2: "17.5" },
    { x1: "9", x2: "31", y1: "19", y2: "19" },
    { x1: "12", x2: "30", y1: "20.5", y2: "20.5" },
    { x1: "13", x2: "28", y1: "22", y2: "22" },
    { x1: "15", x2: "25", y1: "23.5", y2: "23.5" },
    { x1: "17", x2: "23", y1: "25", y2: "25" }
  ],
  tooth26: [
    { x1: "6.7", x2: "31", y1: "1.5", y2: "1.5" },
    { x1: "6", x2: "32", y1: "2.5", y2: "2.5" },
    { x1: "6", x2: "32.5", y1: "4", y2: "4" },
    { x1: "5.5", x2: "33", y1: "5.5", y2: "5.5" },
    { x1: "5", x2: "33.5", y1: "7", y2: "7" },
    { x1: "4.6", x2: "34.2", y1: "8.5", y2: "8.5" },
    { x1: "4.4", x2: "34.8", y1: "10", y2: "10" },
    { x1: "4.2", x2: "35.4", y1: "11.5", y2: "11.5" },
    { x1: "4.2", x2: "36", y1: "13", y2: "13" },
    { x1: "4.2", x2: "36.5", y1: "14.5", y2: "14.5" },
    { x1: "4.2", x2: "36", y1: "16", y2: "16" },
    { x1: "4.2", x2: "35", y1: "17.5", y2: "17.5" },
    { x1: "4.2", x2: "14.5", y1: "19", y2: "19" },
    { x1: "4.9", x2: "12", y1: "21.7", y2: "21.7" },
    { x1: "4.5", x2: "13", y1: "20.5", y2: "20.5" },
    { x1: "33.9", x2: "25", y1: "19", y2: "19" },
    { x1: "33", x2: "27", y1: "20", y2: "20" }
  ],
  tooth27: [
    { x1: "6.5", x2: "31", y1: "1.5", y2: "1.5" },
    { x1: "6", x2: "32", y1: "2.5", y2: "2.5" },
    { x1: "6", x2: "32.5", y1: "4", y2: "4" },
    { x1: "5.5", x2: "33", y1: "5.5", y2: "5.5" },
    { x1: "5", x2: "33.5", y1: "7", y2: "7" },
    { x1: "4.6", x2: "34", y1: "8.5", y2: "8.5" },
    { x1: "4.4", x2: "34.7", y1: "10", y2: "10" },
    { x1: "4.2", x2: "35.3", y1: "11.5", y2: "11.5" },
    { x1: "4.2", x2: "36", y1: "13", y2: "13" },
    { x1: "4.2", x2: "36.5", y1: "14.5", y2: "14.5" },
    { x1: "4.2", x2: "36", y1: "16", y2: "16" },
    { x1: "4.2", x2: "35", y1: "17.5", y2: "17.5" },
    { x1: "4.2", x2: "14.5", y1: "19", y2: "19" },
    { x1: "4.9", x2: "12", y1: "21.7", y2: "21.7" },
    { x1: "4.5", x2: "13", y1: "20.5", y2: "20.5" },
    { x1: "33.9", x2: "25", y1: "19", y2: "19" },
    { x1: "33", x2: "27", y1: "20", y2: "20" },
  ],

  tooth28: [
    { x1: "9.5", x2: "31", y1: "1.5", y2: "1.5" },
    { x1: "9", x2: "32", y1: "2.5", y2: "2.5" },
    { x1: "7.7", x2: "32.5", y1: "4", y2: "4" },
    { x1: "7.7", x2: "33", y1: "5.5", y2: "5.5" },
    { x1: "6.8", x2: "33.5", y1: "7", y2: "7" },
    { x1: "5.2", x2: "34", y1: "8.5", y2: "8.5" },
    { x1: "5.3", x2: "34.7", y1: "10", y2: "10" },
    { x1: "4.2", x2: "35.3", y1: "11.5", y2: "11.5" },
    { x1: "4.2", x2: "35", y1: "13", y2: "13" },
    { x1: "4.2", x2: "35", y1: "14.5", y2: "14.5" },
    { x1: "4.6", x2: "36", y1: "16", y2: "16" },
    { x1: "6.5", x2: "19", y1: "17.5", y2: "17.5" },
    { x1: "7", x2: "16", y1: "18.5", y2: "18.5" },
    { x1: "25", x2: "35", y1: "17.5", y2: "17.5" },
    { x1: "28", x2: "35", y1: "18.5", y2: "18.5" },
  ],
  tooth31: [
    { x1: "11.5", x2: "28", y1: "2.5", y2: "2.5" },
    { x1: "10", x2: "29.3", y1: "4", y2: "4" },
    { x1: "9.7", x2: "30", y1: "5.5", y2: "5.5" },
    { x1: "9.5", x2: "30.2", y1: "7", y2: "7" },
    { x1: "9.5", x2: "30.2", y1: "8.5", y2: "8.5" },
    { x1: "9.8", x2: "30.5", y1: "10", y2: "10" },
    { x1: "9.8", x2: "30.6", y1: "11.5", y2: "11.5" },
    { x1: "9.9", x2: "30", y1: "13", y2: "13" },
    { x1: "9.7", x2: "30", y1: "14.5", y2: "14.5" },
    { x1: "9.6", x2: "30", y1: "16", y2: "16" },
    { x1: "9.4", x2: "30", y1: "17.5", y2: "17.5" },
    { x1: "10", x2: "29", y1: "19", y2: "19" },
    { x1: "10", x2: "29", y1: "20.5", y2: "20.5" },
    { x1: "11", x2: "29", y1: "22", y2: "22" },
    { x1: "11.8", x2: "28", y1: "23.5", y2: "23.5" },
    { x1: "11.8", x2: "26.3", y1: "25", y2: "25" }
  ],
  tooth32: [
    { x1: "9.5", x2: "30.2", y1: "8.5", y2: "8.5" },
    { x1: "9", x2: "31", y1: "10", y2: "10" },
    { x1: "9", x2: "31", y1: "11.5", y2: "11.5" },
    { x1: "9", x2: "31", y1: "13", y2: "13" },
    { x1: "9", x2: "31", y1: "14.5", y2: "14.5" },
    { x1: "9", x2: "31", y1: "16", y2: "16" },
    { x1: "9", x2: "31", y1: "17.5", y2: "17.5" },
    { x1: "9", x2: "31", y1: "19", y2: "19" },
    { x1: "9.1", x2: "31", y1: "20.5", y2: "20.5" },
    { x1: "9", x2: "31", y1: "22", y2: "22" },
    { x1: "9", x2: "30", y1: "23.5", y2: "23.5" },
    { x1: "10", x2: "30", y1: "25", y2: "25" },
    { x1: "10", x2: "30", y1: "26.5", y2: "26.5" },
    { x1: "11", x2: "30", y1: "28", y2: "28" },
    { x1: "11", x2: "28.2", y1: "29.5", y2: "29.5" },
    { x1: "13", x2: "27", y1: "30.8", y2: "30.8" }
  ],
  tooth33: [
    { x1: "19.5", x2: "28", y1: "4", y2: "4" },
    { x1: "18", x2: "29", y1: "5.5", y2: "5.5" },
    { x1: "17", x2: "29.6", y1: "7", y2: "7" },
    { x1: "15", x2: "30.2", y1: "8.5", y2: "8.5" },
    { x1: "11", x2: "33", y1: "10", y2: "10" },
    { x1: "9", x2: "34", y1: "11.5", y2: "11.5" },
    { x1: "7.5", x2: "34.5", y1: "13", y2: "13" },
    { x1: "7", x2: "35", y1: "14.5", y2: "14.5" },
    { x1: "6", x2: "35", y1: "16", y2: "16" },
    { x1: "5", x2: "35", y1: "17.5", y2: "17.5" },
    { x1: "5", x2: "35", y1: "19", y2: "19" },
    { x1: "5.4", x2: "34", y1: "20.5", y2: "20.5" },
    { x1: "5.9", x2: "34", y1: "22", y2: "22" },
    { x1: "6.4", x2: "33", y1: "23.5", y2: "23.5" },
    { x1: "7.2", x2: "33", y1: "25", y2: "25" },
    { x1: "8", x2: "32", y1: "26.5", y2: "26.5" },
    { x1: "9", x2: "31", y1: "28", y2: "28" },
    { x1: "10", x2: "31", y1: "29.5", y2: "29.5" },
    { x1: "11", x2: "30", y1: "30.8", y2: "30.8" }
  ],
  tooth34: [
    { x1: "15", x2: "24.3", y1: "4", y2: "4" },
    { x1: "14", x2: "27", y1: "5.5", y2: "5.5" },
    { x1: "12", x2: "29.6", y1: "7", y2: "7" },
    { x1: "11", x2: "30.2", y1: "8.5", y2: "8.5" },
    { x1: "8", x2: "32", y1: "10", y2: "10" },
    { x1: "8", x2: "32", y1: "11.5", y2: "11.5" },
    { x1: "7.5", x2: "32", y1: "13", y2: "13" },
    { x1: "8", x2: "32", y1: "14.5", y2: "14.5" },
    { x1: "8", x2: "32", y1: "16", y2: "16" },
    { x1: "8", x2: "31", y1: "17.5", y2: "17.5" },
    { x1: "9", x2: "31", y1: "19", y2: "19" },
    { x1: "9", x2: "30", y1: "20.5", y2: "20.5" },
    { x1: "10", x2: "29", y1: "22", y2: "22" },
    { x1: "11", x2: "29", y1: "23.5", y2: "23.5" },
    { x1: "11", x2: "28", y1: "25", y2: "25" },
    { x1: "12", x2: "27", y1: "26.5", y2: "26.5" }
  ],
  tooth35: [
    { x1: "15", x2: "24.3", y1: "4", y2: "4" },
    { x1: "14", x2: "27", y1: "5.5", y2: "5.5" },
    { x1: "12", x2: "29.6", y1: "7", y2: "7" },
    { x1: "11", x2: "30.2", y1: "8.5", y2: "8.5" },
    { x1: "8", x2: "32", y1: "10", y2: "10" },
    { x1: "8", x2: "32", y1: "11.5", y2: "11.5" },
    { x1: "7.5", x2: "32", y1: "13", y2: "13" },
    { x1: "8", x2: "32", y1: "14.5", y2: "14.5" },
    { x1: "8", x2: "32", y1: "16", y2: "16" },
    { x1: "8", x2: "31", y1: "17.5", y2: "17.5" },
    { x1: "9", x2: "31", y1: "19", y2: "19" },
    { x1: "9", x2: "30", y1: "20.5", y2: "20.5" },
    { x1: "10", x2: "29", y1: "22", y2: "22" },
    { x1: "11", x2: "29", y1: "23.5", y2: "23.5" },
    { x1: "11", x2: "28", y1: "25", y2: "25" },
    { x1: "12", x2: "27", y1: "26.5", y2: "26.5" }
  ],
  tooth36: [
    { x1: "5", x2: "11", y1: "2.5", y2: "2.5" },
    { x1: "4", x2: "13.4", y1: "4", y2: "4" },
    { x1: "4", x2: "15", y1: "5.5", y2: "5.5" },
    { x1: "27", x2: "33", y1: "4", y2: "4" },
    { x1: "24", x2: "35", y1: "5.5", y2: "5.5" },
    { x1: "4", x2: "35.5", y1: "7", y2: "7" },
    { x1: "4", x2: "35.6", y1: "8.5", y2: "8.5" },
    { x1: "4", x2: "36", y1: "10", y2: "10" },
    { x1: "4", x2: "36", y1: "11.5", y2: "11.5" },
    { x1: "4", x2: "36", y1: "13", y2: "13" },
    { x1: "4", x2: "35", y1: "14.5", y2: "14.5" },
    { x1: "4", x2: "34", y1: "16", y2: "16" },
    { x1: "5", x2: "33.2", y1: "17.5", y2: "17.5" },
    { x1: "5", x2: "33", y1: "19", y2: "19" },
    { x1: "6", x2: "32", y1: "20.5", y2: "20.5" },
    { x1: "7", x2: "31", y1: "22", y2: "22" }
  ],
  tooth37: [
    { x1: "5", x2: "11", y1: "2.5", y2: "2.5" },
    { x1: "4", x2: "13.4", y1: "4", y2: "4" },
    { x1: "4", x2: "15", y1: "5.5", y2: "5.5" },
    { x1: "27", x2: "33", y1: "4", y2: "4" },
    { x1: "24", x2: "35", y1: "5.5", y2: "5.5" },
    { x1: "4", x2: "35.5", y1: "7", y2: "7" },
    { x1: "4", x2: "35.6", y1: "8.5", y2: "8.5" },
    { x1: "4", x2: "36", y1: "10", y2: "10" },
    { x1: "4", x2: "36", y1: "11.5", y2: "11.5" },
    { x1: "4", x2: "36", y1: "13", y2: "13" },
    { x1: "4", x2: "35", y1: "14.5", y2: "14.5" },
    { x1: "4", x2: "34", y1: "16", y2: "16" },
    { x1: "5", x2: "33.2", y1: "17.5", y2: "17.5" },
    { x1: "5", x2: "33", y1: "19", y2: "19" },
    { x1: "6", x2: "32", y1: "20.5", y2: "20.5" },
    { x1: "7", x2: "31", y1: "22", y2: "22" }
  ],
  tooth38: [
    { x1: "8", x2: "17", y1: "2.5", y2: "2.5" },
    { x1: "28.5", x2: "35", y1: "2.5", y2: "2.5" },
    { x1: "5.5", x2: "19.7", y1: "4", y2: "4" },
    { x1: "5", x2: "35", y1: "5.5", y2: "5.5" },
    { x1: "24", x2: "35", y1: "4", y2: "4" },
    { x1: "5", x2: "35.5", y1: "7", y2: "7" },
    { x1: "5", x2: "35.6", y1: "8.5", y2: "8.5" },
    { x1: "5", x2: "35", y1: "10", y2: "10" },
    { x1: "5", x2: "34", y1: "11.5", y2: "11.5" },
    { x1: "6", x2: "34", y1: "13", y2: "13" },
    { x1: "7", x2: "33.2", y1: "14.5", y2: "14.5" },
    { x1: "7", x2: "33", y1: "16", y2: "16" },
    { x1: "8", x2: "32.2", y1: "17.5", y2: "17.5" },
    { x1: "10", x2: "32", y1: "19", y2: "19" },
    { x1: "11", x2: "32", y1: "20.5", y2: "20.5" }
  ],
  tooth41: [
    { x1: "11.5", x2: "28", y1: "2.5", y2: "2.5" },
    { x1: "10", x2: "29.9", y1: "4", y2: "4" },
    { x1: "9.7", x2: "30.2", y1: "5.5", y2: "5.5" },
    { x1: "9.5", x2: "30.2", y1: "7", y2: "7" },
    { x1: "9.5", x2: "30.2", y1: "8.5", y2: "8.5" },
    { x1: "9.8", x2: "30.5", y1: "10", y2: "10" },
    { x1: "9.8", x2: "30.6", y1: "11.5", y2: "11.5" },
    { x1: "9.9", x2: "30", y1: "13", y2: "13" },
    { x1: "9.7", x2: "30", y1: "14.5", y2: "14.5" },
    { x1: "9.6", x2: "30", y1: "16", y2: "16" },
    { x1: "10", x2: "30", y1: "17.5", y2: "17.5" },
    { x1: "11", x2: "30", y1: "19", y2: "19" },
    { x1: "11", x2: "30", y1: "20.5", y2: "20.5" },
    { x1: "11", x2: "29", y1: "22", y2: "22" },
    { x1: "11.8", x2: "29", y1: "23.5", y2: "23.5" },
    { x1: "13", x2: "28", y1: "25", y2: "25" }
  ],
  tooth42: [
    { x1: "9", x2: "30.5", y1: "8.5", y2: "8.5" },
    { x1: "9", x2: "30.7", y1: "10", y2: "10" },
    { x1: "9", x2: "30.9", y1: "11.5", y2: "11.5" },
    { x1: "9", x2: "31", y1: "13", y2: "13" },
    { x1: "9", x2: "31", y1: "14.5", y2: "14.5" },
    { x1: "9", x2: "31", y1: "16", y2: "16" },
    { x1: "9", x2: "31", y1: "17.5", y2: "17.5" },
    { x1: "9", x2: "31", y1: "19", y2: "19" },
    { x1: "9", x2: "31", y1: "20.5", y2: "20.5" },
    { x1: "9", x2: "31", y1: "22", y2: "22" },
    { x1: "10", x2: "30.3", y1: "23.5", y2: "23.5" },
    { x1: "10.3", x2: "30", y1: "25", y2: "25" },
    { x1: "10.3", x2: "30", y1: "26.5", y2: "26.5" },
    { x1: "10.3", x2: "29", y1: "28", y2: "28" },
    { x1: "11", x2: "29", y1: "29.5", y2: "29.5" },
    { x1: "13", x2: "27", y1: "31", y2: "31" }
  ],
  tooth43: [
    { x1: "12", x2: "20", y1: "4", y2: "4" },
    { x1: "11.8", x2: "21.4", y1: "5.5", y2: "5.5" },
    { x1: "11", x2: "23", y1: "7", y2: "7" },
    { x1: "10", x2: "24", y1: "8.5", y2: "8.5" },
    { x1: "6.7", x2: "28", y1: "10", y2: "10" },
    { x1: "6", x2: "31", y1: "11.5", y2: "11.5" },
    { x1: "5.8", x2: "32", y1: "13", y2: "13" },
    { x1: "5.5", x2: "33", y1: "14.5", y2: "14.5" },
    { x1: "5.6", x2: "34.5", y1: "16", y2: "16" },
    { x1: "5.4", x2: "34.7", y1: "17.5", y2: "17.5" },
    { x1: "5.3", x2: "34.3", y1: "19", y2: "19" },
    { x1: "6", x2: "34", y1: "20.5", y2: "20.5" },
    { x1: "6.5", x2: "34", y1: "22", y2: "22" },
    { x1: "6.5", x2: "33", y1: "23.5", y2: "23.5" },
    { x1: "7", x2: "32", y1: "25", y2: "25" },
    { x1: "7.7", x2: "32", y1: "26.5", y2: "26.5" },
    { x1: "9", x2: "30.3", y1: "28", y2: "28" },
    { x1: "9", x2: "30", y1: "29.5", y2: "29.5" },
    { x1: "10", x2: "29", y1: "31", y2: "31" }
  ],
  tooth44: [
    { x1: "15", x2: "23.7", y1: "4", y2: "4" },
    { x1: "13.3", x2: "25.8", y1: "5.5", y2: "5.5" },
    { x1: "11.2", x2: "27", y1: "7", y2: "7" },
    { x1: "9", x2: "30.5", y1: "8.5", y2: "8.5" },
    { x1: "8", x2: "31.8", y1: "10", y2: "10" },
    { x1: "8", x2: "31.9", y1: "11.5", y2: "11.5" },
    { x1: "8", x2: "31.8", y1: "13", y2: "13" },
    { x1: "8.4", x2: "31.7", y1: "14.5", y2: "14.5" },
    { x1: "8.5", x2: "31.5", y1: "16", y2: "16" },
    { x1: "8.5", x2: "31.4", y1: "17.5", y2: "17.5" },
    { x1: "9", x2: "30.8", y1: "19", y2: "19" },
    { x1: "10", x2: "31", y1: "20.5", y2: "20.5" },
    { x1: "11", x2: "30", y1: "22", y2: "22" },
    { x1: "11", x2: "29", y1: "23.5", y2: "23.5" },
    { x1: "12", x2: "29", y1: "25", y2: "25" },
    { x1: "12", x2: "28", y1: "26.5", y2: "26.5" }
  ],
  tooth45: [
    { x1: "15", x2: "23.7", y1: "4", y2: "4" },
    { x1: "13.3", x2: "25.8", y1: "5.5", y2: "5.5" },
    { x1: "11.2", x2: "27", y1: "7", y2: "7" },
    { x1: "9", x2: "30.5", y1: "8.5", y2: "8.5" },
    { x1: "8", x2: "31.8", y1: "10", y2: "10" },
    { x1: "8", x2: "31.9", y1: "11.5", y2: "11.5" },
    { x1: "8", x2: "31.8", y1: "13", y2: "13" },
    { x1: "8.4", x2: "31.7", y1: "14.5", y2: "14.5" },
    { x1: "8.5", x2: "31.5", y1: "16", y2: "16" },
    { x1: "8.5", x2: "31.4", y1: "17.5", y2: "17.5" },
    { x1: "9", x2: "30.8", y1: "19", y2: "19" },
    { x1: "10", x2: "31", y1: "20.5", y2: "20.5" },
    { x1: "11", x2: "30", y1: "22", y2: "22" },
    { x1: "11", x2: "29", y1: "23.5", y2: "23.5" },
    { x1: "12", x2: "29", y1: "25", y2: "25" },
    { x1: "12", x2: "28", y1: "26.5", y2: "26.5" }
  ],
  tooth46: [
    { x1: "28", x2: "34", y1: "2.5", y2: "2.5" },
    { x1: "7", x2: "13.4", y1: "4", y2: "4" },
    { x1: "5.5", x2: "15", y1: "5.5", y2: "5.5" },
    { x1: "25.9", x2: "35", y1: "4", y2: "4" },
    { x1: "24.7", x2: "35.4", y1: "5.5", y2: "5.5" },
    { x1: "4", x2: "35.8", y1: "7", y2: "7" },
    { x1: "4", x2: "35.8", y1: "8.5", y2: "8.5" },
    { x1: "4", x2: "36", y1: "10", y2: "10" },
    { x1: "4", x2: "36", y1: "11.5", y2: "11.5" },
    { x1: "4", x2: "36", y1: "13", y2: "13" },
    { x1: "5", x2: "36", y1: "14.5", y2: "14.5" },
    { x1: "5.5", x2: "36", y1: "16", y2: "16" },
    { x1: "6", x2: "35", y1: "17.5", y2: "17.5" },
    { x1: "7", x2: "35", y1: "19", y2: "19" },
    { x1: "8", x2: "34", y1: "20.5", y2: "20.5" },
    { x1: "9", x2: "33", y1: "22", y2: "22" }
  ],
  tooth47: [
    { x1: "28", x2: "34", y1: "2.5", y2: "2.5" },
    { x1: "7", x2: "13.4", y1: "4", y2: "4" },
    { x1: "5.5", x2: "15", y1: "5.5", y2: "5.5" },
    { x1: "25.9", x2: "35", y1: "4", y2: "4" },
    { x1: "24.7", x2: "35.4", y1: "5.5", y2: "5.5" },
    { x1: "4", x2: "35.8", y1: "7", y2: "7" },
    { x1: "4", x2: "35.8", y1: "8.5", y2: "8.5" },
    { x1: "4", x2: "36", y1: "10", y2: "10" },
    { x1: "4", x2: "36", y1: "11.5", y2: "11.5" },
    { x1: "4", x2: "36", y1: "13", y2: "13" },
    { x1: "5", x2: "36", y1: "14.5", y2: "14.5" },
    { x1: "5.5", x2: "36", y1: "16", y2: "16" },
    { x1: "6", x2: "35", y1: "17.5", y2: "17.5" },
    { x1: "7", x2: "35", y1: "19", y2: "19" },
    { x1: "8", x2: "34", y1: "20.5", y2: "20.5" },
    { x1: "9", x2: "33", y1: "22", y2: "22" }
  ],
  tooth48: [
    { x1: "5", x2: "13", y1: "2.5", y2: "2.5" },
    { x1: "25", x2: "33", y1: "2.5", y2: "2.5" },
    { x1: "5", x2: "15", y1: "4", y2: "4" },
    { x1: "5", x2: "35", y1: "5.5", y2: "5.5" },
    { x1: "20", x2: "35", y1: "4", y2: "4" },
    { x1: "5", x2: "35.8", y1: "7", y2: "7" },
    { x1: "5", x2: "35.8", y1: "8.5", y2: "8.5" },
    { x1: "5", x2: "35", y1: "10", y2: "10" },
    { x1: "6", x2: "35", y1: "11.5", y2: "11.5" },
    { x1: "6", x2: "34", y1: "13", y2: "13" },
    { x1: "7", x2: "34", y1: "14.5", y2: "14.5" },
    { x1: "7", x2: "32.3", y1: "16", y2: "16" },
    { x1: "8", x2: "32", y1: "17.5", y2: "17.5" },
    { x1: "7.8", x2: "30", y1: "19", y2: "19" }
  ]
};

const defaultStrokeColor = theme.palette.common.skyBorder;
const defaultFillColor = theme.palette.common.skyBg;
const defaultLineColor = theme.palette.v2.secondary

const fixtureCrown = 'Fixture and Crown';
const metalCeramic = 'Metal Ceramic'
const Crown11 = (finding: string, color: string, fill: string, stroke: string, subtype: string) => {
  const isImplant = finding === 'implant';
  const isCrown = finding === 'crown'
  const isSct = finding === 'sct'

  return (
    <svg
      version='1.1'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      x='0px'
      y='0px'
      width='40px'
      height='27px'
      viewBox='0 0 40 27'
    >
      <path
        fill={fill || defaultFillColor}
        stroke={stroke || defaultStrokeColor}
        strokeWidth={stroke === defaultStrokeColor ? '0.45' : '1'}
        d='M12.1,0.5h17.7c0,0,0.3,1.1,1.4,2.7c1.1,1.6,3.2,5.1,3,19.2c0,0,0,2.8-1.1,3c-1.4,0.2-2.3,0.2-2.3,0.2
	s-1.9,0-3.1,0.5s-4.1,0.7-4.1,0.7s-4.5-0.1-7.6-1c0,0-1.3-0.2-4.3,0s-5.9-0.7-5.9-5.1S7,5.1,10.5,2.6C10.5,2.6,12,1.2,12.1,0.5z'
      />
      {isImplant && <path fill={color || defaultLineColor} d='M24,0v1.7l0,0l-0.3,0.8c-1.4,3.3-6,3.3-7.4,0L16,1.7V0H24z' />}
      {((((isImplant && subtype === fixtureCrown) || (isCrown && subtype === metalCeramic))) || (isCrown && subtype === metalCeramic)) && (
        lineArray?.tooth11?.map((res: any, i: number) =>
          <line stroke={stroke || defaultLineColor} key={i} fill='none' stroke-width='0.3' x1={res?.x1} x2={res?.x2} y1={res?.y1} y2={res?.y2} />
        )
      )}
      {/* start sct */}
      {isSct && <g transform="translate(6, 0)">
        <line x1="22.83" y1="-0.462891" x2="18.33" y2="6.53711" stroke={color || defaultLineColor} />
        <line x1="18.33" y1="6.53711" x2="27.33" y2="12.0371" stroke={color || defaultLineColor} />
        <line x1="27.33" y1="12.0371" x2="15.0684" y2="25.9" stroke={color || defaultLineColor} />
      </g>}
      {/* end sct */}
    </svg>
  );
};

const Crown12 = (finding: string, color: string, fill: string, stroke: string, subtype: string) => {
  const isImplant = finding === 'implant';
  const isCrown = finding === 'crown'
  const isSct = finding === 'sct'
  return (
    <svg
      version='1.1'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      x='0px'
      y='0px'
      width='40px'
      height='32px'
      viewBox='0 0 40 32'
    >
      <path
        fill={fill || defaultFillColor}
        stroke={stroke || defaultStrokeColor}
        strokeWidth={stroke === defaultStrokeColor ? '0.45' : '1'}
        d='M13,0.2c0,0,10.8,0,14,0C27.3,1,28.4,1.6,29.5,4c1.8,3.8,2.2,14.1,1.5,18.7c0,0-0.4,2-2.6,1.9
      	C26.2,24.6,25,24,24,24c-1,0.1-3.6,0.6-4.8,0.6c-1.5,0-3.5-1.1-5-1.3c-2.1-0.3-5.9,1.8-5.5-3.1s0.8-7.8,0.8-9.4s0.3-6.4,1.4-8
	      C11.8,1.5,12.8,0.8,13,0.2z'
      />
      {isImplant &&
        <path fill={color || defaultLineColor} d='M24,0v1.7l0,0l-0.3,0.8c-1.4,3.3-6,3.3-7.4,0L16,1.7V0H24z' />}
      {((isImplant && subtype === fixtureCrown) || (isCrown && subtype === metalCeramic)) && (
        lineArray?.tooth12?.map((res: any, i: number) =>
          <line stroke={stroke || defaultLineColor} key={i} fill='none' stroke-width='0.3' x1={res?.x1} x2={res?.x2} y1={res?.y1} y2={res?.y2} />
        )
      )}
      {/* start sct */}
      {isSct && <g>
        <line x1="27.8026" y1="-1.33398" x2="23.2342" y2="5.61916" stroke={color || defaultLineColor} />
        <line x1="23.2342" y1="5.61916" x2="30.371" y2="9.0823" stroke={color || defaultLineColor} />
        <line x1="30.371" y1="9.0823" x2="20.7266" y2="24.0087" stroke={color || defaultLineColor} />
      </g>}
      {/* end sct */}
    </svg >
  );
};

const Crown13 = (finding: string, color: string, fill: string, stroke: string, subtype: string) => {
  const isImplant = finding === 'implant';
  const isCrown = finding === 'crown'
  const isSct = finding === 'sct'
  return (
    <svg
      version='1.1'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      x='0px'
      y='0px'
      width='40px'
      height='32px'
      viewBox='0 0 40 32'
    >
      <path
        fill={fill || defaultFillColor}
        stroke={stroke || defaultStrokeColor}
        strokeWidth={stroke === defaultStrokeColor ? '0.45' : '1'}
        d='M10.8,0.2h17.6c0,0,6.7,9,6.5,14c0,0,0,1.4-1.3,2.9c-0.3,0.4-0.6,0.7-0.9,1.1c-0.7,1.2-2.4,3.6-4.6,3.7
	        c-3.3,0.2-4.7,2.6-4.7,2.6s-3.8,5-6.9,5c0,0-3.7-0.2-4.5-2c-1-1.5-1.2-3.5-2.6-4.3c-1.5-0.9-3.9-1.5-4-4.4C5.4,18.7,3.4,12,10.8,0.2z'
      />
      {isImplant && <path fill={color || defaultLineColor} d='M24,0v1.7l0,0l-0.3,0.8c-1.4,3.3-6,3.3-7.4,0L16,1.7V0H24z' />}
      {((isImplant && subtype === fixtureCrown) || (isCrown && subtype === metalCeramic)) && (
        lineArray?.tooth13?.map((res: any, i: number) =>
          <line stroke={stroke || defaultLineColor} key={i} fill='none' stroke-width='0.3' x1={res?.x1} x2={res?.x2} y1={res?.y1} y2={res?.y2} />
        )
      )}
      {/* start sct */}
      {isSct && <g transform="translate(9, 0)">
        <line x1="16.4545" y1="-2" x2="11.9545" y2="5" stroke={color || defaultLineColor} />
        <line x1="11.9545" y1="5" x2="20.9545" y2="10.5" stroke={color || defaultLineColor} />
        <line x1="20.9545" y1="10.5" x2="12.77734" y2="25.1263" stroke={color || defaultLineColor} />
      </g>}
      {/* end sct */}
    </svg >
  );
};

const Crown14 = (finding: string, color: string, fill: string, stroke: string, subtype: string) => {
  const isImplant = finding === 'implant';
  const isCrown = finding === 'crown'
  const isSct = finding === 'sct'
  return (
    <svg
      version='1.1'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      x='0px'
      y='0px'
      width='40px'
      height='28px'
      viewBox='0 0 40 28'
    >
      <g>
        <path
          fill={fill || defaultFillColor}
          stroke={stroke || defaultStrokeColor}
          strokeWidth={stroke === defaultStrokeColor ? '0.45' : '1'}
          d='M18.6,22.9l1.8,2c0.9,1,2.5,1.2,3.6,0.5c1.4-0.9,3.3-2.5,4.9-5.7c3-6-8.4-1-8.4-1L18.6,22.9z'
        />
        <path
          fill={fill || defaultFillColor}
          stroke={stroke || defaultStrokeColor}
          strokeWidth={stroke === defaultStrokeColor ? '0.45' : '1'}
          d='M13,0.2h13.1c0,0,9.2,15.7,2.4,20.4c0,0,0-0.7-1.1-0.9c-1.7-0.3-8.7,5.9-11.3,5.9c-2.8,0-7.7-6.8-7.2-12.1
		C9.5,8.1,12.6,5.1,13,0.2z'
        />
      </g>
      {isImplant && <path fill={color || defaultLineColor} d='M24,0v1.7l0,0l-0.3,0.8c-1.4,3.3-6,3.3-7.4,0L16,1.7V0H24z' />}
      {((isImplant && subtype === fixtureCrown) || (isCrown && subtype === metalCeramic)) && (
        lineArray?.tooth14?.map((res: any, i: number) =>
          <line stroke={stroke || defaultLineColor} key={i} fill='none' stroke-width='0.3' x1={res?.x1} x2={res?.x2} y1={res?.y1} y2={res?.y2} />
        )
      )}
      {/* start sct */}
      {isSct && <g transform="translate(9, 0)">
        <line x1="16.4487" y1="-2" x2="11.9487" y2="5" stroke={color || defaultLineColor} />
        <line x1="11.9487" y1="5" x2="20.9487" y2="10.5" stroke={color || defaultLineColor} />
        <line x1="20.9487" y1="10.5" x2="7.77148" y2="25" stroke={color || defaultLineColor} />
      </g>}
      {/* end sct */}
    </svg>
  );
};

const Crown15 = (finding: string, color: string, fill: string, stroke: string, subtype: string) => {
  const isImplant = finding === 'implant';
  const isCrown = finding === 'crown'
  const isSct = finding === 'sct'
  return (
    <svg
      version='1.1'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      x='0px'
      y='0px'
      width='40px'
      height='28px'
      viewBox='0 0 40 28'
    >
      <path
        fill={fill || defaultFillColor}
        stroke={stroke || defaultStrokeColor}
        strokeWidth={stroke === defaultStrokeColor ? '0.45' : '1'}
        d='M12.9,0.2h14c0,0,6.3,8.6,5.2,17.4c-0.1,0.6-0.5,1.1-1.1,1.3c-0.3,0.1-0.8,0.2-1.4,0.3c-0.7,0.1-1.4,0.5-1.8,1
	         c-1.5,1.8-5,5.6-7.8,5.4c-3.7-0.3-11.7-5.5-12.3-9.2S12.9,0.2,12.9,0.2z'
      />
      {isImplant && <path fill={color || defaultLineColor} d='M24,0v1.7l0,0l-0.3,0.8c-1.4,3.3-6,3.3-7.4,0L16,1.7V0H24z' />}
      {((isImplant && subtype === fixtureCrown) || (isCrown && subtype === metalCeramic)) && (
        lineArray?.tooth15?.map((res: any, i: number) =>
          <line stroke={stroke || defaultLineColor} key={i} fill='none' stroke-width='0.3' x1={res?.x1} x2={res?.x2} y1={res?.y1} y2={res?.y2} />
        )
      )}
      {/* start sct */}
      {isSct && <g transform="translate(6, 0)">
        <line x1="20.5366" y1="-2" x2="16.0366" y2="5" stroke={color || defaultLineColor} />
        <line x1="16.0366" y1="5" x2="25.0366" y2="10.5" stroke={color || defaultLineColor} />
        <line x1="25.0366" y1="10.5" x2="11.8594" y2="25" stroke={color || defaultLineColor} />
      </g>}
      {/* end sct */}
    </svg>
  );
};
const Crown16 = (finding: string, color: string, fill: string, stroke: string, subtype: string) => {
  const isImplant = finding === 'implant';
  const isCrown = finding === 'crown'
  const isSct = finding === 'sct'
  return (
    <svg
      version='1.1'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      x='0px'
      y='0px'
      width='40px'
      height='24px'
      viewBox='0 0 40 24'
    >
      <path
        fill={fill || defaultFillColor}
        stroke={stroke || defaultStrokeColor}
        strokeWidth={stroke === defaultStrokeColor ? '0.45' : '1'}
        d='M8.8,0.2h24c0,0,0.7,2.2,2.1,5.2c2.1,4.4,1.9,16-1.7,16.8c0,0-3.5,1.1-7.6-3c-5.3-4.2-10.5-1.3-12.1-0.1
        	C7.7,23.5,2.1,17.2,4,11.4C5.9,5.9,9.1,2.5,8.8,0.2z'
      />
      {isImplant && <path fill={color || defaultLineColor} d='M24,0v1.7l0,0l-0.3,0.8c-1.4,3.3-6,3.3-7.4,0L16,1.7V0H24z' />}
      {((isImplant && subtype === fixtureCrown) || (isCrown && subtype === metalCeramic)) && (
        lineArray?.tooth16?.map((res: any, i: number) =>
          <line stroke={stroke || defaultLineColor} key={i} fill='none' stroke-width='0.3' x1={res?.x1} x2={res?.x2} y1={res?.y1} y2={res?.y2} />
        )
      )}
      {/* start sct */}
      {isSct && <g transform="translate(2.5, 0)">
        <line x1="28.625" y1="-2" x2="24.125" y2="5" stroke={color || defaultLineColor} />
        <line x1="24.125" y1="5" x2="33.125" y2="10.5" stroke={color || defaultLineColor} />
        <line x1="33.125" y1="10.5" x2="23.625" y2="19.5" stroke={color || defaultLineColor} />
      </g>}
      {/* end sct */}
    </svg>
  );
};

const Crown17 = (finding: string, color: string, fill: string, stroke: string, subtype: string) => {
  const isImplant = finding === 'implant';
  const isCrown = finding === 'crown'
  const isSct = finding === 'sct'
  return (
    <svg
      version='1.1'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      x='0px'
      y='0px'
      width='40px'
      height='24px'
      viewBox='0 0 40 24'
    >
      <path
        fill={fill || defaultFillColor}
        stroke={stroke || defaultStrokeColor}
        strokeWidth={stroke === defaultStrokeColor ? '0.45' : '1'}
        d='M8.8,0.2h24c0,0,0.7,2.2,2.1,5.2c2.1,4.4,1.9,16-1.7,16.8c0,0-3.5,1.1-7.6-3c-5.3-4.2-10.5-1.3-12.1-0.1
	C7.7,23.5,2.1,17.2,4,11.4C5.9,5.9,9.1,2.5,8.8,0.2z'
      />
      {isImplant && <path fill={color || defaultLineColor} d='M24,0v1.7l0,0l-0.3,0.8c-1.4,3.3-6,3.3-7.4,0L16,1.7V0H24z' />}
      {((isImplant && subtype === fixtureCrown) || (isCrown && subtype === metalCeramic)) && (
        lineArray?.tooth17?.map((res: any, i: number) =>
          <line stroke={stroke || defaultLineColor} key={i} fill='none' stroke-width='0.3' x1={res?.x1} x2={res?.x2} y1={res?.y1} y2={res?.y2} />
        )
      )}
      {/* start sct */}
      {isSct && <g transform="translate(2.5, 0)">
        <line x1="28.625" y1="-2" x2="24.125" y2="5" stroke={color || defaultLineColor} />
        <line x1="24.125" y1="5" x2="33.125" y2="10.5" stroke={color || defaultLineColor} />
        <line x1="33.125" y1="10.5" x2="23.625" y2="19.5" stroke={color || defaultLineColor} />
      </g>}
      {/* end sct */}
    </svg>
  );
};

const Crown18 = (finding: string, color: string, fill: string, stroke: string, subtype: string) => {
  const isImplant = finding === 'implant';
  const isCrown = finding === 'crown'
  const isSct = finding === 'sct'
  return (
    <svg
      version='1.1'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      x='0px'
      y='0px'
      width='40px'
      height='21px'
      viewBox='0 0 40 21'
    >
      <path
        fill={fill || defaultFillColor}
        stroke={stroke || defaultStrokeColor}
        strokeWidth={stroke === defaultStrokeColor ? '0.45' : '1'}
        strokeMiterlimit={10}
        d='M8.3,0.2h21.2c0,0,11.1,12,4.1,17.7c0,0-4.1,3.6-16.5-2.2c0,0-8.2,5-11.9,3.4S8.3,0.2,8.3,0.2z'
      />
      {isImplant && <path fill={color || defaultLineColor} d='M24,0v1.735l0,0l-0.3,0.816c-1.4,3.265-6,3.265-7.4,0L16,1.735V0H24z' />}
      {((isImplant && subtype === fixtureCrown) || (isCrown && subtype === metalCeramic)) && (
        lineArray?.tooth18?.map((res: any, i: number) =>
          <line stroke={stroke || defaultLineColor} key={i} fill='none' stroke-width='0.3' x1={res?.x1} x2={res?.x2} y1={res?.y1} y2={res?.y2} />
        )
      )}
      {/* start sct */}
      {isSct && <g transform="translate(2.5, 0)">
        <line x1="28.625" y1="-2" x2="24.125" y2="5" stroke={color || defaultLineColor} />
        <line x1="24.125" y1="5" x2="33.125" y2="10.5" stroke={color || defaultLineColor} />
        <line x1="33.125" y1="10.5" x2="23.625" y2="18.5" stroke={color || defaultLineColor} />
      </g>}
      {/* end sct */}
    </svg>
  );
};

const Crown21 = (finding: string, color: string, fill: string, stroke: string, subtype: string) => {
  const isImplant = finding === 'implant';
  const isCrown = finding === 'crown'
  const isSct = finding === 'sct'
  return (
    <svg
      version='1.1'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      x='0px'
      y='0px'
      width='40px'
      height='27px'
      viewBox='0 0 40 27'
    >
      <path
        fill={fill || defaultFillColor}
        stroke={stroke || defaultStrokeColor}
        strokeWidth={stroke === defaultStrokeColor ? '0.45' : '1'}
        strokeMiterlimit={10}
        d='M29.5,2.3C33,4.8,34.2,16,34.2,20.4s-2.9,5.3-5.9,5.1s-4.3,0-4.3,0c-3.1,0.9-7.6,1-7.6,1s-2.9-0.2-4.1-0.7
	s-3.1-0.5-3.1-0.5s-0.9,0-2.3-0.2c-1.1-0.2-1.1-3-1.1-3C5.6,8,7.7,4.5,8.8,2.9c1.1-1.6,1.4-2.7,1.4-2.7h17.7
	C28,0.9,29.5,2.3,29.5,2.3z'
      />
      {isImplant && <path fill={color || defaultLineColor} d='M24,0v1.7l0,0l-0.3,0.8c-1.4,3.3-6,3.3-7.4,0L16,1.7V0H24z' />}
      {((isImplant && subtype === fixtureCrown) || (isCrown && subtype === metalCeramic)) && (
        lineArray?.tooth21?.map((res: any, i: number) =>
          <line stroke={stroke || defaultLineColor} key={i} fill='none' stroke-width='0.3' x1={res?.x1} x2={res?.x2} y1={res?.y1} y2={res?.y2} />
        )
      )}
      {/* start sct */}
      {isSct && <g transform="translate(5.7, 0)">
        <line x1="21.83" y1="-0.462891" x2="18.33" y2="6.53711" stroke={color || defaultLineColor} />
        <line x1="18.33" y1="6.53711" x2="27.33" y2="12.0371" stroke={color || defaultLineColor} />
        <line x1="27.33" y1="12.0371" x2="15.0684" y2="25.7" stroke={color || defaultLineColor} />
      </g>}
      {/* end sct */}
    </svg>
  );
};

const Crown22 = (finding: string, color: string, fill: string, stroke: string, subtype: string) => {
  const isImplant = finding === 'implant';
  const isCrown = finding === 'crown'
  const isSct = finding === 'sct'
  return (
    <svg
      version='1.1'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      x='0px'
      y='0px'
      width='40px'
      height='32px'
      viewBox='0 0 40 32'
    >
      <path
        fill={fill || defaultFillColor}
        stroke={stroke || defaultStrokeColor}
        strokeWidth={stroke === defaultStrokeColor ? '0.45' : '1'}
        strokeMiterlimit={10}
        d='M29.1,2.8c1.1,1.6,1.4,6.4,1.4,8s0.4,4.5,0.8,9.4s-3.4,2.8-5.5,3.1c-1.5,0.2-3.5,1.3-5,1.3
	c-1.2,0-3.8-0.5-4.8-0.6c-1,0-2.2,0.6-4.4,0.6C9.4,24.7,9,22.7,9,22.7C8.3,18.1,8.7,7.8,10.5,4c1.1-2.4,2.2-3,2.5-3.8
	c3.2,0,14,0,14,0C27.2,0.8,28.2,1.5,29.1,2.8z'
      />
      {isImplant && <path fill={color || defaultLineColor} d='M24,0v1.7l0,0l-0.3,0.8c-1.4,3.3-6,3.3-7.4,0L16,1.7V0H24z' />}
      {((isImplant && subtype === fixtureCrown) || (isCrown && subtype === metalCeramic)) && (
        lineArray?.tooth22?.map((res: any, i: number) =>
          <line stroke={stroke || defaultLineColor} key={i} fill='none' stroke-width='0.3' x1={res?.x1} x2={res?.x2} y1={res?.y1} y2={res?.y2} />
        )
      )}
      {/* start sct */}
      {isSct && <g>
        <line x1="27.8026" y1="-1.33398" x2="23.2342" y2="5.61916" stroke={color || defaultLineColor} />
        <line x1="23.2342" y1="5.61916" x2="30.371" y2="9.0823" stroke={color || defaultLineColor} />
        <line x1="30.371" y1="9.0823" x2="20.7266" y2="24.0087" stroke={color || defaultLineColor} />
      </g>}
      {/* end sct */}
    </svg>
  );
};

const Crown23 = (finding: string, color: string, fill: string, stroke: string, subtype: string) => {
  const isImplant = finding === 'implant';
  const isCrown = finding === 'crown'
  const isSct = finding === 'sct'
  return (
    <svg
      version='1.1'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      x='0px'
      y='0px'
      width='40px'
      height='32px'
      viewBox='0 0 40 32'
    >
      <path
        fill={fill || defaultFillColor}
        stroke={stroke || defaultStrokeColor}
        strokeWidth={stroke === defaultStrokeColor ? '0.45' : '1'}
        strokeMiterlimit={10}
        d='M34.7,18.8c-0.1,2.9-2.5,3.5-4,4.4c-1.4,0.8-1.6,2.8-2.6,4.3c-0.8,1.8-4.5,2-4.5,2c-3.1,0-6.9-5-6.9-5
	s-1.4-2.4-4.7-2.6c-2.2-0.1-3.9-2.5-4.6-3.7c-0.3-0.4-0.6-0.7-0.9-1.1c-1.3-1.5-1.3-2.9-1.3-2.9c-0.2-5,6.5-14,6.5-14h17.6
	C36.7,12,34.7,18.7,34.7,18.8z'
      />
      {isImplant && <path fill={color || defaultLineColor} d='M24,0v1.7l0,0l-0.3,0.8c-1.4,3.3-6,3.3-7.4,0L16,1.7V0H24z' />}
      {((isImplant && subtype === fixtureCrown) || (isCrown && subtype === metalCeramic)) && (
        lineArray?.tooth23?.map((res: any, i: number) =>
          <line stroke={stroke || defaultLineColor} key={i} fill='none' stroke-width='0.3' x1={res?.x1} x2={res?.x2} y1={res?.y1} y2={res?.y2} />
        )
      )}
      {/* start sct */}
      {isSct && <g transform="translate(6, 0)">
        <line x1="19.7956" y1="5" x2="27.6" y2="10.5" stroke={color || defaultLineColor} />
        <line x1="24.2956" y1="-2" x2="19.7956" y2="5" stroke={color || defaultLineColor} />
        <line x1="27.33" y1="10.0371" x2="16.0684" y2="28.7" stroke={color || defaultLineColor} />
      </g>}
      {/* start sct */}
    </svg>
  );
};

const Crown24 = (finding: string, color: string, fill: string, stroke: string, subtype: string) => {
  const isImplant = finding === 'implant';
  const isCrown = finding === 'crown'
  const isSct = finding === 'sct'
  return (
    <svg
      version='1.1'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      x='0px'
      y='0px'
      width='40px'
      height='28px'
      viewBox='0 0 40 28'
    >
      <g>
        <path
          fill={fill || defaultFillColor}
          stroke={stroke || defaultStrokeColor}
          strokeWidth={stroke === defaultStrokeColor ? '0.45' : '1'}
          strokeMiterlimit={10}
          d='M19.4,18.7c0,0-11.4-5-8.4,1c1.6,3.2,3.5,4.8,4.9,5.7c1.1,0.7,2.7,0.5,3.6-0.5l1.8-2L19.4,18.7z'
        />
        <path
          fill={fill || defaultFillColor}
          stroke={stroke || defaultStrokeColor}
          strokeWidth={stroke === defaultStrokeColor ? '0.45' : '1'}
          strokeMiterlimit={10}
          d='M31,13.5c0.5,5.3-4.4,12.1-7.2,12.1c-2.6,0-9.6-6.2-11.3-5.9c-1.1,0.2-1.1,0.9-1.1,0.9
		c-6.8-4.7,2.4-20.4,2.4-20.4h13.1C27.3,5.1,30.4,8.1,31,13.5z'
        />
      </g>
      {isImplant && <path fill={color || defaultLineColor} d='M24,0v1.7l0,0l-0.3,0.8c-1.4,3.3-6,3.3-7.4,0L16,1.7V0H24z' />}
      {((isImplant && subtype === fixtureCrown) || (isCrown && subtype === metalCeramic)) && (
        lineArray?.tooth24?.map((res: any, i: number) =>
          <line stroke={stroke || defaultLineColor} key={i} fill='none' stroke-width='0.3' x1={res?.x1} x2={res?.x2} y1={res?.y1} y2={res?.y2} />
        )
      )}
      {/* start sct */}
      {isSct && <g transform="translate(9, 0)">
        <line x1="16.4545" y1="-2" x2="11.9545" y2="5" stroke={color || defaultLineColor} />
        <line x1="11.9545" y1="5" x2="20.9545" y2="10.5" stroke={color || defaultLineColor} />
        <line x1="20.9545" y1="10.5" x2="12.77734" y2="25.1263" stroke={color || defaultLineColor} />
      </g>}
      {/* end sct */}
    </svg>
  );
};

const Crown25 = (finding: string, color: string, fill: string, stroke: string, subtype: string) => {
  const isImplant = finding === 'implant';
  const isCrown = finding === 'crown'
  const isSct = finding === 'sct'
  return (
    <svg
      version='1.1'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      x='0px'
      y='0px'
      width='40px'
      height='28px'
      viewBox='0 0 40 28'
    >
      <path
        fill={fill || defaultFillColor}
        stroke={stroke || defaultStrokeColor}
        strokeWidth={stroke === defaultStrokeColor ? '0.45' : '1'}
        strokeMiterlimit={10}
        d='M32.2,16.4c-0.6,3.7-8.6,8.9-12.3,9.2c-2.8,0.2-6.3-3.6-7.8-5.4c-0.4-0.5-1.1-0.9-1.8-1
	c-0.6-0.1-1.1-0.2-1.4-0.3c-0.6-0.2-1-0.7-1.1-1.3C6.7,8.8,13,0.2,13,0.2h14C27,0.2,32.8,12.7,32.2,16.4z'
      />
      {isImplant && <path fill={color || defaultLineColor} d='M24,0v1.7l0,0l-0.3,0.8c-1.4,3.3-6,3.3-7.4,0L16,1.7V0H24z' />}
      {((isImplant && subtype === fixtureCrown) || (isCrown && subtype === metalCeramic)) && (
        lineArray?.tooth25?.map((res: any, i: number) =>
          <line stroke={stroke || defaultLineColor} key={i} fill='none' stroke-width='0.3' x1={res?.x1} x2={res?.x2} y1={res?.y1} y2={res?.y2} />
        )
      )}
      {/* start sct */}
      {isSct && <g transform="translate(7, 0)">
        <line x1="19.56" y1="-2" x2="15.06" y2="5" stroke={color || defaultLineColor} />
        <line x1="15.06" y1="5" x2="24.06" y2="10.5" stroke={color || defaultLineColor} />
        <line x1="24.06" y1="10.5" x2="12.8828" y2="25.1263" stroke={color || defaultLineColor} />
      </g>}
      {/* end sct */}
    </svg>
  );
};

const Crown26 = (finding: string, color: string, fill: string, stroke: string, subtype: string) => {
  const isImplant = finding === 'implant';
  const isCrown = finding === 'crown'
  const isSct = finding === 'sct'
  return (
    <svg
      version='1.1'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      x='0px'
      y='0px'
      width='40px'
      height='24px'
      viewBox='0 0 40 24'
    >
      <path
        fill={fill || defaultFillColor}
        stroke={stroke || defaultStrokeColor}
        strokeWidth={stroke === defaultStrokeColor ? '0.45' : '1'}
        strokeMiterlimit={10}
        d='M35.9,11.4c1.9,5.8-3.7,12.1-9.5,7.7c-1.6-1.2-6.8-4.1-12.1,0.1c-4.1,4.1-7.6,3-7.6,3C3.1,21.4,2.9,9.8,5,5.4
	c1.4-3,2.1-5.2,2.1-5.2h24C30.8,2.5,34,5.9,35.9,11.4z'
      />
      {isImplant && <path fill={color || defaultLineColor} d='M24,0v1.7l0,0l-0.3,0.8c-1.4,3.3-6,3.3-7.4,0L16,1.7V0H24z' />}
      {((isImplant && subtype === fixtureCrown) || (isCrown && subtype === metalCeramic)) && (
        lineArray?.tooth26?.map((res: any, i: number) =>
          <line stroke={stroke || defaultLineColor} key={i} fill='none' stroke-width='0.3' x1={res?.x1} x2={res?.x2} y1={res?.y1} y2={res?.y2} />
        )
      )}

      {/* start sct */}
      {isSct && <g transform="translate(4, 0)">
        <line x1="26.625" y1="-2" x2="22.125" y2="5" stroke={color || defaultLineColor} />
        <line x1="22.125" y1="5" x2="31.125" y2="10.5" stroke={color || defaultLineColor} />
        <line x1="31.125" y1="10.5" x2="21.625" y2="18.5" stroke={color || defaultLineColor} />
      </g>}
      {/* end sct */}
    </svg>
  );
};

const Crown27 = (finding: string, color: string, fill: string, stroke: string, subtype: string) => {
  const isImplant = finding === 'implant';
  const isCrown = finding === 'crown'
  const isSct = finding === 'sct'
  return (
    <svg
      version='1.1'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      x='0px'
      y='0px'
      width='40px'
      height='24px'
      viewBox='0 0 40 24'
    >
      <path
        fill={fill || defaultFillColor}
        stroke={stroke || defaultStrokeColor}
        strokeWidth={stroke === defaultStrokeColor ? '0.45' : '1'}
        strokeMiterlimit={10}
        d='M35.9,11.4c1.9,5.8-3.7,12.1-9.5,7.7c-1.6-1.2-6.8-4.1-12.1,0.1c-4.1,4.1-7.6,3-7.6,3C3.1,21.4,2.9,9.8,5,5.4
	c1.4-3,2.1-5.2,2.1-5.2h24C30.8,2.5,34,5.9,35.9,11.4z'
      />
      {isImplant && <path fill={color || defaultLineColor} d='M24,0v1.7l0,0l-0.3,0.8c-1.4,3.3-6,3.3-7.4,0L16,1.7V0H24z' />}

      {<g>
        {((isImplant && subtype === fixtureCrown) || (isCrown && subtype === metalCeramic)) && lineArray?.tooth27?.map((res: any, i: number) =>
          <line stroke={stroke || defaultLineColor} key={i} fill='none' stroke-width='0.3' x1={res?.x1} x2={res?.x2} y1={res?.y1} y2={res?.y2} />
        )}

      </g>}
      {/* start sct */}
      {isSct && <g transform="translate(4, 0)">
        <line x1="26.625" y1="-2" x2="22.125" y2="5" stroke={color || defaultLineColor} />
        <line x1="22.125" y1="5" x2="31.125" y2="10.5" stroke={color || defaultLineColor} />
        <line x1="31.125" y1="10.5" x2="21.625" y2="18.5" stroke={color || defaultLineColor} />
      </g>}
      {/* end sct */}
    </svg>
  );
};

const Crown28 = (finding: string, color: string, fill: string, stroke: string, subtype: string) => {
  const isImplant = finding === 'implant';
  const isCrown = finding === 'crown'
  const isSct = finding === 'sct'
  return (
    <svg
      version='1.1'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      x='0px'
      y='0px'
      width='40px'
      height='21px'
      viewBox='0 0 40 21'
    >
      <path
        fill={fill || defaultFillColor}
        stroke={stroke || defaultStrokeColor}
        strokeWidth={stroke === defaultStrokeColor ? '0.45' : '1'}
        strokeMiterlimit={10}
        d='M34.8,19.1c-3.7,1.6-11.9-3.4-11.9-3.4c-12.4,5.8-16.5,2.2-16.5,2.2c-7-5.7,4.1-17.7,4.1-17.7h21.2
	C31.7,0.2,38.5,17.5,34.8,19.1z'
      />
      {isImplant && <path fill={color || defaultLineColor} d='M24,0v1.7l0,0l-0.3,0.8c-1.4,3.3-6,3.3-7.4,0L16,1.7V0H24z' />}

      {((isImplant && subtype === fixtureCrown) || (isCrown && subtype === metalCeramic)) && lineArray?.tooth28?.map((res: any, i: number) =>
        <line stroke={stroke || defaultLineColor} key={i} fill='none' stroke-width='0.3' x1={res?.x1} x2={res?.x2} y1={res?.y1} y2={res?.y2} />
      )}
      {/* start sct */}
      {isSct && <g transform="translate(3, 0)">
        <line x1="26.625" y1="-2" x2="22.125" y2="5" stroke={color || defaultLineColor} />
        <line x1="22.125" y1="5" x2="31.125" y2="10.5" stroke={color || defaultLineColor} />
        <line x1="31.125" y1="10.5" x2="21.625" y2="17" stroke={color || defaultLineColor} />
      </g>}
      {/* end sct */}
    </svg>
  );
};

const Crown31 = (finding: string, color: string, fill: string, stroke: string, subtype: string) => {
  const isImplant = finding === 'implant';
  const isCrown = finding === 'crown'
  const isSct = finding === 'sct'
  return (
    <svg
      version='1.1'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      x='0px'
      y='0px'
      width='40px'
      height='27px'
      viewBox='0 0 40 27'
    >
      <path
        fill={fill || defaultFillColor}
        stroke={stroke || defaultStrokeColor}
        strokeWidth={stroke === defaultStrokeColor ? '0.45' : '1'}
        strokeMiterlimit={10}
        d='M12.9,26.8H26c0,0,0.7-2.1,1.8-3s2.9-9.4,2.8-16.4c0,0,0.6-5.8-6.2-4.4c0,0-2.3,0.1-3.9-0.6
	c-1.9-0.8-5.2-0.1-5.2-0.1S12.6,3.2,11.5,3c-1.1-0.1-2.1,0.8-2.1,3.1C9.4,8.5,8.6,20.4,12.9,26.8z'
      />
      {isImplant && <path fill={color || defaultLineColor} d='M16,27v-1.7l0.3-0.8c1.4-3.3,6-3.3,7.4,0l0.3,0.8l0,0V27H16z' />}
      {((isImplant && subtype === fixtureCrown) || (isCrown && subtype === metalCeramic)) && lineArray?.tooth31?.map((res: any, i: number) =>
        <line stroke={stroke || defaultLineColor} key={i} fill='none' stroke-width='0.3' x1={res?.x1} x2={res?.x2} y1={res?.y1} y2={res?.y2} />
      )}
      {/* start sct */}
      {isSct && <g transform="translate(19,2)">
        <line x1="5.5" y1="24.5" x2="1.1861" y2="18.8884" stroke={color || defaultLineColor} />
        <line x1="1.1861" y1="18.8884" x2="10.3229" y2="13.4253" stroke={color || defaultLineColor} />
        <line x1="10.3229" y1="13.4253" x2="1.1861" y2="0.5" stroke={color || defaultLineColor} />
      </g>}
      {/* end sct */}
    </svg>
  );
};

const Crown32 = (finding: string, color: string, fill: string, stroke: string, subtype: string) => {
  const isImplant = finding === 'implant';
  const isCrown = finding === 'crown'
  const isSct = finding === 'sct'
  return (
    <svg
      version='1.1'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      x='0px'
      y='0px'
      width='40px'
      height='32px'
      viewBox='0 0 40 32'
    >
      <path
        fill={fill || defaultFillColor}
        stroke={stroke || defaultStrokeColor}
        strokeWidth={stroke === defaultStrokeColor ? '0.45' : '1'}
        strokeMiterlimit={10}
        d='M27,31.8c0,0-10.8,0-14,0c-0.3-0.8-1.4-1.4-2.5-3.8C8.7,24.2,8.3,13.9,9,9.3c0,0,0.4-2,2.6-1.9
	C13.8,7.4,15,8,16,8c1-0.1,3.6-0.6,4.8-0.6c1.5,0,3.5,1.1,5,1.3c2.1,0.3,5.9-1.8,5.5,3.1s-0.8,7.8-0.8,9.4s-0.3,6.4-1.4,8
	C28.2,30.5,27.2,31.2,27,31.8z'
      />
      {isImplant && <path fill={color || defaultLineColor} d='M16,32v-1.7l0.3-0.8c1.4-3.3,6-3.3,7.4,0l0.3,0.8l0,0V32H16z' />}
      {((isImplant && subtype === fixtureCrown) || (isCrown && subtype === metalCeramic)) && lineArray?.tooth32?.map((res: any, i: number) =>
        <line stroke={stroke || defaultLineColor} key={i} fill='none' stroke-width='0.3' x1={res?.x1} x2={res?.x2} y1={res?.y1} y2={res?.y2} />
      )}
      {/* start sct */}
      {isSct && <g transform="translate(20,7)">
        <line x1="5.5" y1="24.5" x2="1.1861" y2="18.8884" stroke={color || defaultLineColor} />
        <line x1="1.1861" y1="18.8884" x2="10.3229" y2="13.4253" stroke={color || defaultLineColor} />
        <line x1="10.3229" y1="13.4253" x2="1.1861" y2="0.5" stroke={color || defaultLineColor} />
      </g>}

      {/* end sct */}
    </svg >
  );
};

const Crown33 = (finding: string, color: string, fill: string, stroke: string, subtype: string) => {
  const isImplant = finding === 'implant';
  const isCrown = finding === 'crown'
  const isSct = finding === 'sct'
  return (
    <svg
      version='1.1'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      x='0px'
      y='0px'
      width='40px'
      height='32px'
      viewBox='0 0 40 32'
    >
      <path
        fill={fill || defaultFillColor}
        stroke={stroke || defaultStrokeColor}
        strokeWidth={stroke === defaultStrokeColor ? '0.45' : '1'}
        strokeMiterlimit={10}
        d='M29.3,31.8H11.7c0,0-6.7-9-6.5-14c0,0,0-1.4,1.3-2.9c0.3-0.4,0.6-0.7,0.9-1.1c0.7-1.2,2.4-3.6,4.6-3.7
	c3.3-0.2,4.7-2.6,4.7-2.6s3.8-5,6.9-5c0,0,3.7,0.2,4.5,2c1,1.5,1.2,3.5,2.6,4.3c1.5,0.9,3.9,1.5,4,4.4C34.7,13.3,36.7,20,29.3,31.8z
	'
      />
      {isImplant && <path fill={color || defaultLineColor} d='M16,32v-1.7l0.3-0.8c1.4-3.3,6-3.3,7.4,0l0.3,0.8l0,0V32H16z' />}
      {((isImplant && subtype === fixtureCrown) || (isCrown && subtype === metalCeramic)) && (
        lineArray?.tooth33?.map((res: any, i: number) =>
          <line stroke={stroke || defaultLineColor} key={i} fill='none' stroke-width='0.3' x1={res?.x1} x2={res?.x2} y1={res?.y1} y2={res?.y2} />
        )
      )}
      {/* start sct */}
      {isSct && <g transform="translate(20,7)">
        <line x1="5.5" y1="24.5" x2="1.1861" y2="18.8884" stroke={color || defaultLineColor} />
        <line x1="1.1861" y1="18.8884" x2="10.3229" y2="13.4253" stroke={color || defaultLineColor} />
        <line x1="10.3229" y1="13.4253" x2="1.1861" y2="-3.6" stroke={color || defaultLineColor} />
      </g>}
      {/* end sct */}
    </svg>
  );
};

const Crown34 = (finding: string, color: string, fill: string, stroke: string, subtype: string) => {
  const isImplant = finding === 'implant';
  const isCrown = finding === 'crown'
  const isSct = finding === 'sct'
  return (
    <svg
      version='1.1'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      x='0px'
      y='0px'
      width='40px'
      height='28px'
      viewBox='0 0 40 28'
    >
      <path
        fill={fill || defaultFillColor}
        stroke={stroke || defaultStrokeColor}
        strokeWidth={stroke === defaultStrokeColor ? '0.45' : '1'}
        strokeMiterlimit={10}
        d='M27,27.8H13c0,0-6.3-8.6-5.2-17.4c0.1-0.6,0.5-1.1,1.1-1.3C9.2,9,9.7,8.9,10.3,8.8c0.7-0.1,1.4-0.5,1.8-1
	c1.5-1.8,5-5.6,7.8-5.4c3.7,0.3,11.7,5.5,12.3,9.2C32.8,15.3,27,27.8,27,27.8z'
      />
      {isImplant && <path fill={color || defaultLineColor} d='M16,28v-1.7l0.3-0.8c1.4-3.3,6-3.3,7.4,0l0.3,0.8l0,0V28H16z' />}
      {((isImplant && subtype === fixtureCrown) || (isCrown && subtype === metalCeramic)) && (
        lineArray?.tooth34?.map((res: any, i: number) =>
          <line stroke={stroke || defaultLineColor} key={i} fill='none' stroke-width='0.3' x1={res?.x1} x2={res?.x2} y1={res?.y1} y2={res?.y2} />
        )
      )}
      {/* start sct */}
      {isSct && <g transform="translate(20,3)">
        <line x1="5.5" y1="24.5" x2="1.1861" y2="18.8884" stroke={color || defaultLineColor} />
        <line x1="1.1861" y1="18.8884" x2="10.3229" y2="13.4253" stroke={color || defaultLineColor} />
        <line x1="10.3229" y1="13.4253" x2="1.1861" y2="0" stroke={color || defaultLineColor} />
      </g>}
      {/* end sct */}
    </svg>

  );
};

const Crown35 = (finding: string, color: string, fill: string, stroke: string, subtype: string) => {
  const isImplant = finding === 'implant';
  const isCrown = finding === 'crown'
  const isSct = finding === 'sct'
  return (
    <svg
      version='1.1'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      x='0px'
      y='0px'
      width='40px'
      height='28px'
      viewBox='0 0 40 28'
    >
      <path
        fill={fill || defaultFillColor}
        stroke={stroke || defaultStrokeColor}
        strokeWidth={stroke === defaultStrokeColor ? '0.45' : '1'}
        strokeMiterlimit={10}
        d='M27,27.8H13c0,0-6.3-8.6-5.2-17.4c0.1-0.6,0.5-1.1,1.1-1.3C9.2,9,9.7,8.9,10.3,8.8c0.7-0.1,1.4-0.5,1.8-1
	c1.5-1.8,5-5.6,7.8-5.4c3.7,0.3,11.7,5.5,12.3,9.2C32.8,15.3,27,27.8,27,27.8z'
      />
      {isImplant && <path fill={color || defaultLineColor} d='M16,28v-1.7l0.3-0.8c1.4-3.3,6-3.3,7.4,0l0.3,0.8l0,0V28H16z' />}
      {((isImplant && subtype === fixtureCrown) || (isCrown && subtype === metalCeramic)) && (
        lineArray?.tooth35?.map((res: any, i: number) =>
          <line stroke={stroke || defaultLineColor} key={i} fill='none' stroke-width='0.3' x1={res?.x1} x2={res?.x2} y1={res?.y1} y2={res?.y2} />
        )
      )}
      {/* start sct */}
      {isSct && <g transform="translate(20,3)">
        <line x1="5.5" y1="24.5" x2="1.1861" y2="18.8884" stroke={color || defaultLineColor} />
        <line x1="1.1861" y1="18.8884" x2="10.3229" y2="13.4253" stroke={color || defaultLineColor} />
        <line x1="10.3229" y1="13.4253" x2="1.1861" y2="0" stroke={color || defaultLineColor} />
      </g>}
      {/* end sct */}
    </svg>
  );
};

const Crown36 = (finding: string, color: string, fill: string, stroke: string, subtype: string) => {
  const isImplant = finding === 'implant';
  const isCrown = finding === 'crown'
  const isSct = finding === 'sct'
  return (
    <svg
      version='1.1'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      x='0px'
      y='0px'
      width='40px'
      height='24px'
      viewBox='0 0 40 24'
    >
      <path
        fill={fill || defaultFillColor}
        stroke={stroke || defaultStrokeColor}
        strokeWidth={stroke === defaultStrokeColor ? '0.45' : '1'}
        strokeMiterlimit={10}
        d='M31.1,23.8h-24c0,0-0.7-2.2-2.1-5.2c-2.1-4.4-1.9-16,1.7-16.8c0,0,3.5-1.1,7.6,3C19.6,9,24.8,6.1,26.4,4.9
	c5.8-4.4,11.4,1.9,9.5,7.7C34,18.1,30.8,21.5,31.1,23.8z'
      />
      {isImplant && <path fill={color || defaultLineColor} d='M16,24v-1.7l0.3-0.8c1.4-3.3,6-3.3,7.4,0l0.3,0.8l0,0V24H16z' />}
      {((isImplant && subtype === fixtureCrown) || (isCrown && subtype === metalCeramic)) && (
        lineArray?.tooth36?.map((res: any, i: number) =>
          <line stroke={stroke || defaultLineColor} key={i} fill='none' stroke-width='0.3' x1={res?.x1} x2={res?.x2} y1={res?.y1} y2={res?.y2} />
        )
      )}
      {/* start sct */}
      {isSct && <g transform="translate(25,0)">
        <line x1="4.5" y1="23.5" x2="1.1861" y2="18.8884" stroke={color || defaultLineColor} />
        <line x1="1.1861" y1="18.8884" x2="10.3229" y2="13.4253" stroke={color || defaultLineColor} />
        <line x1="10.3229" y1="13.4253" x2="1.1861" y2="5" stroke={color || defaultLineColor} />
      </g>}
      {/* end sct */}
    </svg>
  );
};
const Crown37 = (finding: string, color: string, fill: string, stroke: string, subtype: string) => {
  const isImplant = finding === 'implant';
  const isCrown = finding === 'crown'
  const isSct = finding === 'sct'
  return (
    <svg
      version='1.1'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      x='0px'
      y='0px'
      width='40px'
      height='24px'
      viewBox='0 0 40 24'
    >
      <path
        fill={fill || defaultFillColor}
        stroke={stroke || defaultStrokeColor}
        strokeWidth={stroke === defaultStrokeColor ? '0.45' : '1'}
        strokeMiterlimit={10}
        d='M31.1,23.8h-24c0,0-0.7-2.2-2.1-5.2c-2.1-4.4-1.9-16,1.7-16.8c0,0,3.5-1.1,7.6,3C19.6,9,24.8,6.1,26.4,4.9
	c5.8-4.4,11.4,1.9,9.5,7.7C34,18.1,30.8,21.5,31.1,23.8z'
      />
      {isImplant && <path fill={color || defaultLineColor} d='M16,24v-1.7l0.3-0.8c1.4-3.3,6-3.3,7.4,0l0.3,0.8l0,0V24H16z' />}
      {((isImplant && subtype === fixtureCrown) || (isCrown && subtype === metalCeramic)) && (
        lineArray?.tooth37?.map((res: any, i: number) =>
          <line stroke={stroke || defaultLineColor} key={i} fill='none' stroke-width='0.3' x1={res?.x1} x2={res?.x2} y1={res?.y1} y2={res?.y2} />
        )
      )}
      {/* start sct */}
      {isSct && <g transform="translate(25,0)">
        <line x1="4.5" y1="23.5" x2="1.1861" y2="18.8884" stroke={color || defaultLineColor} />
        <line x1="1.1861" y1="18.8884" x2="10.3229" y2="13.4253" stroke={color || defaultLineColor} />
        <line x1="10.3229" y1="13.4253" x2="1.1861" y2="5" stroke={color || defaultLineColor} />
      </g>}
      {/* end sct */}
    </svg>
  );
};

const Crown38 = (finding: string, color: string, fill: string, stroke: string, subtype: string) => {
  const isImplant = finding === 'implant';
  const isCrown = finding === 'crown'
  const isSct = finding === 'sct'
  return (
    <svg
      version='1.1'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      x='0px'
      y='0px'
      width='40px'
      height='21px'
      viewBox='0 0 40 21'
    >
      <path
        fill={fill || defaultFillColor}
        stroke={stroke || defaultStrokeColor}
        strokeWidth={stroke === defaultStrokeColor ? '0.45' : '1'}
        strokeMiterlimit={10}
        d='M31.7,20.8H10.5c0,0-11.1-12-4.1-17.7c0,0,4.1-3.6,16.5,2.2c0,0,8.2-5,11.9-3.4S31.7,20.8,31.7,20.8z'
      />
      {isImplant && <path fill={color || defaultLineColor} d='M16,21v-1.7l0.3-0.8c1.4-3.3,6-3.3,7.4,0l0.3,0.8l0,0V21H16z' />}
      {((isImplant && subtype === fixtureCrown) || (isCrown && subtype === metalCeramic)) && (
        lineArray?.tooth38?.map((res: any, i: number) =>
          <line stroke={stroke || defaultLineColor} key={i} fill='none' stroke-width='0.3' x1={res?.x1} x2={res?.x2} y1={res?.y1} y2={res?.y2} />
        )
      )}
      {/* start sct */}
      {isSct && <g transform="translate(24,0)">
        <line x1="4.5" y1="20.5" x2="1.1861" y2="18.8884" stroke={color || defaultLineColor} />
        <line x1="1.1861" y1="18.8884" x2="10.3229" y2="13.4253" stroke={color || defaultLineColor} />
        <line x1="10.3229" y1="13.4253" x2="2.1861" y2="4" stroke={color || defaultLineColor} />
      </g>}
      {/* end sct */}
    </svg>
  );
};

const Crown41 = (finding: string, color: string, fill: string, stroke: string, subtype: string) => {
  const isImplant = finding === 'implant';
  const isCrown = finding === 'crown'
  const isSct = finding === 'sct'
  return (
    <svg
      version='1.1'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      x='0px'
      y='0px'
      width='40px'
      height='27px'
      viewBox='0 0 40 27'
    >
      <path
        fill={fill || defaultFillColor}
        stroke={stroke || defaultStrokeColor}
        strokeWidth={stroke === defaultStrokeColor ? '0.45' : '1'}
        strokeMiterlimit={10}
        d='M30.6,6.1c0-2.3-1-3.2-2.1-3.1c-1.1,0.2-3.8-0.7-3.8-0.7s-3.3-0.7-5.2,0.1C17.9,3.1,15.6,3,15.6,3
	C8.8,1.6,9.4,7.4,9.4,7.4c-0.1,7,1.7,15.5,2.8,16.4s1.8,3,1.8,3h13.1C31.4,20.4,30.6,8.5,30.6,6.1z'
      />
      {isImplant && <path fill={color || defaultLineColor} d='M16,27v-1.7l0,0l0.3-0.8c1.4-3.3,6-3.3,7.4,0l0.3,0.8V27H16z' />}

      {((isImplant && subtype === fixtureCrown) || (isCrown && subtype === metalCeramic)) && (
        lineArray?.tooth41?.map((res: any, i: number) =>
          <line stroke={stroke || defaultLineColor} key={i} fill='none' stroke-width='0.3' x1={res?.x1} x2={res?.x2} y1={res?.y1} y2={res?.y2} />
        )
      )}
      {/* start sct */}
      {isSct && <g transform="translate(19,2)">
        <line x1="5.5" y1="24.5" x2="1.1861" y2="18.8884" stroke={color || defaultLineColor} />
        <line x1="1.1861" y1="18.8884" x2="10.3229" y2="13.4253" stroke={color || defaultLineColor} />
        <line x1="10.3229" y1="13.4253" x2="1.1861" y2="0.5" stroke={color || defaultLineColor} />

      </g>}
      {/* end sct */}

    </svg>
  );
};

const Crown42 = (finding: string, color: string, fill: string, stroke: string, subtype: string) => {
  const isImplant = finding === 'implant';
  const isCrown = finding === 'crown'
  const isSct = finding === 'sct'
  return (
    <svg
      version='1.1'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      x='0px'
      y='0px'
      width='40px'
      height='32px'
      viewBox='0 0 40 32'
    >
      <path
        fill={fill || defaultFillColor}
        stroke={stroke || defaultStrokeColor}
        strokeWidth={stroke === defaultStrokeColor ? '0.45' : '1'}
        strokeMiterlimit={10}
        d='M10.9,29.2c-1.1-1.6-1.4-6.4-1.4-8s-0.4-4.5-0.8-9.4s3.4-2.8,5.5-3.1c1.5-0.2,3.5-1.3,5-1.3
	C20.4,7.4,23,7.9,24,8c1,0,2.2-0.6,4.4-0.6C30.6,7.3,31,9.3,31,9.3c0.7,4.6,0.3,14.9-1.5,18.7c-1.1,2.4-2.2,3-2.5,3.8
	c-3.2,0-14,0-14,0C12.8,31.2,11.8,30.5,10.9,29.2z'
      />
      {isImplant && <path fill={color || defaultLineColor} d='M16,32v-1.7l0,0l0.3-0.8c1.4-3.3,6-3.3,7.4,0l0.3,0.8V32H16z' />}
      {((isImplant && subtype === fixtureCrown) || (isCrown && subtype === metalCeramic)) && (
        lineArray?.tooth42?.map((res: any, i: number) =>
          <line stroke={stroke || defaultLineColor} key={i} fill='none' stroke-width='0.3' x1={res?.x1} x2={res?.x2} y1={res?.y1} y2={res?.y2} />
        )
      )}
      {/* start sct */}
      {isSct && <g transform="translate(20,7)">
        <line x1="5.5" y1="24.5" x2="1.1861" y2="18.8884" stroke={color || defaultLineColor} />
        <line x1="1.1861" y1="18.8884" x2="10.3229" y2="13.4253" stroke={color || defaultLineColor} />
        <line x1="10.3229" y1="13.4253" x2="1.1861" y2="0.5" stroke={color || defaultLineColor} />
      </g>}
      {/* end sct */}
    </svg>
  );
};

const Crown43 = (finding: string, color: string, fill: string, stroke: string, subtype: string) => {
  const isImplant = finding === 'implant';
  const isCrown = finding === 'crown'
  const isSct = finding === 'sct'
  return (
    <svg
      version='1.1'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      x='0px'
      y='0px'
      width='40px'
      height='32px'
      viewBox='0 0 40 32'
    >
      <path
        fill={fill || defaultFillColor}
        stroke={stroke || defaultStrokeColor}
        strokeWidth={stroke === defaultStrokeColor ? '0.45' : '1'}
        strokeMiterlimit={10}
        d='M5.4,13.2c0.1-2.9,2.5-3.5,4-4.4C10.8,8,11,6,12,4.5c0.8-1.8,4.5-2,4.5-2c3.1,0,6.9,5,6.9,5s1.4,2.4,4.7,2.6
	c2.2,0.1,3.9,2.5,4.6,3.7c0.3,0.4,0.6,0.7,0.9,1.1c1.3,1.5,1.3,2.9,1.3,2.9c0.2,5-6.5,14-6.5,14H10.8C3.4,20,5.4,13.3,5.4,13.2z'
      />
      {isImplant && <path fill={color || defaultLineColor} d='M16.1,32v-1.7l0,0l0.3-0.8c1.4-3.3,6-3.3,7.4,0l0.3,0.8V32H16.1z' />}
      {((isImplant && subtype === fixtureCrown) || (isCrown && subtype === metalCeramic)) && (
        lineArray?.tooth43?.map((res: any, i: number) =>
          <line stroke={stroke || defaultLineColor} key={i} fill='none' stroke-width='0.3' x1={res?.x1} x2={res?.x2} y1={res?.y1} y2={res?.y2} />
        )
      )}
      {/* start sct */}
      {isSct && <g transform="translate(20,7)">
        <line x1="5.5" y1="24.5" x2="1.1861" y2="18.8884" stroke={color || defaultLineColor} />
        <line x1="1.1861" y1="18.8884" x2="10.3229" y2="13.4253" stroke={color || defaultLineColor} />
        <line x1="10.3229" y1="13.4253" x2="1.1861" y2="-1.6" stroke={color || defaultLineColor} />
      </g>}
      {/* end sct */}
    </svg>
  );
};
const Crown44 = (finding: string, color: string, fill: string, stroke: string, subtype: string) => {
  const isImplant = finding === 'implant';
  const isCrown = finding === 'crown'
  const isSct = finding === 'sct'
  return (
    <svg
      version='1.1'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      x='0px'
      y='0px'
      width='40px'
      height='28px'
      viewBox='0 0 40 28'
    >
      <path
        fill={fill || defaultFillColor}
        stroke={stroke || defaultStrokeColor}
        strokeWidth={stroke === defaultStrokeColor ? '0.45' : '1'}
        strokeMiterlimit={10}
        d='M7.7,11.6C8.3,7.9,16.3,2.7,20,2.4c2.8-0.2,6.3,3.6,7.8,5.4c0.4,0.5,1.1,0.9,1.8,1C30.2,8.9,30.7,9,31,9.1
	c0.6,0.2,1,0.7,1.1,1.3c1.1,8.8-5.2,17.4-5.2,17.4h-14C12.9,27.8,7.1,15.3,7.7,11.6z'
      />
      {isImplant && <path fill={color || defaultLineColor} d='M15.9,28v-1.7l0,0l0.3-0.8c1.4-3.3,6-3.3,7.4,0l0.3,0.8V28H15.9z' />}
      {((isImplant && subtype === fixtureCrown) || (isCrown && subtype === metalCeramic)) && (
        lineArray?.tooth44?.map((res: any, i: number) =>
          <line stroke={stroke || defaultLineColor} key={i} fill='none' stroke-width='0.3' x1={res?.x1} x2={res?.x2} y1={res?.y1} y2={res?.y2} />
        )
      )}
      {/* start sct */}
      {isSct && <g transform="translate(20,3)">
        <line x1="5.5" y1="24.5" x2="1.1861" y2="18.8884" stroke={color || defaultLineColor} />
        <line x1="1.1861" y1="18.8884" x2="10.3229" y2="13.4253" stroke={color || defaultLineColor} />
        <line x1="10.3229" y1="13.4253" x2="1.1861" y2="0" stroke={color || defaultLineColor} />
      </g>}
      {/* end sct */}
    </svg>
  );
};
const Crown45 = (finding: string, color: string, fill: string, stroke: string, subtype: string) => {
  const isImplant = finding === 'implant';
  const isCrown = finding === 'crown'
  const isSct = finding === 'sct'
  return (
    <svg
      version='1.1'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      x='0px'
      y='0px'
      width='40px'
      height='28px'
      viewBox='0 0 40 28'
    >
      <path
        fill={fill || defaultFillColor}
        stroke={stroke || defaultStrokeColor}
        strokeWidth={stroke === defaultStrokeColor ? '0.45' : '1'}
        strokeMiterlimit={10}
        d='M7.7,11.6C8.3,7.9,16.3,2.7,20,2.4c2.8-0.2,6.3,3.6,7.8,5.4c0.4,0.5,1.1,0.9,1.8,1C30.2,8.9,30.7,9,31,9.1
	c0.6,0.2,1,0.7,1.1,1.3c1.1,8.8-5.2,17.4-5.2,17.4h-14C12.9,27.8,7.1,15.3,7.7,11.6z'
      />
      {isImplant && <path fill={color || defaultLineColor} d='M15.9,28v-1.7l0,0l0.3-0.8c1.4-3.3,6-3.3,7.4,0l0.3,0.8V28H15.9z' />}
      {((isImplant && subtype === fixtureCrown) || (isCrown && subtype === metalCeramic)) && (
        lineArray?.tooth45?.map((res: any, i: number) =>
          <line stroke={stroke || defaultLineColor} key={i} fill='none' stroke-width='0.3' x1={res?.x1} x2={res?.x2} y1={res?.y1} y2={res?.y2} />
        )
      )}
      {/* start sct */}
      {isSct && <g transform="translate(20,3)">
        <line x1="5.5" y1="24.5" x2="1.1861" y2="18.8884" stroke={color || defaultLineColor} />
        <line x1="1.1861" y1="18.8884" x2="10.3229" y2="13.4253" stroke={color || defaultLineColor} />
        <line x1="10.3229" y1="13.4253" x2="1.1861" y2="0" stroke={color || defaultLineColor} />
      </g>}
      {/* end sct */}
    </svg>
  );
};

const Crown46 = (finding: string, color: string, fill: string, stroke: string, subtype: string) => {
  const isImplant = finding === 'implant';
  const isCrown = finding === 'crown'
  const isSct = finding === 'sct'
  return (
    <svg
      version='1.1'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      x='0px'
      y='0px'
      width='40px'
      height='24px'
      viewBox='0 0 40 24'
    >
      <path
        fill={fill || defaultFillColor}
        stroke={stroke || defaultStrokeColor}
        strokeWidth={stroke === defaultStrokeColor ? '0.45' : '1'}
        strokeMiterlimit={10}
        d='M4,12.6C2.1,6.8,7.7,0.5,13.5,4.9c1.6,1.2,6.8,4.1,12.1-0.1c4.1-4.1,7.6-3,7.6-3c3.6,0.8,3.8,12.4,1.7,16.8
	c-1.4,3-2.1,5.2-2.1,5.2h-24C9.1,21.5,5.9,18.1,4,12.6z'
      />
      {isImplant && <path fill={color || defaultLineColor} d='M15.9,24v-1.7l0,0l0.3-0.8c1.4-3.3,6-3.3,7.4,0l0.3,0.8V24H15.9z' />}
      {((isImplant && subtype === fixtureCrown) || (isCrown && subtype === metalCeramic)) && (
        lineArray?.tooth46?.map((res: any, i: number) =>
          <line stroke={stroke || defaultLineColor} key={i} fill='none' stroke-width='0.3' x1={res?.x1} x2={res?.x2} y1={res?.y1} y2={res?.y2} />
        )
      )}
      {/* start sct */}
      {isSct && <g transform="translate(25,0)">
        <line x1="4.5" y1="23.5" x2="1.1861" y2="18.8884" stroke={color || defaultLineColor} />
        <line x1="1.1861" y1="18.8884" x2="10.3229" y2="13.4253" stroke={color || defaultLineColor} />
        <line x1="10.3229" y1="13.4253" x2="1.1861" y2="5" stroke={color || defaultLineColor} />
      </g>}
      {/* end sct */}
    </svg>
  );
};

const Crown47 = (finding: string, color: string, fill: string, stroke: string, subtype: string) => {
  const isImplant = finding === 'implant';
  const isCrown = finding === 'crown'
  const isSct = finding === 'sct'
  return (
    <svg
      version='1.1'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      x='0px'
      y='0px'
      width='40px'
      height='24px'
      viewBox='0 0 40 24'
    >
      <path
        fill={fill || defaultFillColor}
        stroke={stroke || defaultStrokeColor}
        strokeWidth={stroke === defaultStrokeColor ? '0.45' : '1'}
        strokeMiterlimit={10}
        d='M4,12.6C2.1,6.8,7.7,0.5,13.5,4.9c1.6,1.2,6.8,4.1,12.1-0.1c4.1-4.1,7.6-3,7.6-3c3.6,0.8,3.8,12.4,1.7,16.8
	c-1.4,3-2.1,5.2-2.1,5.2h-24C9.1,21.5,5.9,18.1,4,12.6z'
      />
      {isImplant && <path fill={color || defaultLineColor} d='M15.9,24v-1.7l0,0l0.3-0.8c1.4-3.3,6-3.3,7.4,0l0.3,0.8V24H15.9z' />}
      {((isImplant && subtype === fixtureCrown) || (isCrown && subtype === metalCeramic)) && (
        lineArray?.tooth47?.map((res: any, i: number) =>
          <line stroke={stroke || defaultLineColor} key={i} fill='none' stroke-width='0.3' x1={res?.x1} x2={res?.x2} y1={res?.y1} y2={res?.y2} />
        )
      )}
      {/* start sct */}
      {isSct && <g transform="translate(25,0)">
        <line x1="4.5" y1="23.5" x2="1.1861" y2="18.8884" stroke={color || defaultLineColor} />
        <line x1="1.1861" y1="18.8884" x2="10.3229" y2="13.4253" stroke={color || defaultLineColor} />
        <line x1="10.3229" y1="13.4253" x2="1.1861" y2="5" stroke={color || defaultLineColor} />
      </g>}
      {/* end sct */}
    </svg>
  );
};

const Crown48 = (finding: string, color: string, fill: string, stroke: string, subtype: string) => {
  const isImplant = finding === 'implant';
  const isCrown = finding === 'crown'
  const isSct = finding === 'sct'
  return (
    <svg
      version='1.1'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      x='0px'
      y='0px'
      width='40px'
      height='21px'
      viewBox='0 0 40 21'
    >
      <path
        fill={fill || defaultFillColor}
        stroke={stroke || defaultStrokeColor}
        strokeWidth={stroke === defaultStrokeColor ? '0.45' : '1'}
        strokeMiterlimit={10}
        d='M5.2,1.9c3.7-1.6,11.9,3.4,11.9,3.4c12.4-5.8,16.5-2.2,16.5-2.2c7,5.7-4.1,17.7-4.1,17.7H8.3
	C8.3,20.8,1.5,3.5,5.2,1.9z'
      />
      {isImplant && <path fill={color || defaultLineColor} d='M15.9,21v-1.7l0,0l0.3-0.8c1.4-3.3,6-3.3,7.4,0l0.3,0.8V21H15.9z' />}

      {((isImplant && subtype === fixtureCrown) || (isCrown && subtype === metalCeramic)) && (
        lineArray?.tooth48?.map((res: any, i: number) =>
          <line stroke={stroke || defaultLineColor} key={i} fill='none' stroke-width='0.3' x1={res?.x1} x2={res?.x2} y1={res?.y1} y2={res?.y2} />
        )
      )}
      {/* start sct */}
      {isSct && <g transform="translate(25,-3)">
        <line x1="4.5" y1="23.5" x2="1.1861" y2="18.8884" stroke={color || defaultLineColor} />
        <line x1="1.1861" y1="18.8884" x2="10.3229" y2="13.4253" stroke={color || defaultLineColor} />
        <line x1="10.3229" y1="13.4253" x2="1.1861" y2="5" stroke={color || defaultLineColor} />
      </g>}
      {/* end sct */}
    </svg>
  );
};

export const getCrownSvg = {
  Crown11,
  Crown12,
  Crown13,
  Crown14,
  Crown15,
  Crown16,
  Crown17,
  Crown18,
  Crown21,
  Crown22,
  Crown23,
  Crown24,
  Crown25,
  Crown26,
  Crown27,
  Crown28,
  Crown31,
  Crown32,
  Crown33,
  Crown34,
  Crown35,
  Crown36,
  Crown37,
  Crown38,
  Crown41,
  Crown42,
  Crown43,
  Crown44,
  Crown45,
  Crown46,
  Crown47,
  Crown48
} as any;
