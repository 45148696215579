import { all, call, put, takeEvery } from "redux-saga/effects";
import API from "../../../../../utils/api";
import {
  getOcclusionDentistRecallExamDataRequestSuccess,
  getOcclusionDentistRecallExamDataRequestFailure,
  putOcclusionDentistRecallExamDataRequestSuccess,
  putOcclusionDentistRecallExamDataRequestFailure,
} from "../../../../actions/v1/dentist/clinicalExamDentistRecallExamActions/occlusionDentistRecallExamAction";
import {
  GET_OCCLUSION_DENTIST_RECALL_EXAM_REQUEST,
  PUT_OCCLUSION_DENTIST_RECALL_EXAM_REQUEST,
} from "../../../../ActionTypes/v1/dentist/clinicalExamDentistRecallExam";
import { updateOcclusionDentistRecallFlowRequestFailure } from "../../../../actions/v1/dentist/clinicExamDentistActions/occlusionDentistRecallExamAction";
import { errorToaster } from '../../../../../utils/helper';

function* getOcclusionDentistRecallExamRequestSaga(payload: any): any {
  yield put(updateOcclusionDentistRecallFlowRequestFailure())
  try {
    const response = yield API.get(
      `/exam/dentist/recall/occlusion/${payload?.payload?.appointment_id}?appt_type=${payload?.payload?.appt_type}`
    );
    yield call(payload.payload.callback, response?.data);
    yield put(
      getOcclusionDentistRecallExamDataRequestSuccess({
        data: response?.data,
      })
    );
    // TODO: future use
    // if (payload.payload.callback) {
    //   payload.payload.callback(response.data);
    // }
  } catch (e: any) {
    yield put(updateOcclusionDentistRecallFlowRequestFailure())
    yield put(
      getOcclusionDentistRecallExamDataRequestFailure({
        error: e?.detail,
      })
    );
  }
}
function* putOcclusionDentistRecallExamRequestSaga(payload: any): any {
  try {
    const response = yield API.put(
      `/exam/dentist/recall/occlusion`,
      payload?.payload?.occlusionData
    );
    if (payload?.payload?.callback) {
      yield call(payload?.payload?.callback, response?.data);
    }
    yield put(
      putOcclusionDentistRecallExamDataRequestSuccess({
        data: response.data,
      })
    );
  } catch (e: any) {
    errorToaster(e?.detail)
    yield put(
      putOcclusionDentistRecallExamDataRequestFailure({
        error: e?.detail,
      })
    );
  }
}
function* getOcclusionDentistRecallSaga() {
  yield takeEvery(
    GET_OCCLUSION_DENTIST_RECALL_EXAM_REQUEST,
    getOcclusionDentistRecallExamRequestSaga
  );
}
function* putOcclusionDentistRecallSaga() {
  yield takeEvery(
    PUT_OCCLUSION_DENTIST_RECALL_EXAM_REQUEST,
    putOcclusionDentistRecallExamRequestSaga
  );
}

export default function* rootSaga() {
  yield all([getOcclusionDentistRecallSaga(), putOcclusionDentistRecallSaga()]);
}
