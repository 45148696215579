import { intraOralDentistRecallExamInterface } from "../../../../types/dentistTypes";
import {
  UPDATE_INTRA_ORAL_DENTIST_RECALL_EXAM,
  UPDATE_INTRA_ORAL_DENTIST_RECALL_EXAM_FAILURE,
  CHANGE_INTRA_ORAL_DENTIST_RECALL_EXAM,
} from "../../../../ActionTypes/v1/dentist/clinicalExamDentist";
import {
  defaultMpclass,
  defaultTonsilClass,
  defaultTongueScalloping,
  defaultTongueTies,
  defaultSoftPalete,
  defaultUvala,
  defaultSalivaryFlow,
  defaultSoftTissueData,
} from "../../../../../utils/recallFlowIntraOralConstant";
import { jsonParseAndStringfy } from "../../../../../utils/helper";
import {
  defaultCacheMpclass,
  defaultCacheSalivaryFlow,
  defaultCacheSoftPalete,
  defaultCacheTongueScalloping,
  defaultCacheTongueTies,
  defaultCacheTonsilClass,
  defaultCacheUvala,
  defaultCacheSoftTissueData,
} from "../../../../../utils/cacheDataConstant/recallIntraoralConstant";
const initialState: intraOralDentistRecallExamInterface = {
  mpClass: [defaultMpclass],
  tonsilClass: [defaultTonsilClass],
  tongueScalloping: [defaultTongueScalloping],
  tongueTies: [defaultTongueTies],
  softPalate: [defaultSoftPalete],
  uvala: [defaultUvala],
  salivaryFlow: [defaultSalivaryFlow],
  softTissue: [defaultSoftTissueData],
  intra_oral_soft_tissue_exam: "",
  intra_oral_comment: "",
  hx_snoring: ""
};

export const intraOralDentalTeamReducer = (
  state = initialState,
  action: any
) => {
  switch (action.type) {
    case UPDATE_INTRA_ORAL_DENTIST_RECALL_EXAM:
      return {
        ...state,
        ...action.payload,
      };
    case UPDATE_INTRA_ORAL_DENTIST_RECALL_EXAM_FAILURE:
      const newMpclassData = jsonParseAndStringfy(defaultCacheMpclass);
      const newTonsilClassData = jsonParseAndStringfy(defaultCacheTonsilClass);
      const newTongueScallopingData = jsonParseAndStringfy(
        defaultCacheTongueScalloping
      );
      const newTongueTiesData = jsonParseAndStringfy(defaultCacheTongueTies);
      const newSoftPaleteData = jsonParseAndStringfy(defaultCacheSoftPalete);
      const newUvalaData = jsonParseAndStringfy(defaultCacheUvala);
      const newSalivaryData = jsonParseAndStringfy(defaultCacheSalivaryFlow);
      const newSoftTissueData = jsonParseAndStringfy(
        defaultCacheSoftTissueData
      );
      return {
        mpClass: [newMpclassData],
        tonsilClass: [newTonsilClassData],
        tongueScalloping: [newTongueScallopingData],
        tongueTies: [newTongueTiesData],
        softPalate: [newSoftPaleteData],
        uvala: [newUvalaData],
        salivaryFlow: [newSalivaryData],
        softTissue: [newSoftTissueData],
        intra_oral_soft_tissue_exam: "",
        intra_oral_comment: "",
        hx_snoring:""
      };
    case CHANGE_INTRA_ORAL_DENTIST_RECALL_EXAM:
      const { toFinding, value, type, index, key } = action.payload as any;
      const newState = state as any;
      let newFinding = newState[toFinding] as any;
      if (newFinding && typeof newFinding !== "string") {
        newFinding[index] = newFinding[index]?.map((res: any) => {
          if (res.name === key) {
            return { ...res, value: value };
          }
          return res;
        });
      } else {
        newFinding = value;
      }
      return {
        ...state,
        [toFinding]: newFinding,
      };

    default:
      return { ...state };
  }
};
