import React, { useEffect } from "react";
import { Divider, Grid, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { makeStyles } from "@mui/styles";
import moment from 'moment';
import { useDispatch, useSelector } from "react-redux";
import theme from "../../../../../theme";
import CollapsibleBox from "../../../../../components/v2/common/CollapsibleBox";
import { RootState } from "../../../../../app/reducers/v1/rootReducer";
import { updateSleepQualityAction } from "../../../../../app/actions/v2/dentist/preIntakeActions";
import RadioSelect from "../../../common/RadioSelect";
import InputHeading from "../../../common/InputHeading";
import {
  neckMeasuredOptions,
  radioButtonOptions,
  sleepScreenQuestion,
  snoreLoudlyOptions,
} from "../../../../../utils/v2/preIntakeConstant/preIntakeConstant";

export const useStyles = makeStyles((themes: any) => ({
  td: {
    padding: "4px 12px",
    borderBottom: `1px dashed ${theme.palette.common.borderGrey}`,
  },
  th: {
    padding: "12px",
    minWidth: '150px'
  },

  radioButton: {
    height: "50px",
    padding: "0 10px 0 0",
    margin: 0,
    color: theme.palette.common.black50,
    "& .MuiButtonBase-root": {
      color: theme.palette.v2.primary.main,
    },
    "& .MuiTypography-root": {
      fontSize: `14px !important`,
      fontFamily: "FavoritPro-Regular",
    },
  },
}));

type Props = {
  disabledField?: boolean;
};

const SleepQuality = (props: Props) => {
  const { disabledField } = props;

  const classes = useStyles();
  const dispatch = useDispatch();
  const { sleepQuality, patientInformation, generalHealth, medicalHealthHistory,getFiledData } = useSelector((state: RootState) => state.preIntake);

  const handleChange = (e: any, index?: any, r?: any) => {
    const { value, name } = e.target as any;
    let updatedSleepQuality = sleepQuality;
    if (name === "additionalSleep") {
      updatedSleepQuality[name][index] = r;
    } else {
      updatedSleepQuality[name] = value;
    }
    dispatch(updateSleepQualityAction(updatedSleepQuality));
  };

  const getBMIValue = () => {
    // BMI =  kg/m2
    if (generalHealth?.heightFT) {
      // if height in ft and weight in lb
      const totalInches = parseInt(generalHealth?.heightFT) * 12 + parseInt(generalHealth?.heightIN);
      const weightKg = parseInt(generalHealth?.weight) * 0.453592; // Convert pounds to kg
      const heightM = totalInches * 0.0254; // Convert inches to meters
      return weightKg / (heightM ** 2);
    }
    else {
      // if height in cm and weight in kg
      return parseInt(generalHealth?.weight) / ((parseInt(generalHealth?.heightCM) / 100) ** 2);
    }
  };
  const bloodPressureIdMedical = getFiledData?.medicalConditionsData?.find((item: any) => item.name === "High Blood Pressure");
  const isbloodPressureMedicalIncluded = bloodPressureIdMedical && medicalHealthHistory?.medicalConditions.includes(bloodPressureIdMedical?.id);

  //stop bang cont
  const stopBangCount = () => {
    const getPatientAge = moment().diff(moment(patientInformation?.dateOfBirth), 'years');
    let count = 0;

    if (getPatientAge > 50) {
      count = count + 1;
    }
    if (patientInformation?.gender === 'Male') {
      count = count + 1;
    }
    if (getBMIValue() > 35) {
      count = count + 1;
    }
    if (isbloodPressureMedicalIncluded) {
      count = count + 1;
    }
    if (sleepQuality?.feelDuringDay === 'yes') {
      count = count + 1;
    }
    if (sleepQuality?.gaspDuringSleep === 'yes') {
      count = count + 1;
    }
    if (sleepQuality?.snoreLoudly === 'yes') {
      count = count + 1;
    }
    if (sleepQuality?.neckCircumferenceMeasured === 'Greater than or equal to 16 inches/40 cm') {
      count = count + 1;
    }
    return count;
  };

  useEffect(() => {
    let newData = sleepQuality;
    if (stopBangCount() === 3) {
      newData = {
        ...sleepQuality,
        additionalSleep: Array(8).fill(null)
      }
    }
    dispatch(updateSleepQualityAction(newData));
  }, [stopBangCount()])

  return (
    <CollapsibleBox title={"Sleep Quality"} id={"sleepQuality"}>
      <Grid container rowSpacing={1.5} columnSpacing={2.5}>
        <Grid item xs={12}>
          <Typography
            mb={"8px"}
            className={` lh-25 f-w-500 f-14 labelColor fw-medium`}
          >
            Our office focuses on a comprehensive and holistic approach to your
            health. One of the areas we screen for is something called
            “obstructive sleep apnea” which often goes undiagnosed. Sleep apnea
            is a serious medical condition that can affect long term heart and
            brain health, as well as the longevity of your life. If you could
            please answer the following questions. This will help us screen for
            this condition.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider
            color={theme.palette.common.borderGrey}
            sx={{ marginBottom: "8px" }}
          />
        </Grid>
        <Grid item xs={12}>
          <InputHeading
            title={"Have you ever been diagnosed with sleep apnea?"}
          />
          <RadioSelect
            disabled={disabledField}
            options={radioButtonOptions}
            groupProps={{
              name: "diagnosedSleepApnea",
              id: "diagnosedSleepApnea",
              defaultValue: sleepQuality?.diagnosedSleepApnea,
              onChange: (e: any) => handleChange(e),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <InputHeading
            title={
              "Neck circumference measured around Adam's apple (this would be the collar size of your dress shirts)"
            }
          />
          <RadioSelect
            disabled={disabledField}
            options={neckMeasuredOptions}
            groupProps={{
              name: "neckCircumferenceMeasured",
              id: "neckCircumferenceMeasured",
              defaultValue: sleepQuality?.neckCircumferenceMeasured,
              onChange: (e: any) => handleChange(e),
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <InputHeading title={"Do you snore loudly?"} />
          <RadioSelect
            disabled={disabledField}
            options={snoreLoudlyOptions}
            groupProps={{
              name: "snoreLoudly",
              id: "snoreLoudly",
              defaultValue: sleepQuality?.snoreLoudly,
              onChange: (e: any) => handleChange(e),
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <InputHeading
            title={"Do you often feel tired, fatigued, sleepy during the day?"}
          />
          <RadioSelect
            disabled={disabledField}
            options={radioButtonOptions}
            groupProps={{
              //   defaultValue: "no",
              name: "feelDuringDay",
              id: "feelDuringDay",
              defaultValue: sleepQuality?.feelDuringDay,
              onChange: (e: any) => handleChange(e),
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <InputHeading
            title={
              "Has anyone observed you stop breathing or gasp during sleep?"
            }
          />
          <RadioSelect
            disabled={disabledField}
            options={radioButtonOptions}
            groupProps={{
              name: "gaspDuringSleep",
              id: "gaspDuringSleep",
              defaultValue: sleepQuality?.gaspDuringSleep,
              onChange: (e: any) => handleChange(e),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputHeading
            title={"Do you have difficulty breathing through your nose?"}
          />
          <RadioSelect
            disabled={disabledField}
            options={radioButtonOptions}
            groupProps={{
              name: "difficultyBreathingNose",
              id: "difficultyBreathingNose",
              defaultValue: sleepQuality?.difficultyBreathingNose,
              onChange: (e: any) => handleChange(e),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputHeading title={"Do you currently use a CPAP machine?"} />
          <RadioSelect
            disabled={disabledField}
            options={radioButtonOptions}
            groupProps={{
              name: "useCPAPMachine",
              id: "useCPAPMachine",
              defaultValue: sleepQuality?.useCPAPMachine,
              onChange: (e: any) => handleChange(e),
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <InputHeading
            title={
              "Do you currently use an oral appliance specifically designed for sleep?"
            }
          />
          <RadioSelect
            disabled={disabledField}
            options={radioButtonOptions}
            groupProps={{
              name: "useOralAppliance",
              id: "useOralAppliance",
              defaultValue: sleepQuality?.useOralAppliance,
              onChange: (e: any) => handleChange(e),
            }}
          />
        </Grid>
        {stopBangCount() >= 4 && (
          <Grid item xs={12}>
            <InputHeading
              title={
                "Additional Sleep Screening Questions (Epworth Sleepiness Scale)"
              }
            />
            <TableContainer
              component={Paper}
              className="border-radius-8"
              sx={{
                background: theme.palette.common.white,
                border: `1px solid ${theme.palette.common.borderGrey}`,
              }}
            >
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{
                        minWidth: {
                          xs: '200px'
                        }
                      }}
                      className={`labelColor fw-regular f-14 lh-19 f-w-500`}
                    >
                      How likely are you to doze off or sleep while doing the
                      following activities?
                    </TableCell>
                    <TableCell
                      align="center"
                      classes={{ root: classes.th }}
                      className={`labelColor fw-regular f-14 lh-19 f-w-500`}
                    >
                      Would never doze or sleep
                    </TableCell>
                    <TableCell
                      align="center"
                      classes={{ root: classes.th }}
                      className={`labelColor fw-regular f-14 lh-19 f-w-500`}
                    >
                      Slight chance of dozing or sleeping
                    </TableCell>
                    <TableCell
                      align="center"
                      classes={{ root: classes.th }}
                      className={`labelColor fw-regular f-14 lh-19 f-w-500`}
                    >
                      Moderate chance of dozing or sleeping
                    </TableCell>
                    <TableCell
                      align="center"
                      classes={{ root: classes.th }}
                      className={`labelColor fw-regular f-14 lh-19 f-w-500`}
                    >
                      High chance of dozing or sleeping
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sleepScreenQuestion.map((row: any, index: number) => (
                    <TableRow
                      key={index}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        classes={{ root: classes.td }}
                        className={`labelColor fw-regular f-14 lh-19 f-w-500`}
                      >
                        {row.label}
                      </TableCell>
                      {[0, 1, 2, 3].map((r: number) => (
                        <TableCell
                          align="center"
                          classes={{ root: classes.td }}
                        >
                          <RadioSelect
                            disabled={disabledField}
                            sx={{ justifyContent: "center" }}
                            options={[{ label: ``, value: `${r}` }]}
                            groupProps={{
                              name: "additionalSleep",
                              id: "additionalSleep",
                              value: `${sleepQuality.additionalSleep[index]}`,
                              onChange: (e: any) => handleChange(e, index, r),
                            }}
                          />
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        )}
      </Grid>
    </CollapsibleBox>
  );
};

export default SleepQuality;
