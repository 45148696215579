
import { put, takeEvery, all } from "redux-saga/effects";
import API from "../../../../utils/api";
import { errorToaster } from '../../../../utils/helper';
import { GetTCDataFailure, GetTCDataSuccess, termsConditionFailure, termsConditionSuccess } from '../../../actions/v2/authAction/termsConditionsAction';
import { GET_TC_REQUEST, TERMS_CONDITION_V2_REQUEST } from '../../../ActionTypes/v2/auth';

function* acceptTermsConditionRequestSaga(payload: any): any {
  try {
    const response = yield API.put(`/v2/auth/terms/conditions/accepted?is_owner=${payload?.payload?.isOwner}`, payload?.payload?.payload)
    yield put(
      termsConditionSuccess({
        termsConditionsData: response?.termsConditions
      })
    );
    if (payload?.payload?.callback) {
      payload?.payload?.callback(response)
    }
  } catch (e: any) {
    errorToaster(e?.message)
    yield put(
      termsConditionFailure({
        termsConditionsError: e?.message || "Something went wrong!"
      })
    );
  }
}

function* getTCDataRequestSaga(payload: any): any {
  try {
    const response = yield API.get(`/v2/admin/cms?seo_url=${payload?.payload?.seo_url}&module=${payload?.payload?.module}&country=${payload?.payload?.country}`)
    yield put(
      GetTCDataSuccess({
        getTCData: response?.data?.detail
      })
    );
    if (payload?.payload?.callback) {
      payload?.payload?.callback(response)
    }
  } catch (e: any) {
    errorToaster(e?.message)
    yield put(
      GetTCDataFailure({
        getTCError: e?.message || "Something went wrong!"
      })
    );
  }
}

function* acceptTermsConditionSaga() {
  yield takeEvery(TERMS_CONDITION_V2_REQUEST, acceptTermsConditionRequestSaga);
}

function* getTCDataSaga() {
  yield takeEvery(GET_TC_REQUEST, getTCDataRequestSaga);
}
export default function* rootSaga() {
  yield all([
    acceptTermsConditionSaga(),
    getTCDataSaga()
  ])
}
