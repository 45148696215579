import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import {Grid} from "@mui/material";

const Loader = (props: any) => {
    return (
        <Grid container justifyContent={'center'} alignItems={'center'} sx={{display: 'flex'}}
              height={props.height || "100vh"}>
            <CircularProgress/>
        </Grid>
    )
}

export default Loader;
