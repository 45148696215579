

export const muscleSubType = [
    { label: "Neither", value: "Neither" },
    { label: "Left", value: "Left" },
    { label: "Right", value: "Right" },
    { label: "Both", value: "Both" },
  ];
  export const masseterData = [
    {
      title: "Sub Type",
      value: "",
      name: "sub_type",
      type: "select",
      option: muscleSubType,
    },
    {
      title: "Notes",
      value: "",
      name: "note",
      type: "string",
    },
  ];
  export const temporalisData = [
    {
      title: "Sub Type",
      value: "",
      name: "sub_type",
      type: "select",
      option: muscleSubType,
    },
    {
      title: "Notes",
      value: "",
      name: "note",
      type: "string",
    },
  ];
  export const lateralPterygoidData = [
    {
      title: "Sub Type",
      value: "",
      name: "sub_type",
      type: "select",
      option: muscleSubType,
    },
    {
      title: "Notes",
      value: "",
      name: "note",
      type: "string",
    },
  ];
  export const medialPterygoidData = [
    {
      title: "Sub Type",
      value: "",
      name: "sub_type",
      type: "select",
      option: muscleSubType,
    },
    {
      title: "Notes",
      value: "",
      name: "note",
      type: "string",
    },
  ];
  export const otherData = [
    {
      title: "Notes",
      value: "",
      name: "note",
      type: "string",
    },
  ];